import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { getStationByCreatorAction } from "../../../store/actions/stationAction";
import Table from "./locationTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';




const StationView = (props) => {
  useEffect(() => {
    props.StationData();
  }, [])

  const { role } = JSON.parse(localStorage.getItem("user"));

  const data = props.stations && props.stations

  const columns = useMemo(

    () => [

      {
        header: 'Station Id',
        accessor: 'stationid'
      },
      {
        header: 'Charging Station Name',
        accessor: 'name'
      },
      {
        header: 'Visibility',
        accessor: 'visibility'
      },
      {
        header: 'Project Name',
        accessor: 'projectname'
      },

      {
        header: 'Commission Date	',
        id: 'commisiondate',
        accessor: d => {
          return moment(d.commisiondate)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Actions',
        id: 'id',
        accessor: d => `${d.stationid}/${d.id}`,
        Cell: (data) => {

          function handelClickUpdate(e) {
            if (role == "Network Operator" || role == "Admin"){
              window.open(`/dashboard/station/updatestation/${data.value}`, "_self");
            } else {
              alert("Not Authorised")
            }
          }
          return (
            <div>
              <span>
                <a href={`/dashboard/stations/viewstation/${data.value}`}
                  rel="tooltip"
                  title="View "
                  className="table-action view"
                >
                  <i className="fa fa-eye"></i>
                </a>

              </span>&nbsp;
              <span>
                <a href={`/dashboard/station/editstation/${data.value}`}
                  rel="tooltip"
                  title="Edit "
                  className="table-action view">
                  <i className="fa fa-edit"></i>
                </a>
              </span>&nbsp;

              <span>
                <i rel="tooltip" title="Update"  className="fas fa-wrench table-action Update  li" onClick={handelClickUpdate}></i>
              </span>&nbsp;

              <span>
                <a
                  href={`/dashboard/stations/addstationgroup/${data.value}`}
                  rel="tooltip"
                  title="Add Price Group "
                  className="table-action Price Group"

                >
                  <i className="far fa-object-group "   rel="tooltip"
                  title="Price Group "></i>
                </a>
              </span>&nbsp;
            </div>

          );

        },

      }
    ],
    []
  );

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="mb-3 h-100 sideCardDesigns ">
            <div className="card-header singleCard">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <strong>Location Details</strong>
              </div>
            </div>

            {!data.length ?
              (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
              :
              (<div className="App"> <Table data={data} columns={columns}></Table></div>)}

          </div>
        </div>
      </div>

    </>
  );
};

const mapStateToProps = function (state) {
  return {
    stations: state.station.fetchStation,
    loading: state.station.isLoading,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    StationData: (viewtype) => dispatch(getStationByCreatorAction(viewtype)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StationView);




