import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { getChargerByOwnerAction } from "../../../store/actions/stationAction";
import Table from "./chargerStatusTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';

const ChargerListView = (props) => {
  useEffect(() => {
    props.chargerData();
  }, [])

  const statusColorMap =  {
    Available: 'Green',
    Busy: 'Purle',
    Maintenance: 'Red'
  }

  const data = props.chargers && props.chargers
  const columns = useMemo(

    () => [

      {
        header: 'Charge BoxId',
        accessor: 'chargeboxid',
        id: 'chargeboxid',
      },
      {
        header: 'Charger Name',
        accessor: 'chargername'
      },
      {
        header: 'Location',
        accessor: 'location'
      },
      {
        header: 'Operator',
        accessor: 'operator'
      },
      {
        header: 'Charger Type',
        accessor: 'chargertype'
      },
      {
        header: 'Make',
        accessor: 'make'
      },
      {
        header: 'Model',
        accessor: 'model'
      },
      {
        header: 'QR Number',
        accessor: 'qrnumber'
      },
      {
        header: 'Power Type	',
        accessor: 'powertype'
      },
      {
        header: 'Commission Date	',
        id: 'commisiondate',
        accessor: d => {
          return moment(d.commisiondate)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Status',
        accessor:"status",
        Cell: (props) => {
          return (
            <p style={{ color: statusColorMap[props.value],fontWeight:"bold" }}>{props.value}</p>
          );
        }
 
      },
      {
        header: 'Actions',
        accessor: 'chargeboxid',
        id: 'chargeboxida',
        Cell: (chargeboxid) => {
      // console.log("id cell",id)
          return (
            <div>
              <span>
                <a href={`/dashboard/station/chargerstats/${chargeboxid.value}`}
                  rel="tooltip"
                  title="View "
                  className="table-action view"
                >
                  <i className="fas fa-eye"></i>
                </a></span>
            </div>

          );

        },

      }
    ],
    []
  );



  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="mb-3 h-100 sideCardDesigns">
            <div className="card-header singleCard ">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <strong>Charger status Details</strong>
              </div>
            </div>

            {!data.length ?
              (<div style={{display: "flex",  alignItems: "center",justifyContent: "center"}}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{display: "flex",  alignItems: "center",justifyContent: "center"}} /></h3></div>) 
              :
              (<div className="App"> <Table data={data} columns={columns}></Table></div>)}

          </div></div>
      </div>

    </>
  );
};

const mapStateToProps = function (state) {
  return {
    chargers: state.station.fetchdashchargers,
    loading: state.station.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    chargerData: () => dispatch(getChargerByOwnerAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargerListView);
