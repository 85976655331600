import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { getLocationAction, deactivateLocationAction } from "../../../../store/actions/locationAction";
import Table from "./locationTable";
import "../../report/table.css";
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2'

const LocationPage = (props) => {
  useEffect(() => {
    props.getLocation();

  }, [])
  const user = JSON.parse(localStorage.getItem('user'));
  
  const deactivateLocation = (id) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#1e295d',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        props.deactivateLocation(id);
        window.location.reload();
      }
    });
  }

  const data = props.locations && props.locations
  const deactive = props.locations && props.locations.map(en => en.deactive)
  //console.log("deac",deactive)


  const columns = useMemo(

    () => [

      {
        header: 'Location Name',
        accessor: 'name'
      },
      {
        header: 'Address',
        accessor: 'address'
      },
      {
        header: 'City',
        accessor: 'city'
      },
      {
        header: 'State',
        accessor: 'state'
      },
      {
        header: 'Date',
        id: 'createdat',
        accessor: d => {
          return moment(d.createdat)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Status',
        accessor: 'status'
      },

      {
        header: 'Actions',
        accessor: 'id',
        Cell: (id) => {
          // console.log("id",id)
          //console.log("id",id.data.map(ac => ac.deactive))
          return (
            <div>
              <span>
                <a href={`/dashboard/assets/viewlocation/${id.value}`}
                  rel="tooltip"
                  title="View "
                  className="table-action view"
                >
                  <i className="fas fa-eye"></i>
                </a></span>
              &nbsp;&nbsp;

              <span>
                <a href={`/dashboard/assets/editlocation/${id.value}`}
                  rel="tooltip"
                  title="Edit "
                  className="table-action edit"
                >
                  <i className="fa fa-edit"></i>
                </a></span>
              &nbsp;&nbsp;
              {user.role === "Admin" ? (
              <span>
              {deactive ? <a href="#" rel="tooltip" title="Activate" id="id" className="table-action slash" onClick={(e) => { deactivateLocation(id, false); }} disabled>
                <i className="fas fa-trash" style={{ color: "green" }} ></i>
              </a> : <a href="#" rel="tooltip" title="Deactivate" id="id" className="table-action active" onClick={(e) => { deactivateLocation(id, true); }} disabled>
                <i className="fas fa-trash" style={{ color: "red" }} ></i>
              </a>}
            </span>
           ) : (
            <span >
          </span>
        )}
            </div>

          );

        },

      }
    ],
    []
  );

  return (

    <div className="col-md-12 col-lg-12">
      <div className="mb-3 h-100 sideCardDesigns">
        <div className=" singleCard">
          <div className="card-header-title font-size-lg text-capitalize font-weight-normal pl-3 pb-4 pt-3 ">
            <strong style={{ color: "#10204B" }}>Location Details</strong>
          </div>
        </div>

        {!data.length ?
          (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
          :
          (<div className="App"> <Table data={data} columns={columns}></Table></div>)}

      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locations: state.location.fetchLocation,
    deactivateloc: state.location.deactivateLocation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLocation: () => dispatch(getLocationAction()),
    deactivateLocation: (id) => dispatch(deactivateLocationAction(id))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LocationPage);
