import React from "react";
import "./help.css";

const Help=()=>{
    return(
      <>
    <center><h1>Frequently Asked Questions (FAQ)</h1></center>
    <div class="accordion">
        <div class="item">
            <input id="accordion1" checked="checked" type="radio" name="accordion" hidden="hidden"></input>
            <label class="menulabel" for="accordion1">What is this ChargeGrid website for?  </label>
            <div class="acoordion-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit nulla non laboriosam accusamus ex neque sit. Corporis in expedita optio ducimus, id illo, iure hic officia quam qui sapiente veniam!</div>
        </div>
        <div class="item">
            <input id="accordion2" checked="checked" type="radio" name="accordion" hidden="hidden"></input>
            <label class="menulabel" for="accordion2">What all features and functionality are there in this dashboard?</label>
            <div class="acoordion-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit nulla non laboriosam accusamus ex neque sit. Corporis in expedita optio ducimus, id illo, iure hic officia quam qui sapiente veniam!</div>
        </div>
        <div class="item">
            <input id="accordion3" checked="checked" type="radio" name="accordion" hidden="hidden"></input>
            <label class="menulabel" for="accordion3">If I am a fleet owner, Will I able to view my driver details?</label>
            <div class="acoordion-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit nulla non laboriosam accusamus ex neque sit. Corporis in expedita optio ducimus, id illo, iure hic officia quam qui sapiente veniam!</div>
        </div>
        <div class="item">
            <input id="accordion4" checked="checked" type="radio" name="accordion" hidden="hidden"></input>
            <label class="menulabel" for="accordion4">Can I check previous reports of a particular date?</label>
            <div class="acoordion-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit nulla non laboriosam accusamus ex neque sit. Corporis in expedita optio ducimus, id illo, iure hic officia quam qui sapiente veniam!</div>
        </div>
        <div class="item">
            <input id="accordion5" checked="checked" type="radio" name="accordion" hidden="hidden"></input>
            <label class="menulabel" for="accordion5">Can I send bulk mail and messages?</label>
            <div class="acoordion-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit nulla non laboriosam accusamus ex neque sit. Corporis in expedita optio ducimus, id illo, iure hic officia quam qui sapiente veniam!</div>
        </div>
    </div>
   
      </>

    )
    }
export default Help;