const initState = {
    saveSubscriptionplan: '',
    fetcSubscriptionplan: '',
    isLoading: false,
    fetchPlanType: '',
    fetchsubscriptionbyid: '',
    updateplanbyid: '',
    planDeactivation:'',
}
const subscriptionReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SUBSCRIPTION_PLAN_LOADING':
            return { ...state, isLoading: true }
        case 'SAVE_SUBSCRIPTION_PLAN':
            return { ...state, saveSubscriptionplan: action.payload, isLoading: false }
        case 'FETCH_SUBSCRIPTION_PLAN':
            return { ...state, fetcSubscriptionplan: action.payload, isLoading: false }
        case 'FETCH_SUB_PLAN_TYPE':
            return { ...state, fetchPlanType: action.payload, isLoading: false }
        case 'FETCH_SINGLE_PLAN':
            return { ...state, fetchsubscriptionbyid: action.payload, isLoading: false }
        case 'UPDATE_SINGLE_PLAN':
            return { ...state, updateplanbyid: action.payload, isLoading: false }
        case 'PLAN_DEACTIVATION':
            return { ...state, planDeactivation: action.payload, isLoading: false }
        default:
            return state;
    }
}
export default subscriptionReducer;