import React from 'react';
import TransactionCardPage from './transactioncard';
import TransactionListPage from './transactionlist';

function Transaction() {
 
    return (
    <div className="container-fluid">
    
     <TransactionListPage></TransactionListPage>
     
    </div>
  );
}
export default Transaction;