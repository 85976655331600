import { HUB_BASE_URL, GET_ALL_HUB_OPERATIONDATA_URL, GET_ALL_HUB_LIST_URL, GET_SINGLE_HUB_URL, GET_HUB_STATUS_URL, GET_HUB_POWER_URL, GET_ALL_HUB_METER_URL, GET_CHARGER_PMR_URL, GET_ELECTRICPANEL_PMR_URL, GET_BROADBAND_PMR_URL, GET_CCTV_PMR_URL, GET_ALL_ATTENDANCE, GET_ALL_HUBPRIVILES_URL, GET_ALL_HUBROLES_URL, SAVE_ACCESSMANAGEMENT_URL, GET_ALL_HUBUSERS_URL,DELETE_HUB_USER_URL,GET_HUB_HAZARD_REPORT_URL,GET_SINGLE_HUB_USER_URL,UPDATE_SINGLE_HUB_USER_URL,GET_QRCODE_LIST_URL,GET_VEHICLE_NO_LIST_URL,GET_BATTERY_TYPE_URL,GET_VEHICLE_TYPE_URL,GET_FLEET_OPERATOR_URL,SAVE_VEHICLE_DATA_URL,GET_VEHICLE_DETAILS_URL,GET_HUB_TRANSACTION_DATA_URL,GET_VISITOR_ATTENDANCE_URL,GET_HUBOTP_URL, SAVE_HUB_LIST_URL,SAVE_QR_MASTER_URL,SAVE_VEHICLE_NUMBER_MASTER_URL,SAVE_VEHICLE_TYPE_MASTER_URL,SAVE_BATTERY_KW_MASTER_URL,SAVE_FLEET_OPERATOR_MASTER_URL,GET_COMPLETED_TRANSACTION_URL,GET_VEHICLE_CHECKIN_WITHTIME_URL,GET_BUSY_PARKING_REPORT_URL, VEHICLE_STATUS_REPORT_URL, GET_ALL_HUB_OPERATIONDATA_WITHIN_DATE_RANGE } from "../../components/config/configs";


export const getAllHubOperationData = () => {

  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_ALL_HUB_OPERATIONDATA_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_HUB_OPS_DATA",data);
        dispatch({ type: 'GET_HUB_OPS_DATA', payload: data })
      })
  }
}


export const getAllHubListData = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + GET_ALL_HUB_LIST_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_HUB_LIST_DATA",data);
        dispatch({ type: 'GET_HUB_LIST_DATA', payload: data })
      })
  }
}



export const getSingleHubDetails = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_SINGLE_HUB_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_SINGLE_HUB_DETAILS",data);
        dispatch({ type: 'GET_SINGLE_HUB_DETAILS', payload: data })
      })
  }
}

export const getAllHubDetails = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_ALL_HUB_METER_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_ALL_HUB_DETAILS",data);
        dispatch({ type: 'GET_ALL_HUB_DETAILS', payload: data })
      })
  }
}

export const getHubStatus = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token ", bearer)
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_HUB_STATUS_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_HUB_STATUS",data);
        dispatch({ type: 'GET_HUB_STATUS', payload: data })
      })
  }
}


export const getSingleHubPower = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_HUB_POWER_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_HUB_POWER",data);
        dispatch({ type: 'GET_SINGLE_HUB_POWER', payload: data })
      })
  }
}

export const getSingleHubChargerPmr = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_CHARGER_PMR_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_SINGLE_HUB_CHARGER_PMR",data);
        dispatch({ type: 'GET_SINGLE_HUB_CHARGER_PMR', payload: data })
      })
  }
}
export const getSingleHubCCTVPmr = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token ", bearer)
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_CCTV_PMR_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_SINGLE_HUB_CCTV_PMR",data);
        dispatch({ type: 'GET_SINGLE_HUB_CCTV_PMR', payload: data })
      })
  }
}
export const getSingleHubBroadbandPmr = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_BROADBAND_PMR_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_SINGLE_HUB_BROADBAND_PMR",data);
        dispatch({ type: 'GET_SINGLE_HUB_BROADBAND_PMR', payload: data })
      })
  }
}
export const getSingleHubElectricpanelPmr = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_ELECTRICPANEL_PMR_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_SINGLE_HUB_ELECTRICPANEL_PMR",data);
        dispatch({ type: 'GET_SINGLE_HUB_ELECTRICPANEL_PMR', payload: data })
      })
  }
}
export const getHubHazardReport = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_HUB_HAZARD_REPORT_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_HUB_WISE_HAZARD_REPORT",data);
        dispatch({ type: 'GET_HUB_WISE_HAZARD_REPORT', payload: data })
      })
  }
}
export const getSingleHubUserDetails = (mobile) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_SINGLE_HUB_USER_URL + "?mobile=" + mobile, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_SINGLE_HUB_USER",data);
        dispatch({ type: 'GET_SINGLE_HUB_USER', payload: data })
      })
  }
}

export const getAllAttendance = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_ALL_ATTENDANCE, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("GET_ALL_ATTENDANCE", data);
        dispatch({ type: 'GET_ALL_ATTENDANCE', payload: data })
      })
  }
}

export const getAllprivileges = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_ALL_HUBPRIVILES_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("GET_ALL_HUBPRIVILES_URL", data);
        dispatch({ type: 'GET_ALL_HUBPRIVILEGES', payload: data })
      })
  }
}


export const getHubroles = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_ALL_HUBROLES_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("GET_ALL_HUBROLES_URL", data);
        dispatch({ type: 'GET_ALL_HUBROLES', payload: data })
      })
  }
}




export const saveAccessmanagementAction = (user) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { name, role, privileges, phone, country, cstate, city } = user;
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + SAVE_ACCESSMANAGEMENT_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        mobile: parseInt(phone),
        country: country,
        state: cstate,
        city: city,
        role: role,
        privileges: privileges,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("SAVE_ACCESSMANAGEMENT", data)
        dispatch({ type: 'SAVE_ACCESSMANAGEMENT', payload: data })
      })
  }
}


export const getAllhubuserlist = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;

  // console.log("tok----->",bearer )
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + GET_ALL_HUBUSERS_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json()
      )
      .then(data => {
        
        dispatch({ type: 'GET_ALLHUBUSER_LIST', payload: data })
      })
  }
}



export const deactivateHubAction = (user) =>{
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("user",user);
  return dispatch => {
    return fetch(HUB_BASE_URL+DELETE_HUB_USER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
          "mobile": user,
     })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("DELETE_SIGNLEHUB_USER",data)
          dispatch({type: 'DELETE_SIGNLEHUB_USER', payload: data})
      })
  }
}

export const editHubUserByNumber = (mobile,data) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const {name,role,country,city,state,privileges} = data;
  return dispatch => {
    dispatch({ type: 'PRICE_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + UPDATE_SINGLE_HUB_USER_URL, {
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "name":name,
        "mobile":parseInt(mobile),
        "role":role,
        "country":country,
        "city":city,
        "privileges":privileges,
        "state":state

      })
    }).then(resp => resp.json())
      .then(data => {

        dispatch({ type: 'EDIT_HUB_USER_DETAILS', payload: data })
        // console.log("EDIT_HUB_USER_DETAILS", data);
      })
  }
}

export const getQRcodeList = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_QRCODE_LIST_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_QRCODE_LIST_DATA",data);
        dispatch({ type: 'GET_QRCODE_LIST_DATA', payload: data })
      })
  }
}
export const getVehicleNoList = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_VEHICLE_NO_LIST_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_VEHICLE_NO_DATA",data);
        dispatch({ type: 'GET_VEHICLE_NO_DATA', payload: data })
      })
  }
}
export const getBatteryTypeList = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_BATTERY_TYPE_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_BATTERY_TYPE_DATA",data);
        dispatch({ type: 'GET_BATTERY_TYPE_DATA', payload: data })
      })
  }
}
export const getFleetOperatorList = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_FLEET_OPERATOR_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_FLEET_OPERATOR_DATA",data);
        dispatch({ type: 'GET_FLEET_OPERATOR_DATA', payload: data })
      })
  }
}
export const getVehicleTypeList = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token ", bearer)
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_VEHICLE_TYPE_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_VEHICLE_TYPE_DATA",data);
        dispatch({ type: 'GET_VEHICLE_TYPE_DATA', payload: data })
      })
  }
}


export const saveVehicleAndQrcode = (selectedQrcode,selectedBatteryType,selectedHubList,selectedVehicleNo,selectedVehicleType,selectedFleet) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + SAVE_VEHICLE_DATA_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({

        QRcode: selectedQrcode,
        hubname: selectedHubList,
        fleetoperator: selectedFleet,
        vehiclenumber: selectedVehicleNo,
        vehicletype: selectedVehicleType,
        batterykwh: selectedBatteryType,

      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("SAVE_VEHICLE_QRCODE_DATA", data)
        dispatch({ type: 'SAVE_VEHICLE_QRCODE_DATA', payload: data })
      })
  }
}

export const getVehicleDetails = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token ", bearer)
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_VEHICLE_DETAILS_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_VEHICLE_DETAILS",data);
        dispatch({ type: 'GET_VEHICLE_DETAILS', payload: data })
      })
  }
}


//All Hub transaction details

export const getAllHubTransactionDetails = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token ", bearer)
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_HUB_TRANSACTION_DATA_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_TRANSACTION_DATA",data);
        dispatch({ type: 'FETCH_TRANSACTION_DATA', payload: data })
      })
  }
}

// Get all visitor attenadace details
export const getAllvisitorsattendance = (hubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token ", bearer)
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_VISITOR_ATTENDANCE_URL + "?hubname=" + hubname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_VISITOR_ATTENDANCE_DATA",data);
        dispatch({ type: 'FETCH_VISITOR_ATTENDANCE_DATA', payload: data })
        
      })
     
  }
}



export const getAllHubOTPListData = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(HUB_BASE_URL + GET_HUBOTP_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_HUB_LIST_DATA",data);
        dispatch({ type: 'FETCH_OTP_DATA', payload: data })
      })
  }
}

//add new hub in master

export const savehublistAction = (hublist) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))

  var bearer = 'basic ' + token;
  const { hubname, lat, long, stationid,isloadrestriction,minloadrestriction} = hublist;


  var isloadrestrictionTemp = false
  if(isloadrestriction==="Yes"){
    isloadrestrictionTemp = true
  }



  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + SAVE_HUB_LIST_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        hubname: hubname,
        lat: parseFloat(lat),
        long: parseFloat(long),
        isloadrestriction:isloadrestrictionTemp,
        minloadrestriction:parseFloat(minloadrestriction),
        stationid: stationid
      })
    })
      .then(resp => resp.json())
      .then(data => {

        dispatch({ type: 'SAVE_HUBLIST_DATA', payload: data })
      })
  }
}

//Add new qr in master
export const savevehicleqrAction = (qr) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { qrcodeno} = qr;
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + SAVE_QR_MASTER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        qrcodeno:qrcodeno,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'SAVE_VEHICLE_QR_DATA', payload: data })
      })
  }
}

//Add new vehicle number in master
export const savevehiclenumberAction = (vehicleno) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { vehiclenumber} = vehicleno;
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + SAVE_VEHICLE_NUMBER_MASTER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        vehiclenumber:vehiclenumber,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'SAVE_VEHICLE_NUMBER_DATA', payload: data })
      })
  }
}

//Add new vehicle type in master
export const savevehicletypeAction = (vehicletypemaster) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { vehicletype} = vehicletypemaster;
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + SAVE_VEHICLE_TYPE_MASTER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        vehicletype:vehicletype,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("SAVE_VEHICLE_TYPE_DATA", data)
        dispatch({ type: 'SAVE_VEHICLE_TYPE_DATA', payload: data })
      })
  }
}


//Add new vehicle type in master
export const savebatterykwAction = (vehicleno) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("vehiclenob",vehicleno)
  const { batterytype} = vehicleno;
  // console.log("battary",batterytype)
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + SAVE_BATTERY_KW_MASTER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        batterykwh:batterytype,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("SAVE_BATTERY_KW_DATA", data)
        dispatch({ type: 'SAVE_BATTERY_KW_DATA', payload: data })
      })
  }
}

//Add new fleet operator in master
export const savefleetoperatorAction = (oem) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const {fleetoperator} = oem;
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + SAVE_FLEET_OPERATOR_MASTER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        oemname:fleetoperator
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("SAVE_FLEET_OPERATOR_DATA", data)
        dispatch({ type: 'SAVE_FLEET_OPERATOR_DATA', payload: data })
      })
  }
}





//All Hub Completed transaction details

export const getAllCompletedHubTransaction = (trhubname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token ", bearer)

  const {hubname} = trhubname;

  return dispatch => {
    return fetch(HUB_BASE_URL + GET_COMPLETED_TRANSACTION_URL, {
      method: "POST",
      headers: {
         'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        hubname:hubname
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_COMPLETED_TRANSACTION_DATA",data);
        dispatch({ type: 'FETCH_COMPLETED_TRANSACTION_DATA', payload: data })
      })
  }
}

//checkin vehicle with hub name and date and time filter

export const getVehicleCheckinwithtime = (hubname) => {
  const { token, id } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("newhubname",hubname)
  var hubname_new = hubname;
  // console.log("hubname_new",hubname_new)
  // var checkdate_new = hubname.checkdate;
  // var checkfromtime_new = hubname.checkfromtime;
  // var checktotime_new = hubname.checktotime;


  // console.log("Downtimeurl",STATION_BASE_URL + GET_CHARGER_DOWNTIME_URL + "?chargeboxid=" + chargeboxid + "&start=" + start + "&end=" + end,)
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + GET_VEHICLE_CHECKIN_WITHTIME_URL + "?hubname=" + hubname_new, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_VEHICLE_CHECKIN_DATE_DATA", data);
        dispatch({ type: 'FETCH_VEHICLE_CHECKIN_DATE_DATA', payload: data })
      })
  }
}



//parking report

export const getVehicalparkingAction = (phubname,start,end) => {
  const { token, id } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;

  // console.log("hubname",phubname)
  // console.log("startdate",start)
  // console.log("enddate",end)
  const hubname = phubname.hubname
  return dispatch => {
    dispatch({ type: 'HUB_LOADING', payload: '' })
    return fetch(HUB_BASE_URL + GET_BUSY_PARKING_REPORT_URL + "?hubname=" + hubname + "&startdate=" + start + "&enddate=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
      //  console.log("FETCH_VEHICLE_PARKING_DATA", data);
        dispatch({ type: 'FETCH_VEHICLE_PARKING_DATA', payload: data })
      })
  }
}


export const getVehicleStatusReport = (hubName) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'VEHICLE_STATUS_REPORT_DATA', payload: '', isVehicleStatusReportLoading: true })
    return fetch(HUB_BASE_URL + VEHICLE_STATUS_REPORT_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        hubName: hubName
      })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'VEHICLE_STATUS_REPORT_DATA', payload: data, isVehicleStatusReportLoading: false })
      }).catch(error => {
        dispatch({ type: 'VEHICLE_STATUS_REPORT_DATA', payload: '', isVehicleStatusReportLoading: false })
        // console.log(error.message);
      });
  }
}

export const getAllHubOperationDataWithDateRange = (req) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'GET_HUB_OPS_DATA', isVehicleStatusReportLoading: true })
    return fetch(HUB_BASE_URL + GET_ALL_HUB_OPERATIONDATA_WITHIN_DATE_RANGE, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        fromDate: req[0],
        toDate: req[1],
      })
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("GET_HUB_OPS_DATA",data);
        dispatch({ type: 'GET_HUB_OPS_DATA', payload: data })
      })
  }
}