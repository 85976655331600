import React from 'react';
import PriceCardModel from './pricecard';
import "./price.css";
import PriceListView from './pricelist';
import SubscriptionListTable from '../plans/subscriptionlist';
function Price(props) {
     return (
    <div className="container-fluid">
      <PriceCardModel></PriceCardModel>
      <SubscriptionListTable></SubscriptionListTable>
      <PriceListView></PriceListView>
     
    </div>
  );
}
export default Price;