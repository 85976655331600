import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { DateRangePicker } from "react-dates";
import Table from "./chargerStatusTable";
import Loader from 'react-loader-spinner';
import { useParams } from "react-router-dom";
import { getChargerDowntime } from "../../../store/actions/stationAction";
import PureBreadcrumbs from "../Breadcrums";

const ChargerDetail = (props) => {
  const { chargeboxid } = useParams()
  const [startDate, setStartDate] = useState(Moment().subtract(6, "days"));
  const [endDate, setEndDate] = useState(Moment().add(1, "days"));
  const [focusedInput, setFocusedInput] = useState(Moment());
  const isOutsideRange = (day) => {
    day.isAfter(Moment()) || day.isBefore(Moment().subtract(30, "days"));
  };

  useEffect(() => {
    const today = Moment();
    const current = today.add(1, "days").format("YYYY-MM-DD");
    const last = today.subtract(6, "days").format("YYYY-MM-DD");
    props.chargerDowntime(chargeboxid, last, current)
  }, []);

  const handleDateClick = () => {
    if (
      startDate && startDate.format("ll") &&
      endDate && endDate.format("ll")
    ) {
      const current = startDate.format("YYYY-MM-DD");
      const last = endDate.format("YYYY-MM-DD");
      props.chargerDowntime(chargeboxid, current, last);
    }
  };
  const statsdata = props.chargerstats && props.chargerstats.session


  const columns = useMemo(() => [
    {
      header: 'Date & Time',
      accessor: 'ocpp_time',
      id: 'ocpp_time',
      Cell: props => {
        return (
          <div>{props.value.substring(0, props.value.length - 8)}</div>)
      }
    },
    {
      header: 'Time',
      accessor: 'ocpp_time',
      id: 'Ocpp',
      Cell: props => {
        // console.log("props",props)
        return (
          <div>{Moment(props.value).add(330, 'minutes').format('hh:mm A')}</div>)
      }
    },
    {
      header: 'Status',
      accessor: 'status',
      Cell: (props) => {
        return (
          <p style={{ color: props.value === "Online" ? "green" : "red", fontWeight: "bold" }}>
            {props.value}
          </p>
        );
      }
    },
  ],
    []
  );

  return (

    <div className="container-fluid">
      <div className="row  ">
        <div className="col col-sm-4 ">
          <PureBreadcrumbs />
        </div>
      </div>
      <div className="row gy-5 ">
        <div className="col-md-4 col-xl-4 col-sm-4 col-12">
          <div className="sideCardDesigns border-light mb-3 widget-content  ">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper p-3">
                <div className="widget-content-left">
                  <div className="insideHeading">Charge BoxID</div>
                </div>
                <div className="widget-content-right">
                  <div className="widget-numbers insideContent">
                    <strong>
                      {chargeboxid}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xl-4 col-sm-4 col-12">
          <div className="sideCardDesigns border-light mb-3 widget-content ">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper p-3">
                <div className="widget-content-left">
                  <div className="insideHeading">Total Available Time(in hrs)</div>
                </div>
                <div className="widget-content-right">
                  <div className="widget-numbers insideContent">
                    <strong>

                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xl-4 col-sm-4 col-12">
          <div className="sideCardDesigns border-light mb-3 widget-content ">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper p-3">
                <div className="widget-content-left">
                  <div className="insideHeading">Total Downtime(in hrs)</div>
                </div>
                <div className="widget-content-right">
                  <div className="widget-numbers insideContent">
                    <strong>

                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />

      <div className="row-fluid sideCardDesigns">
        <div className="col-md-12 col-xl-12">
          <div className="row py-2 mt-3  ">
            <div className="col">
              <div className="row py-2">
                <div className="col-md-12 col-lg-12">
                  <div className="mb-3 h-100 ">
                    <div className="card-header singleCard justify-content-between">
                      <div className="col-md-4">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal formHeading"><strong>Charger realtime status</strong>
                        </div>
                      </div>
                      <div className="">
                        <DateRangePicker
                          startDate={startDate}
                          startDateId="s_id"
                          endDate={endDate}
                          endDateId="e_id"
                          onDatesChange={({ startDate, endDate }) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                          }}
                          focusedInput={focusedInput}
                          onFocusChange={(e) => setFocusedInput(e)}
                          displayFormat="DD/MM/YYYY"
                          horizontalMargin={20}
                          isOutsideRange={isOutsideRange}
                        />

                        <button
                          className="dateBtn ml-2 "
                          type="button"
                          onClick={handleDateClick}
                        >
                          Go
                        </button>
                      </div>


                    </div>
                    {statsdata && statsdata.length > 0 ?
                      (<div className="App"> <Table data={statsdata} columns={columns}></Table></div>)
                      :
                      (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-sm-12 col-12">
            <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" href="/dashboard/stations">Back</a>
          </div>
          <hr className="my-4" />
        </div>

      </div>
    </div>

  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.station.isLoading,
    chargerstats: state.station.getChargerDowntime
  }
}
const mapDispatchToProps = dispatch => {
  return {
    chargerDowntime: (chargeboxid, start, end) => dispatch(getChargerDowntime(chargeboxid, start, end))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargerDetail);
