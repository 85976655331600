const initState={
  fetchOperator:'',
    fetchEnterprise:''
}
const opReducer=(state=initState,action)=>{
    switch (action.type) {
        case 'FETCH_OPERATOR':
          return {...state, fetchOperator: action.payload}
          case 'FETCH_ENTERPRISE':
            return {...state, fetchEnterprise: action.payload}  
        default:
          return state;
      }
}
export default opReducer;