import React, { useState } from "react";
import "./campaign.css";
import DatePicker from "react-datepicker";

const SendSms = (props) => {
  const [startDate, setStartDate] = useState(null);
  // const smsForm = {
  //   message: { value: "", isValid: true, message: "" },
  //   mobile: { value: "", isValid: true, message: "" },
  // };
  const [sendsms, setsendsms] = useState();
  const handleChange = (e) => {
    setsendsms({
      ...sendsms,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.sendsms(sendsms, startDate);
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div
            className="card shadow p-3 mb-5 rounded"
            style={{ height: "5.3rem" }}
          >
            <div className="row ">
              <div className="col">
                <div
                  className="card border-light mb-3 cardbg bg-custom-1"
                  style={{ height: "3rem" }}
                >
                  <div className="card-body">
                    <h5 className="card-title">Send New Message</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row-fluid">
        <div className="col">
          <div className="card shadow p-3 mb-5 rounded">
            <form className="form-sms" onSubmit={handleSubmit}>
              <div className="row ">
                <div className="col-4 text-center">To</div>
                <div className="col-8">
                  <div className="mb-3">
                    <div className="form-label">
                      <input
                        type="text"
                        id="mobile"
                        class="form-control"
                        name="mobile"
                        placeholder="Input mobile number"
                        maxLength="10"
                        onInput={maxLengthCheck}
                        onChange={handleChange}
                      />
                      {/* <span className="help-block text-danger">
                          {mobile.message}
                        </span> */}
                    </div>
                  </div>

                  <div className="form-check" style={{display:'inline'}}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label className="form-check-label" for="flexCheckDefault">
                      All ChargeGrid User
                    </label>
                  </div>
                  <div className="form-check" style={{display:'inline', marginInline: "25px"}}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label className="form-check-label" for="flexCheckDefault">
                      List Of Contact
                    </label>
                  </div>

                  <div class="form-group mt-3">
                    <label class="mr-4">Upload Contact:</label>
                    <input type="file" name="file" accept=".csv"></input>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="row ">
                <div className="col-4 text-center">Message</div>
                <div className="col-8">
                  <div className="mb-3">
                    <div className="form-label">
                      <input
                        type="text"
                        id="message"
                        class="form-control"
                        name="message"
                        placeholder="Type your message here"
                        onChange={handleChange}
                      />
                      {/* <span className="help-block text-danger">
                          {message.message}
                        </span> */}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="row ">
                <div className="col-4 text-center">Schedule</div>
                <div className="col-8">
                  <div class="form-row my-2">
                    <div class="col-8">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        dateFormat="Pp"
                        isClearable
                        placeholderText="Select Date & Time"
                      />
                    </div>
                    <div class="col-4">
                      <div className="col-xl-12 col-sm-6 col-12 float-left">
                        <button className="btn btn-primary btn-pill btn-wide float-right">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendSms;
