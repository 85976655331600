import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {getPaymenthistoryActions} from "../../../store/actions/customerAction"

class CustomerReport extends Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getCustomerReport(params.mobile)
    }
    render() {
       // console.log("inisdecustomer",this.props.getsinglecustomer)
        return (
        <>
          <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>View Customer Details</b>
                        </h4>

                        <div className="row rowpadding  justify-content-between mt-5">
                    <div className="col-md-6 col-sm-12 ">
                      <div className="col-12  formContainer">
                        <div className="col-12">
                          <label className="formLable" htmlFor="name">Name</label>
                          <div className="form-label-group ">
                                <input type="text" id="name" name="name" className="form-control control-label" placeholder="Full Name"  value={this.props.getsinglecustomer.name} disabled/>
                                <label htmlFor="name">Name</label>
                            </div>
                          <label className="formLable" htmlFor="mobile">Mobile Number</label>
                          <div className="form-label-group">
                                <input type="number" id="mobile" name="mobile" className="form-control control-label" placeholder="Mobile Number" value={this.props.getsinglecustomer.mobile} disabled />
                                <label htmlFor="mobile">Mobile Number</label>
                            </div>
                          <label className="formLable" htmlFor="email">Email ID</label>
                          <div className="form-label-group">
                                <input type="email" id="email" name="email" className="form-control control-label" placeholder="Email ID"  value={this.props.getsinglecustomer.email} disabled />
                                <label htmlFor="email">Email ID</label>
                            </div>
                          <label className="formLable" htmlFor="city">City</label>
                          <div className="form-label-group">
                                <input type="email" id="city" name="city" className="form-control control-label" placeholder="City"  value={this.props.getsinglecustomer.city} disabled />
                                <label htmlFor="city">City</label>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <div className="col-12  formContainer ">

                        <div className="col-12 ">

                          <label className="formLable" htmlFor="state">State</label>
                          <div className="form-label-group required" >
                                <input type="text" id="state" name="state" className="form-control control-label" placeholder="State" value={this.props.getsinglecustomer.state} disabled  />
                                <label htmlFor="state">State</label>
                            </div>

                          <label className="formLable" htmlFor="zipcode">Pin Code</label>
                          <div className="form-label-group required" >
                                <input type="text" id="zipcode" name="zipcode" className="form-control control-label" placeholder="Pin Code" value={this.props.getsinglecustomer.zipcode} disabled  />
                                <label htmlFor="zipcode">Pin Code</label>
                            </div>
                          <label className="formLable" htmlFor="address">Address</label>
                          <div className="form-label-group pb-3">
                                <input type="text" id="address" name="address" className="form-control control-label" placeholder="Address" value={this.props.getsinglecustomer.address} disabled />
                                <label htmlFor="address">Address</label>
                            </div>
                            <br/>
                            <br/>
                            <br/>

                        </div>
                      </div>
                    </div>
                  </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/customers">Back</a>
                        </div>
                        <hr className="my-4" />
                    </div>
                    </div>
                </div>
            </div>



                {/* <div className="row">
                    < div className="col-md-4 col-xl-4 col-sm-6 col-12">
                        <div className="card border-light mb-3 widget-content cardbg">
                            <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <div className="stats">Name</div>
                                    </div>
                                    <div className="widget-content-right">
                                        <div className="widget-numbers text-success">{props.list != null ? props.list.stationid : 0}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-xl-4 col-sm-6 col-12">
                        <div className="card border-light mb-3 widget-content cardbg ">
                            <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <div className="stats">Email</div>
                                    </div>
                                    <div className="widget-content-right">
                                        <div className="widget-numbers text-success">{props.list != null ? props.list.name : 0}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-xl-4 col-sm-6 col-12">
                        <div className="card border-light mb-3 widget-content cardbg ">
                            <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <div className="stats">Wallet balance</div>
                                    </div>
                                    <div className="widget-content-right">
                                        <div className="widget-numbers text-success">{props.list != null ? props.list.name : 0}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row-fluid">
                    <div className="col-md-3 col-xl-3 col-sm-6 col-12">
                        <div className="card mb-3 shadow p-3 mb-5 bg-white rounded">
                            <div className="row ">
                                <div className="col">
                                    <div className="card border-dark mb-3 text-center cardbg bg-custom-1" >
                                        <div className="card-body">
                                            <h5 className="card-title">Total Amount</h5>
                                            <div className="card no-shadow rm-border widget-chart text-center bg-custom-1">

                                                <span style={{ color: "#1E295D" }}>
                                                    <i className="fas fa-rupee-sign fa-3x"></i>
                                                </span>


                                            </div>
                                            <div className="d-block p-3 text-center ">
                                                <strong>12000</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="card border-dark mb-3 text-center cardbg bg-custom-1" >
                                        <div className="card-body">
                                            <h5 className="card-title">Lifetime Sessions</h5>
                                            <div className="card no-shadow rm-border widget-chart text-center bg-custom-1">
                                                <div className="icon-wrapper">
                                                    <img src={process.env.PUBLIC_URL + '/images/fleet_logo/lighting.svg'} alt="" />
                                                </div>
                                            </div>
                                            <div className="d-block p-3 text-center ">
                                                <strong>07</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="card border-dark mb-3 text-center cardbg bg-custom-1" >
                                        <div className="card-body">
                                            <h5 className="card-title">Total Energy Consumed</h5>
                                            <div className="card no-shadow rm-border widget-chart text-center bg-custom-1">
                                                <div className="icon-wrapper">
                                                    <img src={process.env.PUBLIC_URL + '/images/fleet_logo/energetic.svg'} alt="" />
                                                </div>
                                            </div>
                                            <div className="d-block p-3 text-center ">
                                                <strong>10 kWh</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="card border-dark mb-3 text-center cardbg bg-custom-1" >
                                        <div className="card-body">
                                            <h5 className="card-title">Current Plan</h5>
                                            <div className="card no-shadow rm-border widget-chart text-center bg-custom-1">
                                                <div className="icon-wrapper">
                                                    <img src={process.env.PUBLIC_URL + '/images/fleet_logo/filled.svg'} alt="" />
                                                </div>
                                            </div>
                                            <div className="d-block p-3 text-center ">
                                                <strong>Pay as You Go</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
         
        </>
    )
}
}

const mapStateToProps = function (state) {
    return {
        getsinglecustomer: state.customer.paymenthistory,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCustomerReport: (mobile) => dispatch(getPaymenthistoryActions(mobile))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerReport));
