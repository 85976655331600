import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getSubscriptionPlanByCreatorAction } from '../../../store/actions/subscriptionAction';
import { getRFIDListAction, saveFleetRFIDAction } from '../../../store/actions/customerAction';
import "../report/table.css";
import FleetTable from './fleetReactTable';
import Loader from 'react-loader-spinner';


const FleetRFID = (props) => {
    useEffect(() => {
        const { planname } = JSON.parse(localStorage.getItem('user'))
        let pname = planname
        if (pname === "") {
            pname = "All"
        }
        props.fetSubscriptionplan(pname);

        props.getRFIDdata();
    }, []);

    const [fleetData, setFleetData] = useState();
    const [fleetList, setfleetList] = useState([]);


    const fleetdata = props.rfidlist && props.rfidlist

    const columns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'firstname'
            },

            {
                Header: 'RFID Number',
                accessor: 'rfidno'
            },

            {
                Header: 'Tag UID',
                accessor: 'taguid'
            },
            {
                Header: 'Key UID',
                accessor: 'keyuid'
            },
            {
                Header: 'TopUp Amount',
                accessor: 'topup'
            },
        ],
        []
    );

    const handleChange = (e) => {
        setFleetData({
            ...fleetData,
            [e.target.name]: e.target.value.trim(),
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("Fleet RFID Added");
        // console.log("Save RFID FLEET", fleetData, fleetList);
        props.saveFleetRFID(fleetData, fleetList);
    };

    // console.log("Save", fleetData, fleetList);
    return (
        <div className="container-fluid">
            <form className="form" onSubmit={handleSubmit} autoComplete="off">
                <div className='row'>
                    <div className='col'>
                        <div className="card">
                            <div className="card-header">
                                <div className="col-md-9 text-left">
                                    Select Fleet to bind RFID
                                </div>

                                <div className="col text-right">
                                    <select className="form-control" id="name" name="name" required onChange={handleChange}>
                                        <option value="" >Select Fleet</option>
                                        {props.sublistdata && props.sublistdata.slice(1,).map(planname => {
                                            return planname !== "Pay as you go" && planname.category === "Fleet" ?
                                                <option key={planname.id} value={planname.name} >{planname.name}</option> : ''
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className="col-md-12 col-lg-12">
                        <div className="mb-3 h-100 card">
                            <div className="card-header">
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                    <strong>Location Details</strong>
                                </div>
                            </div>

                            {!fleetdata.length ?
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                                :
                                (<div className="App"> <FleetTable data={fleetdata} columns={columns}   > </FleetTable></div>)}

                        </div>
                    </div>

                </div>

                <div className="row mt-3">
                    <div className="col-xl-6 col-sm-6 col-12">
                        <a
                            className="btn btn-primary btn-pill btn-wide text-uppercase float-left"
                            href="/dashboard/fleet"
                        >
                            Back
                        </a>
                    </div>

                    <div className="col-xl-6 col-sm-6 col-12 float-right">
                        <button className="btn btn-primary btn-pill btn-wide text-uppercase float-right">
                            {!props.loading ? "Save Group Fleet RFID" : "Please wait..."}
                        </button>
                    </div>
                    <hr className="my-4" />
                </div>
            </form>

        </div>

    )
}


const mapStateToProps = function (state) {
    return {
        loading: state.fleet.isLoading,
        sublistdata: state.subscriptionplan.fetcSubscriptionplan,
        rfidlist: state.customer.fetchAllRfid,
        savegrouprfid: state.customer.savefleetrfid,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetSubscriptionplan: (planname) => dispatch(getSubscriptionPlanByCreatorAction(planname)),
        getRFIDdata: () => dispatch(getRFIDListAction()),
        saveFleetRFID: (rfid, rfidlist) => dispatch(saveFleetRFIDAction(rfid, rfidlist))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(FleetRFID);


