import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSingleConnectorActions } from "../../../../store/actions/ctAction";

class ViewSingleConnectorDetails extends Component {
    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getSingleConnector(params.id)

    }
    render() {
        // console.log("utildetails",this.props.connectorsingledetail);
        return (
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading"><b>View Connector Type</b></h4>

                        <form className="form mt-4" onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="row rowpadding  justify-content-between">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer">
                                        <div className="col-12">
                                            <label className="formLable" htmlFor="code">Connector Code</label>
                                            <div className="form-label-group ">
                                    <input type="text" id="code" name="code" className="form-control control-label" value={this.props.connectorsingledetail.code} disabled    />
                                    <label htmlFor="code">Connector Code</label>
                                </div>
                                            <label className="formLable" htmlFor="name">Connector Name</label>
                                            <div className="form-label-group ">
                                    <input type="text" id="name" name="name" className="form-control control-label" value={this.props.connectorsingledetail.name} disabled    />
                                    <label htmlFor="name">Connector Name</label>
                                </div>

                                            <label className="formLable" htmlFor="protocol">Communication protocol</label>
                                            <div className="form-label-group ">
                                    <input type="text" id="protocol" name="protocol" className="form-control control-label" value={this.props.connectorsingledetail.protocol} disabled    />
                                    <label htmlFor="protocol">Communication protocol</label>
                                </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">

                                        <div className="col-12 ">

                                            <label className="formLable" htmlFor="coonectortype">Connector Type</label>
                                            <div className="form-label-group ">
                                    <input type="text" id="connectortype" name="connectortype" className="form-control control-label" value={this.props.connectorsingledetail.connectortype} disabled    />
                                    <label htmlFor="connectortype">Connector Type</label>
                                </div>

                                            <label className="formLable" htmlFor="powertype">Power Type</label>
                                            <div className="form-label-group ">
                                    <input type="text" id="powertype" name="powertype" className="form-control control-label" value={this.props.connectorsingledetail.powertype} disabled    />
                                    <label htmlFor="powertype">Power Type</label>
                                </div>

                                            <label className="formLable" htmlFor="power">Power(KW)</label>
                                            <div className="form-label-group ">
                                    <input type="text" id="power" name="power" className="form-control control-label" value={this.props.connectorsingledetail.power} disabled    />
                                    <label htmlFor="power">Power</label>
                                </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-md-3 col-lg-3 ">
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase" href="/dashboard/assets">Back</a>
                            </div>

                        </div>
                        </form>

                    </div>
                </div>
            </div>
        )

    }

}

const mapStateToProps = function (state) {
    return {
        connectorsingledetail: state.ct.fetchSingleConnector,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleConnector: (id) => dispatch(getSingleConnectorActions(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleConnectorDetails));           