import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getPriceByCreatorAction } from "../../../store/actions/priceAction";
import { editStationAction, updatePriceGroupAction } from "../../../store/actions/stationAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StationGroupView = (props) => {
  const { stationid, location } = useParams()
  const [price, setPrice] = useState('');
  useEffect(() => {
    props.EditStationByName(stationid, location);
    props.fetPrice()
  }, []);
  const onChangeHandler = (e) => {
    setPrice(e.target.value);
  };

  const handleClick = (event) => {
    // console.log(event.chargeboxid);
    // console.log(price)
    props.updatePriceGroup(event.chargeboxid, price)

  };
  const notify = () => {
    toast("Price group created");
  };
  // console.log(props.list)
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-xl-12">


          <div className="row gy-5 ">
            <div className="col-md-4 col-xl-4 col-sm-4 col-12">
              <div className="sideCardDesigns border-light mb-3 widget-content  ">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper p-3">
                    <div className="widget-content-left">
                      <div className="insideHeading">Station ID</div>
                    </div>
                    <div className="widget-content-right">
                      <div className="widget-numbers insideContent">
                        <strong>
                          {props.list != null ? props.list.stationid : 0}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-4 col-sm-4 col-12">
              <div className="sideCardDesigns border-light mb-3 widget-content ">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper p-3">
                    <div className="widget-content-left">
                      <div className="insideHeading">Location Name</div>
                    </div>
                    <div className="widget-content-right">
                      <div className="widget-numbers insideContent">
                        <strong>
                          {props.list != null ? props.list.name : 0}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-4 col-sm-4 col-12">
              <div className="sideCardDesigns border-light mb-3 widget-content ">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper p-3">
                    <div className="widget-content-left">
                      <div className="insideHeading">Commission Date</div>
                    </div>
                    <div className="widget-content-right">
                      <div className="widget-numbers insideContent">
                        <strong>
                          {props.list != null ? props.list.commisiondate : 0}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      {props.list && props.list.chargers.map((charger) =>
        <div className="row " key={charger.id}>
          <div className="col-xs-6 col-sm-6 col-lg-6  ">
            <ul className="sideCardDesigns pb-4">
              <li className="list-group-item singleCard">Charge Box Id</li>
              <li className="list-group-item singleCard" >Enterprise</li>
              <li className="list-group-item singleCard">Make</li>
              <li className="list-group-item singleCard">model</li>
              <li className="list-group-item singleCard">Power Type</li>
              <li className="list-group-item singleCard">status</li>
              <li className="list-group-item singleCard">Price Group Name</li>
            </ul>
          </div>
          <div className="col-xs-6 col-sm-6 col-lg-6">
            <ul className="sideCardDesigns" >
              <li className="list-group-item singleCard">{charger.chargeboxid}</li>
              <li className="list-group-item singleCard">{charger.enterprise != "" ? "Tariff" : "N/A"}</li>
              <li className="list-group-item singleCard">{charger.make}</li>
              <li className="list-group-item singleCard">{charger.model}</li>
              <li className="list-group-item singleCard">{charger.powertype}</li>
              <li className="list-group-item singleCard">{charger.status}</li>
              <li className="list-group-item singleCard">
                {charger.tariff != 0 ? charger.priceplan :
                  <div className="row">
                    <div className="col-xs-8 col-sm-8 col-lg-8 mr-1">
                      <div className="form-label-group">
                        <select className="form-control-1 border-radius" id="price" name="price" onChange={onChangeHandler}>
                          <option value="">Select Price </option>
                          {props.pricelistdata && props.pricelistdata.map(p =>
                            <option id={p.id} value={p.name}>{p.name}</option>
                          )}
                        </select>

                      </div>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3">
                      <button className="btn columnsBtn btn-sm" onClick={() => { handleClick(charger); notify(); }}>{!props.pricegrouploading ? 'Add Price' : 'Please wait...'}</button>
                    </div>
                    <ToastContainer style={{ top: '3rem' }} />


                  </div>
                }
              </li>
            </ul>
          </div>

        </div>

      )}
      <div className="row">
        <div className="col-xl-12 col-sm-12 col-12">
          <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/stations">Back</a>
        </div>
        <hr className="my-4" />
      </div>


    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.station.editstationbyname,
    loading: state.price.isLoading,
    pricelistdata: state.price.fetchPriceList,
    pricegrouploading: state.station.isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    EditStationByName: (stationid, location) => dispatch(editStationAction(stationid, location)),
    fetPrice: () => dispatch(getPriceByCreatorAction()),
    updatePriceGroup: (chargeboxid, priceplan) => dispatch(updatePriceGroupAction(chargeboxid, priceplan))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StationGroupView);

