import React from 'react';
import LoginComponentPage from './components/auth/login';
import Switch from 'react-bootstrap/esm/Switch';
import { Route, Redirect } from 'react-router-dom';
import UpdatePassComponent from './components/auth/updatepass';
// import OTPValidate from './components/auth/otp'
import DashboardLayout from './components/dashboard/layout';
import WelcomePage from './components/landing/welcome';
import Contact from "./components/landing/contact"; 
import ForgotPasswordOTP from './components/auth/forgotPassword/otp'
import ForgotPassword from './components/auth/forgotPassword/forgotPassword';
import Help from "./components/landing/help";
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )} />
)
function App(props) {
  var isLoggedIn = localStorage.getItem("user");
   return (
    
    <div style={{height:'100%'}}>
    {/* Components */}
      <Switch>
      {/* <Route exact path="/"> {isLoggedIn ? <Redirect to="/dashboard" /> : <LoginComponentPage />}</Route> */}
        <Route exact path="/" component={LoginComponentPage} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/changepassword" component={UpdatePassComponent} />
        {/* <Route exact path="/otpvalidate" component={OTPValidate} /> */}
        <Route exact path="/forgotpassotp" component={ForgotPasswordOTP} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <PrivateRoute  path="/dashboard" component={DashboardLayout} />
        
      </Switch>

     </div>
  );
}

const mapStateToProps = (state) =>{
  return{
    isAuthenticated:state.auth.user
  }
}
export default connect(mapStateToProps)(App)