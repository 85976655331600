
import React, { useEffect, useState } from "react";
import { useParams,Redirect ,useHistory} from "react-router-dom";
import { connect } from "react-redux";
import { editHubUserByNumber,getSingleHubUserDetails, getHubroles, getAllprivileges } from "../../../store/actions/hubActions";
import { cityAction, countryAction, stateAction } from "../../../store/actions/commonAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


let arr = [];
const EditHubAccess = (props) => {
    
    const { mobile } = useParams()
    const history = useHistory();
    const [privilage,setPrivilage] = useState()


    const [data, setData] = useState({
        name: "",
        role: "",
        country: "",
        city: "",
        state: "",
        privileges: [],
       

    });

    useEffect(() => {
        props.getSingleHubUser(mobile);
        props.countrylist();
        props.rolelist();
        props.privilegelist();
    }, []);

     useEffect(() => {
        setPrivilage(props.roles)
    }, [props.roles])

     useEffect(() => {
        setData(props.fetchSingleUserDetails)
    }, [props.fetchSingleUserDetails])


    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "country") {
            props.statelist(e.target.value);
        }
        if (e.target.name === "state") {
            props.citylist(e.target.value);
        }
        if (e.target.name === "role") {
            props.privilegelist(e.target.id);
        }
      
    }


   const getChecked = (item) => {
    let privilagesss = data && data.privileges.filter((itr) => itr.name === item)
    return privilagesss.length !== 0 ? true : false
    }

    const handelPrivilegesChange = (e) => {
        const target = e.target;
        let pvalue = target.value;
        let pid = target.id;
        arr = data.privileges;

        if (target.checked) {
            arr.push({ code: pid, name: pvalue });
            // console.log("push", arr);
        } else {
            const removeIndex = arr.findIndex((item) => item.name === pvalue);
            arr.splice(removeIndex, 1);
            // console.log("slice", arr);
        }
        setData({
            ...data,
            [arr]: data[arr]
          })
    };

   

    const handleSubmit = (e) => {
        props.updateSingleHubUser(mobile, data);
        alert('Update Successfully');
        history.push("/dashboard/hub")
    };

    return (


        <>
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                          <b>Edit Hub User Access</b>
                        </h4>

                        <form className="form mt-4" onSubmit={handleSubmit}>
                            <div className="row rowpadding  justify-content-between my-5">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">
                                        <div className="col-12 pb-2">
                                        <label className="formLable" htmlFor="name">Name</label>
                                        <div className="form-label-group">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control control-label"
                                                value={data.name}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="name">Enter Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="phone">Phone Number</label>
                                        <div className="form-label-group">
                                            <input
                                                type="number"
                                                id="phone"
                                                name="phone"
                                                className="form-control control-label"
                                                value={mobile} disabled
                                            />
                                            <label htmlFor="phone">Phone Number</label>
                                        </div>

                                        <label className="formLable" htmlFor="country">Country</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="country" name="country" label={data.country} onChange={handleChange}>
                                                {props.countries && props.countries.map((country) => <option key={country.code} value={country.code}>{country.name}</option>)}
                                            </select>
                                        </div>
                                        <label className="formLable" htmlFor="role">Roles</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="role" name="role" onChange={handleChange}>
                                                <option>{data.role}</option>
                                                {props.privileges && props.privileges.map((r) => <option key={r.key} value={r.name}>{r.name}</option>)}
                                            </select>
                                        </div>

                                       
                                  
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">
                                        <div className="col-12 pb-5">

                                        <label className="formLable" htmlFor="state">State</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="state" name="state"  onChange={handleChange}>
                                                <option>{data.state}</option>
                                                {props.states && props.states.map((st) => <option key={st.code} value={st.name}>{st.name}</option>)}
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="city">City</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="city" name="city" onChange={handleChange} >
                                                <option>{data.city}</option>
                                                {props.cities && props.cities.map((c) => <option key={c.id} value={c.name}>{c.name}</option>)}
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="privileges">Privileges</label>
                                        <div className=" containerAme p-2 pt-1">
                                            {privilage && privilage.map((pre,i) => (
                                                <div className="form-check form-check-inline border badge badge-pill text py-1 px-2" key={pre.i} >
                                                <input className="form-check-input" type="checkbox" name="privileges" id={pre.key} value={pre.name} checked={getChecked(pre.name)}   onChange={handelPrivilegesChange} />
                                                <label className="form-check-label" htmlFor={pre.key}>{pre.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                        
                                        </div>

                                   
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-md-6 col-lg-6 ">
                                    <a
                                        className="btn columnsBtn btn-pill btn-wide text-uppercase"
                                        href="/dashboard/hub"
                                    >
                                        Back
                                    </a>
                                </div>

                                <div className="col-md-6 col-lg-6 ">
                                    <button
                                        className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"
                                    >
                                        Update
                                    </button>
                                </div>
                                <ToastContainer style={{ top: '3rem' }} />
                            </div>
                        </form>
                      
                            </div>

                            
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = function (state) {
    return {
        countries: state.common.countryList,
        states: state.common.stateList,
        cities: state.common.cityList,
        roles: state.hub.gethubroles,
        privileges: state.hub.getHubprivileges,
        fetchSingleUserDetails: state.hub.singleHubUserDetails,
        updateSingleHubUser: state.hub.updateSingleHubUserDetails
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

        countrylist: () => dispatch(countryAction()),
        statelist: (countrycode) => dispatch(stateAction(countrycode)),
        citylist: (statecode) => dispatch(cityAction(statecode)),
        rolelist: () => dispatch(getHubroles()),
        privilegelist: () => dispatch(getAllprivileges()),
        getSingleHubUser: (mobile) => dispatch(getSingleHubUserDetails(mobile)),
        updateSingleHubUser: (mobile,data) => dispatch(editHubUserByNumber(mobile,data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditHubAccess);
