
const initState = {
  countryList: '',
  stateList: '',
  cityList: '',
  roleList: '',
  rolelistbynp: '',
  privilegeList: '',
  fetchsigninotp: '',
  validatesigninotp: '',
  forgotpassword: '',
  resendOtp:'',
}
const commonReducer = (state = initState, action) => {
  switch (action.type) {
    case 'COUNTRY_LIST':
      return { ...state, countryList: action.payload }
    case 'STATE_LIST':
      return { ...state, stateList: action.payload }
    case 'CITY_LIST':
      return { ...state, cityList: action.payload }
    case 'ROLE_LIST':
      return { ...state, roleList: action.payload }
    case 'ROLE_LIST_BY_NP':
      return { ...state, roleListByNp: action.payload }
    case 'PRIVILEGE_LIST':
      return { ...state, privilegeList: action.payload }
    case 'LOGIN_OTP':
      return { ...state, fetchsigninotp: action.payload }
    case 'LOGIN_OTP_VALIDATE':
      return { ...state, validatesigninotp: action.payload }
    case 'FORGOT_PASSWORD':
      return { ...state, forgotpassword: action.payload }
    case 'RESEND_OTP':
      return { ...state, resendOtp: action.payload }
    default:
      return state;
  }
}
export default commonReducer;