const initState = {
    isLoading: false,
    fetchTransactionList: '',
    fetchTransactionData: '',
    releasecharger: '',
    releasestucktransaction: '',
    livechargingdata: '',
    fetchinitialize: '',
    updateinitialize: ''
}
const transactionReducer = (state = initState, action) => {
    switch (action.type) {
        case 'TRANSACTION_LOADING':
            return { ...state, isLoading: true }
        case 'FETCH_TRANSACTION_LIST':
            return { ...state, fetchTransactionList: action.payload, isLoading: false }
        case 'FETCH_TRANSACTION_DATA':
            return { ...state, fetchTransactionData: action.payload, isLoading: false }
        case 'RELEASE_CHARGER':
            return { ...state, releasecharger: action.payload, isLoading: false }
        case 'RELEASE_STUCK_TRANSACTION':
            return { ...state, releasestucktransaction: action.payload, isLoading: false }
        case 'LIVE_CHARGING_DATA':
            return { ...state, livechargingdata: action.payload, isLoading: false }
        case 'FETCH_INITIALISE_TRANSACTION':
            return { ...state, fetchinitialize: action.payload, isLoading: false }
        case 'UPDATE_INITIALISE_TRANSACTION':
            return { ...state, updateinitialize: action.payload, isLoading: false }
        default:
            return state;
    }
}
export default transactionReducer;