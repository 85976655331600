import React, { useState }  from 'react';
import './campaign.css';
import DatePicker from "react-datepicker";


const SendEmail=(props)=> {
  const [startDate, setStartDate] = useState(null);
  const [sendemail,setsendemail]=useState();
  const handleChange=(e)=>{
    setsendemail({
      ...sendemail,
      [e.target.name]: e.target.value.trim()
    });       
  }
  const handleSubmit = (e) => {
    e.preventDefault();
   props.sendsms(sendemail,startDate)
  };  
    return (
      <>
  <div className="row-fluid">
  <div className="col">
  <div className="card shadow p-3 mb-5 rounded" style={{height:'5.3rem'}}>
  <div className="row ">
  <div className="col">
  <div className="card border-light mb-3 cardbg bg-custom-1" style={{height:'3rem'}}>
  <div className="card-body">
    <h5 className="card-title">Send New Email</h5>
  </div>
  </div>
  </div> 
  </div>
 
  </div>
  </div>       
  </div>

    <div className="row-fluid">
    <div className="col">
    <div className="card shadow p-3 mb-5 rounded">
    <form className="form-signin" onSubmit={handleSubmit}>
    <div className="row ">
    <div className="col-4 text-center">Email address</div>
    <div className="col-8">
    <div className="form-label">
         <input type="email" id="email" name="email" className="form-control" placeholder="Email address" onChange={handleChange} />
        {/* <span className="help-block text-danger">{email.message}</span> */}
        </div>
        
        <div className="form-check" style={{display:'inline'}}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label className="form-check-label" for="flexCheckDefault">
                      All ChargeGrid User
                    </label>
                  </div>
                  <div className="form-check" style={{display:'inline', marginInline: "25px"}}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label className="form-check-label" for="flexCheckDefault">
                      List Of Contact
                    </label>
                  </div>

                  <div class="form-group mt-3">
                    <label class="mr-4">Upload Contact:</label>
                    <input type="file" name="file" accept=".csv"></input>
                  </div>
                </div>
              </div>
    <br />
    <br />
    <div className="row ">
    <div className="col-4 text-center">Subject</div>
    <div className="col-8">
    <div className="form-label required">
         <input type="text" id="subject" name="subject" className="form-control" placeholder="Subject"  onChange={handleChange} />
        {/* <span className="help-block text-danger">{subject.message}</span> */}
        </div>
        </div>
    </div>
    <br />
    <br />
    <div className="row ">
    <div className="col-4 text-center">Input your message here</div>
    <div className="col-8">
    <div className="mb-3">
    <div className="form-label required">
        <input type="text" id="message" className="form-control control-label" name="message" placeholder="message" onChange={handleChange}/>
        {/* <span className="help-block text-danger">{message.message}</span> */}
        </div>
    </div>
    </div>
    </div>
    <br />
                <br />
                <div className="row ">
                  <div className="col-4 text-center">Schedule</div>
                  <div className="col-8">
                  <div class="form-row my-2">
                    <div class="col-8">
                    <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            showTimeSelect
                            dateFormat="Pp"
                            isClearable
                            placeholderText="Select Date & Time"
                            />
                        </div>
                        <div class="col-4">
                       
                <button
                  className="btn btn-primary btn-wide btn-pill float-right"
                >
                  Send Email
                </button>

                        </div>
                    </div>  
                  </div>
                </div>
    </form>
    {/* <div className="col-xl-12 col-sm-6 col-12 float-left"> 
          <button className="btn btn-primary btn-pill float-right" >Send Email</button>
    </div> */}
    </div>
    
    </div>    
    </div>


      </>
    
    )
}
export default SendEmail;