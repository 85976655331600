import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { cityAction, countryAction, privilegeAction, roleAction, stateAction } from "../../../store/actions/commonAction";
import { getUserDetailsAction, updateAction } from "../../../store/actions/userAction";

var arr = [];
class EditUser extends Component {
  state = {
    user: {
      username: '',
      firstname: '',
      lastname: '',
      phone: '',
      role: '',
      privileges: [],
      organization: '',
      organizationtype: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      createdat: '',
      accountstatus: '',
      ispasswordupdated: '',
      error: '',
    }
  }
  componentDidMount() {
    const { match: { params } } = this.props;
    const token = '';
    this.props.getUser(params.userId, token)
    this.props.rolelist(this.state);
    this.props.privilegelist(this.state);
    this.props.countrylist(this.state);
    this.props.statelist(this.state);
    this.props.citylist(this.state);


  }
  componentDidUpdate(previousProps, previousState) {
    if (previousProps.userdetail !== this.props.userdetail) {
      this.setState({
        user: this.props.userdetail,
        privilegeValue: this.props.userdetail.privileges
      })
    }
  }
  handleCheckChange = (e) => {
    const target = e.target;
    let pvalue = target.value;
    let pid = target.id
    arr = this.state.privilegeValue
    if (target.checked) {
      arr.push({ key: pid, name: pvalue })
    } else {
      const removeIndex = arr.findIndex(item => item.name === pvalue);
      // remove object
      arr.splice(removeIndex, 1);
    }
    this.setState({
      privilegeValue: arr
    })
  }
  getChecked(item) {
    let status = this.props.userdetail && this.props.userdetail.privileges.filter(pri => pri.name === item).map(st => { return true });
    return status.length != 0 ? true : false;
  }
  getRedioChecked(role) {
    return this.props.userdetail.role === role ? true : false;
  }
  handleChange = (e) => {
    e.persist();
    this.setState(prevState => ({
      user: { ...prevState.user, [e.target.name]: e.target.value }
    }))
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { match: { params } } = this.props;
    this.props.updateUser(params.userId, this.state.user)
  }
  render() {
    if (this.props.modifiedUser && this.props.modifiedUser.error === '') {
      return <Redirect to='/dashboard/users' />;
    }
    console.log("sdfS", this.state.user.firstname)
    return (
      <div className="container-fluid">
        <div className="sideCardDesigns card-signin my-5">
          <div className="card-body">
            <h4 className="text-center"><b>Edit User</b></h4>
            <hr className="my-4" />
            <form className="form-signin" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input type="email" className="form-control" name="username" value={this.state.user.username} onChange={this.handleChange} disabled />
                    <label>Email address</label>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input type="password" className="form-control" name="password" value={this.state.user.password} disabled />
                    <label >Password</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input type="text" className="form-control" name="firstname" value={this.state.user.firstname} onChange={this.handleChange} />
                    <label>First Name</label>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input type="text" className="form-control" name="lastname" value={this.state.user.lastname} onChange={this.handleChange} />
                    <label>Last Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <h5>Select User Role</h5>
                  <hr />
                  {this.props.roles && this.props.roles.map((r) =>
                    <div className="custom-control custom-radio">
                      <input type="radio" className="custom-control-input" id={r.id} name="role" value={r.name} onChange={this.handleChange} defaultChecked={this.getRedioChecked(r.name)} />
                      <label className="custom-control-label" htmlFor={r.id}>{r.name}</label>
                    </div>
                  )}

                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <h5>Select Privileges</h5>
                  <hr />
                  {this.props.privileges && this.props.privileges.map((privilege) =>
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" id={privilege.key} name="privileges" className="custom-control-input" onChange={this.handleCheckChange} value={privilege.name} defaultChecked={this.getChecked(privilege.name)} />
                      <label className="custom-control-label" htmlFor={privilege.key}>{privilege.name}</label>
                    </div>
                  )}
                </div>
              </div>
              <hr className="my-4" />
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input type="text" name="organization" className="form-control" value={this.state.user.organization} onChange={this.handleChange} />
                    <label>Organization Name</label>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <select class="form-control border-radius" name="organizationtype" value={this.state.user.organizationtype} onChange={this.handleChange}>
                      {this.state.user.organizationtype ? <option>{this.state.user.organizationtype}</option> : ''}
                      <option>Private Ltd Company</option>
                      <option>Public Ltd Company</option>
                      <option>Sole Proprietorship</option>
                      <option>Cooperatives</option>
                      <option>Limited Liability Partnership(LLP)</option>
                    </select>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input type="text" name="address" className="form-control" value={this.state.user.address} onChange={this.handleChange} />
                    <label>Organization Address</label>
                  </div>
                </div>

                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input type="text" name="phone" className="form-control" value={this.state.user.phone} onChange={this.handleChange} />
                    <label>Phone Number</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <select className="form-control border-radius" name="country" onChange={this.handleChange} value={this.state.user.country}>
                      {this.state.user.country ? <option>{this.state.user.country}</option> : ''}
                      {this.props.countries && this.props.countries.map((country) => <option key={country.code} value={country.name}>{country.name}</option>)}
                    </select>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <select className="form-control border-radius" name="state" onChange={this.handleChange} value={this.state.user.state}>
                      {this.state.user.state ? <option>{this.state.user.state}</option> : ''}
                      {this.props.states && this.props.states.map((st) => <option key={st.code} value={st.name}>{st.name}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <select className="form-control border-radius" name="city" onChange={this.handleChange} value={this.state.user.city}>
                      {this.state.user.city ? <option>{this.state.user.city}</option> : ''}
                      {this.props.cities && this.props.cities.map((city) => <option key={city.id} value={city.name}>{city.name}</option>)}
                    </select>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input type="text" name="zipcode" className="form-control" placeholder="Enter the zipcode" value={this.state.user.zipcode} onChange={this.handleChange} />
                    <label >Zip Code</label>
                  </div>
                </div>
              </div>
              <hr className="my-4" />
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <a className="btn btn-primary btn-pill btn-wide text-uppercase float-left" href="/dashboard/access">Back</a>
                </div>

                <div className="col-xl-6 col-sm-6 col-12 float-right">
                  <button className="btn btn-primary btn-pill btn-wide text-uppercase float-right">Update  User</button>

                </div>
                <hr className="my-4" />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = function (state) {
  return {
    userdetail: state.users.fetchUserDetail,
    roles: state.common.roleList,
    privileges: state.common.privilegeList,
    countries: state.common.countryList,
    states: state.common.stateList,
    cities: state.common.cityList,
    modifiedUser: state.users.updateUser

  }
}
const mapDispatchToProps = dispatch => {
  return {
    getUser: (id, token) => dispatch(getUserDetailsAction(id, token)),
    rolelist: (role) => dispatch(roleAction(role)),
    privilegelist: (privilege) => dispatch(privilegeAction(privilege)),
    countrylist: () => dispatch(countryAction()),
    statelist: () => dispatch(stateAction()),
    citylist: () => dispatch(cityAction()),
    updateUser: (id, user) => dispatch(updateAction(id, user))

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditUser));
