import React, { useEffect, useState  } from "react";
import { useParams, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getQRcodeList,getFleetOperatorList,getBatteryTypeList,getVehicleNoList,getVehicleTypeList,getAllHubListData,saveVehicleAndQrcode } from "../../../../store/actions/hubActions";
import Select from "react-select";



const BindingQRcode = (props) => {
    const history = useHistory();

    const [isDisabled, setisDisabled] = useState(false) 
    const [isLoading, setisLoading] = useState(false) 
    const [isClearable, setisClearable] = useState(true) 
    const [isRtl, setisRtl] = useState(false) 
    const [isSearchable, setisSearchable] = useState(true) 
    const [selectedQrcode, setselectedQrcode] = useState(null) 
    const [selectedVehicleNo, setselectedVehicleNo] = useState(null) 
    const [selectedBatteryType, setselectedBatteryType] = useState(null) 
    const [selectedVehicleType, setselectedVehicleType] = useState(null) 
    const [selectedFleet, setselectedFleet] = useState(null) 
    const [selectedHubList, setselectedHubList] = useState(null) 



    
    useEffect(() => {
        props.getQRcode()
        props.getVehicleList()
        props.getBatteryType()
        props.getFleetList()
        props.getVehicleType()
        props.getHubListData()
    }, []);


    const allQRcode = props.fetchQRcode && props.fetchQRcode
    const allVehicleList = props.fetchVehicleNo && props.fetchVehicleNo
    const allBattertList = props.fetchBatteryType && props.fetchBatteryType
    const allVehicleType = props.fetchVehicleType && props.fetchVehicleType
    const allFleetList = props.fetchFleetOperator && props.fetchFleetOperator
    const allHubList = props.fetchHubListData && props.fetchHubListData

    // console.log("qrcode---->",allQRcode)
    // console.log("vehiclelist---->",allVehicleList)


    var optionQRCodeData = [];
    if(allQRcode && typeof allQRcode !== undefined && allQRcode!==""){
        allQRcode.forEach(element => {
            // if(!element.status || element.status == false || element.status ==="false"){
                var tempData = {label: element.qrcodeno, value:element.id};
                optionQRCodeData.push(tempData);
            // }
           
        });
    }
    var optionVehicleNoData = [];
    if(allVehicleList && typeof allVehicleList !== undefined && allVehicleList!==""){
        allVehicleList.forEach(element => {
            // if(!element.status || element.status == false || element.status ==="false"){
                var tempData = {label: element.vehiclenumber, value:element.id};
                optionVehicleNoData.push(tempData);
            // }
        });
    }
    var optionBatteryData = [];
    if(allBattertList && typeof allBattertList !== undefined && allBattertList!==""){
        allBattertList.forEach(element => {
            var tempData = {label: element.batterykwh, value:element.id};
            optionBatteryData.push(tempData);
        });
    }
    var optionVehicleTypeData = [];
    if(allVehicleType && typeof allVehicleType !== undefined && allVehicleType!==""){
        allVehicleType.forEach(element => {
            var tempData = {label: element.vehicletype, value:element.id};
            optionVehicleTypeData.push(tempData);
        });
    }
    
    var optionFleetData = [];
    if(allFleetList && typeof allFleetList !== undefined && allFleetList!==""){
        allFleetList.forEach(element => {
            var tempData = {label: element.oemname, value:element.id};
            optionFleetData.push(tempData);
        });
    }
    var optionHubListData = [];
    if(allHubList && typeof allHubList !== undefined && allHubList!==""){
        allHubList.forEach(element => {
            var tempData = {label: element.hubname, value:element.id};
            optionHubListData.push(tempData);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.saveVehicleQr(selectedQrcode.label,selectedBatteryType.label,selectedHubList.label,selectedVehicleNo.label,selectedVehicleType.label,selectedFleet.label)
        alert('Update Successfully');
        history.push("/dashboard/hub")
        
    };



    return (
        <>
       <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading"><b>Bind QR Code</b></h4>
                    
                        <form className="form mt-5" onSubmit={handleSubmit} autoComplete="off">
                            <div className="row rowpadding  justify-content-between">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer">
                                        <div className="col-12">
                                            <label className="formLable pl-2" htmlFor="qrcode">QR Code</label>
                                            <Select
                                                className="basic-single p-1"
                                                classNamePrefix="select"
                                                isDisabled={isDisabled}
                                                isLoading={isLoading}
                                                isClearable={isClearable}
                                                isRtl={isRtl}
                                                isSearchable={isSearchable}
                                                options={optionQRCodeData}
                                                onChange={setselectedQrcode}
                                                value={selectedQrcode}
                                                name="selectedQrcode"
                                            />
                                            <label className="formLable pl-2" htmlFor="selectedBatteryType">Battery Type</label>
                                            <Select
                                                className="basic-single p-1"
                                                classNamePrefix="select"
                                                isDisabled={isDisabled}
                                                isLoading={isLoading}
                                                isClearable={isClearable}
                                                isRtl={isRtl}
                                                isSearchable={isSearchable}
                                                options={optionBatteryData}
                                                onChange={setselectedBatteryType}
                                                value={selectedBatteryType}
                                                name="selectedBatteryType"
                                            />

                                            <label className="formLable pl-2" htmlFor="selectedHubList">Hub Name</label>
                                            <Select
                                                className="basic-single p-1 mb-3"
                                                classNamePrefix="select"
                                                isDisabled={isDisabled}
                                                isLoading={isLoading}
                                                isClearable={isClearable}
                                                isRtl={isRtl}
                                                isSearchable={isSearchable}
                                                options={optionHubListData}
                                                onChange={setselectedHubList}
                                                value={selectedHubList}
                                                name="selectedHubList"
                                            />


                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">
                                        <div className="col-12 ">
                                        <label className="formLable pl-2" htmlFor="selectedVehicleNo">Vehicle No.</label>
                                          <Select
                                                className="basic-single p-1"
                                                classNamePrefix="select"
                                                isDisabled={isDisabled}
                                                isLoading={isLoading}
                                                isClearable={isClearable}
                                                isRtl={isRtl}
                                                isSearchable={isSearchable}
                                                options={optionVehicleNoData}
                                                onChange={setselectedVehicleNo}
                                                value={selectedVehicleNo}
                                                name="selectedVehicleNo"
                                            />


                                        <label className="formLable pl-2" htmlFor="selectedVehicleType">Vehicle Type</label>
                                        <Select
                                                className="basic-single p-1"
                                                classNamePrefix="select"
                                                isDisabled={isDisabled}
                                                isLoading={isLoading}
                                                isClearable={isClearable}
                                                isRtl={isRtl}
                                                isSearchable={isSearchable}
                                                options={optionVehicleTypeData}
                                                onChange={setselectedVehicleType}
                                                value={selectedVehicleType}
                                                name="selectedVehicleType"
                                            />


                                        <label className="formLable pl-2" htmlFor="selectedFleet">Fleet Operator</label>
                                        <Select
                                                className="basic-single p-1 mb-3"
                                                classNamePrefix="select"
                                                isDisabled={isDisabled}
                                                isLoading={isLoading}
                                                isClearable={isClearable}
                                                isRtl={isRtl}
                                                isSearchable={isSearchable}
                                                options={optionFleetData}
                                                onChange={setselectedFleet}
                                                value={selectedFleet}
                                                name="selectedFleet"
                                            />


                                        </div>
                                    </div>
                                </div>
                            </div>

                        

                            <div className="row">
                                <div className="col-xl-6 col-sm-6 col-12">
                                    <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/hub">Back</a>
                                </div>

                                <div className="col-xl-6 col-sm-6 col-12 float-right">
                                    <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">Bind QR Code</button>

                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
          
      </>
    );
  }

const mapStateToProps = function (state) {


  return {
    
    fetchQRcode: state.hub.fetchQRcodeList,
    fetchVehicleNo: state.hub.fetchVehicleNoList,
    fetchBatteryType: state.hub.fetchBatteryTypeList,
    fetchFleetOperator: state.hub.fetchFleetOperatorList,
    fetchVehicleType: state.hub.fetchVehicleTypeList,
    fetchHubListData: state.hub.getAllHubListData,
    saveVehicleQrcode: state.hub.saveVehicleaAndQrcode,
  }
}
const mapDispatchToProps = dispatch => {
  return {

    getQRcode: () => dispatch(getQRcodeList()),
    getVehicleList: () => dispatch(getVehicleNoList()),
    getBatteryType: () => dispatch(getBatteryTypeList()),
    getFleetList: () => dispatch(getFleetOperatorList()),
    getVehicleType: () => dispatch(getVehicleTypeList()),
    getHubListData: () => dispatch(getAllHubListData()),
    saveVehicleQr: (selectedQrcode,selectedBatteryType,selectedHubList,selectedVehicleNo,selectedVehicleType,selectedFleet) => dispatch(saveVehicleAndQrcode(selectedQrcode,selectedBatteryType,selectedHubList,selectedVehicleNo,selectedVehicleType,selectedFleet)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BindingQRcode));