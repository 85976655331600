import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getLocationAction } from "../../../../store/actions/locationAction";
import { networkEnterpriseOperatorAction, operatorAction, } from "../../../../store/actions/opAction";
import { saveUtilityAction } from "../../../../store/actions/assetAction";
import dashboard from "../excel/utils.xlsx";
import * as XLSX from "xlsx";

const Utility = (props) => {
    const { firstname } = JSON.parse(localStorage.getItem("user"));
    const [utilityList, setUtilityList] = useState();
    useEffect(() => {
        props.npData("Network Operator");
        props.getLocation();
    }, []);
    const handleChange = (e) => {
        setUtilityList({
            ...utilityList,
            [e.target.name]: e.target.value.trim(),
        });
        if (e.target.name === "network_operator") {
            let index = e.target.selectedIndex;
            let el = e.target.childNodes[index];
            let createdby = el.getAttribute("id");
            props.epData(createdby, "Enterprise");
        }
    };

    // console.log("utilityList", utilityList)
    const handleSubmit = (e) => {
        e.preventDefault();
        alert("New Utility Added");
        props.commisionUtility(utilityList);
    };
    //Read Excel file
    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                console.log("wsname", ws);
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
                // console.log("exceldata", data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
        promise.then((d) => {
            // console.log("data2", d);

            for (let value of Object.values(d)) {
                const a = (Object.keys(value));
                // console.log("array of keys", a);

                var b = ['network_operator', 'enterprise', 'station_name', 'service_number', 'electricity_bill_due_date', 'commissioned_date', 'electricity_bill_amount', 'unit_price'];
                // console.log("array of key b",b);
                // Comparing both arrays using stringify
                if (JSON.stringify(a) == JSON.stringify(b)) {
                    setUtilityList(d);
                    return;
                } else {
                    alert("Header format does not match");

                    return window.location.href = "/dashboard/assets/utility";
                }
            }

        });
    };





    const handleSubmitFile = () => {
        //var keys = Object.keys(utilityList); //get keys from object as an array
        // console.log(keys,"keys")


        // console.log("Selectedfile", utilityList);
        for (let i = 0; i < utilityList.length; i++) {
            props.commisionUtility(utilityList[i]);
        }
    };

    if (
        props.response.error !== "" &&
        props.response.result === "New util has been inserted into DB"
    ) {
        return <Redirect to={"/dashboard/assets"}></Redirect>;
    }

    return (
        <div className="container-fluid">
            <div className="sideCardDesigns card-signin">
                <div className="card-body">
                    <h4 className="text-center formHeading">
                        <b >Create New Utility</b>
                    </h4>
            <form className="form mt-4" onSubmit={handleSubmit} autoComplete="off">
            <div className="row rowpadding  justify-content-between">
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer">
                  <div className="col-12">
                    <label className="formLable" htmlFor="networkoperator">Network Operator</label>
                     <div className="form-label-group">
                                    <select
                                        className="form-control border-radius"
                                        id="network_operator"
                                        name="network_operator"
                                        required
                                        onChange={handleChange}
                                    >
                                        <option value="">Network Operator</option>
                                        {props.operatorlist &&
                                            props.operatorlist.map((network) => (
                                                <option
                                                    key={network.id}
                                                    id={network.id}
                                                    value={network.organization}
                                                >
                                                    {network.organization}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                    <label className="formLable" htmlFor="enterprise">Enterprise</label>
                    <div className="form-label-group">
                                    <select
                                        className="form-control border-radius"
                                        id="enterprise"
                                        name="enterprise"
                                        required
                                        onChange={handleChange}
                                    >
                                        <option value="">Enterprise</option>
                                        {props.enterpriselist === null ? (
                                            <option value="none">none</option>
                                        ) : (
                                            props.enterpriselist &&
                                            props.enterpriselist.map((enterprise) => (
                                                <option
                                                    key={enterprise.id}
                                                    value={enterprise.organization}
                                                >
                                                    {enterprise.organization}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                </div>

                    <label className="formLable" htmlFor="locationname">Location Name</label>
                    <div className="form-label-group">
                                    <select
                                        className="form-control border-radius"
                                        id="station_name"
                                        name="station_name"
                                        required
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Location</option>
                                        {props.locations === null ? (
                                            <option value="none">none</option>
                                        ) : (
                                            props.locations &&
                                            props.locations.map((location) => (
                                                <option key={location.name} value={location.name}>
                                                    {location.name}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                </div>

                    <label className="formLable" htmlFor="commissioned_date">Commissioned Date</label>
                    <div className="form-label-group">
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="commissioned_date"
                                        name="commissioned_date"
                                        required
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="commissioned_date">Commissioned Date</label>
                                </div>

                    <label className="formLable" htmlFor="service_number">Service Number</label>
                    <div className="form-label-group required">
                                    <input
                                        type="text"
                                        id="service_number"
                                        name="service_number"
                                        className="form-control control-label"
                                        placeholder="Enter Service Number"
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="service_number">Service Number</label>
                        </div>

                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer ">

                  <div className="col-12 ">

                    <label className="formLable" htmlFor="electricity_bill_due_date">Electricity Bill Due date</label>
                    <div className="form-label-group">
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="electricity_bill_due_date"
                                        name="electricity_bill_due_date"
                                        required
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="electricity_bill_due_date">
                                        Electricity Bill Due date
                                    </label>
                                </div>

                    <label className="formLable" htmlFor="electricity_bill_amount">Electricity Bill Amount</label>
                    <div className="form-label-group required">
                                    <input
                                        type="number"
                                        id="electricity_bill_amount"
                                        name="electricity_bill_amount"
                                        className="form-control control-label"
                                        placeholder="Enter Service Number"
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="electricity_bill_amount">
                                        Electricity Bill Amount
                                    </label>
                                </div>

                    <label className="formLable" htmlFor="unit_price">Electricity Price per Unit</label>
                    <div className="form-label-group required">
                                    <input
                                        type="number"
                                        id="unit_price"
                                        name="unit_price"
                                        className="form-control control-label"
                                        placeholder="Enter Service Number"
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="unit_price">Electricity Price per Unit</label>
                                </div>

                    <label className="formLable" htmlFor="enterdby">Entered By</label>
                    <div className="form-label-group ">
                                    <input
                                        type="text"
                                        className="form-control control-label"
                                        name="enterdby"
                                        value={firstname}
                                        disabled
                                    />
                                    <label htmlFor="enterdby">Data Entered By</label>
                                </div>


                 
                    <br/>
                    <br/>
                    <br/>

                  </div>
                </div>
              </div>
            </div>



            <div className="row" >
                            <div className="col-md-3 col-lg-3 ">
                                <a
                                    className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                    href="/dashboard/assets"
                                >
                                    Cancel
                                </a>
                            </div>
                            <div className="col-md-9 col-lg-9 ">
                                <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">
                                
                                        {!props.loading ? "Create Utility" : "Please wait..."}
                                    
                                </button>
                            </div>
                </div>
          </form>


          <div className="row">
            <div className="hr-sect">
              <span style={{ color: "#10204B", fontWeight: "600", fontSize: "30px" }}>OR</span>
            </div>
          </div>


          <div className="row formLable">
          <div className="col-6">
            <div className="col-12 formContainer p-5">
              <h4 className="text-left textColor ">
                <b> Download Excel </b>
              </h4>

              <div className="page-title-subheading text-left">
                                <ul>Steps to follow</ul>
                                <ul>1. Download the excel.</ul>
                                <ul>2. Add the desired data without changing header row.</ul>
                                <ul>2. Select the location name as it is mentioned in above form.</ul>
                                <ul>3. If there is already a dropdown, select the options from dropdown.</ul></div>
                            <a className="btn columnsBtn btn-pill btn-wide text-uppercase " href={dashboard} download="utils.xlsx" >
                                {" "}
                                Download Excel Format Here{" "}
                            </a>
            </div>
            
            </div>
          <div className="col-6 ">
            <div className="col-12 textColor formContainer p-5 ">
              <h4 className="text-left">
                <b> Upload Excel </b>
              </h4>
              <div className="page-title-subheading text-left ">
                                <ul>Steps to follow</ul>
                                <ul>1. Save the previous excel file.</ul>
                                <ul>2. Click on choose file and select the same file.</ul>
                                <ul>3. Click upload.</ul></div>
                            <input type="file" name="file_upload columnsBtn" onChange={(e) => { const file = e.target.files[0]; readExcel(file); }} />

                            <br/>
                            <br/>
                            <br/>
                            <br/>
            </div>
            </div>
          </div>

          <div className="row mt-3">
                        <div className="col-md-3 col-lg-3 ">
                            <a
                                className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                href="/dashboard/assets"
                            >
                                Cancel
                            </a>
                        </div>
                        <div className="col-md-9 col-lg-9">
                            <button
                                className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"
                                onClick={handleSubmitFile}
                            >
                                 Upload New Utility
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        operatorlist: state.operator.fetchOperator,
        enterpriselist: state.operator.fetchEnterprise,
        locations: state.location.fetchLocation,
        response: state.asset.saveUtility,
        loading: state.asset.isLoading,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        npData: (nrole) => dispatch(operatorAction(nrole)),
        epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
        getLocation: () => dispatch(getLocationAction()),
        commisionUtility: (utility) => dispatch(saveUtilityAction(utility)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Utility);
