import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import {makeAction,modelAction,saveAssetAction} from "../../../../store/actions/assetAction";
import {getOperatorLocationAction,slocationAction} from "../../../../store/actions/locationAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class AddAsset extends Component {
  assetForm = {
    assettype: {
      value: this.props.match.params.type,
      isValid: true,
      message: "",
    },
    assetname: { value: "", isValid: true, message: "" },
    assetid: { value: "", isValid: true, message: "" },
    make: { value: "", isValid: true, message: "" },
    model: { value: "", isValid: true, message: "" },
    serialno: { value: "", isValid: true, message: "" },
    location: { value: "", isValid: true, message: "" },
    status: { value: "", isValid: true, message: "" },
    warrantystart: { value: "", isValid: true, message: "" },
    warrantyduration: { value: "", isValid: true, message: "" },
    warrantyend: { value: "", isValid: true, message: "" },
    description: { value: "", isValid: true, message: "" },
  };
  state = {
    ...this.assetForm,
  };
  componentDidMount() {
    this.props.makeData();
    this.props.locationData();
    this.props.locationStatusList();
  }
  handleChange = (e) => {
    e.persist();
    const state = {
      ...this.state,
      [e.target.name]: {
        ...this.state[e.target.name],
        value: e.target.value,
      },
    };
    if (e.target.name === "make") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let makeid = el.getAttribute("id");
      // console.log(makeid);
      this.props.modelData(makeid);
    }
    this.setState(state);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.saveAsset({ ...this.state });
  };
  render() {
    const type = this.props.match.params.type;
    if (this.props.saveresponse && this.props.saveresponse.error === "") {
      return <Redirect to="/dashboard/assets" />;
    }
    toast.configure();
    const notify = () => {
      toast("Asset Added Successfully");
    };

    return (
      <div className="container-fluid">
        <div className="sideCardDesigns card-signin my-5">
          <div className="card-body">
            <h4 className="text-center">
              <b>Create New Asset</b>
            </h4>
            <hr className="my-4" />
            <form className="form-signin" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input
                      type="text"
                      id="assettype"
                      name="assettype"
                      className="form-control control-label"
                      value={type}
                      onChange={this.handleChange}
                      disabled
                    />
                    <label htmlFor="assettype">Asset Type</label>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input
                      type="text"
                      id="assetname"
                      name="assetname"
                      className="form-control control-label"
                      placeholder="Enter Asset Name"
                      onChange={this.handleChange}
                    />
                    <label htmlFor="assetname">Enter Asset Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input
                      type="text"
                      id="assetid"
                      name="assetid"
                      className="form-control control-label"
                      placeholder="Enter Asset ID"
                      onChange={this.handleChange}
                    />
                    <label htmlFor="assetid">Enter Asset ID</label>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <select
                      class="form-control border-radius"
                      id="make"
                      name="make"
                      onChange={this.handleChange}
                    >
                      <option>Make</option>
                      {this.props.makelist &&
                        this.props.makelist.map((make) => (
                          <option id={make.code} value={make.name}>
                            {make.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <select
                      class="form-control border-radius"
                      id="model"
                      name="model"
                      onChange={this.handleChange}
                    >
                      <option>Model</option>
                      {this.props.modellist &&
                        this.props.modellist.map((model) => (
                          <option id={model.modelcode} value={model.model}>
                            {model.model}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input
                      type="text"
                      id="serialno"
                      name="serialno"
                      className="form-control control-label"
                      placeholder="Enter Asset ID"
                      onChange={this.handleChange}
                    />
                    <label htmlFor="serialno">Enter Serial No.</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <select
                      class="form-control border-radius"
                      id="location"
                      name="location"
                      onChange={this.handleChange}
                    >
                      <option>Select Location</option>
                      {this.props.locationlist &&
                        this.props.locationlist.map((location) => (
                          <option id={location.id} value={location.name}>
                            {location.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <select
                      class="form-control border-radius"
                      id="status"
                      name="status"
                      onChange={this.handleChange}
                    >
                      <option>Status</option>
                      {this.props.statuslist &&
                        this.props.statuslist.map((slist) => (
                          <option key={slist.id} value={slist.code}>
                            {slist.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input
                      type="date"
                      name="warrantystart"
                      min="1000-01-01"
                      max="3000-12-31"
                      class="form-control"
                      onChange={this.handleChange}
                    />
                    <label htmlFor="warrantystart">
                      Warranty Start(Optinal).
                    </label>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input
                      type="text"
                      id="warrantyduration"
                      name="warrantyduration"
                      className="form-control control-label"
                      placeholder="Warranty Duration(In Days)."
                      onChange={this.handleChange}
                    />
                    <label htmlFor="warrantyduration">
                      Warranty Duration(In Days).
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="form-label-group">
                    <input
                      type="date"
                      name="warrantyend"
                      min="1000-01-01"
                      max="3000-12-31"
                      class="form-control"
                      onChange={this.handleChange}
                    />
                    <label htmlFor="warrantyend">Warranty End(Optinal)</label>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-6">
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      id="description"
                      name="description"
                      placeholder="Description(Optional)"
                      rows="4"
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <a
                    className="btn btn-primary btn-pill btn-wide text-uppercase float-left"
                    href="/dashboard/assets"
                  >
                    Back
                  </a>
                </div>

                <div className="col-xl-6 col-sm-6 col-12 float-right">
                  <button
                    className="btn btn-primary btn-pill btn-wide text-uppercase float-right"
                    onClick={notify}
                  >
                    {!this.props.loading
                      ? "Create New Asset"
                      : "Please wait..."}
                  </button>
                </div>
                <hr className="my-4" />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.asset.isLoading,
    makelist: state.asset.fetchMaker,
    modellist: state.asset.fetchModel,
    locationlist: state.location.fetchOperatorLocationList,
    statuslist: state.location.fetchLocationStatusList,
    saveresponse: state.asset.saveAsset,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    makeData: () => dispatch(makeAction()),
    modelData: (makerid) => dispatch(modelAction(makerid)),
    locationData: () => dispatch(getOperatorLocationAction()),
    locationStatusList: () => dispatch(slocationAction()),
    saveAsset: (asset) => dispatch(saveAssetAction(asset)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddAsset));
