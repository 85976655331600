import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
    countryAction,
    stateAction,
} from "../../../store/actions/commonAction";
import {
    networkEnterpriseOperatorAction,
    operatorAction,
} from "../../../store/actions/opAction";
import { getSubscriptionPlanByCreatorAction } from "../../../store/actions/subscriptionAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPriceSingleAction, editPricebyID } from "../../../store/actions/priceAction";
import TimePicker from 'rc-time-picker';
import moment from 'moment';

const EditPrice = (props) => {
    const { id } = useParams()
    let arr = [];
    var WeakdaysList = ([{ code: "Monday", name: "Monday" }, { code: "Tuesday", name: "Tuesday" }, { code: "Wednesday", name: "Wednesday" }, { code: "Thursday", name: "Thursday" }, { code: "Friday", name: "Friday" }, { code: "Saturday", name: "Saturday" }, { code: "Sunday", name: "Sunday" }])


    const [data, setData] = useState({
        operator: "",
        enterprise: "",
        name: "",
        description: "",
        currency: "",
        country: "",
        state: "",
        starttime: "",
        endtime: "",
        plantype: "",
        status: "",
        scheduleday:[]

    });

    const [inputList, setInputList] = useState([{ power: "", kwh: Number, pricetype: "", priceunit: "", amount: Number, idlefee: Number, graceperiod: Number }]);

    const history = useHistory();
    const [isPriceGroup, setPriceGroup] = useState();
    const [starttime, setStartTime] = useState('00:00:00');
    const [endtime, setEndTime] = useState('23:59:59');
    const showSecond = true;
    const str = showSecond ? 'HH:mm:ss a' : 'HH:mm a';

    useEffect(() => {
        props.npData("Network Operator");
        props.epData("Enterprise");
        props.countrylist();
        props.plantypelist("All");
        props.getSinglePrice(id)
    }, []);


    useEffect(() => {
        setData(props.pricesingledetail)
    }, [props.pricesingledetail])

    useEffect(() => {
        setInputList(props.pricesingledetail.pricecomponent)
    }, [props.pricesingledetail.pricecomponent])



    useEffect(() => {
        setPriceGroup(props.pricesingledetail.isPriceGroup)
    }, [props.pricesingledetail.isPriceGroup])


    const handleChange = (e) => {

        if (e.target.name === "country") {
            let index = e.target.selectedIndex;
            let el = e.target.childNodes[index];
            let countrycode = el.getAttribute("id");
            props.statelist(countrycode);
        }
        setData({
            ...data,
            [e.target.name]: e.target.value

        });

    }

    const onStartTimeChange = (value) => {
        setStartTime(value.format(str))
    }
    const onEndTimeChange = (value) => {
        setEndTime(value.format(str))
    }

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const handleRemoveClick = (e, index) => {
        e.preventDefault()
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([...inputList, { power: "", kwh: Number, pricetype: "", priceunit: "", amount: Number, idlefee: Number, graceperiod: Number }]);
    };


    const handleWeekChange = (e) => {
        const target = e.target;
        let pvalue = target.value;
        let pid = target.id;
        arr = data.scheduleday;

        if (target.checked) {
            arr.push({ code: pid, name: pvalue });
            // console.log("push", arr);
        } else {
            const removeIndex = arr.findIndex((item) => item.name === pvalue);
            arr.splice(removeIndex, 1);
            // console.log("push", arr);
        }
        setData({
            ...data,
            [arr]: data[arr]
          })
    };

    const getChecked = (item) => {
        let dayss = data && data.scheduleday.filter((itr) => itr.name === item)
        return dayss.length !== 0 ? true : false  
    }


    const handelGroupCheck = (e) => {
        setPriceGroup(current => !current);
    };


    const handleSubmit = (e) => {
        props.updatePriceDetails(id, data, inputList, isPriceGroup);
        alert('Update Successfully');
        history.push("/dashboard/pricing")
    };

    const notify = () => {
        toast("Price Created Successfully");
    };
    if (props.response.error === "" && props.response.result !== "") {
        return (
            <Redirect
                to={"/dashboard/pricing/addpricelevel/" + props.response.result}
            ></Redirect>
        );
    }
    return (


        <>

            <div className="container-fluid">
                <div className="sideCardDesigns card-signin">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>Edit Price Plan</b>
                        </h4>

                        <form className="form mt-4" onSubmit={handleSubmit} autoComplete="off">

                            <div className="row rowpadding  justify-content-between">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer">
                                        <div className="col-12">
                                            <label className="formLable" htmlFor="operator">Network Operator</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    id="noperator"
                                                    name="operator"
                                                    onChange={handleChange}
                                                    disabled
                                                >
                                                    <option>{props.pricesingledetail.operator}</option>

                                                </select>
                                            </div>
                                            <label className="formLable" htmlFor="enterprise">Enterprise</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    name="enterprise"
                                                    onChange={handleChange}
                                                    disabled

                                                >
                                                    <option>{props.pricesingledetail.enterprise}</option>

                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="name">Price Name</label>
                                            <div className="form-label-group required">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="form-control control-label"
                                                    placeholder="Price Name"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.name}
                                                />
                                                <label htmlFor="name">Price Name</label>
                                            </div>

                                            <label className="formLable" htmlFor="plantype">Subscription Plan</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    name="plantype"
                                                    onChange={handleChange}
                                                    value={data.plantype}

                                                >
                                                    <option >Subscription Plan</option>
                                                    {props.plantypleres &&
                                                        props.plantypleres.map((pt) => (
                                                            <option key={pt.id} value={pt.name} name="plantype">
                                                                {pt.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="currency">Currency</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    id="currency"
                                                    name="currency"
                                                    onChange={handleChange}
                                                    value={data.currency}
                                                >
                                                    <option value="N/A">Currency</option>
                                                    <option value="INR">INR</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="scheduledays">Days of Week</label>
                                            <div className="containerAme p-2 ">
                                                {WeakdaysList && WeakdaysList.map((day) => (
                                                    <div className="form-check form-check-inline border badge badge-pill text py-1 px-2 containerAme" code={day.code}>
                                                        <input className="form-check-input" type="checkbox" name="schedule" id={day.code} value={day.name} checked={getChecked(day.name)} onChange={handleWeekChange} />
                                                        <label className="form-check-label" htmlFor={day.code}>{day.name}</label>
                                                    </div>

                                                ))}
                                            </div>
                                            <br />



                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">

                                        <div className="col-12 ">

                                            <label className="formLable" htmlFor="country">Country</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    name="country"
                                                    onChange={handleChange}
                                                    value={data.country}
                                                >
                                                    <option>Select Country</option>
                                                    {props.countries &&
                                                        props.countries.map((country) => (
                                                            <option
                                                                key={country.id}
                                                                id={country.code}
                                                                value={country.name}
                                                                name="country"
                                                            >
                                                                {country.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="state">State</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    name="state"
                                                    onChange={handleChange}
                                                    value={data.state}
                                                >
                                                    <option >Select State</option>
                                                    {props.states &&
                                                        props.states.map((st) => (
                                                            <option key={st.code} value={st.name}>
                                                                {st.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="pricestatus">Price Status</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    name="pricestatus"
                                                    onChange={handleChange}

                                                >
                                                    <option name="pricestatus">Draft</option>
                                                </select>
                                            </div>
                                            <label className="formLable" htmlFor="isPriceGroup">Is Price Group</label>
                                            <div className="form-label-group containerAme p-3">
                                                <input
                                                    name="isPriceGroup"
                                                    type="checkbox"
                                                    checked={isPriceGroup}
                                                    onChange={handelGroupCheck}
                                                    value={isPriceGroup}
                                                // defaultChecked={isPriceGroup}

                                                />{" "}
                                                Is Price Group?
                                            </div>

                                            <label className="formLable" htmlFor="description">Description</label>
                                            <div className="form-label-group">
                                                <textarea
                                                    className="form-control"
                                                    id="description"
                                                    name="description"
                                                    placeholder="Description(Optional)"
                                                    rows="3"
                                                    onChange={handleChange}
                                                    value={data.description}
                                                ></textarea>
                                            </div>
                                            <label className="formLable" htmlFor="timestart">Time Start</label>
                                            <div className="containerAme">
                                                <TimePicker
                                                    name="starttime"
                                                    style={{ width: "100%" }}
                                                    value={moment({ starttime })}
                                                    className="xxx "
                                                    onChange={onStartTimeChange}
                                                    disabled
                                                />
                                            </div>
                                            <label className="formLable" htmlFor="timeend">Time End</label>
                                            <div className="containerAme ">
                                                <TimePicker
                                                    name="endtime"
                                                    style={{ width: "100%" }}
                                                    value={moment({ endtime })}
                                                    className="xxx"
                                                    onChange={onEndTimeChange}
                                                    disabled
                                                />
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>







                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3">
                                    <li className="breadcrumb-item active" aria-current="page">Add Price Component</li>
                                </ol>
                            </nav>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                    <div className="table-responsive">
                                        <table class="table table-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Power</th>
                                                    <th scope="col">kW</th>
                                                    <th scope="col">Price Type</th>
                                                    <th scope="col">Price Unit</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Idle Fee</th>
                                                    <th scope="col">Grace Period</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inputList && inputList.map((x, i) => {

                                                    return (

                                                        <tr key={i}>
                                                            <td >
                                                                <select className="border-radius" name="power" value={x.power} onChange={e => handleInputChange(e, i)}>

                                                                    <option>Select Power</option>
                                                                    <option>AC</option>
                                                                    <option>DC</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="border-radius" name="kwh" value={x.kwh} onChange={e => handleInputChange(e, i)}>
                                                                    <option value="">KW</option>
                                                                    <option>450</option>
                                                                    <option>350</option>
                                                                    <option>150</option>
                                                                    <option>125</option>
                                                                    <option>100</option>
                                                                    <option>75</option>
                                                                    <option>50</option>
                                                                    <option>25</option>
                                                                </select>
                                                            </td>
                                                            <td >
                                                                <select className="border-radius" name="pricetype" value={x.pricetype} onChange={e => handleInputChange(e, i)}>
                                                                    <option value="">Price Type</option>
                                                                    <option>Energy</option>
                                                                    <option>Time</option>
                                                                </select>
                                                            </td>
                                                            <td >
                                                                <select className="border-radius" name="priceunit" value={x.priceunit} onChange={e => handleInputChange(e, i)}>
                                                                    <option value="">Price Unit</option>
                                                                    <option>Hour</option>
                                                                    <option>Minute</option>
                                                                    <option>Second</option>
                                                                    <option>WH</option>
                                                                    <option>kWh</option>
                                                                    <option>Flat</option>
                                                                </select>

                                                            </td>
                                                            <td >
                                                                <input type="number" name="amount" placeholder="Price Amount" value={x.amount} onChange={e => handleInputChange(e, i)} />
                                                            </td>
                                                            <td >
                                                                <input type="number" name="idlefee" placeholder="Idle Fee Amount" value={x.idlefee} onChange={e => handleInputChange(e, i)} />
                                                            </td>
                                                            <td >
                                                                <select className="border-radius" name="graceperiod" value={x.graceperiod} onChange={e => handleInputChange(e, i)}>
                                                                    <option value="">Grace Period(Minute)</option>
                                                                    <option>10</option>
                                                                    <option>20</option>
                                                                    <option>30</option>
                                                                    <option>40</option>
                                                                    <option>50</option>
                                                                    <option>60</option>
                                                                </select>

                                                            </td>


                                                            <td >
                                                                {inputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveClick(e, i)}>-</button>}
                                                                {inputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddClick}>+</button>}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6 col-lg-6 ">
                                    <a
                                        className="btn columnsBtn btn-pill btn-wide text-uppercase"
                                        href="/dashboard/pricing"
                                    >
                                        Cancel
                                    </a>
                                </div>

                                <div className="col-md-6 col-lg-6 ">
                                    <button
                                        className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"
                                        onClick={notify}
                                    >
                                        Update
                                    </button>
                                </div>
                                <ToastContainer style={{ top: '3rem' }} />
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = function (state) {
    return {
        operatorlist: state.operator.fetchOperator,
        enterpriselist: state.operator.fetchEnterprise,
        countries: state.common.countryList,
        states: state.common.stateList,
        response: state.price.savePriceResponse,
        plantypleres: state.subscriptionplan.fetcSubscriptionplan,
        pricesingledetail: state.price.fetchPrice,
        updatePrice: state.price.editPriceByID,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        npData: (nrole) => dispatch(operatorAction(nrole)),
        epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
        countrylist: () => dispatch(countryAction()),
        statelist: (countrycode) => dispatch(stateAction(countrycode)),
        plantypelist: () => dispatch(getSubscriptionPlanByCreatorAction("All")),
        getSinglePrice: (id) => dispatch(getPriceSingleAction(id)),
        updatePriceDetails: (id, data, inputList, isPriceGroup) => dispatch(editPricebyID(id, data, inputList, isPriceGroup))



    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPrice);
