import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getconnectorTypeListAction } from "../../../store/actions/ctAction";
import { getLocationAction } from "../../../store/actions/locationAction";
import { networkEnterpriseOperatorAction, operatorAction } from "../../../store/actions/opAction";
import { commissionStationAction } from "../../../store/actions/stationAction";
import { useParams } from "react-router-dom";
import { getSingleAssetActions } from "../../../store/actions/assetAction";
import "./station.css";


const CreateChargeStation = (props) => {
  const { firstname } = JSON.parse(localStorage.getItem("user"));
  const [inputList, setInputList] = useState([{ chargername: "", chargeboxid: "", chargerserialno: "", make: "", model: "", compatibilty: "", powertype: "", chargertype: "", protocol: "", connectors: {} }]);
  const [inputConnectorList, setInputConnectorList] = useState([{ chargeboxid: "", connectorname: "", connectortype: "", powertype: "", power: "", connectorno: "" }]);
  const [inputImagesList, setImagesList] = useState([{ file: "" }]);
  const [stationList, setStationList] = useState();
  const { locationname } = useParams()

  // console.log("WEF", locationname)

  useEffect(() => {
    props.npData("Network Operator");
    props.getLocation();
    props.getConnectors();
    props.getSingleAsset()
  }, [])
  const handleChange = (e) => {
    setStationList({
      ...stationList,
      [e.target.name]: e.target.value.trim()

    });
    if (e.target.name === "networkoperator") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index]
      let createdby = el.getAttribute('id');
      props.epData(createdby, "Enterprise")
    }
  }
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    // console.log("inputList", inputList)

  };
  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e.preventDefault()
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleConnectorInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputConnectorList];
    list[index][name] = value;
    setInputConnectorList(list);

  };
  const handleConnectorRemoveClick = (e, index) => {
    e.preventDefault()
    const list = [...inputConnectorList];
    list.splice(index, 1);
    setInputConnectorList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { chargername: "", chargeboxid: "", chargerserialno: "", make: "", model: "", compatibilty: "", powertype: "", chargertype: "", protocol: "" }]);
  };

  const handleConnectorAddClick = () => {
    setInputConnectorList([...inputConnectorList, { chargeboxid: "", connectorname: "", connectortype: "", powertype: "", power: "", connectorno: "" }]);
  };
  // const handleImageAddClick = () => {
  //   setImagesList([...inputImagesList, { file: ""}]);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('New Station Commissioned');
    props.commisionStation(stationList, inputList, inputConnectorList, inputImagesList);
  }


  if (props.response.error === '' && props.response.result !== "") {
    return <Redirect to={"/dashboard/stations"}></Redirect>
  }
  return (
    <div className="container-fluid">
      <div className="sideCardDesigns card-signin my-5">
        <div className="card-body">
          <h4 className="text-center formHeading"><b>Add New Charging Station </b></h4>

          <form className="form mt-5" onSubmit={handleSubmit} autoComplete="off">

            <div className="row rowpadding  justify-content-between">
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer">
                  <div className="col-12">
                    <label className="formLable" htmlFor="stationid">Station ID</label>
                    <div className="form-label-group">
                      <input type="text" id="stationid" name="stationid" className="form-control control-label" placeholder="Station ID" required onChange={handleChange} />
                      <label htmlFor="stationid">Station ID</label>
                    </div>
                    <label className="formLable" htmlFor="networkoperator">Network Operator</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="networkoperator" name="networkoperator" required onChange={handleChange}>
                        <option value="">Network Operator</option>
                        {props.operatorlist && props.operatorlist.map(network =>
                          <option key={network.id} id={network.id} value={network.organization}>{network.organization}</option>
                        )}
                      </select>
                    </div>


                    <label className="formLable" htmlFor="stationname">Station Name</label>
                    <div className="form-label-group">
                      <input type="text" id="stationname" name="stationname" className="form-control control-label" placeholder="Station Name" required onChange={handleChange} />
                      <label htmlFor="stationname">Station Name</label>
                    </div>

                    <label className="formLable" htmlFor="enterprise">Enterprise</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="enterprise" name="enterprise" required onChange={handleChange}>
                        <option value="">Enterprise</option>
                        {props.enterpriselist === null ? <option value="none">none</option> : props.enterpriselist && props.enterpriselist.map(enterprise =>
                          <option key={enterprise.id} value={enterprise.organization}>{enterprise.organization}</option>
                        )}
                      </select>
                    </div>
                    <label className="formLable" htmlFor="location">Location Name</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius selectpicker" id="location" name="location" data-live-search="true" required onChange={handleChange}>
                        <option value="">Select Location</option>
                        {props.locations === null ? <option value="none">none</option> : props.locations && props.locations.map(location =>
                          <option key={location.id} value={location.id}>{location.name}</option>
                        )}
                      </select>
                    </div>
                    <label className="formLable" htmlFor="visibility">Visibility</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="visibility" name="visibility" required onChange={handleChange}>
                        <option value="">Select Visibility</option>
                        <option>Public</option>
                        <option>Private</option>
                        <option>Captive</option>
                      </select>
                    </div>



                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer ">

                  <div className="col-12 ">

                    <label className="formLable" htmlFor="commisioneddate">Commisioned Date</label>
                    <div className="form-label-group">
                      <input className="form-control" type="date" id="commisioneddate" name="commisioneddate" required onChange={handleChange} />
                      <label htmlFor="commisioneddate">Commisioned Date</label>
                    </div>

                    <label className="formLable" htmlFor="status">Status</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="status" name="status" required onChange={handleChange}>
                        <option value="">Status</option>
                        <option>Available</option>
                        <option>offline</option>
                        <option>Maintenance</option>
                        <option>Coming soon</option>
                      </select>
                    </div>
                    <label className="formLable" htmlFor="install">Install</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="install" name="install" required onChange={handleChange}>
                        <option value="">Install</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <label className="formLable" htmlFor="fleet">Fleet</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="fleet" name="fleet" required onChange={handleChange}>
                        <option value="">Fleet</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <label className="formLable" htmlFor="projectname">Project Name</label>
                    <div className="form-label-group">
                      <input type="text" id="projectname" name="projectname" className="form-control control-label" placeholder="Project Name" required onChange={handleChange} />
                      <label htmlFor="projectname">Project Name</label>
                    </div>
                    <label className="formLable" htmlFor="enteredby">Entered By</label>
                    <div className="form-label-group ">
                      <input
                        type="text"
                        name="enteredby"
                        className="form-control control-label"
                        value={firstname}
                        disabled
                      />
                      <label htmlFor="enterdby">Data Entered By</label>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">Add Chargers</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">BoxId</th>
                        <th scope="col">Serial No</th>
                        <th scope="col">Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">Compatibility</th>
                        <th scope="col">Type</th>
                        <th scope="col">Charger</th>
                        <th scope="col">Protocol</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputList.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td><input type="text" name="chargername" placeholder="Name" value={x.chargername} onChange={e => handleInputChange(e, i)} /></td>
                            <td><input type="text" name="chargeboxid" placeholder="Charger BoxId" value={x.chargerno} onChange={e => handleInputChange(e, i)} /></td>
                            <td><input type="text" name="chargerserialno" placeholder="Serial No.(Machine)" value={x.chargerserialno} onChange={e => handleInputChange(e, i)} /> </td>
                            <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handleInputChange(e, i)} /> </td>
                            <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handleInputChange(e, i)} /> </td>
                            <td className="multiselect">
                              <input type="checkbox" name="Compatibility" value={x.compatibilty} onChange={e => handleInputChange(e, i)} />e-3w <br />
                              <input type="checkbox" name="Compatibility" value={x.compatibilty} onChange={e => handleInputChange(e, i)} />e-4w <br />
                              <input type="checkbox" name="Compatibility" value={x.compatibilty} onChange={e => handleInputChange(e, i)} />e-bus
                              {/* <Multiselect name="compatibilty" placeholder="Compatibility" options={vehicleoption} displayValue="Vehicle" onChange={e => handleInputChange(e, i)} /> */}
                            </td>
                            <td>
                              <select className="border-radius" name="powertype" onChange={e => handleInputChange(e, i)}>
                                <option>Power Type</option>
                                <option>AC</option>
                                <option>DC</option>
                              </select>
                            </td>
                            <td>
                              <select className="border-radius" name="chargertype" onChange={e => handleInputChange(e, i)}>
                                <option>Type</option>
                                <option>IEC60309</option>
                                <option>Type 2</option>
                                <option>GBT</option>
                                <option>CCS2</option>
                                <option>CHAdeMO</option>
                                <option>GBT2</option>

                              </select>
                            </td>
                            <td>
                              <select className="border-radius" name="protocol" onChange={e => handleInputChange(e, i)} >
                                <option value="">Protocol</option>
                                <option>OCPP1.6J</option>
                                <option>OCPP2.0.1</option>
                                <option>Non OCPP</option>
                              </select>
                            </td>
                            <td>
                              {inputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveClick(e, i)}>-</button>}
                              {inputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddClick}>+</button>}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">Add Connector</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Charger No</th>
                        <th scope="col">Connector Name</th>
                        <th scope="col">Connector Type</th>
                        <th scope="col">Power Type</th>
                        <th scope="col">Rating(In kW)</th>
                        <th scope="col">Connector No(Machine)</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    {inputList[0].chargerno !== "" ? <tbody>

                      {inputConnectorList.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <select className="border-radius" name="chargeboxid" onChange={e => handleConnectorInputChange(e, i)}  >
                                <option value="">Select Charger Number</option>
                                {inputList !== -1 && inputList.map((il) => {
                                  return (
                                    <option>{il.chargeboxid}</option>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              <select className="border-radius" name="connectorname" onChange={e => handleConnectorInputChange(e, i)}>
                                <option value="">Connector Name</option>
                                <option>A</option>
                                <option>B</option>
                                <option>C</option>
                                <option>D</option>
                                <option>E</option>
                                <option>F</option>
                                <option>G</option>
                                <option>H</option>
                                <option>I</option>
                                <option>J</option>
                                <option>K</option>
                                <option>L</option>
                              </select>
                            </td>
                            <td>
                              <select className="border-radius" name="connectortype" onChange={e => handleConnectorInputChange(e, i)}>
                                <option value="">Connector Type</option>
                                {props.conectortypes && props.conectortypes.map((ct) => {
                                  return <option>{ct.connectortype}</option>
                                })}
                              </select>
                            </td>
                            <td>
                              <select className="border-radius" name="powertype" onChange={e => handleConnectorInputChange(e, i)}>
                                <option value="">Power Type</option>
                                <option>AC</option>
                                <option>DC</option>
                              </select>
                            </td>
                            <td>
                              <input type="text" name="power" placeholder="Power" onChange={e => handleConnectorInputChange(e, i)} />
                              {/* <select className="border-radius" name="power" onChange={e => handleConnectorInputChange(e, i)}>
                            <option value="">Power</option>
                           {props.conectortypes && props.conectortypes.map((ct)=>{
                             return <option>{ct.power}</option>
                           })}
                        </select>        */}
                            </td>
                            <td>
                              <input type="text" name="connectorno" placeholder="Connector No." onChange={e => handleConnectorInputChange(e, i)} />
                            </td>
                            <td>
                              {inputConnectorList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleConnectorRemoveClick(e, i)}>-</button>}
                              {inputConnectorList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleConnectorAddClick}>+</button>}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody> : ""}
                  </table>
                </div>
              </div>
            </div>



            <div className="row">
              <div className="col">
                {props.response && props.response.error != '' ? <div className="alert alert-danger" role="alert">
                  {props.response.error}
                </div> : ''}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-lg-3 ">
                <a className="btn columnsBtn btn-pill btn-wide text-uppercase" href="/dashboard/stations">Cancel</a>
              </div>
              <div className="col-md-9 col-lg-9 ">
                <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"  >{!props.loading ? 'Commission Charger' : 'Please wait...'}</button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}
const mapStateToProps = function (state) {
  return {
    operatorlist: state.operator.fetchOperator,
    enterpriselist: state.operator.fetchEnterprise,
    pricelistdata: state.price.fetchPriceList,
    locations: state.location.fetchLocation,
    conectortypes: state.ct.fetchConnectorTypeList,
    loading: state.station.isLoading,
    response: state.station.saveStationResponse,
    assetsingledetail: state.asset.fetchSingleAsset,

  }
}
const mapDispatchToProps = dispatch => {
  return {
    npData: (nrole) => dispatch(operatorAction(nrole)),
    epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
    getLocation: () => dispatch(getLocationAction()),
    getConnectors: () => dispatch(getconnectorTypeListAction()),
    commisionStation: (station, chargers, connectors) => dispatch(commissionStationAction(station, chargers, connectors)),
    getSingleAsset: (assetid) => dispatch(getSingleAssetActions(assetid))


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateChargeStation);