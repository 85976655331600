import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../Breadcrums"
import Loader from 'react-loader-spinner';
import UserAttendance from "./userAttendance/attendanceList";
import VisitorAttendanceList from "./visitorAttendance/visitorattendanceList"
const AttendanceCard = (props) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col col-sm-4 ">
            <PureBreadcrumbs />
          </div>
        </div>


        <div className="row rowpadding topCardDesigns mr-1">
          <div className="col">
            <div className="row py-2">
              <div className="col-md-12 col-lg-12 sideCardDesigns ">
                <div className="mb-3 h-100 singleCard">
                  <UserAttendance></UserAttendance>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row rowpadding topCardDesigns mr-1">
          <div className="col">
            <div className="row py-2">
              <div className="col-md-12 col-lg-12 sideCardDesigns ">
                <div className="mb-3 h-100 singleCard">
                  <VisitorAttendanceList></VisitorAttendanceList>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}



export default AttendanceCard;

