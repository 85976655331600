import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../Breadcrums"
import Loader from 'react-loader-spinner';
import Table from "./hubOperation/hubReactTable";
import { getAllHubOperationData } from "../../../store/actions/hubActions";
import moment from "moment";
import HubUserTableView from "./hubuserdetailstable";
import VehicleDetailsTable from "./vehicleDetails";
import HubtransactionDetailsTable from "./hubTransaction/transactionDetails"
import Hubcustomerotp from "./hubOtp/hubcustomerotp";
import Vehiclecheckin from "./vehiclecheckin/vehiclecheckin"
// import Vehicleparking from "./parkingreport/vehicleparking"
const HubCardModel = (props) => {
  const userrole = JSON.parse(localStorage.getItem('user'));
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col col-sm-4 ">
            <PureBreadcrumbs />
          </div>
        </div>
        <div className="container-fluid">
          <div className="row rowpadding topCardDesigns mr-1">
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-sm colpadding singleCard">
                  <div className=" text-center cardbg singleCard" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-right ">
                          <img className="float-right" src={process.env.PUBLIC_URL + '/images/hub/operation.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center  pt-3" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "20px", textAlign: "center" }}>Check-In/Out</h4></span>
                        <div className="page-title-actions">
                          <a href={`/dashboard/hub/operation`} className="btn btn-primary " style={{ width: "80%" }} >View All</a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "50px" }} />
                <div className="col-sm colpadding singleCard">
                  <div className=" text-center cardbg singleCard" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-left">
                          <img className="float-left" src={process.env.PUBLIC_URL + '/images/hub/pmr_icon.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pt-3" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "20px", textAlign: "center" }}>PMR</h4></span>
                        <div className="page-title-actions">
                          <a href={`/dashboard/hub/pmr`} className="btn btn-primary" style={{ width: "80%" }}  >View All</a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "50px" }} />

                <div className="col-sm colpadding singleCard">
                  <div className=" text-center cardbg singleCard" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-left ">
                          <img className="float-left" src={process.env.PUBLIC_URL + '/images/hub/hubmeterIcon.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pt-3" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "20px", textAlign: "center" }}>Meter Data</h4></span>
                        <div className="page-title-actions">
                          <a href={`/dashboard/hub/meterdata`} className="btn btn-primary" style={{ width: "80%" }}  >View All</a>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "50px" }} />

                <div className="col-sm colpadding singleCard">
                  <div className=" text-center cardbg singleCard" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-left">
                          <img className="float-left" src={process.env.PUBLIC_URL + '/images/hub/hubstatus.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pt-3" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "20px", textAlign: "center" }}>Hub Status</h4></span>
                        <div className="page-title-actions">
                          <a href={`/dashboard/hub/hubstatus`} className="btn btn-primary" style={{ width: "80%" }} >View All</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="vl d-none d-md-block d-lg-block d-xl-block "></div> */}
                {/* <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "50px" }} /> */}
                </div>
                <div className="row">

                <div className="col-sm colpadding singleCard">
                  <div className=" text-center cardbg singleCard" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-left">
                          <img className="float-left" src={process.env.PUBLIC_URL + '/images/hub/hazard.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pt-3" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "20px", textAlign: "center" }}>Hazard Report</h4></span>
                        <div className="page-title-actions">
                          <a href={`/dashboard/hub/hazardreport`} className="btn btn-primary" style={{ width: "80%" }} >View All</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "50px" }} />
                <div className="col-sm colpadding singleCard">
                  <div className=" text-center cardbg singleCard" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-left">
                          <img className="float-left" src={process.env.PUBLIC_URL + '/images/hub/attendance.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pt-3" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "20px", textAlign: "center" }}>Attendance Report</h4></span>
                        <div className="page-title-actions">
                          <a href={`/dashboard/hub/attendance`} className="btn btn-primary" style={{ width: "80%" }} >View All</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "50px" }} />
                <div className="col-sm colpadding singleCard">
                  <div className=" text-center cardbg singleCard" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-left">
                          <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/cleanKm.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pt-3" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "20px", textAlign: "center" }}>Vehicle Status</h4></span>
                        <div className="page-title-actions">
                          <a href={`/dashboard/hub/vehicleStatus`} className="btn btn-primary" style={{ width: "80%" }} >View All</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "50px" }} />
                <div className="col-sm colpadding singleCard">
                  <div className=" text-center cardbg singleCard" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-left">
                          <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/Station.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pt-3" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "20px", textAlign: "center" }}>Charger Uptime</h4></span>
                        <div className="page-title-actions">
                          <a href={`/dashboard/hub/chargerUptime`} className="btn btn-primary" style={{ width: "80%" }} >View All</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

               

              </div>


            </div>
          </div>
        </div>
        {userrole.role === "Admin" ? (
          <div className="container-fluid">
            <div className="row rowpadding topCardDesigns mr-1">
              <div className="col-6 p-3">
                <div className="col-12 ml-2 ">
                  <img src={process.env.PUBLIC_URL + '/images/user_logo/assignUser.svg'} style={{ height: "34px", width: "32px" }} alt="" />
                  <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>Assign User Access</span>
                  <div className="page-title-subheading pt-2" style={{ fontSize: "12px", color: "#bc0078", fontWeight: "550" }}>Give privilages and assign roles to different users</div>
                </div>
              </div>
              <div className="col-6">
                <div className="text-right m-3 pt-3">
                  <a href={`/dashboard/hub/hubassignaccess`} className="btn btn-primary" >Assign User Access</a>
                </div>
              </div>
            </div>
          </div>
        ) : (<span ></span>)
        }
        <div className="container-fluid">
          <div className="row rowpadding topCardDesigns mr-1">
            <div className="col-6 p-3">
              <div className="col-12 ml-2 ">
                <img src={process.env.PUBLIC_URL + '/images/hub/QRCode.svg'} style={{ height: "34px", width: "32px" }} alt="" />
                <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>Binding QR Code</span>
                <div className="page-title-subheading pt-2" style={{ fontSize: "12px", color: "#bc0078", fontWeight: "550" }}>Binding QR code with Vehicle Number</div>
              </div>
            </div>
            <div className="col-6">
              <div className="text-right m-3 pt-3">
                <a href={`/dashboard/hub/bindingqrcode`} className="btn btn-primary" >Bind QR Code</a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row rowpadding topCardDesigns mr-1">
            <div className="col-12 p-3">
              <div className="col-12 ml-2 ">
                <img src={process.env.PUBLIC_URL + '/images/hub/masterData.svg'} style={{ height: "34px", width: "32px" }} alt="" />
                <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>Master Data</span>
              </div>
              <div className="col-12">
                <div className="text-left m-3 pt-3">
                  <a href={`/dashboard/hub/qrcodelistmaster`} className="btn btn-primary m-1 mr-2" >Add QR Code</a>
                  <a href={`/dashboard/hub/vehiclenolistmaster`} className="btn btn-primary m-1 mr-2" >Add Vehicle Number</a>
                  <a href={`/dashboard/hub/vehicletypemaster`} className="btn btn-primary m-1 mr-2" >Add Vehicle Type</a>
                  <a href={`/dashboard/hub/batterytypemaster`} className="btn btn-primary m-1 mr-2" >Add Battery Type</a>
                  <a href={`/dashboard/hub/fleetlistmaster`} className="btn btn-primary m-1 mr-2" >Add Fleet Operator</a>
                  <a href={`/dashboard/hub/hubnamelistmaster`} className="btn btn-primary m-1 mr-2" >Add Hub Name</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* user list*/}
        {userrole.role === "Admin" ? (
          <div className="row rowpadding  mr-1">
            <div className="col">
              <div className="row ">
                <div className="col-md-12 col-lg-12  ">

                  <HubUserTableView></HubUserTableView>

                </div>
              </div>
            </div>
          </div>
        ) : (<span ></span>)
        }

        <div className="row rowpadding  mr-1">
          <div className="col">
            <div className="row ">
              <div className="col-md-12 col-lg-12  ">

                <VehicleDetailsTable></VehicleDetailsTable>

              </div>
            </div>
          </div>
        </div>
        <div className="row rowpadding  mr-1">
          <div className="col">
            <div className="row ">
              <div className="col-md-12 col-lg-12  ">

                <HubtransactionDetailsTable></HubtransactionDetailsTable>

              </div>
            </div>
          </div>
        </div>

        {userrole.role === "Admin" ? (
        <div className="row rowpadding  mr-1">
          <div className="col">
            <div className="row">
              <div className="col-md-12 col-lg-12  ">

                <Hubcustomerotp></Hubcustomerotp>

              </div>
            </div>
          </div>
        </div>
          ) : (<span ></span>)
        }

        {/* 
        <div className="row rowpadding topCardDesigns mr-1">
          <div className="col">
            <div className="row py-2">
              <div className="col-md-12 col-lg-12 sideCardDesigns ">
                <div className="mb-3 h-100 singleCard">
                  <Vehiclecheckin></Vehiclecheckin>
                </div>
              </div>
            </div>
          </div>
        </div> */}



      </div>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    fetchAllOpsData: state.hub.getAllHubOperationData
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getAllOpsData: () => dispatch(getAllHubOperationData()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HubCardModel);

