import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getPriceSingleAction } from "../../../store/actions/priceAction";

class ViewSinglePriceDetails extends Component {
    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getSinglePrice(params.id)
    }

    render() {
        // console.log("AfcEA", this.props.pricesingledetail)
        return (

            <div className="container-fluid">
                <div className="sideCardDesigns card-signin">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>View Price Plan</b>
                        </h4>

                        <div className="row rowpadding  justify-content-between">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="operator">Network Operator</label>
                                        <div className="form-label-group">
                                            <input type="text" id="operator" name="operator" className="form-control" value={this.props.pricesingledetail.operator} disabled />
                                            <label htmlFor="operator">Operator</label>
                                        </div>
                                        <label className="formLable" htmlFor="enterprise">Enterprise</label>
                                        <div className="form-label-group">
                                            <input type="text" id="enterprise" name="enterprise" className="form-control" value={this.props.pricesingledetail.enterprise} disabled />
                                            <label htmlFor="enterprise">Enterprise</label>
                                        </div>

                                        <label className="formLable" htmlFor="name">Price Name</label>
                                        <div className="form-label-group">
                                            <input type="text" id="name" name="name" className="form-control" value={this.props.pricesingledetail.name} disabled />
                                            <label htmlFor="name">Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="plantype">Subscription Plan</label>
                                        <div className="form-label-group">
                                            <input type="text" id="plantype" name="plantype" className="form-control" value={this.props.pricesingledetail.plantype} disabled />
                                            <label htmlFor="plantype">Subscription Plan</label>
                                        </div>

                                        <label className="formLable" htmlFor="currency">Currency</label>
                                        <div className="form-label-group">
                                            <input type="text" id="currency" name="currency" className="form-control" value={this.props.pricesingledetail.currency} disabled />
                                            <label htmlFor="currency">Currency</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="country">Country</label>
                                        <div className="form-label-group">
                                            <input type="text" id="country" name="country" className="form-control" value={this.props.pricesingledetail.country} disabled />
                                            <label htmlFor="country">Country</label>
                                        </div>

                                        <label className="formLable" htmlFor="state">State</label>
                                        <div className="form-label-group">
                                            <input type="text" id="state" name="state" className="form-control" value={this.props.pricesingledetail.state} disabled />
                                            <label htmlFor="state">State</label>
                                        </div>

                                        <label className="formLable" htmlFor="isPriceGroup">Is Price Group</label>
                                        <div className="form-label-group">
                                            <input type="text" id="isPriceGroup" name="isPriceGroup" className="form-control" value={this.props.pricesingledetail.isPriceGroup} disabled />
                                            <label htmlFor="isPriceGroup">Is Price Group</label>
                                        </div>

                                        <label className="formLable" htmlFor="status">Status</label>

                                        <div className="form-label-group pb-3">
                                            <input type="text" id="status" name="status" className="form-control" value={this.props.pricesingledetail.status} disabled />
                                            <label htmlFor="status">Status</label>
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb ">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Days of Week</li>
                            </ol>
                        </nav>
                        {this.props.pricesingledetail && this.props.pricesingledetail.scheduleday.map((day) =>
                            <div className="form-check form-check-inline containerAme p-2" key={day.code}>
                                &nbsp;&nbsp;&nbsp;
                                <label className="form-check-label" htmlFor={day.code}>{day.name}</label>
                            </div>
                        )}
                        <br />

                        <div className="my-4" />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add Price Component</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Power</th>
                                                <th scope="col">kW</th>
                                                <th scope="col">Price type</th>
                                                <th scope="col">Price Unit</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Ideal Fee</th>
                                                <th scope="col">Grace Period</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.pricesingledetail && this.props.pricesingledetail.pricecomponent.map((price) =>
                                                <tr>
                                                    <td>{price.power}</td>
                                                    <td>{price.kwh} </td>
                                                    <td>{price.pricetype} </td>
                                                    <td>{price.priceunit} </td>
                                                    <td>{price.amount}</td>
                                                    <td>{price.idlefee}</td>
                                                    <td>{price.graceperiod} </td>

                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-md-12 col-lg-12 ">
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/pricing">Back</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

}

const mapStateToProps = function (state) {
    return {
        pricesingledetail: state.price.fetchPrice,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSinglePrice: (id) => dispatch(getPriceSingleAction(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSinglePriceDetails));           