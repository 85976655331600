import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { configurerListAction, deactivateConfigurerAction } from "../../../store/actions/customerAction";
import Table from "./customerTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';
import PureBreadcrumbs from "../Breadcrums"
import Swal from 'sweetalert2';


const Configurerlist = (props) => {
  useEffect(() => {
    props.fetCustomerList();
  }, [])


  const user = JSON.parse(localStorage.getItem('user'));
  const activateDeactivateConfigurer=(mobile,is_deleted)=>{

  Swal.fire({
    title: 'Are you user status?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#1e295d',
    confirmButtonText: 'Yes!'
  }).then((result) => {
    if (result.value) {
     
      props.deactivateConfigrurer(mobile,is_deleted);
      setTimeout(() => {
        window.location.reload();
      }, 5000); 
    }
  });
}

  const data = props.customerlist && props.customerlist.fcm_list

  const columns = useMemo(

    () => [
      {
        header: 'Confirurer Name',
        accessor: 'name'
      },
      {
        header: 'Mobile',
        accessor: 'mobile'
      },
      {
        header: 'Email ID',
        accessor: 'email'
      },
      {
        header: 'OTP',
        accessor: 'test_ran'
      },
      {
        header: 'Password Date',
        id: 'passworddat',
        accessor: d => {
          return moment(d.passworddat)
            .local()
            .format("DD-MM-YYYY");
        }
      },

      {
        header: 'Date',
        id: 'createdat',
        accessor: d => {
          return moment(d.createdat)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Status',
        id: "is_deleted",
        accessor: d => {
          return d.is_deleted == 0 ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
        }
      },

      {
        header: 'Actions',
        id:'action',
        accessor: s=> {
          return (
            
            <div>
              
              &nbsp;&nbsp;&nbsp;
              {user.role === "Admin" ? (
                  <span>
                  {s.is_deleted == 0  ? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash"  onClick={() => activateDeactivateConfigurer(s.mobile, 1) } >
                      <i className="fas fa-user-check"></i></a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active" onClick={() => activateDeactivateConfigurer(s.mobile, 0)}>
                  <i className="fas fa-user-slash"></i></a>}
              </span>
              ):( <span ></span>)
            }
              &nbsp;&nbsp;&nbsp;

            </div>

          );
        }
        },
    ],
    []
  );
  useEffect(() => {
    props.fetCustomerList();
  }, [])
  return (<>



    <div className="row py-2">
      <div className="col">

        <div className="row py-2">
          <div className="col-md-12 col-lg-12">
            <div className="mb-3 h-100 sideCardDesigns">
              <div className="card-header singleCard">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <strong>Configurer Details</strong>
                </div>
      
              </div>

              {!data.length ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
                (<div className="App"> <Table data={data} columns={columns}></Table></div>)}
            </div>
          </div>
        </div> 
        </div>
    </div>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.customer.isLoading,
    customerlist: state.customer.fetchconfigurerList,
    response:state.customer.deactivateConfigurer,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetCustomerList: () => dispatch(configurerListAction()),
    deactivateConfigrurer:(mobile,is_deleted)=>dispatch(deactivateConfigurerAction(mobile,is_deleted))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Configurerlist);