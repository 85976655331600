import { BASE_URL, CITY_URL, COUNTRY_URL, PRIVILEGE_URL, ROLE_URL, STATE_URL,ROLE_NP_URL,GET_LOGIN_OTP,VALIDATE_SIGNIN_OTP_URL,FORGOT_PASSWORD_URL,CUSTOMER_BASE_URL,RESEND_OTP_URL} from "../../components/config/configs"
export const countryAction = () => {
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
     return fetch(BASE_URL+COUNTRY_URL, {
       method: "GET",
       headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
     })
       .then(resp => resp.json())
       .then(data => {
           dispatch({type: 'COUNTRY_LIST', payload: data})
       })
   }
 }
 export const stateAction = (cc) => {
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
    return dispatch => {
     return fetch(BASE_URL+STATE_URL+"?countrycode="+cc, {
       method: "GET",
       headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
     })
       .then(resp => resp.json())
       .then(data => {
           dispatch({type: 'STATE_LIST', payload: data})
       })
   }
 }
 export const cityAction = (sn) => {
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
    return dispatch => {
     return fetch(BASE_URL+CITY_URL+"?statename="+sn, {
       method: "GET",
       headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
     })
       .then(resp => resp.json())
       .then(data => {
           dispatch({type: 'CITY_LIST', payload: data})
       })
   }
 }

 export const roleAction = (role) => {
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
   return dispatch => {
   return fetch(BASE_URL+ROLE_URL, {
     method: "GET",
     headers: {
      'Authorization': bearer,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
   })
     .then(resp => resp.json())
     .then(data => {
      // console.log(" roleAction----",data);
         dispatch({type: 'ROLE_LIST', payload: data})
     })
 }
}


export const roleActionByNetworkOperator = (access) => {
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
   return dispatch => {
   return fetch(BASE_URL+ROLE_NP_URL+"?access="+access, {
     method: "GET",
     headers: {
      'Authorization': bearer,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
   })
     .then(resp => resp.json())
     .then(data => {
      // console.log(" roleActionByNetworkOperator----",data);
         dispatch({type: 'ROLE_LIST_BY_NP', payload: data})
     })
 }
}

export const privilegeAction = (privilege) => {
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
   return dispatch => {
   return fetch(BASE_URL+PRIVILEGE_URL+"?rolekey="+privilege, {
     method: "GET",
     headers: {
      'Authorization': bearer,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
   })
     .then(resp => resp.json())
     .then(data => {
      // console.log(" privilegeAction----",data);
         dispatch({type: 'PRIVILEGE_LIST', payload: data})
     })
 }
}

export const getLoginDashboardOTP = (phone) => {
  // console.log(phone)
  return dispatch => {
    return fetch(BASE_URL + GET_LOGIN_OTP, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "phone": parseInt(phone),
      })
    }).then(resp => resp.json())
      .then(data => {
        //  console.log("LOGIN_OTP", data)
        dispatch({ type: 'LOGIN_OTP', payload: data })
      })
  }
}

export const LoginDashboardOTPValidate = (phone, otp) => {
  // console.log(phone, otp)
  return dispatch => {
    return fetch(BASE_URL + VALIDATE_SIGNIN_OTP_URL, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "phone": parseInt(phone),
        "otp": parseInt(otp),
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("LOGIN_OTP_VALIDATE", data)
        localStorage.setItem('forgotpassword', JSON.stringify(data));
        dispatch({ type: 'LOGIN_OTP_VALIDATE', payload: data })
      })
  }
}

export const forgotPassword = (phone,password) => {
  // const { phone } = JSON.parse(localStorage.getItem('phone'))
  // console.log(phone, password)
  return dispatch => {
    return fetch(BASE_URL + FORGOT_PASSWORD_URL, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "phone": parseInt(phone),
        "password": password,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("FORGOT_PASSWORD", data)
        dispatch({ type: 'FORGOT_PASSWORD', payload: data })
      })
  }
}

export const resendOtpAction = () => {
  const { phone } = JSON.parse(localStorage.getItem('forgotpassword'))
  // console.log(phone)
  return dispatch => {
    return fetch(CUSTOMER_BASE_URL + RESEND_OTP_URL, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "mobile": parseInt(phone),
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("RESEND_OTP", data)
        dispatch({ type: 'RESEND_OTP', payload: data })
      })
  }
}

