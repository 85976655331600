import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { getInventoryAssetByIDAction, updateInventoryAssetAction } from "../../../store/actions/inventoryAction";

class EditInventoryAssets extends Component {
    state = {
        invaseet: {
            chargerserialno: '',
            chargeboxid: '',
            ptype: '',
            assembled: '',
            firmwareversion: '',
            dispatchdate: '',
            rfidno: '',
            tagid: '',
            simcard: '',
            acpboard: '',
            sunboard: '',
            instructionboard: '',
            stand: '',
            couriername: '',
            trackingnumber: '',
            dispatchbrand: '',
            address: '',
            prnumber: '',
            remark: '',
            projectcode: '',
            sealable: '',
            statuss: '',
            enteredby: '',
            error: '',
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const token = '';
        this.props.viewInventoryasset(params.id, token)
    }
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.viewasset !== this.props.viewasset) {
            this.setState({
                invaseet: this.props.viewasset,
            })
        }
    }


    handleChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            invaseet: { ...prevState.invaseet, [e.target.name]: e.target.value }
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { match: { params } } = this.props;
        this.props.updateInventoryasset(params.id, this.state.invaseet)
        alert("Inventory Asset Updated")
        //  this.props.history.push('/dashboard/inventory')

    }

    render() {
        if (this.props.updateasset && this.props.updateasset.result === 'success') {
            return <Redirect to='/dashboard/inventory' />;
        }
        return (
            <>
                <div className="container-fluid">
                    <div className="sideCardDesigns card-signin">
                        <div className="card-body">
                            <h4 className="text-center formHeading">
                                <b>Edit Inventory Asset</b>
                            </h4>

                            <form className="form mt-5" onSubmit={this.handleSubmit}>

                                <div className="row rowpadding  justify-content-between">
                                    <div className="col-md-6 col-sm-12 ">
                                        <div className="col-12  formContainer">
                                            <div className="col-12">
                                                <label className="formLable" htmlFor="chargerserialno">Charger Serial Number</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="chargerserialno"
                                                        name="chargerserialno"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.chargerserialno}
                                                    />
                                                    <label htmlFor="chargerserialno">Charger Serial Number</label>
                                                </div>
                                                <label className="formLable" htmlFor="chargeboxid">ChargeBox ID</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="chargeboxid"
                                                        name="chargeboxid"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.chargeboxid}
                                                    />
                                                    <label htmlFor="chargeboxid">Enter ChargeBox ID</label>
                                                </div>

                                                <label className="formLable" htmlFor="ptype">Product Type</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="ptype"
                                                        name="ptype"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.ptype}
                                                    />
                                                    <label htmlFor="ptype">Enter Product Type</label>
                                                </div>

                                                <label className="formLable" htmlFor="assembled">Assembled By</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="assembled"
                                                        name="assembled"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.assembled}
                                                    />
                                                    <label htmlFor="assembled">Assembled By</label>
                                                </div>

                                                <label className="formLable" htmlFor="firmwareversion">Firmware Version</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="firmwareversion"
                                                        name="firmwareversion"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.firmwareversion}
                                                    />
                                                    <label htmlFor="firmwareversion">Enter Firmware Version</label>
                                                </div>

                                                <label className="formLable" htmlFor="dispatchdate">Dispatch Date</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        id="dispatchdate"
                                                        name="dispatchdate"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.dispatchdate}
                                                    />
                                                    <label htmlFor="dispatchdate">Enter Dispatch Date</label>
                                                </div>

                                                <label className="formLable" htmlFor="rfidno">RFID Number</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="rfidno"
                                                        name="rfidno"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.rfidno}
                                                    />
                                                    <label htmlFor="rfidno">RFID Number</label>
                                                </div>

                                                <label className="formLable" htmlFor="tagid">Tag ID</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="tagid"
                                                        name="tagid"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.tagid}
                                                    />
                                                    <label htmlFor="tagid">Tag ID</label>
                                                </div>

                                                <label className="formLable" htmlFor="simcard">Sim Card</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="simcard"
                                                        name="simcard"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.simcard}
                                                    />
                                                    <label htmlFor="simcard">Sim Card</label>
                                                </div>

                                                <label className="formLable" htmlFor="acpboard"> ACP Board Attached? </label>
                                                <div className="form-label-group">
                                                    <select className="form-control border-radius" name="acpboard" onChange={this.handleChange} value={this.state.invaseet.acpboard}>
                                                        <option >ACP Board Attached?</option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                </div>


                                                <label className="formLable" htmlFor="sunboard">Sun Board Attached?</label>
                                                <div className="form-label-group">
                                                    <select className="form-control border-radius" name="sunboard" onChange={this.handleChange} value={this.state.invaseet.sunboard}>
                                                        <option >Sun Board Attached?</option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                </div>

                                                <label className="formLable" htmlFor="instructionboard">Instruction Board Attached?</label>
                                                <div className="form-label-group">
                                                    <select className="form-control border-radius" name="instructionboard" onChange={this.handleChange} value={this.state.invaseet.instructionboard}>
                                                        <option >Instruction Board Attached?</option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 ">
                                        <div className="col-12  formContainer ">
                                            <div className="col-12 ">
                                                <label className="formLable" htmlFor="stand">Charger Mounting stand?</label>
                                                <div className="form-label-group">
                                                    <select className="form-control border-radius" name="stand" onChange={this.handleChange} value={this.state.invaseet.stand}>
                                                        <option >Charger Mounting stand?</option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                </div>

                                                <label className="formLable" htmlFor="couriername">Courier Name</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="couriername"
                                                        name="couriername"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.couriername}
                                                    />
                                                    <label htmlFor="couriername">Courier Name</label>
                                                </div>

                                                <label className="formLable" htmlFor="trackingnumber">Tracking Number</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="trackingnumber"
                                                        name="trackingnumber"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.trackingnumber}
                                                    />
                                                    <label htmlFor="trackingnumber">Tracking Number</label>
                                                </div>

                                                <label className="formLable" htmlFor="dispatchbrand">Dispatching under Brand</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="dispatchbrand"
                                                        name="dispatchbrand"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.dispatchbrand}
                                                    />
                                                    <label htmlFor="dispatchbrand">Dispatching under Brand</label>
                                                </div>
                                                <label className="formLable" htmlFor="address">Address</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.address}
                                                    />
                                                    <label htmlFor="address">Address</label>
                                                </div>

                                                <label className="formLable" htmlFor="prnumber">PR Number</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="prnumber"
                                                        name="prnumber"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.prnumber}
                                                    />
                                                    <label htmlFor="prnumber">Enter PR Number</label>
                                                </div>


                                                <label className="formLable" htmlFor="projectcode">Project Code</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="projectcode"
                                                        name="projectcode"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.projectcode}
                                                    />
                                                    <label htmlFor="projectcode">Project Code</label>
                                                </div>



                                                <label className="formLable" htmlFor="sealable">Sales order type?</label>
                                                <div className="form-label-group">
                                                    <select className="form-control border-radius" name="sealable" onChange={this.handleChange} value={this.state.invaseet.sealable}>
                                                        <option >Sales order type?</option>
                                                        <option>Saleable</option>
                                                        <option>Non saleable</option>
                                                        <option>Internal Use</option>
                                                    </select>
                                                </div>

                                                <label className="formLable" htmlFor="remark">Remark</label>
                                                <div className="form-label-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="remark"
                                                        name="remark"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.remark}
                                                    />
                                                    <label htmlFor="remark">Remark</label>
                                                </div>
                                                <label className="formLable" htmlFor="statuss">Status</label>
                                                <div className="form-label-group">
                                                    <select className="form-control border-radius" name="statuss" onChange={this.handleChange} value={this.state.invaseet.statuss}>
                                                        <option value="" >Status</option>
                                                        <option>In Stock</option>
                                                        <option>Dispatched</option>
                                                    </select>
                                                </div>
                                                <label className="formLable" htmlFor="enteredby">Entered By</label>
                                                <div className="form-label-group pb-3">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="enteredby"
                                                        name="enteredby"
                                                        onChange={this.handleChange}
                                                        value={this.state.invaseet.enteredby}
                                                    />
                                                    <label htmlFor="enteredby">Entered By</label>
                                                </div>
                                                <br />
                                                <br />
                                                <br />

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-xl-6 col-sm-6 col-12">
                                        <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/inventory">Back</a>
                                    </div>

                                    <div className="col-xl-6 col-sm-6 col-12 float-right">
                                        <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">Update Inventory Assets</button>
                                    </div>
                                    <hr className="my-4" />
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = function (state) {
    return {
        viewasset: state.inventory.getInventoryAssetByID,
        updateasset: state.inventory.updateInventoryAsset,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        viewInventoryasset: (id) => dispatch(getInventoryAssetByIDAction(id)),
        updateInventoryasset: (id, invaseet) => dispatch(updateInventoryAssetAction(id, invaseet))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditInventoryAssets)); 