import React, { useEffect } from "react"
import { connect } from "react-redux";
import { getPriceByCreatorAction } from "../../../store/actions/priceAction";
import { getSubscriptionPlanByCreatorAction } from "../../../store/actions/subscriptionAction";
import PureBreadcrumbs from "../Breadcrums"

const PriceCardModel = (props) => {
  useEffect(() => {
    props.fetSubscriptionplan();
    props.fetPrice();
  }, [])
  const activeprice = props.pricelistdata && props.pricelistdata.filter(pr => pr.status === true);
  const activesubplan = props.sublistdata && props.sublistdata.filter(sp => sp.display === true);

  return (
    <>
      <div className="row ">
        <div className="col col-sm-4 ">
          <PureBreadcrumbs />
        </div>
      </div>

      <div className="row rowpadding mb-4">
        <div className="col-sm-12 col-md-6">
          <div className="col-md-12  sideCardDesigns mt-1 ">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold"> Subscriptions</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{props.sublistdata !== null ? props.sublistdata.length : 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding ">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold"> Active</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{activesubplan != null ? activesubplan.length : 0}</div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3 ">
                <div className="row">
                  <div className="col-12 align-right">
                    <div className="float-center">
                      <a href={`/dashboard/plans/addsubscription`} className="btn btn-primary btn-block" ><span className="mr-1">Add Subscription Plan</span></a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="col-md-12  sideCardDesigns mt-1 ">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold"> Price Plans</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{props.pricelistdata !== null ? props.pricelistdata.length : 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding ">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold"> Active</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{activeprice != null ? activeprice.length : 0}</div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3 ">
                <div className="row">
                  <div className="col-12">
                    <div className="float-center">
                      <a href={`/dashboard/pricing/addprice`} className="btn btn-primary btn-block" ><span className="mr-1">Add Price Plan</span></a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    pricelistdata: state.price.fetchPriceList,
    sublistdata: state.subscriptionplan.fetcSubscriptionplan
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetSubscriptionplan: () => dispatch(getSubscriptionPlanByCreatorAction()),
    fetPrice: () => dispatch(getPriceByCreatorAction())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PriceCardModel);