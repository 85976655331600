import React from 'react';
import ReportCard from './reportcard';


import "./report.css"
function Report() {
  return (
    <div className="container-fluid">
     <ReportCard></ReportCard>
   
     
    </div>
  );
}
export default Report;