import React, { useMemo, useEffect } from "react"
import moment from "moment";
import "../report/table.css"
import { connect } from "react-redux";
import { deactivateAction, getUsersAction } from "../../../store/actions/userAction"
import Table from "./accessTable";
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2'

const UserTableView=(props)=>{
  useEffect(()=>{
    props.getUserList();
    
  },[])
  const userrole = JSON.parse(localStorage.getItem('user'));
  const activateDeactivateUser=(user, userstatus)=>{

//popup message for confirmation

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#1e295d',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        props.deactivateUser(user, userstatus);
        window.location.reload();
      }
    });
  }

//data for tabel
    const data = props.userlist && props.userlist
    const columns = useMemo(

        () => [
          {
            header: 'User ID',
            accessor: 'username'
          },
          {
            header: 'Name',
            id:'name',
            accessor: user => {
                return (`${user.firstname } ${user.lastname}`)
            }
            
          },
          {
            header: 'Role',
            accessor: 'role'
          },
          {
            header: 'Organization',
            accessor: 'organization'
          },
          {
            header: 'Phone',
            accessor: 'phone'
          },
          {
            header: 'City',
            accessor: 'city'
          },
          
          {
            header: 'Date',
            id: 'createdat',
        accessor: d => {
          return moment(d.createdat)
            .local()
            .format("DD-MM-YYYY");
        }
          },
          {
            header: 'Status',
            id:"accountstatus",
            accessor: s => {
              return s.accountstatus ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
            }
        },
    
        {
          header: 'Action',
          id:'action',
          accessor: user=> {
            return (
              
              <div>
                <span>
                <a rel="tooltip" title="View " className="table-action view" href={`/dashboard/access/viewaccess/${user.id}`}>
                    <i className="fas fa-eye"></i></a>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span>
                <a rel="tooltip" title="Edit " className="table-action edit" href={`/dashboard/access/editaccess/${user.id}`}>
                    <i className="fa fa-edit"></i></a>
                </span>
                &nbsp;&nbsp;&nbsp;
                {userrole.role === "Admin" ? (
                <span>
                    {user.accountstatus ? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash"  onClick={() => activateDeactivateUser(user, false) } >
                        <i className="fas fa-user-check"></i></a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active" onClick={() => activateDeactivateUser(user, true)}>
                    <i className="fas fa-user-slash"></i></a>}
                </span>
                ):( <span ></span>)
              }
                &nbsp;&nbsp;&nbsp;

              </div>
              );
          } 
        },
      ],
        []
      );
    useEffect(()=>{
        props.getUserList();
    },[])
    return(
        <div className="row py-2">
        <div className="col">
 
        <div className="row py-2">
        <div className="col-md-12 col-lg-12">
        <div className="mb-3 h-100 sideCardDesigns">
        <div className="card-header singleCard">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
          <strong>User Details</strong>
        </div>
        
        </div>
    
        {!data.length ?
          (<div style={{display: "flex",  alignItems: "center",justifyContent: "center"}}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{display: "flex",  alignItems: "center",justifyContent: "center"}} /></h3></div>) 
     : 
    (<div className="App"> <Table data={data} columns={columns}></Table></div>) }
    
    
    </div>
  </div>
  </div>  </div>
  </div>

    )
}

const mapStateToProps = (state) =>{
    return{
        userlist: state.users.fetchList,
        response: state.users.deactivateUser
    }
  }
  const mapDispatchToProps=dispatch =>{
    return{
        getUserList: (user) => dispatch(getUsersAction(user)),
        deactivateUser: (user, userstatus) => dispatch(deactivateAction(user, userstatus))
        
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(UserTableView);