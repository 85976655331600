import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getAssetListAction,
  getUtilityAction,
} from "../../../store/actions/assetAction";
import { getconnectorTypeListAction } from "../../../store/actions/ctAction";
import { getLocationAction } from "../../../store/actions/locationAction";
import { operatorAction } from "../../../store/actions/opAction";
import "./asset.css";
import LocationPage from "./location/locations";
// import Enterprise from './enterprise/enterprise';
import AssetTableView from "./asset/assetview";
import ViewConnector from "./connectors/viewconnector";
import UtilityList from "./utility/utilitylist";
import PureBreadcrumbs from "../Breadcrums";

const AssetCard = (props) => {
  const role = "Enterprise";
  useEffect(() => {
    props.getLocation();
    props.assetdata();
    props.getConnectorTypeData();
    props.epData(role);
    props.UtilityData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row  ">
        <div className="col col-sm-4 ">
          <PureBreadcrumbs />
        </div>
      </div>
      <div className="row rowpadding topCardDesigns m-1">
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-sm colpadding singleCard">
                
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-right pl-5 ">
                            <img className="float-right" src={process.env.PUBLIC_URL + '/images/assetLocation.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.locations !== null ? props.locations.length: 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Location</h5></span>
                          <div className="page-title-actions">
                            <a href={`/dashboard/assets/addnewlocation`} className="btn btn-primary " style={{ width: "80%" }} >Add New Location</a>
                          </div>
                        </div>
                      </div>
                    </div>
                
                </div>

                <div className="col-sm colpadding singleCard">
                  
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/assetIcon.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.asset !== null ? props.asset.length : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Assets</h5></span>
                                <div className="page-title-actions">
                            <a href={`/dashboard/assets/createasset`} className="btn btn-primary " style={{ width: "80%" }} >Add New Assets</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                </div>


                <div className="col-sm colpadding singleCard ">
                  
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5 ">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/utilityIcon.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.utility !== null ? props.utility.length : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Utility</h5></span>
                          <div className="page-title-actions">
                            <a href={`/dashboard/assets/utility`} className="btn btn-primary " style={{ width: "80%" }} >Add New Utility</a>
                          </div>
                        </div>
                      </div>
                    </div>

                
                </div>


                <div className="col-sm colpadding singleCard">
               
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/connectorIcon.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.ctlist != null ? props.ctlist.length : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Connectors</h5></span>
                          <div className="page-title-actions">
                            <a href={`/dashboard/assets/addconnectortype`} className="btn btn-primary " style={{ width: "80%" }} >Add New Connector</a>
                          </div>
                        </div>
                      </div>
                    </div>             
                </div>

              </div>
            </div>
          </div>

    

      <div className="row g-2">
        <div className="col-md-12">
          <div>
            <div className="row py-4">
              <LocationPage></LocationPage>
            </div>
            <div className="row py-4">
              <AssetTableView></AssetTableView>
            </div>
            <div className="row py-4">
              <UtilityList></UtilityList>
            </div>
            <div className="row py-4">
              <ViewConnector></ViewConnector>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    asset: state.asset.fetchAssetList,
    loading: state.asset.isLoading,
    locations: state.location.fetchLocation,
    ctlist: state.ct.fetchConnectorTypeList,
    oplist: state.operator.fetchOperator,
    utility: state.asset.fetchAllUtility,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    assetdata: () => dispatch(getAssetListAction()),
    getLocation: () => dispatch(getLocationAction()),
    getConnectorTypeData: () => dispatch(getconnectorTypeListAction()),
    epData: (role) => dispatch(operatorAction(role)),
    UtilityData: () => dispatch(getUtilityAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssetCard);
