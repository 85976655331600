import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { DateRangePicker } from "react-dates";
import Table from "./vehicleparkingTable";
import Loader from 'react-loader-spinner';
import { useParams } from "react-router-dom";
import { getAllHubListData, getVehicalparkingAction } from "../../../../store/actions/hubActions";
import PureBreadcrumbs from "../../Breadcrums";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";


const VehhicleparkingReport = (props) => {
  const initialValues = { hubname: "TTC" }
  const [singlehubname, setSinglehubname] = useState(initialValues)    //   const [startDate, setStartDate] = useState(Moment().subtract(6, "days"));
  const { chargeboxid } = useParams()
  const [startDate, setStartDate] = useState(Moment().subtract(6, "days"));
  const [endDate, setEndDate] = useState(Moment().add(1, "days"));
  const [focusedInput, setFocusedInput] = useState(Moment());
  const isOutsideRange = (day) => {
    day.isAfter(Moment()) || day.isBefore(Moment().subtract(30, "days"));
  };

  // console.log("singlehubname", singlehubname)
  useEffect(() => {
    let hubb = "All"
    props.getHubListData();

  }, []);
  useEffect(() => {
    let hubname = "All"
    const today = Moment();
    const current = today.add(1, "days").format("DD-MM-YYYY");
    const last = today.subtract(6, "days").format("DD-MM-YYYY");
    props.chargerDowntime(singlehubname, last, current)

  }, []);

  const handleChange = (e) => {
    let hubname = e.target.value
    const { name, value } = e.target;
    setSinglehubname({ ...singlehubname, [name]: value });

  };

  const handleDateClick = () => {
    if (
      startDate && startDate.format("ll") &&
      endDate && endDate.format("ll")
    ) {
      const current = startDate.format("DD-MM-YYYY");
      const last = endDate.format("DD-MM-YYYY");
      props.chargerDowntime(singlehubname, current, last);
      toast("Command Triggered");    
    }
  };
  const statsdata = props.chargerstats && props.chargerstats.result

  const columns = useMemo(() => [
    {
      header: 'Hub Name',
      accessor: 'hubname',
    },
    {
      header: 'Fleet Operator',
      accessor: 'fleetoperator',
    },
    {
      header: 'Vechile No',
      accessor: 'vechileno',
    },
    {
      header: 'In SOC',
      accessor: 'insoc',
    },
    {
      header: 'Out SOC',
      accessor: 'outsoc',
    },
    {
      header: 'In Date',
      accessor: 'indate',
    },
    {
      header: 'In Time',
      accessor: 'intime',
    },
    {
      header: 'Out Date',
      accessor: 'outdate',
    },
    {
      header: 'Out Time',
      accessor: 'outtime',
    },
    {
      header: 'Total Kw Consume',
      accessor: 'totalkw',
    },
    {
      header: 'Total Charging Time (HH:MM:SS)',
      id: 'totalChargingTime',
      accessor: d => {

        // var milliseconds = Math.floor((d.totalChargingTime % 1000) / 100),
        // seconds = Math.floor((d.totalChargingTime / 1000) % 60),
        // minutes = Math.floor((d.totalChargingTime / (1000 * 60)) % 60),
        // hours = Math.floor((d.totalChargingTime / (1000 * 60 * 60)) % 24);

        // hours = (hours < 10) ? "0" + hours : hours;
        // minutes = (minutes < 10) ? "0" + minutes : minutes;
        // seconds = (seconds < 10) ? "0" + seconds : seconds;

        // return hours + ":" + minutes + ":" + seconds;


        const days = Math.floor(d.totalChargingTime / (24*60*60*1000));
        const daysms = d.totalChargingTime % (24*60*60*1000);
        const hours = Math.floor(daysms / (60*60*1000));
        const hoursms = d.totalChargingTime % (60*60*1000);
        const minutes = Math.floor(hoursms / (60*1000));
        const minutesms = d.totalChargingTime % (60*1000);
        const sec = Math.floor(minutesms / 1000);
        return days + ":" + hours + ":" + minutes + ":" + sec;
        
      }
    },
    {
      header: 'Total Parking Time (HH:MM:SS)',
      id: 'totalParkingTime',
      accessor: d => {
        // var parktime = (d.totalParkingTime - d.totalChargingTime)
        // var milliseconds = Math.floor((d.totalParkingTime % 1000) / 100),
        // seconds = Math.floor((parktime / 1000) % 60),
        // minutes = Math.floor((parktime / (1000 * 60)) % 60),
        // hours = Math.floor((parktime/ (1000 * 60 * 60)) % 24);

        // hours = (hours < 10) ? "0" + hours : hours;
        // minutes = (minutes < 10) ? "0" + minutes : minutes;
        // seconds = (seconds < 10) ? "0" + seconds : seconds;

        // return hours + ":" + minutes + ":" + seconds;


        var parktime = (d.totalParkingTime - d.totalChargingTime)

        // const days = Math.floor(parktime / (24*60*60*1000));
        // const daysms = parktime % (24*60*60*1000);
        const hours = Math.floor(parktime / (60*60*1000));
        const hoursms = parktime % (60*60*1000);
        const minutes = Math.floor(hoursms / (60*1000));
        const minutesms = parktime % (60*1000);
        const sec = Math.floor(minutesms / 1000);
        // return  hours + ":" + minutes + ":" + sec;
        return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + sec.toString().padStart(2, '0');

      }
    },
    {
      header: 'Total Time (HH:MM:SS)',
      id: 'totaltime',
      accessor: d => {
        // var milliseconds = Math.floor((d.totalParkingTime % 1000) / 100),
        // seconds = Math.floor((d.totalParkingTime / 1000) % 60),
        // minutes = Math.floor((d.totalParkingTime / (1000 * 60)) % 60),
        // hours = Math.floor((d.totalParkingTime / (1000 * 60 * 60)) % 24);

        // hours = (hours < 10) ? "0" + hours : hours;
        // minutes = (minutes < 10) ? "0" + minutes : minutes;
        // seconds = (seconds < 10) ? "0" + seconds : seconds;

        // return hours + ":" + minutes + ":" + seconds;



        // const days = Math.floor(d.totalParkingTime / (24*60*60*1000));
        // const daysms = d.totalParkingTime % (24*60*60*1000);
        const hours = Math.floor(d.totalParkingTime / (60*60*1000));
        const hoursms = d.totalParkingTime % (60*60*1000);
        const minutes = Math.floor(hoursms / (60*1000));
        const minutesms = d.totalParkingTime % (60*1000);
        const sec = Math.floor(minutesms / 1000);
        // return  hours + ":" + minutes + ":" + sec;
        return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + sec.toString().padStart(2, '0');

      }
    },
  ],
    []
  );

  const hubList = props.fetchAllHubData && props.fetchAllHubData.map(id => id.hubname)
  return (

    <div className="container-fluid">



      <div className="row-fluid sideCardDesigns">
        <div className="col-md-12 col-xl-12">
          <div className="row py-2 mt-3  ">
            <div className="col">
              <div className="row py-2">
                <div className="col-md-12 col-lg-12">
                  <div className="mb-3 h-100 ">
                    <div className="card-header singleCard justify-content-between">
                      <div className="col-md-4">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal formHeading"><strong>Vehicle Parking Time Report</strong>
                        </div>
                      </div>
                      <div className="">
                        <span className="pr-2">
                          <select className="columnsBtn p-2" name="hubname" onChange={handleChange}>
                            <option value="All" >All</option>
                            {hubList && hubList.map((hub) => (
                              <option key={hub.id} value={hub} >{hub}</option>
                            ))}
                          </select>
                        </span>

                        <DateRangePicker
                          startDate={startDate}
                          startDateId="s_id"
                          endDate={endDate}
                          endDateId="e_id"
                          onDatesChange={({ startDate, endDate }) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                          }}
                          focusedInput={focusedInput}
                          onFocusChange={(e) => setFocusedInput(e)}
                          displayFormat="DD-MM-YYYY"
                          horizontalMargin={20}
                          isOutsideRange={isOutsideRange}
                        />

                        <button
                          className="dateBtn ml-2 "
                          type="button"
                          onClick={handleDateClick}
                        >
                          Go
                        </button>
                      </div>


                    </div>
                    {statsdata && statsdata.length > 0 ?
                      (<div className="App"> <Table data={statsdata} columns={columns}></Table></div>)
                      :
                      (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-sm-12 col-12">
            <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" href="/dashboard/stations">Back</a>
          </div>
          <hr className="my-4" />
        </div>
        <ToastContainer style={{ top: '3rem' }} />

      </div>
    </div>

  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.hub.isLoading,
    fetchAllHubData: state.hub.getAllHubListData,
    chargerstats: state.hub.fetchvehicleparkingreport
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getHubListData: () => dispatch(getAllHubListData()),
    chargerDowntime: (hubname, start, end) => dispatch(getVehicalparkingAction(hubname, start, end))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehhicleparkingReport);
