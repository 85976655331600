import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { getSingleLocationAction } from "../../../../store/actions/locationAction";
import { cityAction, countryAction, stateAction, } from "../../../../store/actions/commonAction";
import { getLocationOwnerAction, locationCategoryAction, locationSubCategoryAction, slocationAction, updateLocationAction, } from "../../../../store/actions/locationAction";
import { networkEnterpriseOperatorAction, operatorAction, } from "../../../../store/actions/opAction";
import { amenityAction } from "../../../../store/actions/assetAction";

var arr = [];
class ViewLocation extends Component {
  state = {
    location: {
      operator: "",
      enterprise: "",
      name: "",
      number: "",
      status: "",
      locationtype: "",
      locationsubtype: "",
      amenitylist: [],
      address: "",
      address1: "",
      landmark: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      enterdby: "",
      latitude: "",
      longitude: "",
      description: "",
      source: "",
      error: "",

    },
  };


  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const token = "";
    this.props.getLocation(params.id, token);

    this.props.npData("Network Operator");
    this.props.locationStatusList(this.state);
    this.props.locationType(this.state);
    this.props.countrylist(this.state);
    this.props.locationOwn(this.state);
    this.props.epData(this.state);
    this.props.locationSubType(this.state);
    this.props.statelist(this.state);
    this.props.citylist(this.state);
    this.props.getAmenityList(this.state);
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.locationdetail !== this.props.locationdetail) {
      this.setState({
        location: this.props.locationdetail,
        // amenityDetails: this.props.amenitylist,
      });
    }
  }

  handleAmenityChanges = (e) => {
    const target = e.target;
    let pvalue = target.value;
    let pid = target.id;
    arr = this.props.locationdetail.amenity;

    if (target.checked) {
      arr.push({ icon: pid, name: pvalue });
      // console.log("push", arr);
    } else {
      const removeIndex = arr.findIndex((item) => item.name === pvalue);
      arr.splice(removeIndex, 1);
      // console.log("pop", arr);
    }
    this.setState({
      access: arr,
    });
  };

  getChecked(item) {
    let status =
      this.props.locationdetail &&
      this.props.locationdetail.amenity.filter((itr) => itr.name === item);
    return status.length !== 0 ? true : false;
  }

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      location: { ...prevState.location, [e.target.name]: e.target.value },
    }));

    if (e.target.name === "operator") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let createdby = el.getAttribute("id");
      this.props.epData(createdby, "Enterprise");
    }
    if (e.target.name === "locationtype") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let loctype = el.getAttribute("id");
      this.props.locationSubType(loctype);
    }
    if (e.target.name === "country") {
      this.props.statelist(e.target.value);
    }
    if (e.target.name === "state") {
      this.props.citylist(e.target.value);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      match: { params },
    } = this.props;
    this.props.updateLocation(params.id, this.state.location);
    alert("Location updated");
    this.props.history.push("/dashboard/assets");
  };

  render() {

    // console.log("bdhdbh",this.props.locationdetail.amenity)
    const { firstname } = JSON.parse(localStorage.getItem("user"));
    if (this.props.updateloc && this.props.updateloc.error === "") {
      return <Redirect to="/dashboard/assets" />;
    }

    return (
      <div className="container-fluid">
        <div className="sideCardDesigns card-signin my-5">
          <div className="card-body">
            <h4 className="text-center formHeading">
              <b>Edit Location</b>
            </h4>


            <form className="form mt-4" onSubmit={this.handleSubmit}>
              <div className="row rowpadding  justify-content-between">
                <div className="col-md-6 col-sm-12 ">
                  <div className="col-12  formContainer">
                    <div className="col-12">
                      <label className="formLable" htmlFor="networkoperator">Network Operator</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="operator"
                          onChange={this.handleChange}
                          value={this.state.location.operator}
                        >
                          <option>Select Operator</option>
                          {this.props.operatorlist &&
                            this.props.operatorlist.map((network) => (
                              <option key={network.id}
                                id={network.id}
                                value={network.organization}>
                                {network.organization}
                              </option>
                            ))}
                        </select>
                      </div>
                      <label className="formLable" htmlFor="enterprise">Enterprise</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="enterprise"
                          onChange={this.handleChange}
                          value={this.state.location.enterprise}
                        >
                          {this.state.location.enterprise ? (
                            <option>{this.state.location.enterprise}</option>
                          ) : (
                            ""
                          )}
                          {this.props.enterpriselist &&
                            this.props.enterpriselist.map((enterprise) => (
                              <option
                                key={enterprise.id}
                                id={enterprise.id}
                                value={enterprise.organization}
                              >
                                {enterprise.organization}{" "}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="locationname">Location Name</label>
                      <div className="form-label-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={this.state.location.name}
                          onChange={this.handleChange}
                        />
                        <label>Enter Location Name</label>
                      </div>

                      <label className="formLable" htmlFor="locationnumber">Location Number</label>
                      <div className="form-label-group">
                        <input
                          type="text"
                          name="number"
                          className="form-control"
                          value={this.state.location.number}
                          onChange={this.handleChange}
                        />
                        <label>Enter Location Number</label>
                      </div>

                      <label className="formLable" htmlFor="locationstatus">Location Status</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="status"
                          onChange={this.handleChange}
                          value={this.state.location.status}
                        >
                          {this.props.statuslist &&
                            this.props.statuslist.map((slist) => (
                              <option key={slist.id} value={slist.name}>
                                {slist.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="locationtype">Location Category</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          id="loctype"
                          name="locationtype"
                          onChange={this.handleChange}
                          value={this.state.location.locationtype}
                        >
                          {this.props.ltype &&
                            this.props.ltype.map((lt) => (
                              <option key={lt.id} id={lt.code} value={lt.type}>
                                {lt.type}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="locationsubtype">Location Sub-Category</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="locationsubtype"
                          onChange={this.handleChange}
                          value={this.state.location.locationsubtype}
                        >
                          {this.state.location.locationsubtype ? (
                            <option>{this.state.location.locationsubtype}</option>
                          ) : (
                            ""
                          )}
                          {this.props.lsubtype &&
                            this.props.lsubtype.map((lts) => (
                              <option
                                key={lts.id}
                                id={lts.code}
                                value={lts.subtype}
                              >
                                {lts.subtype}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="locationowner">Location Ownership</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="source"
                          onChange={this.handleChange}
                          value={this.state.location.source}
                        >
                          {this.props.locationownerlist &&
                            this.props.locationownerlist.map((own) => (
                              <option key={own.id} value={own.marker.value}>
                                {own.owner}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="address">Address Line 1</label>
                      <div className="form-label-group">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.location.address}
                        />
                        <label htmlFor="address">Address Line 1</label>
                      </div>

                      <label className="formLable" htmlFor="latitude"> Location Latitude </label>
                      <div className="form-label-group">
                        <input
                          type="text"
                          id="latitude"
                          name="latitude"
                          className="form-control"
                          placeholder="Enter Location Latitude"
                          onChange={this.handleChange}
                          value={this.state.location.latitude}
                        />
                        <label htmlFor="latitude">Location Latitude </label>
                      </div>


                      <label className="formLable" htmlFor="longitude">Location Longitude</label>
                      <div className="form-label-group">
                        <input
                          type="text"
                          id="longitude"
                          name="longitude"
                          className="form-control"
                          placeholder="Enter Location Longitude"
                          onChange={this.handleChange}
                          value={this.state.location.longitude}
                        />
                        <label htmlFor="longitude">Location Longitude</label>
                      </div>

                    </div>
                  </div>
                </div>



                <div className="col-md-6 col-sm-12 ">
                  <div className="col-12  formContainer ">

                    <div className="col-12 ">

                      <label className="formLable" htmlFor="addressline1">Address Line 2</label>
                      <div className="form-label-group">
                        <input
                          type="text"
                          id="addressline1"
                          name="addressline1"
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.location.address1}
                        />
                        <label htmlFor="addressline1">
                          Address Line 2(Optional)
                        </label>
                      </div>

                      <label className="formLable" htmlFor="landmark">Enter Landmark</label>
                      <div className="form-label-group">
                        <input
                          type="text"
                          id="landmark"
                          name="landmark"
                          className="form-control"
                          placeholder="Land Mark(Optional)"
                          onChange={this.handleChange}
                          value={this.state.location.landmark}
                        />
                        <label htmlFor="landmark">Land Mark(Optional)</label>
                      </div>

                      <label className="formLable" htmlFor="country">country</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="country"
                          onChange={this.handleChange}
                          value={this.state.location.country}
                        >
                          <option>Select Country</option>
                          {this.props.countries &&
                            this.props.countries.map((country) => (
                              <option key={country.code} value={country.code}>
                                {country.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="lstate">State</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="state"
                          onChange={this.handleChange}
                          value={this.state.location.state}
                        >
                          {this.state.location.state ? (
                            <option>{this.state.location.state}</option>
                          ) : (
                            ""
                          )}
                          {this.props.states &&
                            this.props.states.map((st) => (
                              <option key={st.code} value={st.name}>
                                {st.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="city">Select City</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="city"
                          onChange={this.handleChange}
                          value={this.state.location.city}
                        >
                          {this.state.location.city ? (
                            <option>{this.state.location.city}</option>
                          ) : (
                            ""
                          )}
                          {this.props.cities &&
                            this.props.cities.map((c) => (
                              <option key={c.id} value={c.name}>
                                {c.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="zipcode">Zipcode</label>
                      <div className="form-label-group">
                        <input
                          type="text"
                          id="zipcode"
                          name="zipcode"
                          className="form-control"
                          placeholder="Enter Zipcode"
                          onChange={this.handleChange}
                          value={this.state.location.zipcode}
                        />
                        <label htmlFor="zipcode">Zipcode</label>
                      </div>

                      <label className="formLable" htmlFor="source">Source</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="source"
                          onChange={this.handleChange}
                          value={this.state.location.source}
                        >
                          {this.props.locationownerlist &&
                            this.props.locationownerlist.map((own) => (
                              <option key={own.id} value={own.marker.value}>
                                {own.owner}
                              </option>
                            ))}
                        </select>
                      </div>


                      <label className="formLable" htmlFor="ameneties">Ameneties</label>
                      <div className=" containerAme p-2 pt-1">
                        {this.props.amenitylist &&
                          this.props.amenitylist.map((ame) => (
                            <div className="form-check form-check-inline border badge badge-pill text py-1 px-2" icon={ame.icon}>
                              <input className="form-check-input" type="checkbox" name="amenities" id={ame.icon} value={ame.name} defaultChecked={this.getChecked(ame.name)} onChange={this.handleAmenityChanges} />
                              <label className="form-check-label" htmlFor={ame.icon}>{ame.name}</label>
                            </div>

                          ))}
                      </div>



                      <label className="formLable pt-4" htmlFor="enteredby">Entry By</label>
                      <div className="form-label-group ">
                        <input
                          type="text"
                          id="enterdby"
                          name="enterdby"
                          className="form-control control-label"
                          value={firstname}
                          onChange={this.handleChange}
                          disabled
                        />
                        <label htmlFor="enterdby">Entered By</label>
                      </div>

                      <label className="formLable" htmlFor="description">Description</label>
                      <div className="form-group pb-3">
                        <textarea
                          className="form-control p-3"
                          id="description"
                          name="description"
                          placeholder="Description(Optional)"
                          rows="4"
                          onChange={this.handleChange}
                          value={this.state.location.description}
                        ></textarea>
                      </div>
                      <br />
                      <br />
                      <br />

                    </div>
                  </div>
                </div>
              </div>



              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <a
                    className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                    href="/dashboard/assets"
                  >
                    Back
                  </a>
                </div>

                <div className="col-xl-6 col-sm-6 col-12 float-right">
                  <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">
                    Update Locations
                  </button>
                </div>
                <hr className="my-4" />
              </div>
            </form>

          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    locationdetail: state.location.fetchSingleLocation,
    operatorlist: state.operator.fetchOperator,
    enterpriselist: state.operator.fetchEnterprise,
    statuslist: state.location.fetchLocationStatusList,
    ltype: state.location.fetchLocationCategorylist,
    lsubtype: state.location.ftchLocationSubCategoryList,
    countries: state.common.countryList,
    states: state.common.stateList,
    cities: state.common.cityList,
    locationownerlist: state.location.fetchLocationOwner,
    updateloc: state.location.updateLocation,
    amenitylist: state.asset.fetchAmenity,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocation: (id, token) => dispatch(getSingleLocationAction(id, token)),
    npData: (nrole) => dispatch(operatorAction(nrole)),
    epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
    locationStatusList: () => dispatch(slocationAction()),
    locationType: () => dispatch(locationCategoryAction()),
    locationSubType: (loctype) => dispatch(locationSubCategoryAction(loctype)),
    countrylist: () => dispatch(countryAction()),
    statelist: (countrycode) => dispatch(stateAction(countrycode)),
    citylist: (statecode) => dispatch(cityAction(statecode)),
    locationOwn: () => dispatch(getLocationOwnerAction()),
    updateLocation: (id, location) => dispatch(updateLocationAction(id, location)),
    getAmenityList: () => dispatch(amenityAction()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewLocation));
