import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { getSubscriptionPlanByCreatorAction,PlanDeactivateAction } from "../../../store/actions/subscriptionAction";
import Table from "./subscriptionTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2'
const SubscriptionListTable = (props) => {
  useEffect(() => {
    const { planname } = JSON.parse(localStorage.getItem('user'))
    let pname = planname
    if (pname === "") {
      props.fetSubscriptionplan("All")

    } else {
      props.fetSubscriptionplan(pname)
    }
  }, [])
  const user = JSON.parse(localStorage.getItem('user'));
  const activateDeactivateUser=(id, status)=>{

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#1e295d',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        props.PlanDeactivation(id,status);
        window.location.reload();
      }
    });
  }


  const data = props.sublistdata && props.sublistdata
  const columns = useMemo(

    () => [

      {
        header: 'Plan Name',
        accessor: 'name'
      },
      {
        header: 'Plan Code',
        accessor: 'code'
      },
      {
        header: 'Fee',
        accessor: 'fee'
      },
      {
        header: 'Setup Fee',
        accessor: 'setupfee'
      },
      {
        header: 'Category',
        accessor: 'category'
      },
      {
        header: 'Start Date',
        id: 'startdate',
        accessor: d => {
          return moment(d.startdate)
            .local()
            .format("DD-MM-YYYY");
        }

      },
      {
        header: 'End Date',
        id: 'enddate',
        accessor: d => {
          return moment(d.enddate)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Display',
        id: "display",
        accessor: d => {
          return d.display ? <span className="text-success">Yes</span> : <span className="text-danger">No</span>
        }
      },

      {
        header: 'Actions',
        id: 'action',
        accessor: (plan) => {
          return (
            <div>
              <span>
                <a href={`/dashboard/pricing/viewsingleplan/${plan.id}`} rel="tooltip" title="View " className="table-action view">
                  <i className="fas fa-eye"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;
              <span>
                <a href={`/dashboard/pricing/editplan/${plan.id}`} rel="tooltip" title="Edit " className="table-action edit">
                  <i className="fas fa-edit"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;
              {user.role === "Admin" ? (
               <span>
                    {plan.status ? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash"  onClick={() => activateDeactivateUser(plan.id, false) } >
                        <i className="fas fa-user-check"></i></a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active" onClick={() => activateDeactivateUser(plan.id, true)}>
                    <i className="fas fa-user-slash"></i></a>}
                </span>
                ):( <span ></span>)
              }
               &nbsp;&nbsp;&nbsp;
              
            </div>

          );

        },

      }
    ],
    []
  );


  return (
    <div className="row ">
      <div className="col ">
        <div className=" sideCardDesigns ">
          <div className="card-header singleCard">
            <div className="card-header-title font-size-lg text-capitalize font-weight-normal "><strong>Subscription Plans</strong>
            </div>
          </div>
          {!data.length ?
            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
            :
            (<div className="App"> <Table data={data} columns={columns}></Table></div>)}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.subscriptionplan.isLoading,
    sublistdata: state.subscriptionplan.fetcSubscriptionplan,
    planDeactivation: state.subscriptionplan.planDeactivation,


  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetSubscriptionplan: (planname) => dispatch(getSubscriptionPlanByCreatorAction(planname)),
    PlanDeactivation: (id,status) => dispatch(PlanDeactivateAction(id,status))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionListTable);





