import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../Breadcrums"
import Loader from 'react-loader-spinner';
import { checkserverstatusAction, upocppserverAction } from "../../../store/actions/stationAction"

const Server = (props) => {
    const userrole = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        props.getcheckserverstatus()
    }, [])
    // const handleSubmitcheckstatus = (e) => {
    //     e.preventDefault();
    //     props.getcheckserverstatus()
    // };
    // if (props.checkServerupordown.message && props.checkServerupordown.message) {
    //     console.log("down",props.checkServerupordown.message)
    //    alert("down")
    //   }

    const handleSubmitupserver = (e) => {
        e.preventDefault();
        props.upocppserver();
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row m-1">
                    <div className="col col-sm-4 ">
                        <PureBreadcrumbs />
                    </div>
                </div>

                <div className="row rowpadding topCardDesigns m-1">
                    <div className="col-4 p-3">
                        <div className="col-12 ml-2 ">
                            <img src={process.env.PUBLIC_URL + '/images/user_logo/server-svgrepo-com.svg'} style={{ height: "34px", width: "32px" }} alt="" />
                            <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>Server status</span>
                            <div className="page-title-subheading pt-2" style={{ fontSize: "12px", color: "#bc0078", fontWeight: "550" }}>Check server up or down </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="text-center m-3">
                            {/* <form className="form mt-4" onSubmit={handleSubmitcheckstatus} autoComplete="off">
                                <button className="columnsBtn p-2" >Check Server Status </button>
                            </form> */}
                            <div className="col-12 ml-2 ">
                                <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>Cureent OCPP Server status</span>
                                {
                                    <p style={{ color: props.checkServerupordown.message === "UP" ? "green" : "red", fontWeight: "bold", fontSize: 16 }}>
                                        {props.checkServerupordown.message}
                                    </p>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="col-4">
                        <div className="text-right m-3">
                            <form className="form mt-4" onSubmit={handleSubmitupserver} autoComplete="off">
                                <button className="btn btn-primary" >Up OCPP Server</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        checkServerupordown: state.station.checkserverupordown,
        upOcppserver: state.station.upocppserver,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getcheckserverstatus: () => dispatch(checkserverstatusAction()),
        upocppserver: () => dispatch(upocppserverAction()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Server);

