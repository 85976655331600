import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { getLocationAction } from "../../../../store/actions/locationAction";
import { networkEnterpriseOperatorAction, operatorAction, } from "../../../../store/actions/opAction";
import { saveUtilityAction } from "../../../../store/actions/assetAction";
import { updateUtilityAction, getSingleUtilityActions } from "../../../../store/actions/assetAction";

var arr = [];
class Editutility extends Component {
    state = {
        user: {
            network_operator: '',
            enterprise: '',
            station_name: '',
            service_number: '',
            electricity_bill_due_date: '',
            electricity_bill_amount: '',
            unit_price: '',
            enterdby: '',
            commissioned_date: '',
        }
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        const token = '';
        this.props.getutils(params.id, token)
        this.props.npData("Network Operator");
        this.props.epData(this.state);
        this.props.getLocation(this.state);

    }
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.utilitydetail !== this.props.utilitydetail) {
            this.setState({
                user: this.props.utilitydetail
            })
        }
    }

    handleChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            user: { ...prevState.user, [e.target.name]: e.target.value }
        }))
        if (e.target.name === "network_operator") {
            let index = e.target.selectedIndex;
            let el = e.target.childNodes[index];
            let createdby = el.getAttribute("id");
            this.props.epData(createdby, "Enterprise");
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { match: { params } } = this.props;
        this.props.updateUtility(params.id, this.state.user)
        alert("Details updated")
        this.props.history.push('/dashboard/assets')

    }
    render() {

        // console.log("sdfS", this.state.user.station_name)
        return (
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>Update Utility</b>
                        </h4>


                        <form className="form mt-4" onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="row rowpadding  justify-content-between">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer">
                                        <div className="col-12">
                                            <label className="formLable" htmlFor="networkoperator">Network Operator</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    id="network_operator"
                                                    name="network_operator"
                                                    required
                                                    onChange={this.handleChange}
                                                    value={this.state.user.network_operator}
                                                >
                                                    {this.state.user.network_operator ? <option>Network Operator</option> : ''}
                                                    {this.props.operatorlist &&
                                                        this.props.operatorlist.map((network) => (
                                                            <option
                                                                key={network.id}
                                                                id={network.id}
                                                                value={network.organization}
                                                            >
                                                                {network.organization}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            <label className="formLable" htmlFor="enterprise">Enterprise</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    id="enterprise"
                                                    name="enterprise"
                                                    required
                                                    onChange={this.handleChange}
                                                    value={this.state.user.enterprise}
                                                >
                                                    {this.state.user.enterprise ? <option>{this.state.user.enterprise}</option> : ''}
                                                    {this.state.user.enterpriselist === null ? (
                                                        <option value="none">none</option>
                                                    ) : (
                                                        this.props.enterpriselist &&
                                                        this.props.enterpriselist.map((enterprise) => (
                                                            <option
                                                                key={enterprise.id}
                                                                value={enterprise.organization}
                                                            >
                                                                {enterprise.organization}
                                                            </option>
                                                        ))
                                                    )}
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="station_name">Station Name</label>
                                            <div className="form-label-group">
                                                <select
                                                    className="form-control border-radius"
                                                    id="station_name"
                                                    name="station_name"
                                                    required
                                                    onChange={this.handleChange}
                                                    value={this.state.user.station_name}
                                                >
                                                    {this.state.user.station_name ? <option>{this.state.user.station_name}</option> : ''}

                                                    {this.props.locations === null ? (
                                                        <option value="none">none</option>
                                                    ) : (
                                                        this.state.user.locations &&
                                                        this.state.user.map((location) => (
                                                            <option key={location.name} value={location.name}>
                                                                {location.name}
                                                            </option>
                                                        ))
                                                    )}
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="commissioned_date">Commissioned Date</label>
                                            <div className="form-label-group">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="commissioned_date"
                                                    name="commissioned_date"
                                                    required
                                                    onChange={this.handleChange}
                                                    value={this.state.user.commissioned_date}
                                                />
                                                <label htmlFor="commissioned_date">Commissioned Date</label>
                                            </div>

                                            <label className="formLable" htmlFor="service_number">Service Number</label>
                                            <div className="form-label-group required">
                                                <input
                                                    type="text"
                                                    id="service_number"
                                                    name="service_number"
                                                    className="form-control control-label"
                                                    placeholder="Enter Service Number"
                                                    onChange={this.handleChange}
                                                    value={this.state.user.service_number}
                                                    required
                                                />
                                                <label htmlFor="service_number">Service Number</label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">

                                        <div className="col-12 ">

                                            <label className="formLable" htmlFor="electricity_bill_due_date">Electricity Bill Due date</label>
                                            <div className="form-label-group">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="electricity_bill_due_date"
                                                    name="electricity_bill_due_date"
                                                    required
                                                    onChange={this.handleChange}
                                                    value={this.state.user.electricity_bill_due_date}
                                                />
                                                <label htmlFor="electricity_bill_due_date">
                                                    Electricity Bill Due date
                                                </label>
                                            </div>

                                            <label className="formLable" htmlFor="electricity_bill_amount">Electricity Bill Amount</label>
                                            <div className="form-label-group required">
                                                <input
                                                    type="number"
                                                    id="electricity_bill_amount"
                                                    name="electricity_bill_amount"
                                                    className="form-control control-label"
                                                    placeholder="Enter Service Number"
                                                    onChange={this.handleChange}
                                                    value={this.state.user.electricity_bill_amount}
                                                    required
                                                />
                                                <label htmlFor="electricity_bill_amount">
                                                    Electricity Bill Amount
                                                </label>
                                            </div>

                                            <label className="formLable" htmlFor="unit_price">Electricity Price per Unit</label>
                                            <div className="form-label-group required">
                                                <input
                                                    type="number"
                                                    id="unit_price"
                                                    name="unit_price"
                                                    className="form-control control-label"
                                                    placeholder="Enter Service Number"
                                                    onChange={this.handleChange}
                                                    value={this.state.user.unit_price}
                                                    required
                                                />
                                                <label htmlFor="unit_price">Electricity Price per Unit</label>
                                            </div>

                                            <label className="formLable" htmlFor="enterdby">Entered By</label>
                                            <div className="form-label-group pb-3">
                                                <input
                                                    type="text"
                                                    className="form-control control-label"
                                                    value={this.state.user.enterdby}
                                                    disabled
                                                />
                                                <label htmlFor="enterdby">Data Entered By</label>
                                            </div>
                                            <br />
                                            <br />
                                            <br />

                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="row" >
                                <div className="col-md-3 col-lg-3 ">
                                    <a
                                        className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                        href="/dashboard/assets"
                                    >
                                        Cancel
                                    </a>
                                </div>
                                <div className="col-md-9 col-lg-9 ">
                                    <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">

                                        Update  Utils

                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )

    }

}

const mapStateToProps = function (state) {
    return {
        utilitydetail: state.asset.fetchUtilityByID,
        operatorlist: state.operator.fetchOperator,
        enterpriselist: state.operator.fetchEnterprise,
        locations: state.location.fetchLocation,
        response: state.asset.saveUtility,
        loading: state.asset.isLoading,
        modifiedutils: state.asset.updateUtilityByID,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getutils: (id, token) => dispatch(getSingleUtilityActions(id, token)),
        npData: (nrole) => dispatch(operatorAction(nrole)),
        epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
        getLocation: () => dispatch(getLocationAction()),
        commisionUtility: (utility) => dispatch(saveUtilityAction(utility)),
        updateUtility: (id, user) => dispatch(updateUtilityAction(id, user)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Editutility));