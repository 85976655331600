const initState = {
  isLoading: false,
  fetchCustomerList: '',
  saveCustomer: '',
  fetchAllRfid: '',
  fetchSingleRFID: '',
  deactivateRFID: '',
  saverfidexcel: '',
  fetchsinglecustomer: '',
  savefleetrfid: '',
  payasugolist: '',
  premiumlist: '',
  edisignlerfid: '',
  fetchuserwallet: '',
  paymenthistory: '',
  fetchCustomerOTPList: '',
  saveconfigurer: '',
  fetchconfigurerList: '',
  deactivateConfigurer: ''

}
const customerReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CUSTOMER_LOADING':
      return { ...state, isLoading: true }
    case 'FETCH_CUSTOMER_LIST':
      return { ...state, fetchCustomerList: action.payload, isLoading: false }
    case 'SAVE_CUSTOMER':
      return { ...state, saveCustomer: action.payload }
    case 'FETCH_RFID':
      return { ...state, fetchAllRfid: action.payload }
    case 'FETCH_SINGLE_RFID':
      return { ...state, fetchSingleRFID: action.payload }
    case 'DEACTIVATE_RFID':
      return { ...state, deactivateRFID: action.payload }
    case 'SAVE_EXCEL_RFID':
      return { ...state, saverfidexcel: action.payload }
    case 'FETCH_SINGLE_CUSTOMER':
      return { ...state, fetchsinglecustomer: action.payload }
    case 'SAVE_FLEET_RFID':
      return { ...state, savefleetrfid: action.payload }
    case 'FETCH_PAYASYOUGO_LIST':
      return { ...state, payasugolist: action.payload }
    case 'FETCH_PREMIUM_LIST':
      return { ...state, premiumlist: action.payload }
    case 'EDIT_SINGLE_RFID':
      return { ...state, edisignlerfid: action.payload }
    case 'FETCH_CUSTOMER_WALLET_LIST':
      return { ...state, fetchuserwallet: action.payload }
    case 'FETCH_PAYMENT_HISTORY':
      return { ...state, paymenthistory: action.payload, isLoading: false }
    case 'SAVE_NEW_CONFIRURER':
      return { ...state, saveconfigurer: action.payload, isLoading: false }
    case 'FETCH_CONFIRURER_LIST':
      return { ...state, fetchconfigurerList: action.payload, isLoading: false }
    case 'DEACTIVATE_CONFIGURER':
      return { ...state, deactivateConfigurer: action.payload }


    default:
      return state;
  }
}
export default customerReducer;


