import React, { useState } from "react";
import "./dashboard.css"
import SideToolBar from "./sidetoolbar/toolbar";
import Switch from "react-bootstrap/esm/Switch";
import { Route } from "react-router-dom";
import Insight from "./insights/insights";
import HubInsights from "./hub/hubInsights/hubInsights"
import Users from "./users/users";
import CreateNewUser from "./users/addusers";
import { logoutAction } from "../../store/actions/authAction";
import { useDispatch } from "react-redux";
import Asset from "./assets/asset";
import Price from "./price/pricing";
import Station from "./station/stations";
import Transaction from "./transaction/transaction";
import Firmware from "./firmware/firmware";
import ChargerList from "./logs/chargerList";
import ChargerLogs from "./logs/chargerLogs";
import Fleet from "./fleet/fleet";
import Report from "./report/reports";
import EditUser from "./users/edituser";
import ViewUser from "./users/viewuser";
import CreateNewLocation from "./assets/location/addnewlocation";
import NotFound from "../../notfound";
import AssetType from "./assets/asset/assettype";
import CreateAsset from "./assets/asset/createasset";
import AddEnerpriseOperator from "./assets/enterprise/addenterprise";
import AddAsset from "./assets/asset/addasset";
import AddNewConnector from "./assets/connectors/addconnector";
import CommissionType from "./assets/commisions/commission"
import CommissionPage from "./assets/commisions/locationlive";
import CreatePriceModel from "./price/addprice";
import CreatePriceLevel from "./price/createpricelevel";
import CreateChargeStation from "./station/addstation";
import CustomerLandingPage from "./customer/customer";
import CreateSubscriptionPlan from "./plans/addsubscription";
import CSviewall from "./report/csviewall";
import Kwhviewall from "./report/kwhviewall";
import Timeviewall from "./report/timeviewall";
import Revenueviewall from "./report/revenueviewall";
import TotalCustomerViewall from "./customer/totalCviewall";
import PayasyougoViewall from "./customer/payviewall";
import MonthlyViewall from "./customer/monthlyviewall";
import PremiumViewall from "./customer/premiumviewall";
import Settings from "./settings/settings";
import UpdatePassComponent from "../auth/updatepass";
import Tusharfleet from "./fleet/tusharfleet";
import UpdateStation from "./station/updatestation";
import StationViewAll from "./station/stationviewall";
import StationGroupView from "./station/stationgroup";
import AddCustomer from "./customer/addCustomer";
import ChargerConfigurer from "./customer/addChargerConfigurer";
import Campaign from "./campaign/campaign";
import CustomerAll from "./customer/allcustomer";
import SendSms from "./campaign/sendsms";
import SendEmail from "./campaign/sendemail";
import TotalChargerInsight from "./insights/totalcharger";
import ChargerStatus from "./insights/chargerstatus";
import ChargerDetail from "./station/chargerStats";
import EditLocation from "./assets/location/editlocation";
import EditConnector from "./assets/connectors/editconnector";
import EditAsset from "./assets/asset/editasset";
import EditEnterprise from "./assets/enterprise/editenterprise";
import EditSingleStation from "./station/editstation";
import EditCustomer from "./customer/editcustomer";
import EditCustomerWallet from "./customer/editcustomerwallet";
import EditPrice from "./price/editprice";
import ViewLocation from "./assets/location/viewlocation";
import ViewStation from "./station/viewstation";
import AccessCardList from "./access/accesscard";
import AssignAccess from "./access/assignaccess";
import ViewSingleAssetsDetails from "./assets/asset/viewsingleassets";
import FileUpload from "./station/FileUpload";
import CreateNewRFID from "./assets/rfid/createrfid";
import ViewSingleRFIDdetails from "./assets/rfid/viewsinglerfid";
import UpdateChargerFirmware from "./firmware/updatefirmware";
import Utility from "./assets/utility/utility";
import ViewSingleUtilityDetails from "./assets/utility/viewsingleutility";
import Editutility from "./assets/utility/editutility";
import ViewSingleConnectorDetails from "./assets/connectors/viewsingleconnector";
import ViewSinglePlanDetails from "./plans/viewsingleplan";
import CustomerReport from "./customer/singleCustomerReport";
import CustomerWalletReport from "./customer/singleCustomerWalletReport";
import { Redirect } from "react-router";
import ViewAssignedAccess from "./access/viewaccess";
import EditAssignedAccess from "./access/editaccess";
import ViewSinglePriceDetails from "./price/viewsingleprice";
import FleetRFID from "./fleet/fleetrfid";
import FleetGroupRFID from "./fleet/rfidGroupFleet";
import InventoryCardModel from "./inventory/inventorycard";
import HubCardModel from "./hub/hubcard";
import CreateInventoryAsset from "./inventory/addinventoryasset"
import ViewInventoryAsset from "./inventory/viewinventoryasset";
import EditInventoryAssets from "./inventory/editinventoryasset";
import EditPlan from "./plans/editplan";
import ManageHubmeterData from "./hub/hubMeterData/hubMeterData"
import { MdOpacity } from "react-icons/md";
import ManageHubStatusPage from "./hub/hubStatus/statusPage"
import HubOperation from "./hub/hubOperation/hubOperation"
import PmrManagementPage from "./hub/PMR/pmrPage"
import AttendanceCard from "./hub/attendancecard"
import Editrfid from "./assets/rfid/editrfid";
import HubAssignAccess from "./hub/hubassignaccess";
import ManageHubHazardReport from "./hub/hubHazardReport/hazardReport";
import ViewHubAccess from "./hub/hubaccessview";
import EditHubAccess from "./hub/hubaccessedit";
import BindingQRcode from "./hub/hubOperation/bindingQRcode";
import BateryTypeMaster from "./hub/hubMasterData/addBatteryType";
import FleetOperatorListMaster from "./hub/hubMasterData/addFleetOperator";
import HubNameListMaster from "./hub/hubMasterData/addHubName";
import QRcodeListMaster from "./hub/hubMasterData/addQRcode";
import VehicleNoListMaster from "./hub/hubMasterData/addVehicleNo";
import VehicleTypeMaster from "./hub/hubMasterData/addVehicleType";
import Server from "./server/server"
import Mobile from "./mobilesetting/mobile";
import Parkingcart from "./hub/parkingreport/parkingcart";
import BookingCard from "./station/booking/booking";
import ManageBooking from "./station/booking/managebooking";
import ViewSingleChargerBooking from "./station/booking/viewbooking";
import Firmwareupdatebyair from "./firmware/firmwareupdatebyair"

import VehicleStatusReport from "./hub/vehicleStatusReport/vehicleStatusReport"
import ChargerUpTimeReport from "./hub/chargerUpTimeReport/chargerUpTimeReport"

function DashboardLayout({ history, match }, props) {
  const [sideDrawOpen, setIsOpen] = useState(true)
  const dispatch = useDispatch()
  const { username, privileges, firstname } = JSON.parse(localStorage.getItem('user'))
  function drawToggleButtonHandler() {
    setIsOpen(!sideDrawOpen)
  }
  function LogoutHandler() {
    dispatch(logoutAction())
    history.push("/");
  }
  //console.log("privilages", privileges)
  return (
    <div className={"wrapper" + (sideDrawOpen ? " toggled" : "")}>
      <header className="toolbar">
        <nav className="toolbar_navigation">
          <div>
            <button className="toggle-button" onClick={drawToggleButtonHandler}>
              <li> <img src={process.env.PUBLIC_URL + '/images/HamMenu.svg'} alt="" /></li>
            </button>
          </div>
          <div className="toolbar_logo">
            <a href="/dashboard">
              {username == "networkoperator@tsredco.gov.in" ? <img src={process.env.PUBLIC_URL + "/images/tsredco/logo.png"} alt="TSREDCO" className="logo-tsr-ds" /> : <img src={process.env.PUBLIC_URL + "/images/logo/LogoLeft.svg"} alt="Chargegrid" className="logo" />}
            </a>
          </div>
          <div className="spacer"></div>
          <div className="toolbar_navigation_items">
            <ul className="navbar-right unordered-list">
              <li className="list-item">
                {/* {username == "networkoperator@tsredco.gov.in" ? <img src={process.env.PUBLIC_URL + "/images/tsredco/tsruser.svg"} /> : <img src={process.env.PUBLIC_URL + '/images/Maglogo.svg'} alt="" />} */}

              </li>
              {/* <li className="list-item"><span>{firstname}</span></li> */}
              {/* <li className="list-item"> <img src={process.env.PUBLIC_URL + '/images/Notification.svg'} alt="Notification" title="Notification" /></li> */}
              <li className="list-item"><a className="img" href={`/dashboard/settings/settings`}> <img src={process.env.PUBLIC_URL + '/images/SettingsIcon.svg'} alt="Settings" title="Settings" /></a></li>
              <li className="list-item"> <img src={process.env.PUBLIC_URL + '/images/LogoutIcon.svg'} alt="Sign Out" title="Sign Out" onClick={LogoutHandler} /></li>

            </ul>
          </div>
        </nav>
      </header>
      <div className="sidebar_wrapper">
        <SideToolBar match={match}></SideToolBar>
        {sideDrawOpen ? <div className="copyright ml-5" style={{ color: "#3C3C3C", opacity: "74%" }}>Powered by &nbsp; <a href=""><img style={{ height: "30px" }} src={process.env.PUBLIC_URL + "/images/logo/LogoLeft.svg"} alt="" /></a></div> : <div className="copyright" style={{ color: "#3C3C3C", opacity: "74%", marginLeft: "250px" }}>Powered by &nbsp;<a href=""><img style={{ height: "30px" }} src={process.env.PUBLIC_URL + "/images/logo/LogoLeft.svg"} alt="" /></a></div>}
      </div>
      <div className="main_container">
        <Switch>
          <Route exact path={`${match.path}`}>
            <Redirect to={`${match.path}/${privileges[0].key}`} />

          </Route>
          <Route exact path={`${match.path}/insights`}>
            <Insight></Insight>
          </Route>
          <Route exact path={`${match.path}/users`}>
            <Users></Users>
          </Route>
          <Route exact path={`${match.path}/assets`}>
            <Asset></Asset>
          </Route>
          <Route exact path={`${match.path}/pricing`}>
            <Price></Price>
          </Route>
          <Route exact path={`${match.path}/stations`}>
            <Station></Station>
          </Route>
          <Route exact path={`${match.path}/transactions`}>
            <Transaction></Transaction>
          </Route>
          <Route exact path={`${match.path}/firmware`}>
            <Firmware></Firmware>
          </Route>
          <Route exact path={`${match.path}/customers`}>
            <CustomerLandingPage></CustomerLandingPage>
          </Route>
          <Route exact path={`${match.path}/logs`}>
            <ChargerList></ChargerList>
          </Route>

          <Route exact path={`${match.path}/charger-logs/:chargeBoxId`}>
            <ChargerLogs></ChargerLogs>
          </Route>

          <Route exact path={`${match.path}/reports`}>
            <Report></Report>
          </Route>
          <Route exact path={`${match.path}/fleet`}>
            <Fleet></Fleet>
          </Route>
          <Route exact path={`${match.path}/campaign`}>
            <Campaign></Campaign>
          </Route>
          <Route exact path={`${match.path}/inventory`}>
            <InventoryCardModel></InventoryCardModel>
          </Route>
          <Route exact path={`${match.path}/hub`}>
            <HubCardModel></HubCardModel>
          </Route>

          <Route exact path={`${match.path}/hub/insights`}>
            <HubInsights></HubInsights>
          </Route>

          <Route exact path={`${match.path}/hub/vehicleparking`}>
            <Parkingcart></Parkingcart>
          </Route>
          <Route exact path={`${match.path}/server`}>
            <Server></Server>
          </Route>
          <Route exact path={`${match.path}/mobile`}>
            <Mobile></Mobile>
          </Route>
          
          <Route exact path={`${match.path}/access`}>
            <AccessCardList></AccessCardList>
          </Route>
          <Route exact path={`${match.path}/users/addnewuser`}>
            <CreateNewUser></CreateNewUser>
          </Route>
          <Route exact path={`${match.path}/users/edituser/:userId`}>
            <EditUser></EditUser>
          </Route>
          <Route exact path={`${match.path}/users/viewuser/:userId`}>
            <ViewUser></ViewUser>
          </Route>
          <Route exact path={`${match.path}/assets/addnewlocation`}>
            <CreateNewLocation></CreateNewLocation>
          </Route>
          <Route exact path={`${match.path}/assets/assettype`}>
            <AssetType></AssetType>
          </Route>
          <Route exact path={`${match.path}/assets/addenterprise`}>
            <AddEnerpriseOperator></AddEnerpriseOperator>
          </Route>
          <Route exact path={`${match.path}/assets/addasset/:type`}>
            <AddAsset></AddAsset>
          </Route>
          <Route exact path={`${match.path}/assets/createasset`}>
            <CreateAsset></CreateAsset>
          </Route>
          <Route exact path={`${match.path}/assets/addconnectortype`}>
            <AddNewConnector></AddNewConnector>
          </Route>
          <Route exact path={`${match.path}/assets/location`}>
            <CommissionType></CommissionType>
          </Route>
          <Route exact path={`${match.path}/assets/commission-new-charger/:location`}>
            <CommissionPage></CommissionPage>
          </Route>
          <Route exact path={`${match.path}/pricing/addprice`}>
            <CreatePriceModel></CreatePriceModel>
          </Route>
          <Route exact path={`${match.path}/pricing/addpricelevel/:priceid`}>
            <CreatePriceLevel></CreatePriceLevel>
          </Route>
          <Route exact path={`${match.path}/plans/addsubscription`}>
            <CreateSubscriptionPlan></CreateSubscriptionPlan>
          </Route>
          <Route exact path={`${match.path}/stations/addcharger`}>
            <CreateChargeStation></CreateChargeStation>
          </Route>
          <Route exact path={`${match.path}/stations/upload`}>
            <FileUpload></FileUpload>
          </Route>

          <Route exact path={`${match.path}/report/charging`}>
            <CSviewall></CSviewall>
          </Route>
          <Route exact path={`${match.path}/report/kwh`}>
            <Kwhviewall></Kwhviewall>
          </Route>
          <Route exact path={`${match.path}/report/time`}>
            <Timeviewall></Timeviewall>
          </Route>
          <Route exact path={`${match.path}/report/revenue`}>
            <Revenueviewall></Revenueviewall>
          </Route>
          <Route exact path={`${match.path}/customer/totalCviewall`}>
            <TotalCustomerViewall></TotalCustomerViewall>
          </Route>
          <Route exact path={`${match.path}/customer/payviewall`}>
            <PayasyougoViewall></PayasyougoViewall>
          </Route>
          <Route exact path={`${match.path}/customer/monthlyviewall`}>
            <MonthlyViewall></MonthlyViewall>
          </Route>
          <Route exact path={`${match.path}/customer/premiumviewall`}>
            <PremiumViewall></PremiumViewall>
          </Route>
          <Route exact path={`${match.path}/settings/settings`}>
            <Settings></Settings>
          </Route>
          <Route exact path={`${match.path}/auth/updatepass`}>
            <UpdatePassComponent></UpdatePassComponent>
          </Route>
          <Route exact path={`${match.path}/fleet/tusharfleet`}>
            <Tusharfleet></Tusharfleet>
          </Route>
          <Route exact path={`${match.path}/station/updatestation/:stationid/:location`}>
            <UpdateStation></UpdateStation>
          </Route>
          <Route exact path={`${match.path}/station/stationviewall`}>
            <StationViewAll></StationViewAll>
          </Route>
          <Route exact path={`${match.path}/stations/addstationgroup/:stationid/:location`}>
            <StationGroupView></StationGroupView>
          </Route>
          <Route exact path={`${match.path}/customer/addCustomer`}>
            <AddCustomer></AddCustomer>
          </Route>
          <Route exact path={`${match.path}/campaign/campaign`}>
            <Campaign></Campaign>
          </Route>

          <Route exact path={`${match.path}/customer/allcustomer`}>
            <CustomerAll></CustomerAll>
          </Route>

          <Route exact path={`${match.path}/campaign/sendsms`}>
            <SendSms></SendSms>
          </Route>
          <Route exact path={`${match.path}/campaign/sendemail`}>
            <SendEmail></SendEmail>
          </Route>
          <Route exact path={`${match.path}/insights/totalchargers`}>
            <TotalChargerInsight></TotalChargerInsight>
          </Route>
          <Route exact path={`${match.path}/insights/chargerstatus/:status`}>
            <ChargerStatus></ChargerStatus>
          </Route>
          <Route exact path={`${match.path}/station/chargerstats/:chargeboxid`}>
            <ChargerDetail></ChargerDetail>
          </Route>
          <Route exact path={`${match.path}/assets/editlocation/:id`}>
            <EditLocation></EditLocation>
          </Route>
          <Route exact path={`${match.path}/assets/editconnector/:id`}>
            <EditConnector></EditConnector>
          </Route>
          <Route exact path={`${match.path}/assets/editasset/:assetid`}>
            <EditAsset></EditAsset>
          </Route>
          <Route exact path={`${match.path}/assets/editenterprise`}>
            <EditEnterprise></EditEnterprise>
          </Route>
          <Route exact path={`${match.path}/station/editstation/:stationid/:location`}>
            <EditSingleStation></EditSingleStation>
          </Route>
          <Route exact path={`${match.path}/customer/editcustomer`}>
            <EditCustomer></EditCustomer>
          </Route>
          <Route exact path={`${match.path}/customer/editcustomerwallet`}>
            <EditCustomerWallet></EditCustomerWallet>
          </Route>
          <Route exact path={`${match.path}/pricing/editprice/:id`}>
            <EditPrice></EditPrice>
          </Route>
          <Route exact path={`${match.path}/assets/viewlocation/:locationId`}>
            <ViewLocation></ViewLocation>
          </Route>
          <Route exact path={`${match.path}/stations/viewstation/:stationid/:location`}>
            <ViewStation></ViewStation>
          </Route>
          <Route exact path={`${match.path}/access/assignaccess`}>
            <AssignAccess></AssignAccess>
          </Route>
          <Route exact path={`${match.path}/assets/viewsingleassets/:assetid`}>
            <ViewSingleAssetsDetails></ViewSingleAssetsDetails>
          </Route>
          <Route exact path={`${match.path}/assets/createrfid`}>
            <CreateNewRFID></CreateNewRFID>
          </Route>
          <Route exact path={`${match.path}/assets/viewsinglerfid/:id`}>
            <ViewSingleRFIDdetails></ViewSingleRFIDdetails>
          </Route>
          <Route exact path={`${match.path}/firmware/updatefirmware/:stationid/:location`}>
            <UpdateChargerFirmware></UpdateChargerFirmware>
          </Route>
          <Route exact path={`${match.path}/assets/utility`}>
            <Utility></Utility>
          </Route>
          <Route exact path={`${match.path}/assets/viewsingleutility/:id`}>
            <ViewSingleUtilityDetails></ViewSingleUtilityDetails>
          </Route>
          <Route exact path={`${match.path}/assets/editutility/:id`}>
            <Editutility></Editutility>
          </Route>
          <Route exact path={`${match.path}/assets/viewsingleconnector/:id`}>
            <ViewSingleConnectorDetails></ViewSingleConnectorDetails>
          </Route>
          <Route exact path={`${match.path}/pricing/viewsingleplan/:id`}>
            <ViewSinglePlanDetails></ViewSinglePlanDetails>
          </Route>
          <Route exact path={`${match.path}/customers/customerreport/:mobile`}>
            <CustomerReport></CustomerReport>
          </Route>
          <Route exact path={`${match.path}/customer/addChargerConfigurer`}>
            <ChargerConfigurer></ChargerConfigurer>
          </Route>
          
          <Route exact path={`${match.path}/customers/singlecustomerwallet/:mobile`}>
            <CustomerWalletReport></CustomerWalletReport>
          </Route>
          <Route exact path={`${match.path}/access/viewaccess/:userId`}>
            <ViewAssignedAccess></ViewAssignedAccess>
          </Route>
          <Route exact path={`${match.path}/access/editaccess/:userId`}>
            <EditAssignedAccess></EditAssignedAccess>
          </Route>
          <Route exact path={`${match.path}/pricing/viewsingleprice/:id`}>
            <ViewSinglePriceDetails></ViewSinglePriceDetails>
          </Route>
          <Route exact path={`${match.path}/fleet/grouprfid`}>
            <FleetRFID />
          </Route>
          <Route exact path={`${match.path}/fleet/fleetgrouprfid`}>
            <FleetGroupRFID />
          </Route>
          <Route exact path={`${match.path}/inventory/createasset`}>
            <CreateInventoryAsset />
          </Route>
          <Route exact path={`${match.path}/inventory/viewsingleasset/:id`}>
            <ViewInventoryAsset></ViewInventoryAsset>
          </Route>
          <Route exact path={`${match.path}/inventory/editasset/:id`}>
            <EditInventoryAssets></EditInventoryAssets>
          </Route>
          <Route exact path={`${match.path}/pricing/editplan/:id`}>
            <EditPlan></EditPlan>
          </Route>
          <Route exact path={`${match.path}/hub/meterdata`}>
            <ManageHubmeterData></ManageHubmeterData>
          </Route>
          <Route exact path={`${match.path}/hub/hubstatus`}>
            <ManageHubStatusPage></ManageHubStatusPage>
          </Route>
          <Route exact path={`${match.path}/hub/operation`}>
            <HubOperation></HubOperation>
          </Route>
          <Route exact path={`${match.path}/hub/pmr`}>
            <PmrManagementPage></PmrManagementPage>
          </Route>
          <Route exact path={`${match.path}/hub/attendance`}>
            <AttendanceCard></AttendanceCard>
          </Route>
          <Route exact path={`${match.path}/hub/vehicleStatus`}>
            <VehicleStatusReport></VehicleStatusReport>
          </Route>
          <Route exact path={`${match.path}/hub/chargerUptime`}>
            <ChargerUpTimeReport></ChargerUpTimeReport>
          </Route>
          <Route exact path={`${match.path}/assets/editrfid/:rfid`}>
            <Editrfid></Editrfid>
          </Route>
          <Route exact path={`${match.path}/hub/hubassignaccess`}>
            <HubAssignAccess></HubAssignAccess>
          </Route>
          <Route exact path={`${match.path}/hub/hazardreport`}>
            <ManageHubHazardReport></ManageHubHazardReport>
          </Route>
          <Route exact path={`${match.path}/hub/viewhubaccess/:mobile`}>
            <ViewHubAccess></ViewHubAccess>
          </Route>
          <Route exact path={`${match.path}/hub/edithubaccess/:mobile`}>
            <EditHubAccess></EditHubAccess>
          </Route>
          <Route exact path={`${match.path}/hub/bindingqrcode`}>
            <BindingQRcode></BindingQRcode>
          </Route>
          <Route exact path={`${match.path}/hub/batterytypemaster`}>
            <BateryTypeMaster></BateryTypeMaster>
          </Route>
          <Route exact path={`${match.path}/hub/fleetlistmaster`}>
            <FleetOperatorListMaster></FleetOperatorListMaster>
          </Route>
          <Route exact path={`${match.path}/hub/hubnamelistmaster`}>
            <HubNameListMaster></HubNameListMaster>
          </Route>
          <Route exact path={`${match.path}/hub/qrcodelistmaster`}>
            <QRcodeListMaster></QRcodeListMaster>
          </Route>
          <Route exact path={`${match.path}/hub/vehiclenolistmaster`}>
            <VehicleNoListMaster></VehicleNoListMaster>
          </Route>
          <Route exact path={`${match.path}/hub/vehicletypemaster`}>
            <VehicleTypeMaster></VehicleTypeMaster>
          </Route>
          <Route exact path={`${match.path}/stations/booking`}>
          <BookingCard></BookingCard>
          </Route>
          <Route exact path={`${match.path}/stations/booking/managebooking/:stationid/:location`}>
          <ManageBooking></ManageBooking>
          </Route>
          <Route exact path={`${match.path}/stations/booking/viewbooking/:chargeboxid`}>
          <ViewSingleChargerBooking></ViewSingleChargerBooking>
          </Route>
          <Route exact path={`${match.path}/firmware/firmwareupdatebyair`}>
          <Firmwareupdatebyair></Firmwareupdatebyair>
          </Route>
          
        </Switch>
      </div>
    </div>
  )

}

export default DashboardLayout;