import React, { useEffect, useState } from "react";
import {
    changeConfigurationAction,
    changeConfigurationchargeboxidAction,
    changeConfigurationendpointAction,
    changeConfigurationetworkAction,
    changeConfigurationwifissidAction,
    changeConfigurationwifipassAction,
    changeConfigurationoperatormodeAction,
    changeConfigurationochargemodelAction,
    changeConfigurationconnectiotimeAction,
    changeConfigurationloadsimulationAction,
    changeConfigurationsaveconfAction,
    changeConfigurationrfid1Action,
    changeConfigurationrfid2Action
} from "../../../store/actions/stationAction";

import { connect } from "react-redux";

const UpdateChargerFirmware = (props) => {

    const [firmware, setFirmware] = useState({
        chargerBoxid: "",
        key: "MEVS_ChargeBoxId",
        value: "",
    });

    const [url, setUrl] = useState({
        chargerBoxid1: "",
        key1: "MEVS_ServerEndPoint",
        value1: "",
    });

    const [network, setNetwork] = useState({
        chargerBoxid2: "",
        key2: "MEVS_NetworkMode",
        value2: "",
    });

    const [wifissid, setWifissid] = useState({
        chargerBoxid3: "",
        key3: "MEVS_WifiSsid",
        value3: "",
    });

    const [wifipass, setWifipass] = useState({
        chargerBoxid4: "",
        key4: "MEVS_WifiPassword",
        value4: "",
    });
    const [operatormode, setOperatormode] = useState({
        chargerBoxid5: "",
        key5: "OperatorMode",
        value5: "",
    });

    const [chargepointmodel, setChargepointmodel] = useState({
        chargerBoxid6: "",
        key6: "MEVS_ChargePointModel",
        value6: "",
    });

    const [connectiontiomeout, setConnectionTimeout] = useState({
        chargerConnectionTimeOutBoxid7: "",
        key7: "ConnectionTimeOut",
        value7: "",
    });
    const [loadsimulation, setLoadsimulation] = useState({
        chargerBoxid8: "",
        key8: "MEVS_LoadSimulation",
        value8: "",
    });
    const [saveconfiguration, setSaveconfiguration] = useState({
        chargerBoxid9: "",
        key9: "MEVS_SaveChargerConfiguration",
        value9: "true",
    });
    const [saverfid1, setSaveRFID1] = useState({
        chargerBoxid10: "",
        key10: "MEVS_RFID_TAG_1",
        value10: "",
    });
    const [saverfid2, setSaveRFID2] = useState({
        chargerBoxid11: "",
        key11: "MEVS_RFID_TAG_2",
        value11: "",
    });
    // handle change
    const [isRebootRequired, setIsRebootRequired] = useState(false);

    const handleChange = (e) => {
        setFirmware({
            ...firmware,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const handleChangeURL = (e) => {
        setUrl({
            ...url,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const handleChangeNetwork = (e) => {
        setNetwork({
            ...network,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const handleChangeWifissid = (e) => {
        setWifissid({
            ...wifissid,
            [e.target.name]: e.target.value.trim(),
        });
    }


    const handlesetWifipass = (e) => {
        setWifipass({
            ...wifipass,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const handleOperatormode = (e) => {
        setOperatormode({
            ...operatormode,
            [e.target.name]: e.target.value.trim(),
        });
    }


    const handleChangeChargepointmodel = (e) => {
        setChargepointmodel({
            ...chargepointmodel,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const handleConnectionTimeout = (e) => {
        setConnectionTimeout({
            ...connectiontiomeout,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const handleLoadsimulation = (e) => {
        setLoadsimulation({
            ...loadsimulation,
            [e.target.name]: e.target.value.trim(),
        });
    }
    const handleSaveconfiguration = (e) => {
        setSaveconfiguration({
            ...saveconfiguration,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const handleSaverfid1 = (e) => {
        setSaveRFID1({
            ...saverfid1,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const handleSaverfid2 = (e) => {
        setSaveRFID2({
            ...saverfid2,
            [e.target.name]: e.target.value.trim(),
        });
    }

    //Submit form 
    const handlechargeboxidSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid, key, value } = firmware;
        // Check if any of the fields is empty
        if (!chargerBoxid || !key || !value) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigBychargebox(chargerBoxid, key, value);

    };

    const handleserverendpointSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid1, key1, value1 } = url;

        if (!firmware.chargerBoxid || !key1 || !value1) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigByendpoint(firmware.chargerBoxid, key1, value1);

    };

    const handlenetworkmodSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid2, key2, value2 } = network;
        if (!firmware.chargerBoxid || !key2 || !value2) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigByntwork(firmware.chargerBoxid, key2, value2);

    };

    const handlewifissidSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid3, key3, value3 } = wifissid;
        if (!firmware.chargerBoxid || !key3 || !value3) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigByssid(firmware.chargerBoxid, key3, value3);

    };

    const handlesetWifipassSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid4, key4, value4 } = wifipass;
        if (!firmware.chargerBoxid || !key4 || !value4) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigBypass(firmware.chargerBoxid, key4, value4);

    };
    const handleOperatormodeSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid5, key5, value5 } = operatormode;
        if (!firmware.chargerBoxid || !key5 || !value5) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigByoperator(firmware.chargerBoxid, key5, value5);

    }

    const handleChangeChargepointmodelSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid6, key6, value6 } = chargepointmodel;
        if (!firmware.chargerBoxid || !key6 || !value6) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigBymodel(firmware.chargerBoxid, key6, value6);

    }


    const handleConnectionTimeoutSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid7, key7, value7 } = connectiontiomeout;
        if (!firmware.chargerBoxid || !key7 || !value7) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigByconntime(firmware.chargerBoxid, key7, value7);

    }

    const handleRfid1Submit = (e) => {
        e.preventDefault();
        const { chargerBoxid10, key10, value10 } = saverfid1;
        if (!firmware.chargerBoxid || !key10 || !value10) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigByrfid1(firmware.chargerBoxid, key10, value10);
    }


    const handleRfid2Submit = (e) => {
        e.preventDefault();
        const { chargerBoxid11, key11, value11 } = saverfid2;
        if (!firmware.chargerBoxid || !key11 || !value11) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigByrfid2(firmware.chargerBoxid, key11, value11);
    }
    const handleLoadsimulationSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid8, key8, value8 } = loadsimulation;
        if (!firmware.chargerBoxid || !key8 || !value8) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigByloadsim(firmware.chargerBoxid, key8, value8);

    }

    const handleSaveconfigurationSubmit = (e) => {
        e.preventDefault();
        const { chargerBoxid9, key9, value9 } = saveconfiguration;
        if (!firmware.chargerBoxid || !key9 || !value9) {
            alert("Please fill in all the fields before submitting.");
            return;
        }
        props.ChangeConfigBysaveconf(firmware.chargerBoxid, key9, value9);

    }

    // if (props.changeconfigsaveconf && props.changeconfigsaveconf.result == "RebootRequired") {
    //     alert("Charger Configure Successfully Happy Charging")
    //     window.location.reload();
    // }

    useEffect(() => {
        if (props.changeconfigsaveconf && props.changeconfigsaveconf.result === "RebootRequired") {
            setIsRebootRequired(true);

            // Delay the page reload after 5 seconds
            setTimeout(() => {
                alert("Charger Configure Successfully Happy Charging");
                window.location.reload();
            }, 4000);
        }
    }, [props.changeconfigsaveconf]);


    return (
        <>

            <div className="row-fluid">
                <div className="col">

                    <div className="row" >
                        <div className="col-md-12 col-xl-12">
                            <div className="sideCardDesigns mb-3 widget-content">

                                <h4 className="text-center formHeading mb-2">
                                    <b>Configure Charger</b>
                                </h4>
                                {/*  // chargerbox id configration*/}

                                <form className="form" onSubmit={handlechargeboxidSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}> CHARGEBOX ID</label>

                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid" name="chargerBoxid" className="form-control control-label" placeholder="Path" onChange={handleChange} />
                                                    <label htmlFor="chargerBoxid">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key" name="key" className="form-control control-label" placeholder="Path" />
                                                    <label htmlFor="key">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value">Value</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="value" name="value" className="form-control control-label" placeholder="Path" onChange={handleChange} />
                                                    <label htmlFor="value">Value</label>
                                                </div>


                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3">
                                                    {!props.loading ? "Configure Chargebox ID" : "Please wait..."}
                                                </button>
                                            </div>
                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigchargebox && props.changeconfigchargebox.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Eg. chargerBoxid": "CGXX2109","key":"MEVS_ChargeBoxId","value":"CGMH000121"</h6>}
                                            </div>

                                        </div>
                                    </div>

                                </form>

                                {/*  // serverendpoint configration*/}
                                <form className="form" onSubmit={handleserverendpointSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  SERVER ENDPOINT [URL]</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid1">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid1" name="chargerBoxid1" className="form-control control-label" placeholder="Path" value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid1">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key1">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key1" name="key1" className="form-control control-label" placeholder="Path" onChange={handleChangeURL} />
                                                    <label htmlFor="key1">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value1">Value</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="value1" name="value1" className="form-control control-label" placeholder="Path" onChange={handleChangeURL} />
                                                    <label htmlFor="value1">Value</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3" >
                                                    {!props.loading ? "Configure Server Enpoint" : "Please wait..."}
                                                </button>
                                            </div>
                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigurl && props.changeconfigurl.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Eg. "CGXX2109","key":"MEVS_ServerEndPoint","value":"ws://stage.magentachargegrid.com:8887/ws/"</h6>}
                                            </div>
                                        </div>
                                    </div>


                                </form>
                                {/*  // networkmod configration*/}

                                <form className="form" onSubmit={handlenetworkmodSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  NETWORK MODE</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid2">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid2" name="chargerBoxid2" className="form-control control-label" placeholder="Path" onChange={handleChangeNetwork} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid2">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key2">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key2" name="key2" className="form-control control-label" placeholder="Path" onChange={handleChangeNetwork} />
                                                    <label htmlFor="key2">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value2">Value</label>
                                                <div className="form-label-group">
                                                    {/* <input type="text" id="value2" name="value2" className="form-control control-label" placeholder="Path" onChange={handleChangeNetwork} />
                                                    <label htmlFor="value2">Value</label> */}


                                                    <select className="form-control border-radius" id="value2" name="value2" onChange={handleChangeNetwork}>
                                                        <option>Select Network</option>
                                                        <option>AllNetwork</option>
                                                        <option>OnlyWifi</option>
                                                        <option>OnlyGsm</option>
                                                        <option>OnlyOffline</option>

                                                    </select>
                                                </div>


                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3">
                                                    {!props.loading ? "Configure Network Mode" : "Please wait..."}
                                                </button>
                                            </div>

                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfignetwork && props.changeconfignetwork.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Eg. "chargerBoxid": "CGXX2109","key":"MEVS_NetworkMode","value":"AllNetwork"</h6>}
                                            </div>
                                        </div>
                                    </div>


                                </form>


                                {/*  // wifissid configration*/}

                                <form className="form" onSubmit={handlewifissidSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  WIFI SSID</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid3">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid3" name="chargerBoxid3" className="form-control control-label" placeholder="Path" onChange={handleChangeWifissid} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid3">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key3">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key3" name="key3" className="form-control control-label" placeholder="Path" onChange={handleChangeWifissid} />
                                                    <label htmlFor="key3">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value3">Value</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="value3" name="value3" className="form-control control-label" placeholder="Path" onChange={handleChangeWifissid} />
                                                    <label htmlFor="value3">Value</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3" >
                                                    {!props.loading ? "Configure WIFI SSID" : "Please wait..."}
                                                </button>
                                            </div>

                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigssid && props.changeconfigssid.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Eg. "chargerBoxid": "CGXX2109","key":"MEVS_WifiSsid","value":"Magenta House 2.4GHz"</h6>}
                                            </div>

                                        </div>
                                    </div>


                                </form>


                                {/* 4 // change password configration*/}

                                <form className="form" onSubmit={handlesetWifipassSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  WIFI PASSWORD</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid4">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid4" name="chargerBoxid4" className="form-control control-label" placeholder="Path" onChange={handlesetWifipass} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid4">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key4">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key4" name="key4" className="form-control control-label" placeholder="Path" onChange={handlesetWifipass} />
                                                    <label htmlFor="key4">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value4">Value</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="value4" name="value4" className="form-control control-label" placeholder="Path" onChange={handlesetWifipass} />
                                                    <label htmlFor="value4">Value</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3" >
                                                    {!props.loading ? "Configure Wifi Password" : "Please wait..."}
                                                </button>
                                            </div>

                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigpass && props.changeconfigpass.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Eg. "chargerBoxid": "CGXX2109","key":"MEVS_WifiPassword","value":"M***************"</h6>}
                                            </div>


                                        </div>
                                    </div>


                                </form>


                                {/* 5 // handleOperatormode configration*/}

                                <form className="form" onSubmit={handleOperatormodeSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}> OPERATOR MODE</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid5">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid5" name="chargerBoxid5" className="form-control control-label" placeholder="Path" onChange={handleOperatormode} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid5">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key5">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key5" name="key5" className="form-control control-label" placeholder="Path" onChange={handleOperatormode} />
                                                    <label htmlFor="key5">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value5">Value</label>
                                                <div className="form-label-group">
                                                    {/* <input type="text" id="value5" name="value5" className="form-control control-label" placeholder="Path" onChange={handleOperatormode} />
                                                    <label htmlFor="value5">Value</label> */}


                                                    <select className="form-control border-radius" id="value5" name="value5" onChange={handleOperatormode}>
                                                        <option>Select Operator Mode</option>
                                                        <option>fleet</option>
                                                        <option>private</option>
                                                        <option>public</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3" >
                                                    {!props.loading ? "Configure Operator Mode" : "Please wait..."}
                                                </button>
                                            </div>

                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigoperator && props.changeconfigoperator.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Example. "chargerBoxid": "CGXX2109","key":"OperatorMode","value":"fleet"</h6>}
                                            </div>

                                        </div>
                                    </div>


                                </form>


                                {/* 6 // handleChangeChargepointmodel configration*/}

                                <form className="form" onSubmit={handleChangeChargepointmodelSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  CHARGE POINT MODEL</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid6">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid6" name="chargerBoxid6" className="form-control control-label" placeholder="Path" onChange={handleChangeChargepointmodel} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid6">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key6">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key6" name="key6" className="form-control control-label" placeholder="Path" onChange={handleChangeChargepointmodel} />
                                                    <label htmlFor="key6">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value6">Value</label>
                                                <div className="form-label-group">
                                                    {/* <input type="text" id="value6" name="value6" className="form-control control-label" placeholder="Path" onChange={handleChangeChargepointmodel} />
                                                    <label htmlFor="value6">Value</label> */}

                                                    <select className="form-control border-radius" id="value6" name="value6" onChange={handleChangeChargepointmodel}>
                                                        <option>Select Charge Point Model</option>

                                                        <option>PACT-OCPP-IEC</option>
                                                        <option>PACT-OCPP-T2</option>
                                                        <option>PENTA-IEC</option>
                                                        <option>PENTA-T2</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3">
                                                    {!props.loading ? "Configure Charge Point Model" : "Please wait..."}
                                                </button>
                                            </div>

                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigmodel && props.changeconfigmodel.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Eg. "chargerBoxid": "CGXX2109","key":"MEVS_ChargePointModel","value":"PACT-OCPP-IEC"</h6>}
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}> Description:
                                                    CGXX2109= Existing Charge Box Id.,
                                                    MEVS_ChargePointModel= Key name.,
                                                    PACT-OCPP-IEC = If charger is PACT IEC.,
                                                    PACT-OCPP-T2 = If charger is PACT TYPE2.,
                                                    PENTA-IEC = If charger is PENTA IEC.,
                                                    PENTA-T2 = If charger is PENTA TYPE2.</h6>}
                                            </div>

                                        </div>
                                    </div>


                                </form>



                                {/* 7 // handleConnectionTimeout configration*/}

                                <form className="form" onSubmit={handleConnectionTimeoutSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  CONNECTION TIMEOUT [NO LOAD TIME]</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid7">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid7" name="chargerBoxid7" className="form-control control-label" placeholder="Path" onChange={handleConnectionTimeout} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid7">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key7">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key7" name="key7" className="form-control control-label" placeholder="Path" onChange={handleConnectionTimeout} />
                                                    <label htmlFor="key7">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value7">Value</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="value7" name="value7" className="form-control control-label" placeholder="Path" onChange={handleConnectionTimeout} />
                                                    <label htmlFor="value7">Value</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3">
                                                    {!props.loading ? "Configure Connection Time Out" : "Please wait..."}
                                                </button>
                                            </div>

                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigtimeout && props.changeconfigtimeout.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Example: "chargerBoxid": "CGXX2109","key":"ConnectionTimeOut","value":"180"</h6>}
                                            </div>


                                        </div>
                                    </div>
                                </form>


                                {/*  8// handleLoadsimulation configration*/}

                                <form className="form" onSubmit={handleLoadsimulationSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  LOAD SIMULATION</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid8">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid8" name="chargerBoxid8" className="form-control control-label" placeholder="Path" onChange={handleLoadsimulation} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid8">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key8">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key8" name="key8" className="form-control control-label" placeholder="Path" onChange={handleLoadsimulation} />
                                                    <label htmlFor="key8">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value8">Value</label>
                                                <div className="form-label-group">
                                                    {/* <input type="text" id="value8" name="value8" className="form-control control-label" placeholder="Path" onChange={handleLoadsimulation} />
                                                    <label htmlFor="value8">Value</label> */}

                                                    <select className="form-control border-radius" id="value8" name="value8" onChange={handleLoadsimulation}>
                                                        <option>Select Load Simulation</option>
                                                        <option>true</option>
                                                        <option>false</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3">
                                                    {!props.loading ? "Configure Load Simulation" : "Please wait..."}
                                                </button>
                                            </div>
                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigload && props.changeconfigload.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Eg. "chargerBoxid": "CGXX2109","key":"MEVS_LoadSimulation","value":"false"</h6>}
                                            </div>


                                        </div>
                                    </div>
                                </form>

                                {/* 10 // RFID1 configration*/}

                                <form className="form" onSubmit={handleRfid1Submit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  RFID 1</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid10">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid10" name="chargerBoxid10" className="form-control control-label" placeholder="Path" onChange={handleSaverfid1} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid10">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key10">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key10" name="key10" className="form-control control-label" placeholder="Path" onChange={handleSaverfid1} />
                                                    <label htmlFor="key10">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value7">Value</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="value10" name="value10" className="form-control control-label" placeholder="Path" onChange={handleSaverfid1} />
                                                    <label htmlFor="value10">Value</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3">
                                                    {!props.loading ? "Configure RFID 1" : "Please wait..."}
                                                </button>
                                            </div>

                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigrfid1 && props.changeconfigrfid1.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Example: "key":"MEVS_RFID_TAG_1","readonly":false,"value":"1234abcd"</h6>}
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}> Description:
                                                    MEVS_WifiSsid:- Wifi ssid with which the charger will be connected . Default will be “MEVS-SERVICE-01”.
                                                    MEVS_WifiPassword:- Wifi password with which charger will be connected. Default will be “service1234”.
                                                    MEVS_ChargeBoxId:- Charge Box Id to identify the charger connected with the server. Default will be generated random No. while first programming or Factory Reset. E.g CGXX8125.  For Offline Mode E.g OFLN_MODE.</h6>}
                                            </div>
                                        </div>
                                    </div>
                                </form>


                                {/* 11 // RFID2 configration*/}

                                <form className="form" onSubmit={handleRfid2Submit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  RFID 2</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid11">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid11" name="chargerBoxid11" className="form-control control-label" placeholder="Path" onChange={handleSaverfid2} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid11">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="key10">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key11" name="key11" className="form-control control-label" placeholder="Path" onChange={handleSaverfid2} />
                                                    <label htmlFor="key11">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="value7">Value</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="value11" name="value11" className="form-control control-label" placeholder="Path" onChange={handleSaverfid2} />
                                                    <label htmlFor="value11">Value</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3">
                                                    {!props.loading ? "Configure RFID 2" : "Please wait..."}
                                                </button>
                                            </div>

                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigrfid2 && props.changeconfigrfid2.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Example: "key":"MEVS_RFID_TAG_2","readonly":false,"value":"1234abcd"</h6>}
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}> Description:
                                                    MEVS_WifiSsid:- Wifi ssid with which the charger will be connected . Default will be “MEVS-SERVICE-01”.
                                                    MEVS_WifiPassword:- Wifi password with which charger will be connected. Default will be “service1234”.
                                                    MEVS_ChargeBoxId:- Charge Box Id to identify the charger connected with the server. Default will be generated random No. while first programming or Factory Reset. E.g CGXX8125.  For Offline Mode E.g OFLN_MODE.</h6>}
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                {/* 12 // handleSaveconfiguration configration*/}

                                <form className="form" onSubmit={handleSaveconfigurationSubmit} autoComplete="off">
                                    <div className="container-fluid mt-2">
                                        <label className="formLable" style={{ fontSize: "14px" }}>  SAVE CHARGER CONFIGURATION</label>
                                        <div className="row formContainer">

                                            <div className="col-md-3">
                                                <label className="formLable" htmlFor="chargerBoxid9">Chargebox ID</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="chargerBoxid9" name="chargerBoxid9" className="form-control control-label" placeholder="Path" onChange={handleSaveconfiguration} value={firmware.chargerBoxid} disabled />
                                                    <label htmlFor="chargerBoxid9">Chargebox ID</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6" hidden>
                                                <label className="formLable" htmlFor="key9">Key</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="key9" name="key9" className="form-control control-label" placeholder="Path" onChange={handleSaveconfiguration} />
                                                    <label htmlFor="key9">Key</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3" >
                                            </div>
                                            <div className="col-md-3" hidden>
                                                <label className="formLable" htmlFor="value9">Value</label>
                                                <div className="form-label-group">
                                                    <input type="text" id="value9" name="value9" className="form-control control-label" placeholder="Path" onChange={handleSaveconfiguration} />
                                                    <label htmlFor="value9">Value</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                <button className="btn btn-primary mt-3" >
                                                    {!props.loading ? "Save Charger Configuration" : "Please wait..."}
                                                </button>
                                            </div>
                                            <div className="col-md-3 d-flex align-items-center">
                                                <div>
                                                    {<h6 style={{ fontSize: "16px" }}>Response :{props.changeconfigsaveconf && props.changeconfigsaveconf.result}</h6>}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                {<h6 style={{ fontSize: "10px" }}>Eg. "chargerBoxid": "CGXX2109","key":"MEVS_SaveChargerConfiguration","value":"true"</h6>}
                                            </div>


                                        </div>
                                    </div>
                                </form>

                                {/* Back button */}
                                <div className="row mt-5">
                                    <div className="col-xl-6 col-sm-6 col-12">
                                        <a
                                            className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                            href="/dashboard/firmware"
                                        >
                                            Back
                                        </a>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )


}

const mapStateToProps = (state) => {
    return {
        changeconfig: state.station.changeconfiguration,
        changeconfigchargebox: state.station.changeconfigurationchargeboxid,
        changeconfigurl: state.station.changeconfigurationurl,
        changeconfignetwork: state.station.changeconfigurationnetwork,
        changeconfigssid: state.station.changeconfigurationwifissid,
        changeconfigpass: state.station.changeconfigurationwifipass,
        changeconfigoperator: state.station.changeconfigurationoperatormode,
        changeconfigmodel: state.station.changeconfigurationchargepointmodel,
        changeconfigtimeout: state.station.changeconfigurationconnectiontimeout,
        changeconfigload: state.station.changeconfigurationloadsimulation,
        changeconfigrfid1: state.station.changeconfigurationrfid1,
        changeconfigrfid2: state.station.changeconfigurationrfid2,
        changeconfigsaveconf: state.station.changeconfigurationsaveconfiguration,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ChangeConfigByID: (chargeboxid, Key, value) => dispatch(changeConfigurationAction(chargeboxid, Key, value)),
        ChangeConfigBychargebox: (chargeboxid, Key, value) => dispatch(changeConfigurationchargeboxidAction(chargeboxid, Key, value)),
        ChangeConfigByendpoint: (chargeboxid, Key, value) => dispatch(changeConfigurationendpointAction(chargeboxid, Key, value)),
        ChangeConfigByntwork: (chargeboxid, Key, value) => dispatch(changeConfigurationetworkAction(chargeboxid, Key, value)),
        ChangeConfigByssid: (chargeboxid, Key, value) => dispatch(changeConfigurationwifissidAction(chargeboxid, Key, value)),
        ChangeConfigBypass: (chargeboxid, Key, value) => dispatch(changeConfigurationwifipassAction(chargeboxid, Key, value)),
        ChangeConfigByoperator: (chargeboxid, Key, value) => dispatch(changeConfigurationoperatormodeAction(chargeboxid, Key, value)),
        ChangeConfigBymodel: (chargeboxid, Key, value) => dispatch(changeConfigurationochargemodelAction(chargeboxid, Key, value)),
        ChangeConfigByconntime: (chargeboxid, Key, value) => dispatch(changeConfigurationconnectiotimeAction(chargeboxid, Key, value)),
        ChangeConfigByloadsim: (chargeboxid, Key, value) => dispatch(changeConfigurationloadsimulationAction(chargeboxid, Key, value)),
        ChangeConfigByrfid1: (chargeboxid, Key, value) => dispatch(changeConfigurationrfid1Action(chargeboxid, Key, value)),
        ChangeConfigByrfid2: (chargeboxid, Key, value) => dispatch(changeConfigurationrfid2Action(chargeboxid, Key, value)),
        ChangeConfigBysaveconf: (chargeboxid, Key, value) => dispatch(changeConfigurationsaveconfAction(chargeboxid, Key, value))


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateChargerFirmware);
