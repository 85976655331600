import React, { Component } from "react";
import './sideicons.css'

class SideToolBar extends Component {
  state = {
    selected: []
  }
  selectImage(index) {
    var selected = this.state.selected;
    if (selected.indexOf(index) !== -1) selected.push(index);
    this.setState({ selected: selected });
  }

  eventHandler(evt) {
    if (evt.target.id.includes("arrowImg_")) {
      let selectedMenuArrow = evt.target.parentElement.parentElement.parentElement
      selectedMenuArrow.classList.toggle("showMenuOpts")
    }
  }


  render() {
    const { privileges, role } = JSON.parse(localStorage.getItem('user'))
    return (
      <div onClick={(evt) => { this.eventHandler(evt) }}  >
        <ul className="unordered-list glassbackground pt-3">
          {privileges && privileges.map((item, index) => (
            <li className="list-item" key={index} >
              <div className="d-flex justify-content-between align-items-center">
                <a href={`${this.props.match.url}/${item.key}`} onClick={this.selectImage.bind(this, item.key)} className={(this.state.selected.indexOf(item.key) !== -1) ? "selected" : "not-selected"} >
                  <span className="icon"><img style={{ height: "18px", width: "18px" }} src={process.env.PUBLIC_URL + '/images/Sidebarlogo/' + item.code} /></span>
                  <span className="title" style={{ color: "#FFFFFF" }}>{item.name}</span>
                </a>
                {item.key === "hub" ? (<span id={`sp_${index}`} className="menu-icon" ><img id={`arrowImg_${index}`} style={{ height: "24px", color: "#fff", width: "28px" }} src={process.env.PUBLIC_URL + '/images/Sidebarlogo/' + "menu-dn-arrow.svg"} /></span>) : ''}
              </div>

              {/* Sub-menu only visible for hub management module */}

              {item.key === "hub" ? (
                <ul key={`smul_${index}`} className="sub-menu">
                  {/* <li key={`smuli_${index}`} >
                    <a className="sm-title" href={`${this.props.match.url}/${item.key}/insights`}>Hub Insights </a>
                  </li> */}
                  <li key={`smuli_${index}`} >
                    <a href={`${this.props.match.url}/${item.key}`}>
                      Hub Management
                    </a>
                  </li>
                </ul>
              ) : ''}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default SideToolBar;