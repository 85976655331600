import React, { useEffect, useState } from "react";

const Pagination = ({showPerPage, onPaginationChange}) =>{
    const [ counter, setCounter ]= useState(1);
    useEffect (() => {
       const value = showPerPage * counter;
       onPaginationChange( value - showPerPage, value);


    }, [counter]);

    const onButtonClick = (type) => {
      if (type === "prev") {
          if (counter === 1){
              setCounter(1);
          }else {
              setCounter(counter - 1);
          }

      }  else if(type === "next"){
          setCounter(counter + 1);
      }
    };

    return (
        <div className="d-flex justify-content-between">
        <button className="btn btn-primary btn-sm" onClick = {() => onButtonClick("prev")}>Previous</button>
        <button className="btn btn-primary btn-sm" onClick = {() => onButtonClick("next")}>Next</button>
    </div>

    );
};

export default Pagination;