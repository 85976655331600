import { FLEET_BASE_URL, FETCH_FLEET_LIST_URL, FETCH_FLEET_TRANS_URL } from "../../components/config/configs";

export const fleetListAction = (planname) => {
  const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
  //console.log("token",JSON.parse(localStorage.getItem('user')))
  
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'FLEET_LOADING', payload: '' })
    // console.log("apicall",FLEET_BASE_URL + FETCH_FLEET_LIST_URL + "?userid=" + id + "&role=" + role + "&fleetid=" + planname + "&np=" + networkoperator)
    return fetch(FLEET_BASE_URL + FETCH_FLEET_LIST_URL + "?userid=" + id + "&role=" + role + "&fleetid=" + planname + "&np=" + networkoperator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
      //  console.log("Fleet Data----", data);
        dispatch({ type: 'FETCH_FLEET_LIST', payload: data })
      })
  }
}


export const fleetTranListAction = (planname) => {
  const { token, id, role, networkoperator, } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'FLEET_LOADING', payload: '' })
    //console.log("tranapicall", FLEET_BASE_URL + FETCH_FLEET_TRANS_URL + "?userid=" + id + "&role=" + role + "&fleetid=" + planname + "&np=" + networkoperator + "&viewtype=" + "All")
    return fetch(FLEET_BASE_URL + FETCH_FLEET_TRANS_URL + "?userid=" + id + "&role=" + role + "&fleetid=" + planname + "&np=" + networkoperator + "&viewtype=" + "All", {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
      //  console.log("Fleet Transaction Data----", data);
        dispatch({ type: 'FETCH_FLEET_TRANSACTION_LIST', payload: data })
      })
  }
}
