import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUserDetailsAction } from "../../../store/actions/userAction";
import { cityAction, countryAction, privilegeAction, stateAction, roleActionByNetworkOperator } from "../../../store/actions/commonAction";
import { operatorAction } from "../../../store/actions/opAction";
import { getSubscriptionPlanByCreatorAction } from "../../../store/actions/subscriptionAction";
var arr = [];

class ViewAssignedAccess extends Component {
    state = {

        access: { value: '', isValid: true, message: '' },
        role: JSON.parse(localStorage.getItem('user'))
    }

    //onload call apis
    componentDidMount() {
        const { match: { params } } = this.props;
        const token = '';
        this.props.getUser(params.userId, token);
        this.props.npData("Network Operator");
    }

    //on handalechnage change values
    handleChange = (e) => {
        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: e.target.value,
            }
        };
        if (e.target.name === "networkoperator") {
            this.props.rolelist("NP", this.state);
        }
        if (e.target.name === "country") {
            this.props.statelist(e.target.value);
        }
        if (e.target.name === "cstate") {
            this.props.citylist(e.target.value);
        }
        if (e.target.name === "role") {
            this.props.privilegelist(e.target.id);
        }
        if (e.target.name === "sedan.svg") {
            this.props.fetcSubscriptionplan(e.target.id);
        }
        this.setState(state);
    }

    //handle check change 
    handleCheckChange = (e) => {
        const target = e.target;
        let pvalue = target.value;
        let pid = target.id
        let cd = e.target['name']
        if (cd === "sedan.svg") {
            this.props.fetSubscriptionplan(e.target.id);
        }
        if (target.checked) {
            arr.push({ key: pid, name: pvalue, code: cd })
        } else {
            arr.splice(pvalue, 1);
        }
        this.setState({
            access: arr
        })
    }

    render() {
        // console.log("netwrokop", this.props.userdetail.networkoperator);
        // console.log("operator list", this.props.operatorlist);
        // console.log("Roles list", this.props.userdetail.planname);
        return (
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading"><b>User Details</b></h4>
                        <div className="row rowpadding  justify-content-between mt-5">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="username">Email address</label>
                                        <div className="form-label-group">
                                            <input type="email" id="username" className="form-control" value={this.props.userdetail.username} disabled />
                                            <label htmlFor="username">Email address</label>
                                        </div>
                                        <label className="formLable" htmlFor="password">Password</label>
                                        <div className="form-label-group">
                                            <input type="password" id="password" className="form-control" value={this.props.userdetail.password} disabled />
                                            <label htmlFor="password">Password</label>
                                        </div>

                                        <label className="formLable" htmlFor="firstname">First Name</label>
                                        <div className="form-label-group">
                                            <input type="text" id="firstname" className="form-control" value={this.props.userdetail.firstname} disabled />
                                            <label htmlFor="firstname">First Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="lastname">Last Name</label>
                                        <div className="form-label-group">
                                            <input type="text" id="lastname" className="form-control" value={this.props.userdetail.lastname} disabled />
                                            <label htmlFor="lastname">Last Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="organization">Organization Name</label>
                                        <div className="form-label-group">
                                            <input type="text" id="organization" className="form-control" value={this.props.userdetail.organization} disabled />
                                            <label htmlFor="organization">Organization Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="organizationtype">Organization type</label>
                                        <div className="form-label-group">
                                            <select class="form-control border-radius" id="organizationtype" >
                                                <option>{this.props.userdetail.organizationtype}</option>

                                            </select>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">
                                    <div className="col-12 ">
                                        <label className="formLable" htmlFor="addres">Organization Address</label>
                                        <div className="form-label-group">
                                            <input type="text" id="organizationadd" className="form-control" value={this.props.userdetail.address} disabled />
                                            <label htmlFor="organizationadd">Organization Address</label>
                                        </div>

                                        <label className="formLable" htmlFor="phone">Phone Number</label>
                                        <div className="form-label-group">
                                            <input type="text" id="offphoneno" className="form-control" value={this.props.userdetail.phone} disabled />
                                            <label htmlFor="offphoneno">Phone Number</label>
                                        </div>

                                        <label className="formLable" htmlFor="country">Country</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="country">
                                                <option>{this.props.userdetail.country}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="cstate">State</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="state">
                                                <option>{this.props.userdetail.state}</option>
                                            </select>

                                        </div>
                                        <label className="formLable" htmlFor="city">City</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="city" >
                                                <option>{this.props.userdetail.city}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="zipcode">Zip Code</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="city" >
                                                <option>{this.props.userdetail.city}</option>
                                            </select>
                                        </div>
                                       
                                       


                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row rowpadding formContainer m-1 mb-4">

                            <div className="col-xl-3 col-sm-3 col-12 p-3 ">
                                <h5 className="font-size-lg text-capitalize font-weight-normal pl-3"> Network Operator </h5>
                                <hr />
                                {this.props.operatorlist && this.props.operatorlist.map((network) => (
                                    <div className="custom-control custom-radio pl-5 " key={network.id} checked>
                                        <input type="radio" id={network.id} name="networkoperator" className="custom-control-input" value={network.id} onChange={this.handleChange} />
                                        <label className="custom-control-label" htmlFor={network.id} >
                                            {network.organization}
                                        </label>
                                    </div>
                                ))}

                                <span className="help-block text-danger">
                                    {/* {networkoperator.message} */}
                                </span>
                            </div>
                            <div className="col-xl-3 col-sm-3 col-12 p-3 ">
                                <h5>Select Access Role</h5>
                                <hr />
                                <div className="custom-control custom-radio pl-5">
                                    <input type="radio" name="customRadio" className="custom-control-input" value={this.props.userdetail.role} checked />
                                    <label className="custom-control-label">{this.props.userdetail.role}</label>
                                </div>

                            </div>
                            <div className="col-xl-3 col-sm-3 col-12 p-3">
                                <h5>Select Privileges</h5>
                                <hr />
                                {this.props.userdetail.privileges && this.props.userdetail.privileges.map((privilege) =>
                                    <div className="custom-control custom-checkbox pl-5">
                                        <input type="checkbox" id={privilege.key} name="customCheck" className="custom-control-input" value={privilege.name} checked />
                                        <label className="custom-control-label" htmlFor={privilege.key}>{privilege.name}</label>
                                    </div>
                                )}
                            </div>
                            <div className="col-xl-3 col-sm-3 col-12 p-3">
                                <h5 className="font-size-lg text-capitalize font-weight-normal"> Fleet Users </h5>
                                <hr />
                                <div className="custom-control custom-radio pl-5">
                                    <input type="radio" name="customRadio" className="custom-control-input" value={this.props.userdetail.planname} checked />
                                    <label className="custom-control-label">{this.props.userdetail.planname}</label>
                                </div>
                            </div>



                            </div>





                        <div className="row">
                            <div className="col-xl-12 col-sm-12 col-12">
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/access">Back</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = function (state) {
    return {
        userdetail: state.users.fetchUserDetail,
        operatorlist: state.operator.fetchOperator,
        roles: state.common.roleListByNp,
        privileges: state.common.privilegeList,
        sublistdata: state.subscriptionplan.fetcSubscriptionplan

    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUser: (id, token) => dispatch(getUserDetailsAction(id, token)),
        npData: (nrole) => dispatch(operatorAction(nrole)),
        rolelist: (access) => dispatch(roleActionByNetworkOperator(access)),
        fetSubscriptionplan: () => dispatch(getSubscriptionPlanByCreatorAction("All"))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewAssignedAccess));
