import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSingleUtilityActions } from "../../../../store/actions/assetAction"

class ViewSingleUtilityDetails extends Component {
    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getSingleUtility(params.id)

    }
    render() {
        return (

            <div className="container-fluid">
                <div className="sideCardDesigns card-signin">
                    <div className="card-body">
                        <h4 className="text-center formHeading mb-3"><b>View Utility</b></h4>
                        <div className="row rowpadding  justify-content-between">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="network_operator">Network Operator</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="network_operator" name="network_operator" disabled  >
                                                <option>{this.props.utilitysingledetail.network_operator}</option>

                                            </select>
                                        </div>
                                        <label className="formLable" htmlFor="enterprise">Enterprise</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="enterprise" name="enterprise" disabled  >
                                                <option>{this.props.utilitysingledetail.enterprise}</option>

                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="station_name">Station Name</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="station_name" name="station_name" disabled  >
                                                <option>{this.props.utilitysingledetail.station_name}</option>

                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="commissioned_date">Commissioned Date</label>
                                        <div className="form-label-group">
                                            <input className="form-control" id="commissioned_date" name="commissioned_date" value={this.props.utilitysingledetail.commissioned_date} disabled />
                                            <label htmlFor="commissioned_date">Commisioned Date</label>
                                        </div>

                                        <label className="formLable" htmlFor="service_number">Service Number</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="service_number" name="service_number" className="form-control control-label" value={this.props.utilitysingledetail.service_number} disabled />
                                            <label htmlFor="service_number">Service Number</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="electricity_bill_due_date">Electricity Bill Due date</label>
                                        <div className="form-label-group">
                                            <input className="form-control" id="electricity_bill_due_date" name="electricity_bill_due_date" value={this.props.utilitysingledetail.electricity_bill_due_date} disabled />
                                            <label htmlFor="electricity_bill_due_date">Electricity Bill Due date</label>
                                        </div>

                                        <label className="formLable" htmlFor="electricity_bill_amount">Electricity Bill Amount</label>
                                        <div className="form-label-group ">
                                            <input type="number" id="electricity_bill_amount" name="electricity_bill_amount" className="form-control control-label" value={this.props.utilitysingledetail.electricity_bill_amount} disabled />
                                            <label htmlFor="electricity_bill_amount">Electricity Bill Amount</label>
                                        </div>

                                        <label className="formLable" htmlFor="unit_price">Electricity Price per Unit</label>
                                        <div className="form-label-group ">
                                            <input type="number" id="unit_price" name="unit_price" className="form-control control-label" value={this.props.utilitysingledetail.unit_price} disabled />
                                            <label htmlFor="unit_price">Electricity Price per Unit</label>
                                        </div>

                                        <label className="formLable" htmlFor="enterdby">Entered By</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="enterdby" name="enterdby" className="form-control control-label" value={this.props.utilitysingledetail.enterdby} disabled />
                                            <label htmlFor="enterdby">Data Entered By</label>
                                        </div>
                                        <br />
                                        <br />
                                        <br />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-lg-3 ">
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase" href="/dashboard/assets">Back</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

const mapStateToProps = function (state) {
    return {
        utilitysingledetail: state.asset.fetchUtilityByID,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleUtility: (id) => dispatch(getSingleUtilityActions(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleUtilityDetails));
