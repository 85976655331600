const initState={
    saveConnectorType:'',
    isLoading:false,
    fetchConnectorTypeList:'',
    fetchSingleConnector:'',
    updateConnectorByID:'',
}
const ctReducer=(state=initState,action)=>{
    switch (action.type) {
        case 'CONNECTOR_TYPE_LOADING':
            return{...state,isLoading:true}
        case 'SAVE_CONNECTOR_TYPE':
           return {...state, saveConnectorType: action.payload,isLoading:false}
        case 'FETCH_CONNECTOR_TYPE_LIST':
           return {...state, fetchConnectorTypeList: action.payload,isLoading:false}
           case 'FETCH_SINGLE_CONNECTOR':
           return {...state, fetchSingleConnector: action.payload,isLoading:false}
           case 'UPDATE_CONNECTOR':
            return{...state, updateConnectorByID: action.payload,isLoading:false}
            
        default:
          return state;
      }
}
export default ctReducer;