import React, { Component } from "react";
import { connect } from "react-redux";
import "../login.css"
import validator from 'validator'
import { Redirect } from "react-router-dom";
import { forgotPassword } from "../../../store/actions/commonAction";
import HeaderPage from "../../landing/header";
import queryString from 'query-string';


class ForgotPassword extends Component {

    forgotPasswordform = {
        newPassword: { value: '', isValid: true, message: '' },
        confirmPassword: { value: '', isValid: true, message: '' },
        error: { value: '', isValid: true, message: '' }

    }
    state = {
        ...this.forgotPasswordform
    }

    //handlechange
    handleChange = (e) => {
        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: e.target.value,
            }
        };

        this.setState(state);
    }

    //form submition 
    handleSubmit = (e) => {
        e.preventDefault();
        this.resetValidationStates();
        if (this.formIsValid()) {
            const newPassword = { ...this.state.newPassword };
            const phone = JSON.parse(localStorage.getItem('phone'));
            this.props.resetPassword(phone.value, newPassword.value);
        }
    }

    //form validation

    formIsValid = () => {
        const newPassword = { ...this.state.newPassword };
        const confirmPassword = { ...this.state.confirmPassword };
        const error = { ...this.state }
        let isGood = true;
        if (validator.isEmpty(newPassword.value)) {
            newPassword.isValid = false
            newPassword.message = 'Password is required'
            isGood = false;
        }
        if (validator.isEmpty(confirmPassword.value)) {
            confirmPassword.isValid = false
            confirmPassword.message = 'Password is required'
            isGood = false;
        }
        if (newPassword.value !== confirmPassword.value) {
            error.isValid = false;
            error.message = 'Passwords do not match.'
            isGood = false;
        }
        if (!isGood) {
            this.setState({
                error,
                newPassword,
                confirmPassword,
            });
        }

        return isGood;
    }

    //reset validation
    resetValidationStates = () => {
        const state = JSON.parse(JSON.stringify(this.state));
        Object.keys(state).map(key => {
            if (state[key].hasOwnProperty('isValid')) {
                state[key].isValid = true;
                state[key].message = '';
            }
        });
        this.setState(state);
    }


    render() {
        const { newPassword, confirmPassword, error } = this.state;
        const queryStringParams = queryString.parse(window.location.search);
        const token = queryStringParams.auth;

        if (this.props.forgotpassword.result && this.props.forgotpassword.result === "Password changed successfully") {
            return <Redirect to='/dashboard' />;
        }

        return (
            <div className="container-fluid  h-1000">
                <div className="container-fluid ">
                    <div className="row ">
                        <div id="outer-container">
                            <div className="cardLogo text-center mt-3 ">
                                <a href="/forgotpassword" className="c_logo">
                                    {token === "tsredco" ? <img src="images/tsredco/logo.png" alt="TSREDCO" className="logo-tsr" /> : <img src="images/logo/magentaLogo.svg" style={{ width: "300px" }} alt="Chargegrid" className="logo" />}
                                </a>
                            </div>

                            <form className="form-signin" onSubmit={this.handleSubmit}>
                                <div className="sign-up-header " >
                                    <h5 style={{ color: "#1e295d", fontFamily: "Helvetica", fontSize: "22px", fontWeight: "600" }} >Create New Password</h5>
                                </div>
                                <div className="form-label-group required">
                                    <input type="password" id="newPassword" name="newPassword" className="form-control" placeholder="New password" onChange={this.handleChange} />
                                    <label className="loginLabel" htmlFor="newPassword">New password</label>
                                    <span className="help-block text-danger ml-1">{newPassword.message}</span>
                                </div>
                                <div className="form-label-group required">
                                    <input type="password" id="confirmPassword" name="confirmPassword" className="form-control" placeholder="Confirm new password" onChange={this.handleChange} />
                                    <label className="loginLabel" htmlFor="confirmPassword">Confirm new password</label>
                                    <span className="help-block text-danger ml-1">{confirmPassword.message}</span>
                                </div>
                                <div className="form-label-group">
                                    <span className="help-block text-danger">{error.message}</span>
                                </div>
                                <button className="btn btn-lg btn-primary btn-block text-uppercase otpBtn" style={{ fontSize: "18px" }}>{!this.props.loading ? 'RESET PASSWORD' : 'Please wait...'}</button>
                            </form>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-12 mx-auto footerContainer">
                            Powered by &nbsp; <a href=""><img style={{ height: "40px" }} src={process.env.PUBLIC_URL + "/images/logo/LogoLeft.svg"} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>

        )
    }


}

//call  reducer from Redux store

const mapStateToProps = (state) => {
    return {

        forgotpassword: state.common.forgotpassword

    }
}

//call action from Redux store

const mapDispatchToProps = dispatch => {
    return {

        resetPassword: (phone, password) => dispatch(forgotPassword(phone, password))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
