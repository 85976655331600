import React, { useMemo, useEffect, useState } from "react"
import moment from "moment";
import "../../report/table.css"
import { connect } from "react-redux";
import {getAllHubListData, getAllvisitorsattendance } from "../../../../store/actions/hubActions"
import Table from "./visitorattendanceTable";
import Loader from 'react-loader-spinner';


const VisitorAttendanceList = (props) => {
    const initialValues = { hubname: "All" }
    const [singlehubname, setSinglehubname] = useState(initialValues)

    useEffect(() => {
        let hubb = "All"
        props.getHubListData();

        if (hubb == "All") {
            props.getallvisitors(hubb);
        }
    }, []);

    const data1 = props.fetchallvisitor && props.fetchallvisitor
    const data = data1.result

    const handleChange = (e) => {
        let hubname = e.target.value
        // console.log("dvbsd", hubname)
        const { name, value } = e.target;
        setSinglehubname({ ...singlehubname, [name]: value });

        if (e.target.value !== "" && e.target.value !== undefined) {
            props.getallvisitors(hubname);
        }

    };


    const columns = useMemo(

        () => [
            {
                header: 'Visitor Name',
                accessor: 'name'
            },
            {
                header: 'Mobile',
                accessor: 'mobile'
            },
            {
                header: 'Hub Name',
                accessor: 'hubname'
            },

            // {
            //     header: 'Check-in Date',
            //     id: 'checkindate',
            //     accessor: d => {
            //       return moment.utc(d.checkindate).format("DD-MM-YYYY")
            //     }

            // },
            {
                header: 'Check-in Date',
                accessor: 'checkindate',
            },
           {
                header: 'Check-in Time',
                accessor: 'checkintime'

            },
            //   {
            //     header: 'Visitor Image',
            //     accessor: 'image'

            // },
            //  {
            //     header: 'Check-out Date',
            //     id: 'checkoutdate',
            //     accessor: d => {
            //       return moment.utc(d.checkoutdate).format("DD-MM-YYYY");
            //     }
            // },
            {
                header: 'Check-out Date',
                accessor: 'checkoutdate',
            },
           {
                header: 'Check-out Time',
                accessor: 'checkouttime'

            },
            {
                header: 'Purpose',
                accessor: 'purpose'

            },
        ],
        []
    );

    const hubList = props.fetchAllHubData && props.fetchAllHubData.map(id => id.hubname)

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col sideCardDesigns mb-2">

                    <div className="card-header singleCard justify-content-between mb-3">
                        <div className="text-left">
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Visitor Details</strong>
                            </div>
                        </div>
                        <div className=" text-right">
                            <select className="columnsBtn p-2" name="hubname" onChange={handleChange}>
                                <option value="All" >All</option>
                                {hubList && hubList.map((hub) => (
                                    <option key={hub.id} value={hub} >{hub}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col">
                            <div className="row ">
                                <div className="col-md-12 col-lg-12">
                                    <div className="mb-3 h-100 singleCard">
                                        {data && data.length > 0 ? (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                            :
                                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#1e295d", fontWeight: "600", fontSize: "30px", marginTop: "30px" }}>No Data Available</div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        fetchAllHubData: state.hub.getAllHubListData,
        fetchallvisitor: state.hub.fetchallvisitor,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getHubListData: () => dispatch(getAllHubListData()),
        getallvisitors: (hubname) => dispatch(getAllvisitorsattendance(hubname)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VisitorAttendanceList);