const initState={
    savePriceResponse:'',
    isLoading:false,
    fetchPrice:'',
    updatePriceResponse:'',
    fetchPriceList:'',
    editPriceByID:'',
    priceDeactivation:'',
}
const priceReducer=(state=initState,action)=>{
    switch (action.type) {
        case 'PRICE_LOADING':
            return{...state,isLoading:true}
        case 'SAVE_PRICE':
           return {...state, savePriceResponse: action.payload,isLoading:false}
        case 'FETCH_SINGLE_PRICE':
           return {...state, fetchPrice: action.payload,isLoading:false}     
        case 'UPDATE_PRICE':
           return {...state, updatePriceResponse: action.payload,isLoading:false}     
        case 'FETCH_PRICE_LIST':
            return {...state, fetchPriceList: action.payload,isLoading:false}     
        case 'EDIT_PRICE_BYID':
            return {...state, editPriceByID: action.payload,isLoading:false}     
        case 'PLAN_DEACTIVATION':
            return {...state, priceDeactivation: action.payload,isLoading:false}     
         
        default:
          return state;
      }
}
export default priceReducer;