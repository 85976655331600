import React from "react";
import "./campaign.css";
import SMS from "./sms";
import Email from "./email";
import { Line } from "react-chartjs-2";
import { CardBody, } from "reactstrap";

function Campaign() {
  let chartSMS = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)");
      return {
        labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL"],
        datasets: [
          {
            label: "Monthly Message",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [10, 40, 23, 58, 13, 35, 1],
          },
        ],
      };
    },
  };
  let chartEmail = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
      gradientStroke.addColorStop(0, "rgba(66,134,121,0.0)");
      gradientStroke.addColorStop(0, "rgba(66,134,121,0)");
      return {
        labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL"],
        datasets: [
          {
            label: "Monthly Email",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#00d6b4",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00d6b4",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00d6b4",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [50, 40, 23, 8, 130, 35, 1],
          },
        ],
      };
    },
  };

  return (
    <div className="container-fluid">
      <div className="row my-2">
        <div className="col-md-6 py-1">
          <div className="card mb-3 shadow p-3 mb-5 bg-white rounded">
            <div className="card cardbg bg-custom-1">
              <CardBody>
                <div className="chart-area">
                  <Line data={chartEmail.data} />
                </div>
              </CardBody>
            </div>
            <br />
            <div className="row">
            <div className="col text-center">
                <h6 className="card-category">Email Sent</h6>
               <img  className="campicon" src={process.env.PUBLIC_URL + '/images/Campaign_logo/Email.svg'} alt=""  /> 
                &nbsp; 176

            </div>
            <div className="col text-center">
                <h6 className="card-category">Email Delivered</h6>
               <img  className="campicon" src={process.env.PUBLIC_URL + '/images/Campaign_logo/Email.svg'} alt=""  /> 
                 &nbsp;176
            </div>
            <div className="col text-center">
                <h6 className="card-category">Email Failed</h6>
               <img  className="campicon" src={process.env.PUBLIC_URL + '/images/Campaign_logo/Email.svg'} alt=""  /> 
                 &nbsp;7
            </div>
            </div>
          </div>
          <div className="card mb-3 shadow p-3 mb-5 bg-white rounded">
            <div className="card cardbg bg-custom-1">
              <CardBody>
                <div className="chart-area">
                  <Line data={chartSMS.data} />
                </div>
              </CardBody>
            </div>
          <br />
            <div className="row">
            <div className="col text-center">
                <h6 className="card-category">Message Sent</h6>
               <img className="campicon" src={process.env.PUBLIC_URL + '/images/Campaign_logo/Message.svg'} alt=""  /> 
                &nbsp; 76

            </div>
            <div className="col text-center">
                <h6 className="card-category">Message Delivered</h6>
               <img className="campicon" src={process.env.PUBLIC_URL + '/images/Campaign_logo/Message.svg'} alt=""  /> 
                 &nbsp;76
            </div>
            <div className="col text-center">
                <h6 className="card-category">Message Failed</h6>
               <img className="campicon" src={process.env.PUBLIC_URL + '/images/Campaign_logo/Message.svg'} alt=""  /> 
                 &nbsp;0
            </div>
            </div>
          </div>
        </div>
        <div className="col-6">
        <div className="row my-2">
        <div className="col py-1">
          <div className="card mb-3 shadow p-3 mb-5 bg-white rounded">
          <div className="card cardbg bg-custom-1">
          <li className="nav-item text-center">
        <img className="campicon h4" src={process.env.PUBLIC_URL + '/images/Campaign_logo/notification.svg'} alt=""  />
       <span className="h4">&nbsp;Create New Campaign</span>
       <br />
       <div className="row">
            <div className="col text-center">
            <a href={`/dashboard/campaign/sendemail`} >
                <h6 className="card-category"> 
                <img  className="campicon" src="https://img.icons8.com/bubbles/2x/email.png" alt=""  /> 
                </h6>
                <h6>Email</h6>
                </a>

            </div>
            <div className="col text-center">
            <a href={`/dashboard/campaign/sendsms`}>
                <h6 className="card-category"> <img  className="campicon" src="https://img.icons8.com/bubbles/2x/email.png" alt=""  /> </h6>
             <h6>Message</h6>
            </a>
            </div>
            <div className="col text-center">
            <a href={`/dashboard/campaign/emailsms`}>
                <h6 className="card-category"> <img  className="campicon" src="https://img.icons8.com/bubbles/2x/email.png" alt=""  /> </h6>
             <h6>Email and Message</h6>
            </a>
            </div>
            </div>
    </li>
    </div>
           </div>
          </div>
          </div>

          <div className="row my-2">
        <div className="col py-1">
          <div className="card mb-3 shadow p-3 mb-5 bg-white rounded">
          <div className="card cardbg bg-custom-1">
          <Email />
           </div>
           </div>
          </div>
          </div>
          <div className="row my-2">
        <div className="col py-1">
          <div className="card mb-3 shadow p-3 mb-5 bg-white rounded">
          <div className="card cardbg bg-custom-1">
          <SMS />
           </div>
           </div>
          </div>
          </div>
          

      </div>
      </div>

      {/* <div className="row">
  <div className="col-md-4 col-xl-6 col-sm-4 col-12">
  <div className="card shadow p-3 mb-5 rounded" >
  <div className="row gy-5 ">
  <div className="col">
  <ul className="nav nav-tabs" id="myTab" role="tablist">
    <li className="nav-item">
      <a className="nav-link active" id="sms-tab" data-toggle="tab" href="#sms" role="tab" aria-controls="sms" aria-selected="true">
      <img className="campicon" src={process.env.PUBLIC_URL + '/images/Campaign_logo/Message.svg'} alt=""  />
      &nbsp;&nbsp;Message
        </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" id="email-tab" data-toggle="tab" href="#email" role="tab" aria-controls="email" aria-selected="false">
      <img  className="campicon" src={process.env.PUBLIC_URL + '/images/Campaign_logo/Email.svg'} alt=""  />
      &nbsp;&nbsp;Email</a>
    </li>
    <li className="nav-item">
      <a className="nav-link" id="notification-tab" data-toggle="tab" href="#notification" role="tab" aria-controls="notification" aria-selected="false">
      <img className="campicon" src={process.env.PUBLIC_URL + '/images/Campaign_logo/notification.svg'} alt=""  />
      &nbsp;&nbsp;Notification</a>
    </li>
  </ul>
  </div>
  </div>
  </div>
  </div>
  <div className="col">
  </div>
  </div>

      <div className="row">
      <div className="col">
      <div className="card shadow p-3 mb-5 rounded" >
      <div className="row gy-5 ">
      <div className="col">
      <div className="tab-content" id="myTabContent">
      <div className="tab-pane fade show active" id="sms" role="tabpanel" aria-labelledby="sms-tab">
      <SMS />
      </div>
      <div className="tab-pane fade" id="email" role="tabpanel" aria-labelledby="email-tab">
     <Email />
      </div>
      <div className="tab-pane fade" id="notification" role="tabpanel" aria-labelledby="notification-tab">
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div> */}
    </div>
  );
}

export default Campaign;
