import { ASSET_BASE_URL,FETCH_LOCATION_OWNER, FETCH_OPERATOR_LOCATION, LOCATION_CAT_LIST, LOCATION_STATUS_LIST, LOCATION_SUB_CAT_LIST, SAVE_LOCATION_URL, FETCH_LOCATIONS,UPDATE_LOCATION_URL ,DEACTIVATE_LOCATION} from "../../components/config/configs";

export const getLocationAction=()=>{
  const {token,id,role,networkoperator,organization}= JSON.parse(localStorage.getItem('user'))
 // console.log(token)
  // console.log("url",ASSET_BASE_URL+FETCH_OPERATOR_LOCATION+"?userid="+id+"&role="+role+"&np="+networkoperator+"&org="+organization)
  var bearer = 'basic ' + token;
    return dispatch => {
      return fetch(ASSET_BASE_URL+FETCH_OPERATOR_LOCATION+"?userid="+id+"&role="+role+"&np="+networkoperator+"&org="+organization, {
       method: "GET",
       headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
     })
       .then(resp => resp.json())
       .then(data => {
       // console.log("FETCH_LOCATION1--",data)
           dispatch({type: 'FETCH_LOCATION', payload: data})
       })
   }
 }

export const slocationAction=()=>{
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
    return dispatch => {
     return fetch(ASSET_BASE_URL+LOCATION_STATUS_LIST, {
       method: "GET",
       headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
     })
       .then(resp => resp.json())
       .then(data => {
           dispatch({type: 'FETCH_LOCATION_STATUS_LIST', payload: data})
       })
   }
 }

 export const locationCategoryAction=()=>{
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
    return dispatch => {
     return fetch(ASSET_BASE_URL+LOCATION_CAT_LIST, {
       method: "GET",
       headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
     })
       .then(resp => resp.json())
       .then(data => {
        //  console.log("FETCH_LOCATION_CATEGORY_LIST",data);
           dispatch({type: 'FETCH_LOCATION_CATEGORY_LIST', payload: data})
       })
   }
 }
 export const locationSubCategoryAction=(loctype)=>{
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
    return dispatch => {
     return fetch(ASSET_BASE_URL+LOCATION_SUB_CAT_LIST+"?category="+loctype, {
       method: "GET",
       headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
     })
       .then(resp => resp.json())
       .then(data => {
           dispatch({type: 'FETCH_LOCATION_SUB_CATEGORY_LIST', payload: data})
       })
   }
 }

 export const saveLocationAction = (location,amenity) =>{
  const {token,id,firstname}= JSON.parse(localStorage.getItem('user'))
  // console.log("id",id)
  var bearer = 'basic ' + token;
  const {networkoperator,enterprise,locationname,locationnumber,locationstatus,locationtype,locationsubtype,address,addressline1,landmark,country,lstate,city,zipcode,latitude,longitude,description,locationowner,source}=location;
  // console.log(getLocationOwnerAction);
  return dispatch => {
        dispatch({type:'LOCATION_LOADING',payload:''})
        return fetch(ASSET_BASE_URL+SAVE_LOCATION_URL, {
          method: "POST",
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            operator:networkoperator,
            enterprise:enterprise,
            name:locationname,
            number:locationnumber,
            status:locationstatus,
            locationtype:locationtype,
            locationsubtype:locationsubtype,
            amenity:amenity,
            address:address,
            address1:addressline1,
            landmark:landmark,
            country:country,
            state:lstate,
            city:city,
            zipcode:zipcode,
            latitude:parseFloat(latitude),
            longitude:parseFloat(longitude),
            description:description,
            marker:parseInt(locationowner),
            icon:"charger.png",
            createdby:"6018fe0637274971a4775b44",
            source:source,
            enterdby: firstname,
         })
        })
          .then(resp => resp.json())
          .then(data => {
            //  console.log("ADD_LOCATION",data)
              dispatch({type: 'ADD_LOCATION', payload: data})
          })
      }
    }



    
    export const getOperatorLocationAction=()=>{
      const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
      var bearer = 'basic ' + token;
        return dispatch => {
         return fetch(ASSET_BASE_URL+FETCH_OPERATOR_LOCATION+"?userid="+id+"&role="+role+"&np="+networkoperator, {
           method: "GET",
           headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
         })
           .then(resp => resp.json())
           .then(data => {
               dispatch({type: 'FETCH_OPERATOR_LOCATION', payload: data})
           })
       }
     }

     export const getLocationOwnerAction=()=>{
      const {token}= JSON.parse(localStorage.getItem('user'))
      var bearer = 'basic ' + token;
        return dispatch => {
         return fetch(ASSET_BASE_URL+FETCH_LOCATION_OWNER, {
           method: "GET",
           headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
         })
           .then(resp => resp.json())
           .then(data => {
               dispatch({type: 'FETCH_LOCATION_OWNER', payload: data})
           })
       }
     }

  export const getSingleLocationAction = (id) =>{
    const {token}= JSON.parse(localStorage.getItem('user'))
    // console.log("edfew",ASSET_BASE_URL+FETCH_LOCATIONS+"?id="+id)
    var bearer = 'basic ' + token;
    return dispatch => {
      return fetch(ASSET_BASE_URL+FETCH_LOCATIONS+"?id="+id, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
        .then(resp => resp.json())
        .then(data => {
          // console.log("FETCH_SINGLE_LOCATION",data);
            dispatch({type: 'FETCH_SINGLE_LOCATION', payload: data})
        })
    }
  }

  export const updateLocationAction = (id,location) =>{
    const {token,firstname}= JSON.parse(localStorage.getItem('user'))
    // console.log("URL",ASSET_BASE_URL+UPDATE_LOCATION_URL)
    var bearer = 'basic ' + token;
  
    return dispatch => {
      return fetch(ASSET_BASE_URL+UPDATE_LOCATION_URL, {
        method: "PUT",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          "id":id,
          "operator":location.operator,
          "enterprise":location.enterprise,
          "name":location.name,
          "number":location.number,
          "status":location.status,
          "locationtype":location.locationtype,
          "locationsubtype":location.locationsubtype,
          "amenity":location.amenity,
          "address":location.address,
          "address1":location.address1,
          "landmark":location.landmark,
          "country":location.country,
          "state":location.state,
          "city":location.city,
          "zipcode":location.zipcode,
          "source":location.source,
          "latitude":parseFloat(location.latitude),
          "longitude":parseFloat(location.longitude),
          "description":location.description,
          "marker":parseInt(location.marker),
          "createdby":location.createdby,
          "enterdby":firstname,
          "icon":"charger.png",
     
       })
      })
        .then(resp => resp.json())
        .then(data => {
          // console.log("UPDATE_LOCATION",data)
            dispatch({type: 'UPDATE_LOCATION', payload: data})
        })
    }
  }



  export const deactivateLocationAction = (id) =>{
    const {token}= JSON.parse(localStorage.getItem('user'))

    // console.log("id",id.value)
    var bearer = 'basic ' + token;
    return dispatch => {
      return fetch(ASSET_BASE_URL+DEACTIVATE_LOCATION+"?id="+id.value, {
        method: "PUT",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({

          "id":id.value
     
       })
      })
        .then(resp => resp.json())
        .then(data => {
         // console.log("DEACTIVATE_LOCATION",data)
            dispatch({type: 'DEACTIVATE_LOCATION', payload: data})
        })
    }
  }
