import HubInsightsCards from "../../../utils/hubInsightsCard";
import React, { useRef, useState, useEffect } from 'react';
import { HUB_INSIGHTS_BASE_URL } from '../../../config/configs'
const user = JSON.parse(localStorage.getItem('user'));
const URL = `${HUB_INSIGHTS_BASE_URL}/hub/secure/ws/${user?.id}?authKey=${user?.token}`

const HubInsights = () => {
    const clientRef = useRef(null);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [messages, setMessages] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    function addMessage(message) {
        setMessages(message);
    }

    useEffect(() => {

        if (waitingToReconnect) {
            return;
        }

        // Only set up the websocket once
        if (!clientRef.current) {
            const client = new WebSocket(URL);
            clientRef.current = client;

            window.client = client;

            client.onerror = (e) => console.log(e);

            client.onopen = () => {
                // setIsOpen(true);
            };

            client.onclose = () => {

                if (clientRef.current) {
                    // Connection failed
                } else {
                    // Cleanup initiated from app side, can return here, to not attempt a reconnect
                    return;
                }

                if (waitingToReconnect) {
                    return;
                };

                // Parse event code and log
                // setIsOpen(false);

                // Setting this will trigger a re-run of the effect,
                // cleaning up the current websocket, but not setting
                // up a new one right away
                setWaitingToReconnect(true);

                // This will trigger another re-run, and because it is false,
                // the socket will be set up again
                setTimeout(() => setWaitingToReconnect(null), 15000);
            };

            client.onmessage = message => {
                addMessage(JSON.parse(message.data));
            };
            return () => {
                // Dereference, so it will set up next time
                clientRef.current = null;

                client.close();
            }
        }

    }, [waitingToReconnect]);

    function goFullScreen() {
        const elem = document.getElementById("root");
        setIsFullScreen(isFullScreen ? false : true)

        if (!isFullScreen) {
            if (elem?.requestFullscreen) {
                elem.requestFullscreen();

            } else if (elem?.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();

            } else if (elem?.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document?.exitFullscreen) {
                document?.exitFullscreen();

            } else if (document?.webkitExitFullscreen) { /* Safari */
                document?.webkitExitFullscreen();

            } else if (document?.msExitFullscreen) { /* IE11 */
                document?.msExitFullscreen();

            }
        }
    }

    return (
        <div id="hub_insights_cards" onDoubleClick={(() => { goFullScreen() })} className="container-fluid">
            {messages.length > 0 ? (<HubInsightsCards hubInsightsData={messages} />) :
                (<h1 > Loading........ </h1>)
            }
        </div>
    )
}

export default HubInsights;