

import React, { Component } from "react"
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { saveConnectorTypeAction } from "../../../../store/actions/ctAction";
import { getSingleConnectorActions, updateConnectorAction } from "../../../../store/actions/ctAction";
class EditConnector extends Component {

    state = {
        user: {
            code: '',
            name: '',
            protocol: '',
            connectortype: '',
            powertype: '',
            power: '',
            createdby: '',
            enterdby: '',
        }
    }

    componentDidMount() {

        const { match: { params } } = this.props;
        const token = '';
        this.props.getSingleConnector(params.id, token)

    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.connectorsingledetail !== this.props.connectorsingledetail) {
            this.setState({
                user: this.props.connectorsingledetail
            })
        }
    }

    handleChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            user: { ...prevState.user, [e.target.name]: e.target.value }
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { match: { params } } = this.props;
        this.props.updateConnector(params.id, this.state.user)
        alert("Connector updated")
        this.props.history.push('/dashboard/assets')

    }

    render() {
        return (

            <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading"><b>Edit Connector Type</b></h4>

                        <form className="form mt-4" onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="row rowpadding  justify-content-between">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer">
                                        <div className="col-12">
                                            <label className="formLable" htmlFor="code">Connector Code</label>
                                            <div className="form-label-group">
                                                <input type="text" id="code" name="code" placeholder="Connector Code" className="form-control control-label" onChange={this.handleChange} value={this.state.user.code} />
                                                <label htmlFor="code">Connector Code</label>
                                            </div>
                                            <label className="formLable" htmlFor="name">Connector Name</label>
                                            <div className="form-label-group">
                                                <input type="text" id="name" name="name" className="form-control control-label" placeholder="Connector Name" onChange={this.handleChange} value={this.state.user.name} />
                                                <label htmlFor="name">Connector Name</label>
                                            </div>

                                            <label className="formLable" htmlFor="protocol">Communication protocol</label>
                                            <div className="form-label-group">
                                                <select className="form-control border-radius" id="protocol" name="protocol" onChange={this.handleChange} value={this.state.user.protocol}>
                                                    {this.state.user.protocol ? <option>{this.state.user.protocol}</option> : ''}
                                                    <option>OCPP1.6J</option>
                                                    <option>OCPP1.6S</option>
                                                    <option>OCPP2.0.1</option>

                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">

                                        <div className="col-12 ">

                                            <label className="formLable" htmlFor="coonectortype">Connector Type</label>
                                            <div className="form-label-group">
                                                <select className="form-control border-radius" id="connectortype" name="connectortype" onChange={this.handleChange} value={this.state.user.connectortype}>
                                                    {this.state.user.connectortype ? <option>{this.state.user.connectortype}</option> : ''}
                                                    <option>IEC60309</option>
                                                    <option>Type 2</option>
                                                    <option>GBT</option>
                                                    <option>CCS2</option>
                                                    <option>CHAdeMO</option>
                                                    <option>GBT2</option>
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="powertype">Power Type</label>
                                            <div className="form-label-group">
                                                <select className="form-control border-radius" id="powertype" name="powertype" onChange={this.handleChange} value={this.state.user.powertype}>
                                                    {this.state.user.powertype ? <option>{this.state.user.powertype}</option> : ''}
                                                    <option>AC</option>
                                                    <option>DC</option>
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="power">Power(KW)</label>
                                            <div className="form-label-group">
                                                <input type="text" id="power" name="power" className="form-control control-label" placeholder="Connector Name" onChange={this.handleChange} value={this.state.user.power} />
                                                <label htmlFor="power">Power(KW)</label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-xl-6 col-sm-6 col-12">
                                    <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/assets">Back</a>
                                </div>

                                <div className="col-xl-6 col-sm-6 col-12 float-right">
                                    <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">{!this.props.loading ? 'Update Connector Type' : 'Please wait...'}</button>
                                </div>
                                <hr className="my-4" />
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        )
    }
}



const mapStateToProps = (state) => {
    return {
        // loading:state.ct.isLoading,
        // saveresponse:state.ct.getSingleConnectorActions,
        connectorsingledetail: state.ct.fetchSingleConnector,
        modifiedConnector: state.ct.updateConnectorByID,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleConnector: (id, token) => dispatch(getSingleConnectorActions(id, token)),
        updateConnector: (id, user) => dispatch(updateConnectorAction(id, user)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditConnector));


