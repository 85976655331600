import { FETCH_TRANSACTION_DATA_URL, FETCH_TRANSACTION_LIST_URL, TRANSACTION_BASE_URL, REPORT_BASE_URL,RELEASE_CHARGER_URL,RELEASE_STUCK_TRANSACTION_URL ,FETCH_LIVE_CHARGING_DATA,FETCH_INITIALIZE_URL,UPDATE_INITIALIZE_URL } from "../../components/config/configs";

export const transactionListAction = () => {
  const { token, id } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'TRANSACTION_LOADING', payload: '' })
    return fetch(TRANSACTION_BASE_URL + FETCH_TRANSACTION_LIST_URL + "?userid=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'FETCH_TRANSACTION_LIST', payload: data })
      })
  }
}


export const transactionDataAction = (viewtype, planname) => {
  const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
  //console.log("token",JSON.parse(localStorage.getItem('user')) )
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'TRANSACTION_LOADING', payload: '' })
    return fetch(REPORT_BASE_URL + FETCH_TRANSACTION_DATA_URL + "?userid=" + id + "&viewtype=" + viewtype + "&role=" + role + "&np=" + networkoperator + "&planname=" + planname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
          // console.log("FETCH_TRANSACTION_DATA----", data);
        dispatch({ type: 'FETCH_TRANSACTION_DATA', payload: data })
      })
  }
}

export const releaseChargerAction = (connectorno, chargeboxid) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(TRANSACTION_BASE_URL + RELEASE_CHARGER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "connectorno": connectorno,
        "chargeboxid": chargeboxid,
      })
    }).then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'RELEASE_CHARGER', payload: data })
      })
  }
}

export const releaseStuckTransactionAction = (connectorno, chargeboxid) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(TRANSACTION_BASE_URL + RELEASE_STUCK_TRANSACTION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "connectorno": connectorno,
        "chargeboxid": chargeboxid,
      })
    }).then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'RELEASE_STUCK_TRANSACTION', payload: data })
      })
  }
}

export const liveChargingData = () => {
  const { token} = JSON.parse(localStorage.getItem('user'))
  //console.log("token",JSON.parse(localStorage.getItem('user')) )
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'TRANSACTION_LOADING', payload: '' })
    return fetch(TRANSACTION_BASE_URL + FETCH_LIVE_CHARGING_DATA, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_TRANSACTION_DATA----", data);
        dispatch({ type: 'LIVE_CHARGING_DATA', payload: data })
      })
  }
}

//Fetch initialize transaction from transaction table
export const fetchiitializetransactionAction = () => {
  const { token} = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
   
  return dispatch => {
    dispatch({ type: 'TRANSACTION_LOADING', payload: '' })
    return fetch(TRANSACTION_BASE_URL + FETCH_INITIALIZE_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'FETCH_INITIALISE_TRANSACTION', payload: data })
      })
  }
}


//relese initialise 
export const realiseinitialiseAction = (trid) => {
  const { token, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  console.log("action id",trid)
  // const { id } = trid;
  return dispatch => {
    dispatch({ type: 'TRANSACTION_LOADING', payload: '' })
    return fetch(TRANSACTION_BASE_URL + UPDATE_INITIALIZE_URL + "?id=" + trid , {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then(resp => resp.json())
      .then(data => {
        console.log("UPDATE_INITIALISE_TRANSACTION", data)
        dispatch({ type: 'UPDATE_INITIALISE_TRANSACTION', payload: data })
      })
  }
}
