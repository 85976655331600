const initState = {
    saveInventoryAsset: '',
    getAllInventoryAsset: '',
    getInventoryAssetByID: '',
    updateInventoryAsset: '',
    productCountType:'',
}

const inventoryReducer = (state = initState, action) => {
    switch (action.type) {
        case 'INVENTORY_LOADING':
            return { ...state, isLoading: true }
        case 'SAVE_INVENTORY_ASSET':
            return { ...state, saveInventoryAsset: action.payload, isLoading: false }
        case 'GETALL_INVENTORY_ASSET':
            return { ...state, getAllInventoryAsset: action.payload, isLoading: false }
        case 'GET_INVENTORY_ASSET_BYID':
            return { ...state, getInventoryAssetByID: action.payload, isLoading: false }
        case 'UPDATE_INVENTORY_ASSET':
            return { ...state, updateInventoryAsset: action.payload, isLoading: false }
            case 'ASSET_PRODUCT_COUNT':
            return { ...state, productCountType: action.payload, isLoading: false }
        default:
            return state;
    }
}
export default inventoryReducer