import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSingleAssetActions } from "../../../../store/actions/assetAction"
import "../../report/table.css"

class ViewSingleAssetsDetails extends Component {


    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getSingleAsset(params.assetid)

    }
    render() {
        // console.log("details", this.props.assetsingledetail.location);
        return (
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>View Asset</b>
                        </h4>

                        <div className="row rowpadding mt-3  justify-content-between">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">

                                        <label className="formLable" htmlFor="location">Asset Location</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="location" name="location" >
                                                <option >{this.props.assetsingledetail.location}</option>

                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="name">Asset Name</label>
                                        <div className="form-label-group">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control control-label"
                                                value={this.props.assetsingledetail.name} disabled

                                            />
                                            <label htmlFor="assetid">Enter Asset Name</label>
                                        </div>



                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="status">Asset Status</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="status" name="status" >
                                                <option>{this.props.assetsingledetail.status}</option>

                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="installed">Asset Installed</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="installed" name="installed" disabled>
                                                <option>{this.props.assetsingledetail.installed}</option>

                                            </select>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View Charger</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Charge Box ID</th>
                                                <th scope="col">Charger Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Charger Capacity</th>
                                                <th scope="col">Charger Type</th>
                                                <th scope="col">Ingress Protection</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Period (Years)</th>
                                                <th scope="col">AMC Start Date</th>
                                                <th scope="col">AMC End Date</th>
                                                <th scope="col">AMC Period</th>
                                                <th scope="col">Other Information (Optional)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.charger.map((charger) =>
                                                <tr>
                                                    <td>{charger.chargeboxid}</td>
                                                    <td><select className="border-radius" name="name" >
                                                        <option>{charger.name}</option>
                                                    </select></td>
                                                    <td>{charger.make} </td>
                                                    <td>{charger.model} </td>
                                                    <td>{charger.serialno} </td>
                                                    <td>{charger.capacity}</td>
                                                    <td>
                                                        <select className="border-radius" name="type"  >
                                                            <option>{charger.type}</option>
                                                        </select>
                                                    </td>
                                                    <td>{charger.ingress}</td>
                                                    <td>{charger.warrantystart} </td>
                                                    <td>{charger.warrantyend} </td>
                                                    <td>{charger.warrantyduration} </td>
                                                    <td>{charger.amcstart} </td>
                                                    <td>{charger.amcend} </td>
                                                    <td>{charger.amcperiod} </td>
                                                    <td>{charger.description} </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View Modem</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">SSID Name</th>
                                                <th scope="col">WIFI Password/Pin</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Period (Years)</th>
                                                <th scope="col">Other Information (Optional)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.modem.map((modem) =>
                                                <tr>
                                                    <td>{modem.make} </td>
                                                    <td> {modem.model}</td>
                                                    <td> {modem.serialno}</td>
                                                    <td> {modem.ssidname}</td>
                                                    <td>{modem.pin} </td>
                                                    <td> {modem.warrantystart}</td>
                                                    <td>{modem.warrantyend} </td>
                                                    <td> {modem.warrantyduration}</td>
                                                    <td> {modem.description}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View Network</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Network Provider</th>
                                                <th scope="col">Sim type</th>
                                                <th scope="col">IMEI Number</th>
                                                <th scope="col">Sim Activaton Date</th>
                                                <th scope="col">Recharge Expiry Date</th>
                                                <th scope="col">Other Information (Optional)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.network.map((network) =>
                                                <tr>
                                                    <td>{network.networkprovider} </td>
                                                    <td> {network.simtype}</td>
                                                    <td> {network.imeinumber}</td>
                                                    <td> {network.warrantystart}</td>
                                                    <td>{network.warrantyend} </td>
                                                    <td> {network.description}</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View Power Cabinet</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.Powercabinet.map((Powercabinet) =>
                                                <tr>
                                                    <td>{Powercabinet.name} </td>
                                                    <td> {Powercabinet.make}</td>
                                                    <td> {Powercabinet.model}</td>
                                                    <td> {Powercabinet.serialno}</td>
                                                    <td>{Powercabinet.warrantystart} </td>
                                                    <td> {Powercabinet.warrantyend}</td>
                                                    <td>{Powercabinet.warrantyduration} </td>
                                                    <td> {Powercabinet.description}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View Switchgear</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.switchgear.map((switchgear) =>
                                                <tr>
                                                    <td>{switchgear.name} </td>
                                                    <td> {switchgear.make}</td>
                                                    <td> {switchgear.model}</td>
                                                    <td> {switchgear.serialno}</td>
                                                    <td>{switchgear.warrantystart} </td>
                                                    <td> {switchgear.warrantyend}</td>
                                                    <td>{switchgear.warrantyduration} </td>
                                                    <td> {switchgear.description}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View RFID Reader</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.rfidreader.map((rfidreader) =>
                                                <tr>
                                                    <td>{rfidreader.name} </td>
                                                    <td> {rfidreader.make}</td>
                                                    <td> {rfidreader.model}</td>
                                                    <td> {rfidreader.serialno}</td>
                                                    <td>{rfidreader.warrantystart} </td>
                                                    <td> {rfidreader.warrantyend}</td>
                                                    <td>{rfidreader.warrantyduration} </td>
                                                    <td> {rfidreader.description}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View Cable</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.cable.map((cable) =>
                                                <tr>
                                                    <td>{cable.name} </td>
                                                    <td> {cable.make}</td>
                                                    <td> {cable.model}</td>
                                                    <td> {cable.serialno}</td>
                                                    <td>{cable.warrantystart} </td>
                                                    <td> {cable.warrantyend}</td>
                                                    <td>{cable.warrantyduration} </td>
                                                    <td> {cable.description}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View POS</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.pos.map((pos) =>
                                                <tr>
                                                    <td>{pos.name} </td>
                                                    <td> {pos.make}</td>
                                                    <td> {pos.model}</td>
                                                    <td> {pos.serialno}</td>
                                                    <td>{pos.warrantystart} </td>
                                                    <td> {pos.warrantyend}</td>
                                                    <td>{pos.warrantyduration} </td>
                                                    <td> {pos.description}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View Battery</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.battery.map((battery) =>
                                                <tr>
                                                    <td>{battery.name} </td>
                                                    <td> {battery.make}</td>
                                                    <td> {battery.model}</td>
                                                    <td> {battery.serialno}</td>
                                                    <td>{battery.warrantystart} </td>
                                                    <td> {battery.warrantyend}</td>
                                                    <td>{battery.warrantyduration} </td>
                                                    <td> {battery.description}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">View Other assets</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.assetsingledetail && this.props.assetsingledetail.otherasset.map((otherasset) =>
                                                <tr>
                                                    <td>{otherasset.name} </td>
                                                    <td> {otherasset.make}</td>
                                                    <td> {otherasset.model}</td>
                                                    <td> {otherasset.serialno}</td>
                                                    <td>{otherasset.warrantystart} </td>
                                                    <td> {otherasset.warrantyend}</td>
                                                    <td>{otherasset.warrantyduration} </td>
                                                    <td> {otherasset.description}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 mb-3 ">
                            <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/assets">Back</a>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = function (state) {
    return {
        assetsingledetail: state.asset.fetchSingleAsset,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleAsset: (assetid) => dispatch(getSingleAssetActions(assetid))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleAssetsDetails));


