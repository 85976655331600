import { STATION_BASE_URL, SAVE_STATION_URL, FETCH_STATION_URL, FETCH_TOTAL_CHARGER_URL, TRIGGER_CHARGER_URL, GET_STATION_BY_NAME_URL, GET_CONFIGURATION_URL, CHANGE_CONFIGURATION_URL, RESET_CHARGER_URL, UNLOACK_CONNECTOR_URL, CLEAR_CACHE_URL, SAVE_STATION_PRICE_GROUP_URL, FETCH_DASH_CHARGERS_URL, FETCH_CHARGERS_BY_STATUS_URL, UPDATE_FIRMWARE_URL,UPDATE_QR_NUMBER_URL,GET_CHARGER_DOWNTIME_URL,CHANGE_AVAILABILITY_URL,UPDATE_STATION_URL,RELEASE_STUCK_BUSY_URL,CHECK_SERVER_STATUS_URL,UP_OCPP_SERVER_URL,SAVE_BOOKING_MASTER ,FETCH_MASTER_Booking, GET_CHARGER_LIST_URL, GET_LOGS_BY_CHARGEBOXID_URL,DEACTIVE_CHARGER_URL,GET_CHARGER_UPTIME_REPORT_URL} from "../../components/config/configs";
import Moment from "moment";
export const commissionStationAction = (station, chargers, connectors) => {
  const { token, id,firstname } = JSON.parse(localStorage.getItem('user'))


  var bearer = 'basic ' + token;
  const { stationid, stationname, networkoperator, enterprise, location, visibility, commisioneddate, status, install, projectname ,fleet} = station;
  // console.log(station);
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + SAVE_STATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        stationid: stationid,
        name: stationname,
        operator: networkoperator,
        enterprise: enterprise,
        location: location,
        visibility: visibility,
        projectname: projectname,
        commisiondate: commisioneddate,
        status: status,
        chargers: chargers,
        connectors: connectors,
        createdby: "6018fe0637274971a4775b44",
        install: install,
        fleet:fleet,
        enterdby: firstname,
      })
    }).then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'SAVE_STATION', payload: data })
      })
  }
}

export const getStationByCreatorAction = (viewtype) => {
  const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
  //console.log("sfeF",JSON.parse(localStorage.getItem('user')))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + FETCH_STATION_URL + "?createdby=" + id + "&role=" + role + "&np=" + networkoperator + "&viewtype=" + viewtype, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'FETCH_STATION', payload: data })
      })
  }
}

export const getStationByCreatorActionTop10 = (viewtype) => {
  const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + FETCH_STATION_URL + "?createdby=" + id + "&role=" + role + "&np=" + networkoperator + "&viewtype=" + viewtype, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'FETCH_STATION_TOP10', payload: data })
      })
  }
}

export const getChargerByCreatorAction = () => {
  const { token, id, role, networkoperator,planname} = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
    // console.log("wefe",STATION_BASE_URL + FETCH_TOTAL_CHARGER_URL + "?createdby=" + id + "&role=" + role + "&np=" + networkoperator+"&planname="+planname)
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + FETCH_TOTAL_CHARGER_URL + "?createdby=" + id + "&role=" + role + "&np=" + networkoperator+"&planname="+planname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("Totalcharger status data", data)
        dispatch({ type: 'FETCH_TOTAL_CHARGER', payload: data })
      })
  }
}

export const editStationAction = (stationid, location) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + GET_STATION_BY_NAME_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        location: location,
        stationid: stationid,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("QEF",data)
        dispatch({ type: 'EDIT_STATION', payload: data })
      })
  }
}



export const updateStationAction = (station, chargers, connectors) => {
  const { token, id,firstname } = JSON.parse(localStorage.getItem('user'))
  // console.log("careated by ", id)
  // console.log("station data+++++")
  // console.log(station.id)
  var bearer = 'basic ' + token;
  const { stationid, name, operator, enterprise, location, visibility, commisiondate, status, install, projectname ,fleet} = station;
  // console.log(station);
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + UPDATE_STATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id":station.id,
        "stationid": stationid,
        "name": name,
        "operator": operator,
        "enterprise": enterprise,
        "location": location,
        "visibility": visibility,
        "projectname": projectname,
        "commisiondate": commisiondate,
        "status": status,
        "chargers": chargers,
        "connectors": connectors,
        "createdby": id,
        "install": install,
        "fleet":fleet,
        "enterdby": firstname,

      })
    }).then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'UPDATE_STATION', payload: data })
      })
  }
}

export const getConfigurationAction = (chargerBoxId) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + GET_CONFIGURATION_URL + "?chargerBoxId=" + chargerBoxId, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //console.log("Print data", data)
        dispatch({ type: 'GET_CONFIGURATION', payload: data })
      })
  }
}

export const clearCacheAction = (chargerBoxId) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CLEAR_CACHE_URL + "?chargerBoxId=" + chargerBoxId, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("Print cache data", data)
        dispatch({ type: 'CLEAR_CACHE', payload: data })
      })
  }
}



export const unlockConnectorAction = (connectorno,chargeboxid) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + UNLOACK_CONNECTOR_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": chargeboxid,
        "connectorno": connectorno,

      })
    }).then(resp => resp.json())
      .then(data => {
        //  console.log("Print cache data", data)
        dispatch({ type: 'UNLOACK_CONNECTOR', payload: data })
      })
  }
}

export const triggerConnectorAction = (chargeboxid,connectorno, RequestedMessage) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("Triggerstationaction---", chargeboxid,connectorno, RequestedMessage)
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + TRIGGER_CHARGER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": chargeboxid,
        "connectorno": connectorno,
        "RequestedMessage": RequestedMessage,

      })
    }).then(resp => resp.json())
      .then(data => {
        //  console.log("Print trigger command", data)
        dispatch({ type: 'TRIGGER_CONNECTOR', payload: data })
      })
  }
}

export const changeConfigurationAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CHANGE_CONFIGURATION', payload: data })
      })
  }
}

export const resetChargerAction = (chargeboxid, Resettype) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log(chargeboxid, Resettype)
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + RESET_CHARGER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": chargeboxid,
        "resettype": Resettype,

      })
    }).then(resp => resp.json())
      .then(data => {
        //  console.log("Print reset data", data)
        dispatch({ type: 'RESET_CHARGER', payload: data })
      })
  }
}




export const updatePriceGroupAction = (chargebox, price_plan_name) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + SAVE_STATION_PRICE_GROUP_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": chargebox,
        "planname": price_plan_name,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'SAVE_STATION_PRICE', payload: data })
      })
  }
}


export const getChargerByOwnerAction = () => {
  const { token, id, role, networkoperator,planname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log(token)
  // console.log("rwfwf",STATION_BASE_URL + FETCH_DASH_CHARGERS_URL + "?createdby=" + id + "&role=" + role + "&np=" + networkoperator+"&planname="+planname)
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + FETCH_DASH_CHARGERS_URL + "?createdby=" + id + "&role=" + role + "&np=" + networkoperator+"&planname="+planname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("FETCH_DASH_CHARGERS", data)
        dispatch({ type: 'FETCH_DASH_CHARGERS', payload: data })
      })
  }
}

export const getChargerByStatus = (status) => {
  const { token, id, role, networkoperator,planname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + FETCH_CHARGERS_BY_STATUS_URL + "?status=" + status + "&createdby=" + id + "&role=" + role + "&np=" + networkoperator+"&planname="+planname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
          //  console.log("FETCH_CHARGERS_BY_STATUS", data)
        dispatch({ type: 'FETCH_CHARGERS_BY_STATUS', payload: data })
      })
  }
}


export const updateFirmwareAction = (firmware) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { chargeboxid, location, retries, retrieveDate, retryInterval } = firmware
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + UPDATE_FIRMWARE_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": chargeboxid,
        "location": location,
        "retries": parseInt(retries),
        "retrieveDate": new Date(retrieveDate),
        "retryInterval": parseInt(retryInterval),
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UPDATE_FIRMWARE",data)
        dispatch({ type: 'UPDATE_FIRMWARE', payload: data })
      })
  }
}



export const updateQRNumber = (chargeboxid,qrnumber) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("QR",STATION_BASE_URL + UPDATE_QR_NUMBER_URL+"?chargeboxid="+chargeboxid+"&qrnumber="+qrnumber)
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + UPDATE_QR_NUMBER_URL+"?chargeboxid="+chargeboxid+"&qrnumber="+qrnumber, {
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UPDATE_QRNUMBER", data)
        dispatch({ type: 'UPDATE_QRNUMBER', payload: data })
      })
  }
}

export const getChargerDowntime = (chargeboxid,start,end) => {
  const { token, id } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("Downtimeurl",STATION_BASE_URL + GET_CHARGER_DOWNTIME_URL + "?chargeboxid=" + chargeboxid + "&start=" + start + "&end=" + end,)
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + GET_CHARGER_DOWNTIME_URL + "?chargeboxid=" + chargeboxid + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
      //  console.log("GET_CHARGER_DOWNTIME", data);
        dispatch({ type: 'GET_CHARGER_DOWNTIME', payload: data })
      })
  }
}


export const triggerChangeAvailability = (chargeboxid,connectorno, availabilitytype) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("Triggerchangeaction---", chargeboxid,connectorno, availabilitytype)
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_AVAILABILITY_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": chargeboxid,
        "connectorno": connectorno,
        "availabilitytype": availabilitytype,

      })
    }).then(resp => resp.json())
      .then(data => {
          // console.log("CHANGE_AVAILABILITY", data)
        dispatch({ type: 'CHANGE_AVAILABILITY', payload: data })
      })
  }
}


export const releaseStuckBusyAction = (connectorno, chargeboxid) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + RELEASE_STUCK_BUSY_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "connectorno": connectorno,
        "chargeboxid": chargeboxid,
      })
    }).then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'UPDATE_BUSY_TO_AVAILABLE', payload: data })
      })
  }
}





//check server status

export const checkserverstatusAction = () => {
  const { token} = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
   
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHECK_SERVER_STATUS_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        console.log("CHECK_SERVER_UP_DOWN", data)
        dispatch({ type: 'CHECK_SERVER_UP_DOWN', payload: data })
      })
  }
}

//up ocpp server

export const upocppserverAction = () => {
  const { token, id} = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + UP_OCPP_SERVER_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        console.log("UP_OCPP_SERVER", data)
        dispatch({ type: 'UP_OCPP_SERVER', payload: data })
      })
  }
}

export const saveMasterBooking = (booking, regular_hours) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { chargeboxid } = booking;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + SAVE_BOOKING_MASTER, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        chargeboxid: chargeboxid,
        regular_hours: regular_hours,

      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("SAVE_MASTER_BOOKING",data)
        dispatch({ type: 'SAVE_MASTER_BOOKING', payload: data })
      })
  }
}


export const getMasterBookingList = (chargeboxid) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  console.log(STATION_BASE_URL + FETCH_MASTER_Booking + "?chargeboxid=" + chargeboxid)
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + FETCH_MASTER_Booking + "?chargeboxid=" + chargeboxid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        console.log("Print data", data)
        dispatch({ type: 'FETCH_MASTER_BOOKING', payload: data })
      })
  }
}

export const getChargerList = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + GET_CHARGER_LIST_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'GET_CHARGER_LIST', payload: data })
      })
  }
}

export const getLogsByChargeboxId = (chargeboxid, commandName, startDate, endDate) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  console.log(startDate, endDate)
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + GET_LOGS_BY_CHARGEBOXID_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        chargeboxid: chargeboxid,
        commandName: commandName,
        startDate: startDate,
        endDate: endDate,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'GET_LOGS_BY_CHARGEBOXID', payload: data })
      })
  }
}




export const deactivechargervisibilityAction = (stationid,chargeboxid,deactive) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + DEACTIVE_CHARGER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": chargeboxid,
        "stationid": stationid,
        "deactive": deactive
   

      })
    }).then(resp => resp.json())
      .then(data => {
         console.log("DECATIVE_CHARGER", data)
        dispatch({ type: 'DECATIVE_CHARGER', payload: data })
      })
  }
}


export const getChargerUpTimeReport = (locationId) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'CHARGER_UPTIME_REPORT_DATA', payload: '', isChargerUpTimeReportLoading: true })
    return fetch(STATION_BASE_URL + GET_CHARGER_UPTIME_REPORT_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        locationId: locationId,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'CHARGER_UPTIME_REPORT_DATA', payload: data, isChargerUpTimeReportLoading:false })
      }).catch(error => {
        dispatch({ type: 'CHARGER_UPTIME_REPORT_DATA', payload: '', isChargerUpTimeReportLoading:false })
        // console.log(error.message);
      });
  }
}

///change configration chargebox id

export const changeConfigurationchargeboxidAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_CHARGEBOXID', payload: data })
      })
  }
}


///change configration endpoint

export const changeConfigurationendpointAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_URL', payload: data })
      })
  }
}


///change configration network

export const changeConfigurationetworkAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_NETWORK', payload: data })
      })
  }
}


///change configration wifi ssid

export const changeConfigurationwifissidAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_WIFISSID', payload: data })
      })
  }
}


///change configration wifi password

export const changeConfigurationwifipassAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_WIFIPASS', payload: data })
      })
  }
}

///change configration operatormod

export const changeConfigurationoperatormodeAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_OPERATORMODE', payload: data })
      })
  }
}


///change configration chargepoint model

export const changeConfigurationochargemodelAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_CHARGEPOINTMODEL', payload: data })
      })
  }
}



///change configration connection time

export const changeConfigurationconnectiotimeAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_CONNECTIONTIOMEOUT', payload: data })
      })
  }
}


///change configration load simulation

export const changeConfigurationloadsimulationAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_LOADSIMULATION', payload: data })
      })
  }
}

///change configration load simulation

export const changeConfigurationrfid1Action = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_RFID1', payload: data })
      })
  }
}


///change configration load simulation

export const changeConfigurationrfid2Action = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_RFID2', payload: data })
      })
  }
}



///change configration load simulation

export const changeConfigurationsaveconfAction = (ChargerBoxID, Key, Value) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'STATION_LOADING', payload: '' })
    return fetch(STATION_BASE_URL + CHANGE_CONFIGURATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "chargeboxid": ChargerBoxID,
        "key": Key,
        "Value": Value,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Print unlock data", data)
        dispatch({ type: 'CONFIGURATION_SAVECONFIGURATION', payload: data })
      })
  }
}