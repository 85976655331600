import React, { Component } from "react";
import validator from "validator";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { saveCustomerAction } from "../../../store/actions/customerAction";

class AddCustomer extends Component {
  customerForm = {
    firstname: { value: '', isValid: true, message: '' },
    mobile: { value: '', isValid: true, message: '' },
    username: { value: '', isValid: true, message: '' },
    password: { value: '', isValid: true, message: '' },
  };
  state = {
    ...this.customerForm,
  };

  handleChange = (e) => {
    const state = {
      ...this.state,
      [e.target.name]: {
        ...this.state[e.target.name],
        value: e.target.value,
      },
    };
    this.setState(state);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    alert('New Customer Added');
    this.props.history.push('/dashboard/customers');
    if (this.customerFormValidation()) {
      this.props.saveCustomer({ ...this.state })
    }
  };

  customerFormValidation = () => {
    const firstname = { ...this.state.firstname };
    const mobile = { ...this.state.mobile };
    const username = { ...this.state.username };
    const password = { ...this.state.password };
    let isGood = true;
    if (validator.isEmpty(firstname.value)) {
      firstname.isValid = false;
      firstname.message = "First Name is required";
      isGood = false;
    }
    if (validator.isEmpty(mobile.value)) {
      mobile.isValid = false;
      mobile.message = "mobile is required";
      isGood = false;
    }
    if (!validator.isEmail(username.value)) {
      username.isValid = false;
      username.message = "Not a valid email address";
      isGood = false;
    }
    if (validator.isEmpty(password.value)) {
      password.isValid = false;
      password.message = "Password is required";
      isGood = false;
    }

    if (!isGood) {
      this.setState({
        firstname,
        mobile,
        username,
        password,
      });
    }
    return isGood;
  };
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  render() {
    if (this.props.customerresponse && this.props.customerresponse.error === '') {
      return <Redirect to='/dashboard/customers' />;
    }
    const { firstname, mobile, username, password } = this.state;


    return (
      <>
        <div className="row-fluid py-2">
          <div className="col">
            <div className="sideCardDesigns card-signin">
              <div className="card-body">
                <h4 className="text-center formHeading">
                  <b>Add New Customer</b>
                </h4>

                <form
                  className="form mt-5"
                  onSubmit={this.handleSubmit}
                >


                  <div className="row rowpadding  justify-content-between">
                    <div className="col-md-6 col-sm-12 ">
                      <div className="col-12  formContainer">
                        <div className="col-12">
                          <label className="formLable" htmlFor="firstname">Full Name</label>
                          <div className="form-label-group required">
                            <input
                              type="text"
                              id="firstname"
                              name="firstname"
                              className="form-control control-label"
                              placeholder="Full Name "
                              onChange={this.handleChange}
                              required
                            />
                            <label htmlFor="firstname">Full Name</label>
                            <span className="help-block text-danger">
                              {firstname.message}
                            </span>
                          </div>
                          <label className="formLable" htmlFor="mobile">Mobile Number</label>
                          <div className="form-label-group required">
                            <input
                              type="number"
                              id="mobile"
                              className="form-control"
                              name="mobile"
                              placeholder="mobile Number"
                              maxLength="10"
                              onInput={this.maxLengthCheck}
                              onChange={this.handleChange}
                              required
                            />
                            <label htmlFor="mobile">Mobile Number</label>
                            <span className="help-block text-danger">
                              {mobile.message}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <div className="col-12  formContainer ">

                        <div className="col-12 ">

                          <label className="formLable" htmlFor="username">Email address</label>
                          <div className="form-label-group required">
                            <input
                              type="email"
                              id="username"
                              name="username"
                              className="form-control"
                              placeholder="Email address"
                              onChange={this.handleChange}
                              required
                            />
                            <label htmlFor="username">Email address</label>
                            <span className="help-block text-danger">
                              {username.message}
                            </span>
                          </div>

                          <label className="formLable" htmlFor="password">Password</label>
                          <div className="form-label-group required">
                            <input
                              type="password"
                              id="password"
                              name="password"
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                              className="form-control"
                              placeholder="Password"
                              onChange={this.handleChange}
                              required
                            />
                            <label htmlFor="password">Password</label>
                            <span className="help-block text-danger">
                              {password.message}
                            </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-3 col-lg-3 ">
                      <a className="btn columnsBtn btn-sm btn-wide text-uppercase float-left" href="/dashboard/customers">Back</a>
                    </div>
                    <div className="col-md-9 col-lg-9 ">
                      <button className="btn columnsBtn btn-sm btn-wide float-right" >
                        {!this.props.loading ? 'CREATE NEW CUSTOMER' : 'Please wait...'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    customerresponse: state.users.saveCustomer,
    loading: state.users.isLoading,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomer: (signup) => dispatch(saveCustomerAction(signup)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddCustomer))
