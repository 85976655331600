import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { ReportListAction } from "../../../store/actions/chargesessionAction";
import Table from "./reportTable";
import "./table.css";
import PureBreadcrumbs from "../Breadcrums"
import Loader from 'react-loader-spinner';

const ReportCard = (props) => {
  
  useEffect(()=>{
    props.fetReport();
  },[])

const data = props.list && props.list.report
  const columns = useMemo(
    () => [
      {
        header: 'Charger BoxID',
        accessor: 'station'
      },
      {
        header: 'Charger Name',
        accessor: 'last_name'
      },
      {
        header: 'Location Name',
        accessor: 'location'
      },
      {
        header: 'Address',
        accessor: 'date'
      },
      {
        header: 'OCPP/ HTTP',
        accessor: 'age'
      },
      {
        header: 'Operator',
        accessor: 'networkoperator'
      },

      {
        header: 'Category',
        accessor: 'phorgrwwne'
      },

      {
        header: 'Sub-Category',
        accessor: 'phovfxfbne'
      }
      ,

      {
        header: 'Ownership of the Charger',
        accessor: 'phvsvone'
      }
      ,

      {
        header: 'Charger OEM',
        accessor: 'pfgfbhone'
      }
      ,

      {
        header: 'Connector Type',
        accessor: 'connectortype'
      }
      ,

      {
        header: 'Power Output Type',
        accessor: 'phowene'
      }
      ,

      {
        header: 'Power Output (kW)',
        accessor: 'phonfde'
      }
      ,

      {
        header: 'Charger Commissioning Date',
        accessor: 'phonedf'
      }
      ,

      {
        header: 'Electricity Bill Due Date',
        accessor: 'phweone'
      }
      ,

      {
        header: 'Charger AMC Due Date',
        accessor: 'phowefne'
      }
      ,

      {
        header: 'Total Sessions (Nos)',
        accessor: 'totalchargingsession'
      },

      {
        header: 'Total Energy (kWh)',
        accessor: 'energy'
      }
      ,

      {
        header: 'Charger Utilization (%)',
        accessor: 'eneefrgy'
      }
      ,

      {
        header: 'Total Revenue',
        accessor: 'amount'
      }
      ,

      {
        header: 'Charger Electricity Cost (INR/kWh)',
        accessor: 'ewf'
      }
      ,

      {
        header: 'Average Revenue per Session (INR)',
        accessor: 'avgrevenue'
      }
      ,

      {
        header: 'Energy Consumed Per Session (kWh)',
        accessor: 'avgsession'
      }
      ,

      {
        header: 'No. of Hours Charger is under Maintenance',
        accessor: 'avgsessaedion'
      },
    ],
    []
  );

  return (
    <>

      <div className="row ">
        <div className="col col-sm-4 ">
        <PureBreadcrumbs />
        </div>
      </div>

      <div className="row rowpadding topCardDesigns m-1 mb-5 ">
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-sm colpadding singleCard m-1">
                
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        {/* <div className="media d-flex">
                          <div className="float-right pl-5 ">
                            <img className="float-right" src={process.env.PUBLIC_URL + '/images/assetLocation.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div> */}
                        <div className="media-body float-center " style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center",color: "#bc0078" }}><strong>{props.list !== null ? props.list.chargingsession : 0}</strong></h4></span>
                          <span className="stats "  > <h5 className="font-weight-bold pb-2" style={{ fontSize: "20px", textAlign: "center" }}>Charging Sessions</h5></span>
                          <div className="page-title-actions">
                          <a href={`/dashboard/report/charging`} className="btn btn-primary btn-block" ><span className="mr-1">View All</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                
                </div>

                <div className="col-sm colpadding singleCard m-1">
                  
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        {/* <div className="media d-flex">
                          <div className="float-left pl-5">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/assetIcon.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div> */}
                        <div className="media-body float-center" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center",color: "#bc0078" }}><strong>{props.list !== null ? props.list.totalkwh : 0}</strong></h4></span>
                          <span className="stats"  > <h5 className="font-weight-bold pb-2" style={{ fontSize: "20px", textAlign: "center" }}>kWh consumed</h5></span>
                                <div className="page-title-actions">
                                <a href={`/dashboard/report/kwh`} className="btn btn-primary btn-block" ><span className="mr-1">View All</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                </div>


                <div className="col-sm colpadding singleCard m-1">
                  
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        {/* <div className="media d-flex">
                          <div className="float-left pl-5 ">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/utilityIcon.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div> */}
                        <div className="media-body float-center" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center" ,color: "#bc0078" }}><strong>{props.list !== null ? props.list.totaltime : 0}</strong></h4></span>
                          <span className="stats"  > <h5 className="font-weight-bold pb-2" style={{ fontSize: "20px", textAlign: "center" }}>Time</h5></span>
                          <div className="page-title-actions">
                          <a href={`/dashboard/report/time`} className="btn btn-primary btn-block" ><span className="mr-1">View All</span></a>
                          </div>
                        </div>
                      </div>
                    </div>

                
                </div>


                <div className="col-sm colpadding singleCard m-1">
               
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        {/* <div className="media d-flex">
                          <div className="float-left pl-5">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/connectorIcon.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div> */}
                        <div className="media-body float-center" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center",color: "#bc0078" }}><strong>{props.list !== null ? props.list.revenue : 0}</strong></h4></span>
                          <span className="stats"  > <h5 className="font-weight-bold pb-2" style={{ fontSize: "20px", textAlign: "center" }}>Revenue</h5></span>
                          <div className="page-title-actions">
                          <a href={`/dashboard/report/revenue`} className="btn btn-primary btn-block" ><span className="mr-1">View All</span></a>
                          </div>
                        </div>
                      </div>
                    </div>             
                </div>

              </div>
            </div>
          </div>  
      <div className="row rowpadding">
        <div className="col-md-12 ">
        
        <div className="sideCardDesigns ">
        <div className="mb-3 h-100 singleCard">
                  <div className="card-header singleCard">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{color:"#10204B"}}>Report Details</strong>
                    </div>

                  </div>
                  
                  {!data.length ?
         (<div style={{display: "flex",  alignItems: "center",justifyContent: "center"}}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{display: "flex",  alignItems: "center",justifyContent: "center"}} /></h3></div>) 
     : 
    (<div className="App"> <Table data={data} columns={columns}></Table></div>) }
                </div>
        
        
     <br />
        </div>
        </div>
       

</div>
<br />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    list: state.report.fetchReportList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetReport: () => dispatch(ReportListAction()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportCard);
