import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "../../styles/hubInsightsCard.css";
import { useEffect, useRef, useState } from 'react'


function useHasRecentlyChanged(variable_v1, variable_v2, timeout = 4000) {
    const firstRender = useRef(true);
    const [hasRecentlyChanged_v1, setHasRecentlyChanged_v1] = useState(false);
    const [hasRecentlyChanged_v2, setHasRecentlyChanged_v2] = useState(false);

    useEffect(() => {
        if (firstRender.current) {
            return;
        }

        setHasRecentlyChanged_v1(true);

        setTimeout(() => {
            setHasRecentlyChanged_v1(false);
        }, timeout);
    }, [variable_v1, timeout]);

    useEffect(() => {
        if (firstRender.current) {
            return;
        }

        setHasRecentlyChanged_v2(true);

        setTimeout(() => {
            setHasRecentlyChanged_v2(false);
        }, timeout);
    }, [variable_v2, timeout]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        }
    });

    return { hasRecentlyChanged_v1, hasRecentlyChanged_v2 };
}



function CardItem({ idx, val }) {
    const { hasRecentlyChanged_v1, hasRecentlyChanged_v2 } = useHasRecentlyChanged(val.busyParkingSlot, val.availableChargersCount);

    return (
        <div className="col-md-12 col-lg-12 col-xl-12 pl-0 pr-0">
            <div>
                <span className="card_title"> {val.hubname} </span>
            </div>
            <div className="custom-row flex-sm-nowrap align-items-end">
                <div className='min-width-div'>
                    <div className="media d-flex justify-content-center">
                        <div className="float-right">
                            <img className="float-right" src={process.env.PUBLIC_URL + '/images/hub/HubName.svg'} alt="" style={{ height: "70px", width: "56px" }} />
                        </div>
                    </div>
                </div>

                <div className='min-width-div'>
                    <div className="media-body float-center mt-2" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "30px", textAlign: "center" }}><strong>{val.totalNumOfParkingSlot}</strong></h4></span>
                        <span className="stats"  > <h5 style={{ fontSize: "13px", textAlign: "center" }}>Available Parking</h5></span>
                    </div>
                </div>

                <div className='min-width-div'>
                    <div className="media-body float-center mt-2" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 key={idx + 'PIN'} className={hasRecentlyChanged_v1 ? 'changedValue' : undefined} style={{ fontSize: "30px", textAlign: "center", color: val.busyParkingSlot > val.totalNumOfParkingSlot ? '#fc0320' : '#10204B' }}><strong>{val.busyParkingSlot}</strong></h4></span>
                        <span className="stats"  > <h5 style={{ fontSize: "13px", textAlign: "center" }}>Checked In</h5></span>
                    </div>
                </div>
            </div>
            <div className="custom-vl d-none d-sm-none d-md-none"></div>
            <hr className="d-none d-sm-block d-md-block d-lg-block d-xl-block" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "97%", textAlign: "center", marginLeft: "5px", marginBottom: "10px", marginTop: "2px" }} />
            <div className="custom-row flex-sm-nowrap align-items-end">
                <div className='min-width-div'>
                    <div className="media d-flex justify-content-center">
                        <div className="float-right">
                            <img className="float-right" src={process.env.PUBLIC_URL + '/images/Insight_img/Station.svg'} alt="" style={{ height: "28px", width: "28px" }} />
                        </div>
                    </div>
                    <div className="media-body float-center mt-2" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "30px", textAlign: "center" }}><strong>{val.totalChargersCount}</strong></h4></span>
                        <span className="stats"  > <h5 style={{ fontSize: "13px", textAlign: "center" }}>Total Chargers</h5></span>
                    </div>
                </div>

                <div className='min-width-div'>
                    <div className="media-body float-center mt-2" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 key={idx + 'CA'} className={hasRecentlyChanged_v2 ? 'changedValue' : undefined} style={{ fontSize: "30px", textAlign: "center" }}><strong>{val.availableChargersCount}</strong></h4></span>
                        <span className="stats"  > <h5 style={{ fontSize: "13px", textAlign: "center" }}>Available</h5></span>
                    </div>
                </div>

                <div className='min-width-div-busy'>
                    <div className="media-body float-center mt-2" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "30px", textAlign: "center" }}><strong>{val.busyChargersCount}</strong></h4></span>
                        <span className="stats"  > <h5 style={{ fontSize: "13px", textAlign: "center" }}> Busy </h5></span>
                    </div>
                </div>

                <div className='min-width-div'>
                    <div className="media-body float-center mt-2" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "30px", textAlign: "center", color: val.totalChargersCount === val.inMaintenanceChargersCount ? '#fc0320' : '#10204B' }}><strong>{val.inMaintenanceChargersCount}</strong></h4></span>
                        <span className="stats"  > <h5 style={{ fontSize: "13px", textAlign: "center" }}>Maintenance</h5></span>
                    </div>
                </div>
            </div>
        </div>

    );
}

const HubInsightsCards = ({ hubInsightsData }) => {
    return (
        <div className='custom-row justify-content-start'>
            {hubInsightsData.map((val, idx) => (
                <div key={idx} className='pl-0 pr-0'>
                    <Card className='custom-card'>
                        <Card.Body className='p-2'>
                            <CardItem idx={idx} val={val} />
                        </Card.Body>
                    </Card>
                </div>
            ))}
        </div>
    )
}

export default HubInsightsCards;