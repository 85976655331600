import {REPORT_BASE_URL, FETCH_REPORT_DATA_URL, FETCH_REPORT_CHARGINGSESSION, FETCH_REPORT_kWh, FETCH_REPORT_TIME, FETCH_REPORT_REVENUE} from "../../components/config/configs";


export const ReportListAction = () => {
  const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    //console.log("data",REPORT_BASE_URL + FETCH_REPORT_DATA_URL + "?createdby=" + id + "&role=" + role + "&np=" + networkoperator)
    return async dispatch => {
        dispatch({type:'REPORT_LOADING',payload:''})
        
        const resp = await fetch(REPORT_BASE_URL + FETCH_REPORT_DATA_URL + "?createdby=" + id + "&role=" + role + "&np=" + networkoperator, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      });
      const data = await resp.json();
      // console.log("Data====", data);
      dispatch({ type: 'FETCH_REPORT_LIST', payload: data });
}
}

export const ChargingSesionAction = (start,end) => {
  const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log("aefew",REPORT_BASE_URL+  FETCH_REPORT_CHARGINGSESSION)
     return dispatch => {
          dispatch({type:'REPORT_LOADING',payload:''})
          return fetch(REPORT_BASE_URL+  FETCH_REPORT_CHARGINGSESSION, {
            method: "POST",
            headers: {
              'Authorization': bearer,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
                "userid":id,
                "startdate":start,
                "enddate":end,
                "role":role,
                "np":networkoperator
              
              })
          }).then(resp => resp.json())
            .then(data => {
              // console.log("Print data --->",data)
                dispatch({type: 'FETCH_REPORT_CHARGING_LIST', payload: data})
            })
        }
      }

          export const TotalkWhAction = (start,end) => {
            const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
              var bearer = 'basic ' + token;
              return dispatch => {
                    dispatch({type:'REPORT_LOADING',payload:''})
                    return fetch(REPORT_BASE_URL+ FETCH_REPORT_kWh, {
                      method: "POST",
                      headers: {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                      },
                      body: JSON.stringify({
                        
                          "userid":id,
                          "startdate":start,
                          "enddate":end,
                          "role":role,
                          "np":networkoperator
                        
                        })
                    }).then(resp => resp.json())
                      .then(data => {
                        // console.log("Print kwh data --->",data)
                          dispatch({type: 'FETCH_REPORT_KWH_LIST', payload: data})
                      })
                  }
                }
      
      
            export const TotalTimeAction = (start,end) => {
              const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
                var bearer = 'basic ' + token;
                 return dispatch => {
                      dispatch({type:'REPORT_LOADING',payload:''})
                      return fetch(REPORT_BASE_URL+ FETCH_REPORT_TIME, {
                        method: "POST",
                        headers: {
                          'Authorization': bearer,
                          'Content-Type': 'application/json',
                          Accept: 'application/json',
                        },
                        body: JSON.stringify({
                          
                            "userid":id,
                            "startdate":start,
                            "enddate":end,
                            "role":role,
                            "np":networkoperator
                          
                          })
                      }).then(resp => resp.json())
                        .then(data => {
                          // console.log("Print time data --->",data)
                            dispatch({type: 'FETCH_REPORT_TIME_LIST', payload: data})
                        })
                    }
                  }

                  export const TotalRevenueAction = (start,end) => {
                    const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
                      var bearer = 'basic ' + token;
                       return dispatch => {
                            dispatch({type:'REPORT_LOADING',payload:''})
                            return fetch(REPORT_BASE_URL+ FETCH_REPORT_REVENUE, {
                              method: "POST",
                              headers: {
                                'Authorization': bearer,
                                'Content-Type': 'application/json',
                                Accept: 'application/json',
                              },
                              body: JSON.stringify({
                                
                                  "userid":id,
                                  "startdate":start,
                                  "enddate":end,
                                  "role":role,
                                  "np":networkoperator
                                
                                })
                            }).then(resp => resp.json())
                              .then(data => {
                                // console.log("Print revenue data --->",data)
                                  dispatch({type: 'FETCH_REPORT_REVENUE_LIST', payload: data})
                              })
                          }
                        }
                  
                  