import { ASSET_BASE_URL, FETCH_CONNECTOR_TYPE_LIST_URL, SAVE_CONNECTOR_TYPE_URL,FETCH_SINGLE_CONNECTOR_URL,UPDATE_CONNECTOR_URL} from "../../components/config/configs";

   export const saveConnectorTypeAction = connectortype =>{
      //  console.log("connectortype",connectortype);
    const {token,id,firstname}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const {code,name,protocol,coonectortype,powertype,power}=connectortype;
        return dispatch => {
          dispatch({type:'CONNECTOR_TYPE_LOADING',payload:''})
          return fetch(ASSET_BASE_URL+SAVE_CONNECTOR_TYPE_URL, {
            method: "POST",
            headers: {
              'Authorization': bearer,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
                "code":code.value,
                "name":name.value,
                "protocol":protocol.value,
                "connectortype":coonectortype.value,
                "powertype":powertype.value,
                "power":power.value,
                "createdby":id,
                "enterdby": firstname,
           })
          }).then(resp => resp.json())
            .then(data => {
                dispatch({type: 'SAVE_CONNECTOR_TYPE', payload: data})
            })
        }
      }

      export const getconnectorTypeListAction = () => {
        const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
        var bearer = 'basic ' + token;
        return dispatch => {
            dispatch({type:'CONNECTOR_TYPE_LOADING',payload:''}) 
            return fetch(ASSET_BASE_URL+FETCH_CONNECTOR_TYPE_LIST_URL+"?userid="+id+"&role="+role+"&np="+networkoperator, {
             method: "GET",
             headers: {
              'Authorization': bearer,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            }
           })
             .then(resp => resp.json())
             .then(data => {
                  dispatch({type: 'FETCH_CONNECTOR_TYPE_LIST', payload: data})
             })
         }
       }

    //Single Connector View
    export const getSingleConnectorActions = (id) =>{
      const {token}= JSON.parse(localStorage.getItem('user'))
    // console.log(token)
    // console.log("token",ASSET_BASE_URL+FETCH_SINGLE_CONNECTOR_URL+"?id="+id)
      var bearer = 'basic ' + token;
      return dispatch => {
        return fetch(ASSET_BASE_URL+FETCH_SINGLE_CONNECTOR_URL+"?id="+id, {
          method: "GET",
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        })
          .then(resp => resp.json())
          .then(data => {
          //  console.log("FETCH_SINGLE_CONNECTOR",data);
              dispatch({type: 'FETCH_SINGLE_CONNECTOR', payload: data})
          })
      }
    }


    //Single Connector Update

export const updateConnectorAction = (id,user) => {
  const {token} = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token", ASSET_BASE_URL + UPDATE_CONNECTOR_URL)

  return dispatch => {
    return fetch(ASSET_BASE_URL + UPDATE_CONNECTOR_URL , {
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": id,
        "code":user.code,
        "name":user.name,
        "protocol":user.protocol,
        "connectortype":user.connectortype,
        "powertype":user.powertype,
        "power":user.power,
        "createdby":user.createdby,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UPDATE_CONNECTORS", data);

        dispatch({ type: 'UPDATE_CONNECTORS', payload: data })
      })
  }
}