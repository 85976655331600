import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { fetchiitializetransactionAction,realiseinitialiseAction } from "../../../store/actions/transactionAction";
import Table from "./locationTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';
import { toast, ToastContainer } from "react-toastify";
import { FaRocket } from "react-icons/fa";




const StationinitializeView = (props) => {
  useEffect(() => {
    props.StationData();
  }, [])

  const { role } = JSON.parse(localStorage.getItem("user"));

  const handleRelease = (id) => {
    console.log("handleRelease", id)
    props.ReleaseCharger(id)
    setTimeout(function () {
      window.location.reload();
    }, 5000);  }

  const data = props.stations && props.stations.data

  console.log("data",data)

  const columns = useMemo(

    () => [

      {
        header: 'Transaction Id',
        accessor: 'transactionid'
      },

      {
        header: 'Customer Name',
        accessor: 'customername'
      },
      {
        header: 'Mobile',
        accessor: 'mobile'
      },
      {
        header: 'Wallet Amount',
        accessor: 'walletamount'
      },
      {
        header: 'Location Name',
        accessor: 'locationname'
      },
      {
        header: 'Chargeboxid',
        accessor: 'chargeboxid'
      },
      {
        header: 'Connector No',
        accessor: 'connectorno'
      },
      {
        header: 'Connector Type',
        accessor: 'connectortype'
      },
      {
        header: 'Date',
        id: 'start1',
        accessor: d => {
          return moment(d.start)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Time',
        id: 'start2',
        accessor: d => {
          return moment(d.start).local().format('HH:mm:ss');
        }
      },
  
   
      {
        header: 'Transaction Status',
        accessor: 'transactionstatus'
      },

      {
        header: 'Actions',
        id: 'action',
        accessor: (d) => {
          return (
            <div>
              <span>
              {/* <FaRocket size="20px" color="black" onClick={() => { handleRelease(d.id); notify(); }} /> */}
                <button className="btn btn-primary btn-sm mr-2" onClick={() => { handleRelease(d.id); notify(); }}>Release Initialize</button>
              </span>
            
            </div>

          );

        },

      }

    ],
    []
  );
  const notify = () => {
    toast("Command Triggered");
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="mb-3 h-100 sideCardDesigns ">
            <div className="card-header singleCard">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <strong>Initialise Transaction Details</strong>
              </div>
            </div>

            {!data.length ?
              (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
              :
              (<div className="App"> <Table data={data} columns={columns}></Table></div>)}

          </div>
          <ToastContainer style={{ top: '3rem' }} />
        </div>
      </div>

    </>
  );
};

const mapStateToProps = function (state) {
  return {
    stations: state.transaction.fetchinitialize,
    updateinitialize : state.transaction.updateinitialize,
    loading: state.transaction.isLoading,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    StationData: () => dispatch(fetchiitializetransactionAction()),
    ReleaseCharger: (id) => dispatch(realiseinitialiseAction(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StationinitializeView);




