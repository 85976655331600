import{ REPORT_BASE_URL,WALLET_BASE_URL, CUSTOMER_BASE_URL, FETCH_DAILY_CHART_URL,FETCH_WEEKLY_CHART_URL, FETCH_MONTHLY_CHART_URL, FETCH_TOTALLIFETIME_CHART_URL, FETCH_WALLE_CHART_URL, FETCH_CUSTOMER_CHART_URL,FETCH_CITY_CHART_URL,FETCH_STATE_CHART_URL,FETCH_WALLET_MONTHLY_CREDIT_URL,FETCH_WALLET_MONTHLY_DEBIT_URL } from "../../components/config/configs";

export const insightDailyDataAction = (current,lastDay) => {
  const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    //console.log("dailygst",REPORT_BASE_URL+ FETCH_DAILY_CHART_URL+"?userid="+id+"&startdate="+current+"&enddate="+lastDay+"&role="+role+"&np="+networkoperator)
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(REPORT_BASE_URL+ FETCH_DAILY_CHART_URL+"?userid="+id+"&startdate="+current+"&enddate="+lastDay+"&role="+role+"&np="+networkoperator, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
         //  console.log("Chart Daily Data----",data);
             dispatch({type: 'FETCH_DAILY_DATA', payload: data})
         })
     }
   }


   export const insightWeeklyDataAction = (current,lastDay) => {
    const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(REPORT_BASE_URL+ FETCH_WEEKLY_CHART_URL+"?userid="+id+"&startdate="+current+"&enddate="+lastDay+"&role="+role+"&np="+networkoperator, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
          // console.log("Chart weekly Data----",data);
             dispatch({type: 'FETCH_WEEKLY_DATA', payload: data})
         })
     }
   }

   export const insightMonthlyDataAction = (current,lastDay) => {
    const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log("month",REPORT_BASE_URL+  FETCH_MONTHLY_CHART_URL+"?userid="+id+"&startdate="+current+"&enddate="+lastDay+"&role="+role+"&np="+networkoperator)
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(REPORT_BASE_URL+  FETCH_MONTHLY_CHART_URL+"?userid="+id+"&startdate="+current+"&enddate="+lastDay+"&role="+role+"&np="+networkoperator, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
          //  console.log("Chart monthly Data----",data);
             dispatch({type: 'FETCH_MONTHLY_DATA', payload: data})
         })
     }
   }

   export const insightTotalDataAction = () => {
    const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
    // console.log("fwrfwefwef",REPORT_BASE_URL+  FETCH_TOTALLIFETIME_CHART_URL+"?userid="+id+"&role="+role+"&np="+networkoperator)
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(REPORT_BASE_URL+  FETCH_TOTALLIFETIME_CHART_URL+"?userid="+id+"&role="+role+"&np="+networkoperator, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
          // console.log("Chart total Data----",data);
             dispatch({type: 'FETCH_TOTALLIFETIME_DATA', payload: data})
         })
     }
   }

   
   export const insightWalletDataAction = (current,lastDay) => {
    const {token}= JSON.parse(localStorage.getItem('user'))
  //  console.log(WALLET_BASE_URL+  FETCH_WALLE_CHART_URL+"?startdate="+current+"&enddate="+lastDay,token)
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(WALLET_BASE_URL+  FETCH_WALLE_CHART_URL+"?startdate="+current+"&enddate="+lastDay, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
          // console.log("Chart Wallet Data----",data);
             dispatch({type: 'FETCH_WALLET_DATA', payload: data})
         })
     }
   }

   export const insightCustomerDataAction = (current,lastDay) => {
    const {token}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(CUSTOMER_BASE_URL+  FETCH_CUSTOMER_CHART_URL+"?startdate="+current+"&enddate="+lastDay, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
          // console.log("Chart Customer Data----",data);
             dispatch({type: 'FETCH_CUSTOMER_DATA', payload: data})
         })
     }
   }
  
   export const insightCityDataAction = () => {
    const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
   // console.log("sefewFWefewf",REPORT_BASE_URL+  FETCH_CITY_CHART_URL+"?userid="+id+"&role="+role+"&np="+networkoperator,)
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(REPORT_BASE_URL+  FETCH_CITY_CHART_URL+"?userid="+id+"&role="+role+"&np="+networkoperator, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
         //  console.log("Chart City Data----",data);
             dispatch({type: 'FETCH_CITY_DATA', payload: data})
         })
     }
   }

   export const insightStateDataAction = () => {
    const {token,id,role,networkoperator}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(REPORT_BASE_URL+  FETCH_STATE_CHART_URL+"?userid="+id+"&role="+role+"&np="+networkoperator, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
          // console.log("Chart State Data----",data);
             dispatch({type: 'FETCH_STATE_DATA', payload: data})
         })
     }
   }


   export const walletCreditMonthlyDataAction = (current,lastDay) => {
    const {token}= JSON.parse(localStorage.getItem('user'))
    //console.log(WALLET_BASE_URL+  FETCH_WALLET_MONTHLY_CREDIT_URL+"?startdate="+current+"&enddate="+lastDay,token)
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(WALLET_BASE_URL+  FETCH_WALLET_MONTHLY_CREDIT_URL+"?startdate="+current+"&enddate="+lastDay, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
          // console.log("FETCH_WALLET_MONTHLY_CREDIT----",data);
             dispatch({type: 'FETCH_WALLET_MONTHLY_CREDIT', payload: data})
         })
     }
   }

   export const walletDebitMonthlyDataAction = (current,lastDay) => {
    const {token}= JSON.parse(localStorage.getItem('user'))
   // console.log(WALLET_BASE_URL+  FETCH_WALLET_MONTHLY_DEBIT_URL+"?startdate="+current+"&enddate="+lastDay,token)
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({type:'INSIGHT_LOADING',payload:''})
        return fetch(WALLET_BASE_URL+  FETCH_WALLET_MONTHLY_DEBIT_URL+"?startdate="+current+"&enddate="+lastDay, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
          // console.log("FETCH_WALLET_MONTHLY_DEBIT----",data);
             dispatch({type: 'FETCH_WALLET_MONTHLY_DEBIT', payload: data})
         })
     }
   }