let user = JSON.parse(localStorage.getItem('user'));
const initState ={
  user:user ? {user} : {},
  isLoading:false,
  isLogged:false
} 

const authReducer=(state=initState,action)=>{
    switch (action.type) {
      case 'LOGIN_LOADING':
        return{...state,isLoading:true}
        case 'LOGIN_USER':
          return {...state ,isLoading:false,isLogged:true,user: action.payload}
        case 'CHANGE_USER_PASSWORD':
          return {...state ,isLoading:false,user: action.payload}
        case 'LOGOUT_USER':
          return {...state, user:{}}
        default:
          return state;
      }
}
export default authReducer;