import React, { Component } from "react";
import validator from "validator";

class EditCustomer extends Component {
  customerForm = {
    firstname: { value: "", isValid: true, message: "" },
    address: { value: "", isValid: true, message: "" },
    city: { value: "", isValid: true, message: "" },
    state: { value: "", isValid: true, message: "" },
    pincode: { value: "", isValid: true, message: "" },
  };
  state = {
    ...this.customerForm,
  };

  handleChange = (e) => {
    const state = {
      ...this.state,
      [e.target.name]: {
        ...this.state[e.target.name],
        value: e.target.value,
      },
    };
    this.setState(state);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.customerFormValidation()) {
      this.props.saveCustomer({ ...this.state });
    }
  };

  customerFormValidation = () => {
    const firstname = { ...this.state.firstname };
    const address = { ...this.state.address };
    const city = { ...this.state.city };
    const state = { ...this.state.state };
    const pincode = { ...this.state.state };

    let isGood = true;
    if (validator.isEmpty(firstname.value)) {
      firstname.isValid = false;
      firstname.message = "First Name is required";
      isGood = false;
    }
    if (validator.isEmpty(address.value)) {
      address.isValid = false;
      address.message = "Address is required";
      isGood = false;
    }
    if (!validator.isEmail(city.value)) {
      city.isValid = false;
      city.message = "City is required";
      isGood = false;
    }
    if (validator.isEmpty(state.value)) {
      state.isValid = false;
      state.message = "State is required";
      isGood = false;
    }
    if (validator.isEmpty(pincode.value)) {
      pincode.isValid = false;
      pincode.message = "pincode is required";
      isGood = false;
    }

    if (!isGood) {
      this.setState({
        firstname,
        address,
        city,
        state,
        pincode,
      });
    }
    return isGood;
  };



  render() {
    const { firstname, address, city, state, pincode } = this.state;

    return (
      <div className="row-fluid py-2">
        <div className="col">
   
                <div className="sideCardDesigns card-signin">
                  <div className="card-body">
                    <h4 className="text-center formHeading">
                      <b>Edit Existing Customer</b>
                    </h4>

                    <form
                      className="form mt-5"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="row rowpadding  justify-content-between mt-5">
                        <div className="col-md-6 col-sm-12 ">
                          <div className="col-12  formContainer">
                            <div className="col-12">
                              <label className="formLable" htmlFor="firstname">Full Name</label>
                              <div className="form-label-group required">
                                <input
                                  type="text"
                                  id="firstname"
                                  name="firstname"
                                  className="form-control"
                                  placeholder="Full Name "
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="firstname">Full Name</label>
                                <span className="help-block text-danger">
                                  {firstname.message}
                                </span>
                              </div>
                              <label className="formLable" htmlFor="address">Address</label>
                              <div className="form-label-group required">
                                <input
                                  type="text"
                                  id="address"
                                  className="form-control"
                                  name="address"
                                  placeholder="Address"
                                />
                                <label htmlFor="address">Address</label>
                                <span className="help-block text-danger">
                                  {address.message}
                                </span>
                              </div>
                              <label className="formLable" htmlFor="city">City</label>
                              <div className="form-label-group required">
                                <input
                                  type="text"
                                  id="city"
                                  name="city"
                                  className="form-control"
                                  placeholder="City"
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="city">City</label>
                                <span className="help-block text-danger">
                                  {city.message}
                                </span>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 ">
                          <div className="col-12  formContainer ">

                            <div className="col-12 ">

                              <label className="formLable" htmlFor="state">State</label>
                              <div className="form-label-group required">
                                <input
                                  type="text"
                                  id="state"
                                  name="state"
                                  className="form-control"
                                  placeholder="state"
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="state">State</label>
                                <span className="help-block text-danger">
                                  {state.message}
                                </span>
                              </div>
                              <label className="formLable" htmlFor="pincode">Pincode</label>
                              <div className="form-label-group required pb-3">
                                <input
                                  type="number"
                                  id="pincode"
                                  name="pincode"
                                  className="form-control"
                                  placeholder="pincode"
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="pincode">Pincode</label>
                                <span className="help-block text-danger">
                                  {pincode.message}
                                </span>
                              </div>
                              <br />
                              <br />
                              <br />

                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                    <div className="row">
                      <div className="col-md-3 col-lg-3 ">
                        <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/customers">Cancel</a>
                      </div>
                      <div className="col-md-9 col-lg-9 ">
                        <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
     
    )
  }
}

export default EditCustomer;