import React, { Component } from "react";
import { connect } from "react-redux";
import "./login.css"
import { authAction } from "../../store/actions/authAction"
import { Alert } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import validator from 'validator'
import HeaderPage from "../landing/header";
import FooterPage from "../landing/footer";
import Recaptcha from 'react-google-invisible-recaptcha';
import queryString from 'query-string';

class LoginComponentPage extends Component {

  authDefaults = {
    username: { value: '', isValid: true, message: '' },
    password: { value: '', isValid: true, message: '' },
    error: { value: '', isValid: true, message: '' },
  }

  state = {
    ...this.authDefaults,
    messageSent: false,
    hidden: true,

  }
  //View password

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  }


  onResolved = () => {
    this.setState({ messageSent: true });
  }

  //handle change
  handleChange = (e) => {
    const state = {
      ...this.state,
      [e.target.name]: {
        ...this.state[e.target.name],
        value: e.target.value,
      }
    };
    this.setState(state);
  }

//submit login form
  handleLoginSubmit = (e) => {
    e.preventDefault();
    this.resetValidationStates();

    if (this.authFormIsValid()) {
      const username = { ...this.state.username };
      const password = { ...this.state.password };
      this.recaptcha.execute();
      this.props.signIn(username.value, password.value)
    }
  }

  //Form validation
  authFormIsValid = () => {
    const username = { ...this.state.username };
    const password = { ...this.state.password };
    const error = { ...this.state }
    let isGood = true;
    if (!validator.isEmail(username.value)) {
      username.isValid = false;
      username.message = 'Not a valid email address';
      isGood = false;
    }
    if (validator.isEmpty(password.value)) {
      password.isValid = false
      password.message = 'Password is required'
      isGood = false;
    }
    if (!isGood) {
      this.setState({
        username,
        password,
      });
    }

    return isGood;
  }

  resetValidationStates = () => {
    // make a copy of everything in state
    const state = JSON.parse(JSON.stringify(this.state));
    Object.keys(state).map(key => {
      if (state[key].hasOwnProperty('isValid')) {
        state[key].isValid = true;
        state[key].message = '';
      }
    });
    this.setState(state);
  }
  //Reset Form
  resetForm = () => {
    this.setState(...this.formDefaults);
  }

  render() {
    const queryStringParams = queryString.parse(window.location.search);
    const token = queryStringParams.auth;

    const { username, password } = this.state;
    if (this.props.response && (!this.props.response.ispasswordupdated && this.props.response.error === '')) {
      return <Redirect to='/changepassword' />;
    }
    if (this.props.response && (this.props.response.ispasswordupdated && this.props.response.error === '')) {
      return <Redirect to='/dashboard' />;
    }


//Login form
    return (
      <div className="container-fluid  h-1000">
        <div className="container-fluid ">
          <div className="row ">
            <div id="outer-container">

              <form className="form-signin" onSubmit={this.handleLoginSubmit}>
                <div className="text-center  ">
                  <a href="/" className="c_logo">
                    {token === "tsredco" ? <img src="images/tsredco/logo.png" alt="TSREDCO" className="logo-tsr" /> : <img src="images/logo/magentaLogo.svg" style={{ width: "300px" }} alt="Chargegrid" className="logo" />}
                  </a>
                </div>
                <div className="loginContainer text-center mt-2 mb-4">
                  <h3 className="loginText">LOGIN</h3>
                </div>
                <label className="loginLabel" htmlFor="username">User Name</label>
                <div className="form-label-group required" >
                  <input type="email" id="username" name="username" className="form-control " placeholder="Email address" onChange={this.handleChange} />
                  <label className="insideLable" htmlFor="username">User Name</label>
                  <span className="help-block text-danger">{username.message}</span>
                </div>
                <label className="loginLabel" htmlFor="password">Password</label>
                <div className="form-label-group required">
                  <input type={this.state.hidden ? "password" : "text"} id="password" name="password" className="form-control " placeholder="Password" onChange={this.handleChange} />
                  <label className="insideLable" htmlFor="password">Password</label>
                  <span className="p-viewer">
                    <i onClick={this.toggleShow} className={`fa ${this.state.hidden ? "fa-eye-slash" : "fa-eye"}`} style={{ cursor: "pointer", marginLeft: "-30px" }}></i>
                  </span>

                  <span className="help-block text-danger">{password.message}</span>

                </div>
                <div className="form-label-group">
                  <span className="help-block text-danger">
                    {this.props.response && this.props.response.error ? <Alert variant="danger">
                      <Alert.Heading style={{ fontSize: "14px" }}>
                        {this.props.response.error}
                      </Alert.Heading>
                    </Alert> : null
                    }
                  </span>

                </div>

                <button className="btn btn-lg  btn-block text-uppercase" >{!this.props.loading ? 'Sign in' : 'Please wait...'}</button>

                <div className="forgotContainer m-3 text-right">
                  <a href="/forgotpassotp" style={{ textDecoration: "underline" }} className="forgotLable">Forgot Password?</a>
                </div>

              </form>
              <div className="contactContainer text-right m-3 ">
                <a className="contactText" href={`https://www.magentamobility.com/contact`} style={{ textDecoration: "none" }}>Contact us <img className="contactImg pr-3" style={{ height: "20px" }} src={process.env.PUBLIC_URL + '/images/logo/contactusicon.svg'} ></img></a>
                <a className="contactText" href={`/help`} style={{ textDecoration: "none" }}>Help <img className="contactImg pr-2" style={{ height: "20px" }} src={process.env.PUBLIC_URL + '/images/logo/helpicon.svg'} ></img></a>
              </div>

            </div>
            <Recaptcha ref={ref => this.recaptcha = ref}
              sitekey="6LeaHbMeAAAAADQSIbjeNDwPGL65ykAmBpifFtgV"
              onResolved={this.onResolved} />
          </div>
          <div className="row ">
            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto footerContainer">
              Powered by &nbsp; <a href=""><img style={{ height: "40px" }} src={process.env.PUBLIC_URL + "/images/logo/LogoLeft.svg"} alt="" /></a>
            </div>
          </div>
        </div>

      </div>

    )
  }
}

//call login reducer from Redux store
const mapStateToProps = (state) => {
  return {
    response: state.auth.user,
    loading: state.auth.isLoading
  }
}

//call login action from Redux store

const mapDispatchToProps = dispatch => {
  return {
    signIn: (username, password) => dispatch(authAction(username, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponentPage)