import { CUSTOMER_BASE_URL,WALLET_BASE_URL, FETCH_CUSTOMER_LIST_URL, SAVE_CUSTOMER_URL, FETCH_SINGLE_RFID, FETCH_ALL_RFID, DELETE_RFID_URL, SAVE_RFID_EXCEL_URL, FETCH_SINGLE_CUSTOMER_REPORT_URL, SAVE_FLEET_RFID_URL,FETCH_PAYASYOUGO_CUSTOMER,FETCH_PREMIUM_CUSTOMER,EDIT_SINGLE_RFID_URL,FETCH_CUSTOMER_WALLET_LIST_URL,FETCH_PAYMENT_HISTORY_URL,FETCH_CUSTOMER_OTP_URL, SAVE_CONFIGRURER_URL, FETCH_CONFIGURER_LIST_URL,DELETE_CONNFIGURER_URL } from "../../components/config/configs";

export const customerListAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })

    return fetch(CUSTOMER_BASE_URL + FETCH_CUSTOMER_LIST_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
          // console.log("Data----",data);
        dispatch({ type: 'FETCH_CUSTOMER_LIST', payload: data })
      })
  }
}

export const saveCustomerAction = signup => {
  // console.log(signup);
  const { firstname, mobile, username, password } = signup;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
    return fetch(CUSTOMER_BASE_URL + SAVE_CUSTOMER_URL, {
      method: "POST",
      body: JSON.stringify({
        "name": firstname.value,
        "mobile": parseInt(mobile.value),
        "email": username.value,
        "password": password.value,
        "isagree": true,
        "accountstatus": true
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("Data----",data);
        dispatch({ type: 'SAVE_CUSTOMER', payload: data })
      })
  }
}




export const getRFIDListAction = docID => {
  const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
    return fetch(CUSTOMER_BASE_URL + FETCH_ALL_RFID + "?role=" + role + "&createdby=" + id + "&np=" + networkoperator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_RFID list", data)
        dispatch({ type: 'FETCH_RFID', payload: data })
      })
  }
}

export const getSingleRFDIActions = (id) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("infoRFIDdata", CUSTOMER_BASE_URL + FETCH_SINGLE_RFID + "?rfid=" + id)
  return dispatch => {
    return fetch(CUSTOMER_BASE_URL + FETCH_SINGLE_RFID + "?rfid=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("FETCH_SINGLE_RFID",data);
        dispatch({ type: 'FETCH_SINGLE_RFID', payload: data })
      })
  }
}

export const deactivateAction = (id,active) =>{

  // console.log("id,userstatus",id,active)
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(CUSTOMER_BASE_URL + DELETE_RFID_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
          "id": id,
          "active": active
     })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UTILITY_DEACTIVATION",data)
          dispatch({type: 'DEACTIVATE_RFID', payload: data})
      })
  }
}

export const saveRfidExcelActions = (rfId) => {
  const { token, id } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { firstname, lastname, mobile, emailid, idprof, idprofnumber, rfidno, rfidissuedate, taguid, keyuid, country, state, city, status, address, topup, usedamt, current, active, price } = rfId;
  return dispatch => {
    dispatch({ type: 'ASSET_LOADING', payload: '' })
    return fetch(CUSTOMER_BASE_URL + SAVE_RFID_EXCEL_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        firstname: firstname,
        lastname: lastname,
        mobile: mobile,
        emailid: emailid,
        idprof: idprof,
        idprofnumber: String(idprofnumber),
        rfidno: String(rfidno),
        rfidissuedate: rfidissuedate,
        taguid: String(taguid),
        keyuid: String(keyuid),
        country: country,
        state: state,
        city: city,
        status: status,
        address: address,
        topup: topup,
        usedamt: usedamt,
        current: current,
        price:price,
        active: active,
        createdby: id,
      })
    }).then(resp => resp.json())
      .then(data => {
        //  console.log("EXELRFID", data)
        dispatch({ type: 'SAVE_EXCEL_RFID', payload: data })
      })
  }
}

export const getSingleCustomerActions = (mobile) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("infodata",CUSTOMER_BASE_URL+FETCH_SINGLE_CUSTOMER_REPORT_URL+"?mobile="+mobile)
  return dispatch => {
    return fetch(CUSTOMER_BASE_URL + FETCH_SINGLE_CUSTOMER_REPORT_URL + "?mobile=" + mobile, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_SINGLE_CUSTOMER",data);
        dispatch({ type: 'FETCH_SINGLE_CUSTOMER', payload: data })
      })
  }
}


export const saveFleetRFIDAction = (rfid, rfidlist) => {
  const { token, id } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { name } = rfid;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
    return fetch(CUSTOMER_BASE_URL + SAVE_FLEET_RFID_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        name: name,
        rfidlist: rfidlist,
        createdby: id,
      })
    }).then(resp => resp.json())
      .then(data => {
        //  console.log("SAVE_FLEET_RFID", data)
        dispatch({ type: 'SAVE_FLEET_RFID', payload: data })
      })
  }
}

export const payAsyoyGoDriverListAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })

    return fetch(CUSTOMER_BASE_URL + FETCH_PAYASYOUGO_CUSTOMER, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
          //  console.log("FETCH_PAYASYOUGO_LIST", data)
        dispatch({ type: 'FETCH_PAYASYOUGO_LIST', payload: data })
      })
  }
}

export const premiumDriverListAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })

    return fetch(CUSTOMER_BASE_URL + FETCH_PREMIUM_CUSTOMER, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_PAYASYOUGO_LIST", data)
        dispatch({ type: 'FETCH_PREMIUM_LIST', payload: data })
      })
  }
}

export const editRfid = (rfid, data) => {
  const { token, id } = JSON.parse(localStorage.getItem('user'));
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload:'' })
    return fetch(CUSTOMER_BASE_URL+EDIT_SINGLE_RFID_URL, {
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        id: rfid,
        "firstname": data.firstname,
        "lastname": data.lastname,
        "mobile": parseInt(data.mobile),
        "emailid": data.emailid,
        "idprof": data.idprof,
        "idprofnumber": data.idprofnumber,
        "rfidno": data.rfidno,
        "rfidissuedate": data.rfidissuedate,
        "taguid": data.taguid,
        "keyuid": data.keyuid,
        "country": data.country,
        "state": data.state,
        "city": data.city,
        "status": data.status,
        "address": data.address,
        "topup": parseInt(data.topup),
        "usedamt": parseInt(data.usedamt),
        "current": parseInt(data.current),
        "createdby": id,
        "price": data.price,
        "active": data.active,
      })

    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'EDIT_SINGLE_RFID', payload: data })
      })
  }
}


export const customerWalletListAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })

    return fetch(WALLET_BASE_URL + FETCH_CUSTOMER_WALLET_LIST_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("Data----",data);
        dispatch({ type: 'FETCH_CUSTOMER_WALLET_LIST', payload: data })
      })
  }
}


export const getPaymenthistoryActions = (mobile) => {
  console.log("action mobile no",mobile)
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("infodata",CUSTOMER_BASE_URL+FETCH_SINGLE_CUSTOMER_REPORT_URL+"?mobile="+mobile)
  return dispatch => {
    return fetch(WALLET_BASE_URL + FETCH_PAYMENT_HISTORY_URL + "?loginid=" + mobile, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_SINGLE_CUSTOMER",data);
        dispatch({ type: 'FETCH_PAYMENT_HISTORY', payload: data })
      })
  }
}


export const customerOTPListAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })

    return fetch(CUSTOMER_BASE_URL + FETCH_CUSTOMER_OTP_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
          // console.log("Data----",data);
        dispatch({ type: 'FETCH_CUSTOMER_OTP', payload: data })
      })
  }
}


///save new configration user

export const saveChargerConfigurerAction = signup => {
  // console.log(signup);
  const { firstname, mobile, username } = signup;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
    return fetch(CUSTOMER_BASE_URL + SAVE_CONFIGRURER_URL, {
      method: "POST",
      body: JSON.stringify({
        "name": firstname.value,
        "mobile": parseInt(mobile.value),
        "email": username.value,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("Data----",data);
        dispatch({ type: 'SAVE_NEW_CONFIRURER', payload: data })
      })
  }
}

//// configurer list 

export const configurerListAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'CUSTOMER_LOADING', payload: '' })

    return fetch(CUSTOMER_BASE_URL + FETCH_CONFIGURER_LIST_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
          // console.log("Data----",data);
        dispatch({ type: 'FETCH_CONFIRURER_LIST', payload: data })
      })
  }
}


///deactive configurer

export const deactivateConfigurerAction = (mobile,active) =>{

  // console.log("id,userstatus",id,active)
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(CUSTOMER_BASE_URL + DELETE_CONNFIGURER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
          "mobile": mobile,
          "is_deleted": active
     })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UTILITY_DEACTIVATION",data)
          dispatch({type: 'DEACTIVATE_CONFIGURER', payload: data})
      })
  }
}