import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUserDetailsAction } from "../../../store/actions/userAction";

class ViewUser extends Component{
    
    componentDidMount() {
        const { match: { params } } = this.props;
        const token='';
       this.props.getUser(params.userId,token)
    }
    render(){
        // console.log(this.props.userdetail);
        return(
            <div className="container-fluid">
            <div className="sideCardDesigns card-signin my-5">
                <div className="card-body">
                 <h4 className="text-center"><b>User Details</b></h4>
                 <hr className="my-4"/>
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                       <input type="email" id="username" className="form-control" value={this.props.userdetail.username} disabled />
                       <label htmlFor="username">Email address</label>
                     </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                       <input type="password" id="password" className="form-control" value={this.props.userdetail.password} disabled/>
                       <label htmlFor="password">Password</label>
                     </div>
                    </div>
                    </div>
                    <div className="row">       
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                       <input type="text" id="firstname" className="form-control" value={this.props.userdetail.firstname} disabled />
                       <label htmlFor="firstname">First Name</label>
                     </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                       <input type="text" id="lastname" className="form-control" value={this.props.userdetail.lastname} disabled/>
                       <label htmlFor="lastname">Last Name</label>
                     </div>
                    </div>
                    </div>
                  <div className="row">
                  <div className="col-xl-6 col-sm-6 col-12">
                        <h5>Select User Role</h5>
                        <hr/>
                        <div className="custom-control custom-radio pl-5">
                        <input type="radio" name="customRadio" className="custom-control-input" value = {this.props.userdetail.role} checked/>
                            <label className="custom-control-label">{this.props.userdetail.role}</label>
                        </div>
                      
                      </div>
                   
                        <div className="col-xl-6 col-sm-6 col-12">
                            <h5>Select Privileges</h5>
                            <hr/>
                            {this.props.userdetail.privileges && this.props.userdetail.privileges.map((privilege)=> 
                           <div className="custom-control custom-checkbox pl-5">
                           <input type="checkbox" id={ privilege.key } name="customCheck" className="custom-control-input" value = { privilege.name } checked/>
                               <label className="custom-control-label" htmlFor={ privilege.key }>{ privilege.name }</label>
                           </div>  
                            )}
                    </div>
                    </div>
                    <hr/>
                    <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                       <input type="text" id="organization" className="form-control" value={this.props.userdetail.organization} disabled/>
                       <label htmlFor="organization">Organization Name</label>
                     </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                    <select class="form-control border-radius" id="organizationtype" >
                         <option>{this.props.userdetail.organizationtype}</option>
        
                    </select>
                      
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                       <input type="text" id="organizationadd" className="form-control" value={this.props.userdetail.address} disabled/>
                       <label htmlFor="organizationadd">Organization Address</label>
                     </div>
                    </div>
                    
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                       <input type="text" id="offphoneno" className="form-control" value={this.props.userdetail.phone} disabled />
                       <label htmlFor="offphoneno">Phone Number</label>
                     </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                    <select className="form-control border-radius"  id="country">
                    <option>{this.props.userdetail.country}</option>
                    </select>
                     </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                    <select className="form-control border-radius" id="state">
                    <option>{this.props.userdetail.state}</option>
                     </select>
                      
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                    <select className="form-control border-radius" id="city" >
                    <option>{this.props.userdetail.city}</option>
                      </select>
                    </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12"> 
                    <div className="form-label-group">
                       <input type="text" id="zipcode" className="form-control" value={this.props.userdetail.zipcode} disabled/>
                       <label htmlFor="zipcode">Zip Code</label>
                     </div>
                    </div>
                    </div>
                 <div className="row">
                 <div className="col-xl-6 col-sm-6 col-12"> 
                     <a className="btn btn-primary btn-pill btn-wide text-uppercase float-left" href="/dashboard/users">Back</a>
                     </div>     
                    
                  <div className="col-xl-6 col-sm-6 col-12 float-right"> 
                     <a className="btn btn-primary btn-pill btn-wide text-uppercase float-right"  href={`/dashboard/users/edituser/${this.props.userdetail.id}`}>Edit User</a>
                      
                     </div>     
                  </div> 
                 </div>
             </div>    
            </div>
        )
    }

}

const mapStateToProps = function(state){
    return {
       userdetail:state.users.fetchUserDetail,
     
     }
  }
  const mapDispatchToProps=dispatch =>{
    return{
       getUser:(id,token)=>dispatch(getUserDetailsAction(id,token)),
      
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ViewUser));
