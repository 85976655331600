import React, { Component } from "react";
import queryString from 'query-string';
class HeaderPage extends Component {

  
  render() {
    const queryStringParams = queryString.parse(window.location.search);
    // console.log(queryStringParams);
    const token=queryStringParams.auth;
    // console.log('token',token)

    return (
      <header className="primary-navigation z-999">
        <div>

          <a href="/" className="c_logo">
          {token==="tsredco"?<img src="images/tsredco/logo.png" alt="TSREDCO" className="logo-tsr"/>:<img src="images/logo/magentamain.png" alt="Chargegrid" className="logo"/>}
          </a>
          <nav>
            <ul>
              <li>
                <a href={`/contact`}>Contact</a>
              </li>
              <li>
                <a href={`/help`}>Help</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}
export default HeaderPage;
