import authReducer from "./authReducer";
import {combineReducers} from 'redux';
import commonReducer from "./commonReducer";
import userReducer from "./userReducer";
import assetReducer from "./assetReducer";
import opReducer from "./opReducer";
import locationReducer from "./locationReducer";
import ctReducer from "./connReducer";
import priceReducer from "./priceReducer";
import stationReducer from "./stationReducer";
import subscriptionReducer from "./subscriptionReducer";
import customerReducer from "./customerReducer";
import transactionReducer from "./transactionReducer";
import reportReducer from "./chargesessionReducer";
import insightReducer from "./insightReducer"
import fleetReducer from "./fleetReducer";
import inventoryReducer from "./inventoryReducer";
import hubReducer from "./hubReducer";
import utilsReducer from "./utilsReducer";



const rootReducer=combineReducers({
    auth:authReducer,
    common:commonReducer,
    users:userReducer,
    asset:assetReducer,
    operator:opReducer,
    location:locationReducer,
    ct:ctReducer,
    price:priceReducer,
    station:stationReducer,
    subscriptionplan:subscriptionReducer,
    customer:customerReducer,
    transaction:transactionReducer,
    report:reportReducer,
    insight:insightReducer,
    fleet:fleetReducer,
    inventory:inventoryReducer,
    hub:hubReducer,
    utils:utilsReducer

});

export default rootReducer;