const initState = {
    isLoading: false,
    fetchDailyData: '',
    fetchWeeklyData: '',
    fetchMonthlyData: '',
    fetchTotalData: '',
    fetchWalletData: '',
    fetchCustomerData: '',
    fetchCityData: '',
    fetchStateData: '',
    fetchWalletCreditData: '',
    fetchWalletDebitData: '',
}
const insightReducer = (state = initState, action) => {
    switch (action.type) {
        case 'INSIGHT_LOADING':
            return { ...state, isLoading: true }
        case 'FETCH_DAILY_DATA':
            return { ...state, fetchDailyData: action.payload, isLoading: false }
        case 'FETCH_WEEKLY_DATA':
            return { ...state, fetchWeeklyData: action.payload, isLoading: false }
        case 'FETCH_MONTHLY_DATA':
            return { ...state, fetchMonthlyData: action.payload, isLoading: false }
        case 'FETCH_TOTALLIFETIME_DATA':
            return { ...state, fetchTotalData: action.payload, isLoading: false }
        case 'FETCH_WALLET_DATA':
            return { ...state, fetchWalletData: action.payload, isLoading: false }
        case 'FETCH_CUSTOMER_DATA':
            return { ...state, fetchCustomerData: action.payload, isLoading: false }
        case 'FETCH_CITY_DATA':
            return { ...state, fetchCityData: action.payload, isLoading: false }
        case 'FETCH_STATE_DATA':
            return { ...state, fetchStateData: action.payload, isLoading: false }
        case 'FETCH_WALLET_MONTHLY_CREDIT':
            return { ...state, fetchWalletCreditData: action.payload, isLoading: false }
        case 'FETCH_WALLET_MONTHLY_DEBIT':
            return { ...state, fetchWalletDebitData: action.payload, isLoading: false }


        default:
            return state;
    }
}
export default insightReducer;