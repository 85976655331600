import React, { useEffect, useState  } from "react";
import { useParams, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getSingleHubUserDetails } from "../../../store/actions/hubActions";


const ViewHubAccess = (props) => {

  const { mobile } = useParams()

  useEffect(() => {
    props.getSingleHubUser(mobile);
  }, []);

  const data = props.fetchSingleUserDetails && props.fetchSingleUserDetails

    return (
        <>
      <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                          <b>View Hub User Access</b>
                        </h4>
                      
                            <div className="row rowpadding  justify-content-between my-5">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">
                                        <div className="col-12 pb-2">
                                        <label className="formLable" htmlFor="name">Name</label>
                                        <div className="form-label-group">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control control-label"
                                                value={data.name} disabled
                                            />
                                            <label htmlFor="name">Enter Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="phone">Phone Number</label>
                                        <div className="form-label-group">
                                            <input
                                                type="number"
                                                id="phone"
                                                name="phone"
                                                className="form-control control-label"
                                                value={data.mobile} disabled
                                            />
                                            <label htmlFor="phone">Phone Number</label>
                                        </div>

                                        <label className="formLable" htmlFor="country">Country</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="country" name="country" disabled >
                                                <option>{data.country}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="role">Roles</label>
                                        <div className="form-label-group">
                                            <input
                                                type="text"
                                                id="role"
                                                name="role"
                                                className="form-control control-label"
                                                value={data.role} disabled
                                            />
                                            <label htmlFor="role">Phone Number</label>
                                        </div>
                                  
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">
                                        <div className="col-12 ">

                                        <label className="formLable" htmlFor="state">State</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="state" name="state" disabled>
                                                <option>{data.state}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="city">City</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="city" name="city" disabled >
                                                <option>{data.city}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="status">Status</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" id="status" name="status" disabled >
                                                <option>{data.accountstatus ? "Active": "Inactive"}</option>
                                            </select>
                                        </div>
                                        
                                        <label className="formLable" htmlFor="privileges">Privileges</label>
                                        <div className=" containerAme p-2 pt-1">
                                          {data && data.privileges.map((pre) =>
                                                    <div className="form-check form-check-inline border badge badge-pill text m-1">
                                                      <label className="form-check-label" >{pre.name}</label>
                                                    </div>
                                                )}
                                        </div>
                                        </div>

                                   
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-xl-6 col-sm-6 col-12">
                                    <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/hub">Back</a>
                                </div>
                            </div>
                            </div>

                            
                    </div>
                </div>
          
      </>
    );
  }

const mapStateToProps = function (state) {
  return {
    fetchSingleUserDetails: state.hub.singleHubUserDetails
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getSingleHubUser: (mobile) => dispatch(getSingleHubUserDetails(mobile)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewHubAccess));