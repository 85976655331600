import { BASE_URL, LOGIN_URL, CHANGE_U_PASS } from "../../components/config/configs"

export const authAction = (username,password) => {
   return dispatch => {
       dispatch({type:'LOGIN_LOADING',payload:''})
      return fetch(BASE_URL+LOGIN_URL, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          "username":username,
          'password':password
       })
      })
        .then(resp => resp.json())
        .then(data => {
          // console.log(data);
          localStorage.setItem('user', JSON.stringify(data));
            dispatch({type: 'LOGIN_USER', payload: data})
        })
    }
  }
  
  export const changeAction = (username,password,token) =>{
    var bearer = 'basic ' + token;
    return dispatch => {
      dispatch({type:'LOGIN_LOADING',payload:''})
      return fetch(BASE_URL+CHANGE_U_PASS, {
        method: "PUT",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
            "username": username,
            'password':password
       })
      })
        .then(resp => resp.json())
        .then(data => {
            dispatch({type: 'CHANGE_USER_PASSWORD', payload: data})
        })
    }
  }
 
  export const logoutAction = () => {
    return dispatch => {
      localStorage.removeItem('user');
      dispatch({type: 'LOGOUT_USER', payload: {}})
   }
 }