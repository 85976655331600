import React from 'react';
import './campaign.css';

function Email() {

    return (
      <>
      {/* <div className="row">
      <div className="col-md-4 col-xl-4 col-sm-6 col-12">
      <div className="card border-light mb-3 widget-content cardbg bg-custom-1">
      <div className="widget-content-outer">
      <div className="widget-content-wrapper">
      <div className="widget-content-left">
           <div className="widget-heading">Lifetime Email Sent</div>
      </div>
      <div className="widget-content-right">
        <div className="widget-numbers text-success"><strong>110</strong></div>
      </div>
      </div>
      </div>
      </div>
      </div>

      <div className="col-md-4 col-xl-4 col-sm-6 col-12">
      <div className="card border-light mb-3 widget-content cardbg bg-custom-1">
      <div className="widget-content-outer">
      <div className="widget-content-wrapper">
      <div className="widget-content-left">
           <div className="widget-heading">Email Sent Today</div>
      </div>
      <div className="widget-content-right">
        <div className="widget-numbers text-success"><strong>5</strong></div>
      </div>
      </div>
      </div>
      </div>
      </div>  

      <div className="col-md-4 col-xl-4 col-sm-6 col-12">
      <div className="card border-light mb-3 widget-content cardbg bg-custom-1">
      <div className="widget-content-outer">
      <div className="widget-content-wrapper">
      <div className="widget-content-left">
           <div className="widget-heading">Email Failed</div>
      </div>
      <div className="widget-content-right">
        <div className="widget-numbers text-success"><strong>0</strong></div>
      </div>
      </div>
      </div>
      </div>
      </div>  
      </div> */}
      
      <div className="row">
      <div className="col-md-12 col-lg-12">
        <div className="mb-3 h-100 card">
        <div className="card-header">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong>Email Activity</strong>
        </div>
        {/* <div className="btn-actions-pane-right">
            <a href={`/dashboard/campaign/sendemail`}><button type="button" class="btn btn-primary btn-sm" ><span class="mr-1">Send New Email</span>
            </button></a>
        </div> */}
        </div>
        <div className="table-responsive">
        <table className="table table-sm table-bordered table-hover">
        <thead className="thead-light">
            <tr>
               
                <th scope="col">Recipient</th>
                <th scope="col">Subject</th>
                <th scope="col">Date</th>
                </tr>
             </thead>   
             <tbody>     
      <tr>
      <td>sanjeev@gmail.com
      </td>
      <td>New Subscriber</td>
      <td>26 Feb 2021</td>
      </tr>

      <tr>
      <td>asha@gmail.com
      </td>
      <td>New Charger at sajgaon</td>
      <td> 6 March 2021</td>     
      </tr>
      <tr>
      <td>rajat@gmail.com
      </td>
      <td>New Subscriber</td>
      <td>26 Feb 2021</td>
      </tr>

      <tr>
      <td>recipient@gmail.com
      </td>
      <td>New Charger at sajgaon</td>
      <td> 6 March 2021</td>     
      </tr> <tr>
      <td>recipient@gmail.com
      </td>
      <td>New Subscriber</td>
      <td>26 Feb 2021</td>
      </tr>
      </tbody>
         </table>   
        </div>
        </div>
      </div> 
      </div>
  </>
   
    )}
    

  export default Email;