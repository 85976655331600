import React, { Component } from "react"
import { connect } from "react-redux";
import PureBreadcrumbs from "../Breadcrums"


class UserCardList extends Component {
    countType(type) {
        if (this.props.userlist != null) {
            const list = this.props.userlist;
            const countTypes = Object.keys(list).filter(user => list[user].accountstatus === type);
            return countTypes.length;
        } else {

            return 0;
        }

    }

    render() {
        return (
            <>
                <div className="row ">
                    <div className="col col-sm-4 ">
                        <PureBreadcrumbs />
                    </div>
                </div>
                <div className="sideCardDesigns mb-3">
                    <div className="z-index-6 ">
                        {/* <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                User Details
            </div> */}
                     
                    </div>
                    <div className="no-gutters row pt-3">
                        <div className="col-sm-6 col-md-4 col-xl-4">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-10 bg-info">
                                        <i className="fas fa-users"></i>
                                    </div>
                                </div>
                                <div className="widget-chart-content">
                                    {/* <div className="widget-subheading">Total Users</div> */}
                                    <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "left" }}>Total Users</h5></span>
                                    <div className="widget-numbers">{this.props.userlist && this.props.userlist ? this.props.userlist.length : 0}</div>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-4">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-10 bg-success">
                                        <i className="fas fa-users"></i>
                                    </div>
                                </div>
                                <div className="widget-chart-content">
                                <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "left" }}>Total Active Users</h5></span>
                                    {/* <div className="widget-subheading">Total Active Users</div> */}
                                    <div className="widget-numbers">{this.countType(true)}</div>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-4">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-10 bg-warning ">
                                        <i className="fas fa-users"></i>
                                    </div>
                                </div>
                                <div className="widget-chart-content">
                                <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "left" }}>Total Inactive Users</h5></span>
                                    {/* <div className="widget-subheading">Total Inactive Users</div> */}
                                    <div className="widget-numbers">{this.countType(false)}</div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center d-block p-3">
                        <a href={`/dashboard/users/addnewuser`} className="btn btn-primary btn-pill btn-wide" ><span className="mr-1">Add New User</span></a>
                    </div>
                </div>

            </>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        userlist: state.users.fetchList
    }
}
export default connect(mapStateToProps)(UserCardList);
