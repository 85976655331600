import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../Breadcrums"
import Loader from 'react-loader-spinner';
import AttendanceTable from "./attendanceTable";
import { getAllAttendance } from "../../../../store/actions/hubActions";
import moment from "moment";

const UserAttendance = (props) => {


    useEffect(() => {
        props.getAllAttendance()
    }, []);

    const data = props.fetchAllAttendance && props.fetchAllAttendance

    const columns = useMemo(

        () => [

            {
                header: 'User Name',
                accessor: 'username'
            },
            {
                header: 'Mobile',
                accessor: 'mobile'
            },
            {
                header: 'role',
                accessor: 'role'
            },
            {
                header: 'Hub Name',
                accessor: 'hubname'
            },
            {
                header: 'Location',
                accessor: 'currentlocation'
            },

            {
                header: 'Check-in Date',
                id: 'checkindate',
                accessor: d => {
                  return moment.utc(d.checkindate).format("DD-MM-YYYY")
                }

            },
           {
                header: 'Check-in Time',
                accessor: 'checkintime'

            },
            //   {
            //     header: 'Check-in Image',
            //     accessor: 'checkinimage'

            // },
             {
                header: 'Check-out Date',
                id: 'checkoutdate',
                accessor: d => {
                  return moment.utc(d.checkoutdate).format("DD-MM-YYYY");
                }

            },
           {
                header: 'Check-out Time',
                accessor: 'checkouttime'

            },

        ],
        []
    );

    return (
        <>
            <div className="container-fluid">

                <div className="row rowpadding  m-1">
                    <div className="col">
                        <div className="row py-2">
                            <div className="col-md-12 col-lg-12 sideCardDesigns ">
                                <div className="mb-3 h-100 singleCard">
                                    <div className="card-header singleCard">
                                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>User Attendance Report</strong>
                                        </div>

                                    </div>

                                    {data && data.length > 0 ?
                                        (<div className="App pt-2"> <AttendanceTable data={data} columns={columns}></AttendanceTable></div>)
                                        :
                                        (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

const mapStateToProps = function (state) {
    return {
        fetchAllAttendance: state.hub.getAttendance
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllAttendance: () => dispatch(getAllAttendance()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAttendance);

