import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { customerListAction } from "../../../store/actions/customerAction";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import Pagination from "../../Pagination";
import 'jspdf-autotable';



const CustomerAll=(props)=>{
    const [searchTerm, setSearchTerm] = useState("");
  useEffect(()=>{
      props.fetCustomerList()
    },[])
  const [ transactiondata, setTransactionData  ] = useState([ props.transactions ])
  // console.log("WRf",props.transactions)
  const [ showPerPage, setShowPerPage ] = useState(10)
  const [ pagination, setPagination ] = useState({
    start:0,
    end:showPerPage,
  });

  const onPaginationChange = (start,end) => {
    setPagination({start:start, end: end});

  }

  const generatePDF = (event) =>{
    var doc = new jsPDF('l', 'pt', 'a4');
    doc.text(50, 40, "All Transaction");
    var res = doc.autoTableHtmlToJson(document.getElementById("ALLCustomer"));
    doc.autoTable(res.columns, res.data, {
      startY: 60,
      theme: 'grid',
    });
    doc.save("ALLCustomer.pdf");
  }

return(
    <>
  <div className="row-fluid py-2">
  <div className="col">
  <div className="p-3 sideCardDesigns">
  <div className="row py-2">
  <div className="col-sm-12 col-md-12 col-xl-12 col-12">   
  <div className="card m-3">
  <div className="table-responsive">
  <div className="table-wrapper">	
  <div className="table-title">
  <div className="row ">
  <div className="col-auto mr-auto">
  <div className="cssearch-box ">   
      <input type="text" className="form-control" placeholder="Search&hellip;" onChange={(event) => {
      setSearchTerm(event.target.value);
       }}
  />
  </div>			
  </div>
  <div className="col-auto">
  <div  className="float-center">
  <div className="btn-group dropleft ">
  <button className="btn btn-primary btn-sm" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  {<i className="fa fa-download" aria-hidden="true" title="Download" ></i>}
  </button>
  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a className="dropdown-item" href="">
    <ReactHTMLTableToExcel
         id="test-table-xls-button"
        table="ALLCustomer"
        filename="ALLCustomer_table"
        className="btn btn-default"
        sheet="tablexls"
        buttonText="Download as XLS">
        </ReactHTMLTableToExcel>
    </a>
    <a className="dropdown-item" href="" onClick ={generatePDF}>
  <center>Download as PDF</center>
    </a>
    </div>
    </div>
    </div>    
    </div>
    </div>
    </div>
    <table className="table table-sm table-bordered table-hover" id="ALLCustomer">
    <thead className="thead-light">
        <tr>
            <th scope="col">Customer Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Email ID</th>
            <th scope="col">Date</th>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
            </tr>
            
         </thead>   
           <tbody>     
           {props.customerlist && props.customerlist.filter((customer) =>{
              if (searchTerm === ""){
                return customer
              } else if (customer.name.toLowerCase().includes(searchTerm.toLowerCase())){
                return customer
            } else if (customer.state.toLowerCase().includes(searchTerm.toLowerCase())){
                return customer
            } else if (customer.email.toLowerCase().includes(searchTerm.toLowerCase())){
                return customer
              }
            }).slice( pagination.start, pagination.end ).map((customer) =>
                 <tr key={customer.id}>
                <td >{customer.name}</td>
                <td >{customer.mobile}</td>
                <td >{customer.email}</td>
                <td >{(new Date(customer.createdat)).toLocaleDateString()}</td>
                <td >{customer.address}</td>
                <td >{customer.city}</td>
                <td >{customer.state}</td>
                <td >{customer.accountstatus?<span className="text-success">Active</span>:<span  className="text-danger">Inactive</span>}</td>
                <td >
                <a rel="tooltip" title="Edit " className="table-action edit">
                        <i className="fa fa-edit"></i>
                    </a>
                    {customer.accountstatus? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash">
                    <i className="fas fa-user-slash"></i>
                    </a>:<a href="#" rel="tooltip" title="Activate" id="username" className="table-action active">
                    <i className="fas fa-user-check"></i>
                    </a>}
                </td>
                </tr>
                 )}
            </tbody>
     </table> 
        <Pagination showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={transactiondata.length}/>
    </div>
    </div>   
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>

</>
)
} 

const mapStateToProps = function(state){
    return{
        loading:state.customer.isLoading,
        customerlist:state.customer.fetchCustomerList
 }
}
const mapDispatchToProps=dispatch =>{
    return{
      fetCustomerList:()=>dispatch(customerListAction())
      }
 }
 
  export default connect(mapStateToProps,mapDispatchToProps)(CustomerAll);