import { PRICE_BASE_URL, FETCH_SUBSCRIPTION_PLAN_LIST_BY_CREATOR, SAVE_SUBSCRIPTION_PLAN_URL, UPDATE_PLAN_URL, FETCH_PLAN_TYPE_LIST, FETCH_SINGLE_PLAN,PLAN_DEACTIVATION_URL } from "../../components/config/configs";

export const saveSubscriptionPlanAction = (subscription, startdate, enddate) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  var onapp = false;
  const { name, code, description, subscriptionfee, setupcost, period, category, displaytype, expirationtype, plantype } = subscription;
  if (displaytype === 'Yes') {
    onapp = true
  }
  return dispatch => {
    dispatch({ type: 'SUBSCRIPTION_PLAN_LOADING', payload: '' })
    return fetch(PRICE_BASE_URL + SAVE_SUBSCRIPTION_PLAN_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "name": name,
        "code": code,
        "description": description,
        "startdate": startdate,
        "enddate": enddate,
        "fee": parseFloat(subscriptionfee),
        "setupfee": parseFloat(setupcost),
        "period": period,
        "category": category,
        "display": onapp,
        "expiration": expirationtype,
        "plantype": plantype,
        "createdby": id,
        "enterdby": firstname,
      })
    }).then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'SAVE_SUBSCRIPTION_PLAN', payload: data })
      })
  }
}

export const getSubscriptionPlanByCreatorAction = (planname) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  // console.log(PRICE_BASE_URL + FETCH_SUBSCRIPTION_PLAN_LIST_BY_CREATOR + "?planname=" + planname)
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'SUBSCRIPTION_PLAN_LOADING', payload: '' })
    return fetch(PRICE_BASE_URL + FETCH_SUBSCRIPTION_PLAN_LIST_BY_CREATOR + "?planname=" + planname, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'FETCH_SUBSCRIPTION_PLAN', payload: data })
      })
  }
}

       export const PlanDeactivateAction = (id,status) =>{
        const {token}= JSON.parse(localStorage.getItem('user'))
        var bearer = 'basic ' + token;
        // console.log("url ",PRICE_BASE_URL+PLAN_DEACTIVATION_URL )
        return dispatch => {
          return fetch(PRICE_BASE_URL+PLAN_DEACTIVATION_URL, {
            method: "POST",
            headers: {
              'Authorization': bearer,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "status":status
           })
          })
            .then(resp => resp.json())
            .then(data => {
              // console.log("PLAN_DEACTIVATION",data)
                dispatch({type: 'PLAN_DEACTIVATION', payload: data})
            })
        }
      }
      

export const getPlanTypeAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'SUBSCRIPTION_PLAN_LOADING', payload: '' })
    return fetch(PRICE_BASE_URL + FETCH_PLAN_TYPE_LIST, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'FETCH_SUB_PLAN_TYPE', payload: data })
      })
  }
}

//Single plan View
export const getSinglePlanActions = (id) => {
  const { token, } = JSON.parse(localStorage.getItem('user'))
  // console.log(token)
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(PRICE_BASE_URL + FETCH_SINGLE_PLAN + "?id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
          // console.log("FETCH_SINGLE_PLAN",data);
        dispatch({ type: 'FETCH_SINGLE_PLAN', payload: data })
      })
  }
}

export const updatePlanAction = (id, data) => {
  const { token, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  var onapp = false;
  if (data.display === 'Yes') {
    onapp = true
  }
  return dispatch => {
    return fetch(PRICE_BASE_URL + UPDATE_PLAN_URL, {
      
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        id: id,
        "name": data.name,
        "code": data.code,
        "description": data.description,
        "startdate": data.startdate,
        "enddate": data.enddate,
        "fee": parseInt(data.fee),
        "setupfee": parseInt(data.setupfee),
        "period": data.period,
        "category": data.category,
        "display": onapp,
        "expiration": data.expiration,
        "plantype": data.plantype,
        enterdby: firstname,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UPDATE_SINGLE_PLAN", data)
        dispatch({ type: 'UPDATE_SINGLE_PLAN', payload: data })
      })
  }
}
