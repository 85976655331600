import React from "react";
import "./campaign.css";

function SMS() {
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="mb-3 h-100 card">
            <div className="card-header">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <strong>Message Activity</strong>
              </div>
              {/* <div className="btn-actions-pane-right">
            <a href={`/dashboard/campaign/sendsms`}><button type="button" class="btn btn-primary btn-sm" ><span class="mr-1">Send New Message</span>
            </button></a>
        </div> */}
            </div>
            <div className="table-responsive">
              <table className="table table-sm  table-bordered table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Message</th>
                    <th scope="col">Sent To</th>
                    <th scope="col">Sent On</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      We have installed One more DC charger at Magenta TTC.
                      Happy charging
                    </td>
                    <td>888888888</td>
                    <td>26 Feb 2021</td>
                  </tr>
                  <tr>
                    <td>
                      Good Evening ChargeGrid User,Please note that ChargeBox ID
                      : CHNM063 which is at sajgaon location is UNDER
                      MAINTAINANCE.
                    </td>
                    <td>986533568</td>
                    <td> 6 March 2021</td>
                  </tr>
                  <tr>
                    <td>
                      We have installed One more DC charger at Magenta TTC.
                      Happy charging
                    </td>
                    <td>888888888</td>
                    <td>26 Feb 2021</td>
                  </tr>
                  <tr>
                    <td>
                      Good Evening ChargeGrid User,Please note that ChargeBox ID
                      : CHNM063 which is at sajgaon location is UNDER
                      MAINTAINANCE.
                    </td>
                    <td>986533568</td>
                    <td> 6 March 2021</td>
                  </tr>{" "}
                  <tr>
                    <td>
                      We have installed One more DC charger at Magenta TTC.
                      Happy charging
                    </td>
                    <td>888888888</td>
                    <td>26 Feb 2021</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SMS;
