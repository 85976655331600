import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./ContactUs.css";
import { Button } from "reactstrap";

const Contact=()=>{
  // console.log("Contact");
  return(
    <>
      <Container fluid className="contactcontainer1">
        <Row className="contactrow1">
          <Col>
            <spam className="contactspam1">CONTACT US</spam>
          </Col>
        </Row>
      </Container>

      <Container fluid className="contactcontainer2">
        <Container>
          <Row className="contactrow2">
            <Col xs="6">
              <spam className="contactspam2">GOT ANY QUESTIONS? </spam>
            </Col>
          </Row>

          <Row className="job1row6">
            <Col xs="12">
              <form
                accept-charset="UTF-8"
                method="POST"
                enctype="multipart/form-data"
                target=""
              >
                <div class="form-group">
                  <spam className="contactspam3">
                    <label for="exampleInputName">
                      <br />
                      Full Name
                    </label>
                  </spam>
                  <input
                    type="text"
                    name="fullname"
                    class="form-control"
                    id="exampleInputName"
                    required="required"
                  ></input>
                </div>
                <div class="form-group">
                  <spam className="contactspam3">
                    {" "}
                    <label for="inputAddress">Company name</label>
                  </spam>
                  <input
                    type="text"
                    name="cname"
                    class="form-control"
                    id="inputAddress"
                  ></input>
                </div>

                <div class="form-group">
                  <spam className="contactspam3">
                    <label for="exampleInputEmail1" required="required">
                      Corporate Email
                    </label>
                  </spam>
                  <input
                    type="email"
                    name="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required="required"
                  ></input>
                </div>
                <div class="form-group">
                  <spam className="contactspam3">
                    <label for="exampleInputName">Subject</label>
                  </spam>
                  <input
                    type="text"
                    name="subject"
                    class="form-control"
                    id="exampleInputName"
                    required="required"
                  ></input>
                </div>

                <div class="form-group">
                  <spam className="contactspam3">
                    {" "}
                    <label for="exampleInputName">Message</label>
                  </spam>
                  <input
                    type="text"
                    name="message"
                    class="form-control"
                    id="exampleInputName"
                    required="required"
                  ></input>
                </div>

                <center>
                  <Button style={{ backgroundColor: "#1E295D" }}>
                    Talk to Us
                  </Button>
                </center>
              </form>
            </Col>
          </Row>

          <Row className="contactrow4">
            <Col>
              <spam className="contactspam4">STILL HAVE QUESTIONS?</spam>
            </Col>
          </Row>

          <Row className="contactrow5">
            <Col>
              <span className="contactspan5">Come Visit Us</span>
            </Col>
          </Row>
          <Row className="contactrow6">
            <Col className="contactcol5">
              <spam className="contactspan4">
                <b>
                  Navi Mumbai
                  <br />
                </b>
              </spam>
              <spam className="contactspan5">
                <br />
                +91 9999999999
              </spam>
              <spam className="contactspan5">
                <br />
                <br />
                102, Alpha Garden, Plot-26, Behind Reliance Foundation School,
                Sector-14, Kopar Khairane, Navi Mumbai-400709, Maharashtra.
              </spam>
            </Col>
            <Col className="contactcol8">
              <spam className="contactspan4">
                <b>
                  Delhi
                  <br />
                </b>
              </spam>
              <spam className="contactspan5">
                <br />
                +91 9898989898
              </spam>
              <spam className="contactspan5">
                <br />
                <br /> Reliance Foundation School, Sector-14, Kopar Khairane,
                Delhi-400709, Delhi.
              </spam>
            </Col>
            <Col className="contactcol6">
              <spam className="contactspan4">
                <b>
                  Noida
                  <br />
                </b>
              </spam>
              <spam className="contactspan5">
                <br />
                +91 888888888
              </spam>
              <spam className="contactspan5">
                <br />
                <br />1 Behind Reliance Foundation School, Sector-14, Kopar
                Khairane, Noida-888888.
              </spam>
            </Col>
            <Col className="contactcol7">
              <spam className="contactspan4">
                <b>
                  Bangalore
                  <br />
                </b>
              </spam>
              <spam className="contactspan5">
                <br />
                +91 7777777777
              </spam>
              <spam className="contactspan5">
                <br />
                <br /> Plot-26, Behind Reliance Foundation School, Sector-14,
                Kopar Khairane, Bangalore-400709, Karnataka.
              </spam>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Contact;