import React, { useMemo, useEffect,useState } from "react"
import moment from "moment";
import "../report/table.css"
import { connect } from "react-redux";
import { getAllHubListData, getVehicleDetails } from "../../../store/actions/hubActions"
import Table from "./vehicledetailsTable";
import Loader from 'react-loader-spinner';


const VehicleDetailsTable = (props) => {
    const initialValues = { hubname: "All" }
    const [singlehubname, setSinglehubname] = useState(initialValues)


    useEffect(() => {
        let hubb = "All"
        props.getHubListData();

        if (hubb == "All") {
            props.getVehicle(hubb);
        }

    }, []);



    const handleChange = (e) => {
        let hubname = e.target.value
        // console.log("dvbsd", hubname)
        const { name, value } = e.target;
        setSinglehubname({ ...singlehubname, [name]: value });

        if (e.target.value !== "" && e.target.value !== undefined) {
            props.getVehicle(hubname);
        }

    };


    const data = props.fetchVehicle && props.fetchVehicle
    // console.log("all data----->", data)

    const columns = useMemo(

        () => [

            {
                header: 'Hub Name',
                accessor: 'hubname'
            },
            {
                header: 'QR Code',
                accessor: 'qrcode'
            },
            {
                header: 'Vehicle Number',
                accessor: 'vehiclenumber'
            },
            {
                header: 'Vehicle Type',
                accessor: 'vehicletype'
            },
            {
                header: 'Battery(in kwh)',
                accessor: 'batterykwh'
            },

            {
                header: 'Fleet',
                accessor: 'fleetoperator'

            },
           
       
        ],
        []
    );

    const hubList = props.fetchAllHubData && props.fetchAllHubData.map(id => id.hubname)

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col sideCardDesigns mb-2">

                    <div className="card-header singleCard justify-content-between mb-3">
                        <div className="text-left">
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Vehicle Details</strong>
                            </div>
                        </div>
                        <div className=" text-right">
                            <select className="columnsBtn p-2" name="hubname" onChange={handleChange}>
                                <option value="All" >All</option>
                                {hubList && hubList.map((hub) => (
                                    <option key={hub.id} value={hub} >{hub}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col">
                            <div className="row ">
                                <div className="col-md-12 col-lg-12">
                                    <div className="mb-3 h-100 singleCard">
                                        {data && data.length > 0 ? (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                            :
                                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#1e295d", fontWeight: "600", fontSize: "30px", marginTop: "30px" }}>No Data Available</div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        fetchAllHubData: state.hub.getAllHubListData,
        fetchVehicle: state.hub.fetchVehicleDetails,
    }
}
const mapDispatchToProps = dispatch => {
    return {
       
        getHubListData: () => dispatch(getAllHubListData()),
        getVehicle: (hubname) => dispatch(getVehicleDetails(hubname)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailsTable);