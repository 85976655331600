import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLocationAction } from "../../../../store/actions/locationAction";
const CommisionType=(props)=>{
  const [location, setLocation] = useState();
  const history=useHistory();
  useEffect(()=>{
    props.getLocation() 
 },[])
  const handleSubmit=(e)=>{
    e.preventDefault();
  history.push("/dashboard/assets/commission-new-charger/"+location)
  }
  return(
       
        <div className="container">
            <div className="row">
            <form className="form-signin" onSubmit={handleSubmit}  autoComplete="off">
            
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div className="sideCardDesigns card-signin my-5">
                <div className="card-body">
                 <h4 className="text-center"><b>Commission Charger</b></h4>
                 <hr className="my-4"/>
                 <div className="form-label-group">
                            <select class="form-control border-radius" id="location" name="location" onChange={e =>setLocation(e.target.value)}>
                                 <option value="">Select Location</option>
                                {props.locations && props.locations.map(location=>
                                 <option id={location.id} value={location.number}>{location.name}</option>
                          
                                )}        
                                </select>
                            </div>
              </div>
              <div className="card-footer">
              <div className="row">
              <div className="col-md-3 col-lg-3 ">
                     <a className="btn btn-primary btn-pill btn-wide text-uppercase" href="/dashboard/assets">Back</a>
                     </div>     
                    
                     <div className="col-md-9 col-lg-9 ">
                     <button className="btn btn-primary btn-pill btn-wide text-uppercase float-right">Commission Charger</button>
                      
                     </div> 
                     <hr className="my-4"/>    
                  </div> 
              </div>
              </div>    
              </div>
              </form>
              </div>  
                
        </div>          
    )
}

const mapStateToProps = (state) =>{
  return{
       locations:state.location.fetchLocation,
     
  }
}
const mapDispatchToProps=dispatch =>{
  return{
    getLocation: () => dispatch(getLocationAction())
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(CommisionType);
