import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { editStationAction } from "../../../store/actions/stationAction";


class ViewStation extends Component {
  componentDidMount() {
    const { match: { params } } = this.props;
    this.props.GetStationByName(params.stationid)
  }
  render() {
    const conn = this.props.statonlist && this.props.statonlist.chargers
    const zxc = conn && conn.map(en => en.connectors)
    const data = Object.values(zxc).flatMap(arr => arr)
    // console.log("mann", this.props.statonlist && this.props.statonlist)
  
    return (
      <div className="container-fluid">
        <div className="sideCardDesigns card-signin my-5">
          <div className="card-body">
            <h4 className="text-center formHeading">
              <b>View Station Details</b>
            </h4>
        

            <div className="row rowpadding  justify-content-between mt-5">
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer">
                  <div className="col-12">
                    <label className="formLable" htmlFor="stationid">Station Id</label>
                    <div className="form-label-group">
                  <input type="text" id="stationid" name="stationid" className="form-control" value={this.props.statonlist.stationid} disabled />
                  <label htmlFor="stationid">Station Id</label>
                </div>
                    <label className="formLable" htmlFor="operator">Network Operator</label>
                    <div className="form-label-group">
                  <input type="text" id="operator" name="operator" className="form-control" value={this.props.statonlist.operator} disabled />
                  <label htmlFor="operator">Network Operator</label>
                </div>
                    <label className="formLable" htmlFor="name">Station name</label>
                    <div className="form-label-group">
                  <input type="text" id="name" name="name" className="form-control" value={this.props.statonlist.name} disabled />
                  <label htmlFor="name">Station name</label>
                </div>
                    <label className="formLable" htmlFor="operator">Enterprise</label>
                    <div className="form-label-group">
                  <input type="text" id="Enterprise" name="Enterprise" className="form-control" value={this.props.statonlist.enterprise} disabled />
                  <label htmlFor="Enterprise">Enterprise</label>
                </div>
                    <label className="formLable" htmlFor="operator">Location Name</label>
                    <div className="form-label-group">
                  <input type="text" id="location" name="location" className="form-control" value={this.props.statonlist.name} disabled />
                  <label htmlFor="location">Location Name</label>
                </div>
                    <label className="formLable" htmlFor="operator">Visibility</label>
                    <div className="form-label-group">
                  <input type="text" id="Visibility" name="Visibility" className="form-control" value={this.props.statonlist.visibility} disabled />
                  <label htmlFor="Visibility">Visibility</label>
                </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer ">

                  <div className="col-12 ">

                    <label className="formLable" htmlFor="Commissioned">Commissioned Date</label>
                    <div className="form-label-group">
                  <input type="text" id="Commissioned" name="Commissioned" className="form-control" value={this.props.statonlist.commisiondate} disabled />
                  <label htmlFor="Commissioned">Enterprise</label>
                </div>

                    <label className="formLable" htmlFor="visibility">Status</label>
                    <div className="form-label-group">
                  <input type="text" id="Status" name="Status" className="form-control" value={conn && conn[0].status} disabled />
                  <label htmlFor="Status">Status</label>
                </div>
                    <label className="formLable" htmlFor="commisiondate">Install</label>
                    <div className="form-label-group">
                  <input type="text" id="install" name="install" className="form-control" value= {conn && conn[0].install} />
                  <label htmlFor="install">Install</label>
                </div>
                    <label className="formLable" htmlFor="commisiondate">Fleet</label>
                    <div className="form-label-group">
                  <input type="text" id="Fleet" name="Fleet" className="form-control" value={conn && conn[0].fleet } disabled />
                  <label htmlFor="Fleet">Fleet</label>
                </div>
                    <label className="formLable" htmlFor="projectname">Project Name</label>
                    <div className="form-label-group">
                  <input type="text" id="projectname" name="projectname" className="form-control" value={this.props.statonlist.projectname} disabled />
                  <label htmlFor="projectname">Project Name</label>
                </div>
                    {/* <label className="formLable" htmlFor="enteredby">Entered By</label>
                    <div className="form-label-group">
                  <input type="text" id="enteredby" name="enteredby" className="form-control" value={this.props.statonlist.enterdby} disabled />
                  <label htmlFor="enteredby">Entered By</label>
                </div> */}

                  </div>
                </div>
              </div>
            </div>


            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">Add Chargers</li>
              </ol>
            </nav>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Chargebox ID</th>
                        <th scope="col">Serial No</th>
                        <th scope="col">Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">Power Type</th>
                        <th scope="col">Charger type</th>
                        <th scope="col">Protocol</th>

                      </tr>
                    </thead>
                    <tbody>
                      {this.props.statonlist && this.props.statonlist.chargers.map((charger) =>
                        <tr>
                          <td>{charger.chargername} </td>
                          <td> {charger.chargeboxid}</td>
                          <td> {charger.chargerserialno}</td>
                          <td> {charger.make}</td>
                          <td>{charger.model} </td>
                          <td> {charger.powertype}</td>
                          <td>{charger.chargertype} </td>
                          <td> {charger.protocol}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">Add Connector</li>
              </ol>
            </nav>


            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Charger No</th>
                        <th scope="col">Connector Name</th>
                        <th scope="col">Connector Type</th>
                        <th scope="col">Power Type</th>
                        <th scope="col">Power</th>
                        <th scope="col">Connector No(Machine)</th>

                      </tr>
                    </thead>
                    <tbody>
                      {data && data.map((subItems) => {
                        return (
                          <tr> <td>{subItems.chargeboxid} </td>
                            <td> {subItems.connectorname}</td>
                            <td> {subItems.connectortype}</td>
                            <td> {subItems.powertype}</td>
                            <td>{subItems.power} </td>
                            <td> {subItems.connectorno}</td></tr>
                        )
                      })}


                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-12 ">
                <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/stations">Back</a>
              </div>

            </div>

          </div>
        </div>
      </div>

    )
  }


}
const mapStateToProps = function (state) {
  return {
    statonlist: state.station.editstationbyname,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    GetStationByName: (stationid, location) => dispatch(editStationAction(stationid, location)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewStation));