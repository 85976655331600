import React from 'react';
import './fleet.css'
function Tusharfleet() {
    return (
        <>
        <div className="container-fluid">
    <div className="row g-2">
    <div className="col-md-3">
    <div className="card mb-3 shadow p-3 mb-5 bg-white rounded">
    <div className="row">
    <div className="col">
    <div className="card border-dark mb-3 text-center cardbg bg-custom-1" >
    <div className="card-body">
      <h5 className="card-title">Lifetime Charging DOne</h5>   
    <div className="card no-shadow rm-border widget-chart text-center bg-custom-1">
    <div className="icon-wrapper">
      <img src={process.env.PUBLIC_URL + '/images/fleet_logo/lighting.svg'} alt=""  />
    </div>
    </div>
    <div className="d-block p-3 text-center ">
      <strong>07</strong>
    </div> 
    </div>
    </div>
    </div>
    </div>
    
    <div className="row">
    <div className="col">
    <div className="card border-dark mb-3 text-center cardbg bg-custom-1" >
    <div className="card-body">
      <h5 className="card-title">Total Amount</h5>   
    <div className="card no-shadow rm-border widget-chart text-center bg-custom-1">
  
    <span style={{color: "#1E295D"}}>
    <i className="fas fa-rupee-sign fa-3x"></i>
        </span>
    
   
    </div>
    <div className="d-block p-3 text-center ">
    <strong>12000</strong>
    </div> 
    </div>
    </div>
    </div>
    </div>

    <div className="row">
    <div className="col">
    <div className="card border-dark mb-3 text-center cardbg bg-custom-1" >
    <div className="card-body">
      <h5 className="card-title">Energy Consumed</h5>   
    <div className="card no-shadow rm-border widget-chart text-center bg-custom-1">
    <div className="icon-wrapper">
      <img src={process.env.PUBLIC_URL + '/images/fleet_logo/energetic.svg'} alt=""  />
    </div>
    </div>
    <div className="d-block p-3 text-center ">
    <strong>10 kWh</strong>
    </div> 
    </div>
    </div>
    </div>
    </div>

    <div className="row">
    <div className="col">
    <div className="card border-dark mb-3 text-center cardbg bg-custom-1" >
    <div className="card-body">
      <h5 className="card-title">Current Plan</h5>   
    <div className="card no-shadow rm-border widget-chart text-center bg-custom-1">
    <div className="icon-wrapper">
      <img src={process.env.PUBLIC_URL + '/images/fleet_logo/filled.svg'} alt=""  />
    </div>
    </div>
    <div className="d-block p-3 text-center ">
    <strong>Pay as You Go</strong>
    </div> 
    </div>
    </div>
    </div>
    </div>
    


    </div>
    </div>
    <div className="col-md-9">
    <div className="card mb-3 shadow p-3 mb-5 bg-white rounded">
    <div className="row">
    <div className="col">
    <div className="p-3 bg-light">

    <div className="user-detail-container">
    <div className="user-detail">
      <div className="upper-card">
      <div className="row">
        <div className="col-xl-6 col-md-6">
          <div className="card user-profile-card" data-mh="card-one">
            <div className="card-img-top">
              <img src="https://www.pngitem.com/pimgs/m/80-800194_transparent-users-icon-png-flat-user-icon-png.png" alt=""></img>
            </div>
            <div className="card-body">
              <h3>Tushar Dhoke</h3>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. A, neque nemo assumenda.</p>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-6">
          <div className="card user-detail-card" data-mh="card-one">
            <p><span>Gender :</span> Male</p>
            <p><span>Email :</span> tushark@example.com</p>
            <p><span>Age :</span> 25 </p>
            <p><span>Location :</span> Near Mumbai</p>
            <p><span>Joined Date :</span> 2019-02-22</p>
            <p><span>Contact no. :</span> 9863265226, 9568442262</p>
          </div>
        </div>
      </div>
        </div>
        </div>
        </div>

        <div className="container-fluid">
        <div className="row py-4">    
        <div className="col-xl-6 col-md-6">
        <div className="mb-3 h-100 card">
        <div className="card-header">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong>Vechile Details</strong>
        </div>
        <div className="btn-actions-pane-right">
        </div>
        </div>
    
    <div className="table-responsive">
    <table className="table table-sm table-bordered">
    <thead>
        <tr>
            <th scope="col">Customer Name</th>
            <th scope="col">Tushar dhoke</th>
            </tr>
            
         </thead>   
           <tbody>  
                <th scope="col">Registration Date</th>
                <th scope="col">29/01/2021</th>
            </tbody>
            <tbody>    
                <th scope="col">Model</th>
                <th scope="col">Tata Tigor EV</th>
                </tbody>
            <tbody>    
                <th scope="col">Color</th>
                <th scope="col">Silver</th>
               
            </tbody>
            <tbody>    
                <th scope="col">VIN</th>
                <th scope="col">684864561684</th>
               
            </tbody>
            <tbody>    
                <th scope="col">Licence Plate</th>
                <th scope="col">MH 131-122</th>
               
            </tbody>
            <tbody>    
                <th scope="col">Owner Name</th>
                <th scope="col">Magenta EV solutions Pvt Ltd</th>
               
            </tbody>
     </table>  
    </div>
    
   </div>
  </div>

  <div className="col-xl-6 col-md-6">
        <div className="mb-3 h-100 card">
        <div className="card-header">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong>Trip Details</strong>
        </div>
        <div className="btn-actions-pane-right">
        </div>
        </div>
    
    <div className="table-responsive">
    <table className="table table-sm table-bordered">
    <thead>
        <tr>
            <th scope="col">Total Distance Covered</th>
            <th scope="col">458Km</th>
            </tr>
            
         </thead>   
           <tbody>  
                <th scope="col">Total Trip Completed</th>
                <th scope="col">29</th>
            </tbody>
            <tbody>    
                <th scope="col">Fuel Consumption</th>
                <th scope="col">548L</th>
                </tbody>
            
     </table>  
    </div>
    

    </div>
    </div>
    </div>

    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
                 </>
                 );


}


export default Tusharfleet;