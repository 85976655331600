import {
  ASSET_BASE_URL,
  FETCH_ASSET_LIST_URL,
  FETCH_ASSET_LOCATION,
  FETCH_MAKER_URL,
  FETCH_MODEL_URL,
  SAVE_ASSET_URL,
  FETCH_SINGLE_ASSET_URL,
  SAVE_UTILITY_URL,
  FETCH_ALL_UTILITY_URL,
  FETCH_BY_ID,
  UPDATE_UTILITY_URL,
  UPDATE_ASSET_URL,
  FETCH_AMENITY_URL,
  ASSET_DEACTIVATION_URL,
  UTILITY_DEACTIVATION_URL,
} from "../../components/config/configs";




export const assetAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'ASSET_LOADING', payload: '' })

    return fetch(ASSET_BASE_URL + FETCH_ASSET_LOCATION, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("Data----",data);
        dispatch({ type: 'FETCH_ASSET', payload: data })
      })
  }
}

export const makeAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'ASSET_LOADING', payload: '' })
    return fetch(ASSET_BASE_URL + FETCH_MAKER_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'FETCH_ASSET_MAKER', payload: data })
      })
  }
}
export const modelAction = (makerid) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'ASSET_LOADING', payload: '' })
    return fetch(ASSET_BASE_URL + FETCH_MODEL_URL + "?makerid=" + makerid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'FETCH_ASSET_MODEL', payload: data })
      })
  }
}


export const getAssetListAction = () => {
  const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
  // console.log(token)
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'ASSET_LOADING', payload: '' })
    return fetch(ASSET_BASE_URL + FETCH_ASSET_LIST_URL + "?userid=" + id + "&role=" + role + "&np=" + networkoperator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //    console.log("assetdatalist",data)
        dispatch({ type: 'FETCH_ASSET_LIST', payload: data })
      })
  }
}


export const AssetDeactivateAction = (assetid,userstatus) =>{
  const {token}= JSON.parse(localStorage.getItem('user'))

  // console.log("assetis, userstatus",assetid.id,userstatus)
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(ASSET_BASE_URL+ASSET_DEACTIVATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
          "id": assetid.id,
          'status':userstatus
     })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("ASSET_DEACTIVATION",data)
          dispatch({type: 'ASSET_DEACTIVATION', payload: data})
      })
  }
}

export const getSingleAssetActions = (assetid) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("infodata", ASSET_BASE_URL + FETCH_SINGLE_ASSET_URL + "?assetid=" + assetid)
  return dispatch => {
    return fetch(ASSET_BASE_URL + FETCH_SINGLE_ASSET_URL + "?assetid=" + assetid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //console.log("FETCH_SINGLE_ASSET_LIST",data);
        dispatch({ type: 'FETCH_SINGLE_ASSET_LIST', payload: data })
      })
  }
}

//new save asset module action
export const saveAssetAction = (asset, charger, modem, network, Powercabinet, switchgear, rfidreader, cable, pos, battery, otherasset) => {
  const { token, id ,firstname} = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { name, assetid, location, installed } = asset;
  return dispatch => {
    dispatch({ type: 'ASSET_LOADING', payload: '' })
    return fetch(ASSET_BASE_URL + SAVE_ASSET_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        assetid: assetid,
        name: name,
        location: location,
        installed: installed,
        charger: charger,
        modem: modem,
        network: network,
        Powercabinet: Powercabinet,
        switchgear: switchgear,
        rfidreader: rfidreader,
        cable: cable,
        pos: pos,
        battery: battery,
        otherasset: otherasset,
        createdby: id,
        enterdby: firstname,
      })
    }).then(resp => resp.json())
      .then(data => {
        // console.log("Save asset", data)
        dispatch({ type: 'SAVE_ASSET', payload: data })
      })
  }
}


//SAVE UTILITY
export const saveUtilityAction = (utility) => {
  const { token, id, role, networkoperator, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("Utility", utility)
  const { network_operator, enterprise, station_name, service_number, electricity_bill_due_date, electricity_bill_amount, unit_price, commissioned_date } = utility;
  return dispatch => {
    dispatch({ type: 'ASSET_LOADING', payload: '' })
    return fetch(ASSET_BASE_URL + SAVE_UTILITY_URL + "?userid=" + id + "&role=" + role + "&np=" + networkoperator, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        network_operator: network_operator,
        enterprise: enterprise,
        station_name: station_name,
        service_number: String(service_number),
        electricity_bill_due_date: electricity_bill_due_date,
        electricity_bill_amount: parseFloat(electricity_bill_amount),
        unit_price: parseFloat(unit_price),
        enterdby: firstname,
        commissioned_date: commissioned_date,
        createdby: id,
      })
    }).then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'SAVE_UTILITY', payload: data })
      })
  }
}

export const getUtilityAction = () => {
  const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'ASSET_LOADING', payload: '' })
    return fetch(ASSET_BASE_URL + FETCH_ALL_UTILITY_URL + "?userid=" + id + "&role=" + role + "&np=" + networkoperator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("FETCH_UTILITY",data)
        dispatch({ type: 'FETCH_UTILITY', payload: data })
      })
  }
}

export const UtilityDeactivateAction = (id,userstatus) =>{

  // console.log("id,userstatus",id,userstatus)
  const {token}= JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(ASSET_BASE_URL+UTILITY_DEACTIVATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
          "id": id,
          "deactive": userstatus
     })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UTILITY_DEACTIVATION",data)
          dispatch({type: 'UTILITY_DEACTIVATION', payload: data})
      })
  }
}


//Single Utility View
export const getSingleUtilityActions = (id) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  // console.log("token", ASSET_BASE_URL + FETCH_BY_ID + "?util_id=" + id)
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(ASSET_BASE_URL + FETCH_BY_ID + "?util_id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("FETCH_UTILIT_BYID",data);
        dispatch({ type: 'FETCH_UTILIT_BYID', payload: data })
      })
  }
}


//Single Utility Update

export const updateUtilityAction = (id, user) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token", ASSET_BASE_URL + UPDATE_UTILITY_URL)

  return dispatch => {
    return fetch(ASSET_BASE_URL + UPDATE_UTILITY_URL, {
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": id,
        "network_operator": user.network_operator,
        "enterprise": user.enterprise,
        "station_name": user.station_name,
        "service_number": String(user.service_number),
        "electricity_bill_due_date": user.electricity_bill_due_date,
        "electricity_bill_amount": parseFloat(user.electricity_bill_amount),
        "unit_price": parseFloat(user.unit_price),
        "enterdby": user.enterdby,
        "commissioned_date": user.commissioned_date,
        "createdby": user.createdby
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UPDATE_UTILS", data);

        dispatch({ type: 'UPDATE_UTILS', payload: data })
      })
  }
}



//Update Asset

export const updateAssetAction = (assetid, data, chargerinputList, modeminputList, networkinputList, powercabinetinputList, switchgearinputList,rfidreaderinputList,cableinputList, posinputList, batteryinputList, otherinputList) => {
  const {location,name,status,installed}=data
  const { token,id } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("token", ASSET_BASE_URL + UPDATE_ASSET_URL)
  // console.log("iddddd", ide)

  return dispatch => {
    return fetch(ASSET_BASE_URL + UPDATE_ASSET_URL, {
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "assetid": assetid,
        "name": name,
        "location": location,
        "status": status,
        "installed": installed,
        "charger": chargerinputList,
        "modem": modeminputList,
        "network": networkinputList,
        "Powercabinet": powercabinetinputList,
        "switchgear": switchgearinputList,
        "rfidreader": rfidreaderinputList,
        "cable": cableinputList,
        "pos": posinputList,
        "battery": batteryinputList,
        "otherasset": otherinputList,
        "createdby": id,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UPDATE_ASSET", data);

        dispatch({ type: 'UPDATE_ASSET', payload: data })
      })
  }
}


export const amenityAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'ASSET_LOADING', payload: '' })

    return fetch(ASSET_BASE_URL + FETCH_AMENITY_URL, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("FETCH_AMENITY----", data);
        dispatch({ type: 'FETCH_AMENITY', payload: data })
      })
  }
}