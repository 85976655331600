import React, { useEffect, useState } from "react";
import { useParams, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { savevehicletypeAction } from "../../../../store/actions/hubActions";
import Select from "react-select";
import dashboard from "../../assets/excel/newvehicletype.xlsx";
import * as XLSX from "xlsx";



const VehicleTypeMaster = (props) => {
    const { firstname } = JSON.parse(localStorage.getItem("user"));
    const [vehicleTypeList, setVehicleTypeList] = useState();

    const handleChange = (e) => {
        setVehicleTypeList({
            ...vehicleTypeList,
            [e.target.name]: e.target.value.trim(),
        });

    };

    const handleSubmit = (e) => {
        console.log("vehicleTypeList", vehicleTypeList)
        e.preventDefault();
        alert("New vehicle Type added");
        props.saveVehicleType(vehicleTypeList);
        return window.location.href = "/dashboard/hub";
    };


    //Read Excel file
    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                console.log("wsname", ws);
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
                // console.log("exceldata", data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
        promise.then((d) => {
            for (let value of Object.values(d)) {
                const a = (Object.keys(value));
                var b = ['vehicletype'];
                if (JSON.stringify(a) == JSON.stringify(b)) {
                    setVehicleTypeList(d);
                    return;
                } else {
                    alert("Header format does not match");
                    return window.location.href = "/dashboard/hub";
                }
            }

        });
    };



    const handleSubmitFile = () => {
        for (let i = 0; i < vehicleTypeList.length; i++) {
            props.saveVehicleType(vehicleTypeList[i]);
        }

    };
  
    if (props.response.error !== "" && props.response.result === "Success") {
        return window.location.href = "/dashboard/hub";  
    }
  


    return (
        <>
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>Create New Vehicle Type</b>
                        </h4>
                        <form className="form mt-4" onSubmit={handleSubmit} autoComplete="off">
                            <div className="row rowpadding  justify-content-center">

                                <div className="col-6  formContainer ">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="vehicletype">Vehicle Type</label>
                                        <div className="form-label-group">
                                            <input
                                                type="text"
                                                id="vehicletype"
                                                name="vehicletype"
                                                className="form-control"
                                                placeholder="Vehicle Type"
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="vehicletype">Vehicle Type</label>
                                        </div>

                                    </div>
                                </div>

                            </div>



                            <div className="row">
                                <div className="col-xl-6 col-sm-6 col-12">
                                    <a
                                        className="btn  btn-pill btn-wide columnsBtn text-uppercase float-left"
                                        href="/dashboard/hub"
                                    >
                                        Back
                                    </a>
                                </div>

                                <div className="col-xl-6 col-sm-6 col-12 float-right">
                                    <button className="btn  btn-pill btn-wide columnsBtn text-uppercase float-right">
                                        {!props.loading ? "Create New Vehicle Type" : "Please wait..."}
                                    </button>
                                </div>
                            </div>
                        </form>

{/* 
                        <div className="row">
                            <div className="hr-sect">
                                <span style={{ color: "#10204B", fontWeight: "600", fontSize: "30px" }}>OR</span>
                            </div>
                        </div>


                        <div className="row formLable">
                            <div className="col-6">
                                <div className="col-12 formContainer p-5">
                                    <h4 className="text-left textColor ">
                                        <b> Download Excel </b>
                                    </h4>

                                    <div className="page-title-subheading text-left textColor">
                                        <ul>Steps to follow</ul>
                                        <ul>1. Download the excel.</ul>
                                        <ul>2. Add the desired data without changing header row.</ul>
                                        <ul>
                                            2. Select the location name as it is mentioned in above form.
                                        </ul>
                                        <ul>
                                            3. If there is already a dropdown, select the options from
                                            dropdown.
                                        </ul>
                                    </div>
                                    <a
                                        className=" p-2 columnsBtn btn-pill btn-wide text-uppercase textColor "
                                        href={dashboard}
                                        download="newvehicletype.xlsx"
                                    >
                                        {" "}
                                        Download Excel Format Here{" "}
                                    </a>
                                </div>

                            </div>
                            <div className="col-6">
                                <div className="col-12 textColor formContainer p-5">
                                    <h4 className="text-left">
                                        <b> Upload Excel </b>
                                    </h4>
                                    <div className="page-title-subheading text-left">
                                        <ul>Steps to follow</ul>
                                        <ul>1. Save the previous excel file.</ul>
                                        <ul>2. Click on choose file and select the same file.</ul>
                                        <ul>3. Click upload.</ul>
                                    </div>
                                    <input
                                        className="p-2 columnsBtn btn-pill btn-wide text-uppercase"
                                        type="file"
                                        name="file_upload"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            readExcel(file);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <br />
                        <div className="row">
                            <div className="col-md-3 col-lg-3 ">
                                <a
                                    className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                    href="/dashboard/hub"
                                >
                                    Cancel
                                </a>
                            </div>
                            <div className="col-md-9 col-lg-9 ">
                                <button
                                    className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"
                                    onClick={handleSubmitFile}
                                >
                                    Upload New Vehicle Type
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = function (state) {
    return {
        response: state.hub.savevehicletype,
        loading: state.location.isLoading,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        saveVehicleType: (vehicleno) => dispatch(savevehicletypeAction(vehicleno)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VehicleTypeMaster));