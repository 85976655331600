import React, { useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { fetchmobilesettingAction,updatemobilesettingAction } from "../../../store/actions/utilsAction";


const Mobile = (props) => {
    const [data, setData] = useState({ updatedat: "", is_sign_up: "", is_payment_enable: "", is_close: "", close_str: "", is_maintenance: "", maintenance_str: "", is_force_update: "", is_app_remove: "", app_download_url: "" ,is_tariff_price:""});
    toast.configure()
    const history = useHistory();
    const { rfid } = useParams();



    useEffect(() => {
        props.getmobileSetting();
    }, []);

    useEffect(() => {
        var mobiledata = props.getmobile.data && props.getmobile.data[0];
        setData(mobiledata);
    }, [props.getmobile.data]);



    // const handleChange = (e) => {
    //     setData({
    //         ...data,
    //         [e.target.name]: e.target.value
    //     });


    // }


    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Handle boolean values
        if (value === "true" || value === "false") {
          setData({
            ...data,
            [name]: value === "true"
          });
        } else {
          setData({
            ...data,
            [name]: value
          });
        }
      };


    const handlemobilesettingsSubmit = (e) => {
        e.preventDefault()
        props.updatemobilesetting(rfid, data);
        history.push("/dashboard/Mobile");
    }

    const notify = () => {
        toast("Mobile Settings Updated Successfully");
    };

    return (
        <div className="container-fluid">
            <div className="sideCardDesigns card-signin my-5">
                <div className="card-body">
                    <h4 className="text-center formHeading"><b>Update Mobile Settings</b></h4>

                    <form className="form mt-5" onSubmit={handlemobilesettingsSubmit} autoComplete="off">

                        <div className="row rowpadding  justify-content-between">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        {/* <label className="formLable" htmlFor="type">First Type</label>
                                        <div className="form-label-group required">
                                            <input type="text" id="type" name="type" className="form-control control-label" placeholder="type" onChange={handleChange} value={data && data.type} />

                                            <label htmlFor="type">type</label>
                                        </div> */}

                                        <label className="formLable" htmlFor="updatedat">Updated Date</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="updatedat" name="updatedat" className="form-control control-label" placeholder="updatedat" onChange={handleChange} value={new Date( data && data.updatedat)}  disabled/>
                                            <label htmlFor="updatedat">Updated Date</label>
                                        </div>


                                        <label className="formLable" htmlFor="is_sign_up">Is Sign UP</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="is_sign_up" onChange={handleChange} value={data && data.is_sign_up}>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="is_tariff_price">IS Tariff Price</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="is_tariff_price" onChange={handleChange} value={data && data.is_tariff_price}>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="is_payment_enable">Is Payment Enable</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="is_payment_enable" onChange={handleChange} value={data && data.is_payment_enable}>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="is_close">Is Close</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="is_close" onChange={handleChange} value={data && data.is_close}>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </select>
                                        </div>


                                        <label className="formLable" htmlFor="close_str">Close String</label>
                                        <div className="form-label-group ">
                                            {/* <select className="form-control border-radius" name="close_str" onChange={handleChange} value={data && data.close_str}>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </select> */}
                                            <input type="text" id="close_str" name="close_str" className="form-control" placeholder="close_str" onChange={handleChange} value={data && data.close_str} />
                                            <label htmlFor="close_str">{data && data.close_str}</label>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                    <label className="formLable" htmlFor="is_maintenance">Is Maintenance</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="is_maintenance" onChange={handleChange} value={data && data.is_maintenance}>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </select>
                                        </div>


                                        <label className="formLable" htmlFor="maintenance_str">Maintenance String</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="maintenance_str" name="maintenance_str" className="form-control" placeholder="maintenance_str" onChange={handleChange} value={data && data.maintenance_str} />
                                            <label htmlFor="maintenance_str">{data && data.maintenance_str}</label>
                                        </div>


                                        <label className="formLable" htmlFor="is_force_update">Is Force Update</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="is_force_update" onChange={handleChange} value={data && data.is_force_update}>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="is_app_remove">Is App Remove</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="is_app_remove" onChange={handleChange} value={data && data.is_app_remove}>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="app_download_url">App Download URL</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="app_download_url" name="app_download_url" className="form-control" placeholder="app_download_url" onChange={handleChange} value={data && data.app_download_url} />
                                            <label htmlFor="app_download_url">{data && data.maintenance_str}</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-lg-3 ">
                                <a
                                    className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                    href="/dashboard/customers"
                                >
                                    Cancel
                                </a>
                            </div>
                            <div className="col-md-9 col-lg-9 ">
                                <button
                                    className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" onClick={notify}
                                >
                                    Update Mobile Settings
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = function (state) {
    return {
        loading: state.utils.isLoading,
        updatemobile: state.utils.updatemobileglobalsetting,
        getmobile: state.utils.fetchmobileglobalsetting
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updatemobilesetting: (rfid, data) => dispatch(updatemobilesettingAction(rfid, data)),
        getmobileSetting: () => dispatch(fetchmobilesettingAction())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Mobile);
