const initState = {
    getAllHubOperationData: '',
    getAllHubListData: '',
    getSingleHubDetails: '',
    getHubStatusDetails: '',
    getHubPowerDetails: '',
    getAllHubDetails: '',
    getChargerPmr: '',
    getElectricpanelPmr: '',
    getBroadbandPmr: '',
    getCCTVPmr: '',
    getAttendance: '',
    getHubprivileges: '',
    gethubroles: '',
    SaveAccessmanagement: '',
    getalluserlist: '',
    deletehubuser: '',
    hubWiseHazardReport: '',
    singleHubUserDetails: '',
    updateSingleHubUserDetails: '',
    fetchQRcodeList: '',
    fetchVehicleNoList: '',
    fetchBatteryTypeList: '',
    fetchFleetOperatorList: '',
    fetchVehicleTypeList: '',
    saveVehicleaAndQrcode: '',
    fetchVehicleDetails: '',
    fetchAllhubtransaction: '',
    fetchallvisitor: '',
    fetchallcustomerotp: '',
    savehublist: '',
    savevehicleqr: '',
    savevehiclenumber: '',
    savevehicletype: '',
    savebatterykw: '',
    savefleetoperator: '',
    fetchcompletedtransactiondata: '',
    fetchcheckinvehiclewithdate: '',
    fetchvehicleparkingreport:'',
    vehicleStatusReport: '',
    isVehicleStatusReportLoading:false,
}

const hubReducer = (state = initState, action) => {
    switch (action.type) {
        case 'HUB_LOADING':
            return { ...state, isLoading: true }
        case 'GET_HUB_OPS_DATA':
            return { ...state, getAllHubOperationData: action.payload, isLoading: false }
        case 'GET_HUB_LIST_DATA':
            return { ...state, getAllHubListData: action.payload, isLoading: false }
        case 'GET_SINGLE_HUB_DETAILS':
            return { ...state, getSingleHubDetails: action.payload, isLoading: false }
        case 'GET_HUB_STATUS':
            return { ...state, getHubStatusDetails: action.payload, isLoading: false }
        case 'GET_SINGLE_HUB_POWER':
            return { ...state, getHubPowerDetails: action.payload, isLoading: false }
        case 'GET_ALL_HUB_DETAILS':
            return { ...state, getAllHubDetails: action.payload, isLoading: false }
        case 'GET_SINGLE_HUB_CHARGER_PMR':
            return { ...state, getChargerPmr: action.payload, isLoading: false }
        case 'GET_SINGLE_HUB_ELECTRICPANEL_PMR':
            return { ...state, getElectricpanelPmr: action.payload, isLoading: false }
        case 'GET_SINGLE_HUB_BROADBAND_PMR':
            return { ...state, getBroadbandPmr: action.payload, isLoading: false }
        case 'GET_SINGLE_HUB_CCTV_PMR':
            return { ...state, getCCTVPmr: action.payload, isLoading: false }
        case 'GET_ALL_ATTENDANCE':
            return { ...state, getAttendance: action.payload, isLoading: false }
        case 'GET_ALL_HUBPRIVILEGES':
            return { ...state, getHubprivileges: action.payload, isLoading: false }
        case 'GET_ALL_HUBROLES':
            return { ...state, gethubroles: action.payload, isLoading: false }
        case 'SAVE_ACCESSMANAGEMENT':
            return { ...state, saveAccessmanagement: action.payload, isLoading: false }
        case 'GET_ALLHUBUSER_LIST':
            return { ...state, getalluserlist: action.payload, isLoading: false }
        case 'DELETE_SIGNLEHUB_USER':
            return { ...state, deletehubuser: action.payload, isLoading: false }
        case 'GET_HUB_WISE_HAZARD_REPORT':
            return { ...state, hubWiseHazardReport: action.payload, isLoading: false }
        case 'GET_SINGLE_HUB_USER':
            return { ...state, singleHubUserDetails: action.payload, isLoading: false }
        case 'EDIT_HUB_USER_DETAILS':
            return { ...state, updateSingleHubUserDetails: action.payload, isLoading: false }
        case 'GET_QRCODE_LIST_DATA':
            return { ...state, fetchQRcodeList: action.payload, isLoading: false }
        case 'GET_VEHICLE_NO_DATA':
            return { ...state, fetchVehicleNoList: action.payload, isLoading: false }
        case 'GET_BATTERY_TYPE_DATA':
            return { ...state, fetchBatteryTypeList: action.payload, isLoading: false }
        case 'GET_FLEET_OPERATOR_DATA':
            return { ...state, fetchFleetOperatorList: action.payload, isLoading: false }
        case 'GET_VEHICLE_TYPE_DATA':
            return { ...state, fetchVehicleTypeList: action.payload, isLoading: false }
        case 'SAVE_VEHICLE_QRCODE_DATA':
            return { ...state, saveVehicleaAndQrcode: action.payload, isLoading: false }
        case 'GET_VEHICLE_DETAILS':
            return { ...state, fetchVehicleDetails: action.payload, isLoading: false }
        case 'FETCH_TRANSACTION_DATA':
            return { ...state, fetchAllhubtransaction: action.payload, isLoading: false }
        case 'FETCH_VISITOR_ATTENDANCE_DATA':
            return { ...state, fetchallvisitor: action.payload, isLoading: false }
        case 'FETCH_OTP_DATA':
            return { ...state, fetchallcustomerotp: action.payload, isLoading: false }
        case 'SAVE_HUBLIST_DATA':
            return { ...state, savehublist: action.payload, isLoading: false }
        case 'SAVE_VEHICLE_QR_DATA':
            return { ...state, savevehicleqr: action.payload, isLoading: false }
        case 'SAVE_VEHICLE_NUMBER_DATA':
            return { ...state, savevehiclenumber: action.payload, isLoading: false }
        case 'SAVE_VEHICLE_TYPE_DATA':
            return { ...state, savevehicletype: action.payload, isLoading: false }
        case 'SAVE_BATTERY_KW_DATA':
            return { ...state, savebatterykw: action.payload, isLoading: false }
        case 'SAVE_FLEET_OPERATOR_DATA':
            return { ...state, savefleetoperator: action.payload, isLoading: false }
        case 'FETCH_COMPLETED_TRANSACTION_DATA':
            return { ...state, fetchcompletedtransactiondata: action.payload, isLoading: false }
        case 'FETCH_VEHICLE_CHECKIN_DATE_DATA':
            return { ...state, fetchcheckinvehiclewithdate: action.payload, isLoading: false }
        case 'FETCH_VEHICLE_PARKING_DATA':
            return { ...state, fetchvehicleparkingreport: action.payload, isLoading: false }
        case 'VEHICLE_STATUS_REPORT_DATA':
            return { ...state, vehicleStatusReport: action.payload, isVehicleStatusReportLoading: action.isVehicleStatusReportLoading }



        default:
            return state;
    }
}
export default hubReducer


