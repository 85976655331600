import React, { Component } from "react"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { saveConnectorTypeAction } from "../../../../store/actions/ctAction";



class AddNewConnector extends Component {

    connectorForm = {
        code: { value: '', isValid: true, message: '' },
        name: { value: '', isValid: true, message: '' },
        protocol: { value: '', isValid: true, message: '' },
        coonectortype: { value: '', isValid: true, message: '' },
        powertype: { value: '', isValid: true, message: '' },
        power: { value: '', isValid: true, message: '' }
    }
    state = { ...this.connectorForm }
    handleChange = (e) => {
        e.persist();
        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: e.target.value,
            }
        };
        this.setState(state)
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.saveCoonectorType({ ...this.state })

    }

    render() {
        const { firstname } = JSON.parse(localStorage.getItem("user"));
        // console.log(this.props.saveresponse);
        if (this.props.saveresponse && this.props.saveresponse.error === '') {
            return <Redirect to='/dashboard/assets' />;
        }

        return (
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading"><b>Create New Connector Type</b></h4>

                        <form className="form mt-4" onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="row rowpadding  justify-content-between">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer">
                                        <div className="col-12">
                                            <label className="formLable" htmlFor="code">Connector Code</label>
                                            <div className="form-label-group">
                                                <input type="text" id="code" name="code" placeholder="Connector Code" className="form-control control-label" onChange={this.handleChange} required />
                                                <label htmlFor="code">Connector Code</label>
                                            </div>
                                            <label className="formLable" htmlFor="name">Connector Name</label>
                                            <div className="form-label-group">
                                                <input type="text" id="name" name="name" className="form-control control-label" placeholder="Connector Name" onChange={this.handleChange} required />
                                                <label htmlFor="name">Connector Name</label>
                                            </div>

                                            <label className="formLable" htmlFor="protocol">Communication protocol</label>
                                            <div className="form-label-group">
                                                <select className="form-control border-radius" id="protocol" name="protocol" onChange={this.handleChange} required>
                                                    <option>Communication protocol</option>
                                                    <option>OCPP1.6J</option>
                                                    <option>OCPP1.6S</option>
                                                    <option>OCPP2.0.1</option>

                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="coonectortype">Connector Type</label>
                                            <div className="form-label-group">
                                                <select className="form-control border-radius" id="coonectortype" name="coonectortype" onChange={this.handleChange} required>
                                                    <option>Connector Type</option>
                                                    <option>IEC60309</option>
                                                    <option>Type 2</option>
                                                    <option>GBT</option>
                                                    <option>CCS2</option>
                                                    <option>CHAdeMO</option>
                                                    <option>GBT2</option>
                                                </select>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">

                                        <div className="col-12 ">

                                            <label className="formLable" htmlFor="powertype">Power Type</label>
                                            <div className="form-label-group">
                                                <select className="form-control border-radius" id="powertype" name="powertype" onChange={this.handleChange} required>
                                                    <option>Power Type</option>
                                                    <option>AC</option>
                                                    <option>DC</option>
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="power">Power(KW)</label>
                                            <div className="form-label-group">
                                                <input type="text" id="power" name="power" className="form-control control-label" placeholder="Connector Name" onChange={this.handleChange} required />
                                                <label htmlFor="power">Power(KW)</label>
                                            </div>

                                            <label className="formLable" htmlFor="enterdby">Entered By</label>
                                            <div className="form-label-group pb-3">
                                                <input
                                                    type="text"
                                                    name="enterdby"
                                                    className="form-control control-label"
                                                    value={firstname}
                                                    disabled
                                                />
                                                <label htmlFor="enterdby">Data Entered By</label>
                                            </div>

                                            <br />
                                            <br />
                                            <br />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-sm-6 col-12">
                                    <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/assets">Back</a>
                                </div>

                                <div className="col-xl-6 col-sm-6 col-12 float-right">
                                    <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">{!this.props.loading ? 'Create Connector Type' : 'Please wait...'}</button>
                                </div>
                                <hr className="my-4" />
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.ct.isLoading,
        saveresponse: state.ct.saveConnectorType
    }
}
const mapDispatchToProps = dispatch => {
    return {
        saveCoonectorType: (ct) => dispatch(saveConnectorTypeAction(ct))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNewConnector);
