//Local Base Url
// export const BASE_URL = "http://localhost:9090";
// export const ASSET_BASE_URL = "http://localhost:9091";
// export const PRICE_BASE_URL = "http://localhost:9092";
// export const STATION_BASE_URL = "http://localhost:9093";
// export const CUSTOMER_BASE_URL = "http://localhost:9094";
// export const HUB_INSIGHTS_BASE_URL = "ws://localhost:9102";
// export const TRANSACTION_BASE_URL = "http://localhost:9097";
// export const REPORT_BASE_URL = "http://localhost:9099";
// export const WALLET_BASE_URL = "http://localhost:9098";
// export const FLEET_BASE_URL = "http://localhost:9100";
// export const HUB_BASE_URL = "http://localhost:9102";
// export const MOBILESETTING_BASE_URL = "http://localhost:9095";


//Dev Base Url
// export const BASE_URL="http://dev.magentachargegrid.com"
// export const ASSET_BASE_URL="http://dev.magentachargegrid.com"
// export const PRICE_BASE_URL="http://dev.magentachargegrid.com"
// export const STATION_BASE_URL="http://dev.magentachargegrid.com"
// export const CUSTOMER_BASE_URL="http://dev.magentachargegrid.com";
// export const HUB_INSIGHTS_BASE_URL="ws://dev.magentachargegrid.com";
// export const TRANSACTION_BASE_URL="http://dev.magentachargegrid.com";
// export const REPORT_BASE_URL="http://dev.magentachargegrid.com"
// export const WALLET_BASE_URL="http://dev.magentachargegrid.com"
// export const FLEET_BASE_URL="http://dev.magentachargegrid.com"
// export const HUB_BASE_URL="http://dev.magentachargegrid.com"
// export const MOBILESETTING_BASE_URL="https://prod.magentachargegrid.com"


//http://stage.magentachargegrid.com/

//Stage Base Url
// export const BASE_URL="https://stage.magentachargegrid.com"
// export const ASSET_BASE_URL="https://stage.magentachargegrid.com"
// export const PRICE_BASE_URL="https://stage.magentachargegrid.com"
// export const STATION_BASE_URL="https://stage.magentachargegrid.com"
// export const CUSTOMER_BASE_URL="https://stage.magentachargegrid.com";
// export const HUB_INSIGHTS_BASE_URL="wss://stage.magentachargegrid.com";
// // export const HUB_INSIGHTS_BASE_URL="https://stage.magentachargegrid.com";
// export const TRANSACTION_BASE_URL="https://stage.magentachargegrid.com";
// export const REPORT_BASE_URL="https://stage.magentachargegrid.com"
// export const WALLET_BASE_URL="https://stage.magentachargegrid.com"
// export const FLEET_BASE_URL="https://stage.magentachargegrid.com"
// export const HUB_BASE_URL="https://stage.magentachargegrid.com"
// export const MOBILESETTING_BASE_URL="https://stage.magentachargegrid.com"    



//Prod Base Url
 export const BASE_URL="https://prod.magentachargegrid.com";
 export const ASSET_BASE_URL="https://prod.magentachargegrid.com";
 export const PRICE_BASE_URL="https://prod.magentachargegrid.com";
 export const STATION_BASE_URL="https://prod.magentachargegrid.com";
 export const CUSTOMER_BASE_URL="https://prod.magentachargegrid.com";
 export const HUB_INSIGHTS_BASE_URL="wss://prod.magentachargegrid.com";
 export const TRANSACTION_BASE_URL="https://prod.magentachargegrid.com";
 export const REPORT_BASE_URL="https://prod.magentachargegrid.com";
 export const WALLET_BASE_URL="https://prod.magentachargegrid.com";
 export const FLEET_BASE_URL="https://prod.magentachargegrid.com";
 export const HUB_BASE_URL="https://prod.magentachargegrid.com";
 export const MOBILESETTING_BASE_URL="https://prod.magentachargegrid.com";



export const LOGIN_URL = "/um/api/v1/admin/portal/login"
export const CHANGE_U_PASS = "/um/secure/api/v1/dashboard/changepass"
export const COUNTRY_URL = "/um/api/v1/util/country"
export const STATE_URL = "/um/api/v1/util/state"
export const CITY_URL = "/um/api/v1/util/city"
export const ROLE_URL = "/um/secure/api/v1/dashboard/roles"
export const ROLE_NP_URL = "/um/secure/api/v1/dashboard/rolesbynp"
export const PRIVILEGE_URL = "/um/secure/api/v1/dashboard/privileges"
export const SAVE_USER_URL = "/um/secure/api/v1/dashboard/createuser"
export const UPDATE_USER_URL = "/um/secure/api/v1/dashboard/updateuser"
export const DELETE_USER_URL = "/um/secure/api/v1/dashboard/deleteuser"
export const FETCT_USER_URL = "/um/secure/api/v1/dashboard/getusers"
export const FETCT_USER_DETAIL_URL = "/um/secure/api/v1/dashboard/getuserdetail"
export const GET_LOGIN_OTP = "/um/api/v1/dashboard/sendotp"
export const VALIDATE_SIGNIN_OTP_URL = "/um/api/v1/dashboard/otpvalidate"
export const FORGOT_PASSWORD_URL = "/um/api/v1/dashboard/forgotpassword"
export const RESEND_OTP_URL = "/cm/api/v1/driver/forgetpassword"

//Location config
export const SAVE_LOCATION_URL = "/am/secure/api/v1/createlocation";
export const LOCATION_STATUS_LIST = "/am/secure/api/v1/getlocationstatuslist"
export const LOCATION_CAT_LIST = "/am/secure/api/v1/getlocationcategory"
export const LOCATION_SUB_CAT_LIST = "/am/secure/api/v1/getlocationsubcategory"
export const FETCH_LOCATIONS = "/am/api/v1/getlocation"
export const FETCH_OPERATOR_LOCATION = "/am/secure/api/v1/getlocationbyoperator"
export const FETCH_LOCATION_OWNER = "/am/secure/api/v1/getlocationowner"
export const UPDATE_LOCATION_URL = "/am/secure/api/v1/updatelocation"
export const DEACTIVATE_LOCATION = "/am/secure/api/v1/deactivatelocation"

//Asset Config
export const FETCH_ASSET_LOCATION = "/am/secure/api/v1/getasset";
export const FETCH_ENTERPRISE_URL = "/um/secure/api/v1/dashboard/getenterpriseuser";
export const FETCH_MAKER_URL = "/am/secure/api/v1/getmake";
export const FETCH_MODEL_URL = "/am/secure/api/v1/getmodel";
export const SAVE_ASSET_URL = "/am/secure/api/v1/createasset";
export const FETCH_ASSET_LIST_URL = "/am/secure/api/v1/getassetlist";
export const FETCH_SINGLE_ASSET_URL = "/am/secure/api/v1/getassetbyid";
export const UPDATE_ASSET_URL = "/am/secure/api/v1/updateasset";
export const FETCH_AMENITY_URL = "/am/secure/api/v1/getamenitylist";
export const ASSET_DEACTIVATION_URL = "/am/secure/api/v1/deactivateasset";

//Connectory type
export const SAVE_CONNECTOR_TYPE_URL = "/am/secure/api/v1/createconnector";
export const FETCH_CONNECTOR_TYPE_LIST_URL = "/am/secure/api/v1/getconnectortype";
export const FETCH_SINGLE_CONNECTOR_URL = "/am/secure/api/v1/getconnectorbyid";
export const UPDATE_CONNECTOR_URL = "/am/secure/api/v1/updateconnector";

//Price Cofig
export const SAVE_PRICE_URL = "/pm/secure/api/v1/createprice";
export const FETCH_SINGLE_PRICE_URL = "/pm/secure/api/v1/getpricebyid";
export const UPDATE_PRICE_LEVEL_URL = "/pm/secure/api/v1/updatepricebyid";
export const FETCH_PRICE_LIST_BY_CREATOR = "/pm/secure/api/v1/getprices";
export const UPDATE_PRICE_PLAN_URL = "/pm/secure/api/v1/updatepricebyid";
export const PRICE_DEACTIVATION_URL = "/pm/secure/api/v1/pricedeactivate";

//Subscription config
export const SAVE_SUBSCRIPTION_PLAN_URL = "/pm/secure/api/v1/createsubscription";
export const FETCH_SUBSCRIPTION_PLAN_LIST_BY_CREATOR = "/pm/secure/api/v1/getsubscription";
export const FETCH_PLAN_TYPE_LIST = "/pm/api/v1/getplans";
export const FETCH_SINGLE_PLAN = "/pm/secure/api/v1/getsubplanbyid";
export const UPDATE_PLAN_URL = "/pm/secure/api/v1/updateplanbyid";
export const PLAN_DEACTIVATION_URL = "/pm/secure/api/v1/plandeactivate";

//Station Config
export const SAVE_STATION_URL = "/sm/secure/api/v1/commissionstation";
export const FETCH_STATION_URL = "/sm/secure/api/v1/getstations";
export const FETCH_TOTAL_CHARGER_URL = "/sm/secure/api/v1/gettotalcharger";
export const GET_STATION_BY_NAME_URL = "/sm/secure/api/v1/getstationbynameweb";
export const UPLOAD_STATION_IMAGE_URL = "/sm/secure/api/v1/upload";
export const GET_CONFIGURATION_URL = "/sm/secure/api/v1/remote/getconfiguration";
export const CHANGE_CONFIGURATION_URL = "/sm/secure/api/v1/remote/changeconfiguration";
export const UNLOACK_CONNECTOR_URL = "/sm/secure/api/v1/remote/unloackconnector";
export const CLEAR_CACHE_URL = "/sm/secure/api/v1/remote/clearcache";
export const RESET_CHARGER_URL = "/sm/secure/api/v1/remote/resetcharger";
export const TRIGGER_CHARGER_URL = "/sm/secure/api/v1/remote/triggermessage";
export const SAVE_STATION_PRICE_GROUP_URL = "/sm/secure/api/v1/savepricegroup";
export const FETCH_DASH_CHARGERS_URL = "/sm/secure/api/v1/dashboard/getchargers";
export const FETCH_CHARGERS_BY_STATUS_URL = "/sm/secure/api/v1/charger/insight/charger_status";
export const UPDATE_FIRMWARE_URL = "/sm/secure/api/v1/remote/updatefirmware";
export const UPDATE_QR_NUMBER_URL = "/sm/secure/api/v1/updateqrcode";
export const GET_CHARGER_DOWNTIME_URL = "/sm/secure/api/v1/getdowntime";
export const CHANGE_AVAILABILITY_URL = "/sm/secure/api/v1/remote/changeavailability";
export const UPDATE_STATION_URL = "/sm/secure/api/v1/updatestation";
export const RELEASE_STUCK_BUSY_URL = "/sm/secure/api/v1/dashboard/updateconnectorsstatus";
export const CHECK_SERVER_STATUS_URL = "/sm/secure/api/v1/checkocppserverstatus";
export const UP_OCPP_SERVER_URL = "/sm/secure/api/v1/upocppserver";
export const SAVE_BOOKING_MASTER="/sm/secure/api/v1/savebookingmaster";
export const FETCH_MASTER_Booking="/sm/secure/api/v1/getbookingmaster";
export const FETCH_INITIALIZE_URL="/tm/secure/api/v1/transaction/initialize";
export const UPDATE_INITIALIZE_URL="/tm/secure/api/v1/transaction/initialize/update"
export const GET_CHARGER_LIST_URL = "/sm/secure/api/v1/logs/getChargerList";
export const GET_LOGS_BY_CHARGEBOXID_URL = "/sm/secure/api/v1/logs/getByChargeBoxId";
export const DEACTIVE_CHARGER_URL = "/sm/secure/api/v1/charger_deactive";
export const GET_CHARGER_UPTIME_REPORT_URL ="/sm/secure/api/v1/dashboard/getChargerUpTime"



//Customers Config
export const FETCH_CUSTOMER_LIST_URL = "/cm/secure/api/v1/driver/getcustomers";
export const SAVE_CUSTOMER_URL = "/cm/api/v1/driver/signup";
export const SAVE_RFID_URL = "/cm/secure/api/v1/rfid/save";
export const FETCH_ALL_RFID = "/cm/secure/api/v1/rfid/getall";
export const FETCH_SINGLE_RFID = "/cm/secure/api/v1/rfid/getsingle";
export const EDIT_SINGLE_RFID_URL="/cm/secure/api/v1/rfid/edit";
export const DELETE_RFID_URL = "/cm/secure/api/v1/rfid/delete";
export const SAVE_RFID_EXCEL_URL = "/cm/secure/api/v1/rfid/execl/save";
export const FETCH_SINGLE_CUSTOMER_REPORT_URL = "/cm/secure/api/v1/driver/getcustomerbyid"
export const FETCH_PAYASYOUGO_CUSTOMER = "/cm/secure/api/v1/driver/payasyougo"
export const FETCH_PREMIUM_CUSTOMER = "/cm/secure/api/v1/driver/premium"
export const FETCH_CUSTOMER_OTP_URL = "/cm/secure/api/v1/customer/otp/list"
export const SAVE_CONFIGRURER_URL = "/cm/api/v1/fcm/add";
export const FETCH_CONFIGURER_LIST_URL = "/cm/api/v1/fcm/list";
export const DELETE_CONNFIGURER_URL = "/cm/api/v1/fcm/status";






//transactions 
export const FETCH_TRANSACTION_LIST_URL = "/tm/secure/api/v1/transaction/getlist";
export const RELEASE_CHARGER_URL = "/tm/secure/api/v1/dashboard/charging/progress";
export const RELEASE_STUCK_TRANSACTION_URL = "/tm/secure/api/v1/dashboard/transaction/release";
export const FETCH_LIVE_CHARGING_DATA = "/tm/secure/api/v1/dashboard/livechargingstatus"


//report
export const FETCH_REPORT_DATA_URL = "/reports/secure/api/v1/dashboard/getreports";
export const FETCH_REPORT_CHARGINGSESSION = "/reports/secure/api/v1/dashboard/charging";
export const FETCH_REPORT_kWh = "/reports/secure/api/v1/dashboard/kwh";
export const FETCH_REPORT_TIME = "/reports/secure/api/v1/dashboard/time";
export const FETCH_REPORT_REVENUE = "/reports/secure/api/v1/dashboard/revenue";
export const FETCH_TRANSACTION_DATA_URL = "/reports/secure/api/v1/transaction/dashboard/getlist";

//insight chart
export const FETCH_DAILY_CHART_URL = "/reports/secure/api/v1/transaction/insight/getdaily";
export const FETCH_WEEKLY_CHART_URL = "/reports/secure/api/v1/transaction/insight/getweekly";
export const FETCH_MONTHLY_CHART_URL = "/reports/secure/api/v1/transaction/insight/getmonthly";
export const FETCH_TOTALLIFETIME_CHART_URL = "/reports/secure/api/v1/transaction/insight/gettotal";
export const FETCH_CUSTOMER_CHART_URL = "/cm/secure/api/v1/customers/getmonthly";
export const FETCH_CITY_CHART_URL = "/reports/secure/api/v1/transaction/insight/getbycity";
export const FETCH_STATE_CHART_URL = "/reports/secure/api/v1/transaction/insight/getbystate";

//wallet
export const FETCH_WALLE_CHART_URL = "/wm/secure/api/v1/wallet/getmonthly";
export const FETCH_WALLET_MONTHLY_CREDIT_URL = "/wm/secure/api/v1/wallet/getmonthlycredit";
export const FETCH_WALLET_MONTHLY_DEBIT_URL = "/wm/secure/api/v1/wallet/getmonthlydebit";
export const FETCH_CUSTOMER_WALLET_LIST_URL = "/wm/secure/api/v1/wallet/user_list";
export const FETCH_PAYMENT_HISTORY_URL = "/wm/secure/api/v1/wallet/payment_history";

//fleet
export const FETCH_FLEET_LIST_URL = "/fleet/secure/api/v1/dashboard/getlist";
export const FETCH_FLEET_TRANS_URL = "/fleet/secure/api/v1/dashboard/transactions";
export const SAVE_FLEET_RFID_URL = "/cm/secure/api/v1/driver/fleetrfid/save";

//utility
export const SAVE_UTILITY_URL = "/am/secure/api/v1/utils/save";
export const FETCH_ALL_UTILITY_URL = "/am/secure/api/v1/utils/get/all";
export const FETCH_BY_ID = "/am/secure/api/v1/utils/getbyid";
export const UPDATE_UTILITY_URL = "/am/secure/api/v1/updateutil";
export const UTILITY_DEACTIVATION_URL = "/am/secure/api/v1/deactivateutils";

//inventory
export const SAVE_INVENTORY_ASSET_URL = "/am/secure/api/v1/inventory/ac/assets"
export const GET_ALL_INVENTORY_ASSET = "/am/secure/api/v1/inventory/assets/getall"
export const GET_INVENTORY_ASSETBYID = "/am/secure/api/v1/inventory/getassetbyid"
export const UPDATE_INVENTORY_ASSETS = "/am/secure/api/v1/inventory/updateinventoryasset"
export const GET_INVENTORY_PRODUCT_COUNT = "/am/secure/api/v1/inventory/getbyproducttype"

//hub management
//hub management
export const GET_ALL_HUB_OPERATIONDATA_URL = "/hub/secure/api/v1/fetchallopsdatawithimage"
export const GET_ALL_HUB_LIST_URL = "/hub/secure/api/v1/hublist"
export const GET_SINGLE_HUB_URL = "/hub/secure/api/v1/fetchlasthubreading"
export const GET_HUB_STATUS_URL = "/hub/secure/api/v1/fetchhubstatus"
export const GET_HUB_POWER_URL = "/hub/secure/api/v1/fetchhubwisepowerdata"
export const GET_ALL_HUB_METER_URL = "/hub/secure/api/v1/fetchmeterdata"
export const GET_CHARGER_PMR_URL = "/hub/secure/api/v1/fetchhubchargerpmr"
export const GET_BROADBAND_PMR_URL = "/hub/secure/api/v1/fetchhubbroadbandpmr"
export const GET_CCTV_PMR_URL = "/hub/secure/api/v1/fetchhubcctvpmr"
export const GET_ELECTRICPANEL_PMR_URL = "/hub/secure/api/v1/fetchhubelecpanelpmr"
export const GET_ALL_ATTENDANCE = "/hub/secure/api/v1/dashboard/attendancereport"
export const GET_ALL_HUBPRIVILES_URL = "/hub/api/v1/hubprivileges"
export const GET_ALL_HUBROLES_URL = "/hub/api/v1/hubroles"
export const SAVE_ACCESSMANAGEMENT_URL ="/hub/secure/api/v1/accessmanagement"
export const GET_ALL_HUBUSERS_URL = "/hub/secure/api/v1/fetchallhubuser"
export const DELETE_HUB_USER_URL = "/hub/secure/api/v1/userdelete"
export const GET_HUB_HAZARD_REPORT_URL = "/hub/secure/api/v1/fetchhubhazardreport"
export const GET_SINGLE_HUB_USER_URL = "/hub/secure/api/v1/fetchlogininfo"
export const UPDATE_SINGLE_HUB_USER_URL = "/hub/secure/api/v1/update/hubuserbynumber"
export const GET_QRCODE_LIST_URL = "/hub/secure/api/v1/fetchqrcodelistnew"
export const GET_VEHICLE_NO_LIST_URL = "/hub/secure/api/v1/fetchvehiclenolistnew"
export const GET_BATTERY_TYPE_URL = "/hub/secure/api/v1/fetchbatterytype"
export const GET_VEHICLE_TYPE_URL = "/hub/secure/api/v1/fetchvehicletype"
export const GET_FLEET_OPERATOR_URL = "/hub/secure/api/v1/fetchfleetoperator"
export const SAVE_VEHICLE_DATA_URL = "/hub/secure/api/v1/save/vehicledetails"
export const GET_VEHICLE_DETAILS_URL = "/hub/secure/api/v1/vehicledetails"
export const GET_HUB_TRANSACTION_DATA_URL = "/hub/secure/api/v1/fetch/allhubtransaction"
export const GET_VISITOR_ATTENDANCE_URL = "/hub/secure/api/v1/fetchhubwise/visitorattendance"
export const GET_HUBOTP_URL = "/hub/secure/api/v1/hub/otp/list"
export const SAVE_HUB_LIST_URL ="/hub/secure/api/v1/save/hublistdata"
export const SAVE_QR_MASTER_URL ="/hub/secure/api/v1/qrcodeupload"
export const SAVE_VEHICLE_NUMBER_MASTER_URL ="/hub/secure/api/v1/vehicleupload"
export const SAVE_VEHICLE_TYPE_MASTER_URL ="/hub/secure/api/v1/vehicletypeupload"
export const SAVE_BATTERY_KW_MASTER_URL ="/hub/secure/api/v1/batterykwhupload"
export const SAVE_FLEET_OPERATOR_MASTER_URL ="/hub/secure/api/v1/fleetopartorupload"
export const GET_COMPLETED_TRANSACTION_URL ="/hub/secure/api/v1/cronhub"
export const GET_VEHICLE_CHECKIN_WITHTIME_URL ="/hub/secure/api/v1/vehiclenotinhub"
export const GET_BUSY_PARKING_REPORT_URL ="/hub/secure/api/v1/reportbusyparking"
export const VEHICLE_STATUS_REPORT_URL ="/hub/secure/api/v1/getVehicleStatus"
export const GET_ALL_HUB_OPERATIONDATA_WITHIN_DATE_RANGE ="/hub/secure/api/v1/getAllOpsDataWithDateRange"


//Utils

export const GET_MOBILE_SETTING = "/utils/secure/api/v1/dashboard/fetchmobileglobalsetting"
export const UPDATE_MOBILE_SETTING = "/utils/secure/api/v1/dashboard/updatemobileglobalsetting"












