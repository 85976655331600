import React from "react";

export const GlobalFilter = ({ filter, setFilter }) => {
    
  return (
    <span>
      <input
        className="SearchFilter p-2"
        placeholder="Search"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}   
      />
      <i className="" style={{cursor:"pointer",marginLeft: "-30px"}}><img className="pr-1" src={process.env.PUBLIC_URL + '/images/Insight_img/inputSearch.svg'} style={{height:"15px"}} ></img></i>
      
      
    
    </span>
    
  );

 
};

export default GlobalFilter