import React, { useState, useMemo } from "react"
import "../../report/table.css";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import "../fleet.css";
import Table1 from "../fleetCustomerTable";
import Table2 from "../fleetTransactionTable";
import Loader from 'react-loader-spinner';

const UsersPage = (props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchcustTerm, setSearchCustTerm] = useState("");
    const generatePDF = (event) => {
        var doc = new jsPDF('l', 'pt', 'a4');
        doc.text(50, 40, "Fleet Transaction table");
        var res = doc.autoTableHtmlToJson(document.getElementById("fleet_table"));
        doc.autoTable(res.columns, res.data, {
            startY: 60,
            theme: 'grid',
        });
        doc.save("Fleet.pdf");
    }
    const generateCustomerPDF = (event) => {
        var doc = new jsPDF('l', 'pt', 'a4');
        doc.text(50, 40, "Fleet Customer table");
        var res = doc.autoTableHtmlToJson(document.getElementById("fleet_cust_table"));
        doc.autoTable(res.columns, res.data, {
            startY: 60,
            theme: 'grid',
        });
        doc.save("Fleet_Customer.pdf");
    }


    const data1 = props.fleetdata.props.fleets.drivers && props.fleetdata.props.fleets.drivers.map(id => id)
    const data2 = props.fleetdata.props.fleettran.transactiondata && props.fleetdata.props.fleettran.transactiondata.map(id => id)

    const columns1 = useMemo(

        () => [

            {
                header: 'Customer Name',
                accessor: 'name'
            },
            {
                header: 'Mobile Number',
                accessor: 'mobile'
            },
            {
                header: 'Plan Name',
                accessor: 'planname'
            },
            {
                header: 'Total Consumption (In kW)',
                id: 'totalconsumption',
                accessor: (d) => (

                    d.unit.toFixed(2)
                )

            },
            {
                header: 'Total Revenue (In ₹)',
                id: 'totalrevenue',
                accessor: (d) => (
                    d.cost.toFixed(2)
                )
            },

        ],
        []
    );
    const columns2 = useMemo(

        () => [

            {
                header: 'Transaction ID',
                accessor: 'transactionid'
            },
            {
                header: 'Mobile Number',
                accessor: 'mobile'
            },
            {
                header: 'Customer Name',
                accessor: 'customername'
            },
            {
                header: 'Transaction Date',
                id: 'transactiondate',
                accessor: (d) => (
                    new Date(d.createdat).toLocaleDateString()
                )

            },
            {
                header: 'Charging Start time',
                accessor: 'starttime'
            },
            {
                header: 'Charging End time',
                accessor: 'stoptime'
            },
            {
                header: 'Charging (HH:MM:SS)',
                accessor: 'timeconsume'
            },
            {
                header: 'Charge BoxID',
                accessor: 'chargeboxid'
            },
            {
                header: 'Connector Type',
                accessor: 'connectortype'
            },
            {
                header: 'Customer Plan Type',
                accessor: 'plantype'
            },
            {
                header: 'Project Name ',
                accessor: 'projectname'
            },
            {
                header: 'Charging Location',
                accessor: 'locationname'
            },
            {
                header: 'Location Type',
                accessor: 'locationtype'
            },
            {
                header: 'Location Subtype',
                accessor: 'locationsubtype'
            },
            {
                header: 'Charging City',
                accessor: 'city'
            },
            {
                header: 'Charging State',
                accessor: 'locationstate'
            },
            {
                header: 'Charging Units (In kW)',
                accessor: 'kwh'
            },
            {
                header: 'Charging Amount (In ₹ )',
                accessor: 'amount'
            },
            {
                header: 'GST Amount',
                accessor: 'gst'
            },
            {
                header: 'Total Amount (In ₹ )',
                accessor: 'billedamount'
            },

        ],
        []
    );

    // console.log("props.fleetdata.props===>",props.fleetdata.props);

    return (
        <div className="container-fluid">
            <div className="row rowpadding  ">
                <div className="col">
                    <div className="row py-2">
                        <div className="col-md-12 col-lg-12 sideCardDesigns ">
                            <div className="mb-3 h-100 singleCard">
                                <div className="card-header singleCard">
                                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Fleet Customer Details</strong>
                                    </div>

                                </div>

                                {data1 && data1.length > 0 ?
                                    (<div className="App pt-2"> <Table1 data={data1} columns={columns1}></Table1></div>)
                                    :
                                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row rowpadding">
                <div className="col">
                    <div className="row py-2">
                        <div className="col-md-12 col-lg-12 sideCardDesigns ">
                            <div className="mb-3 h-100 singleCard">
                                <div className="card-header singleCard">
                                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Fleet Transactions Details</strong>
                                    </div>

                                </div>

                                {data2 && data2.length > 0 ?
                                    (<div className="App pt-2"> <Table2 data={data2} columns={columns2}></Table2></div>)
                                    :
                                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


export default UsersPage;
