import React, { useEffect, useState } from "react";
import { useParams,useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSingleAssetActions, updateAssetAction } from "../../../../store/actions/assetAction";        // Importing the action 
import { getLocationAction } from "../../../../store/actions/locationAction";      // Importing the Location name from the location action 
import "../../report/table.css"  // improiting the css

const EditAsset = (props) => {
    const history = useHistory();     // Access the history for instance used by react router and pushing back to a page 
    const { assetid } = useParams()   // Getting asset ID from previous page through useParam

    // Using useState Hooks for all fields

    const [data, setData] = useState({location:"",name:"",status:"",installed:"",});
    const [chargerinputList, setChargerInputList] = useState([{ chargeboxid: "", name: "", make: "", model: "", serialno: "", capacity: "", type: "", ingress: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", amcstart: "", amcend: "", amcperiod: "", description: "" }]);
    const [modeminputList, setModemInputList] = useState([{ make: "", model: "", serialno: "", ssidname: "", pin: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    const [networkinputList, setNetworkInputList] = useState([{ networkprovider: "", simtype: "", imeinumber: "", warrantystart: "", warrantyend: "", warrantydurationend: "", description: "" }]);
    const [powercabinetinputList, setPowerCabinetInputList] = useState([{ name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    const [switchgearinputList, setSwitchgearInputList] = useState([{ name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    const [rfidreaderinputList, setrfidReaderInputList] = useState([{ name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    const [cableinputList, setCableInputList] = useState([{ name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", ccore: "", corearea: "", material: "", cablebody: "" }]);
    const [posinputList, setPOSInputList] = useState([{ name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    const [batteryinputList, setBatteryInputList] = useState([{ name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    const [otherinputList, setOtherInputList] = useState([{ name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    

  

    
    // Getting Data Through useEffect

    useEffect(() => {
        props.getSingleAsset(assetid);
        props.getLocation();
    }, []);

    useEffect(() => {
        setData(props.assetsingledetail)
    }, [props.assetsingledetail])

    useEffect(() => {
        setChargerInputList(props.assetsingledetail.charger)
    },[props.assetsingledetail.charger])

    useEffect(() => {
        setModemInputList(props.assetsingledetail.modem)
    },[props.assetsingledetail.modem])

    useEffect(() => {
        setNetworkInputList(props.assetsingledetail.network)
    },[props.assetsingledetail.network])

    useEffect(() => {
        setPowerCabinetInputList(props.assetsingledetail.Powercabinet)
    },[props.assetsingledetail.Powercabinet])

    useEffect(() => {
        setSwitchgearInputList(props.assetsingledetail.switchgear)
    },[props.assetsingledetail.switchgear])
    useEffect(() => {
        setrfidReaderInputList(props.assetsingledetail.rfidreader)
    },[props.assetsingledetail.rfidreader])
    useEffect(() => {
        setCableInputList(props.assetsingledetail.cable)
    },[props.assetsingledetail.cable])
    useEffect(() => {
        setPOSInputList(props.assetsingledetail.pos)
    },[props.assetsingledetail.pos])
    useEffect(() => {
        setBatteryInputList(props.assetsingledetail.battery)
    },[props.assetsingledetail.battery])
    useEffect(() => {
        setOtherInputList(props.assetsingledetail.otherasset)
    },[props.assetsingledetail.otherasset])
  


// Handel Chnage of all Input and dropdown 

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    const handleChargerInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...chargerinputList];
        list[index][name] = value;
        setChargerInputList(list);
    };
    const handleModemInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...modeminputList];
        list[index][name] = value;
        setModemInputList(list);
    };
    const handleNetworkInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...networkinputList];
        list[index][name] = value;
        setNetworkInputList(list);
    };
    const handlePowerCabinetInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...powercabinetinputList];
        list[index][name] = value;
        setPowerCabinetInputList(list);
    };
    const handleSwitchgearInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...switchgearinputList];
        list[index][name] = value;
        setSwitchgearInputList(list);
    };
    const handleRFIDReaderInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...rfidreaderinputList];
        list[index][name] = value;
        setrfidReaderInputList(list);
    };
    const handleCableInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...cableinputList];
        list[index][name] = value;
        setCableInputList(list);
    };
    const handlePOSInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...posinputList];
        list[index][name] = value;
        setPOSInputList(list);
    };
    const handleBatteryInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...batteryinputList];
        list[index][name] = value;
        setBatteryInputList(list);
    };
    const handleOtherInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...otherinputList];
        list[index][name] = value;
        setOtherInputList(list);
    };



    // Handel Change of all Remove button

    const handleRemoveChargerClick = (e, index) => {
        e.preventDefault()
        const list = [...chargerinputList];
        list.splice(index, 1);
        setChargerInputList(list);
    };
    const handleRemoveModemClick = (e, index) => {
        e.preventDefault()
        const list = [...modeminputList];
        list.splice(index, 1);
        setModemInputList(list);
    };
    const handleRemoveNetworkClick = (e, index) => {
        e.preventDefault()
        const list = [...networkinputList];
        list.splice(index, 1);
        setNetworkInputList(list);
    };
    const handleRemovePowerCabinetClick = (e, index) => {
        e.preventDefault()
        const list = [...powercabinetinputList];
        list.splice(index, 1);
        setPowerCabinetInputList(list);
    };
    const handleRemoveSwitchgearCabinetClick = (e, index) => {
        e.preventDefault()
        const list = [...switchgearinputList];
        list.splice(index, 1);
        setSwitchgearInputList(list);
    };
    const handleRemoveRFIDClick = (e, index) => {
        e.preventDefault()
        const list = [...rfidreaderinputList];
        list.splice(index, 1);
        setrfidReaderInputList(list);
    };
    const handleRemoveCableClick = (e, index) => {
        e.preventDefault()
        const list = [...cableinputList];
        list.splice(index, 1);
        setCableInputList(list);
    };
    const handleRemovePOSClick = (e, index) => {
        e.preventDefault()
        const list = [...posinputList];
        list.splice(index, 1);
        setPOSInputList(list);
    };
    const handleRemoveBatteryClick = (e, index) => {
        e.preventDefault()
        const list = [...batteryinputList];
        list.splice(index, 1);
        setBatteryInputList(list);
    };
    const handleRemoveOtherClick = (e, index) => {
        e.preventDefault()
        const list = [...otherinputList];
        list.splice(index, 1);
        setOtherInputList(list);
    };

    //  Handel Change of all Add Button

    const handleAddChargerClick = () => {
        setChargerInputList([...chargerinputList, { chargeboxid: "", name: "", make: "", model: "", serialno: "", capacity: "", type: "", ingress: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", amcstart: "", amcend: "", amcperiod: "", description: "" }]);
    };
    const handleAddModemClick = () => {
        setModemInputList([...modeminputList, { make: "", model: "", serialno: "", ssidname: "", pin: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    };
    const handleAddNetworkClick = () => {
        setNetworkInputList([...networkinputList, { networkprovider: "", simtype: "", imeinumber: "", warrantystart: "", warrantyend: "", warrantydurationend: "", description: "" }]);
    };
    const handleAddPowerCabinetClick = () => {
        setPowerCabinetInputList([...powercabinetinputList, { name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    };
    const handleAddSwitchgearClick = () => {
        setSwitchgearInputList([...switchgearinputList, { name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    };
    const handleAddRFIDClick = () => {
        setrfidReaderInputList([...rfidreaderinputList, { name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    };
    const handleAddCableClick = () => {
        setCableInputList([...cableinputList, { name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", ccore: "", corearea: "", material: "", cablebody: "" }]);
    };
    const handleAddPOSClick = () => {
        setPOSInputList([...posinputList, { name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    };
    const handleAddBatteryClick = () => {
        setBatteryInputList([...batteryinputList, { name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    };
    const handleAddOtherClick = () => {
        setOtherInputList([...otherinputList, { name: "", make: "", model: "", serialno: "", warrantystart: "", warrantyend: "", warrantyduration: "", warrantydurationend: "", description: "" }]);
    };
    
    
    // Handel Submit and passing the Parameter of all input field

    const handleSubmit = (e) => {
        props.updateAsset(assetid, data, chargerinputList, modeminputList, networkinputList, powercabinetinputList, switchgearinputList,rfidreaderinputList,cableinputList, posinputList, batteryinputList, otherinputList);
        alert('Update Successfully');
        history.push("/dashboard/assets")
    };

    // Body of the the page

    return (
        <>
             <div className="container-fluid">
                <div className="sideCardDesigns ">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>Update Asset</b>
                        </h4>
                        <form className="form mt-3" onSubmit={handleSubmit} autoComplete="off">
                            <div className="row rowpadding   justify-content-between">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer">
                                        <div className="col-12">

                                            <label className="formLable" htmlFor="location">Asset Location</label>
                                            <div className="form-label-group ">
                                                <select className="form-control border-radius" id="location" name="location" onChange={handleChange} value={data.location}>
                                                    <option value="">{data.location}</option>
                                                     {props.locations === null ? <option value="none">none</option> : props.locations && props.locations.map(location =>
                                                    <option key={location.id} value={location.name}>{location.name}</option>
                                                )}

                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="name">Asset Name</label>
                                            <div className="form-label-group">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="form-control control-label"
                                                    placeholder="Enter Asset Name"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.name}
                                                />
                                                <label htmlFor="name">Enter Asset Name</label>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">

                                        <div className="col-12 ">

                                            <label className="formLable" htmlFor="status">Asset Status</label>
                                            <div className="form-label-group">
                                                <select className="form-control border-radius" id="status" name="status" required value={data.status} onChange={handleChange}>
                                                    <option value="">Select Status</option>
                                                    <option>Commissioned</option>
                                                    <option>Coming Soon</option>
                                                </select>
                                            </div>

                                            <label className="formLable" htmlFor="installed">Asset Installed</label>
                                            <div className="form-label-group">
                                                <select className="form-control border-radius" id="installed" name="installed" required value={data.installed} onChange={handleChange}>

                                                    <option value="">Asset Installed</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </select>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>


                            <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add Charger</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Charge Box ID</th>
                                                <th scope="col">Charger Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Charger Capacity kW</th>
                                                <th scope="col">Charger Type</th>
                                                <th scope="col">Ingress Protection</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Period (Years)</th>
                                                <th scope="col">AMC Start Date</th>
                                                <th scope="col">AMC End Date</th>
                                                <th scope="col">AMC Period</th>
                                                <th scope="col">Other Information (Optional)</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {chargerinputList && chargerinputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><input type="text" className="" name="chargeboxid" placeholder="Chargebox ID" value={x.chargeboxid} onChange={e => handleChargerInputChange(e, i)} /></td>
                                                        <td><select className="border-radius" name="name" value={x.name} onChange={e => handleChargerInputChange(e, i)} >
                                                            <option>Charger Name</option>
                                                            <option>CG001</option>
                                                            <option>CG002</option>
                                                            <option>CG003</option>
                                                            <option>CG004</option>
                                                            <option>CG005</option>
                                                            <option>CG006</option>
                                                            <option>CG007</option>
                                                            <option>CG008</option>
                                                            <option>CG009</option>
                                                            <option>CG010</option>
                                                            <option>CG011</option>
                                                            <option>CG012</option>
                                                            <option>CG013</option>
                                                            <option>CG014</option>
                                                            <option>CG015</option>
                                                            <option>CG016</option>
                                                            <option>CG017</option>
                                                            <option>CG018</option>
                                                            <option>CG019</option>
                                                            <option>CG020</option>
                                                            <option>CG021</option>
                                                            <option>CG022</option>
                                                            <option>CG023</option>
                                                            <option>CG024</option>
                                                            <option>CG025</option>
                                                            <option>CG026</option>
                                                        </select></td>
                                                        <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="serialno" placeholder="Serial No.(Machine)" value={x.serialno} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="capacity" placeholder="Charger Capacity kW" value={x.capacity} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td>
                                                            <select className="border-radius" name="type" value={x.type} onChange={e => handleChargerInputChange(e, i)} >
                                                                <option>Charger Type</option>
                                                                <option>AC</option>
                                                                <option>DC</option>
                                                            </select>
                                                        </td>
                                                        <td><input type="text" name="ingress" placeholder="Ingress Protection" value={x.ingress} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="warrantyduration" placeholder="Warranty Period(Years)" value={x.warrantyduration} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="amcstart" min="1000-01-01" max="3000-12-31" placeholder="AMC Start Date" className="form-control" value={x.amcstart} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="amcend" min="1000-01-01" max="3000-12-31" placeholder="AMC End Date" className="form-control" value={x.amcend} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="amcperiod" placeholder="AMC Period" value={x.amcperiod} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="description" placeholder="Other Information (Optional)" value={x.description} onChange={e => handleChargerInputChange(e, i)} /> </td>
                                                        <td>
                                                            {chargerinputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveChargerClick(e, i)}>-</button>}
                                                            {chargerinputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddChargerClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add Modem</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">SSID Name</th>
                                                <th scope="col">WIFI Password/Pin</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Period (Years)</th>
                                                <th scope="col">Other Information (Optional)</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {modeminputList && modeminputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handleModemInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handleModemInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="serialno" placeholder="Serial No" value={x.serialno} onChange={e => handleModemInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="ssidname" placeholder="SSID Name" value={x.ssidname} onChange={e => handleModemInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="pin" placeholder="WIFI Password/Pin" value={x.pin} onChange={e => handleModemInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handleModemInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handleModemInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="warrantyduration" placeholder="Warranty Period (Years)" value={x.warrantyduration} onChange={e => handleModemInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="description" placeholder="Other Information (Optional)" value={x.description} onChange={e => handleModemInputChange(e, i)} /> </td>
                                                        <td>
                                                            {modeminputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveModemClick(e, i)}>-</button>}
                                                            {modeminputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddModemClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add Network</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Network Provider</th>
                                                <th scope="col">Sim type</th>
                                                <th scope="col">IMEI Number</th>
                                                <th scope="col">Sim Activaton Date</th>
                                                <th scope="col">Recharge Expiry Date</th>
                                                <th scope="col">Other Information (Optional)</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {networkinputList && networkinputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td>
                                                            <select className="border-radius" name="networkprovider" value={x.networkprovider} onChange={e => handleNetworkInputChange(e, i)} >
                                                                <option>Network Provider</option>
                                                                <option>Vi</option>
                                                                <option>Airtel</option>
                                                                <option>Jio</option>
                                                                <option>Global Sim</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select className="border-radius" name="simtype" value={x.simtype} onChange={e => handleNetworkInputChange(e, i)} >
                                                                <option>Sim type</option>
                                                                <option>Celllular</option>
                                                                <option>IOT</option>
                                                                <option>Data Sim</option>
                                                            </select>
                                                        </td>
                                                        <td><input type="text" name="imeinumber" placeholder="IMEI Number" value={x.imeinumber} onChange={e => handleNetworkInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handleNetworkInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handleNetworkInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="description" placeholder="Other Information (Optional)" value={x.description} onChange={e => handleNetworkInputChange(e, i)} /> </td>
                                                        <td>
                                                            {networkinputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveNetworkClick(e, i)}>-</button>}
                                                            {networkinputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddNetworkClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add Power Cabinet</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {powercabinetinputList && powercabinetinputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td><input type="text" name="name" placeholder="Name" value={x.name} onChange={e => handlePowerCabinetInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handlePowerCabinetInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handlePowerCabinetInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="serialno" placeholder="Serial No" value={x.serialno} onChange={e => handlePowerCabinetInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handlePowerCabinetInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handlePowerCabinetInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="warrantyduration" placeholder="Warranty Period/AMC (Years)" value={x.warrantyduration} onChange={e => handlePowerCabinetInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="description" placeholder="Other Information (Optional)" value={x.description} onChange={e => handlePowerCabinetInputChange(e, i)} /> </td>
                                                        <td>
                                                            {powercabinetinputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemovePowerCabinetClick(e, i)}>-</button>}
                                                            {powercabinetinputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddPowerCabinetClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add Switchgear</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {switchgearinputList && switchgearinputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td><input type="text" name="name" placeholder="Name" value={x.name} onChange={e => handleSwitchgearInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handleSwitchgearInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handleSwitchgearInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="serialno" placeholder="Serial No" value={x.serialno} onChange={e => handleSwitchgearInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handleSwitchgearInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handleSwitchgearInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="warrantyduration" placeholder="Warranty Period/AMC (Years)" value={x.warrantyduration} onChange={e => handleSwitchgearInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="description" placeholder="Other Information (Optional)" value={x.description} onChange={e => handleSwitchgearInputChange(e, i)} /> </td>
                                                        <td>
                                                            {switchgearinputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveSwitchgearCabinetClick(e, i)}>-</button>}
                                                            {switchgearinputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddSwitchgearClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>


                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add RFID Reader</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rfidreaderinputList && rfidreaderinputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td><input type="text" name="name" placeholder="Name" value={x.name} onChange={e => handleRFIDReaderInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handleRFIDReaderInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handleRFIDReaderInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="serialno" placeholder="Serial No" value={x.serialno} onChange={e => handleRFIDReaderInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handleRFIDReaderInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handleRFIDReaderInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="warrantyduration" placeholder="Warranty Period/AMC (Years)" value={x.warrantyduration} onChange={e => handleRFIDReaderInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="description" placeholder="Other Information (Optional)" value={x.description} onChange={e => handleRFIDReaderInputChange(e, i)} /> </td>
                                                        <td>
                                                            {rfidreaderinputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveRFIDClick(e, i)}>-</button>}
                                                            {rfidreaderinputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddRFIDClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add Cable</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Cable Core</th>
                                                <th scope="col">Cable core Area (sq. mm)</th>
                                                <th scope="col">Material</th>
                                                <th scope="col">Cable Body</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cableinputList && cableinputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td><input type="text" name="name" placeholder="Name" value={x.name} onChange={e => handleCableInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handleCableInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handleCableInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="serialno" placeholder="Serial No" value={x.serialno} onChange={e => handleCableInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handleCableInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handleCableInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="warrantyduration" placeholder="Warranty Period/AMC (Years)" value={x.warrantyduration} onChange={e => handleCableInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="ccore" placeholder="Cable Core" value={x.ccore} onChange={e => handleCableInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="corearea" placeholder="Cable core Area" value={x.corearea} onChange={e => handleCableInputChange(e, i)} /> </td>
                                                        <td>
                                                            <select className="border-radius" name="material" value={x.material} onChange={e => handleCableInputChange(e, i)} >
                                                                <option>Aluminium</option>
                                                                <option>Copper</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select className="border-radius" name="cablebody" value={x.cablebody} onChange={e => handleCableInputChange(e, i)} >
                                                                <option>Armoured</option>
                                                                <option>Flexible</option>
                                                            </select>
                                                        </td>


                                                        <td>
                                                            {cableinputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveCableClick(e, i)}>-</button>}
                                                            {cableinputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddCableClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add POS</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {posinputList && posinputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td><input type="text" name="name" placeholder="Name" value={x.name} onChange={e => handlePOSInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handlePOSInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handlePOSInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="serialno" placeholder="Serial No" value={x.serialno} onChange={e => handlePOSInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handlePOSInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handlePOSInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="warrantyduration" placeholder="Warranty Period/AMC (Years)" value={x.warrantyduration} onChange={e => handlePOSInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="description" placeholder="Other Information (Optional)" value={x.description} onChange={e => handlePOSInputChange(e, i)} /> </td>
                                                        <td>
                                                            {posinputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemovePOSClick(e, i)}>-</button>}
                                                            {posinputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddPOSClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add Battery</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {batteryinputList && batteryinputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td><input type="text" name="name" placeholder="Name" value={x.name} onChange={e => handleBatteryInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handleBatteryInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handleBatteryInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="serialno" placeholder="Serial No" value={x.serialno} onChange={e => handleBatteryInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handleBatteryInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handleBatteryInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="warrantyduration" placeholder="Warranty Period/AMC (Years)" value={x.warrantyduration} onChange={e => handleBatteryInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="description" placeholder="Other Information (Optional)" value={x.description} onChange={e => handleBatteryInputChange(e, i)} /> </td>
                                                        <td>
                                                            {batteryinputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveBatteryClick(e, i)}>-</button>}
                                                            {batteryinputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddBatteryClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Add Other Asset</li>
                            </ol>
                        </nav>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Make</th>
                                                <th scope="col">Model</th>
                                                <th scope="col">Serial No</th>
                                                <th scope="col">Warranty Start</th>
                                                <th scope="col">Warranty End</th>
                                                <th scope="col">Warranty Duration</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {otherinputList && otherinputList.map((x, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td><input type="text" name="name" placeholder="Name" value={x.name} onChange={e => handleOtherInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handleOtherInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handleOtherInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="serialno" placeholder="Serial No" value={x.serialno} onChange={e => handleOtherInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantystart" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantystart} onChange={e => handleOtherInputChange(e, i)} /> </td>
                                                        <td><input type="date" name="warrantyend" min="1000-01-01" max="3000-12-31" className="form-control" value={x.warrantyend} onChange={e => handleOtherInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="warrantyduration" placeholder="Warranty Period/AMC (Years)" value={x.warrantyduration} onChange={e => handleOtherInputChange(e, i)} /> </td>
                                                        <td><input type="text" name="description" placeholder="Other Information (Optional)" value={x.description} onChange={e => handleOtherInputChange(e, i)} /> </td>
                                                        <td>
                                                            {otherinputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveOtherClick(e, i)}>-</button>}
                                                            {otherinputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddOtherClick}>+</button>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                           
                            <div className="row">
                                <div className="col-md-3 col-lg-3 ">
                                    <a className=" btn columnsBtn  btn-pill btn-wide text-uppercase float-left" href="/dashboard/assets">Cancel</a>
                                </div>
                                <div className="col-md-9 col-lg-9 ">
                                    <button className=" btn columnsBtn btn-pill btn-wide text-uppercase float-right" >{!props.loading ? 'Update  Assets' : 'Please wait...'}</button>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
            </div>


        </>

            
        )

}

const mapStateToProps = function (state) {
    return {

        // Reducer 

        loading: state.asset.isLoading,
        assetsingledetail: state.asset.fetchSingleAsset,
        locations: state.location.fetchLocation,
        updateAssetById : state.asset.updateAssetById

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

        // Action 

        getSingleAsset: (assetid) => dispatch(getSingleAssetActions(assetid)),
        getLocation: () => dispatch(getLocationAction()),
        updateAsset: (assetid, data, chargerinputList, modeminputList, networkinputList, powercabinetinputList, switchgearinputList,rfidreaderinputList,cableinputList, posinputList, batteryinputList, otherinputList) => dispatch(updateAssetAction(assetid, data, chargerinputList, modeminputList, networkinputList, powercabinetinputList, switchgearinputList,rfidreaderinputList,cableinputList, posinputList, batteryinputList, otherinputList)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAsset); 
