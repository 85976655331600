import React, { Component } from "react";
import {connect  } from "react-redux";
import { changeAction } from "../../store/actions/authAction";
import { Redirect } from "react-router-dom";
import validator from 'validator';
import { logoutAction } from "../../store/actions/authAction";  
import Swal from 'sweetalert2'
class UpdatePassComponent extends Component{
  
  formDefaults = {
  password: { value:'', isValid: true, message: '' },
  newPassword: { value: '', isValid: true, message: '' },
  confirmPassword: { value: '', isValid: true, message: '' },
  error:{value:'',isValid:true,message:''}  
  }
  state={
     ...this.formDefaults,
  }

  //handlechange
  handleChange=(e)=>{
      const state = {
        ...this.state,
        [e.target.name]: {
          ...this.state[e.target.name],
          value: e.target.value,
        }
      };

      this.setState(state);
    }

    //submitform
  handleSubmit=(e)=>{
    e.preventDefault();
    this.resetValidationStates();
    if (this.formIsValid()) {
      const newPassword = { ...this.state.newPassword };
      const {username, token}= JSON.parse(localStorage.getItem('user'))
      this.props.updatePassData(username,newPassword.value,token);
      localStorage.removeItem('user');
      Swal.fire({
        icon: 'success',
        title: 'Your password has been successfully changed',
        showConfirmButton: false,
        timer: 3000
      })
  
      setTimeout(
        function() {
          window.location.replace("/")
        }, 3000);
    }
     

  }

  //form validation
  formIsValid = () => {
    const password = { ...this.state.password };
    const newPassword = { ...this.state.newPassword };
    const confirmPassword = { ...this.state.confirmPassword };
    const error={...this.state}
    let isGood = true;
    if (validator.isEmpty(password.value)) {
      password.isValid=false
      password.message='Password is required'
      isGood = false;
    }
    if (validator.isEmpty(newPassword.value)) {
      newPassword.isValid=false
      newPassword.message='Password is required'
      isGood = false;
    }
    if (validator.isEmpty(confirmPassword.value)) {
      confirmPassword.isValid=false
      confirmPassword.message='Password is required'
      isGood = false;
    }
    if(newPassword.value!==confirmPassword.value){
      error.isValid=false;
      error.message='Passwords do not match.'
      isGood = false;
    }
    if (!isGood) {
      this.setState({
        error,
        password,
        newPassword,
        confirmPassword,
      });
    }

    return isGood;
  }

  //reste validation
  resetValidationStates = () => {
    // make a copy of everything in state
    const state = JSON.parse(JSON.stringify(this.state));
    Object.keys(state).map(key => {
      if (state[key].hasOwnProperty('isValid')) {
        state[key].isValid = true;
        state[key].message = '';
      }
    });
    this.setState(state);
  }
  
  render(){
      const { password,newPassword, confirmPassword,error } = this.state;
      if (this.props.response && (this.props.response.ispasswordupdated && this.props.response.error==='')) {
        return <Redirect to='/dashboard'/>;
      }
         
        return(
            <div className="container">
            <div className="row">
             <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
               <div className="card card-signin my-5">
                 <div className="card-body">
                   <h5 className="card-title text-center">Update Password</h5>
                   <form className="form-signin" onSubmit={this.handleSubmit}>       
                     <div className="form-label-group required">
                       <input type="password" id="password" name="password" className="form-control" placeholder="Current password" onChange={this.handleChange}/>
                       <label htmlFor="password">Current password</label>
                            <span className="help-block text-danger ml-1">{password.message}</span>
                     </div>
                     <div className="form-label-group required">
                       <input type="password" id="newPassword" name="newPassword" className="form-control" placeholder="New password" onChange={this.handleChange}/>
                       <label htmlFor="newPassword">New password</label>
                       <span className="help-block text-danger ml-1">{newPassword.message}</span>
                     </div>
                     <div className="form-label-group required">
                       <input type="password" id="confirmPassword" name="confirmPassword" className="form-control" placeholder="Confirm new password" onChange={this.handleChange}/>
                       <label htmlFor="confirmPassword">Confirm new password</label>
                       <span className="help-block text-danger ml-1">{confirmPassword.message}</span>
                     </div>
                     <div className="form-label-group">
                        <span className="help-block text-danger">{error.message}</span>
                     </div>
                      <button className="btn btn-lg btn-primary btn-block text-uppercase">{!this.props.loading?'Update Password':'Please wait...'}</button>
                     
                    </form>
                 </div>
               </div>
             </div>
           </div>
         </div>
        
        )
    }
}
//call  reducer from Redux store

const mapStateToProps = (state) =>{
    return{
      response:state.auth.user,
      loading:state.auth.isLoading
    }
  }

  //call  action from Redux store

  const mapDispatchToProps=dispatch =>{
      return{
        updatePassData: (username,password,token) => dispatch(changeAction(username,password,token))
      }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(UpdatePassComponent)