import React, { useMemo, useEffect } from "react"
import moment from "moment";
import "../../report/table.css"
import { connect } from "react-redux";
import { getRFIDListAction,deactivateAction } from "../../../../store/actions/customerAction";
import Table from "./rfidtable";
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2';

const RFIDTableView=(props)=>{
  useEffect(()=>{
    props.getRFIDdata();
    
  },[])
  const user = JSON.parse(localStorage.getItem('user'));
  const activateDeactivateRfid=(docID,active)=>{

  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#1e295d',
    confirmButtonText: 'Yes!'
  }).then((result) => {
    if (result.value) {
     
      props.deactivateRFID(docID,active);
      window.location.reload();
    }
  });
}

const data = props.rfidlist && props.rfidlist
// console.log("tabledata", data)

    const columns = useMemo(

        () => [
          {
            header: 'RFID Number',
            accessor: 'rfidno'
          },
          {
            header: 'First Name',
            accessor: 'firstname'
          },
          {
            header: 'Tag UID',
            accessor: 'taguid'
          },
          {
            header: 'Key UID',
            accessor: 'keyuid'
          },
          {
            header: 'TopUp Amount',
            accessor: 'topup'
          },
          
          {
            header: 'Created At',
            id: 'createdat',
        accessor: d => {
          return moment(d.createdat)
            .local()
            .format("DD-MM-YYYY");
        }
          },
          {
            header: 'Updated At',
            id: 'updatedat',
        accessor: d => {
          return moment(d.updatedat)
            .local()
            .format("DD-MM-YYYY");
        }
          },
    
          {
            header: 'Status',
            id:"active",
            accessor: s => {
              return s.active ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
            }
        },
    
        {
          header: 'Actions',
          id:'action',
          accessor: s=> {
            return (
              
              <div>
                <span>
                <a  href={`/dashboard/assets/viewsinglerfid/${s.id}`} rel="tooltip" title="View " className="table-action view">
                <i className="fas fa-eye"></i>
                </a></span>
                &nbsp;&nbsp;&nbsp;
                <span>
                <a  href={`/dashboard/assets/editrfid/${s.id}`} rel="tooltip" title="Edit " className="table-action edit">
                <i className="fas fa-edit"></i>
                </a></span>
                &nbsp;&nbsp;&nbsp;
                {user.role === "Admin" ? (
                    <span>
                    {s.active ? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash"  onClick={() => activateDeactivateRfid(s.id, false) } >
                        <i className="fas fa-user-check"></i></a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active" onClick={() => activateDeactivateRfid(s.id, true)}>
                    <i className="fas fa-user-slash"></i></a>}
                </span>
                ):( <span ></span>)
              }
                &nbsp;&nbsp;&nbsp;

              </div>
  
            );
          }
          },
  
        ],
        []
      );
    useEffect(()=>{
        props.getRFIDdata();
    },[])
    return(
        <div className="row py-2">
        <div className="col">
 
        <div className="row py-2">
        <div className="col-md-12 col-lg-12">
        <div className="mb-3 h-100 sideCardDesigns">
        <div className="card-header singleCard">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
          <strong>RFID Details</strong>
        </div>
        
        </div>
    
        {!data.length ?
          (<div style={{display: "flex",  alignItems: "center",justifyContent: "center"}}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{display: "flex",  alignItems: "center",justifyContent: "center"}} /></h3></div>) 
     : 
    (<div className="App"> <Table data={data} columns={columns}></Table></div>) }
    
    
    </div>
  </div>
  </div>  </div>
  </div>

    )
}

const mapStateToProps = (state) =>{
    return{
         rfidlist:state.customer.fetchAllRfid,
         response:state.customer.deactivateRFID,
    }
  }
  const mapDispatchToProps=dispatch =>{
    return{
      getRFIDdata:(docID)=> dispatch(getRFIDListAction(docID)),
      deactivateRFID:(docID,active)=>dispatch(deactivateAction(docID,active))
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(RFIDTableView);