import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import {
  BrowserRouter as Router, Switch, Route, Redirect, useLocation
} from 'react-router-dom'
import { GrConfigure } from "react-icons/gr";
import { BiReset } from "react-icons/bi";
import { ImUnlocked } from "react-icons/im";
import { MdCached, MdTouchApp } from "react-icons/md";
import { FcDataConfiguration } from "react-icons/fc";
import { RiExchangeLine } from "react-icons/ri"
import { RiTimer2Fill } from "react-icons/ri"
import { FaRocket } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { editStationAction, getConfigurationAction, clearCacheAction, unlockConnectorAction, changeConfigurationAction, triggerConnectorAction, resetChargerAction, updateQRNumber, triggerChangeAvailability, releaseStuckBusyAction, deactivechargervisibilityAction } from "../../../store/actions/stationAction";
import { releaseChargerAction, releaseStuckTransactionAction } from "../../../store/actions/transactionAction";
import Popup from './Popup';
import "./station.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const UpdateStation = (props) => {
  const [inputChangeConfig, setChangeConfig] = useState([{ Key: "", Value: "" }])
  const [inputQrNumber, setInputQrNumber] = useState([{ chargeboxid: "", qrnumber: "" }])
  const { stationid, location } = useParams()
  const [changeConfig, setchangeConfig] = useState([{ Key: "", Value: "" }])
  const userrole = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    props.EditStationByName(stationid, location);

  }, []);

  const handleClick = (connector) => {
    // console.log(connector)
    props.GetConfigByID(connector.chargeboxid);
  };
  const handleCache = (connector) => {
    props.ClearCacheByID(connector.chargeboxid);
  };
  const handleUnlocked = (connectorno, chargeboxid) => {
    props.UnlockByID(connectorno, chargeboxid)
  }
  const handleChangeConfig = (chargeboxid, key, value) => {
    props.ChangeConfigByID(chargeboxid, changeConfig.Key, changeConfig.Value)
    // console.log("Key:- ", changeConfig.Key, "Value:- ", changeConfig.Value, "chargeboxid--", chargeboxid)
  }
  const handleReset = (connector, Resettype) => {
    // console.log("Reset--", Resettype)
    props.ResetByID(connector.chargeboxid, Resettype)
  }
  const handletrigger = (connectorno, chargeboxid, RequestedMessage) => {
    // console.log("triggercommand", connectorno, chargeboxid, RequestedMessage)
    props.TriggerByID(connectorno, chargeboxid, RequestedMessage)
  }

  const handlechangeavailability = (connectorno, chargeboxid, availabilitytype) => {
    // console.log("triggeravailability", connectorno, chargeboxid, availabilitytype)
    props.TriggerChangeAvailability(connectorno, chargeboxid, availabilitytype)
  }

  const handleRelease = (connectorno, chargeboxid) => {
    // console.log("handleRelease", connectorno, chargeboxid)
    props.ReleaseCharger(connectorno, chargeboxid)
  }

  const handleDeactive = (chargeboxid, stationid, deactive) => {
    // console.log("handleRelease", connectorno, chargeboxid)
    props.DeactiveCharger(chargeboxid, stationid, deactive);
    window.location.reload();

  }

  const handleStuckTransaction = (connectorno, chargeboxid) => {
    // console.log("handleRelease", connectorno, chargeboxid)
    props.ReleaseStuckTransaction(connectorno, chargeboxid);
    window.location.reload();

  }

  const handleStuckBusy = (connectorno, chargeboxid) => {
    // console.log("handleRelease", connectorno, chargeboxid)
    props.ReleaseStuckBusy(connectorno, chargeboxid);
    window.location.reload();

  }




  const handleQrNumber = (chargeboxid, qrnumber) => {
    props.UpdateQrNumber(chargeboxid, inputQrNumber.qrnumber)
    // console.log("QR", inputQrNumber.qrnumber)
  };

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const [isTOpen, setIsTOpen] = useState(false);
  const toggleResetPopup = () => {
    setIsTOpen(!isTOpen);
  }
  const [isTrOpen, setIsTrOpen] = useState(false);
  const [isAvOpen, setIsAvOpen] = useState(false);
  const toggleTriggerPopup = () => {
    setIsTrOpen(!isTrOpen);
  }

  const toggleAvailabilityPopup = () => {
    setIsAvOpen(!isAvOpen);
  }


  const handleQR1Change = (e) => {
    setInputQrNumber({
      ...inputQrNumber,
      [e.target.name]: e.target.value.trim()

    });
  }
  // const handleChangeConfigChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...changeConfig];
  //   list[index][name] = value;
  //   setchangeConfig(list);
  // };

  const handleChangeConfigChange = (e) => {
    setchangeConfig({
      ...changeConfig,
      [e.target.name]: e.target.value.trim()

    });
  }


  const notify = () => {
    toast("Command Triggered");
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row-fluid">
          <div className="col-md-12 col-xl-12">


            <div className="row gy-5 ">
              <div className="col-md-4 col-xl-4 col-sm-4 col-12">
                <div className="sideCardDesigns border-light mb-3 widget-content  ">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper p-3">
                      <div className="widget-content-left">
                        <div className="insideHeading">Station ID</div>
                      </div>
                      <div className="widget-content-right">
                        <div className="widget-numbers insideContent">
                          <strong>
                            {props.list != null ? props.list.stationid : 0}

                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xl-4 col-sm-4 col-12">
                <div className="sideCardDesigns border-light mb-3 widget-content ">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper p-3">
                      <div className="widget-content-left">
                        <div className="insideHeading">Location Name</div>
                      </div>
                      <div className="widget-content-right">
                        <div className="widget-numbers insideContent">
                          <strong>
                            {props.list != null ? props.list.name : 0}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xl-4 col-sm-4 col-12">
                <div className="sideCardDesigns border-light mb-3 widget-content ">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper p-3">
                      <div className="widget-content-left">
                        <div className="insideHeading">Commission Date</div>
                      </div>
                      <div className="widget-content-right">
                        <div className="widget-numbers insideContent">
                          <strong>
                            {props.list != null ? props.list.commisiondate : 0}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


        {props.list && props.list.chargers.map((charger) =>

          <>

            <div className="row-fluid">
              {(charger.deactive === true || charger.deactive === false) && userrole.role == "Admin" ?

                <div className="col">
                  <div className="row" key={charger.id}>
                    <div className="col-md-12 col-xl-12">
                      <div className="sideCardDesigns mb-3 widget-content">
                        <div className="sideCardDesigns-header">
                          <div className="card-header-title font-size-lg text-capitalize font-weight-bold formHeading">
                            <span>{charger.chargeboxid} - {charger.chargername}  - {charger.status}   | </span>
                            {charger.deactive == true ? <span style={{ color: 'Red' }}> Deactivated </span> : <span style={{ color: 'Green' }}>  Active  </span>}

                            {userrole.role === "Admin" ? (
                              <>
                                {charger.deactive == true ? <FaEyeSlash color="red" onClick={() => { handleDeactive(charger.stationid, charger.chargeboxid, charger.deactive = "false"); notify(); }}></FaEyeSlash> : <FaEye onClick={() => { handleDeactive(charger.stationid, charger.chargeboxid, charger.deactive = "true"); notify(); }}></FaEye>}
                              </>
                            ) : (<span ></span>)
                            }
                          </div>
                        </div>
                        {props.list && charger.connectors.map((connector) =>

                          <div className="table-responsive">
                            <table className="table table-bordered table-hover table-sm">
                              <thead>
                                <tr>


                                  <th scope="col"><center>Connector No</center></th>
                                  <th scope="col"><center>Connector Status</center></th>
                                  <th scope="col"><center>Connector Type</center></th>
                                  <th scope="col"><center>Reset Charger</center></th>
                                  {userrole.role === "Admin" ? (
                                    <>
                                      <th scope="col"><center>Get Configuration</center></th>
                                      <th scope="col"><center>Change Configuration</center></th>
                                    </>
                                  ) : (<span ></span>)
                                  }
                                  <th scope="col"><center>Unlock Charger</center></th>
                                  <th scope="col"><center>Clear Cache</center></th>
                                  <th scope="col"><center>Release Initialize</center></th>
                                  <th scope="col"><center>Release In progress</center></th>
                                  <th scope="col"><center>Release Busy to Available</center></th>
                                  {userrole.role === "Admin" ? (
                                    <>
                                      <th scope="col"><center>Trigger Command</center></th>
                                      <th scope="col"><center>Change Availability</center></th>
                                    </>
                                  ) : (<span ></span>)
                                  }
                                  <th scope="col"><center>QR Code</center></th>

                                </tr>
                              </thead>
                              <tbody>
                                {inputChangeConfig.map((x, i) => {
                                  return (
                                    <tr className="hover" key={connector.chargeboxid}>
                                      <td><center>{connector.connectorno}</center></td>
                                      <td><center>
                                        {connector.status === "Available"
                                          ? <span style={{ color: 'Green' }}> Available </span>
                                          : connector.status === "Maintenance"
                                            ? <span style={{ color: 'Gray' }}> Maintenance </span>
                                            : <span style={{ color: 'Red' }}> Busy </span>
                                        }
                                      </center></td>
                                      <td><center>{connector.connectortype}</center></td>
                                      <td><center><BiReset size="35px" color="red" onClick={toggleResetPopup} /></center>
                                        {isTOpen && <Popup
                                          content={<>
                                            <center> <button type="button" className="btn btn-outline-primary" onClick={() => { handleReset(connector, "Hard"); notify(); }}>Hard Reset</button>&nbsp;&nbsp;
                                              <button type="button" className="btn btn-outline-primary" onClick={() => { handleReset(connector, "Soft"); notify(); }}>Soft Reset</button></center>
                                          </>}
                                          handleClose={toggleResetPopup}
                                        />}</td>
                                      {userrole.role === "Admin" ? (
                                        <>
                                          <td><center><GrConfigure size="35px" color="green" onClick={() => { handleClick(connector); notify(); }} /></center></td>
                                          <td><center><FcDataConfiguration size="35px" color="black" onClick={togglePopup} /></center> {isOpen && <Popup
                                            content={<>
                                              <center>
                                                <input type="text" placeholder="Key" name="Key" onChange={handleChangeConfigChange}   ></input>&nbsp;&nbsp;
                                                <input type="text" placeholder="Value" name="Value" onChange={handleChangeConfigChange} ></input>

                                              </center><br />
                                              <center> <button type="button" className="btn btn-outline-primary" onClick={() => { handleChangeConfig(connector.chargeboxid, connector.Key, connector.Value); notify(); }}>Trigger</button></center>
                                            </>}
                                            handleClose={togglePopup}
                                          />}
                                          </td>
                                        </>
                                      ) : (<span ></span>)
                                      }
                                      <td><center><ImUnlocked size="35px" color="black" onClick={() => { handleUnlocked(connector.connectorno, connector.chargeboxid); notify(); }} /></center></td>

                                      <td><center><MdCached size="35px" color="black" onClick={() => { handleCache(connector); notify(); }} /></center></td>
                                      <td><center><FaRocket size="35px" color="black" onClick={() => { handleRelease(connector.connectorno, connector.chargeboxid); notify(); }} /></center></td>
                                      <td><center><RiTimer2Fill size="35px" color="black" onClick={() => { handleStuckTransaction(connector.connectorno, connector.chargeboxid); notify(); }} /></center></td>
                                      <td><center><FaCheckCircle size="35px" color="black" onClick={() => { handleStuckBusy(connector.connectorno, connector.chargeboxid); notify(); }} /></center></td>
                                      {userrole.role === "Admin" ? (
                                        <>
                                          <td><center><MdTouchApp size="35px" color="black" onClick={toggleTriggerPopup} /></center>
                                            {isTrOpen && <Popup
                                              content={<>
                                                <center>
                                                  <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "Heartbeat"); notify(); }}>HeartBeat</button>
                                                  <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "MeterValues"); notify(); }}>MeterValues</button>&nbsp;&nbsp;
                                                  <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "FirmwareStatusNotification"); notify(); }}>Firmware Status</button>&nbsp;&nbsp;
                                                  <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "BootNotification"); notify(); }}>Boot Notification</button>&nbsp;&nbsp;
                                                  <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "StatusNotification"); notify(); }}>Status Notification</button>
                                                  <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "DiagnosticsStatusNotification"); notify(); }}>Diagnostics Status</button>

                                                </center>
                                              </>}
                                              handleClose={toggleTriggerPopup}
                                            />}
                                          </td>
                                          <td><center><RiExchangeLine size="35px" color="black" onClick={toggleAvailabilityPopup} /></center>
                                            {isAvOpen && <Popup
                                              content={<>
                                                <center>
                                                  <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handlechangeavailability(connector.chargeboxid, connector.connectorno, "Operative"); notify(); }}>Operative</button>
                                                  <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handlechangeavailability(connector.chargeboxid, connector.connectorno, "Inoperative"); notify(); }}>Inoperative</button>&nbsp;&nbsp;
                                                </center>
                                              </>}
                                              handleClose={toggleAvailabilityPopup}
                                            />}
                                          </td>
                                        </>
                                      ) : (<span ></span>)
                                      }
                                      {/* <td><center><a href={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${connector.chargeboxid}-${connector.connectorno}`} target="_blank" rel="noreferrer noopener"><IoMdQrScanner size="35px" color="black" onClick={() => { notify(); }} /></a></center></td> */}
                                      <td>
                                        {/* <input type="text" placeholder="chargeboxid" value={connector.chargeboxid} onChange={e => handleQRinputChange(e, i)} disabled ></input>&nbsp;&nbsp; */}
                                        <input type="text" placeholder="QR Number" name="qrnumber" onChange={handleQR1Change} ></input><br /><br />
                                        <center> <button type="button" className="btn btn-outline-primary" onClick={() => { handleQrNumber(connector.chargeboxid, connector.qrnumber); notify(); }}>Trigger</button></center>

                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>

                            </table>

                          </div>

                        )}
                        <ToastContainer style={{ top: '3rem' }} />
                      </div>
                    </div>
                  </div>

                </div>
                : charger.deactive === false
                ?
                <div className="col">
                    <div className="row" key={charger.id}>
                      <div className="col-md-12 col-xl-12">
                        <div className="sideCardDesigns mb-3 widget-content">
                          <div className="sideCardDesigns-header">
                            <div className="card-header-title font-size-lg text-capitalize font-weight-bold formHeading">
                              <span>{charger.chargeboxid} - {charger.chargername}  - {charger.status}   | </span>
                              {charger.deactive == true ? <span style={{ color: 'Red' }}> Deactivated </span> : <span style={{ color: 'Green' }}>  Active  </span>}

                              {userrole.role === "Admin" ? (
                                <>
                                  {charger.deactive == true ? <FaEyeSlash color="red" onClick={() => { handleDeactive(charger.stationid, charger.chargeboxid, charger.deactive = "false"); notify(); }}></FaEyeSlash> : <FaEye onClick={() => { handleDeactive(charger.stationid, charger.chargeboxid, charger.deactive = "true"); notify(); }}></FaEye>}
                                </>
                              ) : (<span ></span>)
                              }
                            </div>
                          </div>
                          {props.list && charger.connectors.map((connector) =>

                            <div className="table-responsive">
                              <table className="table table-bordered table-hover table-sm">
                                <thead>
                                  <tr>


                                    <th scope="col"><center>Connector No</center></th>
                                    <th scope="col"><center>Connector Status</center></th>
                                    <th scope="col"><center>Connector Type</center></th>
                                    <th scope="col"><center>Reset Charger</center></th>
                                    {userrole.role === "Admin" ? (
                                      <>
                                        <th scope="col"><center>Get Configuration</center></th>
                                        <th scope="col"><center>Change Configuration</center></th>
                                      </>
                                    ) : (<span ></span>)
                                    }
                                    <th scope="col"><center>Unlock Charger</center></th>
                                    <th scope="col"><center>Clear Cache</center></th>
                                    <th scope="col"><center>Release Initialize</center></th>
                                    <th scope="col"><center>Release In progress</center></th>
                                    <th scope="col"><center>Release Busy to Available</center></th>
                                    {userrole.role === "Admin" ? (
                                      <>
                                        <th scope="col"><center>Trigger Command</center></th>
                                        <th scope="col"><center>Change Availability</center></th>
                                      </>
                                    ) : (<span ></span>)
                                    }
                                    <th scope="col"><center>QR Code</center></th>

                                  </tr>
                                </thead>
                                <tbody>
                                  {inputChangeConfig.map((x, i) => {
                                    return (
                                      <tr className="hover" key={connector.chargeboxid}>
                                        <td><center>{connector.connectorno}</center></td>
                                        <td><center>
                                          {connector.status === "Available"
                                            ? <span style={{ color: 'Green' }}> Available </span>
                                            : connector.status === "Maintenance"
                                              ? <span style={{ color: 'Gray' }}> Maintenance </span>
                                              : <span style={{ color: 'Red' }}> Busy </span>
                                          }
                                        </center></td>
                                        <td><center>{connector.connectortype}</center></td>
                                        <td><center><BiReset size="35px" color="red" onClick={toggleResetPopup} /></center>
                                          {isTOpen && <Popup
                                            content={<>
                                              <center> <button type="button" className="btn btn-outline-primary" onClick={() => { handleReset(connector, "Hard"); notify(); }}>Hard Reset</button>&nbsp;&nbsp;
                                                <button type="button" className="btn btn-outline-primary" onClick={() => { handleReset(connector, "Soft"); notify(); }}>Soft Reset</button></center>
                                            </>}
                                            handleClose={toggleResetPopup}
                                          />}</td>
                                        {userrole.role === "Admin" ? (
                                          <>
                                            <td><center><GrConfigure size="35px" color="green" onClick={() => { handleClick(connector); notify(); }} /></center></td>
                                            <td><center><FcDataConfiguration size="35px" color="black" onClick={togglePopup} /></center> {isOpen && <Popup
                                              content={<>
                                                <center>
                                                  <input type="text" placeholder="Key" name="Key" onChange={handleChangeConfigChange}   ></input>&nbsp;&nbsp;
                                                  <input type="text" placeholder="Value" name="Value" onChange={handleChangeConfigChange} ></input>

                                                </center><br />
                                                <center> <button type="button" className="btn btn-outline-primary" onClick={() => { handleChangeConfig(connector.chargeboxid, connector.Key, connector.Value); notify(); }}>Trigger</button></center>
                                              </>}
                                              handleClose={togglePopup}
                                            />}
                                            </td>
                                          </>
                                        ) : (<span ></span>)
                                        }
                                        <td><center><ImUnlocked size="35px" color="black" onClick={() => { handleUnlocked(connector.connectorno, connector.chargeboxid); notify(); }} /></center></td>

                                        <td><center><MdCached size="35px" color="black" onClick={() => { handleCache(connector); notify(); }} /></center></td>
                                        <td><center><FaRocket size="35px" color="black" onClick={() => { handleRelease(connector.connectorno, connector.chargeboxid); notify(); }} /></center></td>
                                        <td><center><RiTimer2Fill size="35px" color="black" onClick={() => { handleStuckTransaction(connector.connectorno, connector.chargeboxid); notify(); }} /></center></td>
                                        <td><center><FaCheckCircle size="35px" color="black" onClick={() => { handleStuckBusy(connector.connectorno, connector.chargeboxid); notify(); }} /></center></td>
                                        {userrole.role === "Admin" ? (
                                          <>
                                            <td><center><MdTouchApp size="35px" color="black" onClick={toggleTriggerPopup} /></center>
                                              {isTrOpen && <Popup
                                                content={<>
                                                  <center>
                                                    <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "Heartbeat"); notify(); }}>HeartBeat</button>
                                                    <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "MeterValues"); notify(); }}>MeterValues</button>&nbsp;&nbsp;
                                                    <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "FirmwareStatusNotification"); notify(); }}>Firmware Status</button>&nbsp;&nbsp;
                                                    <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "BootNotification"); notify(); }}>Boot Notification</button>&nbsp;&nbsp;
                                                    <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "StatusNotification"); notify(); }}>Status Notification</button>
                                                    <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handletrigger(connector.chargeboxid, connector.connectorno, "DiagnosticsStatusNotification"); notify(); }}>Diagnostics Status</button>

                                                  </center>
                                                </>}
                                                handleClose={toggleTriggerPopup}
                                              />}
                                            </td>
                                            <td><center><RiExchangeLine size="35px" color="black" onClick={toggleAvailabilityPopup} /></center>
                                              {isAvOpen && <Popup
                                                content={<>
                                                  <center>
                                                    <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handlechangeavailability(connector.chargeboxid, connector.connectorno, "Operative"); notify(); }}>Operative</button>
                                                    <button type="button" className="btn btn-wide btn-outline-primary" onClick={() => { handlechangeavailability(connector.chargeboxid, connector.connectorno, "Inoperative"); notify(); }}>Inoperative</button>&nbsp;&nbsp;
                                                  </center>
                                                </>}
                                                handleClose={toggleAvailabilityPopup}
                                              />}
                                            </td>
                                          </>
                                        ) : (<span ></span>)
                                        }
                                        {/* <td><center><a href={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${connector.chargeboxid}-${connector.connectorno}`} target="_blank" rel="noreferrer noopener"><IoMdQrScanner size="35px" color="black" onClick={() => { notify(); }} /></a></center></td> */}
                                        <td>
                                          {/* <input type="text" placeholder="chargeboxid" value={connector.chargeboxid} onChange={e => handleQRinputChange(e, i)} disabled ></input>&nbsp;&nbsp; */}
                                          <input type="text" placeholder="QR Number" name="qrnumber" onChange={handleQR1Change} ></input><br /><br />
                                          <center> <button type="button" className="btn btn-outline-primary" onClick={() => { handleQrNumber(connector.chargeboxid, connector.qrnumber); notify(); }}>Trigger</button></center>

                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>

                              </table>

                            </div>

                          )}
                          <ToastContainer style={{ top: '3rem' }} />
                        </div>
                      </div>
                    </div>

                </div>
                : null


              }

            </div>

          </>

        )}
        <div className="row">
          <div className="col-xl-12 col-sm-12 col-12  m-3">
            <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/stations">Back</a>
          </div>

        </div>

      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.station.editstationbyname,
    getconfig: state.station.getconfiguration,
    clearcache: state.station.clearcache,
    unlock: state.station.unloackconnector,
    changeconfig: state.station.changeconfiguration,
    reset: state.station.resetcharger,
    trigger: state.station.triggerconnector,
    release: state.transaction.releasecharger,
    qrnumber: state.station.updateQRnumber,
    changeavailability: state.station.changeavailability,
    releasestucktransaction: state.transaction.releasestucktransaction,
    releasestuckbusy: state.transaction.updatebusytoavailable,
    deactivecharger: state.station.deactivecharger,


  }
}

const mapDispatchToProps = dispatch => {
  return {
    EditStationByName: (stationid, location) => dispatch(editStationAction(stationid, location)),
    GetConfigByID: (chargeboxid) => dispatch(getConfigurationAction(chargeboxid)),
    ClearCacheByID: (chargeboxid) => dispatch(clearCacheAction(chargeboxid)),
    UnlockByID: (connectorno, chargeboxid) => dispatch(unlockConnectorAction(connectorno, chargeboxid)),
    ChangeConfigByID: (chargeboxid, Key, value) => dispatch(changeConfigurationAction(chargeboxid, Key, value)),
    ResetByID: (chargeboxid, Resettype) => dispatch(resetChargerAction(chargeboxid, Resettype)),
    TriggerByID: (chargeboxid, connectorno, RequestedMessage) => dispatch(triggerConnectorAction(chargeboxid, connectorno, RequestedMessage)),
    ReleaseCharger: (connectorno, chargeboxid) => dispatch(releaseChargerAction(connectorno, chargeboxid)),
    UpdateQrNumber: (chargeboxid, qrnumber) => dispatch(updateQRNumber(chargeboxid, qrnumber)),
    TriggerChangeAvailability: (chargeboxid, connectorno, availabilitytype) => dispatch(triggerChangeAvailability(chargeboxid, connectorno, availabilitytype)),
    ReleaseStuckTransaction: (connectorno, chargeboxid) => dispatch(releaseStuckTransactionAction(connectorno, chargeboxid)),
    ReleaseStuckBusy: (connectorno, chargeboxid) => dispatch(releaseStuckBusyAction(connectorno, chargeboxid)),
    DeactiveCharger: (chargeboxid, stationid, deactive) => dispatch(deactivechargervisibilityAction(chargeboxid, stationid, deactive)),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateStation);