import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import Table from "./payAsYouGoTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';
import { payAsyoyGoDriverListAction } from "../../../store/actions/customerAction";



const PayasyougoViewall = (props) => {
    useEffect(() => {
        props.fetchPayasyougoList();
    }, [])

    const data = props.payasyougocustomerlist && props.payasyougocustomerlist

    const columns = useMemo(

        () => [
            {
                header: 'Mobile',
                accessor: 'mobile'
            },
            {
                header: 'Name',
                accessor: 'name'
            },
            // {
            //     header: 'Default Plan',
            //     accessor: 'defaultplan'
            // },
            {
                header: 'Make',
                accessor: 'make'
            },
            {
                header: 'Model',
                accessor: 'model'
            },
            {
                header: 'Plan Name',
                accessor: 'planname'
            },
            {
                header: 'State',
                accessor: 'state'
            },
            {
                header: 'Date',
                id: 'createdat',
                accessor: d => {
                    return moment(d.createdat)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
           
        ],
        []
    );
  
    return (
        <div className="row py-2">
            <div className="col">

                <div className="row py-2">
                    <div className="col-md-12 col-lg-12">
                        <div className="mb-3 h-100 sideCardDesigns">
                            <div className="card-header singleCard">
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                    <strong>Pay As You Go Customer Details</strong>
                                </div>

                            </div>

                            {!data.length ?
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                                :
                                (<div className="App"> <Table data={data} columns={columns}></Table></div>)}


                        </div>
                    </div>
                </div>  </div>
        </div>

    )
}

const mapStateToProps = function (state) {
    return {
        loading: state.customer.isLoading,
        payasyougocustomerlist: state.customer.payasugolist,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchPayasyougoList:()=>dispatch(payAsyoyGoDriverListAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayasyougoViewall);

