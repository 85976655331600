import React from "react"
import "./user.css";
import UserList from "./usertable";
import UserCardList from "./usercards";

const Users=()=>{

    return(
        <div className="container-fluid">
            <UserCardList></UserCardList>
           <UserList></UserList>
        </div>
    )
}

export default Users;