import React, { Component } from "react";
import { connect } from "react-redux";
import "../login.css"
import validator from 'validator'
import { Redirect } from "react-router-dom";
import { getLoginDashboardOTP, LoginDashboardOTPValidate, resendOtpAction } from "../../../store/actions/commonAction";
import HeaderPage from "../../landing/header";
import queryString from 'query-string';


class ForgotPasswordOTP extends Component {
    OTPDefaults = {
        phone: { value: '', isValid: true, message: '' },
        otp: { value: '', isValid: true, message: '' },
        error: { value: '', isValid: true, message: '' }

    }
    state = {
        ...this.OTPDefaults,

    }

    //handlechange

    handleChange = (e) => {
        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: e.target.value,
            }
        };
        this.setState(state);
    }

    //check max length of otp
    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    //Submit OTP
    handlenumberSubmit = (e) => {
        e.preventDefault();
        if (this.mobileFormIsValid()) {
            const phone = { ...this.state.phone };
            this.props.getUserLoginOTP(phone.value)

        }
    }

    //resend otp
    handelResendOtp = (e) => {
        e.preventDefault();
        if (this.mobileFormIsValid()) {
            const phone = { ...this.state.phone };
            this.props.resendOtp(phone.value)
        }
    }
    //Otp submit
    handleOTPSubmit = (e) => {
        e.preventDefault();
        if (this.otpFormIsValid()) {
            const phone = { ...this.state.phone };
            localStorage.setItem('phone', JSON.stringify(phone));
            const otp = { ...this.state.otp };
            this.props.validateUserLoginOTP(phone.value, otp.value)

        }
    }
    //from validation
    mobileFormIsValid = () => {

        const phone = { ...this.state.phone };
        const error = { ...this.state }
        let isGood = true;
        if (validator.isEmpty(phone.value)) {
            phone.isValid = false
            phone.message = 'Mobile no. is required'
            isGood = false;
        }
        if ((phone.value.length > 0) && (phone.value.length < 10)) {
            phone.isValid = false
            phone.message = 'Mobile no. must be 10 digit'
            isGood = false;
        }
        if (!isGood) {
            this.setState({
                phone
            });
        }

        return isGood;

    }

    otpFormIsValid = () => {
        const otp = { ...this.state.otp };
        const phone = { ...this.state.phone };
        const error = { ...this.state }
        let isGood = true;
        if (validator.isEmpty(phone.value)) {
            phone.isValid = false
            phone.message = 'Mobile no. is required'
            isGood = false;
        }
        if (otp.value.length != 4) {
            otp.isValid = false
            otp.message = '4 digit OTP is required'
            isGood = false;
        }
        if (!isGood) {
            this.setState({
                phone,
                otp
            });
        }

        return isGood;
    }

    render() {
        const { phone, otp } = this.state;
        const queryStringParams = queryString.parse(window.location.search);
        const token = queryStringParams.auth;


        if (this.props.loginOTPValidate.error === "Invalid OTP, Try Again") {
            otp.message = 'Invalid OTP, Try Again'
        }
        if (this.props.userLoginOtp.result == "Success") {
            phone.message = 'OTP Sent'
        }
        if (this.props.userLoginOtp.error == "Invalid phone number") {
            phone.message = 'Phone number do not match our record'
        }
        if (this.props.loginOTPValidate.result && this.props.loginOTPValidate.result === "Success") {
            return <Redirect to='/forgotpassword' />;

        }

        return (
            <div className="container-fluid  h-1000">

                <div className="container-fluid ">
                    <div className="row ">
                        <div id="outer-container">
                            <div className="cardLogo text-center  ">
                                <a href="/forgotpassotp" className="c_logo">
                                    {token === "tsredco" ? <img src="images/tsredco/logo.png" alt="TSREDCO" className="logo-tsr" /> : <img src="images/logo/magentaLogo.svg" style={{ width: "300px" }} alt="Chargegrid" className="logo" />}
                                </a>
                            </div>
                            <div className="sign-up-header " >
                                <h5 style={{ color: "#1e295d", fontFamily: "Helvetica", fontSize: "25px", fontWeight: "600" }} > Forgot Password </h5>
                            </div>
                            <div className="sign-up-header " >
                                <h5 style={{ color: "#1e295d", fontFamily: "Helvetica", fontSize: "15px" }}>Please enter registered mobile number to get an OTP </h5>
                            </div>
                            <form className="form-signin" onSubmit={this.handlenumberSubmit}>
                                <div className="form-label">
                                    <input type="number" id="phone" name="phone" className="form-control" placeholder="Enter Mobile No" maxLength="10" onInput={this.maxLengthCheck} onChange={this.handleChange} />

                                    <span className="help-block text-danger">{phone.message}</span>
                                    <br /> </div>
                                <button className="btn btn-lg btn-primary btn-block text-uppercase otpBtn">{!this.props.loading ? 'Send OTP' : 'Please wait...'}</button>
                            </form>


                            <form className="form-signin" onSubmit={this.handleOTPSubmit}>
                                <label className="" htmlFor="password" style={{ color: "#1e295d", fontWeight: "550", opacity: ".8" }}>Enter OTP</label>

                                <div className="form-label required">
                                    <input type="number" id="otp" name="otp" className="form-control" placeholder="OTP" maxLength="4" onInput={this.maxLengthCheck} onChange={this.handleChange} />
                                    <span className="help-block text-danger">{otp.message}</span>
                                    <br />
                                </div>
                                <button className="btn btn-lg btn-primary btn-block text-uppercase otpBtn">{!this.props.loading ? 'VERIFY' : 'Please wait...'}</button>
                            </form>

                            <div className=" text-center mt-5 ">
                                <p className="contactText text-center" href="" style={{ textDecoration: "none", fontSize: "12px", fontWeight: "600" }}>Don't receive the OTP? <a href="" style={{ color: "#BC0078", letterSpacing: "1px" }} onClick={this.handelResendOtp}>RESEND OTP</a></p>
                            </div>

                        </div>

                    </div>
                    <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-12 mx-auto footerContainer">
                            Powered by &nbsp; <a href=""><img style={{ height: "40px" }} src={process.env.PUBLIC_URL + "/images/logo/LogoLeft.svg"} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

//call  reducer from Redux store


const mapStateToProps = (state) => {
    return {

        userLoginOtp: state.common.fetchsigninotp,
        loginOTPValidate: state.common.validatesigninotp,
        resendOtpValid: state.common.resendOtp,
    }
}

//call  action from Redux store

const mapDispatchToProps = dispatch => {
    return {
        getUserLoginOTP: (phone) => dispatch(getLoginDashboardOTP(phone)),
        validateUserLoginOTP: (phone, otp) => dispatch(LoginDashboardOTPValidate(phone, otp)),
        resendOtp: (phone) => dispatch(resendOtpAction(phone))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordOTP)
