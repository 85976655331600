
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PureBreadcrumbs from "../../Breadcrums"
import "../../report/report.css"
import ManageElectricpanel from "./electricpanel/electricpanel"
import ManageCCTV from "./cctv/cctv"
import ManageBroadband from "./broadband/broadBand"
import ManageCharger from "./charger/charger"



const PmrManagementPage = (props) => {



    return (
        <div className="container-fluid">
        <div className="row  ">
            <div className="col col-sm-4 ">
                <PureBreadcrumbs />
            </div>
        </div>
       <ManageElectricpanel />
       <br/>
       <ManageCCTV />
       <br/>
       <ManageBroadband />
       <br/>
       <ManageCharger/>
    </div>
    )
}



const mapStateToProps = function (state) {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
       
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PmrManagementPage);



