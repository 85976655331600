import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { customerListAction } from "../../../store/actions/customerAction";
import Table from "./customerTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';
import PureBreadcrumbs from "../Breadcrums"
const CustomerView = (props) => {
  useEffect(() => {
    props.fetCustomerList();
  }, [])

  const data = props.customerlist && props.customerlist

  const columns = useMemo(

    () => [
      {
        header: 'Customer Name',
        accessor: 'name'
      },
      {
        header: 'Mobile',
        accessor: 'mobile'
      },
      {
        header: 'Email ID',
        accessor: 'email'
      },
      {
        header: 'Date',
        id: 'createdat',
        accessor: d => {
          return moment(d.createdat)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Address',
        accessor: 'address'
      },
      {
        header: 'City',
        accessor: 'city'
      },
      {
        header: 'State',
        accessor: 'state'
      },

      {
        header: 'Status',
        id: "accountstatus",
        accessor: d => {
          return d.accountstatus ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
        }
      },

      {
        header: 'Actions',
        id: 'mobilee',
        accessor: m => `${m.mobile}`,
        Cell: (mobile) => {
          return (
            <div>
              <span>
                <a href={`/dashboard/customers/customerreport/${mobile.value}`} rel="tooltip" title="View " className="table-action view">
                  <i className="fas fa-eye"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;
              <span>
                <a href={`/dashboard/customer/editcustomer`} rel="tooltip" title="Edit" className="table-action edit">
                  <i className="fa fa-edit"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;

            </div>

          );

        },

      }
    ],
    []
  );
  useEffect(() => {
    props.fetCustomerList();
  }, [])
  return (<>



    <div className="row py-2">
      <div className="col">

        <div className="row py-2">
          <div className="col-md-12 col-lg-12">
            <div className="mb-3 h-100 sideCardDesigns">
              <div className="card-header singleCard">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <strong>Customer Details</strong>
                </div>
                <div className="btn-actions-pane-right">
                  <a href={`/dashboard/customer/addCustomer`}>
                    <button type="button" className="btn btn-primary btn-sm">Add New Customer</button></a>
                </div>
              </div>

              {!data.length ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
                (<div className="App"> <Table data={data} columns={columns}></Table></div>)}
            </div>
          </div>
        </div> 
        </div>
    </div>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.customer.isLoading,
    customerlist: state.customer.fetchCustomerList
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetCustomerList: () => dispatch(customerListAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerView);