import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../Breadcrums"
import Loader from 'react-loader-spinner';
import Table from "./inventoryReactTable";
import { CardBody } from "reactstrap";
import { Bar } from 'react-chartjs-2';
import { getAllInventoryAssetAction, getAssetProductCountAction } from "../../../store/actions/inventoryAction";

const InventoryCardModel = (props) => {

  useEffect(() => {
    props.getAllInventoryAsset();
    props.getProducttypeCount();
  }, []);

  const inStock = props.getallAsset && props.getallAsset.filter(comd => comd.statuss === "In Stock");
  const dispatched = props.getallAsset && props.getallAsset.filter(coms => coms.statuss === "Dispatched");
  const data = props.getallAsset && props.getallAsset

  const columns = useMemo(

    () => [

      {
        header: 'Charger Serial Number',
        accessor: 'chargerserialno'
      },
      {
        header: 'ChargeBox ID',
        accessor: 'chargeboxid'
      },
      {
        header: 'Firmware Version',
        accessor: 'firmwareversion'
      },
      {
        header: 'Project Code',
        accessor: 'projectcode'
      },

      {
        header: 'Entered By',
        accessor: 'enteredby'

      },
      {
        header: 'Status',
        accessor: 'statuss'

      },

      {
        header: 'Actions',
        accessor: 'id',
        Cell: (id) => {
          return (
            <div>
              <span>
                <a href={`/dashboard/inventory/viewsingleasset/${id.value}`} rel="tooltip" title="View" className="table-action view">
                  <i className="fas fa-eye"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;
              <span>
                <a href={`/dashboard/inventory/editasset/${id.value}`} rel="tooltip" title="Edit" className="table-action edit">
                  <i className="fa fa-edit"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;

            </div>

          );

        },

      }
    ],
    []
  );

  const productTypeName = props.getProductTypeCount && props.getProductTypeCount.map(ame => ame.id)
  const productTypeCount = props.getProductTypeCount && props.getProductTypeCount.map(count => count.count)

  let TotalProductTypeChart = {
    data: (canvas) => {
      return {
        labels: productTypeName,
        datasets: [
          {
            label: "Product Type Count",
            backgroundColor: "#D1108C",
            data: productTypeCount,
          },
        ],
      };
    },
  };

  let StatusWiseChart = {
    data: (canvas) => {
      return {
        labels: ["In Stock", "Dispatched", "Total"],
        datasets: [
          {
            label: "Status wise bifurcation",
            backgroundColor: "#1E295D",
            data: [inStock && inStock.length, dispatched && dispatched.length, props.getallAsset && props.getallAsset.length],
          },
        ],
      };
    },
  };

  const plugin = {
    beforeInit(chart) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 25;
      }
    }
  }



  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col col-sm-4 ">
            <PureBreadcrumbs />
          </div>
        </div>
        
        <div className="row rowpadding justify-content-between ">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4 mt-2 ">
                            <div className=" colpadding sideCardDesigns p-2">
                                <div className=" text-center cardbg singleCard" >
                                    <div className="card-body">
                                        <div className="media d-flex">
                                            <div className="float-right  ">
                                                <img className="float-right" src={process.env.PUBLIC_URL + '/images/Inventory/inStocks.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                                            </div>
                                        </div>
                                        <div className="media-body float-center" style={{ color: "#10204B" }} >
                                            <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{inStock !== null ? inStock.length : 0}</strong></h4></span>
                                            <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center", fontWeight:"600"}}>In Stock</h5></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4 mt-2 ">
                            <div className="colpadding sideCardDesigns p-2">
                                <div className=" text-center cardbg singleCard" >
                                    <div className="card-body">
                                        <div className="media d-flex">
                                            <div className="float-right  ">
                                                <img className="float-right" src={process.env.PUBLIC_URL + '/images/Inventory/dispatch.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                                            </div>
                                        </div>
                                        <div className="media-body float-center " style={{ color: "#10204B" }} >
                                            <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{dispatched !== null ? dispatched.length : 0}</strong></h4></span>
                                            <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" , fontWeight:"600" }}>Dispatched</h5></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4 mt-2 ">
                            <div className=" colpadding sideCardDesigns p-2">
                                <div className=" text-center cardbg singleCard" >
                                    <div className="card-body">
                                        <div className="media d-flex">
                                            <div className="float-right  ">
                                                <img className="float-right" src={process.env.PUBLIC_URL + '/images/Inventory/totalinventory.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                                            </div>
                                        </div>
                                        <div className="media-body float-center" style={{ color: "#10204B" }} >
                                            <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.getallAsset !== null ? props.getallAsset.length : 0}</strong></h4></span>
                                            <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center", fontWeight:"600" }}>Total</h5></span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>


        <div className="row rowpadding m-1 mb-2 sideCardDesigns ">
          <div className="col-6 p-3">
            <div className="col-12 ml-2 ">
              <img src={process.env.PUBLIC_URL + '/images/Inventory/chargerAssets.svg'} style={{ height: "34px", width: "32px" }} alt="" />
              <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>Add New Charger Assets</span>
              <div className="page-title-subheading pt-2" style={{ fontSize: "12px", color: "#bc0078", fontWeight: "550" }}>New charger assets including In Stock and Dispatched once.</div>
            </div>
          </div>
          <div className="col-6">
            <div className="text-right m-3 pt-3">
              <a href={`/dashboard/inventory/createasset`} className="btn btn-primary" >Add Charger Assets</a>
            </div>
          </div>
        </div>


        <div className="row py-2">
          <div className="col">
            <div className="row py-2">
              <div className="col-md-12 col-lg-12">
                <div className="mb-3 h-100 sideCardDesigns">
                  <div className="card-header singleCard">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong>Inventory Assets Details</strong>
                    </div>

                  </div>
                  {data && data.length > 0 ?
                    (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                    :
                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row rowpadding mt-4 mb-3">
                <div className="col-md-6  ">
                    <div className="col-md-12  sideCardDesigns">
              <CardBody>
                <span className="charttitle"> Total Count By Product Type </span>
                <div className="chart-area1">
                  <Bar
                    data={TotalProductTypeChart.data}
                    plugins={plugin}
                    options={{
                      tooltips: {
                        mode: 'index'
                      },
                      animation: {
                        duration: 0,
                      },
                      hover: {
                        animationDuration: 0,
                      },
                      legend: {
                        display: true,
                        labels: {
                          fontColor: 'black',
                          fontSize: 15,
                          padding: 20,
                          usePointStyle: true,
                          //width of circles in legend
                          boxWidth: 9

                        }
                      },

                      scales: {
                        xAxes: [{
                          gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                          }
                        }],
                        yAxes: [{
                          gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                          }
                        }]
                      },
                      tooltips: {
                        enabled: true
                      },
                      animation: {
                        duration: 10,
                        onComplete: function () {
                          var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                          ctx.textAlign = 'center';
                          ctx.color = "black";
                          ctx.fillStyle = "black";
                          ctx.textBaseline = 'bottom';
                          this.data.datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                              var data = dataset.data[index];

                              if (data > 999 && data < 1000000) {
                                var rdata = (data / 1000).toFixed() + 'K';     // convert to K for number from > 1000 < 1 million 
                                ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                              } else if (data > 1000000) {
                                var rdata = (data / 1000000).toFixed() + 'M';  // convert to M for number from > 1 million 
                                ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                              } else if (data < 900) {
                                ctx.fillText(data, bar._model.x, bar._model.y - 5); // if value < 1000, nothing to do
                              }

                              //   ctx.fillText(data, bar._model.x, bar._model.y - 5);
                            });
                          });
                        }
                      }
                    }
                    }

                  />
                </div>
              </CardBody>
            </div>
          </div>

          <div className="col-md-6   ">
                    <div className="col-md-12 sideCardDesigns">
              <CardBody>
                <span className="charttitle"> Status Wise Bifurcation </span>
                <div className="chart-area1">
                  <Bar
                    data={StatusWiseChart.data}
                    plugins={plugin}
                    options={{
                      tooltips: {
                        mode: 'index'
                      },
                      animation: {
                        duration: 0,
                      },
                      hover: {
                        animationDuration: 0,
                      },
                      legend: {
                        display: true,
                        labels: {
                          fontColor: 'black',
                          fontSize: 15,
                          padding: 20,
                          usePointStyle: true,
                          //width of circles in legend
                          boxWidth: 9

                        }
                      },

                      scales: {
                        xAxes: [{
                          gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                          }
                        }],
                        yAxes: [{
                          gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                          }
                        }]
                      },
                      tooltips: {
                        enabled: true
                      },
                      animation: {
                        duration: 10,
                        onComplete: function () {
                          var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                          ctx.textAlign = 'center';
                          ctx.color = "black";
                          ctx.fillStyle = "black";
                          ctx.textBaseline = 'bottom';
                          this.data.datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                              var data = dataset.data[index];

                              if (data > 999 && data < 1000000) {
                                var rdata = (data / 1000).toFixed() + 'K';     // convert to K for number from > 1000 < 1 million 
                                ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                              } else if (data > 1000000) {
                                var rdata = (data / 1000000).toFixed() + 'M';  // convert to M for number from > 1 million 
                                ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                              } else if (data < 900) {
                                ctx.fillText(data, bar._model.x, bar._model.y - 5); // if value < 1000, nothing to do
                              }

                              //   ctx.fillText(data, bar._model.x, bar._model.y - 5);
                            });
                          });
                        }
                      }
                    }
                    }

                  />
                </div>
              </CardBody>
            </div>
          </div>
        </div>



      </div>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    getallAsset: state.inventory.getAllInventoryAsset,
    getProductTypeCount: state.inventory.productCountType,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getAllInventoryAsset: () => dispatch(getAllInventoryAssetAction()),
    getProducttypeCount: () => dispatch(getAssetProductCountAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCardModel);