import { FETCH_PRICE_LIST_BY_CREATOR, FETCH_SINGLE_PRICE_URL, PRICE_BASE_URL, SAVE_PRICE_URL, UPDATE_PRICE_LEVEL_URL, UPDATE_PRICE_PLAN_URL, PRICE_DEACTIVATION_URL } from "../../components/config/configs";

export const savePriceAction = (price, isPriceGroup) => {
  const { token, id, firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { networkoperator, enterprise, name, description, currency, country, state, plantype } = price;
  return dispatch => {
    dispatch({ type: 'PRICE_LOADING', payload: '' })
    return fetch(PRICE_BASE_URL + SAVE_PRICE_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "operator": networkoperator,
        "enterprise": enterprise,
        "name": name,
        "description": description,
        "currency": currency,
        "country": country,
        "state": state,
        "plantype": plantype,
        "createdby": id,
        "isPriceGroup": isPriceGroup,
        "enterdby": firstname,
      })
    }).then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'SAVE_PRICE', payload: data })
      })
  }
}

export const getPriceSingleAction = (priceid) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("tokennnn", bearer)
  return dispatch => {
    dispatch({ type: 'PRICE_LOADING', payload: '' })
    return fetch(PRICE_BASE_URL + FETCH_SINGLE_PRICE_URL + "?priceid=" + priceid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_SINGLE_PRICE",data)
        dispatch({ type: 'FETCH_SINGLE_PRICE', payload: data })
      })
  }
}

export const updatePriceLevelAction = (price, pricecomponent, pricestatus, starttime, endtime, weeks, pid) => {
  const { token, id } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { operator, enterprise, name, description, currency, country, state, plantype, isPriceGroup } = price;
  return dispatch => {
    dispatch({ type: 'PRICE_LOADING', payload: '' })
    return fetch(PRICE_BASE_URL + UPDATE_PRICE_LEVEL_URL, {
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "operator": operator,
        "enterprise": enterprise,
        "name": name,
        "description": description,
        "currency": currency,
        "country": country,
        "state": state,
        "plantype": plantype,
        "createdby": id,
        "id": pid,
        "status": pricestatus,
        "scheduleday": weeks,
        "starttime": starttime,
        "endtime": endtime,
        "pricecomponent": pricecomponent,
        "isPriceGroup": isPriceGroup

      })
    }).then(resp => resp.json())
      .then(data => {

        dispatch({ type: 'UPDATE_PRICE', payload: data })
      })
  }
}

export const getPriceByCreatorAction = () => {
  const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log(bearer);
  return dispatch => {
    dispatch({ type: 'PRICE_LOADING', payload: '' })
    return fetch(PRICE_BASE_URL + FETCH_PRICE_LIST_BY_CREATOR + "?createdby=" + id + "&role=" + role + "&np=" + networkoperator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("Price list data......",data);
        dispatch({ type: 'FETCH_PRICE_LIST', payload: data })
      })
  }
}

export const PriceDeactivateAction = (id,status) => {
  // console.log("id,status",id,status)
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("url ",PRICE_BASE_URL+PRICE_DEACTIVATION_URL )
  return dispatch => {
    return fetch(PRICE_BASE_URL + PRICE_DEACTIVATION_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": id,
        "status":status
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("PLAN_DEACTIVATION",data)
        dispatch({ type: 'DEACTIVATE_USER', payload: data })
      })
  }
}


export const editPricebyID = (pid, data, inputList, schedule, isPriceGroup) => {
  const { token, id } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { operator, enterprise, name, description, currency, country, state, starttime, endtime, plantype, pricestatus,scheduleday } = data;
  // const {power, kwh, pricetype, priceunit, amount, idlefee, graceperiod,}=inputList;
  return dispatch => {
    dispatch({ type: 'PRICE_LOADING', payload: '' })
    return fetch(PRICE_BASE_URL + UPDATE_PRICE_PLAN_URL, {
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": pid,
        "country": country,
        "currency": currency,
        "description": description,
        "endtime": endtime,
        "starttime": starttime,
        "enterprise": enterprise,
        "isPriceGroup": isPriceGroup,
        "name": name,
        "operator": operator,
        "plantype": plantype,
        "state": state,
        "status": pricestatus,
        "createdby": id,
        "scheduleday": scheduleday,
        "pricecomponent": inputList

      })
    }).then(resp => resp.json())
      .then(data => {

         dispatch({ type: 'EDIT_PRICE_BYID', payload: data })
        // console.log("EDIT_PRICE_BYID", data);
      })
  }
}


