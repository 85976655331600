import React, { useMemo, useEffect, useState } from "react"
import moment from "moment";
import { connect } from "react-redux";
import "./report.css";
import { TotalTimeAction } from "../../../store/actions/chargesessionAction";
import "jspdf-autotable";
import Moment from "moment";
import { DateRangePicker } from "react-dates"
import Table from "./timetable";
import Loader from 'react-loader-spinner';
import "../report/table.css"
import "react-toastify/dist/ReactToastify.css";

const Timeviewall = (props) => {

  const [startDate, setStartDate] = useState(Moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(Moment().add(0, "days"));
  const [focusedInput, setFocusedInput] = useState(Moment());
  const isOutsideRange = (day) => {
    day.isAfter(Moment()) || day.isBefore(Moment().subtract(30, "days"));
  };

  useEffect(() => {
    const today = Moment();
    const current = today.add(0, "days").format("YYYY-MM-DD");
    const lastWeek = today.subtract(7, "days").format("YYYY-MM-DD");
    props.fetchTime(lastWeek, current);
  }, []);

  const handleDateClick = () => {
    if (
      startDate &&
      startDate.format("ll") &&
      endDate &&
      endDate.format("ll")
    ) {
      // console.log(startDate.format("ll"), endDate.format("ll"));
      const current = startDate.format("YYYY-MM-DD");
      const last = endDate.format("YYYY-MM-DD");
      props.fetchTime(current, last);
    }
  };

  const data = props.list && props.list.report

  // console.log("timetableeeeee", data)

  const columns = useMemo(

    () => [
      {
        header: 'Location',
        id: 'Location',
        accessor: d => {
          return (d.location)
        }
      },
    {
      header: 'Operator',
      id: 'networkoperator',
      accessor: d => {
        return (d.networkoperator)
      }
    },
    {
      header: 'Charger BoxID',
      id: 'station',
      accessor: d => {
        return (d.station)
      }
    },
    {
      header: 'Connector Type',
      id: 'connectortype',
      accessor: d => {
        return (d.connectortype)
      }
    },
    {
      header: 'Total Time',
      id: 'totaltime',
      accessor: d => {
        return (d.totaltime)
      }
    },
    {
      header: 'Avg Revenue(%)',
      id: 'avgrevenue',
      accessor: d => {
        return (d.avgrevenue)
      }
    },


    ],
    []
  );

  useEffect(() => {
    const today = Moment();
    const current = today.add(0, "days").format("YYYY-MM-DD");
    const lastWeek = today.subtract(7, "days").format("YYYY-MM-DD");
    props.fetchTime(lastWeek, current);
  }, []);
  return (
    <div className="row py-2">
      <div className="col">

        <div className="row py-2">
          <div className="col-md-12 col-lg-12">
            <div className="mb-3 h-100 sideCardDesigns">
              <div className="card-header singleCard">
                <div className="col-6">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <strong>Total Time</strong>
                  </div>
                </div>
                <div className="col-6 text-right">
                  <DateRangePicker
                    startDate={startDate}
                    startDateId="s_id"
                    endDate={endDate}
                    endDateId="e_id"
                    onDatesChange={({ startDate, endDate }) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(e) => setFocusedInput(e)}
                    displayFormat="DD/MM/YYYY"
                    horizontalMargin={20}
                    isOutsideRange={isOutsideRange}
                  />
                  <button
                    className="dateBtn ml-2"
                    type="button"
                    onClick={handleDateClick}
                  >
                    Go
                  </button>
                </div>

              </div>

              {!data.length ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
                (<div className="App"> <Table data={data} columns={columns}></Table></div>)}


            </div>
          </div>
        </div>  </div>
    </div>

  )
};

const mapStateToProps = (state) => {
  return {
    list: state.report.fetchTimeData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTime: (start, end) => dispatch(TotalTimeAction(start, end)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeviewall);
