import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getInventoryAssetByIDAction } from "../../../store/actions/inventoryAction";

class ViewInventoryAsset extends Component {
    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.viewInventoryasset(params.id)
    }
    render() {

        return (
            <>
                <div className="container-fluid">
                    <div className="sideCardDesigns card-signin">
                        <div className="card-body">
                            <h4 className="text-center formHeading">
                                <b>View Inventory Asset</b>
                            </h4>


                            <div className="row rowpadding  justify-content-between mt-5">
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer">
                  <div className="col-12">
                    <label className="formLable" htmlFor="chargerserialno">Charger Serial Number</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="chargerserialno"
                                            name="chargerserialno"
                                            className="form-control"
                                            value={this.props.viewasset.chargerserialno}
                                            disabled
                                        />
                                        <label htmlFor="chargerserialno">Charger Serial Number</label>
                                    </div>
                    <label className="formLable" htmlFor="chargeboxid">ChargeBox ID</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="chargeboxid"
                                            name="chargeboxid"
                                            className="form-control"
                                            value={this.props.viewasset.chargeboxid}
                                            disabled
                                        />
                                        <label htmlFor="chargeboxid">ChargeBox ID</label>
                                    </div>

                    <label className="formLable" htmlFor="ptype">Product Type</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="ptype"
                                            name="ptype"
                                            className="form-control"
                                            value={this.props.viewasset.ptype}
                                            disabled
                                        />
                                        <label htmlFor="ptype">Product Type</label>
                                    </div>

                    <label className="formLable" htmlFor="assembled">Assembled By</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="assembled"
                                            name="assembled"
                                            className="form-control"
                                            value={this.props.viewasset.assembled}
                                            disabled
                                        />
                                        <label htmlFor="assembled">Assembled By</label>
                                    </div>
                    <label className="formLable" htmlFor="firmwareversion">Firmware Version</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="firmwareversion"
                                            name="firmwareversion"
                                            className="form-control"
                                            value={this.props.viewasset.firmwareversion}
                                            disabled
                                        />
                                        <label htmlFor="firmwareversion">Firmware Version</label>
                                    </div>
                    <label className="formLable" htmlFor="dispatchdate">Dispatch Date</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="dispatchdate"
                                            name="dispatchdate"
                                            className="form-control"
                                            value={this.props.viewasset.dispatchdate}
                                            disabled
                                        />
                                        <label htmlFor="dispatchdate">Dispatch Date</label>
                                    </div>
                    <label className="formLable" htmlFor="rfidno">RFID Number</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="rfidno"
                                            name="rfidno"
                                            className="form-control"
                                            value={this.props.viewasset.rfidno}
                                            disabled
                                        />
                                        <label htmlFor="rfidno">RFID Number</label>
                                    </div>
                    <label className="formLable" htmlFor="tagid">Tag ID</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="tagid"
                                            name="tagid"
                                            className="form-control"
                                            value={this.props.viewasset.tagid}
                                            disabled
                                        />
                                        <label htmlFor="tagid">Tag ID</label>
                                    </div>
                    <label className="formLable" htmlFor="simcard">Sim Card</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="simcard"
                                            name="simcard"
                                            className="form-control"
                                            value={this.props.viewasset.simcard}
                                            disabled
                                        />
                                        <label htmlFor="simcard">Sim Card</label>
                                    </div>
                    <label className="formLable" htmlFor="acpboard">ACP Board Attached?</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="acpboard"
                                            name="acpboard"
                                            className="form-control"
                                            value={this.props.viewasset.acpboard}
                                            disabled
                                        />
                                        <label htmlFor="acpboard">ACP Board Attached</label>
                                    </div>
                    <label className="formLable" htmlFor="sunboard">Sun Board Attached?</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="sunboard"
                                            name="sunboard"
                                            className="form-control"
                                            value={this.props.viewasset.sunboard}
                                            disabled
                                        />
                                        <label htmlFor="sunboard">Sun Board Attached</label>
                                    </div>
                    <label className="formLable" htmlFor="instructionboard">Instruction Board Attached?</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="instructionboard"
                                            name="instructionboard"
                                            className="form-control"
                                            value={this.props.viewasset.instructionboard}
                                            disabled
                                        />
                                        <label htmlFor="instructionboard">Instruction Board Attached</label>
                                    </div>



                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer ">

                  <div className="col-12 ">

                    <label className="formLable" htmlFor="stand">Charger Mounting stand?</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="stand"
                                            name="stand"
                                            className="form-control"
                                            value={this.props.viewasset.stand}
                                            disabled
                                        />
                                        <label htmlFor="stand">Charger Mounting Stand</label>
                                    </div>

                    <label className="formLable" htmlFor="couriername">Courier Name</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="couriername"
                                            name="couriername"
                                            className="form-control"
                                            value={this.props.viewasset.couriername}
                                            disabled
                                        />
                                        <label htmlFor="couriername">Courier Name</label>
                                    </div>
                    <label className="formLable" htmlFor="trackingnumber">Tracking Number</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="trackingnumber"
                                            name="trackingnumber"
                                            className="form-control"
                                            value={this.props.viewasset.trackingnumber}
                                            disabled
                                        />
                                        <label htmlFor="trackingnumber">Tracking Number</label>
                                    </div>
                    <label className="formLable" htmlFor="dispatchbrand">Dispatching under Brand</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="dispatchbrand"
                                            name="dispatchbrand"
                                            className="form-control"
                                            value={this.props.viewasset.dispatchbrand}
                                            disabled
                                        />
                                        <label htmlFor="dispatchbrand">Dispatching Under Brand</label>
                                    </div>
                    <label className="formLable" htmlFor="address">Address</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            className="form-control"
                                            value={this.props.viewasset.address}
                                            disabled
                                        />
                                        <label htmlFor="address">Address</label>
                                    </div>
                    <label className="formLable" htmlFor="prnumber">PR Number</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="prnumber"
                                            name="prnumber"
                                            className="form-control"
                                            value={this.props.viewasset.prnumber}
                                            disabled
                                        />
                                        <label htmlFor="prnumber">PR Number</label>
                                    </div>
                    <label className="formLable" htmlFor="projectcode">Project Code</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="projectcode"
                                            name="projectcode"
                                            className="form-control"
                                            value={this.props.viewasset.projectcode}
                                            disabled
                                        />
                                        <label htmlFor="projectcode">Project Code</label>
                                    </div>
                    <label className="formLable" htmlFor="sealable">Sales order type?</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="sealable"
                                            name="sealable"
                                            className="form-control"
                                            value={this.props.viewasset.sealable}
                                            disabled
                                        />
                                        <label htmlFor="sealable">Sales Order Type</label>
                                    </div>
                    <label className="formLable" htmlFor="remark">Remark</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="remark"
                                            name="remark"
                                            className="form-control"
                                            value={this.props.viewasset.remark}
                                            disabled
                                        />
                                        <label htmlFor="remark">Remark</label>
                                    </div>
                    <label className="formLable" htmlFor="statuss">Status</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="statuss"
                                            name="statuss"
                                            className="form-control"
                                            value={this.props.viewasset.statuss}
                                            disabled
                                        />
                                        <label htmlFor="statuss">Status</label>
                                    </div>
                    <label className="formLable" htmlFor="enteredby">Entered By</label>
                    <div className="form-label-group pb-3">
                                        <input
                                            type="text"
                                            id="enteredby"
                                            name="enteredby"
                                            className="form-control"
                                            value={this.props.viewasset.enteredby}
                                            disabled
                                        />
                                        <label htmlFor="enteredby">Data Entered By</label>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>

                  </div>
                </div>
              </div>
            </div>

                            <div className="row">
                                <div className="col-xl-12 col-sm-12 col-12">
                                    <a
                                        className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                        href="/dashboard/inventory"
                                    >
                                        Back
                                    </a>
                                </div>
                                <hr className="my-4" />
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        viewasset: state.inventory.getInventoryAssetByID
    }
}
const mapDispatchToProps = dispatch => {
    return {
        viewInventoryasset: (id) => dispatch(getInventoryAssetByIDAction(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewInventoryAsset));