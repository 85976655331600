import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../Breadcrums"
import { Redirect } from "react-router-dom";
import * as XLSX from "xlsx";
import dashboard from "./excel/inventoryAssets.xlsx";
import { saveInventoryAssetAction } from "../../../store/actions/inventoryAction";


const CreateInventoryAsset = (props) => {

    const { firstname } = JSON.parse(localStorage.getItem("user"));
    const [assetList, setAssetList] = useState();

    const handleChange = (e) => {
        setAssetList({
            ...assetList,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const handleAssetSubmit = (e) => {
        e.preventDefault();
        alert("New Inventory Asset added");
        props.saveInventoryasset(assetList);
    };

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);
          fileReader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            //  console.log("wsname", ws);
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
            //  console.log("exceldata", data);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
        promise.then((d) => {
        //    console.log("data2", d);
           setAssetList(d);
        //   for (let value of Object.values(d)) {
        //     const a = Object.keys(value);
        //      console.log("array of keys", a);
        //     var b = [
        //       "chargerserialno",
        //       "chargeboxid",
        //       "ptype",
        //       "assembled",
        //       "firmwareversion",
        //       "dispatchdate",
        //       "rfidno",
        //       "tagid",
        //       "simcard",
        //       "acpboard",
        //       "sunboard",
        //       "instructionboard",
        //       "stand",
        //       "couriername",
        //       "trackingnumber",
        //       "dispatchbrand",
        //       "address",
        //       "prnumber",
        //       "projectcode",
        //       "sealable",
        //       "remark",
        //       "statuss",
        //       "enteredby",
        //     ];
        //     if (JSON.stringify(a) == JSON.stringify(b)) {
        //         setAssetList(d);
        //       return;
        //     } else {
        //       alert("Header format does not match");
        //     //   return (window.location.href = "/dashboard/inventory");
        //     }
        //   }
        });
      };

      const handleSubmitFile = () => {
        // console.log("Selectedfile", assetList);
        for (let i = 0; i < assetList.length; i++) {
          props.saveInventoryasset(assetList[i]);
         

        }
        alert("New Inventory Asset added");
      };

    if (props.saveasset && props.saveasset.result === 'Success') {
        return <Redirect to='/dashboard/inventory' />;
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row ">
                    <div className="col col-sm-4 ">
                        <PureBreadcrumbs />
                    </div>
                </div>

                <div className="sideCardDesigns card-signin">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>Add Inventory Asset</b>
                        </h4>
                       
                        <form className="form mt-5" onSubmit={handleAssetSubmit} autoComplete="off">

                        <div className="row rowpadding  justify-content-between">
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer">
                  <div className="col-12">
                    <label className="formLable" htmlFor="chargerserialno">Charger Serial Number</label>
                    <div className="form-label-group required">
                                        <input
                                            type="text"
                                            id="chargerserialno"
                                            name="chargerserialno"
                                            className="form-control control-label"
                                            placeholder="Enter Charger Serial Number"
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="chargerserialno">Charger Serial Number</label>
                                    </div>
                    <label className="formLable" htmlFor="chargeboxid">ChargeBox ID</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="chargeboxid"
                                            name="chargeboxid"
                                            className="form-control"
                                            placeholder="Enter ChargeBox ID"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="chargeboxid">Enter ChargeBox ID</label>
                                    </div>

                    <label className="formLable" htmlFor="ptype">Product Type</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="ptype"
                                            name="ptype"
                                            className="form-control"
                                            placeholder="Enter Product Type"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="ptype">Enter Product Type</label>
                                    </div>

                    <label className="formLable" htmlFor="assembled">Assembled By</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="assembled"
                                            name="assembled"
                                            className="form-control control-label"
                                            placeholder="Assembled By"
                                            onChange={handleChange}

                                        />
                                        <label htmlFor="assembled">Assembled By</label>
                                    </div>
                    <label className="formLable" htmlFor="firmwareversion">Firmware Version</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="firmwareversion"
                                            name="firmwareversion"
                                            className="form-control"
                                            placeholder="Enter Firmware Version"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="firmwareversion">Enter Firmware Version</label>
                                    </div>
                    <label className="formLable" htmlFor="dispatchdate">Dispatch Date</label>
                    <div className="form-label-group">
                                        <input
                                            type="date"
                                            id="dispatchdate"
                                            name="dispatchdate"
                                            className="form-control"
                                            placeholder="Enter Dispatch Date"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="dispatchdate">Enter Dispatch Date</label>
                                    </div>
                    <label className="formLable" htmlFor="rfidno">RFID Number</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="rfidno"
                                            name="rfidno"
                                            className="form-control"
                                            placeholder="Enter RFID Number"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="rfidno">Enter RFID Number</label>
                                    </div>
                    <label className="formLable" htmlFor="tagid">Tag ID</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="tagid"
                                            name="tagid"
                                            className="form-control"
                                            placeholder="Enter Tag ID"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="tagid">Enter Tag ID</label>
                                    </div>
                    <label className="formLable" htmlFor="simcard">Sim Card</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="simcard"
                                            name="simcard"
                                            className="form-control"
                                            placeholder="Enter Sim Card"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="simcard">Enter Sim Card</label>
                                    </div>
                    <label className="formLable" htmlFor="acpboard">ACP Board Attached?</label>
                    <div className="form-label-group">
                                        <select className="form-control border-radius" id="acpboard" name="acpboard" onChange={handleChange}>
                                            <option >ACP Board Attached?</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                        </select>
                                    </div>
                    <label className="formLable" htmlFor="sunboard">Sun Board Attached?</label>
                    <div className="form-label-group">
                                        <select className="form-control border-radius" id="sunboard" name="sunboard" onChange={handleChange}>
                                            <option >Sun Board Attached?</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                        </select>
                                    </div>
                    <label className="formLable" htmlFor="instructionboard">Instruction Board Attached?</label>
                    <div className="form-label-group">
                                        <select className="form-control border-radius" id="instructionboard" name="instructionboard" onChange={handleChange}>
                                            <option >Instruction Board Attached?</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                        </select>
                                    </div>



                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer ">

                  <div className="col-12 ">

                    <label className="formLable" htmlFor="stand">Charger Mounting stand?</label>
                    <div className="form-label-group">
                                        <select className="form-control border-radius" id="stand" name="stand" onChange={handleChange}>
                                            <option >Charger Mounting stand?</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                        </select>
                                    </div>

                    <label className="formLable" htmlFor="couriername">Courier Name</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="couriername"
                                            name="couriername"
                                            className="form-control"
                                            placeholder="Enter Courier Name"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="couriername">Enter Courier Name</label>
                                    </div>
                    <label className="formLable" htmlFor="trackingnumber">Tracking Number</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="trackingnumber"
                                            name="trackingnumber"
                                            className="form-control"
                                            placeholder="Enter Tracking Number"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="trackingnumber">Enter Tracking Number</label>
                                    </div>
                    <label className="formLable" htmlFor="dispatchbrand">Dispatching under Brand</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="dispatchbrand"
                                            name="dispatchbrand"
                                            className="form-control"
                                            placeholder="Dispatching under Brand"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="dispatchbrand">Dispatching under Brand</label>
                                    </div>
                    <label className="formLable" htmlFor="prnumber">PR Number</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="prnumber"
                                            name="prnumber"
                                            className="form-control"
                                            placeholder="Enter PR Number"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="prnumber">Enter PR Number</label>
                                    </div>
                    <label className="formLable" htmlFor="projectcode">Project code</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="projectcode"
                                            name="projectcode"
                                            className="form-control"
                                            placeholder="Enter Project code"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="projectcode">Enter Project code</label>
                                    </div>
                    <label className="formLable" htmlFor="address">Outlet Address</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            className="form-control"
                                            placeholder="Outlet Address"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="address">Outlet Address</label>
                                    </div>
                    <label className="formLable" htmlFor="sealable">Sales order type?</label>
                    <div className="form-label-group">
                                        <select className="form-control border-radius" id="sealable" name="sealable" onChange={handleChange}>
                                            <option >Sales order type?</option>
                                            <option>Saleable</option>
                                            <option>Non saleable</option>
                                            <option>Internal Use</option>
                                        </select>
                                    </div>
                    <label className="formLable" htmlFor="remark">Remark</label>
                    <div className="form-label-group">
                                        <input
                                            type="text"
                                            id="remark"
                                            name="remark"
                                            className="form-control"
                                            placeholder="Enter Remark"
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="remark">Enter Remark</label>
                                    </div>
                    <label className="formLable" htmlFor="statuss">Status</label>
                    <div className="form-label-group">
                                        <select className="form-control border-radius" id="statuss" name="statuss" required onChange={handleChange}>
                                            <option value="" >Status</option>
                                            <option>In Stock</option>
                                            <option>Dispatched</option>
                                        </select>
                                    </div>
                    <label className="formLable" htmlFor="enteredby">Entered By</label>
                    <div className="form-label-group pb-3 ">
                                        <input
                                        name="enteredby"
                                            type="text"
                                            className="form-control control-label"
                                            value={firstname}
                                            disabled
                                        />
                                        <label htmlFor="enteredby">Data Entered By</label>

                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>

                  </div>
                </div>
              </div>
            </div>


                            <div className="row">
                                <div className="col-xl-6 col-sm-6 col-12">
                                    <a
                                        className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                        href="/dashboard/inventory"
                                    >
                                        Back
                                    </a>
                                </div>

                                <div className="col-xl-6 col-sm-6 col-12 float-right">
                                    <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">
                                        {!props.loading ? "Create Inventory Asset" : "Please wait..."}
                                    </button>
                                </div>
                              
                            </div>


                            <div className="row">
            <div className="hr-sect">
              <span style={{ color: "#10204B", fontWeight: "600", fontSize: "30px" }}>OR</span>
            </div>
          </div>

             

                            <div className="row formLable">
                                <div className="col">
                                <div className="col-12 formContainer p-5 ">
                                    <h4 className="text-left textColor">
                                        <b> Download Excel </b>
                                    </h4>

                                    <div className="page-title-subheading text-left">
                                        <ul>Steps to follow</ul>
                                        <ul>1. Download the excel.</ul>
                                        <ul>2. Add the desired data without changing header row.</ul>
                                        <ul>
                                            3. If there is already a dropdown, select the options from
                                            dropdown.
                                        </ul>
                                    </div>
                                    <a
                                        className="btn columnsBtn btn-pill btn-wide text-uppercase mb-1"
                                        href={dashboard}
                                        download="inventoryAssets.xlsx"
                                    >
                                        {" "}
                                        Download Excel Format Here{" "}
                                    </a>
                                    </div>
                                </div>
                                <div className="col">
                                <div className="col-12 formContainer p-5">
                                    <h4 className="text-left">
                                        <b> Upload Excel </b>
                                    </h4>
                                    <div className="page-title-subheading text-left">
                                        <ul>Steps to follow</ul>
                                        <ul>1. Save the previous excel file.</ul>
                                        <ul>2. Click on choose file and select the same file.</ul>
                                        <ul>3. Click upload.</ul>
                                    </div>
                                    <input
                                        className="p-1 columnsBtn btn-pill btn-wide text-uppercase"
                                        type="file"
                                        name="file_upload"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            readExcel(file);
                                        }}
                                    />
                                </div>
                                </div>
                            </div>
                        
          <br />
          <div className="row">
            <div className="col-md-3 col-lg-3 ">
              <a
                className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                href="/dashboard/inventory"
              >
                Cancel
              </a>
            </div>
            <div className="col-md-9 col-lg-9 ">
              <button
                className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"
                onClick={handleSubmitFile}
              >
               Upload New Inventory Assets
              </button>
            </div>
          </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        saveasset: state.inventory.saveInventoryAsset
    }
}
const mapDispatchToProps = dispatch => {
    return {
        saveInventoryasset: (inventoryasset) => dispatch(saveInventoryAssetAction(inventoryasset))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInventoryAsset);