import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { SingleDatePicke } from "react-dates";
import Table from "./vehiclestatusTable";
import Loader from 'react-loader-spinner';
import { getAllHubListData, getVehicleCheckinwithtime } from "../../../../store/actions/hubActions";
import "react-toastify/dist/ReactToastify.css";

const VehicleCheckinDetail = (props) => {

    const initialValues = { hubname: "TTC" }
    const [singlehubname, setSinglehubname] = useState(initialValues)


    useEffect(() => {
        let hubb = "TTC"
        props.getHubListData();

        if (hubb == "TTC") {
            props.chargerDowntime(hubb);
        }

    }, []);



    const handleChange = (e) => {
        let hubname = e.target.value
        // console.log("dvbsd", hubname)
        const { name, value } = e.target;
        setSinglehubname({ ...singlehubname, [name]: value });

        if (e.target.value !== "" && e.target.value !== undefined) {
            props.chargerDowntime(hubname);
        }
  
         };



    const statsdata = props.chargerstats && props.chargerstats.data

    const columns = useMemo(() => [
        {
            header: 'Hub Name',
            accessor: 'hubname'
        },
        {
            header: 'Vehicle Number',
            accessor: 'vehiclenumber'
        },

        {
            header: 'Fleet Operator',
            accessor: 'fleetoperator'
        },

        {
            header: 'Vehicle Type',
            accessor: 'vehicletype'
        },

        {
            header: 'QR code',
            accessor: 'qrcode'
        },

        {
            header: 'Battery kwh',
            accessor: 'batterykwh'
        },

        {
            header: 'In Status',
            accessor: 'instatus',
            Cell: (props) => {
                return (
                    <p style={{ color: props.value === "Yes" ? "green" : "red", fontWeight: "bold" }}>
                        {props.value}
                    </p>
                );
            }
        },

    ],
        []
    );
    const hubList = props.fetchAllHubData && props.fetchAllHubData.map(id => id.hubname)

    return (

        <div className="container-fluid">
            <div className="row-fluid sideCardDesigns">
                <div className="col-md-12 col-xl-12">
                    <div className="row py-2 mt-3  ">
                        <div className="col">
                            <div className="row py-2">
                                <div className="col-md-12 col-lg-12">
                                    <div className="mb-3 h-100 ">
                                        <div className="card-header singleCard justify-content-between">
                                            <div className="col-md-4">
                                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal formHeading"><strong>Vehicle check in data</strong>
                                           
                                                </div>
                                            </div>
                                            <div className="col-md-4 text-center">
                                            <strong className ="font-weight-normal formHeading"> Total numbers of vehicles in hub <strong>{props.chargerstats.data && props.chargerstats.data.length}</strong>
                                                </strong>
                                                </div>
                                            <div className="col-md-4 text-right">
                                                <span className="pr-2">
                                                    <select className="columnsBtn p-2" name="hubname" onChange={handleChange}>
                                                    
                                                        <option value="All" >All</option>
                                                        {hubList && hubList.map((hub) => (
                                                            <option key={hub.id} value={hub} >{hub}</option>
                                                        ))}
                                                    </select>
                                                </span>

                                                {/* <label class="formLable pr-2" for="checkdate" >Date</label> */}

                                                {/* <span className="pr-2">
                                                    <input type="date" id="checkdate" name="checkdate"
                                                        onChange={handleChange} />
                                                </span>

                                                <label htmlFor="checkfromtime" className="formLable pr-2">From</label>
                                                <span className="pr-2">
                                                    <input type="time" id="checkfromtime" name="checkfromtime"
                                                        onChange={handleChange}

                                                    />
                                                </span>

                                                <label htmlFor="checktotime" className="formLable pr-2">To</label>
                                                <span className="pr-2">
                                                    <input type="time" id="checktotime" name="checktotime" placeholder="Time To" onChange={handleChange} />
                                                </span> */}

                                                {/* <button
                                                    className="dateBtn "
                                                    type="button"
                                                    onClick={handleDateClick}
                                                >
                                                    Go
                                                </button> */}
                                            </div>


                                        </div>
                                        {statsdata && statsdata.length > 0 ?
                                            (<div className="App"> <Table data={statsdata} columns={columns}></Table></div>)
                                            :
                                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-sm-12 col-12">
                        <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" href="/dashboard/stations">Back</a>
                    </div>
                    <hr className="my-4" />
                </div>

            </div>

        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        loading: state.hub.isLoading,
        fetchAllHubData: state.hub.getAllHubListData,
        chargerstats: state.hub.fetchcheckinvehiclewithdate
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getHubListData: () => dispatch(getAllHubListData()),
        chargerDowntime: (hubname) => dispatch(getVehicleCheckinwithtime(hubname))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleCheckinDetail);
