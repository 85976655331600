import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import PureBreadcrumbs from "../Breadcrums";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getChargerByStatus } from "../../../store/actions/stationAction";
import Table from "../report/table";
import "../report/table.css";
import Loader from 'react-loader-spinner';
import "./insight.css"

const ChargerStatus = (props) => {
  useEffect(() => {
    props.chargerStatus(status);
  }, [])

  const data = props.chargers && props.chargers

  const { status } = useParams()

  const columns = useMemo(

    () => [

      {
        header: 'Charge BoxId',
        accessor: 'chargeboxid',
        id: 'chargeboxid'
      },
      {
        header: 'Charger Name',
        accessor: 'chargername'
      },
      // {
      //   header: 'State',
      //   accessor: 'state'
      // },
      // {
      //   header: 'City',
      //   accessor: 'city'
      // },
      {
        header: 'Location',
        accessor: 'location'
      },
      {
        header: 'Operator',
        accessor: 'operator'
      },
      // {
      //   header: 'Location Type',
      //   accessor: 'ltype'
      // },
      {
        header: 'Project Name',
        accessor: 'ProjectName'
      },
      {
        header: 'Make',
        accessor: 'make'
      },
      {
        header: 'Charger Type',
        accessor: 'chargertype'
      },

      {
        header: 'Power Type',
        accessor: 'powertype'
      },
      // {
      //   header: 'Charger  Rating(kw)',
      //   accessor: 'rating'
      // },
      {
        header: 'Commission Date	',
        id: 'commisiondate',
        accessor: d => {
          return moment(d.commisiondate)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Status',
        accessor: 'status',
      },
      {
        header: 'Actions',
        accessor: 'chargeboxid',
        id: 'chargeboxida',
        Cell: (chargeboxid) => {
          return (
            <div>
              <span>
                <a href={`/dashboard/station/chargerstats/${chargeboxid.value}`}
                  rel="tooltip"
                  title="View "
                  className="table-action view"
                >
                  <i className="fas fa-eye"></i>
                </a></span>
            </div>

          );

        },

      }
    ],
    []
  );


  return (

    <>

      <div className="container-fluid ">
        <div className="row ">
          <div className="col col-sm-4 ">
            <PureBreadcrumbs />
          </div>
        </div>
        <div className="row">
          <div className="col sideCardDesigns mr-2">
          <div className="card-header  singleCard  justify-content-between">
                            <div className=" text-left" style={{ color: "#10204B",fontSize:"18px" }}>
                                Charger Status
                            </div>
                        </div>
            <div className="mb-3 h-100 singleCard">
              {!data.length ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
                (<div className="App"> <Table data={data} columns={columns}></Table></div>)}

            </div>
          </div>
        </div>
      </div>


    </>
  )
};

const mapStateToProps = function (state) {
  return {
    chargers: state.station.fetchchargerbystatus,
    loading: state.station.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chargerStatus: (status) => dispatch(getChargerByStatus(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargerStatus);
