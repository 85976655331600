
import { MOBILESETTING_BASE_URL,UPDATE_MOBILE_SETTING,GET_MOBILE_SETTING } from "../../components/config/configs";

 
  export const updatemobilesettingAction = (id,user) =>{
    const {token}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    console.log("id",id);
    console.log("user",user.is_sign_up);
    return dispatch => {
      return fetch(MOBILESETTING_BASE_URL+UPDATE_MOBILE_SETTING, {
        method: "POST",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
  
            "type": user.type,
            "updatedat": user.updatedat,
            "api_url": user.api_url,
            "is_sign_up": user.is_sign_up,
            "is_payment_enable": user.is_payment_enable,
            "is_close": user.is_close,
            "close_str": user.close_str,
            "is_maintenance": user.is_maintenance,
            "maintenance_str": user.maintenance_str,
            "is_app_remove": user.is_app_remove,
            "app_download_url": user.app_download_url,
            "is_tariff_price": user.is_tariff_price
       })
      })
        .then(resp => resp.json())
        .then(data => {
          // console.log("UPDATE_USER",data);
            dispatch({type: 'UPDATE_USER', payload: data})
        })
    }
  }

  export const fetchmobilesettingAction = () =>{
    const {token}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
      return fetch(MOBILESETTING_BASE_URL+GET_MOBILE_SETTING, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
        .then(resp => resp.json())
        .then(data => {
           // console.log("mobileaction", data)
            dispatch({type: 'FETCH_MOBILE_SETTTING', payload: data})
        })
    }
  }

