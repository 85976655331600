import React, { useState, useMemo, useEffect } from 'react';
import { connect } from "react-redux";
import { getLogsByChargeboxId } from "../../../store/actions/stationAction";
import ReactDataTable from "../common/reactDataTable"
import Loader from 'react-loader-spinner';
import { useParams } from "react-router-dom";
import moment from "moment";
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from "react-offcanvas";
import CloseButton from 'react-bootstrap/CloseButton';
import DatePicker from "react-datepicker";
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'

const ChargerLogs = (props) => {
  const data = props.logsList || []

  const [isMenuOpened, setisMenuOpened] = useState(false);
  const [canvasData, setCanvasData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [commandName, setCommandName] = useState("All");

  const handleStartDate = (date) => {
    setStartDate(date)
  }

  const commandFlowValues = {
    true: 'Charger to server',
    false: 'Server to charger',
  }

  // var commandFlow = data.reduce((row, p) => {
  //   // console.log("count")
  //   if (row.filter(c => c.id == p.isChargerToServer).length) {
  //     return row
  //   }
  //   row.push({
  //     id: p.isChargerToServer,
  //     label: commandFlowValues[p.isChargerToServer] ? commandFlowValues[p.isChargerToServer] : p.isChargerToServer
  //   })
  //   return row
  // }, []);


  const handleOffCanvas = (data) => {
    setisMenuOpened(true)
    setCanvasData(data)
  }

  const onTableRowClick = (rowProps, event) => {
    // console.log("Asdasdasd", rowProps, event)
    handleOffCanvas(rowProps.data)
  }

  const { chargeBoxId } = useParams()

  useEffect(() => {
    // console.log("asd")
    props.getLogsByChargeboxId(chargeBoxId);
  }, [])
  const columns = useMemo(
    () => [
      {
        header: 'Charge BoxID',
        defaultWidth: 220,
        name: 'chargeboxid'
      },
      {
        header: 'Connector No.',
        defaultWidth: 220,
        name: 'connectorno'
      },
      {
        header: 'Command',
        defaultWidth: 210,
        name: 'commandName'
      },
      {
        header: 'Command Flow',
        defaultWidth: 320,
        name: 'isChargerToServer',
        // filterEditor: SelectFilter,
        // filterEditorProps: {
        //   placeholder: 'All',
        //   dataSource: commandFlow,
        // },
        render: ({ value }) => commandFlowValues[value] ? commandFlowValues[value] : value
      },
      {
        header: 'Created At',
        defaultWidth: 240,
        name: 'createdAt',
        render: ({ value, data }) => {
          return getDateTime(value)
        }
      },
      {
        header: 'Action',
        defaultWidth: 240,
        name: 'protocol',
        render: ({ value, data }) => {
          return <div style={{ display: 'inline-block' }}>
            <a rel="tooltip" title="View Log" onClick={() => handleOffCanvas(data)} className="table-action view" >
              <i className="fa fa-eye"></i>
            </a>
          </div>
        }
      },
    ],
    []
  );

  const getDateTime = (val) => {
    const time = moment(val);
    return time.format("DD-MM-YYYY   HH:mm:ss");
  }

  const filterValue = [
    { name: 'chargeboxid', operator: 'eq', type: 'string', value: '' },
    { name: 'connectorno', operator: 'eq', type: 'string', value: '' },
    { name: 'commandName', operator: 'contains', type: 'string', value: '' },
    // { name: 'isChargerToServer', operator: 'eq', type: 'select', value: null },
    { name: 'createdAt', operator: 'contains', type: 'string', value: null },
  ];

  const commandNameList = ["BootNotification", "Heartbeat", "Heartbeat", "StopTransaction", "MeterValues", "Authorize", "RemoteStartTransaction", "RemoteStopTransaction", "TriggerMessage", "ChangeAvailability", "GetConfiguration", "ChangeConfiguration", "UnlockConnector", "Reset", "ClearCache", "UpdateFirmware", "Disconnected"]

  const BlackScreen = ({ str }) => {
    str = str !== undefined && str !== "" ? str : "{}"
    let json = JSON.parse(str)
    const styles = {
      background: 'white',
      color: 'red !important',
      height: 'fit-content',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '25px',
      padding: '20px'
    };

    return (
      <div style={styles}>
        <pre>{JSON.stringify(json, null, 2)}</pre>
      </div>
    );
  };

  const handleSearch = () => {
    let t1
    let t2
    if (startDate !== null) {
      t1 = startDate.toLocaleDateString()
    }
    if (endDate !== null) {
      t2 = endDate.toLocaleDateString()
    }
    props.getLogsByChargeboxId(chargeBoxId, commandName, t1, t2)
  }

  const handleRefresh = () => {
    setStartDate(null)
    setEndDate(null)
    setCommandName("All")
    props.getLogsByChargeboxId(chargeBoxId)
  }

  return (
    <>
      <OffCanvas
        width={370}
        transitionDuration={600}
        isMenuOpened={isMenuOpened}
        position={"right"}
        effect={"overlay"}
      >
        <OffCanvasBody>
          <div className="container-fluid">
            <div className="row rowpadding justify-content-between">
              <div className={"col "}>
                <div className=" sideCardDesigns " style={{ transition: "width 0.4s", width: (isMenuOpened ? "74%" : "100%") }}>
                  <div className="mb-3 h-100 singleCard">
                    <div className="card-header singleCard">
                      <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Logs List</strong>
                      </div>
                      <div className="card-header-title font-size-lg text-capitalize font-weight-normal"
                        style={{ paddingLeft: '25px' }}>
                        <a className="btn btn-primary-csv-download " onClick={() => handleRefresh()} style={{ backgroundColor: "#d7def2 !important", fontSize: "13px", color: "#10204B", textDecoration: "none", width: "100%" }} ><img className="pr-1" src={process.env.PUBLIC_URL + '/images/Insight_img/refresh.svg'} style={{ height: "15px" }} ></img></a>
                      </div>
                    </div>
                    <div className="App pt-2">
                      <div className="row float-right">
                        <div className="col-md-3">
                          <div className="form-label-group ">
                            <select
                              className="form-control border-radius"
                              name="commandName"
                              value={commandName}
                              onChange={(e) => { setCommandName(e.target.value) }}
                            >
                              <option disabled selected>Command type</option>
                              <option >All</option>
                              {commandNameList.map((key) => (
                                <option >{key}</option>
                              ))}
                            </select>
                            <span className="help-block text-danger">

                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <DatePicker className="form-control control-label "
                            name="startDate"
                            selected={startDate}
                            onChange={date => handleStartDate(date)}
                            isClearable
                            placeholderText="Start Date"
                            dateFormat="yyyy-MM-dd"
                            timeZone="Asia/Kolkata"
                          />
                        </div>
                        <div className="col-md-3">
                          <DatePicker className="form-control control-label "
                            name="endDate"
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            isClearable
                            placeholderText="End Date"
                            dateFormat="yyyy-MM-dd"
                            timeZone="Asia/Kolkata"
                          />
                        </div>
                        <div className="col-md-3" style={{ paddingTop: '5px' }}>
                          <div className="page-title-actions">
                            <a className="btn btn-primary-csv-download "
                              onClick={() => handleSearch()}
                              style={{ backgroundColor: "#d7def2 !important", fontSize: "13px", color: "#10204B", textDecoration: "none", width: "100%" }} >Search</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="App pt-2">
                      {!props.isLoading ?
                        (data && data.length > 0 ?
                          (<ReactDataTable data={data} columns={columns} parentName="logs-charger-list" filter={filterValue}
                            enableCSV={false} enableSearch={false} enableColumnFilter={false} onRowClick={(p, e)=>onTableRowClick(p, e)}
                          ></ReactDataTable>
                          )
                          :
                          (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>No Logs found<h3 className="loader"></h3></div>))
                        :
                        (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OffCanvasBody>

        <OffCanvasMenu
          style={{ backgroundColor: "#fff", height: "750px", padding: "15px", overflow: 'auto' }}
        >
          <div className={"col "} style={{ paddingTop: "68px" }}>
            <h3>OCPP Log Details
              <CloseButton style={{ float: "right", fontSize: "39px" }} onClick={() => {setisMenuOpened(false)}} />
            </h3>
            <div className="row py-2">
              <div className=" sideCardDesigns " style={{ width: "100%" }}>
                <div className="mb-3 h-100 singleCard">
                  <div className="card-header singleCard">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}></strong>
                      <b> Request</b>
                    </div>
                  </div>
                  <div className="App pt-2">
                    {canvasData !== undefined && canvasData !== null && canvasData.commandReqData !== undefined ? <BlackScreen str={canvasData.commandReqData} /> : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-2">
              <div className=" sideCardDesigns " style={{ width: "100%" }}>
                <div className="mb-3 h-100 singleCard">
                  <div className="card-header singleCard">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}></strong>
                      <b>Response</b>
                    </div>
                  </div>
                  <div className="App pt-2">
                    {canvasData !== undefined && canvasData !== null && canvasData.commandResData !== undefined ? <BlackScreen str={canvasData.commandResData} /> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OffCanvasMenu>
      </OffCanvas>

    </>
  );
}
const mapStateToProps = function (state) {
  return {
    logsList: state.station.logsList,
    isLoading: state.station.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLogsByChargeboxId: (chargeBoxId, commandName, startDate, endDate) => dispatch(getLogsByChargeboxId(chargeBoxId, commandName, startDate, endDate)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChargerLogs);