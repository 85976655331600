
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PureBreadcrumbs from "../../Breadcrums"
import { getAllHubListData, getHubHazardReport, getAllHubDetails } from '../../../../store/actions/hubActions';
import Table from "./hazardReportTable";
// import "../report/report.css"
import "../../report/table.css"


const ManageHubHazardReport = (props) => {
    const initialValues = { hubname: "All" }
    const [singlehubname, setSinglehubname] = useState(initialValues)
    const [previewImg, setPreviewImg] = useState("")

    useEffect(() => {
        let hubb = "All"
        props.getAllHubListData();

        if (hubb == "All") {
            props.getSingleHubHazardReport(hubb);
        }

    }, []);



    const handleChange = (e) => {
        let hubname = e.target.value
        // console.log("dvbsd", hubname)
        const { name, value } = e.target;
        setSinglehubname({ ...singlehubname, [name]: value });

        if (e.target.value !== "" && e.target.value !== undefined) {
            props.getSingleHubHazardReport(hubname);
        }

    };


    const previewImageFun = (e, previewImageStr) => {
        if (previewImageStr && previewImageStr !== "") {
            setPreviewImg(previewImageStr);
        } else {
            setPreviewImg("")
        }
    }



    const columns = useMemo(

        () => [

            {
                header: 'Hub Name',
                accessor: 'hubname'
            },
            {
                header: 'Name',
                accessor: 'name'
            },
            {
                header: 'Mobile Number',
                accessor: 'mobile'
            },
            {
                header: 'Image',
                id: 'image',
                accessor: (d) => {
                    return (
                        <>
                            <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModal" onClick={e => previewImageFun(e, d.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                        </>
                    )
                }
            },
            {
                header: 'Date',
                accessor: 'date'
            },
            {
                header: 'Time',
                accessor: 'time'
            },
            {
                header: 'Status',
                accessor: 'status'
            },
            {
                header: 'Updated By Number',
                accessor: 'updatedbynumber'
            },

        ],
        []);


    const hubList = props.fetchAllHubData && props.fetchAllHubData.map(id => id.hubname)

    const data = props.fetchSingleHubHazardReport && props.fetchSingleHubHazardReport.data
    // console.log("data", data)

    return (
        <div className="container-fluid">
            <div className="row  ">
                <div className="col col-sm-4 ">
                    <PureBreadcrumbs />
                </div>
            </div>


            {/* <div className="row rowpadding justify-content-between ">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4 mt-2 ">
                            <div className=" colpadding sideCardDesigns p-2">
                                <div className=" text-center cardbg singleCard" >
                                    <div className="card-body">
                                        <div className="media d-flex">
                                            <div className="float-right  ">
                                                <img className="float-right" src={process.env.PUBLIC_URL + '/images/hub/HubName.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                                            </div>
                                        </div>
                                        <div className="media-body float-center" style={{ color: "#10204B" }} >
                                            <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{singlehubname.hubname}</strong></h4></span>
                                            <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Hub Name</h5></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4 mt-2 ">
                            <div className="colpadding sideCardDesigns p-2">
                                <div className=" text-center cardbg singleCard" >
                                    <div className="card-body">
                                        <div className="media d-flex">
                                            <div className="float-right  ">
                                                <img className="float-right" src={process.env.PUBLIC_URL + '/images/hub/Units.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                                            </div>
                                        </div>
                                        <div className="media-body float-center " style={{ color: "#10204B" }} >
                                            <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{data == "" ? 0 : parseFloat((data.map(id => id.totalunits))[0]).toFixed(2)}</strong></h4></span>
                                            <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Total Unit</h5></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4 mt-2 ">
                            <div className=" colpadding sideCardDesigns p-2">
                                <div className=" text-center cardbg singleCard" >
                                    <div className="card-body">
                                        <div className="media d-flex">
                                            <div className="float-right  ">
                                                <img className="float-right" src={process.env.PUBLIC_URL + '/images/hub/Records.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                                            </div>
                                        </div>
                                        <div className="media-body float-center" style={{ color: "#10204B" }} >
                                            <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{totalRecord}</strong></h4></span>
                                            <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Total Records</h5></span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div> */}



            <div className="row">
                <div className="col">
                    <div className="sideCardDesigns mb-2">
                        <div className="card-header  singleCard  justify-content-between">
                            <div className=" text-left" style={{ color: "#10204B" }}>
                                Hub Wise Hazard Report
                            </div>
                            <div className=" text-right">
                                <select className=" columnsBtn p-2" name="hubname" onChange={handleChange}>
                                    <option value="All" >All</option>
                                    {hubList && hubList.map((hub) => (
                                        <option key={hub.id} value={hub} >{hub}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col">
                                <div className="row ">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="mb-3 h-100 singleCard">

                                            {data && data.length > 0 ? (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                                :
                                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#1e295d", fontWeight: "600", fontSize: "30px", marginTop: "30px" }}>No Data Available</div>)}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="mypreviewImageModal" className="modal " abindex="-1" role="dialog" data-backdrop="false" style={{ paddingTop: '100px' }}>
                            <div className="modal-dialog modal-sm p-2">
                                <div className="modal-content ">
                                    <div className="modal-body text-center ">
                                        {/* {previewImg &&
                                            <img src={`data:image/jpeg;base64,${previewImg}`} className="previewImage w-100" />
                                        } */}

                                        {(() => {
                                            if (previewImg && ((previewImg.indexOf("http://") == 0 || previewImg.indexOf("https://") == 0))) {
                                                return (
                                                    <img src={previewImg} className="previewImage w-100" />
                                                )
                                            } else {
                                                return (
                                                    <img src={`data:image/jpeg;base64,${previewImg}`} className="previewImage w-100" />
                                                )
                                            }
                                        })()}
                                    </div>

                                    <button type="button" className="btn btn-default columnsBtn p-2 m-2" data-dismiss="modal">Close</button>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = function (state) {
    return {
        fetchAllHubData: state.hub.getAllHubListData,
        fetchSingleHubHazardReport: state.hub.hubWiseHazardReport,
        fetchAllHubDetails: state.hub.getAllHubDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllHubListData: () => dispatch(getAllHubListData()),
        getSingleHubHazardReport: (hubname) => dispatch(getHubHazardReport(hubname)),
        getAllHubDetails: () => dispatch(getAllHubDetails()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageHubHazardReport);




