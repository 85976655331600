import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getMasterBookingList } from "../../../../store/actions/stationAction"


class ViewSingleChargerBooking extends Component {


    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getSingleBookingList(params.chargeboxid)
    }

    render() {
        const bookingdata = this.props.bookingsingledetail
        return (

            bookingdata.error === "Error while fetching charger booking master" ?
                <div className="text-center">No data found for this charger</div>
                : (<div className="container-fluid">
                     <div className="sideCardDesigns card-signin my-5">
                          <div className="card-body">
                    <h4 className="text-center"><b>View charger booking slot</b></h4>
        
                    <div className="row mt-3">
                        <div className="col-xl-4 col-sm-4 col-12">
                            <div className="form-label-group ">
                           
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-4 col-12">
                            <div className="form-label-group ">
                                <input type="text" id="chargeboxid" name="chargeboxid" className="form-control text-center" value={this.props.bookingsingledetail.chargeboxid} disabled />
                                <label htmlFor="chargeboxid">Chargebox Id</label>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-4 col-12">
                            <div className="form-label-group ">
                              
                            </div>
                        </div>
                    </div>
                    <h6 className="text-center"><i>Timezone - Indian Standard time</i></h6>
                    <br />

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                            <div className="table-responsive">
                                <table className="table table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Weekday</th>
                                            <th scope="col">Period Begin</th>
                                            <th scope="col">Period End</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.bookingsingledetail && this.props.bookingsingledetail.regular_hours.map((data) =>
                                            <tr>
                                                <td className="text-center">{data.weekday}</td>
                                                <td className="text-center">{data.period_begin} </td>
                                                <td className="text-center">{data.period_end} </td>

                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-sm-12 col-12">
                                    <a className="btn btn-primary btn-pill btn-wide text-uppercase float-right" href="/dashboard/stations/booking">Back</a>
                                </div>
                                <hr className="my-4" />
                            </div>
                        </div>
                    </div>
                </div>
                </div></div>
                )

        )
    }
}

const mapStateToProps = function (state) {
    return {
        bookingsingledetail: state.station.fetchbookingmaster,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleBookingList: (chargeboxid) => dispatch(getMasterBookingList(chargeboxid))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleChargerBooking));
