const initState={
    isLoading:false,
    fetchReportList:'',
    fetchChargingData:'',
    fetchkwhData:'',
    fetchTimeData:'',
    fetchRevenueData:'',
    
}

const reportReducer=(state=initState,action)=>{
    switch (action.type) {
        case 'REPORT_LOADING':
            return{...state,isLoading:true}
         case 'FETCH_REPORT_LIST':
           return {...state, fetchReportList: action.payload,isLoading:true}
           case 'FETCH_REPORT_CHARGING_LIST':
            return {...state, fetchChargingData: action.payload,isLoading:false}
            case 'FETCH_REPORT_KWH_LIST':
            return {...state, fetchkwhData: action.payload,isLoading:false}
            case 'FETCH_REPORT_TIME_LIST':
                return {...state, fetchTimeData: action.payload,isLoading:false}
                case 'FETCH_REPORT_REVENUE_LIST':
                return {...state, fetchRevenueData: action.payload,isLoading:false}
                default:
          return state;
      }
    }

export default reportReducer;