import React, { useEffect } from "react"
import { connect } from "react-redux";
import { CardBody } from "reactstrap";
import { Bar } from 'react-chartjs-2';
import { transactionDataAction } from "../../../store/actions/transactionAction";
import { fleetTranListAction } from "../../../store/actions/fleetAction";

const ConnectorDataChart = (props) => {

    useEffect(() => {

        props.fetchTransactionData("All", "All")
        props.fleetTransData("All")
    }, []);

    //Individual connector data logic
    const connectorIndiviualData = props.transactions && props.transactions.transactiondata !== 0 ? props.transactions && props.transactions.transactiondata : 0
    // console.log("dfdew",connectorIndiviualData)
    let type2_rev = [];
    let type2_unit = [];
    let IEC60309_rev = [];
    let IEC60309_unit = [];
    let GBT_rev = [];
    let GBT_unit = [];
    let CCS2_rev = [];
    let CCS2_unit = []
    let GBT2_rev = [];
    let GBT2_unit = [];
    let CHAdeMO_rev = [];
    let CHAdeMO_unit = []


    for (let i = 0; i < connectorIndiviualData.length; i++) {
        if (connectorIndiviualData[i].connectortype === 'Type 2') {
            type2_rev.push(connectorIndiviualData[i].billedamount)
            type2_unit.push(connectorIndiviualData[i].kwh)
        }
        else if (connectorIndiviualData[i].connectortype === 'IEC60309') {
            IEC60309_rev.push(connectorIndiviualData[i].billedamount)
            IEC60309_unit.push(connectorIndiviualData[i].kwh)
        }
        else if (connectorIndiviualData[i].connectortype === 'GBT') {
            GBT_rev.push(connectorIndiviualData[i].billedamount)
            GBT_unit.push(connectorIndiviualData[i].kwh)
        }
        else if (connectorIndiviualData[i].connectortype === 'CCS2') {
            CCS2_rev.push(connectorIndiviualData[i].billedamount)
            CCS2_unit.push(connectorIndiviualData[i].kwh)
        }
        else if (connectorIndiviualData[i].connectortype === 'GBT2') {
            GBT2_rev.push(connectorIndiviualData[i].billedamount)
            GBT2_unit.push(connectorIndiviualData[i].kwh)
        }
        else if (connectorIndiviualData[i].connectortype === 'CHAdeMO') {
            CHAdeMO_rev.push(connectorIndiviualData[i].billedamount)
            CHAdeMO_unit.push(connectorIndiviualData[i].kwh)
        }
    }
    const type2_ind_revtotal = type2_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const type2_ind_unittotal = type2_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    const IEC60309_ind_revtotal = IEC60309_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const IEC60309_ind_unittotal = IEC60309_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    const GBT_ind_revtotal = GBT_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const GBT_ind_unittotal = GBT_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    const CCS2_ind_revtotal = CCS2_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const CCS2_ind_unittotal = CCS2_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    const GBT2_ind_revtotal = GBT2_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const GBT2_ind_unittotal = GBT2_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    const CHAdeMO_ind_revtotal = CHAdeMO_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const CHAdeMO_ind_unittotal = CHAdeMO_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    //Individual connector data logic end

    //Fleet connector data logic

    // const connectorFleetData = props.fleettran && props.fleettran.transactiondata

    // let type2_fleet_rev = [];
    // let type2_fleet_unit = [];
    // let IEC60309_fleet_rev = [];
    // let IEC60309_fleet_unit = [];
    // let GBT_fleet_rev = [];
    // let GBT_fleet_unit = [];
    // let CCS2_fleet_rev = [];
    // let CCS2_fleet_unit = []
    // let GBT2_fleet_rev = [];
    // let GBT2_fleet_unit = [];
    // let CHAdeMO_fleet_rev = [];
    // let CHAdeMO_fleet_unit = []

    // for (let i = 0; i < connectorFleetData.length; i++) {
    //     if (connectorFleetData[i].connectortype === 'Type 2') {
    //         type2_fleet_rev.push(connectorFleetData[i].billedamount)
    //         type2_fleet_unit.push(connectorFleetData[i].kwh)
    //     }
    //     else if (connectorFleetData[i].connectortype === 'IEC60309') {
    //         IEC60309_fleet_rev.push(connectorFleetData[i].billedamount)
    //         IEC60309_fleet_unit.push(connectorFleetData[i].kwh)
    //     }
    //     else if (connectorFleetData[i].connectortype === 'GBT') {
    //         GBT_fleet_rev.push(connectorFleetData[i].billedamount)
    //         GBT_fleet_unit.push(connectorFleetData[i].kwh)
    //     }
    //     else if (connectorFleetData[i].connectortype === 'CCS2') {
    //         CCS2_fleet_rev.push(connectorFleetData[i].billedamount)
    //         CCS2_fleet_unit.push(connectorFleetData[i].kwh)
    //     }
    //     else if (connectorFleetData[i].connectortype === 'GBT2') {
    //         GBT2_fleet_rev.push(connectorFleetData[i].billedamount)
    //         GBT2_fleet_unit.push(connectorFleetData[i].kwh)
    //     }
    //     else if (connectorFleetData[i].connectortype === 'CHAdeMO') {
    //         CHAdeMO_fleet_rev.push(connectorFleetData[i].billedamount)
    //         CHAdeMO_fleet_unit.push(connectorFleetData[i].kwh)
    //     }
    // }


    // const type2_fleet_revtotal = type2_fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    // const type2_fleet_unittotal = type2_fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    // const IEC60309_fleet_revtotal = IEC60309_fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    // const IEC60309_fleet_unittotal = IEC60309_fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    // const GBT_fleet_revtotal = GBT_fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    // const GBT_fleet_unittotal = GBT_fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    // const CCS2_fleet_revtotal = CCS2_fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    // const CCS2_fleet_unittotal = CCS2_fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    // const GBT2_fleet_revtotal = GBT2_fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    // const GBT2_fleet_unittotal = GBT2_fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    // const CHAdeMO_fleet_revtotal = CHAdeMO_fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    // const CHAdeMO_fleet_unittotal = CHAdeMO_fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    //Fleet connector data logic end
    //sd
    //combined total

    let type2_combined_rev = (Math.ceil(type2_ind_revtotal));
    let type2_combined_unit = (Math.ceil(type2_ind_unittotal));
    let IEC60309_combined_rev = (Math.ceil(IEC60309_ind_revtotal));
    let IEC60309_combined_unit = (Math.ceil(IEC60309_ind_unittotal));
    let GBT_combined_rev = (Math.ceil(GBT_ind_revtotal));
    let GBT_combined_unit = (Math.ceil(GBT_ind_unittotal));
    let CCS2_combined_rev = (Math.ceil(CCS2_ind_revtotal));
    let CCS2_combined_unit = (Math.ceil(CCS2_ind_unittotal));
    let GBT2_combined_rev = (Math.ceil(GBT2_ind_revtotal));
    let GBT2_combined_unit = (Math.ceil(GBT2_ind_unittotal));
    let CHAdeMO_combined_rev = (Math.ceil(CHAdeMO_ind_revtotal));
    let CHAdeMO_combined_unit = (Math.ceil(CHAdeMO_ind_unittotal));



    //Individual location type data logic 
    //Public Redidential Fleet Private commercial
    let residential_rev = [];
    let residential_unit = [];
    let public_rev = [];
    let public_unit = [];
    let fleet_rev = [];
    let fleet_unit = [];
    let commercial_rev = [];
    let commercial_unit = [];
    let private_rev = [];
    let private_unit = [];

    for (let i = 0; i < connectorIndiviualData.length; i++) {
        if (connectorIndiviualData[i].locationtype === 'Captive Residential') {
            residential_rev.push(connectorIndiviualData[i].amount + connectorIndiviualData[i].gst)
            residential_unit.push(connectorIndiviualData[i].kwh)
        }
        else if (connectorIndiviualData[i].locationtype === 'Public Charging Station') {
            public_rev.push(connectorIndiviualData[i].amount + connectorIndiviualData[i].gst)
            public_unit.push(connectorIndiviualData[i].kwh)
        }
        else if (connectorIndiviualData[i].locationtype === 'Fleet') {
            fleet_rev.push(connectorIndiviualData[i].amount + connectorIndiviualData[i].gst)
            fleet_unit.push(connectorIndiviualData[i].kwh)
        }
        else if (connectorIndiviualData[i].locationtype === 'Captive Commercial') {
            commercial_rev.push(connectorIndiviualData[i].amount + connectorIndiviualData[i].gst)
            commercial_unit.push(connectorIndiviualData[i].kwh)
        }
        else if (connectorIndiviualData[i].locationtype === 'Private') {
            private_rev.push(connectorIndiviualData[i].amount + connectorIndiviualData[i].gst)
            private_unit.push(connectorIndiviualData[i].kwh)
        }
    }

    const residential_ind_revtotal = residential_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const residential_ind_unittotal = residential_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    const public_ind_revtotal = public_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const public_ind_unittotal = public_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    const fleet_ind_revtotal = fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const fleet_ind_unittotal = fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    const commercial_ind_revtotal = commercial_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const commercial_ind_unittotal = commercial_unit.reduce((partial_sum, a) => partial_sum + a, 0);

    const private_ind_revtotal = private_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    const private_ind_unittotal = private_unit.reduce((partial_sum, a) => partial_sum + a, 0);

    //Fleet locationtype data logic
    // let residential_fleet_rev = [];
    // let residential_fleet_unit = [];
    // let public_fleet_rev = [];
    // let public_fleet_unit = [];
    // let fleet_fleet_rev = [];
    // let fleet_fleet_unit = [];


    // for (let i = 0; i < connectorFleetData.length; i++) {
    //     if (connectorFleetData[i].connectortype === 'Residential') {
    //         residential_fleet_rev.push(connectorFleetData[i].billedamount)
    //         residential_fleet_unit.push(connectorFleetData[i].kwh)
    //     }
    //     else if (connectorFleetData[i].connectortype === 'Public') {
    //         public_fleet_rev.push(connectorFleetData[i].billedamount)
    //         public_fleet_unit.push(connectorFleetData[i].kwh)
    //     }
    //     else if (connectorFleetData[i].connectortype === 'Fleet') {
    //         fleet_fleet_rev.push(connectorFleetData[i].billedamount)
    //         fleet_fleet_unit.push(connectorFleetData[i].kwh)
    //     }

    // }

    // const residential_fleet_revtotal = type2_fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    // const residential_fleet_unittotal = type2_fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    // const public_fleet_revtotal = IEC60309_fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    // const public_fleet_unittotal = IEC60309_fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    // const fleet_fleet_revtotal = GBT_fleet_rev.reduce((partial_sum, a) => partial_sum + a, 0);
    // const fleet_fleet_unittotal = GBT_fleet_unit.reduce((partial_sum_unit, b) => partial_sum_unit + b, 0);

    //combined locationtype data
    let residential_combined_rev = (Math.ceil(residential_ind_revtotal));
    let residential_combined_unit = (Math.ceil(residential_ind_unittotal));
    let public_combined_rev = (Math.ceil(public_ind_revtotal));
    let public_combined_unit = (Math.ceil(public_ind_unittotal));
    let fleet_combined_rev = (Math.ceil(fleet_ind_revtotal));
    let fleet_combined_unit = (Math.ceil(fleet_ind_unittotal));
    let commercial_combined_rev = (Math.ceil(commercial_ind_revtotal));
    let commercial_combined_unit = (Math.ceil(commercial_ind_unittotal));
    let private_combined_rev = (Math.ceil(private_ind_revtotal));
    let private_combined_unit = (Math.ceil(private_ind_unittotal));




    const plugin = {
        beforeInit(chart) {
            const originalFit = chart.legend.fit;
            chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
                this.height += 25;
            }
        }
    }

    let ConnectorChart = {
        data: (canvas) => {
            return {
                labels: ["Type 2", "IEC60309", "GBT", "CCS2", "GBT2", "CHAdeMO"],
                datasets: [
                    {
                        label: "Revenue (in INR)",
                        backgroundColor: "#1E295D",
                        data: [type2_combined_rev, IEC60309_combined_rev, GBT_combined_rev, CCS2_combined_rev, GBT2_combined_rev, CHAdeMO_combined_rev],
                    },
                    {
                        label: "Consumptions (in kW)",
                        backgroundColor: "#D1108C",
                        data: [type2_combined_unit, IEC60309_combined_unit, GBT_combined_unit, CCS2_combined_unit, GBT2_combined_unit, CHAdeMO_combined_unit],
                    },
                ],
            };
        },
    };

    let LocationtypeChart = {
        data: (canvas) => {
            return {
                labels: ["Captive Residential", "Captive Commercial", "Public Charging Station", "Fleet", "Private"],
                datasets: [
                    {
                        label: "Revenue (in INR)",
                        backgroundColor: "#1E295D",
                        data: [residential_combined_rev, commercial_combined_rev, public_combined_rev, fleet_combined_rev, private_combined_rev],
                    },
                    {
                        label: "Consumptions (in kW)",
                        backgroundColor: "#D1108C",
                        data: [residential_combined_unit, commercial_combined_unit, public_combined_unit, fleet_combined_unit, private_combined_unit],
                    },
                ],
            };
        },
    };



    return (
        <>
            <div className="row rowpadding mt-4">
                <div className="col-md-6  ">
                    <div className="col-md-12  sideCardDesigns">
                        <CardBody >
                            <span className="charttitle"> Total Revenue & Consumption by Connector Type </span>
                            <div className="chart-area">
                                <Bar
                                    data={ConnectorChart.data}
                                    plugins={plugin}
                                    options={{
                                        legend: {
                                            display: true,
                                            labels: {
                                                fontColor: 'black',
                                                fontSize: 15,
                                                padding: 20,
                                                usePointStyle: true,
                                                //width of circles in legend
                                                boxWidth: 9

                                            }
                                        },

                                        tooltips: {
                                            mode: 'index'
                                        },
                                        responsive: true,

                                        animation: {
                                            duration: 0,
                                        },
                                        hover: {
                                            animationDuration: 0,
                                        },
                                        responsiveAnimationDuration: 0,

                                        scales: {
                                            xAxes: [{
                                                gridLines: {
                                                    color: "rgba(0, 0, 0, 0)",
                                                }
                                            }],
                                            yAxes: [{
                                                gridLines: {
                                                    color: "rgba(0, 0, 0, 0)",
                                                }
                                            }]
                                        },
                                        tooltips: {
                                            enabled: true
                                        },
                                        animation: {
                                            duration: 10,
                                            onComplete: function () {
                                                var chartInstance = this.chart,
                                                    ctx = chartInstance.ctx;
                                                ctx.textAlign = 'center';
                                                ctx.color = "black";
                                                ctx.fillStyle = "black";
                                                ctx.textBaseline = 'bottom';
                                                this.data.datasets.forEach(function (dataset, i) {
                                                    var meta = chartInstance.controller.getDatasetMeta(i);
                                                    meta.data.forEach(function (bar, index) {
                                                        var data = dataset.data[index];
                                                        if (data > 999 && data < 1000000) {
                                                            var rdata = (data / 1000).toFixed() + 'K';     // convert to K for number from > 1000 < 1 million 
                                                            ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                                                        } else if (data > 1000000) {
                                                            var rdata = (data / 1000000).toFixed() + 'M';  // convert to M for number from > 1 million 
                                                            ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                                                        } else if (data < 900) {
                                                            ctx.fillText(data, bar._model.x, bar._model.y - 5); // if value < 1000, nothing to do
                                                        }

                                                        // ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                    });
                                                });
                                            }
                                        }
                                    }
                                    }
                                />
                            </div>
                        </CardBody>
                    </div>
                </div>


                <div className="col-md-6   ">
                    <div className="col-md-12 sideCardDesigns">
                        <CardBody >
                            <span className="charttitle"> Total Revenue & Consumption by Location Type </span>
                            <div className="chart-area">
                                <Bar
                                    data={LocationtypeChart.data}
                                    plugins={plugin}
                                    options={{
                                        legend: {
                                            display: true,
                                            labels: {
                                                fontColor: 'black',
                                                fontSize: 15,
                                                padding: 20,
                                                usePointStyle: true,
                                                //width of circles in legend
                                                boxWidth: 9

                                            }
                                        },
                                        tooltips: {
                                            mode: 'index'
                                        },
                                        responsive: true,
                                        maintainAspectRatio: true,
                                        animation: {
                                            duration: 0,
                                        },
                                        hover: {
                                            animationDuration: 0,
                                        },
                                        responsiveAnimationDuration: 0,
                                        scales: {
                                            xAxes: [{
                                                gridLines: {
                                                    color: "rgba(0, 0, 0, 0)",
                                                }
                                            }],
                                            yAxes: [{
                                                gridLines: {
                                                    color: "rgba(0, 0, 0, 0)",
                                                }
                                            }]
                                        },
                                        tooltips: {
                                            enabled: true
                                        },
                                        animation: {
                                            duration: 10,
                                            onComplete: function () {
                                                var chartInstance = this.chart,
                                                    ctx = chartInstance.ctx;
                                                ctx.textAlign = 'center';
                                                ctx.color = "black";
                                                ctx.fillStyle = "black";
                                                ctx.textBaseline = 'bottom';
                                                this.data.datasets.forEach(function (dataset, i) {
                                                    var meta = chartInstance.controller.getDatasetMeta(i);
                                                    meta.data.forEach(function (bar, index) {
                                                        var data = dataset.data[index];

                                                        if (data > 999 && data < 1000000) {
                                                            var rdata = (data / 1000).toFixed() + 'K';     // convert to K for number from > 1000 < 1 million 
                                                            ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                                                        } else if (data > 1000000) {
                                                            var rdata = (data / 1000000).toFixed() + 'M';  // convert to M for number from > 1 million 
                                                            ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                                                        } else if (data < 900) {
                                                            ctx.fillText(data, bar._model.x, bar._model.y - 5); // if value < 1000, nothing to do
                                                        }
                                                        // ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                    });
                                                });
                                            }
                                        }
                                    }
                                    }
                                />
                            </div>
                        </CardBody>
                    </div>
                </div>
            </div>


        </>
    )

}



const mapStateToProps = (state) => {
    return {

        transactions: state.transaction.fetchTransactionData,
        fleettran: state.fleet.fetchFleetTranList,

    }
}
const mapDispatchToProps = dispatch => {
    return {

        fetchTransactionData: (viewtype, planname) => dispatch(transactionDataAction(viewtype, planname)),
        fleetTransData: (planname) => dispatch(fleetTranListAction(planname)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConnectorDataChart);