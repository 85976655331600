import React, { Component } from "react"
import { deactivateAction, getUsersAction } from "../../../store/actions/userAction"
import { connect } from "react-redux";
import PureBreadcrumbs from "../Breadcrums"
import UserTableView from "../access/userdetailstable"

class AccessCardList extends Component {
  state = {
    token: '',
    username: '',
    show: ''
  }
  componentDidMount() {
    this.props.getUserList(this.state)
  }

  //activate deactivate user
  activateDeactivateUser = (user, userstatus) => {
    this.props.deactivateUser(user, userstatus);
    this.props.getUserList(this.state);

  }
  countType(type) {
    if (this.props.userlist != null) {
      const list = this.props.userlist;
      const countTypes = Object.keys(list).filter(user => list[user].accountstatus === type);
      return countTypes.length;
    } else {

      return 0;
    }

  }

  render() {
    return (

      <div className="conatiner-fluid mr-3">
        <div className="row">
          <div className="col col-sm-4 ">
            <PureBreadcrumbs />
          </div>
        </div>

        <div className="row rowpadding mb-4">
          <div className="col-sm-12 col-md-4">
            <div className="col-md-12 sideCardDesigns m-1 ">
              <div className="row">
                <div className="col-sm colpadding">
                  <div className="singleCard text-center cardbg" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-right pl-5 ">
                          <img className="float-right" src={process.env.PUBLIC_URL + '/images/user_logo/totalUser.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{this.props.userlist && this.props.userlist ? this.props.userlist.length : 0}</strong></h4></span>
                        <span className="stats"  > <h5 style={{ fontSize: "18px", textAlign: "center" }}>Total Users</h5></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="col-md-12 sideCardDesigns m-1 ">
              <div className="row">
                <div className="col-sm colpadding">
                  <div className="singleCard text-center cardbg" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-left pl-5">
                          <img className="float-left" src={process.env.PUBLIC_URL + '/images/user_logo/activeUser.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{this.countType(true)}</strong></h4></span>
                        <span className="stats"  > <h5 style={{ fontSize: "18px", textAlign: "center" }}>Active Users</h5></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 ">
            <div className="col-md-12 sideCardDesigns m-1 ">
              <div className="row">
                <div className="col-sm colpadding">
                  <div className="singleCard text-center cardbg" >
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="float-left pl-5 ">
                          <img className="float-left" src={process.env.PUBLIC_URL + '/images/user_logo/inactiveUser.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                        </div>
                      </div>
                      <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                        <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{this.countType(false)}</strong></h4></span>
                        <span className="stats"  > <h5 style={{ fontSize: "18px", textAlign: "center" }}>Inactive Users</h5></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row rowpadding m-1 mb-2 sideCardDesigns ">
          <div className="col-6 p-3">
            <div className="col-12 ml-2 ">
              <img src={process.env.PUBLIC_URL + '/images/user_logo/assignUser.svg'} style={{ height: "34px", width: "32px" }} alt="" />
              <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>Assign User Access</span>
              <div className="page-title-subheading pt-2" style={{ fontSize: "12px", color: "#bc0078", fontWeight: "550" }}>Give privilages and assign roles to different users</div>
            </div>
          </div>
          <div className="col-6">
            <div className="text-right m-3 pt-3">
              <a href={`/dashboard/access/assignaccess`} className="btn btn-primary" >Assign User Access</a>
            </div>
          </div>
        </div>

        <UserTableView></UserTableView>
      </div>
    )
  }
}


const mapStateToProps = function (state) {
  return {
    userlist: state.users.fetchList,
    response: state.users.deactivateUser
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getUserList: (user) => dispatch(getUsersAction(user)),
    deactivateUser: (user, userstatus) => dispatch(deactivateAction(user, userstatus))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessCardList);

