import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  countryAction,
  stateAction,
} from "../../../store/actions/commonAction";
import {
  networkEnterpriseOperatorAction,
  operatorAction,
} from "../../../store/actions/opAction";
import { savePriceAction } from "../../../store/actions/priceAction";
import { getSubscriptionPlanByCreatorAction } from "../../../store/actions/subscriptionAction";


const CreatePriceModel = (props) => {
  const [price, setPrice] = useState();
  const history = useHistory();
  const [isPriceGroup, setPriceGroup] = useState(false);
  const toggleChecked = () => setPriceGroup((value) => !value);
  const { firstname } = JSON.parse(localStorage.getItem("user"));
  // console.log("isPriceGroup",isPriceGroup)
  useEffect(() => {
    props.npData("Network Operator");
    props.countrylist();
    props.plantypelist();
  }, []);
  const handleChange = (e) => {
    setPrice({
      ...price,
      [e.target.name]: e.target.value.trim(),
    });
    if (e.target.name === "networkoperator") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let createdby = el.getAttribute("id");
      props.epData(createdby, "Enterprise");
    }
    if (e.target.name === "country") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let countrycode = el.getAttribute("id");
      props.statelist(countrycode);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.savePrice(price, isPriceGroup);
  };
  if (props.response.error === "" && props.response.result !== "") {
    return (
      <Redirect
        to={"/dashboard/pricing/addpricelevel/" + props.response.result}
      ></Redirect>
    );
  }
  return (

    <>


      <div className="container-fluid">
        <div className="sideCardDesigns card-signin">
          <div className="card-body">
            <h4 className="text-center formHeading">
              <b>Create Price Plan</b>
            </h4>
            <form className="form" onSubmit={handleSubmit} autoComplete="off">

              <div className="row rowpadding  justify-content-between">
                <div className="col-md-6 col-sm-12 ">
                  <div className="col-12  formContainer">
                    <div className="col-12">
                      <label className="formLable" htmlFor="networkoperator">Network Operator</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          id="noperator"
                          name="networkoperator"
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Network Operator</option>
                          {props.operatorlist &&
                            props.operatorlist.map((network) => (
                              <option
                                key={network.id}
                                id={network.id}
                                value={network.organization}
                              >
                                {network.organization}
                              </option>
                            ))}
                        </select>
                      </div>
                      <label className="formLable" htmlFor="enterprise">Enterprise</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="enterprise"
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Enterprise(Optional)</option>
                          {props.enterpriselist === null ? (
                            <option value="N/A">None</option>
                          ) : (
                            props.enterpriselist &&
                            props.enterpriselist.map((enterprise) => (
                              <option
                                key={enterprise.id}
                                value={enterprise.organization}
                              >
                                {enterprise.organization}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="name">Price Name</label>
                      <div className="form-label-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control control-label"
                          placeholder="Price Name"
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="name">Price Name</label>
                      </div>

                      <label className="formLable" htmlFor="plantype">Subscription Plan</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="plantype"
                          onChange={handleChange}
                          required
                        >
                          <option value="">Subscription Plan</option>
                          {props.plantypleres &&
                            props.plantypleres.map((pt) => (
                              <option key={pt.id} value={pt.name}>
                                {pt.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="currency">Currency</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          id="currency"
                          name="currency"
                          onChange={handleChange}
                        >
                          <option value="N/A">Currency</option>
                          <option value="INR">INR</option>
                          <option value="USD">USD</option>
                        </select>
                      </div>

                      <label className="formLable" htmlFor="pricestatus">Draft</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="pricestatus"
                          onChange={handleChange}
                        >
                          <option>Draft</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 ">
                  <div className="col-12  formContainer ">

                    <div className="col-12 ">

                      <label className="formLable" htmlFor="country">Country</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="country"
                          onChange={handleChange}
                        >
                          <option value="N/A">Select Country</option>
                          {props.countries &&
                            props.countries.map((country) => (
                              <option
                                key={country.id}
                                id={country.code}
                                value={country.name}
                              >
                                {country.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="state">State</label>
                      <div className="form-label-group">
                        <select
                          className="form-control border-radius"
                          name="state"
                          onChange={handleChange}
                        >
                          <option value="N/A">Select State</option>
                          {props.states &&
                            props.states.map((st) => (
                              <option key={st.code} value={st.name}>
                                {st.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="price_group">Is Price Group</label>
                      <div className="form-label-group containerAme p-3">
                        <input
                          type="checkbox"
                          name="price_group"
                          checked={isPriceGroup}
                          onChange={toggleChecked}
                        />{" "}
                        Is Price Group?
                      </div>

                      <label className="formLable" htmlFor="enterdby">Entered By</label>
                      <div className="form-label-group ">
                        <input
                        name="enterdby"
                          type="text"
                          className="form-control control-label"
                          value={firstname}
                          disabled
                        />
                        <label htmlFor="enterdby">Data Entered By</label>
                      </div>

                      <label className="formLable" htmlFor="description">Description</label>
                        <div className="form-label-group pb-2">
                          <textarea
                            className="form-control "
                            id="description"
                            name="description"
                            placeholder="Description(Optional)"
                            rows="3"
                            onChange={handleChange}
                          ></textarea>
                    
                      </div>

                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

             
                <div className="row">
                  <div className="col">
                    {props.response && props.response.error != "" ? (
                      <div className="alert alert-danger" role="alert">
                        {props.response.error}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md-6 col-lg-6 ">
                    <a
                      className="btn columnsBtn btn-pill btn-wide text-uppercase"
                      href="/dashboard/pricing"
                    >
                      Cancel
                    </a>
                  </div>

                  <div className="col-md-6 col-lg-6 ">
                    <button
                      className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"
                    >
                      Create
                    </button>
                  </div>
                </div>

            </form>
          </div>
        </div>
      </div>

    </>
  );
};

const mapStateToProps = function (state) {
  return {
    operatorlist: state.operator.fetchOperator,
    enterpriselist: state.operator.fetchEnterprise,
    countries: state.common.countryList,
    states: state.common.stateList,
    response: state.price.savePriceResponse,
    plantypleres: state.subscriptionplan.fetcSubscriptionplan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    npData: (nrole) => dispatch(operatorAction(nrole)),
    epData: (nid, nrole) =>
      dispatch(networkEnterpriseOperatorAction(nid, nrole)),
    countrylist: () => dispatch(countryAction()),
    statelist: (countrycode) => dispatch(stateAction(countrycode)),
    savePrice: (price, isPriceGroup) => dispatch(savePriceAction(price, isPriceGroup)),
    plantypelist: () => dispatch(getSubscriptionPlanByCreatorAction("All")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePriceModel);
