import React, { useState } from "react"
import { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { getPriceSingleAction, updatePriceLevelAction } from "../../../store/actions/priceAction";
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Weak7days = [{ code: "monday", name: "Monday" }, { code: "tuesday", name: "Tuesday" }, { code: "wednesday", name: "Wednesday" }, { code: "thursday", name: "Thursday" }, { code: "friday", name: "Friday" }, { code: "saturday", name: "Saturday" }, { code: "sunday", name: "Sunday" }]
var weekDays = [];
const CreatePriceLevel = (props) => {
  const { priceid } = useParams()
  const [starttime, setStartTime] = useState('00:00:00');
  const [endtime, setEndTime] = useState('23:59:59');
  const [pricestatus, setPriceStatus] = useState();
  const [weeks, setWeeks] = useState();
  const [inputList, setInputList] = useState([{ power: "", kwh: Number, pricetype: "", priceunit: "", amount: Number, idlefee: Number, graceperiod: Number }]);
  const showSecond = true;
  const str = showSecond ? 'HH:mm:ss a' : 'HH:mm a';
  useEffect(() => {
    props.fetPrice(priceid);
  }, []);

  const handleChange = (e) => {
    setPriceStatus({
      ...pricestatus,
      [e.target.name]: e.target.value.trim()
    });
  }
  const onStartTimeChange = (value) => {
    setStartTime(value.format(str))
  }
  const onEndTimeChange = (value) => {
    setEndTime(value.format(str))
  }
  const handleWeekChange = (e) => {
    const target = e.target;
    let pvalue = target.value;
    let pid = target.id
    if (target.checked) {
      weekDays.push({ key: pid, name: pvalue })
    } else {
      weekDays.splice(pvalue, 1);
    }
    setWeeks(weekDays)
  }
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e.preventDefault()
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { power: "", kwh: Number, pricetype: "", priceunit: "", amount: Number, idlefee: Number, graceperiod: Number }]);
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    props.updatePriceLevel(props.pricedata, inputList, pricestatus, starttime, endtime, weeks, priceid)
  }
  const notify = () => {
    toast("Price Plan Created");
  };
  // console.log("props.pricedata",props.pricedata);
  if (props.response && props.response.error === '') {
    return <Redirect to='/dashboard/pricing' />;
  }
  return (

    <div className="container-fluid">
      <div className="sideCardDesigns card-signin">
        <div className="card-body">
          <h4 className="text-center formHeading">
            <b>Price Plan Detail</b>
          </h4>

          <form className="form mt-4" onSubmit={handleSubmit} autoComplete="off">

            <div className="row formContainer m-1">
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  ">
                  <ul className="">
                    <li className=" m-2">Network Operator</li>
                    <li className=" m-2" >Enterprise</li>
                    <li className=" m-2">Price Plan Name</li>
                    <li className=" m-2">Currency</li>
                    <li className=" m-2">Country</li>
                    <li className=" m-2">State</li>
                    <li className=" m-2">Status</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  ">
                  <ul className="">
                    <li className=" m-2">{props.pricedata.operator !== null ? props.pricedata.operator : "N/A"}</li>
                    <li className=" m-2">{props.pricedata.enterprise !== "" ? props.pricedata.enterprise : "N/A"}</li>
                    <li className=" m-2">{props.pricedata.name !== "" ? props.pricedata.name : "N/A"}</li>
                    <li className=" m-2">{props.pricedata.currency !== "" ? props.pricedata.currency : "N/A"}</li>
                    <li className=" m-2">{props.pricedata.country !== "" ? props.pricedata.country : "N/A"}</li>
                    <li className=" m-2">{props.pricedata.state !== "" ? props.pricedata.state : "N/A"}</li>

                    <select className=" m-2" name="pricestatus" onChange={handleChange}>
                      <option>Active</option>
                      <option>Draft</option>
                    </select>

                  </ul>
                </div>
              </div>
            </div>




            <nav aria-label="breadcrumb  ">
              <ol className="breadcrumb mt-3">
                <li className="breadcrumb-item active" aria-current="page">Days of Week</li>
              </ol>
            </nav>
            <div className="row  ">
              <div className="col-12">
                {Weak7days && Weak7days.map((day) =>
                  <div className="form-check form-check-inline border badge badge-pill text py-1 px-2 containerAme" key={day.code}>
                    <input className="form-check-input" type="checkbox" name="scheduledays" id={day.code} value={day.name} onChange={handleWeekChange} />
                    <label className="form-check-label" htmlFor={day.code}>{day.name}</label>
                  </div>
                )}
              </div>

            </div>


            <nav aria-label="breadcrumb ">
              <ol class="breadcrumb mt-3">
                <li className="breadcrumb-item active" aria-current="page">Time</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-12">
                <div className="text-left mb-3">
                  <TimePicker
                    // style={{ width: 200 }}
                    defaultValue={moment({ starttime })}
                    className="xxx "
                    onChange={onStartTimeChange}
                  />
                  <TimePicker
                    // style={{ width: 200 }}
                    defaultValue={moment({ endtime })}
                    className="xxx"
                    onChange={onEndTimeChange}
                  />
                </div>
              </div>
            </div>
            <div className="my-2" />
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mt-3">
                <li className="breadcrumb-item active" aria-current="page">Add Price Component</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div className="table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Power</th>
                        <th scope="col">kW</th>
                        <th scope="col">Price Type</th>
                        <th scope="col">Price Unit</th>
                        <th scope="col">Price</th>
                        <th scope="col">Idle Fee</th>
                        <th scope="col">Grace Period</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputList.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td >
                              <select className="border-radius" name="power" value={x.power} onChange={e => handleInputChange(e, i)}>
                                <option>Select Power</option>
                                <option>AC</option>
                                <option>DC</option>
                              </select>
                            </td>
                            <td >
                              <select className="border-radius" name="kwh" value={x.kwh} onChange={e => handleInputChange(e, i)}>
                                <option value="">KW</option>
                                <option>450</option>
                                <option>350</option>
                                <option>150</option>
                                <option>125</option>
                                <option>100</option>
                                <option>75</option>
                                <option>50</option>
                                <option>25</option>
                              </select>
                            </td>
                            <td >
                              <select className="border-radius" name="pricetype" value={x.pricetype} onChange={e => handleInputChange(e, i)}>
                                <option value="">Price Type</option>
                                <option>Energy</option>
                                <option>Time</option>
                              </select>
                            </td>
                            <td >
                              <select className="border-radius" name="priceunit" value={x.priceunit} onChange={e => handleInputChange(e, i)}>
                                <option value="">Price Unit</option>
                                <option>Hour</option>
                                <option>Minute</option>
                                <option>Second</option>
                                <option>WH</option>
                                <option>kWh</option>
                                <option>Flat</option>
                              </select>

                            </td>
                            <td >
                              <input type="number" name="amount" placeholder="Price Amount" value={x.amount} onChange={e => handleInputChange(e, i)} />
                            </td>
                            <td >
                              <input type="number" name="idlefee" placeholder="Idle Fee Amount" value={x.idlefee} onChange={e => handleInputChange(e, i)} />
                            </td>
                            <td >
                              <select className="border-radius" name="graceperiod" value={x.graceperiod} onChange={e => handleInputChange(e, i)}>
                                <option value="">Grace Period(Minute)</option>
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                                <option>50</option>
                                <option>60</option>
                              </select>

                            </td>


                            <td >
                              {inputList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveClick(e, i)}>-</button>}
                              {inputList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddClick}>+</button>}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-6 ">
                <a className="btn columnsBtn btn-pill btn-wide text-uppercase" href="/dashboard/pricing">Cancel</a>
              </div>

              <div className="col-md-6 col-lg-6 ">
                <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" onClick={notify}>{!props.loading ? 'Save Price Plan' : 'Please wait...'}</button>
              </div>
              <ToastContainer style={{ top: '3rem' }} />
            </div>

          </form>

        </div>
      </div>
    </div>


  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.price.isLoading,
    pricedata: state.price.fetchPrice,
    response: state.price.updatePriceResponse
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetPrice: (priceid) => dispatch(getPriceSingleAction(priceid)),
    updatePriceLevel: (price, inputList, pricestatus, starttime, endtime, weeks, priceid) => dispatch(updatePriceLevelAction(price, inputList, pricestatus, starttime, endtime, weeks, priceid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePriceLevel);