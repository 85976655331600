import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { getAllHubOTPListData } from "../../../../store/actions/hubActions"
import Table from "./hubCustomerOtpTable";
import "../../report/table.css"
import Loader from 'react-loader-spinner';
const HubCustomerOTPView = (props) => {
  useEffect(() => {
    props.fetCustomerList();
  }, [])

  const data = props.customerlist && props.customerlist.otp_list
  const columns = useMemo(

    () => [
      {
        header: 'Customer Name',
        accessor: 'name'
      },
      {
        header: 'Mobile',
        accessor: 'mobile'
      },
      {
        header: 'Email ID',
        accessor: 'mail'
      },
      {
        header: 'Date',
        id: 'createdat',
        accessor: d => {
          return moment(d.createdat)
            .local()
            .format("DD-MM-YYYY");
        }
      },

      {
        header: 'Time',
        id: 'datetime',
        accessor: d => {
                const utcDate = d.createdat;
                const date = new Date(utcDate);
                return date.toTimeString().substring(0, 8);
            
        }
    },


      {
        header: 'OTP',
        accessor: 'otp'
      },
      {
        header: 'OTP Type',
        accessor: 'otp_type'
      },

    ],
    []
  );
  useEffect(() => {
    props.fetCustomerList();
  }, [])
  return (<>



    <div className="row py-2">
      <div className="col">

        <div className="row py-2">
          <div className="col-md-12 col-lg-12">
            <div className="mb-3 h-100 sideCardDesigns">
              <div className="card-header singleCard">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <strong>Driver OTP Details</strong>
                </div>

              </div>

              {!data.length ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
                (<div className="App"> <Table data={data} columns={columns}></Table></div>)}
            </div>
          </div>
        </div> 
        </div>
    </div>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.customer.isLoading,
    customerlist:  state.hub.fetchallcustomerotp,
   
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetCustomerList: () => dispatch(getAllHubOTPListData())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HubCustomerOTPView);