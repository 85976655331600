import React, { useMemo, useEffect, useState } from "react"
import moment from "moment";
import "../../report/table.css"
import { connect } from "react-redux";
import { getAllHubListData, getAllHubTransactionDetails, getAllCompletedHubTransaction } from "../../../../store/actions/hubActions"
import Table from "./hubTransactionTable";
import Loader from 'react-loader-spinner';
import { toast, ToastContainer } from "react-toastify";



const HubtransactionDetailsTable = (props) => {
    const initialValues = { hubname: "All" }
    const [singlehubname, setSinglehubname] = useState(initialValues)


    useEffect(() => {
        let hubb = "All"
        props.getHubListData();

        if (hubb == "All") {
            props.getallTransaction(hubb);
        }

    }, []);


    const handleChange = (e) => {
        let hubname = e.target.value
        const { name, value } = e.target;
        setSinglehubname({ ...singlehubname, [name]: value });

        if (e.target.value !== "" && e.target.value !== undefined) {
            props.getallTransaction(hubname);
        }

    };


    const data1 = props.fetchAllhubtransaction && props.fetchAllhubtransaction
    const data = data1.result


    const columns = useMemo(

        () => [
            {
                header: 'Transaction Id',
                accessor: 'transactionid'
            },
            {
                header: 'Hub Name',
                accessor: 'hubname'
            },
            {
                header: 'Vehicle No',
                accessor: 'vehicleno'
            },
            {
                header: 'Chargeboxid',
                accessor: 'chargeboxid'
            },
            {
                header: 'Status',
                accessor: 'transactionstatus'
            },
            {
                header: 'Transaction Start Date',
                id: 'start',
                accessor: d => {
                    return moment(d.start).format("DD-MM-YYYY");
                }

            },
            {
                header: 'Transaction Start Time',
                id: 'start1',
                accessor: d => {

                    const utcDate = d.start;
                    const date = new Date(utcDate);
                    return date.toTimeString().substring(0, 8);
                }

            },
            {
                header: 'Transaction Stop Date',
                id: 'stop',
                accessor: d => {

                    if (d.transactionstatus === "In progress") {
                        return "Charging in Progress"
                    } {

                        return moment(d.stop).format("DD-MM-YYYY");
                    }
                }
            },
            {
                header: 'Transaction Stop Time',
                id: 'stop1',
                accessor: d => {

                    if (d.transactionstatus === "In progress") {
                        return "Charging in Progress"
                    } {
                        const utcDate = d.stop;
                        const date = new Date(utcDate);
                        return date.toTimeString().substring(0, 8);
                    }
                }
            },
            {
                header: 'Time Consume',
                accessor: 'timeconsume'
            },
            {
                header: 'kwh',
                accessor: 'kwh'
            },

            {
                header: 'City',
                accessor: 'city'
            },
            {
                header: 'State',
                accessor: 'locationstate'
            },
            {
                header: 'Charging start By',
                accessor: 'starttransnumber'
            },
            {
                header: 'charging stop By',
                accessor: 'stoptransnumber'
            },

        ],
        []
    );

    const hubList = props.fetchAllHubData && props.fetchAllHubData.map(id => id.hubname)


    // const handleSubmit = (e) => {
    //     console.log("vehicleTypeList", vehicleTypeList)
    //     e.preventDefault();
    //     alert("New vehicle Type added");
    //     props.saveVehicleType(vehicleTypeList);
    //     return window.location.href = "/dashboard/hub";
    // };


    const handleSubmit = (e) => {
        
        e.preventDefault();
        props.getallcompltedTransaction(singlehubname);
        toast("Command Triggered");    
    };



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col sideCardDesigns mb-2">

                    <div className="card-header singleCard justify-content-between mb-3">
                        <div className="text-left">
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Hub Transaction Details</strong>
                            </div>
                        </div>
                        <div className=" text-right">
                            <select className="columnsBtn p-2" name="hubname" onChange={handleChange}>
                                <option value="All" >All</option>
                                {hubList && hubList.map((hub) => (
                                    <option key={hub.id} value={hub} >{hub}</option>
                                ))}
                            </select>
                        </div>

                        <div className=" text-right">
                            <form className="form mt-4" onSubmit={handleSubmit} autoComplete="off">
                                <button className="columnsBtn p-2" >Trigger Fetch transaction</button>
                            </form>
                        </div>

                    </div>
                    <div className="row ">
                        <div className="col">
                            <div className="row ">
                                <div className="col-md-12 col-lg-12">
                                    <div className="mb-3 h-100 singleCard">
                                        {data && data.length > 0 ? (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                            :
                                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#1e295d", fontWeight: "600", fontSize: "30px", marginTop: "30px" }}>No Data Available</div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ToastContainer style={{ top: '3rem' }} />
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        fetchAllHubData: state.hub.getAllHubListData,
        fetchAllhubtransaction: state.hub.fetchAllhubtransaction,
        fetchAllcompltedhubtransaction: state.hub.fetchcompletedtransactiondata,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getHubListData: () => dispatch(getAllHubListData()),
        getallTransaction: (hubname) => dispatch(getAllHubTransactionDetails(hubname)),
        getallcompltedTransaction: (singlehubname) => dispatch(getAllCompletedHubTransaction(singlehubname)),


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HubtransactionDetailsTable);