import React, { useMemo, useEffect } from 'react';
import { connect } from "react-redux";
import { getChargerList } from "../../../store/actions/stationAction";
import ReactDataTable from "../common/reactDataTable"
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import Loader from 'react-loader-spinner';

const ChargerList = (props) => {

  const data = props.chargers
  useEffect(() => {
    props.chargerData();
  }, [])
  const columns = useMemo(
    () => [
      {
        header: 'Station Name',
        defaultWidth: 220,
        name: 'stationName'
      }
      ,
      {
        header: 'Operator',
        defaultWidth: 180,
        name: 'stationOperator'
      }
      ,
      {
        header: 'Charger Status',
        defaultWidth: 110,
        name: 'status'
      }
      ,
      {
        header: 'Charge BoxID',
        defaultWidth: 110,
        name: 'chargeboxid'
      },
      {
        header: 'Charger Name',
        defaultWidth: 110,
        name: 'chargername'
      },
      {
        header: 'Charger Type',
        defaultWidth: 110,
        name: 'chargertype'
      },
      {
        header: '# Connectors',
        defaultWidth: 110,
        type: 'number',
        filterEditor: NumberFilter,
        name: 'connectorCount'
      },
      {
        header: 'Power Type',
        defaultWidth: 110,
        name: 'powertype'
      },
      {
        header: 'Price Plan',
        defaultWidth: 110,
        name: 'priceplan'
      }
      ,
      {
        header: 'Protocol',
        defaultWidth: 110,
        name: 'protocol'
      }
      ,
      {
        header: 'Action',
        defaultWidth: 100,
        name: 'protocol',
        render: ({ value, data }) => {

          return <div style={{ display: 'inline-block' }}>
            <a rel="tooltip" title="View Log List" className="table-action view" href={`/dashboard/charger-logs/${data.chargeboxid}`}>
              <i className="fa fa-eye"></i>
            </a>
          </div>
        }
      }
    ],
    []
  );

  const filterValue = [
    { name: 'stationName', operator: 'contains', type: 'string', value: '' },
    { name: 'stationOperator', operator: 'contains', type: 'string', value: '' },
    { name: 'status', operator: 'contains', type: 'string', value: '' },
    { name: 'chargeboxid', operator: 'contains', type: 'string', value: '' },
    { name: 'chargername', operator: 'contains', type: 'string', value: '' },
    { name: 'chargertype', operator: 'contains', type: 'string', value: '' },
    { name: 'powertype', operator: 'contains', type: 'string', value: '' },
    { name: 'priceplan', operator: 'contains', type: 'string', value: '' },
    { name: 'protocol', operator: 'contains', type: 'string', value: '' },
    { name: 'connectorCount', operator: 'eq', type: 'number' },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row rowpadding  m-1">
          <div className="col">
            <div className="row py-2">
              <div className="col-md-12 col-lg-12 sideCardDesigns ">
                <div className="mb-3 h-100 singleCard">
                  <div className="card-header singleCard">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Charger List</strong>
                    </div>
                  </div>
                  {!props.isLoading ?
                    (data && data.length > 0 ?
                      (<div className="App pt-2"> <ReactDataTable data={data} columns={columns} parentName="logs-charger-list" filter={filterValue}
                        enableCSV={false} enableSearch={false} enableColumnFilter={false}
                      ></ReactDataTable></div>)
                      : (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>No Charger found<h3 className="loader"></h3></div>)
                    )
                    :
                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = function (state) {
  return {
    chargers: state.station.getchargerlist,
    isLoading: state.station.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    chargerData: () => dispatch(getChargerList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChargerList);