import { BASE_URL, DELETE_USER_URL, FETCT_USER_DETAIL_URL, FETCT_USER_URL, SAVE_USER_URL, UPDATE_USER_URL } from "../../components/config/configs";

export const saveAction = user =>{
   const { username,password,firstname,lastname,role,access,organization,organizationtype,addres,phone,country, cstate,city,zipcode,networkoperator ,planname} =user;
  const {token,id}= JSON.parse(localStorage.getItem('user'))
  // console.log(user);
    var bearer = 'basic ' + token;
    return dispatch => {
      return fetch(BASE_URL+SAVE_USER_URL, {
        method: "POST",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
            "username": username.value,
            "password":password.value,
            "firstname":firstname.value,
            "lastname":lastname.value,
            "phone":parseInt(phone.value),
            "role":role.value,
            "privileges":access,
            "organization":organization.value,
            "organizationtype":organizationtype.value,
            "address":addres.value,
            "city":city.value,
            "state":cstate.value,
            "country":country.value,
            "zipcode":zipcode.value,
            "createdby":id,
            "networkoperator":networkoperator.value,
            "planname":planname.value
       })
      })
        .then(resp => resp.json())
        .then(data => {
            dispatch({type: 'SAVE_USER', payload: data})
        })
    }
  }
 
  export const updateAction = (id,user) =>{
    const {token}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
      return fetch(BASE_URL+UPDATE_USER_URL, {
        method: "PUT",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
            "id":id,
            "username": user.username,
            "firstname":user.firstname,
            "lastname":user.lastname,
            "phone":user.phone,
            "role":user.role,
            "privileges":user.privileges,
            "planname":user.planname,
            "organization":user.organization,
            "organizationtype":user.organizationtype,
            "address":user.address,
            "address1":user.address1,
            "city":user.city,
            "state":user.state,
            "country":user.country,
            "zipcode":user.zipcode,
            "createdat":user.createdat,
            "accountstatus":user.accountstatus,
            "ispasswordupdated":user.ispasswordupdated,
       })
      })
        .then(resp => resp.json())
        .then(data => {
          // console.log("UPDATE_USER",data);
            dispatch({type: 'UPDATE_USER', payload: data})
        })
    }
  }
  export const deactivateAction = (user,userstatus) =>{
    const {token}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
      return fetch(BASE_URL+DELETE_USER_URL, {
        method: "POST",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
            "username": user.username,
            'accountstatus':userstatus
       })
      })
        .then(resp => resp.json())
        .then(data => {
          // console.log("DEACTIVATE_USER",data)
            dispatch({type: 'DEACTIVATE_USER', payload: data})
        })
    }
  }
  export const getUsersAction = user =>{
    const {token}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
      return fetch(BASE_URL+FETCT_USER_URL, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
        .then(resp => resp.json())
        .then(data => {
            dispatch({type: 'FETCH_USER', payload: data})
        })
    }
  }

  export const getUserDetailsAction = (id,nei) =>{
    const {token}= JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
      return fetch(BASE_URL+FETCT_USER_DETAIL_URL+"?id="+id, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
        .then(resp => resp.json())
        .then(data => {
            dispatch({type: 'FETCH_SINGLE_USER', payload: data})
        })
    }
  }