import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import Moment from 'moment';
import { CardBody } from "reactstrap";
import { Bar } from 'react-chartjs-2';
import {  insightMonthlyDataAction, insightCustomerDataAction } from "../../../store/actions/insightAction";

const CustomerDataChart = (props) => {

    useEffect(() => {

        const today = Moment();
        const monthlycurrent = today.subtract(12, 'month').format('YYYY-MM-DD');
        const monthlylastDay = today.add(8, 'month').format('YYYY-MM-DD');
        // const monthlycurrent = ('22-12-01').subtract(12, 'month');
        // const monthlylastDay = ('22-01-01').add(12, 'month');
       console.log("Monthlycurrent", monthlycurrent, monthlylastDay);
        props.MonthlyData(monthlycurrent, monthlylastDay);
        props.TotalCustomer(monthlycurrent, monthlylastDay);
    
      }, [])
      let monthpush = []

      const totalcustomer = props.insightcustomer && props.insightcustomer.map(cu => cu.total);
      const monthlytime = props.insightmonthly && props.insightmonthly.map(month => month.ID);
      const monthlylabel = monthlytime && monthlytime.map(label => {
        let month = label.number
    
        if (month === 12) {
          monthpush.push("December")
        } else if (month === 11) {
          monthpush.push("November")
        } else if (month === 10) {
          monthpush.push("October")
        }
        else if (month === 9) {
          monthpush.push("September")
        }
        else if (month === 8) {
          monthpush.push("August")
        }
        else if (month === 7) {
          monthpush.push("July")
        }
        else if (month === 6) {
          monthpush.push("June")
        }
        else if (month === 5) {
          monthpush.push("May")
        }
        else if (month === 4) {
          monthpush.push("April")
        }
        else if (month ===3) {
          monthpush.push("March")
        }
        else if (month === 2) {
          monthpush.push("February")
        }
        else if (month === 1) {
          monthpush.push("January")
        }
      }
      );

      let CustomerChart = {
        data: (canvas) => {
          return {
            labels: monthpush.slice(-(totalcustomer.length)),
            datasets: [
              {
                label: "Total Customer",
                backgroundColor: "#D1108C",
                data: totalcustomer,
              },
            ],
          };
        },
      };
        
      const plugin = {
        beforeInit(chart) {
          const originalFit = chart.legend.fit;
          chart.legend.fit = function fit() {
            originalFit.bind(chart.legend)();
            this.height += 25;
          }
        }
      }

      return(

        <>
         <div className="sideCardDesigns m-1 pb-5">
              <CardBody>
              <span className="charttitle">Total Customer </span>
                <div className="chart-area">
                  <Bar
                    data={CustomerChart.data}
                    plugins={plugin}
                    options={{


                        tooltips: {
                            mode: 'index'
                        },
                        
                        animation: {
                            duration: 0,
                        },
                        hover: {
                            animationDuration: 0,
                        },
                       
                      legend: {
                        display: true,
                        labels: {
                          fontColor: 'black',
                          fontSize: 15,
                          padding: 20,
                          usePointStyle: true,
                          //width of circles in legend
                          boxWidth: 9

                        }
                      },
                      
                      scales: {
                        xAxes: [{
                          gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                          }
                        }],
                        yAxes: [{
                          gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                          }
                        }]
                      },
                      tooltips: {
                        enabled: true
                    },
                      animation: {
                        duration: 10,
                        onComplete: function () {
                          var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                          ctx.textAlign = 'center';
                          ctx.color = "black";
                          ctx.fillStyle = "black";
                          ctx.textBaseline = 'bottom';
                          this.data.datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                              var data = dataset.data[index];

                              if(data > 999 && data < 1000000){
                                var rdata= (data/1000).toFixed() +'K';     // convert to K for number from > 1000 < 1 million 
                                ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                              }else if(data > 1000000){
                                var rdata= (data/1000000).toFixed() + 'M';  // convert to M for number from > 1 million 
                                ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                              }else if(data < 900){
                                ctx.fillText(data, bar._model.x, bar._model.y - 5); // if value < 1000, nothing to do
                            }
                         
                            //   ctx.fillText(data, bar._model.x, bar._model.y - 5);
                            });
                          });
                        }
                      }
                    }
                    }
                  />
                </div>
                <br />
              </CardBody>
          </div>
        </>
      )
    

}


const mapStateToProps = (state) => {
    return {

      insightmonthly: state.insight.fetchMonthlyData,
      insightcustomer: state.insight.fetchCustomerData,
    
  
    }
  }
  const mapDispatchToProps = dispatch => {
    return {

      MonthlyData: (current, lastDay) => dispatch(insightMonthlyDataAction(current, lastDay)),
      TotalCustomer: (current, lastDay) => dispatch(insightCustomerDataAction(current, lastDay)),
     
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(CustomerDataChart);