import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSinglePlanActions } from "../../../store/actions/subscriptionAction";

class ViewSinglePlanDetails extends Component {
    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getSinglePlan(params.id)
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin">
                    <div className="card-body">
                        <h4 className="text-center formHeading"><b>View Subscription Plan</b></h4>



                        <div className="row rowpadding  justify-content-between mt-5">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        {/* <label className="formLable" htmlFor="plantype">Plan Type</label>
                                        <div className="form-label-group ">
                                    <input type="text" id="plantype" name="plantype" className="form-control control-label" value={this.props.plansingledetail.plantype} disabled    />
                                    <label htmlFor="plantype">Plantype</label>
                                </div> */}
                                        <label className="formLable" htmlFor="name">Subscription Name</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="name" name="name" className="form-control control-label" value={this.props.plansingledetail.name} disabled />
                                            <label htmlFor="name">Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="code">Subscription Code</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="code" name="code" className="form-control control-label" value={this.props.plansingledetail.code} disabled />
                                            <label htmlFor="code">Code</label>
                                        </div>



                                        <label className="formLable" htmlFor="startdate">Start Date</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="startdate" name="startdate" className="form-control control-label" value={this.props.plansingledetail.startdate} disabled />
                                            <label htmlFor="startdate">Start Date</label>
                                        </div>

                                        <label className="formLable" htmlFor="enddate">End Date</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="enddate" name="enddate" className="form-control control-label" value={this.props.plansingledetail.enddate} disabled />
                                            <label htmlFor="enddate">End Date</label>
                                        </div>

                                        <label className="formLable" htmlFor="fee">Subscription Fee</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="fee" name="fee" className="form-control control-label" value={this.props.plansingledetail.fee} disabled />
                                            <label htmlFor="fee">Subscription Fee</label>
                                        </div>

                                        <label className="formLable" htmlFor="setupfee">Subscription Setup Fee</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="setupfee" name="setupfee" className="form-control control-label" value={this.props.plansingledetail.setupfee} disabled />
                                            <label htmlFor="setupfee">Subscription Setup Fee</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="period">Subscription Period</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="period" name="period" className="form-control control-label" value={this.props.plansingledetail.period} disabled />
                                            <label htmlFor="period">Subscription Period</label>
                                        </div>

                                        <label className="formLable" htmlFor="category">Subscription Category</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="category" name="category" className="form-control control-label" value={this.props.plansingledetail.category} disabled />
                                            <label htmlFor="category">Subscription Category</label>
                                        </div>

                                        <label className="formLable" htmlFor="display">Display to Customer</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="display" name="display" className="form-control control-label" value={this.props.plansingledetail.display} disabled />
                                            <label htmlFor="display">Display to Customer</label>
                                        </div>

                                        <label className="formLable" htmlFor="expiration">Subscription Expiration Type</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="expiration" name="expiration" className="form-control control-label" value={this.props.plansingledetail.expiration} disabled />
                                            <label htmlFor="expiration">Subscription Expiration Type</label>
                                        </div>

                                        <label className="formLable" htmlFor="description">Description</label>
                                        <div className="form-label-group pb-3 ">
                                            <input type="text" id="description" name="description" className="form-control control-label" value={this.props.plansingledetail.description} disabled />
                                            <label htmlFor="description">Description</label>
                                        </div>

                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-lg-3 ">
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase" href="/dashboard/pricing">Back</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )

    }

}

const mapStateToProps = function (state) {
    return {
        plansingledetail: state.subscriptionplan.fetchsubscriptionbyid,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSinglePlan: (id) => dispatch(getSinglePlanActions(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSinglePlanDetails));           