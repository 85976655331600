import React from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { Checkbox } from "./Checkbox";
import "./table.css";
import GlobalFilter from "./GlobalFilter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import StickyTable from "react-sticky-table-thead"

export default function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, PageSize } = state;
  return (
    <>


      <div className="container-fluid ">
        <div className="row ">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3 ">
                <a
                  className="btn columnsBtn p-2"
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{ color: "#10204B" }}
                >
                  Select Columns &nbsp;<i className="fas fa-caret-down"></i>
                </a>
              </div>
              <div className="col-md-9 pt-3 ">
                <div className="row float-right ">
                  <div className="col-md-6 ">
                    <div className="row float-right">
                      <div className="col-md-12">
                        <GlobalFilter
                          filter={globalFilter}
                          setFilter={setGlobalFilter}
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="row float-right">
                      <div className="col-md-12">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn columnsBtn p-2 "
                          table="kwh_consume"
                          filename="kwh_consume"
                          sheet="tablexls"
                          buttonText={<a style={{ fontSize: "13px", color: "#10204B", textDecoration: "none" }}><img className="pr-1" src={process.env.PUBLIC_URL + '/images/Insight_img/tableDownload.svg'} style={{ height: "15px" }} ></img>Download</a>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2" style={{height: '20px'}}>
          <div className="col">
            <div className="collapse" id="collapseExample" style={{width: 'auto'}}>
              <div className="float-left">
                <span className="pr-3">
                  <Checkbox {...getToggleHideAllColumnsProps()} /> Toggle all
                </span>
                {allColumns.map((column) => (
                  <span key={column.id} className="pr-3">
                    <label>
                      <input
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />
                      {column.header}
                    </label>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <div className="customtable">

            <StickyTable height={390}>
              <table {...getTableProps()} id="kwh_consume">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("header")}
                          {column.canGroupBy ? (
                            <span {...column.getGroupByToggleProps()}>
                              {column.isGrouped ? "🛑 " : "👊 "}
                            </span>
                          ) : null}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>

              </table>
            </StickyTable>

          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4 text-left">
            {/* page number */}
            <span style={{fontSize:"14px", color:"#10204B"}}>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
          </div>
          <div className="col-md-4 text-center">
            <div className="row">
            <div className="col-md-6 text-right ">
                <span style={{fontSize:"14px", color:"#10204B"}}>
                  Page: {""}
                  <input
                  className="inputPage"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: "25px",height:"25px" }}
                  />
                </span>
              </div>
              <div className="col-md-6 text-left " >
                        <select
                          className="inputPage"
                          value={PageSize}
                          onChange={(e) => setPageSize(Number(e.target.value))}
                          style={{height:"25px"  }}
                        >
                          {[10, 50, 200, 1000, 20000].map((PageSize) => (
                            <option key={PageSize} value={PageSize}>
                             {PageSize} Row
                            </option>
                          ))}
                        </select>
              </div>
            </div>  
          </div>
          
          <div className="col-md-4 text-right">
            <button
              className="btn columnsBtn btn-sm mr-2"
              onClick={() => {
                gotoPage(0);
              }}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="btn columnsBtn btn-sm mr-2"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="btn columnsBtn btn-sm mr-2"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="btn columnsBtn btn-sm mr-2"
              onClick={() => {
                gotoPage(pageCount - 1);
              }}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
