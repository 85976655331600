import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { getconnectorTypeListAction } from "../../../../store/actions/ctAction"
import Table from "./connectorTable";
import "../../report/table.css";
import Loader from 'react-loader-spinner';

const ViewConnector = (props) => {
  useEffect(() => {
    props.getConnectorTypeData();
  }, [])

  const data = props.ctlist && props.ctlist
  const columns = useMemo(

    () => [
      {
        header: 'Code',
        accessor: 'code'
      },
      {
        header: 'Name',
        accessor: 'name'
      },
      {
        header: 'Connector Type',
        accessor: 'connectortype'
      },
      {
        header: 'Power Type',
        accessor: 'powertype'
      },
      {
        header: 'Power(KW)',
        accessor: 'power'
      },

      {
        header: 'Actions',
        accessor: 'id',
        Cell: (id,status) => {
          return (
            <div>
              <span>
                <a href={`/dashboard/assets/viewsingleconnector/${id.value}`} 
                rel="tooltip" title="View " className="table-action view">
                  <i className="fas fa-eye"></i>
                </a></span>&nbsp;&nbsp;
              <span>
                <a href={`/dashboard/assets/editconnector/${id.value}`} rel="tooltip" title="Edit " className="table-action edit">
                  <i className="fa fa-edit"></i>
                </a></span>&nbsp;&nbsp;
                {/* <span>
                  {status ? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash">
                    <i className="fas fa-user-slash"></i>
                  </a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active">
                    <i className="fas fa-user-check"></i>
                  </a>}</span> */}
            </div>

          );

        },

      }
    ],
    []
  );

  return (
    <div className="col-md-12 col-lg-12">
      <div className="mb-3 h-100 sideCardDesigns">
        <div className="card-header singleCard">
          <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Connector Details</strong>
          </div>
          {/* <div class="btn-actions-pane-right">
            <button type="button"  class="btn btn-primary btn-wide">View All<span class="pl-2 align-middle opacity-7"></span></button>
            </div> */}
        </div>

        {!data.length ?
     (<div style={{display: "flex",  alignItems: "center",justifyContent: "center"}}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{display: "flex",  alignItems: "center",justifyContent: "center"}} /></h3></div>) 
     : 
    (<div className="App"> <Table data={data} columns={columns}></Table></div>) }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ctlist: state.ct.fetchConnectorTypeList,

  }
}
const mapDispatchToProps = dispatch => {
  return {
    getConnectorTypeData: () => dispatch(getconnectorTypeListAction())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewConnector);