const initState={
    saveUser:'',
    updateUser:'',
    deactivateUser:'',
    fetchList:'',
    fetchUserDetail:''
}
const userReducer=(state=initState,action)=>{
    switch (action.type) {
        case 'SAVE_USER':
          return {...state, saveUser: action.payload}
        case 'UPDATE_USER':
          return {...state, updateUser: action.payload}
        case 'DEACTIVATE_USER':
          return {...state, deactivateUser:action.payload}
        case 'FETCH_USER':
            return {...state, fetchList:action.payload}
        case 'FETCH_SINGLE_USER':
            return {...state, fetchUserDetail:action.payload}  
        default:
          return state;
      }
}
export default userReducer;