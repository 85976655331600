import React, { Component } from "react";

class EditEnterprise extends Component {
    render() {
        return (
            <div className="container-fluid">
            <div className="sideCardDesigns card-signin my-5">
              <div className="card-body">
                <h4 className="text-center">
                  <b>Edit Enterprise User</b>
                </h4>
                <hr className="my-4" />
                
                <form
                  className="form-signin"
                  onSubmit={this.handleSubmit}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group required">
                        <input
                          type="email"
                          id="username"
                          name="username"
                          className="form-control"
                          placeholder="Email address"
                          onChange={this.handleChange}
                        />
                        <label htmlFor="username">Email address</label>
                        <span className="help-block text-danger">
                         
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group required">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={this.handleChange}
                        />
                        <label htmlFor="password">Password</label>
                        <span className="help-block text-danger">
                
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group required">
                        <input
                          type="text"
                          id="firstname"
                          name="firstname"
                          className="form-control"
                          placeholder="First Name"
                          onChange={this.handleChange}
                        />
                        <label htmlFor="firstname">First Name</label>
                        <span className="help-block text-danger">
        
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group required">
                        <input
                          type="text"
                          id="lastname"
                          name="lastname"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={this.handleChange}
                        />
                        <label htmlFor="lastname">Last Name</label>
                        <span className="help-block text-danger">
      
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-3 col-sm-3 col-12">
                      <h5 className="font-size-lg text-capitalize font-weight-normal">
                        Network Operator
                      </h5>
                      <hr />
                      {this.props.operatorlist &&
                        this.props.operatorlist.map((network) => (
                          <div
                            className="custom-control-1 custom-radio "
                            key={network.id}
                          >
                            <input
                              type="radio"
                              id={network.id}
                              name="networkoperator"
                              className="custom-control-input"
                              value={network.id}
                              onChange={this.handleChange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={network.id}
                            >
                              {network.organization}
                            </label>
                          </div>
                        ))}
                      <span className="help-block text-danger">

                      </span>
                    </div>
    
                    <div className="col-xl-3 col-sm-3 col-12">
                      <h5 className="font-size-lg text-capitalize font-weight-normal">
                        Select User Role
                      </h5>
                      <hr />
                      <div className="custom-control-1 custom-radio">
                        <input
                          type="radio"
                          id="EP"
                          name="role"
                          className="custom-control-input"
                          value="Enterprise"
                          onChange={this.handleChange}
                        />
                        <label className="custom-control-label" htmlFor="EP">
                          Enterprise
                        </label>
                      </div>
                      <span className="help-block text-danger"></span>
                    </div>
    
                    <div className="col-xl-6 col-sm-6 col-12">
                      <h5 className="font-size-lg text-capitalize font-weight-normal">
                        Select Privileges
                      </h5>
                      <hr />
                      {this.props.privileges &&
                        this.props.privileges.map((items, index) => {
                          return (
                            <ul key={index}>
                              {this.props.privileges &&
                                Object.values(items.privileges).map((privilege) => {
                                  return (
                                    <div
                                      className="custom-control-1 custom-checkbox"
                                      key={privilege.key}
                                    >
                                      <input
                                        type="checkbox"
                                        id={privilege.key}
                                        name="privileges"
                                        className="custom-control-input"
                                        value={privilege.name}
                                        onChange={this.handleCheckChange}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={privilege.key}
                                      >
                                        {privilege.name}
                                      </label>
                                    </div>
                                  );
                                })}
                            </ul>
                          );
                        })}
                      <span className="help-block text-danger">

                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group ">
                        <input
                          type="text"
                          id="organization"
                          className="form-control"
                          name="organization"
                          placeholder="Organization"
                          onChange={this.handleChange}
                        />
                        <label htmlFor="organization">Organization Name</label>
                        <span className="help-block text-danger">

                        </span>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group ">
                        <select
                          className="form-control border-radius"
                          name="organizationtype"
                          onChange={this.handleChange}
                        >
                          <option>Organization type</option>
                          <option>Private Ltd Company</option>
                          <option>Public Ltd Company</option>
                          <option>Sole Proprietorship</option>
                          <option>Cooperatives</option>
                          <option>Limited Liability Partnership(LLP)</option>
                        </select>
                        <span className="help-block text-danger">

                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group required">
                        <input
                          type="text"
                          id="addres"
                          className="form-control"
                          name="addres"
                          placeholder="Address"
                          onChange={this.handleChange}
                        />
                        <label htmlFor="addres">Organization Address</label>
                        <span className="help-block text-danger">

                        </span>
                      </div>
                    </div>
    
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group ">
                        <input
                          type="number"
                          id="phone"
                          className="form-control"
                          name="phone"
                          placeholder="Phone Number"
                          maxLength="11"
                          onInput={this.maxLengthCheck}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="phone">Phone Number</label>
                        <span className="help-block text-danger">
        
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group ">
                        <select
                          className="form-control border-radius"
                          name="country"
                          onChange={this.handleChange}
                        >
                          <option>Country</option>
                          {this.props.countries &&
                            this.props.countries.map((country) => (
                              <option key={country.code} value={country.code}>
                                {country.name}
                              </option>
                            ))}
                        </select>
                        <span className="help-block text-danger">

                        </span>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group ">
                        <select
                          className="form-control border-radius"
                          name="cstate"
                          onChange={this.handleChange}
                        >
                          <option>State</option>
                          {this.props.states &&
                            this.props.states.map((st) => (
                              <option key={st.code} value={st.name}>
                                {st.name}
                              </option>
                            ))}
                        </select>
                        <span className="help-block text-danger">
     
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group required">
                        <select
                          className="form-control border-radius"
                          name="city"
                          onChange={this.handleChange}
                        >
                          <option>City</option>
                          {this.props.cities &&
                            this.props.cities.map((c) => (
                              <option key={c.id} value={c.name}>
                                {c.name}
                              </option>
                            ))}
                        </select>
                        <span className="help-block text-danger">

                        </span>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12">
                      <div className="form-label-group required">
                        <input
                          type="number"
                          id="zipcode"
                          className="form-control"
                          placeholder="Zip code"
                          name="zipcode"
                          maxLength="7"
                          onInput={this.maxLengthCheck}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="zipcode">Zip Code</label>
                        <span className="help-block text-danger">
 
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12">
                      <a
                        className="btn btn-primary btn-pill btn-wide text-uppercase float-left"
                        href="/dashboard/assets"
                      >
                        Back
                      </a>
                    </div>
    
                    <div className="col-xl-6 col-sm-6 col-12 float-right">
                      <button
                        className="btn btn-primary btn-pill btn-wide text-uppercase float-right"
                      >
                        Edit Enterprise User
                      </button>
                    </div>
                    <hr className="my-4" />
                  </div>
                </form>
              </div>
            </div>
          </div>                   
        )
    }
}

export default EditEnterprise;