const initState={
    saveLocation:'',
    updateLocation:'',
    fetchSingleLocation:'',
    fetchLocation:'',
    deactivateLocation:'',
    fetchLocationStatusList:'',
    fetchLocationCategorylist:'',
    ftchLocationSubCategoryList:'',
    fetchOperatorLocationList:'',
    isLoading:false,
    fetchLocationOwner:''
}
const locationReducer=(state=initState,action)=>{
    switch (action.type) {
      case 'LOCATION_LOADING':
        return{...state,isLoading:true}
      case 'ADD_LOCATION':
        return {...state, saveLocation: action.payload,isLoading:false}
      case 'UPDATE_LOCATION':
        return {...state, updateLocation: action.payload}
      case 'FETCH_SINGLE_LOCATION':
        return {...state, fetchSingleLocation: action.payload}
      case 'FETCH_LOCATION':
        return {...state, fetchLocation: action.payload}
      case 'DEACTIVATE_LOCATION':
        return {...state, deactivateLocation: action.payload}
      case 'FETCH_LOCATION_STATUS_LIST':
        return {...state, fetchLocationStatusList: action.payload}
      case 'FETCH_LOCATION_CATEGORY_LIST':
        return {...state, fetchLocationCategorylist: action.payload}
      case 'FETCH_LOCATION_SUB_CATEGORY_LIST':
        return {...state, ftchLocationSubCategoryList: action.payload}
     case 'FETCH_OPERATOR_LOCATION':
        return {...state, fetchOperatorLocationList: action.payload}             
      case 'FETCH_LOCATION_OWNER':
        return {...state, fetchLocationOwner: action.payload}             
     
        default:
          return state;
      }
}
export default locationReducer;