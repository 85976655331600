import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from "react-redux";
import PureBreadcrumbs from "../../../Breadcrums"
import Loader from 'react-loader-spinner';
import Table from "./cctvTable";
import { getSingleHubCCTVPmr, getAllHubListData } from "../../../../../store/actions/hubActions";
import Popup from '../../../station/Popup';

import moment from "moment";

const ManageCCTV = (props, args) => {

    const initialValues = { hubname: "" }
    const [previewImg, setPreviewImg] = useState("")
    const [singlehubname, setSinglehubname] = useState(initialValues)

    useEffect(() => {
        let hubb = "All"
        props.getHubListData()
        if (hubb == "All") {
            props.getCCTVPmr(hubb);
        }
    }, []);

    const handleChange = (e) => {
        let hubname = e.target.value
        // console.log("hubnameee", hubname)
        const { name, value } = e.target;
        setSinglehubname({ ...singlehubname, [name]: value });
        if (e.target.value !== "" && e.target.value !== undefined) {
            props.getCCTVPmr(hubname);
        }
    };

    const previewImageFun = (e, previewImageStr) => {

        if (previewImageStr && previewImageStr !== "") {
            setPreviewImg(previewImageStr);
        } else {
            setPreviewImg("")
        }
    }

    const data = props.fetchCCTVpmr && props.fetchCCTVpmr

    // console.log("cctv",data )

    const columns = useMemo(

        () => [
            {
                header: 'Hub Name',
                accessor: 'hubname'
            },
            {
                header: 'Date',
                accessor: 'date'
            },
            {
                header: 'Module',
                accessor: 'module'
            },
            {
                header: 'Enterd By',
                accessor: 'enterdby'
            },
            {
                header: 'CCTV Power',
                id: 'cctvpower',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.cctvpower.map((data, i) =>

                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcctv" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'CCTV Mounted',
                id: 'cctvmounted',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.cctvmounted.map((data, i) =>

                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcctv" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'Damage CCTV',
                id: 'damagecctv',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.damagecctv.map((data, i) =>

                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcctv" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    );

                },
            },


        ],
        []);

    const hubList = props.fetchHubListData && props.fetchHubListData.map(id => id.hubname)

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col sideCardDesigns mb-2">

                    <div className="card-header singleCard justify-content-between mb-3">
                        <div className="text-left">
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Hub CCTV PMR Details</strong>
                            </div>
                        </div>
                        <div className=" text-right">
                            <select className="columnsBtn p-2" name="hubname" onChange={handleChange}>
                                <option value="All" >All</option>
                                {hubList && hubList.map((hub) => (
                                    <option key={hub.id} value={hub} >{hub}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col">
                            <div className="row ">
                                <div className="col-md-12 col-lg-12">
                                    <div className="mb-3 h-100 singleCard">
                                        {data && data.length > 0 ? (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                            :
                                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#1e295d", fontWeight: "600", fontSize: "30px", marginTop: "30px" }}>No Data Available</div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="mypreviewImageModalcctv" className="modal " abindex="-1" role="dialog" data-backdrop="false" style={{ paddingTop: '100px' }}>
                        <div className="modal-dialog modal-sm p-2">
                            <div className="modal-content ">
                                <div className="modal-body text-center ">
                                {(() => {
                                        if (previewImg && ((previewImg.indexOf("http://") == 0 || previewImg.indexOf("https://") == 0))) {
                                            return (
                                                <img src={previewImg} className="previewImage w-100" />
                                            )
                                        } else {
                                            return (
                                                <img src={`data:image/jpeg;base64,${previewImg}`} className="previewImage w-100" />
                                            )
                                        }
                                    })()}
                                </div>

                                <button type="button" className="btn btn-default columnsBtn p-2 m-2" data-dismiss="modal">Close</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        fetchHubListData: state.hub.getAllHubListData,
        fetchCCTVpmr: state.hub.getCCTVPmr
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getHubListData: () => dispatch(getAllHubListData()),
        getCCTVPmr: (hubname) => dispatch(getSingleHubCCTVPmr(hubname)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCCTV);

