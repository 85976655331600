import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { getUtilityAction, UtilityDeactivateAction } from "../../../../store/actions/assetAction";
import Table from "./utilitiesTable";
import "../../report/table.css";
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2'

const UtilityList = (props) => {
  useEffect(() => {
    props.getUtilityData();
  }, [])
  const user = JSON.parse(localStorage.getItem('user'));
  
  const activateDeactivateUser = (id, userstatus) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#1e295d',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        props.UtilityDeactivation(id, userstatus);
        window.location.reload();
      }
    });
  }



  const data = props.utilitylist && props.utilitylist

  const columns = useMemo(

    () => [
      {
        header: 'Station Name',
        accessor: 'station_name'
      },
      {
        header: 'Service Number',
        accessor: 'service_number'
      },
      {
        header: 'Electricity Bill Due date',
        accessor: 'electricity_bill_due_date'
      },
      {
        header: 'Electricity Bill Amount',
        accessor: 'electricity_bill_amount'
      },
      {
        header: 'Electricity Price per Unit',
        accessor: 'unit_price'
      },
      {
        header: '	Entered By',
        accessor: 'enterdby'
      },

      {
        header: 'Actions',
        id: 'action',
        accessor: (utility) => {

          return (
            <div>

              <span>
                <a href={`/dashboard/assets/viewsingleutility/${utility.id}`} rel="tooltip" title="View " className="table-action view">
                  <i className="fas fa-eye"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;
              <span>
                <a href={`/dashboard/assets/editutility/${utility.id}`} rel="tooltip" title="Edit " className="table-action edit">
                  <i className="fas fa-edit"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;

              &nbsp;&nbsp;&nbsp;
              {user.role === "Admin" ? (
                <span>
                  {utility.deactive ? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash" onClick={() => activateDeactivateUser(utility.id, false)} >
                    <i className="fas fa-user-check"></i></a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active" onClick={() => activateDeactivateUser(utility.id, true)}>
                    <i className="fas fa-user-slash"></i></a>}
                </span>
              ) : (<span >
              </span>)
              }
            </div>

          );

        },

      }
    ],
    []
  );
  return (

    <div className="col-md-12 col-lg-12">
      <div className="mb-3 h-100 sideCardDesigns">
        <div className="card-header singleCard">
          <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Utility Details</strong>
          </div>
        </div>
        {!data.length ?
          (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
          :
          (<div className="App"> <Table data={data} columns={columns}></Table></div>)}
      </div>
    </div>

  )

}

const mapStateToProps = (state) => {
  return {
    utilitylist: state.asset.fetchAllUtility,
    utilityDeactivetion: state.asset.utilityDeactivation,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getUtilityData: () => dispatch(getUtilityAction()),
    UtilityDeactivation: (id, userstatus) => dispatch(UtilityDeactivateAction(id, userstatus)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UtilityList);