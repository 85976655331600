const initState={
    isLoading:false,
    fetchFleetList:'',
    fetchFleetTranList:''
}
const fleetReducer=(state=initState,action)=>{
    switch (action.type) {
        case 'FLEET_LOADING':
            return{...state,isLoading:true}
         case 'FETCH_FLEET_LIST':
           return {...state, fetchFleetList: action.payload,isLoading:false}
           case 'FETCH_FLEET_TRANSACTION_LIST':
            return {...state, fetchFleetTranList: action.payload,isLoading:false}
        default:
          return state;
      }
}
export default fleetReducer;