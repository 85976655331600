import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { getStationByCreatorAction } from "../../../store/actions/stationAction";
import { UPLOAD_STATION_IMAGE_URL, STATION_BASE_URL } from "../../config/configs";

const FilePostForm = (props) => {

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [stationname, setStationList] = useState();
  useEffect(() => {
    props.StationData("All")
  }, []);
  const fileSelectedHandler = (event) => {
    setSelectedFiles(event.target.files);
  }
  const handleChange = (e) => {
    setStationList(
      e.target.value.trim()
    );
  }
  const handleSubmitFile = () => {
    // console.log("Selectedfile", selectedFiles)
    if (selectedFiles !== null) {
      let formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("selectedFile", selectedFiles[i]);
      }
      formData.append("name", stationname)
      // console.log(stationname);
      const { token } = JSON.parse(localStorage.getItem('user'))
      var bearer = 'basic ' + token;
      // console.log(JSON.parse(localStorage.getItem('user')));
      axios.post(
        STATION_BASE_URL + UPLOAD_STATION_IMAGE_URL,
        formData,
        {
          headers: {
            'Authorization': bearer

          },
        }
      )
        .then(res => {
          console.log(`Success` + res.data);
        })
        .catch(err => {
          console.log(err);
        })
    }
    alert('Station Image Updated');
  }



  return (
    <div className="container-fluid">
      <div className="sideCardDesigns card-signin my-5">
        <div className="card-body">
          <h4 className="text-center formHeading mb-5"><b>Add  Charger Station Images</b></h4>

          <div className="row rowpadding  justify-content-between mt-5">
            <div className="col-md-6 col-sm-12 ">
              <div className="col-12  formContainer">
                <div className="col-12">
                  <label className="formLable" htmlFor="station">Station</label>
                  <div className="form-label-group required">
                    <select className="form-control border-radius" id="station" name="station" onChange={handleChange} required>
                      <option value="">Select Station</option>
                      {props.stations && props.stations.map(station =>
                        <option key={station.id} id={station.name} value={station.name}>{station.name}</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
              <div className="col-12  formContainer ">
                <div className="col-12 ">
                  <label className="formLable" htmlFor="image">Upload Image</label>
                  <div className="columnsBtn p-1 mb-3 ">
                    <label className="pt-2 ">
                      <input type="file" name="image" multiple accept="image/*" onChange={fileSelectedHandler} required />
                    </label>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-3 col-lg-3 ">
              <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/stations">Cancel</a>
            </div>
            <div className="col-md-9 col-lg-9 ">
              <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" onClick={handleSubmitFile}>Upload Station Images</button>

            </div>



          </div>

        </div>
      </div>
    </div>

  )
}


const mapStateToProps = function (state) {
  return {
    stations: state.station.fetchStation,
    loading: state.station.isLoading,
    uploadImg: state.station.saveStationImageResponse
  }
}
const mapDispatchToProps = dispatch => {
  return {
    StationData: (viewtype) => dispatch(getStationByCreatorAction(viewtype)),
    //saveStationImages:(station,imageslist)=>dispatch(uploadStationImagesAction(station,imageslist))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilePostForm);
