import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { assetAction } from "../../../../store/actions/assetAction";

const AssetType=(props)=>{
    const [assetType, setAssetType] = useState();
    const history=useHistory();
    useEffect(()=>{
       props.assetdata() 
    },[])
    const handleSubmit=(e)=>{
      e.preventDefault();
      history.push("/dashboard/assets/addasset/"+assetType)
      }
    
    return(
       
      <div className="container-fluid">
      <div className="sideCardDesigns card-signin">
           <div className="card-body">
           <h4 className="text-center"><b>Create New Asset</b></h4>
           <hr className="my-4"/>
           <form className="form-signin" onSubmit={handleSubmit}>
           <div className="row">
           <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            {props.loading?<FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon>:   
                          props.assetlist && props.assetlist.map(data => {
                            return (
                                <div className="custom-control custom-radio ">
                                <input type="radio" id={ data.id } name="assettype" className="custom-control-input" value = { data.name } onChange={e =>setAssetType(e.target.value)}/>
                                    <label className="custom-control-label" htmlFor={ data.id }>{ data.name }</label>
                                </div>
                            );
                          })             
                    }
            </div>
           </div>
           <div className="row">
              <div className="col-md-4 col-lg-4 ">
                     <a className="btn btn-primary btn-pill btn-wide text-uppercase" href="/dashboard/assets">Back</a>
                     </div>     
                    
                     <div className="col-md-8 col-lg-8 ">
                     <button className="btn btn-primary btn-pill btn-wide text-uppercase float-right">Create New Asset</button>
                      
                     </div> 
                     <hr className="my-4"/>    
                  </div> 
            
             
           </form>
           </div>
            
           </div>
      </div>             
    )
}

const mapStateToProps = (state) =>{
    return{
         assetlist:state.asset.fetchAsset,
        loading:state.asset.isLoading
    }
  }
  const mapDispatchToProps=dispatch =>{
    return{
      assetdata: () => dispatch(assetAction())
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(AssetType);
