import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

const CommissionPage=(props)=>{
 const[redirect,setRedirect]=useState("")
 const history=useHistory()
    const handleSubmit=()=>{
    setRedirect("success")
    }
    // console.log(redirect);
    if (redirect==='success') {
        return(
            <Redirect to="/dashboard"></Redirect>
        )
    }
        return(
            <div className="container-fluid">
            <div className="sideCardDesigns card-signin my-5">
             <div className="card-body">
             <h4 className="text-center"><b>Commission New Charger</b></h4>
             <hr className="my-4"/>
             <form className="form-signin" onSubmit={handleSubmit}>
            <div class="row">
                <div class="col-xs-4 col-sm-6 col-lg-4">
                <ul class="list-group list-group-flush">
                <li class="list-group-item">Operator</li>
                <li class="list-group-item">Location Name</li>
                <li class="list-group-item">Location Number</li>
                <li class="list-group-item">Status</li>
                <li class="list-group-item"></li>
                </ul>
                </div>
                <div class="col-xs-8 col-sm-6  col-lg-8">
                </div>
                </div>
                <hr className="my-4"/> 
            
                <div class="row">
                <div class="col-xs-4 col-sm-6 col-lg-4">
                </div>   
                <div className="col-xl-6 col-sm-6 col-12 float-right"> 
                  <a href={`/dashboard/pricing/addprice`} className="btn btn-primary btn-pill btn-wide text-uppercase float-right">Add Price</a> 
                 </div> 
                 </div>
                 <hr className="my-4"/> 
            
               </form>
            </div>
            </div>
        </div>
        )
   
}
export default CommissionPage;