import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../Breadcrums"
import { getAllHubOperationData } from "../../../../store/actions/hubActions";
import Vehiclecheckin from "../vehiclecheckin/vehiclecheckin"
import Vehicleparking from "./vehicleparking"

const ParkingCart = (props) => {
  const userrole = JSON.parse(localStorage.getItem('user')); 
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col col-sm-4 ">
            <PureBreadcrumbs />
          </div>
        </div>


        <div className="row rowpadding  mr-1">
          <div className="col">
            <div className="row ">
              <div className="col-md-12 col-lg-12  ">
          
                  <Vehiclecheckin></Vehiclecheckin>
             
              </div>
            </div>
          </div>
        </div>


        <div className="row rowpadding  mr-1">
          <div className="col">
            <div className="row py-2">
              <div className="col-md-12 col-lg-12  ">
          
                  <Vehicleparking></Vehicleparking>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    fetchAllOpsData: state.hub.getAllHubOperationData
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getAllOpsData: () => dispatch(getAllHubOperationData()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingCart);

