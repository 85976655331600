import React, { useCallback, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useRowSelect
} from "react-table";
import { Checkbox } from "../report/Checkbox";
import GlobalFilter from "../report/GlobalFilter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import StickyTable from "react-sticky-table-thead";


export default function FleetTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
    allColumns,
    selectedFlatRows,

    getToggleHideAllColumnsProps,
    rows,
    state: { globalFilter, pageIndex, PageSize },
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );

  
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
     
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest}   />
         
        </>
      )
    }
  )

  const list = selectedFlatRows.map(d => d.original)
  // console.log("list", list)
 
  return (
    <>
     {/* <PrettyPrint jsonObj={list} /> */}
    
      <div className="container-fluid ">
        <div className="row mt-3 mb-3">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4">
                <a
                  className="btn btn-primary btn-sm"
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Select Columns &nbsp;<i className="fas fa-caret-down"></i>
                </a>
              </div>
              <div className="col-md-4 text-center">
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-3">
                  </div>
                  <div className="col-md-3">
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="btn btn-primary btn-sm"
                      table="location-table-to-xls"
                      filename="Location"
                      sheet="tablexls"
                      buttonText="Download"
                    />

                  </div>
                  <div className="col-md-6 " >
                    <select
                      value={PageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 50, 200, 1000, 20000].map((PageSize) => (
                        <option key={PageSize} value={PageSize}>
                          Show {PageSize} entries
                        </option>
                      ))}
                    </select>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2" style={{height: '20px'}}>
          <div className="col">
            <div className="collapse" id="collapseExample" style={{width: 'auto'}}>
              <div className="float-left">
                <span className="pr-3">
                  <Checkbox {...getToggleHideAllColumnsProps()} /> Toggle all
                </span>
                {allColumns.map((column) => (
                  <span key={column.id} className="pr-3">
                    <label>
                      <input
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />
                      {column.header}
                    </label>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <div className="customtable">
            <StickyTable height={390}>
              <table {...getTableProps()} id="location-table-to-xls">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {column.canGroupBy ? (
                            <span {...column.getGroupByToggleProps()}>
                              {column.isGrouped ? "🛑 " : "👊 "}
                            </span>
                          ) : null}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>

              </table>
            </StickyTable>

          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-4 text-left">
            {/* page number */}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
          </div>
          <div className="col-md-4 text-center">
            <span>
              Goto Page :{""}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: "50px" }}
              />
            </span>
          </div>
          <div className="col-md-4 text-right">
            <button
              className="btn btn-primary btn-sm mr-2"
              onClick={() => {
                gotoPage(0);
              }}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="btn btn-primary btn-sm mr-2"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="btn btn-primary btn-sm mr-2"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="btn btn-primary btn-sm mr-2"
              onClick={() => {
                gotoPage(pageCount - 1);
              }}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
        
        {/* <pre>
          <code>
            {JSON.stringify(
              {
                selectedRowIds: selectedRowIds,
                selectedFlatRows: selectedFlatRows.map(
                  d => d.original
                ),
              },
              null,
              2
            )}
            
          </code>
        </pre> */}
      </div>
    </>
  );
}
