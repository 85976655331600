import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { getAssetListAction, AssetDeactivateAction } from "../../../../store/actions/assetAction";
import Table from "./assetTable";
import "../../report/table.css";
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2'
const AssetTableView = (props) => {
  useEffect(() => {
    props.getAssetData();
  }, [])
  const user = JSON.parse(localStorage.getItem('user'));

  const activateDeactivateUser = (assetid, userstatus) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#1e295d',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        props.AssetDeactivation(assetid, userstatus);
        window.location.reload();
      }
    });
  }


  const data = props.assetlist && props.assetlist

  const columns = useMemo(

    () => [
      {
        header: 'Asset Name',
        accessor: 'name'
      },
      {
        header: 'Location',
        accessor: 'location'
      },
      {
        header: 'Status',
        accessor: 'status'
      },
      {
        header: 'Installed',
        accessor: 'installed'
      },
      {
        header: 'Date',
        id: 'createdat',
        accessor: d => {
          return moment(d.createdat)
            .local()
            .format("DD-MM-YYYY");
        }
      },

      {
        header: 'Actions',
        id: 'action',
        accessor: (asset) => {
          return (
            <div>

              &nbsp;&nbsp;&nbsp;
              <span>
                <a href={`/dashboard/assets/viewsingleassets/${asset.assetid}`} rel="tooltip" title="View " className="table-action view">
                  <i className="fas fa-eye"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;
              <span>
                <a href={`/dashboard/assets/editasset/${asset.assetid}`} rel="tooltip" title="Edit " className="table-action edit">
                  <i className="fa fa-edit"></i>
                </a></span>

              {user.role === "Admin" ? (
                <span>
                  {asset.status ? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash" onClick={() => activateDeactivateUser(asset, false)} >
                    <i className="fas fa-user-check"></i></a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active" onClick={() => activateDeactivateUser(asset, true)}>
                    <i className="fas fa-user-slash"></i></a>}
                </span>
              ) : (<span >
              </span>)
              }


            </div>

          );

        },

      }
    ],
    []
  );

  return (
    <div className="col-md-12 col-lg-12">
      <div className="mb-3 h-100 sideCardDesigns">
        <div className="card-header singleCard">
          <div className="card-header-title font-size-lg text-capitalize font-weight-normal "><strong style={{ color: "#10204B" }}>Asset Details</strong>
          </div>
        </div>

        {!data.length ?
          (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
          :
          (<div className="App"> <Table data={data} columns={columns}></Table></div>)}

      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    assetlist: state.asset.fetchAssetList,
    assetDeactivation: state.asset.assetDeactivation,

  }
}
const mapDispatchToProps = dispatch => {
  return {
    getAssetData: () => dispatch(getAssetListAction()),
    AssetDeactivation: (assetid, userstatus) => dispatch(AssetDeactivateAction(assetid, userstatus))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssetTableView);