import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { editStationAction, updateFirmwareAction } from "../../../store/actions/stationAction";
import { connect } from "react-redux";
import Moment from "moment";
const UpdateChargerFirmware = (props) => {

  const { stationid, location } = useParams()
  const [firmware, setFirmware] = useState();

  useEffect(() => {
    props.EditStationByName(stationid, location);
  }, []);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd + "T" + "00:00";
    // var time = "2023-04-20T00:00"
    // return time

  };

  // var curr = new Date();
  // curr.setDate(curr.getDate());
  // var date = curr;
  // const lkj = Moment(date).format();
  // var proposedDate = lkj + "T00:00:00.000Z";
  // console.log("F",proposedDate)


  // const date = Moment("YYYY MM DD").format('2006-01-01T04:00:00.000Z');
  // console.log(date);

  const handleChange = (e) => {
    setFirmware({
      ...firmware,
      [e.target.name]: e.target.value.trim(),
    });
  }

  // console.log("firmware", firmware)
  const handleFirmwareSubmit = (e) => {
    e.preventDefault();
    props.UpdateFirmware(firmware);
  }
  return (
    <>
      <div className="row-fluid">
        <div className="col-md-12 col-xl-12">

          <div className="row gy-5 ">
            <div className="col-md-4 col-xl-4 col-sm-4 col-12">
              <div className="sideCardDesigns border-light mb-3 widget-content  ">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper p-3">
                    <div className="widget-content-left">
                      <div className="insideHeading">Station ID</div>
                    </div>
                    <div className="widget-content-right">
                      <div className="widget-numbers insideContent">
                        <strong>
                          {props.list != null ? props.list.stationid : 0}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-4 col-sm-4 col-12">
              <div className="sideCardDesigns border-light mb-3 widget-content ">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper p-3">
                    <div className="widget-content-left">
                      <div className="insideHeading">Location Name</div>
                    </div>
                    <div className="widget-content-right">
                      <div className="widget-numbers insideContent">
                        <strong>
                          {props.list != null ? props.list.name : 0}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-4 col-sm-4 col-12">
              <div className="sideCardDesigns border-light mb-3 widget-content ">
                <div className="widget-content-outer">
                  <div className="widget-content-wrapper p-3">
                    <div className="widget-content-left">
                      <div className="insideHeading">Commission Date</div>
                    </div>
                    <div className="widget-content-right">
                      <div className="widget-numbers insideContent">
                        <strong>
                          {props.list != null ? props.list.commisiondate : 0}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>






      <div className="row-fluid">
        <div className="col">

          <div className="row" >
            <div className="col-md-12 col-xl-12">
              <div className="sideCardDesigns mb-3 widget-content">

                <h4 className="text-center formHeading">
                  <b>Update Firmware</b>
                </h4>


                <form className="form mt-5" onSubmit={handleFirmwareSubmit} autoComplete="off">

                  <div className="row rowpadding  justify-content-between">
                    <div className="col-md-6 col-sm-12 ">
                      <div className="col-12  formContainer">
                        <div className="col-12">
                          <label className="formLable" htmlFor="location">URL</label>
                          <div className="form-label-group">
                            <input type="text" id="location" name="location" className="form-control control-label" placeholder="Path" onChange={handleChange} />
                            <label htmlFor="location">URL</label>
                          </div>


                          <label className="formLable" htmlFor="chargeboxid">ChargeBox ID</label>
                          <div className="form-label-group required">
                            <select className="form-control border-radius" name="chargeboxid" onChange={handleChange} >
                              <option>Select ChargeBox ID</option>
                              {props.list && props.list.chargers.map((charger) => (
                                <option key={charger.chargeboxid} value={charger.chargeboxid}>
                                  {charger.chargeboxid}
                                </option>
                              ))}
                            </select>
                          </div>

                          <label className="formLable" htmlFor="retrieveDate">Retrieve Date</label>
                          <div className="form-label-group">
                            <input className="form-control" type="datetime-local" id="retrieveDate" name="retrieveDate" min={disablePastDate()} onChange={handleChange} />
                            <label htmlFor="retrieveDate">Retrieve Date</label>
                          </div>



                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <div className="col-12  formContainer ">

                        <div className="col-12 ">

                          <label className="formLable" htmlFor="retries">Retries (Optional)</label>
                          <div className="form-label-group">
                            <select className="form-control border-radius" id="retries" name="retries" onChange={handleChange}>
                              <option value="">Retries (Optional)</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </select>
                          </div>

                          <label className="formLable" htmlFor="retryInterval">Retry Interval(In sec)(Optional)</label>
                          <div className="form-label-group pb-3">
                            <input type="number" id="retryInterval" name="retryInterval" className="form-control control-label" placeholder="Retry Interval" onChange={handleChange} />
                            <label htmlFor="retryInterval">Retry Interval(In sec)(Optional)</label>
                          </div>

                          <br />
                          <br />
                          <br />

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12">
                      <a
                        className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                        href="/dashboard/firmware"
                      >
                        Back
                      </a>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12 float-right">
                      <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">
                        {!props.loading ? "Upload Firmware" : "Please wait..."}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div></div>
      </div>


    </>
  )


}

const mapStateToProps = (state) => {
  return {
    list: state.station.editstationbyname,
    firmware: state.station.updatefirmware,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    EditStationByName: (stationid, location) => dispatch(editStationAction(stationid, location)),
    UpdateFirmware: (firmware) => dispatch(updateFirmwareAction(firmware))

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateChargerFirmware);
