import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { getStationByCreatorAction } from '../../../store/actions/stationAction';
import './firmware.css';
import Table from "./firmwareTable";
import "../report/table.css"
import PureBreadcrumbs from "../Breadcrums"
import Loader from 'react-loader-spinner';

const Firmware = (props) => {
  useEffect(() => {
    props.StationData();
  }, [])

  const data = props.stations && props.stations

  const columns = useMemo(

    () => [

      {
        header: 'Station Id',
        accessor: 'stationid'
      },
      {
        header: 'Charging Station Name',
        accessor: 'name'
      },
      {
        header: 'Visibility',
        accessor: 'visibility'
      },

      {
        header: 'Commission Date	',
        id: 'commisiondate',
        accessor: d => {
          return moment(d.commisiondate)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Actions',
        id: 'id',
        accessor: d => `${d.stationid}/${d.id}`,
        Cell: (data) => {
          //console.log("data", data)
          return (
            <div>
              <span>
                <a href={`/dashboard/firmware/updatefirmware/${data.value}`}
                  rel="tooltip"
                  title="Upload Firmware"
                  className="table-action view"
                >
                  <i className="fa fa-upload" aria-hidden="true"></i>
                </a>
              </span>

            </div>
          );
        },
      }
    ],
    []
  );


  return (
    <>
      <div className="row-fluid ">
        <div className="col col-sm-4 ">
          <PureBreadcrumbs />
        </div>
      </div>
      <div className="col">
        <div className="mb-3 h-100 sideCardDesigns">
          <div className="card-header singleCard">
            <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
              <strong>Firmware Updates</strong>
            </div>
          </div>

          {!data.length ?
            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
            :
            (<div className="App"> <Table data={data} columns={columns}></Table></div>)}

            <br />
        </div>

      </div>
      {/* Firmware update */}
      <div className="container-fluid pb-5 ">
          <div className="row rowpadding topCardDesigns ml-1 mr-1">
            <div className="col-6 p-3">
              <div className="col-12 ml-2 ">
                <img src={process.env.PUBLIC_URL + '/images/hub/QRCode.svg'} style={{ height: "34px", width: "32px" }} alt="" />
                <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>Charger Configration</span>
                <div className="page-title-subheading pt-2" style={{ fontSize: "12px", color: "#bc0078", fontWeight: "550" }}>Configure charger using keys and values </div>
              </div>
            </div>
            <div className="col-6">
              <div className="text-right m-3 pt-3">
                <a href={`/dashboard/firmware/firmwareupdatebyair`} className="btn btn-primary" >Configure Charger</a>
              </div>
            </div>
          </div>
        </div>

    </>
  );
}

const mapStateToProps = function (state) {
  return {
    stations: state.station.fetchStation,
    loading: state.station.isLoading,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    StationData: (viewtype) => dispatch(getStationByCreatorAction(viewtype)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Firmware);

