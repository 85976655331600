import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { transactionDataAction } from "../../../store/actions/transactionAction";
// import Table from "./transactionTable";
import "../report/table.css";
import "./transaction.css";
import PureBreadcrumbs from "../Breadcrums"
import Loader from 'react-loader-spinner';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import ReactDataTable from "../common/reactDataTable"

// const gridStyle = { minHeight: 550, fontSize: 12 } // <-- look here
const TransactionListPage = (props) => {
  useEffect(() => {
    const { planname } = JSON.parse(localStorage.getItem('user'))
    let pname = planname
    if (pname === "") {
      props.fetchTransactionData("All", "All")
    } else {
      props.fetchTransactionData("All", pname)
    }
  }, [])

  const data = props.transactions && props.transactions.transactiondata

  const filterValue = [
    { name: 'plantype', operator: 'contains', type: 'string', value: '' },
    { name: 'customername', operator: 'contains', type: 'string', value: '' },
    { name: 'start', operator: 'contains', type: 'string', value: '' },
    { name: 'locationname', operator: 'contains', type: 'string', value: '' },
    { name: 'locationtype', operator: 'contains', type: 'string', value: '' },
    { name: 'locationsubtype', operator: 'contains', type: 'string', value: '' },
    { name: 'city', operator: 'contains', type: 'string', value: '' },
    { name: 'locationstate', operator: 'contains', type: 'string', value: '' },
    { name: 'projectname', operator: 'contains', type: 'string', value: '' },
    { name: 'transactionid', operator: 'eq', type: 'number' },
  ];
  
  const columns = useMemo(
    () => [
      {
        header: 'Transaction ID',
        defaultWidth: 110,
        name: 'transactionid',
        type: 'number', 
        filterEditor: NumberFilter
      },
      {
        header: 'Mobile No',
        defaultWidth: 110,
        name: 'mobile'
      },
      {
        header: 'Customer Name',
        defaultWidth: 110,
        name: 'customername'
      },
      {
        header: 'Transaction Date',
        defaultWidth: 110,
        name: 'start'
      },
      {
        header: 'Charging Start time',
        defaultWidth: 110,
        name: 'starttime'
      },
      {
        header: 'Charging End time',
        defaultWidth: 110,
        name: 'stoptime'
      },

      {
        header: 'Charging (HH:MM:SS)',
        defaultWidth: 110,
        name: 'timeconsume'
      },

      {
        header: 'Charge BoxID',
        defaultWidth: 110,
        name: 'chargeboxid'
      }
      ,

      {
        header: 'Connector Type',
        defaultWidth: 110,
        name: 'connectortype'
      }
      ,

      {
        header: 'Customer Plan Type',
        defaultWidth: 110,
        name: 'plantype'
      }
      ,

      {
        header: 'Charging Location',
        defaultWidth: 110,
        name: 'locationname'
      }
      ,
      {
        header: 'Location Type',
        defaultWidth: 110,
        name: 'locationtype'
      }
      ,
      {
        header: 'Location Subtype',
        defaultWidth: 110,
        name: 'locationsubtype'
      }
      ,
      {
        header: 'Charging City',
        defaultWidth: 110,
        name: 'city'
      }
      ,

      {
        header: 'Charging State',
        defaultWidth: 110,
        name: 'locationstate'
      }
      ,
      {
        header: 'Project Name',
        defaultWidth: 110,
        name: 'projectname'
      }
      ,

      {
        header: 'Total Unit',
        defaultWidth: 110,
        name: 'kwh'
      }
      ,

      {
        header: 'Charging Amount',
        defaultWidth: 110,
        name: 'amount'
      }
      ,

      {
        header: 'GST Amount',
        defaultWidth: 110,
        name: 'gst'
      }
      ,

      {
        header: 'Total Amount',
        defaultWidth: 110,
        name: 'billedamount'
      }
    ],
    []
  );

  const { role } = JSON.parse(localStorage.getItem('user'))
  const swagatcolumns = useMemo(
    () => [
      {
        header: 'Transaction ID',
        defaultWidth: 110,
        name: 'transactionid',
        type: 'number', 
        filterEditor: NumberFilter
      },
      {
        header: 'Customer Name',
        defaultWidth: 110,
        name: 'customername'
      },
      {
        header: 'Transaction Date',
        name: 'start',
        defaultWidth: 110,
      },
      {
        header: 'Charging Start time',
        defaultWidth: 110,
        name: 'starttime'
      },
      {
        header: 'Charging End time',
        defaultWidth: 110,
        name: 'stoptime'
      },

      {
        header: 'Charging Duration(HH:MM:SS)',
        defaultWidth: 110,
        name: 'timeconsume'
      }
      ,

      {
        header: 'Connector Type',
        defaultWidth: 110,
        name: 'connectortype'
      }

      ,

      {
        header: 'Charging Location',
        defaultWidth: 110,
        name: 'locationname'
      }
      ,
      {
        header: 'Location Type',
        defaultWidth: 110,
        name: 'locationtype'
      }
      ,

      {
        header: 'Charging City',
        defaultWidth: 110,
        name: 'city'
      }
      ,

      {
        header: 'Charging State',
        defaultWidth: 110,
        name: 'locationstate'
      }

      ,

      {
        header: 'Total Unit',
        defaultWidth: 110,
        name: 'kwh'
      }
      ,

      {
        header: 'Charging Amount',
        defaultWidth: 110,
        name: 'amount'
      }
      ,

      {
        header: 'GST Amount',
        defaultWidth: 110,
        name: 'gst'
      }
      ,

      {
        header: 'Total Amount',
        defaultWidth: 110,
        name: 'billedamount'
      }
    ],
    []
  );

  const lifetimetotalrevenue = parseFloat(props.transactions.lifetimeamount + props.transactions.totalgst !== 0 ? props.transactions.lifetimeamount + props.transactions.totalgst : 0).toFixed(0)

  return (
    <>
      <div className="row">
        <div className="col col-sm-4 ">
          <PureBreadcrumbs />
        </div>
      </div>
      <div className="row rowpadding mb-4">
        <div className="col-sm-12 col-md-4">
          <div className="col-md-12  sideCardDesigns mt-1 ">
            <div className="row">
              <div className="col-sm colpadding">
                <div className="singleCard text-center cardbg" >
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="media-body text-left" style={{ color: "#D1108C" }} >
                        <span className="stats font-weight-bold"  >Total Transaction</span>
                        <span className="statsweight"><h4><strong>{props.transactions.totaltransaction !== 0 ? props.transactions.totaltransaction : 0}</strong></h4></span>
                      </div>
                      <div className="float-end">
                        <img className="float-end" src={process.env.PUBLIC_URL + '/images/transaction_logo/totalTransaction.svg'} alt="" style={{ height: "50px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="col-md-12  sideCardDesigns mt-1 ">
            <div className="row">
              <div className="col-sm colpadding">
                <div className="singleCard text-center cardbg" >
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="media-body text-left" style={{ color: "#D1108C" }} >
                        <span className="stats font-weight-bold"  >Total Amount</span>
                        <span className="statsweight"><h4><strong>{lifetimetotalrevenue}</strong></h4></span>
                      </div>
                      <div className="float-end">
                        <img className="float-end" src={process.env.PUBLIC_URL + '/images/transaction_logo/totalAmount.svg'} alt="" style={{ height: "50px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="col-md-12  sideCardDesigns mt-1 ">
            <div className="row">
              <div className="col-sm colpadding">
                <div className="singleCard text-center cardbg" >
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="media-body text-left" style={{ color: "#D1108C" }} >
                        <span className="stats font-weight-bold"  >Total Today Transaction</span>
                        <span className="statsweight"><h4><strong>{props.transactions.totaltodaytransaction !== 0 ? props.transactions.totaltodaytransaction : 0}</strong></h4></span>
                      </div>
                      <div className="float-end">
                        <img className="float-end" src={process.env.PUBLIC_URL + '/images/transaction_logo/todayTransaction.svg'} alt="" style={{ height: "50px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sideCardDesigns">
        <div className="mb-3 h-100 singleCard">
          <div className="card-header singleCard">
            <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Transaction Details</strong>
            </div>

          </div>

          {role === "Enterprise" ?
            <>
              {data && data.length > 0 ?
                (<div className="App"> <ReactDataTable filter ={filterValue} rowHeight={20} data={data} columns={swagatcolumns} parentName="transaction_details"></ReactDataTable></div>)
                :
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
              }
            </>
            :
            <>
              {data && data.length > 0 ?
                (<div className="App"> <ReactDataTable filter={filterValue}  rowHeight={20} data={data} columns={columns} parentName="transaction_details"></ReactDataTable></div>) :
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)

              }
            </>
          }
        </div>



        {/* {
  data  && data.length > 0 ?
  (<div className="App"> <Table data={data} columns={columns}></Table></div>):
  (<div style={{display: "flex",  alignItems: "center",justifyContent: "center"}}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{display: "flex",  alignItems: "center",justifyContent: "center"}} /></h3></div>) 

} */}
        <br />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    transactions: state.transaction.fetchTransactionData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactionData: (viewtype, planname) => dispatch(transactionDataAction(viewtype, planname)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionListPage);
