import React, { useEffect } from "react";
import { connect } from "react-redux";
import { customerListAction,payAsyoyGoDriverListAction,premiumDriverListAction } from "../../../store/actions/customerAction";
import PureBreadcrumbs from "../Breadcrums"

const CustomerCard = (props) => {
  useEffect(() => {
    props.fetCustomerList();
    props.fetchPayasyougoList();
    props.fetchPremiumList();
  }, [])
  const activecustomer = props.customerlist && props.customerlist.filter(cust => cust.accountstatus === true);
  // const prepaidcustomer = props.customerlist.subscription && props.customerlist.subscription.filter(cust => cust.accountstatus === true);
  const payasyougoactive = props.payasyougocustomerlist && props.payasyougocustomerlist.filter(cust => cust.defaultplan === true)
  const premiumactive = props.premiumcustomerlist && props.premiumcustomerlist.filter(cust => cust.defaultplan === true)
  return (
    <>

      <div className="row ">
        <div className="col col-sm-4 ">
          <PureBreadcrumbs />
        </div>
      </div>

      <div className="row rowpadding mb-4">

        <div className="col-sm-12 col-md-4">
          <div className="col-md-12  sideCardDesigns ">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold"> Total Customers</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{props.customerlist !== null ? props.customerlist.length : 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding ">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold"> Active Customers</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{activecustomer != null ? activecustomer.length : 0}</div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3 ">
                <div className="row">
                  <div className="col-8 ml-5  pl-5 mr-4">
                    <div className="float-center">
                      <a href={`/dashboard/customer/totalCviewall`} className="btn btn-primary btn-block" ><span className="mr-1">View all</span></a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="col-md-12  sideCardDesigns ">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold"> Pay as you go</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{props.payasyougocustomerlist !== null ? props.payasyougocustomerlist.length : 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding ">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold"> Active</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{payasyougoactive != null ? payasyougoactive.length : 0}</div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3 ">
                <div className="row">
                  <div className="col-8 ml-5  pl-5 mr-4">
                    <div className="float-center">
                      <a href={`/dashboard/customer/payviewall`} className="btn btn-primary btn-block" ><span className="mr-1">View All</span></a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="col-md-12  sideCardDesigns ">
            <div className="row">
              <div className="col-sm-12 col-md-6 ">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold">Premium</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{props.premiumcustomerlist !== null ? props.premiumcustomerlist.length : 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="col-12 subSideCardDesigns m-1">
                  <div className="col-sm colpadding ">
                    <div className=" border-light mb-1 widget-content cardbg  ">
                      <div className="widget-content-outer">
                        <div className="float-center">
                          <div className="text-center font-weight-bold"> Active</div>
                        </div>
                        <div className="float-center">
                          <div className="text-center font-weight-bold" style={{ color: "#bc0078" }}>{premiumactive != null ? premiumactive.length : 0}</div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3 ">
                <div className="row">
                  <div className="col-8 ml-5  pl-5 mr-4">
                    <div className="float-center">
                      <a href={`/dashboard/customer/premiumviewall`} className="btn btn-primary btn-block" ><span className="mr-1">View All</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row rowpadding m-1 mb-4 sideCardDesigns ">
        <div className="col-6 p-3">
          <div className="col-12 ml-2">
            <img src={process.env.PUBLIC_URL + '/images/RFID.svg'} style={{ height: "34px", width: "32px" }} alt="" />
            <span className=" pl-2" style={{ fontSize: "18px", color: "#bc0078", fontWeight: "550" }}>Add RFID</span>
            <div className="page-title-subheading pt-2" style={{ fontSize: "12px", color: "#bc0078", fontWeight: "550" }}>RFID details for end customers.</div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-right m-3 pt-3">
            <a href={`/dashboard/assets/createrfid`} className="btn btn-primary" style={{ fontSize: "12px" }} >Add New RFID</a>
          </div>
        </div>
      </div>


      <div className="row rowpadding m-1 mb-2 sideCardDesigns ">
        <div className="col-6 p-3">
          <div className="col-12 ml-2">
            <img src={process.env.PUBLIC_URL + '/images/RFID.svg'} style={{ height: "34px", width: "32px" }} alt="" />
            <span className=" pl-2" style={{ fontSize: "18px", color: "#bc0078", fontWeight: "550" }}>Add new charger configurer</span>
            <div className="page-title-subheading pt-2" style={{ fontSize: "12px", color: "#bc0078", fontWeight: "550" }}>Change charger configration using mobile login</div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-right m-3 pt-3">
            <a href={`/dashboard/customer/addChargerConfigurer`} className="btn btn-primary" style={{ fontSize: "12px" }} >Add New Charger Configurer</a>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.customer.isLoading,
    customerlist: state.customer.fetchCustomerList,
    payasyougocustomerlist: state.customer.payasugolist,
    premiumcustomerlist: state.customer.premiumlist
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetCustomerList: () => dispatch(customerListAction()),
    fetchPayasyougoList:()=>dispatch(payAsyoyGoDriverListAction()),
    fetchPremiumList:()=>dispatch(premiumDriverListAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCard);