import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSingleLocationAction } from "../../../../store/actions/locationAction";



class ViewLocation extends Component {
   componentDidMount() {
      const {  match: { params },} = this.props;
      this.props.getLocation(params.locationId);
   }
  

   render() {
      const { firstname } = JSON.parse(localStorage.getItem("user"));
      return (

      <div className="container-fluid">
        <div className="sideCardDesigns card-signin my-5">
          <div className="card-body">
            <h4 className="text-center formHeading ">
              <b>View Location</b>
            </h4>
  

            <div className="row rowpadding  justify-content-between">
              <div className="col-md-6 col-sm-12 mt-3">
                <div className="col-12  formContainer">
                  <div className="col-12">
                    <label className="formLable" htmlFor="networkoperator">Network Operator</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="operator"
                              name="operator"
                              className="form-control"
                              value={this.props.locationdetail.operator}
                              disabled
                           />
                           <label htmlFor="operator">Operator</label>
                        </div>
                    <label className="formLable" htmlFor="enterprise">Enterprise</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="enterprise"
                              name="enterprise"
                              className="form-control"
                              value={this.props.locationdetail.enterprise}
                              disabled
                           />
                           <label htmlFor="enterprise">Enterprise</label>
                        </div>

                    <label className="formLable" htmlFor="locationname">Location Name</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              value={this.props.locationdetail.name}
                              disabled
                           />
                           <label htmlFor="name">Location Name</label>
                        </div>

                    <label className="formLable" htmlFor="locationnumber">Location Number</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="number"
                              name="number"
                              className="form-control"
                              value={this.props.locationdetail.number}
                              disabled
                           />
                           <label htmlFor="Number">Location ID</label>
                        </div>

                    <label className="formLable" htmlFor="locationstatus">Location Status</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="status"
                              name="status"
                              className="form-control"
                              value={this.props.locationdetail.status}
                              disabled
                           />
                           <label htmlFor="status">Location Status</label>
                        </div>

                    <label className="formLable" htmlFor="locationtype">Location Category</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="locationtype"
                              name="locationtype"
                              className="form-control"
                              value={this.props.locationdetail.locationtype}
                              disabled
                           />
                           <label htmlFor="locationtype">Location Type</label>
                        </div>

                    <label className="formLable" htmlFor="locationsubtype">Location Sub-Category</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="locationsubtype"
                              name="locationsubtype"
                              className="form-control"
                              value={this.props.locationdetail.locationsubtype}
                              disabled
                           />
                           <label htmlFor="locationsubtype">Location Sub-Type</label>
                        </div>


                    <label className="formLable" htmlFor="address">Address Line 1</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="address"
                              name="address"
                              className="form-control"
                              value={this.props.locationdetail.address}
                              disabled
                           />
                           <label htmlFor="address">Address Line 1</label>
                        </div>

                    <label className="formLable" htmlFor="latitude"> Location Latitude </label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="latitude"
                              name="latitude"
                              className="form-control"
                              placeholder="Enter Location Latitude"
                              value={this.props.locationdetail.latitude}
                              disabled
                           />
                           <label htmlFor="latitude">Location Latitude </label>
                        </div>


                    <label className="formLable" htmlFor="longitude">Location Longitude</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="longitude"
                              name="longitude"
                              className="form-control"
                              placeholder="Enter Location Longitude"
                              value={this.props.locationdetail.longitude}
                              disabled
                           />
                           <label htmlFor="longitude">Location Longitude</label>
                        </div>

                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12  mt-3">
                <div className="col-12  formContainer ">

                  <div className="col-12 ">

                    <label className="formLable" htmlFor="addressline1">Address Line 2</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="addressline1"
                              name="addressline1"
                              className="form-control"
                              value={this.props.locationdetail.addressline1}
                              disabled
                           />
                           <label htmlFor="addressline1">Address Line 2(Optional)</label>
                        </div>

                    <label className="formLable" htmlFor="landmark">Enter Landmark</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="landmark"
                              name="landmark"
                              className="form-control"
                              placeholder="Land Mark(Optional)"
                              value={this.props.locationdetail.addressline1}
                              disabled
                           />
                           <label htmlFor="landmark">Land Mark(Optional)</label>
                        </div>

                    <label className="formLable" htmlFor="country">country</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="country"
                              name="country"
                              className="form-control"
                              placeholder="Country"
                              value={this.props.locationdetail.country}
                              disabled
                           />
                           <label htmlFor="country">Country</label>
                        </div>

                    <label className="formLable" htmlFor="lstate">State</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="state"
                              name="state"
                              className="form-control"
                              placeholder="state"
                              value={this.props.locationdetail.state}
                              disabled
                           />
                           <label htmlFor="state">State</label>
                        </div>

                    <label className="formLable" htmlFor="city">Select City</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="city"
                              name="city"
                              className="form-control"
                              placeholder="city"
                              value={this.props.locationdetail.city}
                              disabled
                           />
                           <label htmlFor="city">City</label>
                        </div>

                    <label className="formLable" htmlFor="zipcode">Zipcode</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="zipcode"
                              name="zipcode"
                              className="form-control"
                              placeholder="Enter Zipcode"
                              value={this.props.locationdetail.zipcode}
                              disabled
                           />
                           <label htmlFor="zipcode">Zipcode</label>
                        </div>

                    <label className="formLable" htmlFor="source">Source</label>
                    <div className="form-label-group">
                           <input
                              type="text"
                              id="source"
                              name="source"
                              className="form-control"
                              value={this.props.locationdetail.source}
                              disabled
                           />
                           <label htmlFor="address">Source</label>
                        </div>


                    <label className="formLable" htmlFor="networkoperator">Ameneties</label>
                    <div className=" containerAme p-2 pt-1">
                    {this.props.locationdetail &&
                           this.props.locationdetail.amenity.map((ame) =>
                              <div className="form-check form-check-inline border badge badge-pill text py-1 px-2">
                                 <label className="form-check-label" >{ame.name}</label>
                              </div>
                           )}
                    </div>



                    <label className="formLable pt-4" htmlFor="enteredby">Entry By</label>
                    <div className="form-label-group ">
                           <input
                              type="text"
                              id="enterdby"
                              name="enterdby"
                              className="form-control control-label"
                              value={firstname}
                              disabled
                           />
                           <label htmlFor="enterdby">Entered By</label>
                        </div>

                    <label className="formLable" htmlFor="description">Description</label>
                    <div className="form-group pb-1">
                           <textarea
                              className="form-control p-3"
                              id="description"
                              name="description"
                              placeholder="Description(Optional)"
                              rows="4"
                              value={this.props.locationdetail.description}
                              disabled
                           ></textarea>
                        </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="row">
                     <div className="col-xl-6 col-sm-6 col-12">
                        <a
                           className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                           href="/dashboard/assets"
                        >
                           Back
                        </a>
                     </div>
                     <hr className="my-4" />
                  </div>
          

          </div>
        </div>
      </div>

      );
   }
}
const mapStateToProps = function (state) {
   return {
      locationdetail: state.location.fetchSingleLocation,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getLocation: (id) => dispatch(getSingleLocationAction(id)),
   };
};

export default connect(
   mapStateToProps,mapDispatchToProps)(withRouter(ViewLocation));
