import React, { useMemo, useEffect } from "react"
import moment from "moment";
import "../report/table.css"
import { connect } from "react-redux";
import { getAllhubuserlist,deactivateHubAction  } from "../../../store/actions/hubActions"
import Table from "./hubaccessTable";
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2'

const HubUserTableView=(props)=>{
  useEffect(()=>{
    props.getUserList();
    
  },[])
  const userrole = JSON.parse(localStorage.getItem('user'));
  const DeactivateUser=(user)=>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#1e295d',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
    
        window.location.reload();
        props.deactivateUser(user);
        // console.log("user",user,props.deactivateUser(user));
        props.getUserList(this.state);
      }
    });
  }

  
    const data = props.userlist && props.userlist
    const columns = useMemo(

        () => [
          {
            header: 'Mobile Number',
            id:'mobile',
            accessor: 'mobile'
          },
          {
            header: 'Name',
            accessor: 'name'
            
          },
          {
            header: 'Role',
            accessor: 'role'
          },
          {
            header: 'City',
            accessor: 'city'
          },
          {
            header: 'State',
            accessor: 'state'
          },
          {
            header: 'Country',
            accessor: 'country'
          },
          
          {
            header: 'Date',
            id: 'createdat',
        accessor: d => {
          return moment(d.createdat)
            .local()
            .format("DD-MM-YYYY");
        }
          },
          {
            header: 'Status',
            id:"accountstatus",
            accessor: s => {
              return s.accountstatus ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
            }
        },
    
        {
          header: 'Action',
          id:'action',
          accessor: user=> {
            return (
              
              <div>
                <span>
                <a rel="tooltip" title="View " className="table-action view" href={`/dashboard/hub/viewhubaccess/${user.mobile}`}>
                    <i className="fas fa-eye"></i></a>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span>
                <a rel="tooltip" title="Edit " className="table-action edit" href={`/dashboard/hub/edithubaccess/${user.mobile}`}>
                    <i className="fa fa-edit"></i></a>
                </span>
                &nbsp;&nbsp;&nbsp;
                {userrole.role === "Admin" ? (
                <span>
               
                    {<a  href="#" rel="tooltip" title="Activate" id="mobile" className="table-action slash" onClick={() => DeactivateUser(user.mobile, true)}>
                    <i className="fas fa-trash"></i></a>}
                </span>
                ):( <span ></span>)
              }
                &nbsp;&nbsp;&nbsp;

              </div>
              );
          } 
        },
      ],
        []
      );
    useEffect(()=>{
        props.getUserList();
    },[])
    return(
        <div className="row py-2">
        <div className="col">
 
        <div className="row py-2">
        <div className="col-md-12 col-lg-12">
        <div className="mb-3 h-100 sideCardDesigns">
        <div className="card-header singleCard">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
          <strong>User Details</strong>
        </div>
        
        </div>
    
        {!data.length ?
          (<div style={{display: "flex",  alignItems: "center",justifyContent: "center"}}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{display: "flex",  alignItems: "center",justifyContent: "center"}} /></h3></div>) 
     : 
    (<div className="App"> <Table data={data} columns={columns}></Table></div>) }
    
    
    </div>
  </div>
  </div>  </div>
  </div>

    )
}

const mapStateToProps = (state) =>{
    return{
        userlist: state.hub.getalluserlist,
        response: state.hub.deletehubuser
    }
  }
  const mapDispatchToProps=dispatch =>{
    return{
        getUserList: () => dispatch(getAllhubuserlist()),
        deactivateUser: (user) => dispatch(deactivateHubAction(user))
        
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(HubUserTableView);