import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import validator from "validator";
import { cityAction, countryAction, privilegeAction, stateAction, roleActionByNetworkOperator } from "../../../store/actions/commonAction";
import { operatorAction } from "../../../store/actions/opAction";
import { saveAccessmanagementAction, getHubroles, getAllprivileges } from "../../../store/actions/hubActions";
import { getSubscriptionPlanByCreatorAction } from "../../../store/actions/subscriptionAction";

var arr = [];

class HubAssignAccess extends Component {

    state = {
        name: "",
        phone: "",
        country: "",
        cstate: "",
        city: "",
        role: "",
        privileges:""
    }

    toggleShow = () => {
        this.setState({ hidden: !this.state.hidden });
    }

    componentDidMount() {
        this.props.countrylist();
        this.props.rolelist();
        this.props.privilegelist();
        this.props.npData("Network Operator");

    }
    handleChange = (e) => {
        const state = {
            ...this.state,
            [e.target.name]: e.target.value
           
        };
        if (e.target.name === "networkoperator") {
            this.props.rolelist("NP", this.state);
        }
        if (e.target.name === "country") {
            this.props.statelist(e.target.value);
        }
        if (e.target.name === "cstate") {
            this.props.citylist(e.target.value);
        }
        if (e.target.name === "role") {
            this.props.privilegelist(e.target.id);
        }

        this.setState(state);
    }

    handleCheckChange = (e) => {
        const target = e.target;
        let pvalue = target.value;
        let pid = target.id
        let cd = e.target['name']
        if (cd === "sedan.svg") {
            this.props.fetSubscriptionplan(e.target.id);
        }
        if (target.checked) {
            arr.push({ key: pid, name: pvalue })
            // console.log("code", cd)
            // console.log("arrpush", arr)

        } else {

            const removeIndex = arr.findIndex(item => item.name === pvalue);
            // remove object
            arr.splice(removeIndex, 1);
            // console.log("arrpop",arr)
        }
        this.setState({
            privileges: arr
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
       
        this.resetValidationStates();
        if (this.userFormValidation()) {
            this.props.saveUser({ ...this.state })
            alert("User created successully")
            return window.location.href = "/dashboard/hub";
        }
    }
    userFormValidation = () => {

        const name = this.state.name;
        const role = this.state.role;
        const privileges = this.state.privileges;
        const phone = this.state.phone;
        const country = this.state.country;
        const cstate = this.state.cstate;
        const city = this.state.city;

        let isGood = true;

        if (validator.isEmpty(name)) {
            name.isValid = false;
            name.message = "Name is required";
            isGood = false;
        }

        if (validator.isEmpty(role)) {
            role.isValid = false;
            role.message = "Role is required";
            isGood = false;
        }
        if (privileges[0] === undefined || privileges === "") {
            privileges.isValid = false;
            privileges.message = "Privileges is required";
            isGood = false;
        }

        if (validator.isEmpty(phone)) {
            phone.isValid = false;
            phone.message = "Phone is required";
            isGood = false;
        }
        if (validator.isEmpty(country)) {
            country.isValid = false;
            country.message = "Country is required";
            isGood = false;
        }
        if (validator.isEmpty(cstate)) {
            cstate.isValid = false;
            cstate.message = "State is required";
            isGood = false;
        }
        if (validator.isEmpty(city)) {
            city.isValid = false;
            city.message = "City is required";
            isGood = false;
        }

        if (!isGood) {
            this.setState({
                name,
                role,
                privileges,
                phone,
                country,
                cstate,
                city,
            });
        }

        return isGood;
    };
    resetValidationStates = () => {
        // make a copy of everything in state
        const state = JSON.parse(JSON.stringify(this.state));
        Object.keys(state).map((key) => {
            if (state[key].hasOwnProperty("isValid")) {
                state[key].isValid = true;
                state[key].message = "";
            }
        });
        this.setState(state);
    };

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    render() {
        // console.log("dscwe", this.props.privileges.id)
        const { name, role, privileges, phone, country, cstate, city} = this.state;
        if (this.props.userresponse && this.props.userresponse.error === '') {
            return <Redirect to='/dashboard/hub' />;
        }
        return (


            <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading"><b>Assign User Access</b></h4>
                        {/* {this.props.userresponse.error ? <Alert variant="danger">
                            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                            <p>
                                {this.props.userresponse.error}
                            </p>
                        </Alert> : ''
                        } */}
                        <form className="form mt-5" onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="row rowpadding  justify-content-between">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer">
                                        <div className="col-12">
                                            <label className="formLable" htmlFor="name">Name</label>
                                            <div className="form-label-group required">
                                                <input type="text" id="name" name="name" className="form-control" placeholder=" Name" onChange={this.handleChange} />
                                                <label htmlFor="name">First Name</label>
                                                <span className="help-block text-danger">{name.message}</span>
                                            </div>

                                            <label className="formLable" htmlFor="phone">Phone Number</label>
                                            <div className="form-label-group ">
                                                <input type="number" id="phone" className="form-control" name="phone" placeholder="Phone Number" maxLength="11" onInput={this.maxLengthCheck} onChange={this.handleChange} />
                                                <label htmlFor="phone">Phone Number</label>
                                                <span className="help-block text-danger">{phone.message}</span>
                                            </div>

                                            <label className="formLable" htmlFor="country">Country</label>
                                            <div className="form-label-group ">
                                                <select className="form-control border-radius" name="country" onChange={this.handleChange}>
                                                    <option>Country</option>
                                                    {this.props.countries && this.props.countries.map((country) => <option key={country.code} value={country.code}>{country.name}</option>)}
                                                </select>
                                                <span className="help-block text-danger">{country.message}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">
                                        <div className="col-12 ">

                                            <label className="formLable" htmlFor="cstate">State</label>
                                            <div className="form-label-group ">
                                                <select className="form-control border-radius" name="cstate" onChange={this.handleChange}>
                                                    <option>State</option>
                                                    {this.props.states && this.props.states.map((st) => <option key={st.code} value={st.name}>{st.name}</option>)}
                                                </select>
                                                <span className="help-block text-danger">{cstate.message}</span>
                                            </div>
                                            <label className="formLable" htmlFor="city">City</label>
                                            <div className="form-label-group required">
                                                <select className="form-control border-radius" name="city" onChange={this.handleChange}>
                                                    <option>City</option>
                                                    {this.props.cities && this.props.cities.map((c) => <option key={c.id} value={c.name}>{c.name}</option>)}
                                                </select>
                                                <span className="help-block text-danger">{city.message}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row rowpadding formContainer m-1 mb-4">
                                <div className="col-xl-3 col-sm-3 col-12 p-2 ">
                                    <h5 className="font-size-lg text-capitalize font-weight-normal pl-3"> Select Access Role</h5>
                                    <hr />
                                    {this.props.privileges && this.props.privileges.map((network) => (
                                        <div className="custom-control custom-radio pl-5 " key={network.id}>
                                            <input type="radio" id={network.key} name="role" className="custom-control-input" value={network.name} onChange={this.handleChange} />
                                            <label className="custom-control-label" htmlFor={network.key}>{network.name}</label>

                                        </div>
                                    ))}
                                    <span className="help-block text-danger">{role.message}</span>
                                </div>
                                <div className="col-xl-3 col-sm-3 col-12 p-2">
                                    <h5>Select Access Privilege</h5>
                                    <hr />
                                    {this.props.roles && this.props.roles.map((r) =>
                                        <div className="custom-control custom-radio pl-5 " key={r.key}>
                                            <input type="checkbox" name={r.code} id={r.key} className="custom-control-input" value={r.name} onChange={this.handleCheckChange} />
                                            <label className="custom-control-label" htmlFor={r.key}>{r.name}</label>
                                        </div>
                                    )}
                                    
                                    <span className="help-block text-danger">{role.message}</span>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-xl-6 col-sm-6 col-12">
                                    <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/access">Back</a>
                                </div>

                                <div className="col-xl-6 col-sm-6 col-12 float-right">
                                    <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">Assign User Access</button>

                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = function (state) {
    return {
        response: state.auth.currentUser,
        countries: state.common.countryList,
        states: state.common.stateList,
        cities: state.common.cityList,
        roles: state.hub.gethubroles,
        privileges: state.hub.getHubprivileges,
        userresponse: state.hub.saveAccessmanagement,
        operatorlist: state.operator.fetchOperator,
        sublistdata: state.subscriptionplan.fetcSubscriptionplan

    }
}
const mapDispatchToProps = dispatch => {
    return {
        countrylist: () => dispatch(countryAction()),
        statelist: (countrycode) => dispatch(stateAction(countrycode)),
        citylist: (statecode) => dispatch(cityAction(statecode)),
        rolelist: () => dispatch(getHubroles()),
        privilegelist: () => dispatch(getAllprivileges()),
        npData: (nrole) => dispatch(operatorAction(nrole)),
        saveUser: (user) => dispatch(saveAccessmanagementAction(user)),
        fetSubscriptionplan: () => dispatch(getSubscriptionPlanByCreatorAction("All"))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HubAssignAccess);