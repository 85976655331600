import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getPlanTypeAction, saveSubscriptionPlanAction } from "../../../store/actions/subscriptionAction";
import "./subscription.css";

const CreateSubscriptionPlan = (props) => {
    const { firstname } = JSON.parse(localStorage.getItem("user"));
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [subscription, setSubscription] = useState()

    useEffect(() => {
        props.plantypelist();

    }, [])
    const handleChange = (e) => {
        setSubscription({
            ...subscription,
            [e.target.name]: e.target.value.trim()
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        props.saveSubscriptionPlan(subscription, startDate, endDate)
    }

    // console.log(props.loading);
    if (props.response && props.response.error === '') {
        return <Redirect to='/dashboard/pricing' />;
    }

    return (
        <div className="container-fluid">
            <div className="sideCardDesigns card-signin my-5">
                <div className="card-body">
                    <h4 className="text-center formHeading"><b>Create Subscription Plan</b></h4>



                    <form className="form mt-5" onSubmit={handleSubmit} autoComplete="off">


                        <div className="row rowpadding  justify-content-between">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="plantype">Plan Type</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" name="plantype" onChange={handleChange}>
                                                <option value="">Select Plan Type</option>
                                                {props.plantypleres && props.plantypleres.map(pt =>
                                                    <option key={pt.id} value={pt.id}>{pt.name}</option>
                                                )
                                                }
                                            </select>
                                        </div>
                                        <label className="formLable" htmlFor="name">Subscription Name</label>
                                        <div className="form-label-group required">
                                            <input type="text" id="name" name="name" className="form-control control-label" placeholder="Subscription Name" required onChange={handleChange} />
                                            <label htmlFor="name">Subscription Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="code">Subscription Code</label>
                                        <div className="form-label-group">
                                            <input type="text" id="code" name="code" className="form-control control-label" placeholder="Subscription Code/Fleet Code" onChange={handleChange} />
                                            <label htmlFor="code">Subscription Code</label>
                                        </div>



                                        <label className="formLable" htmlFor="startDate">Start Date</label>
                                        <div className="form-label-group">
                                            <DatePicker className="form-control control-label "
                                                name="startDate"
                                                selected={startDate}
                                                onChange={date => setStartDate(date)}
                                                isClearable
                                                placeholderText="Select Subscription Start Date"

                                            />
                                        </div>

                                        <label className="formLable" htmlFor="endDate">End Date</label>
                                        <div className="form-label-group">
                                            <DatePicker className="form-control control-label"
                                                name="endDate"
                                                selected={endDate}
                                                onChange={date => setEndDate(date)}
                                                isClearable
                                                placeholderText="Select Subscription End Date"
                                            />
                                        </div>

                                        <label className="formLable" htmlFor="subscriptionfee">Subscription Fee</label>
                                        <div className="form-label-group">
                                            <input type="number" id="fee" name="subscriptionfee" className="form-control control-label" placeholder="Subscription Fee" onChange={handleChange} />
                                            <label htmlFor="fee">Subscription Fee</label>
                                        </div>

                                        <label className="formLable" htmlFor="setupcost">Subscription Setup Fee</label>
                                        <div className="form-label-group">
                                            <input type="number" id="setupcost" name="setupcost" className="form-control control-label" placeholder="Subscription Setup Fee" onChange={handleChange} />
                                            <label htmlFor="setupcost">Subscription Setup Fee</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="period">Subscription Period</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="period" onChange={handleChange}>
                                                <option value="">Select Subscription Period</option>
                                                <option>Monthly</option>
                                                <option>Quarterly</option>
                                                <option>Yearly</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="category">Subscription Category</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="category" onChange={handleChange}>
                                                <option value="">Subscription Category</option>
                                                <option>Individual</option>
                                                <option>Fleet</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="displaytype">Display to Customer</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="displaytype" onChange={handleChange}>
                                                <option value="">Display to Customer</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="expirationtype">Subscription Expiration Type</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="expirationtype" onChange={handleChange}>
                                                <option value="">Subscription Expiration Type</option>
                                                <option>Subscription End Date</option>
                                                <option>Incentive End Date</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="description">Description</label>
                                        <div className="form-label-group">
                                            <textarea className="form-control" id="description" name="description" placeholder="Description(Optional)" rows="3" onChange={handleChange}></textarea>
                                        </div>
                                        <label className="formLable" htmlFor="enteredby">Entered By</label>
                                        <div className="form-label-group pb-3">
                                            <input
                                                name="enteredby"
                                                type="text"
                                                className="form-control control-label"
                                                value={firstname}
                                                disabled
                                            />
                                            <label htmlFor="enterdby">Data Entered By</label>
                                        </div>

                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-lg-3 ">
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase" href="/dashboard/pricing">Cancel</a>
                            </div>
                            <div className="col-md-9 col-lg-9 ">
                                <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" >{!props.loading ? 'Save Subscription Plan' : 'Please wait...'}</button>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = function (state) {
    return {
        loading: state.subscriptionplan.isLoading,
        response: state.subscriptionplan.saveSubscriptionplan,
        plantypleres: state.subscriptionplan.fetchPlanType
    }
}
const mapDispatchToProps = dispatch => {
    return {
        saveSubscriptionPlan: (subscription, startdate, enddate) => dispatch(saveSubscriptionPlanAction(subscription, startdate, enddate)),
        plantypelist: () => dispatch(getPlanTypeAction())

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscriptionPlan);