import React, { useEffect, useState } from "react";
import { useParams, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { savefleetoperatorAction } from "../../../../store/actions/hubActions";
import Select from "react-select";
import dashboard from "../../assets/excel/newfleetoperator.xlsx";
import * as XLSX from "xlsx";



const FleetOperatorListMaster = (props) => {

    const [fleetList, setFleetList] = useState();

    const handleChange = (e) => {
        setFleetList({
            ...fleetList,
            [e.target.name]: e.target.value.trim(),
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("New fleet operator added");
        props.saveFleet(fleetList);
         return window.location.href = "/dashboard/hub";
    };


    return (
        <>
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>Create New Fleet Operator</b>
                        </h4>
                        <form className="form mt-4" onSubmit={handleSubmit} autoComplete="off">
                            <div className="row rowpadding  justify-content-center">

                                <div className="col-6  formContainer ">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="fleetoperator">Fleet Operator</label>
                                        <div className="form-label-group">
                                            <input
                                                type="text"
                                                id="fleetoperator"
                                                name="fleetoperator"
                                                className="form-control"
                                                placeholder="Fleet Operator"
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="fleetoperator">Fleet Operator</label>
                                        </div>

                                    </div>
                                </div>

                            </div>



                            <div className="row">
                                <div className="col-xl-6 col-sm-6 col-12">
                                    <a
                                        className="btn  btn-pill btn-wide columnsBtn text-uppercase float-left"
                                        href="/dashboard/hub"
                                    >
                                        Back
                                    </a>
                                </div>

                                <div className="col-xl-6 col-sm-6 col-12 float-right">
                                    <button className="btn  btn-pill btn-wide columnsBtn text-uppercase float-right">
                                        {!props.loading ? "Create New Fleet Operator" : "Please wait..."}
                                    </button>
                                </div>
                            </div>
                        </form>



                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = function (state) {
    return {
        response: state.hub.savebatterykw,
        loading: state.location.isLoading,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        saveFleet: (vehicleno) => dispatch(savefleetoperatorAction(vehicleno)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FleetOperatorListMaster));