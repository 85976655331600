
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PureBreadcrumbs from "../../Breadcrums"
import "../../report/report.css"
import ManageHubStatus from "./hubstatus"
import ManageHubPower from "./powerstatus"



const ManageHubStatusPage = (props) => {



    return (
        <div className="container-fluid">
            <div className="row  ">
                <div className="col col-sm-4 ">
                    <PureBreadcrumbs />
                </div>
            </div>
         
           <ManageHubStatus />
           <br/>

           <ManageHubPower/>
        </div>
    )
}



const mapStateToProps = function (state) {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
       
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageHubStatusPage);



