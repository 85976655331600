import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { liveChargingData } from "../../../store/actions/transactionAction";
import ChargingTable from "./liveChargingTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';
import moment from "moment";
const LiveChargingView = (props) => {
  useEffect(() => {
    props.LiveChargingData();

    const interval=setInterval(()=>{
      props.LiveChargingData();
     },30000)      
     return()=>clearInterval(interval)

  }, [])

  function refreshPage() {
    window.location.reload(false);
  }

  const data = props.charging && props.charging

  const columns = useMemo(

    () => [

      {
        header: 'ChargeBox ID',
        accessor: 'chargeboxid'
      },
      {
        header: 'Connector Number',
        accessor: 'connectorno'
      },
      {
        header: 'Charging Station Name',
        accessor: 'locationname'
      },
      {
        header: 'Customer Name',
        accessor: 'customername'
      },
      {
        header: 'Mobile Number',
        accessor: 'mobile'
      },
      {
        header: 'Transaction ID',
        accessor: 'transactionid'
      },
      {
        header: 'ID Tag',
        accessor: 'idTag'
      },
      {
        header: 'Unit Consumed',
        accessor: 'kwh'
      },
      {
        header: 'SOC',
        accessor: 'soc'
      },
      {
        header: 'Billed Amount',
        accessor: 'billedamount'
      },
      {
        header: 'Date',
        id: 'start1',
        accessor: d => {
          return moment(d.start)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Time',
        id: 'start2',
        accessor: d => {
          return moment(d.start).local().format('HH:mm:ss');
        }
      },
  

    ],
    []
  );

  return (
    <>
    
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="mb-3 h-100 sideCardDesigns">
            <div className="card-header singleCard">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <strong>Live Charging Details</strong>
              </div>
            </div>

            {data && data.length > 0 ?
              (<div className="App"> <ChargingTable data={data} columns={columns}></ChargingTable></div>)
              :
              (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>No Data Found<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
            }

          </div>
        </div>
      </div>

    </>
  );
};

const mapStateToProps = function (state) {
  return {
    charging: state.transaction.livechargingdata,

  }
}
const mapDispatchToProps = dispatch => {
  return {
    LiveChargingData: () => dispatch(liveChargingData()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveChargingView);




