import React from 'react';

function NotFound() {
 
    return (
    <div>
     <h1>Page not found</h1>
     
    </div>
  );
}
export default NotFound;