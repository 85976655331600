import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../Breadcrums"
import Loader from 'react-loader-spinner';
// import Table from "./hubReactTable";
import { getAllHubOperationData, getAllHubOperationDataWithDateRange } from "../../../../store/actions/hubActions";
import moment from "moment";
import ReactDataTable from "../../common/reactDataTable"
// import Modal from "react-animated-modal";
import { DateRangePicker } from 'rsuite';
// import 'rsuite/dist/rsuite.min.css';

import "../../../../styles/rsuite.min-cg.css";

const HubOperation = (props) => {

    const [previewImg, setPreviewImg] = useState("")
    const [previewImgMore, setPreviewImgMore] = useState([])

    const currentDate = new Date();
    const twoMonthsAgo = new Date(currentDate);
    twoMonthsAgo.setMonth(currentDate.getMonth() - 2);

    const [value, setValue] = React.useState([twoMonthsAgo, currentDate]);

    useEffect(() => {
        props.getAllOpsData()
    }, []);

    const previewImageFun = (e, previewImageStr) => {

        if (previewImageStr && previewImageStr !== "") {
            setPreviewImg(previewImageStr);
        } else {
            setPreviewImg("")
        }
    }

    const previewImageMoreFun = (e, previewImageMoreArry, imgType) => {
        console.log("previewImageMoreArry: ");
        console.log(previewImageMoreArry)
        if(Object.keys(previewImageMoreArry).length>0){
            var tempArry = [];
            if(imgType==="in"){
                tempArry.push(previewImageMoreArry.vechileinbackimage);
                tempArry.push(previewImageMoreArry.vechileinfrontimage);
                tempArry.push(previewImageMoreArry.vechileinleftimage);
                tempArry.push(previewImageMoreArry.vechileinrightimage);
            }else{
                if(imgType==="out"){
                    tempArry.push(previewImageMoreArry.vechileoutbackimage);
                    tempArry.push(previewImageMoreArry.vechileoutfrontimage);
                    tempArry.push(previewImageMoreArry.vechileoutleftimage);
                    tempArry.push(previewImageMoreArry.vechileoutrightimage);
                }
            }
            setPreviewImgMore(tempArry);
        }else{
            setPreviewImgMore([]);
        }
    }



    const data = props.fetchAllOpsData && props.fetchAllOpsData
    // console.log("all data----->", data)


    const filterValue = [
        { name: 'hubname', operator: 'contains', type: 'string', value: '' },
        { name: 'vehicleindrivername', operator: 'contains', type: 'string', value: '' },
        { name: 'vehicleoutdrivername', operator: 'contains', type: 'string', value: '' },
        { name: 'vehicleindrivernumber', operator: 'contains', type: 'string', value: '' },
        { name: 'vehicleoutdrivernumber', operator: 'contains', type: 'string', value: '' },
        { name: 'fleetoperator', operator: 'contains', type: 'string', value: '' },
        { name: 'vechileno', operator: 'contains', type: 'string', value: '' },
        { name: 'indate', operator: 'contains', type: 'string', value: '' },
        { name: 'outdate', operator: 'contains', type: 'string', value: '' },
        { name: 'entrybyname', operator: 'contains', type: 'string', value: '' },
        { name: 'checkinby', operator: 'contains', type: 'string', value: '' },
    ];

    const columns = useMemo(

        () => [

            {
                header: 'Hub Name',
                name: 'hubname',
                //flex:3,
                defaultWidth: 105

            },
            {
                header: 'Fleet Operator',
                name: 'fleetoperator',
                //flex:3,
                defaultWidth: 110
            },
            {
                header: 'In Driver Name',
                name: 'vehicleindrivername',
                //flex:3,
                defaultWidth: 110
            },
            {
                header: 'In Driver Number',
                name: 'vehicleindrivernumber',
                //flex:3,
                defaultWidth: 110
            },

            {
                header: 'Vehicle No',
                name: 'vechileno',
                //flex:3,
                defaultWidth: 110
            },
            {
                header: 'In SOC',
                name: 'insoc',
                //flex:3,
                defaultWidth: 80
            },
            {
                header: 'Out SOC',
                name: 'outsoc',
                //flex:3,
                defaultWidth: 80
            },
            // {
            //   header: 'Battery Capacity (in kW)',
            //   name: 'batterykw'

            // },
            {
                header: 'In-Date',
                name: 'indate',
                //flex:3,
                defaultWidth: 110,
                render: ({ value, data }) => {
                    const date = moment(data["createdat"]);
                    return date.format("DD-MM-YYYY");
                },

            },
            {
                header: 'In-Time',
                name: 'intime',
                //flex:3,
                defaultWidth: 110,
                render: ({ value, data }) => {
                    const time = moment(data["createdat"]);
                    return time.format("HH:mm:ss");
                },
            },
            {
                header: 'Check In By',
                name: 'checkinby',
                //flex:3,
                defaultWidth: 110
            },
            {
                header: 'Vechicle In Image',
                id: 'vechileinimage',
                name: 'vechileinimage',
                render: ({ value, data }) => {
                    return (
                        <>
                        {(data.vechileinleftimage !== "" || data.vechileinrightimage !== "" || data.vechileinfrontimage !== "" || data.vechileinbackimage !== "") && (
                          <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalopMore" onClick={e => previewImageMoreFun(e, data, "in")}>
                            Preview<i className="fas fa-eye"></i>
                          </a>
                        )}
                        {!data.vechileinleftimage && !data.vechileinrightimage && !data.vechileinfrontimage && !data.vechileinbackimage && "No Image Found"}
                      </>
                    )
                },
                //flex:3,
                defaultWidth: 110
            },    
                
            {
                header: 'Driver In Image',
                id: 'driverinimage',
                name: 'driverinimage',
                render: ({ value, data }) => {
                    return (
                        <>
                            {data.driverinimage !== "" ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalop" onClick={e => previewImageFun(e, data.driverinimage)}>Preview <i color="danger" className="fas fa-eye"></i></a> : "No Image Found"}

                        </>
                    )
                },
                //flex:3,
                defaultWidth: 110
            },
            {
                header: 'Out Driver Name',
                name: 'vehicleoutdrivername',
                defaultWidth: 110
            },
            {
                header: 'Out Driver Number',
                name: 'vehicleoutdrivernumber',
                defaultWidth: 110
            },
            {
                header: 'Out-Date',
                name: 'outdate',
                //flex:3,
                defaultWidth: 110,            
                render: ({ value, data }) => {
                    if (!data.outdate || !value) {
                        return null;
                    } else {
                        // const date = value ? moment(data["modifiedat"]) : moment("00-00-0000", "DD-MM-YYYY");
                        return value
                    }
                },
                

            },
            {
                header: 'Out-Time',
                name: 'outtime',
                defaultWidth: 110,
                render: ({ value, data }) => {
                    if (!data.outdate || !value) {
                        return null;
                    } else {
                        const time = moment(data["modifiedat"]);
                        return time.format("HH:mm:ss");
                    }
                },
            },
            
            {
                header: 'Check Out By',
                name: 'checkoutby',
                //flex:3,
                defaultWidth: 110
            },
            {
                header: 'Vehicle Out Image',
                id: 'vechileoutleftimage',
                name: 'vechileoutleftimage',
                render: ({ value, data }) => {
                  return (
                    <>
                      {(data.vechileoutleftimage !== "" || data.vechileoutrightimage !== "" || data.vechileoutfrontimage !== "" || data.vechileoutbackimage !== "") && (
                          <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalopMore" onClick={e => previewImageMoreFun(e, data, "out")}>
                            Preview <i className="fas fa-eye"></i>
                          </a>
                      )}
                      {!data.vechileoutleftimage && !data.vechileoutrightimage && !data.vechileoutfrontimage && !data.vechileoutbackimage && "No Image Found"}
                    </>
                  );
                },
                defaultWidth: 110
              },
              
            {
                header: 'Driver Out Image',
                id: 'driveroutimage',
                name: 'driveroutimage',
                render: ({ value, data }) => {
                    return (
                        <>
                            {data.driveroutimage !== "" ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalop" onClick={e => previewImageFun(e, data.driveroutimage)}>Preview <i color="danger" className="fas fa-eye"></i></a> : "No Image Found"}

                        </>
                    )
                },
                //flex:3,
                defaultWidth: 110
            },
            {
                header: 'Vehicle Keys',
                name: 'vehiclekeys',
                render: ({ value, data }) => {
                    return (
                        <>
                    <span style={{ color: data.ivs_checkList.checkIn.vehiclekeys ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkIn.vehiclekeys ? 'Yes' : 'No'}
                    </span> /
                    <span style={{ color: data.ivs_checkList.checkOut.vehiclekeys ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkOut.vehiclekeys ? 'Yes' : 'No'}
                    </span>
                  </>
                    );
                },
                defaultWidth: 110
            },
            
            {
                header: 'Offboard',
                name: 'offboard',
                render: ({ data }) => (
                    <>
                    <span style={{ color: data.ivs_checkList.checkIn.offboard ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkIn.offboard ? 'Yes' : 'No'}
                    </span> /
                    <span style={{ color: data.ivs_checkList.checkOut.offboard ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkOut.offboard ? 'Yes' : 'No'}
                    </span>
                  </>
                ),
                defaultWidth: 100
              },
              
              {
                header: 'Jack',
                name: 'jack',
                render: ({ data }) => (
                    <>
                    <span style={{ color: data.ivs_checkList.checkIn.jack ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkIn.jack ? 'Yes' : 'No'}
                    </span> /
                    <span style={{ color: data.ivs_checkList.checkOut.jack ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkOut.jack ? 'Yes' : 'No'}
                    </span>
                  </>
                ),
                defaultWidth: 110
              },
              
              {
                header: 'JackRod',
                name: 'jackrod',
                render: ({ data }) => (
                  <>
                    <span style={{ color: data.ivs_checkList.checkIn.jackrod ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkIn.jackrod ? 'Yes' : 'No'}
                    </span> /
                    <span style={{ color: data.ivs_checkList.checkOut.jackrod ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkOut.jackrod ? 'Yes' : 'No'}
                    </span>
                  </>
                ),
                defaultWidth: 100
              },
              
              {
                header: 'Stepney',
                name: 'stepney',
                render: ({ value, data }) => {
                  return (
                    <>
                    <span style={{ color: data.ivs_checkList.checkIn.stepney ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkIn.stepney ? 'Yes' : 'No'}
                    </span> /
                    <span style={{ color: data.ivs_checkList.checkOut.stepney ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkOut.stepney ? 'Yes' : 'No'}
                    </span>
                  </>
                  );
                },
                defaultWidth: 110
              },
              {
                header: 'LeftSideMirror',
                name: 'leftsidemirror',
                render: ({ value, data }) => {
                  return (
                    <>
                    <span style={{ color: data.ivs_checkList.checkIn.leftsidemirror ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkIn.leftsidemirror ? 'Yes' : 'No'}
                    </span> /
                    <span style={{ color: data.ivs_checkList.checkOut.leftsidemirror ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkOut.leftsidemirror ? 'Yes' : 'No'}
                    </span>
                  </>
                  );
                },
                defaultWidth: 110
              },
              {
                header: 'RightSidemMirror',
                name: 'rightsidemirror',
                render: ({ value, data }) => {
                  return (
                    <>
                    <span style={{ color: data.ivs_checkList.checkIn.rightsidemirror ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkIn.rightsidemirror ? 'Yes' : 'No'}
                    </span> /
                    <span style={{ color: data.ivs_checkList.checkOut.rightsidemirror ? 'green' : 'red' }}>
                      {data.ivs_checkList.checkOut.rightsidemirror ? 'Yes' : 'No'}
                    </span>
                  </>
                  );
                },
                defaultWidth: 110
              },
            {
                header: 'Total Time',
                defaultWidth: 110,
                id:"totalTime",
                render: ({ data }) => {
                  if (!data.outdate || !data.modifiedat || !data.createdat) {
                    return null;
                  } else {
                    const inTime = moment(data.createdat);
                    const outTime = moment(data.modifiedat);
                    const duration = moment.duration(outTime.diff(inTime));
                    const hours = duration.hours();
                    const minutes = duration.minutes();
                    const seconds = duration.seconds();
                    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                  }
                },
              },
              
            
            
            {
                header: 'Entry By',
                name: 'entrybyname',
                //flex:3,
                defaultWidth: 110
            },
            // {
            //   header: 'Total Unit',
            //   name: 'totalunits'

            // },
        ],
        []
    );
    const InHub = props.fetchAllOpsData && props.fetchAllOpsData.filter(coms => coms.outtime === "");

    return (
        <>
            <div className="container-fluid">
                <div className="row ">
                    <div className="col col-sm-4 ">
                        <PureBreadcrumbs />
                    </div>
                </div>

                <div className="row rowpadding justify-content-between ">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4 mt-2 ">
                                <div className=" colpadding sideCardDesigns p-2">
                                    <div className=" text-center cardbg singleCard" >
                                        <div className="card-body">
                                            <div className="media d-flex">
                                                <div className="float-right  ">
                                                    <img className="float-right" src={process.env.PUBLIC_URL + '/images/hub/vehicleIn.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                                                </div>
                                            </div>
                                            <div className="media-body float-center" style={{ color: "#10204B" }} >
                                                <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{InHub !== undefined && InHub !== null ? InHub.length : 0}</strong></h4></span>
                                                <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Vehicle In</h5></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4 mt-2 ">
                                <div className="colpadding sideCardDesigns p-2">
                                    <div className=" text-center cardbg singleCard" >
                                        <div className="card-body">
                                            <div className="media d-flex">
                                                <div className="float-right  ">
                                                    <img className="float-right" src={process.env.PUBLIC_URL + '/images/hub/vehicleOut.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                                                </div>
                                            </div>
                                            <div className="media-body float-center " style={{ color: "#10204B" }} >
                                                <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>34</strong></h4></span>
                                                <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Vehicle Out</h5></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4 mt-2 ">
                                <div className=" colpadding sideCardDesigns p-2">
                                    <div className=" text-center cardbg singleCard" >
                                        <div className="card-body">
                                            <div className="media d-flex">
                                                <div className="float-right  ">
                                                    <img className="float-right" src={process.env.PUBLIC_URL + '/images/hub/totalVehicle.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                                                </div>
                                            </div>
                                            <div className="media-body float-center" style={{ color: "#10204B" }} >
                                                <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.fetchAllOpsData !== undefined && props.fetchAllOpsData !== null ? props.fetchAllOpsData.length : 0}</strong></h4></span>
                                                <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Total Data</h5></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row rowpadding justify-content-between ">
                        <div className="col-md-12 ">
                            <div className="row sideCardDesigns">
                                <div className="col-6 p-3">
                                    <div className="col-12 ml-2 ">
                                        <img src={process.env.PUBLIC_URL + '/images/hub/vehicleIn.svg'} style={{ height: "34px", width: "32px" }} alt="" />
                                        <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>Vehicle Data</span>
                                        <div className="page-title-subheading pt-2" style={{ fontSize: "12px", color: "#bc0078", fontWeight: "550" }}>Vehicle Checkin filter by date and time and Parking Details</div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-right m-3 pt-3">
                                        <a href={`/dashboard/hub/vehicleparking`} className="btn btn-primary" >Vehicle Checkin and Parking Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row rowpadding  m-1">
                    <div className="col">
                        <div className="row py-2">
                            <div className="col-md-12 col-lg-12 sideCardDesigns ">
                                <div className="mb-3 h-100 singleCard">
                                    <div className="card-header singleCard">
                                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Hub Operation Details</strong>
                                        </div>

                                    </div>
                                    <div className="text-right singleCard">
                                        <DateRangePicker
                                            style={{ paddingRight: "18px" }}
                                            format="dd-MM-yyyy HH:mm:ss"
                                            value={value}
                                            onChange={(v)=>{
                                                setValue(v)
                                            }}
                                            onOk={(e)=>{
                                                props.getAllOpsDataDateRange(e)
                                            }}
                                        />
                                    </div>

                                    {data && data.length > 0 ?
                                        (<div className="App pt-2"> <ReactDataTable data={data} columns={columns} parentName = "hub_operation" filter={filterValue}></ReactDataTable></div>)
                                        :
                                        (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id="mypreviewImageModalop" className="modal " abindex="-1" role="dialog" data-backdrop="false" style={{ paddingTop: '150px' }}>
                    <div className="modal-dialog modal-sm p-2" role="dialog" aria-labelledby="myModalLabel" >
                        <div className="modal-content ">
                            <div className="modal-body text-center " >
                        

                                {(() => {

                                    if (previewImg || ((previewImg.indexOf("http://") == 0 || previewImg.indexOf("https://") == 0))) {
                                        return (
                                            <img src={previewImg} className="previewImage w-100" />
                                        )
                                    } else {
                                        return (
                                            <img src={`data:image/jpeg;base64,${previewImg}`} className="previewImage w-100" />
                                        )
                                    }
                                })()}
                            </div>
                            <button type="button" className="btn btn-default columnsBtn p-2 m-2" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div> */}

                <div id="mypreviewImageModalop" className="modal " abindex="-1" role="dialog" data-backdrop="false" style={{ paddingTop: '100px' }}>
                    <div className="modal-dialog modal-sm p-2">
                        <div className="modal-content ">
                            <div className="modal-body text-center ">

                                {(() => {

                                    if (previewImg || ((previewImg.indexOf("http://") == 0 || previewImg.indexOf("https://") == 0))) {
                                        return (
                                            <img src={previewImg} className="previewImage w-100" />
                                        )
                                    } else {
                                        return (
                                            <img src={`data:image/jpeg;base64,${previewImg}`} className="previewImage w-100" />
                                        )
                                    }
                                })()}
                            </div>

                            <button type="button" className="btn btn-default columnsBtn p-2 m-2" data-dismiss="modal">Close</button>

                        </div>

                    </div>
                </div>

                <div id="mypreviewImageModalopMore" className="modal " abindex="-1" role="dialog" data-backdrop="false" style={{ paddingTop: '100px' }}>
                    <div className="modal-dialog modal-sm p-2">
                        <div className="modal-content ">
                            <div className="modal-body text-center ">

                                {previewImgMore.map((previewImg) => (
                                    <div>
                                        {(() => {

                                            if (previewImg || ((previewImg.indexOf("http://") == 0 || previewImg.indexOf("https://") == 0))) {
                                                return (
                                                    <img src={previewImg} className="previewImage w-100" />
                                                )
                                            } else {
                                                return (
                                                    <img src={`data:image/jpeg;base64,${previewImg}`} className="previewImage w-100" />
                                                )
                                            }
                                        })()}
                                    </div>
                                ))}

                        
                            </div>

                            <button type="button" className="btn btn-default columnsBtn p-2 m-2" data-dismiss="modal">Close</button>

                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        fetchAllOpsData: state.hub.getAllHubOperationData
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllOpsData: () => dispatch(getAllHubOperationData()),
        getAllOpsDataDateRange: (req) => dispatch(getAllHubOperationDataWithDateRange(req)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HubOperation);

