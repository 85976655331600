import React from 'react';
import StationCardModel from './stactioncard';
import "./station.css";
import StationView from './stationlist';
import ChargerListView from './chargerlist'
import LiveChargingView from './liveCharging';
import Initialize from './initialize';

function Station() {

  const { role } = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="container-fluid">
      <StationCardModel></StationCardModel>
      <StationView></StationView>
      <br />
      <ChargerListView></ChargerListView>
      <br />
      <LiveChargingView ></LiveChargingView >
   <br />
   <Initialize></Initialize>
   
  
    </div>
  );
}
export default Station;