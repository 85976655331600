import React, { useMemo, useEffect,useState  } from "react"
import { connect } from "react-redux";
import moment from "moment";
import { getPaymenthistoryActions } from "../../../store/actions/customerAction";
import Table from "./singleCustomerReportTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';
import {useParams } from 'react-router-dom';
import PureBreadcrumbs from "../Breadcrums"
const SingleCustomerWalletReport = (props) => {
  
 
    const {mobile}  = useParams();
    // console.log("mobile",mobile)
    
  useEffect(() => {
    props.fetCustomerwalletList(mobile);
  }, [])

  const datahistory = props.customerlist && props.customerlist;
  // console.log("datahistory",datahistory)
  const data  = datahistory && datahistory.history;
// console.log("wallet",data)
  const columns = useMemo(

    () => [
      {
        header: 'mobile',
        accessor:'mobile'
      },
      {
        header: 'walletid',
        accessor: 'walletid'
      },

      {
        header: 'Update Date',
        id: 'updatedat',
        accessor: d => {
          return moment(d.updatedat)
            .local()
            .format("DD-MM-YYYY");
        }
      },

      {
        header: 'orderid',
        accessor: 'orderid'
      },
      {
        header: 'txnid',
        accessor: 'txnid'
      },
      {
        header: 'Amount',
        accessor: 'amount'
      },
      {
        header: 'Description',
        id:"description",
        accessor: s => {
          if(s.status== 1){
            return <span className="text-danger">Failed  </span>
          }
          else if(s.status == 0)
          {
            return <span className="text-success">Credit</span>
          }
          else if(s.status== 2)
          {
            return  <span className="text-danger">Debit</span>
          }
          else if(s.status== -1)
          {
            return  <span className="text-warning">Payment Initiated</span>
          }
          else if(s.status== 3)
          {
            return  <span className="text-success">Refunded</span>
          }
          
          //return s.description=="Credit" ? <span className="text-success">Credit  </span> : <span className="text-danger">Debit</span>
        }
      },
    
    ],
    []
  );

  return (
    <>  <div className="container-fluid">
            <div className="row ">
        <div className="col col-sm-4 ">
         <a class="btn columnsBtn p-2" href="/dashboard/customers">Back</a>
        </div>
      </div>


        <div className="row py-2">
          <div className="col-md-12 col-lg-12">
            <div className="mb-3 h-100 sideCardDesigns">
              <div className="card-header singleCard">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <strong>Customer Wallet History</strong>
                 
                </div>
                
              </div>

              {/*{!data.length ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
              (<div className="App"> <Table data={data} columns={columns}></Table></div>)}*/}

                {(!data || !data.length) ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
              (<div className="App"> <Table data={data} columns={columns}></Table></div>)}

            </div>
          </div>
        </div> 
        </div>
        </>
  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.customer.isLoading,
    customerlist: state.customer.paymenthistory
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetCustomerwalletList: (mobile) => dispatch(getPaymenthistoryActions(mobile))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleCustomerWalletReport);