
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PureBreadcrumbs from "../../Breadcrums"
import { getAllHubListData, getVehicleStatusReport } from '../../../../store/actions/hubActions';
import Table from "./vehicleStatusReportTable";
import "../../report/table.css"
import Loader from 'react-loader-spinner';


const VehicleStatusReport = (props) => {
    // const initialValues = { hubname: "All" }
    const [singlehubname, setSinglehubname] = useState("")


    useEffect(() => {
        // let hubb = "All"
        props.getAllHubListData();
        // if (hubb == "All") {
        //     // props.getVehicleStatusReport(hubb);
        // }
    }, []);

    // useEffect(() => {
    //     console.log(props.isVehicleStatusReportLoading)
    // }, [props.isVehicleStatusReportLoading]);

    useEffect(() => {
        if (props.fetchAllHubData !== undefined && props.fetchAllHubData !== null && props.fetchAllHubData.length > 0) {
            let temp = props.fetchAllHubData[0]
            setSinglehubname(temp.hubname)
            props.getVehicleStatusReport(temp.hubname);
        }
    }, [props.fetchAllHubData]);

    const handleChange = (e) => {
        let hubname = e.target.value
        const { name, value } = e.target;
        setSinglehubname(value);

        if (e.target.value !== "" && e.target.value !== undefined) {
            props.getVehicleStatusReport(hubname);
        }

    };



    const columns = useMemo(

        () => [

            {
                header: 'Vehicle Number',
                accessor: 'vechileno'
            },
            {
                header: 'Home Depot',
                accessor: 'homeHub'
            },
            {
                header: 'Status',
                accessor: 'isCheckedInStatus'
            },
            {
                header: 'Last Checked In Depot',
                accessor: 'hubname'
            },
            {
                header: 'Out Driver Name',
                accessor: 'vehicleoutdrivername'
            },
            {
                 header: 'Out Driver Number',
                 accessor: 'vehicleoutdrivernumber'
 
             },
            {
                header: 'Checked Out Time',
                id: 'checkindate',
                accessor: d => {
                  return d.outdate+ " "+d.outtime
                }

            },

        ],
        []);


    const hubList = props.fetchAllHubData && props.fetchAllHubData.map(id => id.hubname)
    
    const data = props.vehicleStatusReport && props.vehicleStatusReport

    return (
        <div className="container-fluid">
            <div className="row  ">
                <div className="col col-sm-4 ">
                    <PureBreadcrumbs />
                </div>
            </div>
            <div className="row">
                <div className="col">
                {props.isLoading ? (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)  :
                    (<div className="sideCardDesigns mb-2">
                        <div className="card-header  singleCard  justify-content-between">
                            <div className=" text-left" style={{ color: "#10204B" }}>
                                Select Hub to view desired data
                            </div>
                            <div className=" text-right">
                                <select className=" columnsBtn p-2" name="hubname" onChange={handleChange} value={singlehubname}>
                                    <option value="All" >All</option>
                                    {hubList && hubList.map((hub) => (
                                        <option key={hub.id} value={hub} >{hub}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col">
                                <div className="row ">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="mb-3 h-100 singleCard">

                                        {props.isVehicleStatusReportLoading ? (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>) : (data && data.length >0  ?  (<div className="App"> 
                                           <Table data={data} columns={columns} hubName={singlehubname}></Table>
                                           </div>)
                                               :
                                               (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#1e295d", fontWeight: "600", fontSize: "30px", marginTop: "30px" }}>No Data Available</div>))}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = function (state) {
    return {
        fetchAllHubData: state.hub.getAllHubListData,
        vehicleStatusReport: state.hub.vehicleStatusReport,
        isLoading: state.hub.isLoading,
        isVehicleStatusReportLoading: state.hub.isVehicleStatusReportLoading,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllHubListData: () => dispatch(getAllHubListData()),
        getVehicleStatusReport: (hubname) => dispatch(getVehicleStatusReport(hubname)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleStatusReport);




