import React, { useEffect, useState } from "react";
import { countryAction } from "../../../../store/actions/commonAction";
import { stateAction } from "../../../../store/actions/commonAction";
import { cityAction } from "../../../../store/actions/commonAction";
import { getLocationAction } from "../../../../store/actions/locationAction";
import { CUSTOMER_BASE_URL, SAVE_RFID_URL } from "../../../config/configs";
import { saveRfidExcelActions } from "../../../../store/actions/customerAction";
import { getPriceByCreatorAction } from "../../../../store/actions/priceAction"
import dashboard from "../excel/rfid.xlsx";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import { networkEnterpriseOperatorAction, operatorAction } from "../../../../store/actions/opAction";


const CreateNewRFID = (props) => {
    const [selectedFile, setSelectedFiles] = useState(undefined);
    const [rfidlist, setRFIDList] = useState();
    useEffect(() => {
        props.npData("Network Operator");
        props.countrylist();
        props.fetPrice()

    }, []);
    const handleChange = (e) => {
        setRFIDList({
            ...rfidlist,
            [e.target.name]: e.target.value.trim()
        });
        if (e.target.name === "networkoperator") {
            let index = e.target.selectedIndex;
            let el = e.target.childNodes[index];
            let createdby = el.getAttribute("id");
            props.epData(createdby, "Enterprise");
        }
        if (e.target.name === "country") {
            props.statelist(e.target.value);
        }
        if (e.target.name === "state") {
            props.citylist(e.target.value);
        }

    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
    };

    // const fileSelectedHandler = (event) => {
    //     setSelectedFiles(event.target.files);
    // }

    const handleRfidSubmit = (e) => {
        e.preventDefault();
        //  console.log("Selectedfile",selectedFile)
        let formData = new FormData();
        // console.log("RFID", rfidlist)
        const { firstname, lastname, mobile, emailid, idprof, price, idprofnumber, rfidno, rfidissuedate, taguid, keyuid, country, state, city, status, topup, address, usedamt, current, networkoperator, enterprise } = rfidlist;
        formData.append("operator", networkoperator)
        formData.append("enterprise", enterprise)
        formData.append("firstname", firstname)
        formData.append("lastname", lastname)
        formData.append("mobile", mobile)
        formData.append("emailid", emailid)
        formData.append("idprof", idprof)
        formData.append("idprofnumber", idprofnumber)
        formData.append("rfidno", rfidno)
        formData.append("rfidissuedate", rfidissuedate)
        formData.append("taguid", taguid)
        formData.append("keyuid", keyuid)
        formData.append("country", country)
        formData.append("price", price)
        formData.append("state", state)
        formData.append("city", city)
        formData.append("status", status)
        formData.append("topup", topup)
        formData.append("address", address)
        formData.append("usedamt", usedamt)
        formData.append("current", current)
        const { token, id } = JSON.parse(localStorage.getItem('user'))
        formData.append("createdby", id)
        var bearer = 'basic ' + token;
        axios.post(
            CUSTOMER_BASE_URL + SAVE_RFID_URL,
            formData,
            {
                headers: {
                    'Authorization': bearer

                },
            }
        )
        alert('New RFID Added');
        return window.location.href = "/dashboard/customers";

    }

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
                // console.log("exceldata", data)
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
        promise.then((d) => {
            // console.log("data2", d);
            setRFIDList(d);
            for (let value of Object.values(d)) {
                const a = (Object.keys(value));
                // console.log("array of keys", a);

                var b = ['firstname', 'lastname', 'mobile', 'emailid', 'idprof', 'idprofnumber', 'rfidno', 'rfidissuedate', 'taguid', 'keyuid', 'country', 'state', 'city', 'status', 'address', 'topup'];
                // console.log("array of key b",b);
                // Comparing both arrays using stringify
                if (JSON.stringify(a) == JSON.stringify(b)) {
                    setRFIDList(d);
                    return;
                } else {
                    alert("Header format does not match");

                    return window.location.href = "/dashboard/customers";
                }
            }

        });
    };

    const handleRfidExcelUpload = () => {
        for (let i = 0; i < rfidlist.length; i++) {
            props.saveRfidExcel(rfidlist[i]);
        }
        alert('New RFID Added');
    }

    if (props.saveexcelrfid.result === "New Excel Rdif has been inserted into DB") {
        return <Redirect to={"/dashboard/customers"}></Redirect>
    }

    return (
        <div className="container-fluid">
            <div className="sideCardDesigns card-signin my-5">
                <div className="card-body">
                    <h4 className="text-center formHeading"><b>Create New RFID</b></h4>

                    <form className="form mt-5" onSubmit={handleRfidSubmit} autoComplete="off">

                        <div className="row rowpadding  justify-content-between">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="networkoperator">Network Operator</label>
                                        <div className="form-label-group">
                                            <select
                                                className="form-control border-radius"
                                                id="noperator"
                                                name="networkoperator"
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Network Operator</option>

                                                {props.operatorlist &&
                                                    props.operatorlist.map((network) => (
                                                        <option
                                                            key={network.id}
                                                            id={network.id}
                                                            value={network.organization}
                                                        >
                                                            {network.organization}{" "}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <label className="formLable" htmlFor="enterprise">Enterprise</label>
                                        <div className="form-label-group">
                                            <select
                                                className="form-control border-radius"
                                                id="enterprise"
                                                name="enterprise"
                                                required
                                                onChange={handleChange}
                                            >
                                                <option value="">Enterprise</option>
                                                <option>None</option>
                                                {props.enterpriselist === null ? (
                                                    <option value="none">none</option>
                                                ) : (
                                                    props.enterpriselist &&
                                                    props.enterpriselist.map((enterprise) => (
                                                        <option
                                                            key={enterprise.id}
                                                            value={enterprise.organization}
                                                        >
                                                            {enterprise.organization}
                                                        </option>
                                                    ))
                                                )}
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="firstname">First Name</label>
                                        <div className="form-label-group required">
                                            <input type="text" id="firstname" name="firstname" className="form-control control-label" placeholder="First Name" onChange={handleChange} />
                                            <label htmlFor="firstname">First Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="lastname">Last Name</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="lastname" name="lastname" className="form-control control-label" placeholder="Last Name" onChange={handleChange} />
                                            <label htmlFor="lastname">Last Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="mobile">Mobile Number</label>
                                        <div className="form-label-group " >
                                            <input type="number" id="mobile" name="mobile" className="form-control control-label" placeholder="Mobile Number" maxLength="10" onInput={maxLengthCheck} onChange={handleChange} />
                                            <label htmlFor="mobile">Mobile Number</label>
                                        </div>

                                        <label className="formLable" htmlFor="emailid">Email ID</label>
                                        <div className="form-label-group ">
                                            <input type="email" id="emailid" name="emailid" className="form-control control-label" placeholder="Email ID" onChange={handleChange} />
                                            <label htmlFor="emailid">Email ID</label>
                                        </div>

                                        <label className="formLable" htmlFor="idprof">ID Proof</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" id="idprof" name="idprof" onChange={handleChange} >
                                                <option>Select ID Proof</option>
                                                <option>Driving License</option>
                                                <option>Voter Id Card</option>
                                                <option>Passport No.</option>
                                                <option>Pan Card</option>
                                                <option>Aadhar Card No</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="idprofnumber">ID Proof Number</label>
                                        <div className="form-label-group " >
                                            <input type="text" id="idprofnumber" name="idprofnumber" className="form-control control-label" placeholder="ID Proof No" onChange={handleChange} />
                                            <label htmlFor="idprofnumber">ID Proof Number</label>
                                        </div>

                                        <label className="formLable" htmlFor="rfidno">RFID number</label>
                                        <div className="form-label-group required ">
                                            <input type="text" id="rfidno" name="rfidno" className="form-control control-label" placeholder="RFID No" onChange={handleChange} />
                                            <label htmlFor="rfidno">RFID number</label>
                                        </div>

                                        <label className="formLable" htmlFor="rfidissuedate">RFID Issue Date</label>
                                        <div className="form-label-group required ">
                                            <input className="form-control" type="date" id="rfidissuedate" name="rfidissuedate" onChange={handleChange} />
                                            <label htmlFor="rfidissuedate">RFID Issue Date</label>
                                        </div>

                                        <label className="formLable" htmlFor="taguid">TAG-UID</label>
                                        <div className="form-label-group required ">
                                            <input type="text" id="taguid" name="taguid" className="form-control control-label" placeholder="TAG-UID(authorization and authentication)" onChange={handleChange} />
                                            <label htmlFor="taguid">TAG-UID</label>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="keyuid">KEY-UID</label>
                                        <div className="form-label-group required ">
                                            <input type="text" id="keyuid" name="keyuid" className="form-control control-label" placeholder="KEY-UID(authorization and authentication)" onChange={handleChange} />
                                            <label htmlFor="keyuid">KEY-UID</label>
                                        </div>

                                        <label className="formLable" htmlFor="country">Country</label>
                                        <div className="form-label-group  ">
                                            <select className="form-control border-radius" name="country" onChange={handleChange}>
                                                <option>Select Country</option>
                                                {props.countries && props.countries.map((country) => <option key={country.code} value={country.code}>{country.name}</option>)}
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="state">State</label>
                                        <div className="form-label-group  ">
                                            <select className="form-control border-radius" name="state" onChange={handleChange}>
                                                <option>Select State</option>
                                                {props.states && props.states.map((st) => <option key={st.code} value={st.name}>{st.name}</option>)}
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="city">City</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="city" onChange={handleChange}>
                                                <option>Select City</option>
                                                {props.cities && props.cities.map((c) => <option key={c.id} value={c.name}>{c.name}</option>)}
                                            </select>
                                        </div>


                                        <label className="formLable" htmlFor="status">Status</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="status" onChange={handleChange}>
                                                <option>Select Status</option>
                                                <option>Activated</option>
                                                <option>Deactivated</option>
                                            </select>
                                        </div>



                                        <label className="formLable pt-4" htmlFor="address">Address</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="address" name="address" className="form-control" placeholder="Address" onChange={handleChange} />
                                            <label htmlFor="address">Address</label>
                                        </div>

                                        <label className="formLable" htmlFor="price">Select Plan Price </label>
                                        <div className="form-label-group required ">
                                            <select className="form-control border-radius" name="price" required onChange={handleChange}>
                                                <option value="">Select Plan Price </option>
                                                {props.pricelistdata && props.pricelistdata.map(p =>
                                                    <option id={p.id} value={p.name}>{p.name}</option>
                                                )}
                                            </select>

                                        </div>
                                        <label className="formLable" htmlFor="topup">Top Up Amount</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="topup" name="topup" className="form-control" placeholder="Top Up Amount" onChange={handleChange} />
                                            <label htmlFor="topup">Top Up Amount</label>
                                        </div>
                                        <label className="formLable" htmlFor="usedamt">Used Amount</label>
                                        <div className="form-label-group">
                                            <input type="text" id="usedamt" name="usedamt" className="form-control" placeholder="Used Amount" onChange={handleChange} disabled />
                                            <label htmlFor="usedamt">Used Amount</label>
                                        </div>
                                        <label className="formLable" htmlFor="current">Current Balance</label>
                                        <div className="form-label-group">
                                            <input type="text" id="current" name="current" className="form-control" placeholder="Used Amount" onChange={handleChange} disabled />
                                            <label htmlFor="current">Current Balance</label>
                                        </div>
                                        <br />
                                        <br />
                                        <br />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 ">
                                    <a className="btn btn-primary btn-pill btn-wide text-uppercase" href="/dashboard/assets">Back</a>
                                </div>

                                <div className="col-md-9 col-lg-9 ">
                                    <button className="btn btn-primary btn-pill btn-wide text-uppercase float-right">Create RFID</button>

                                </div>
                                <hr className="my-4" />
                            </div>
                        </div>
                    </form>

                    <div className="row">
                        <div className="hr-sect">
                            <span style={{ color: "#10204B", fontWeight: "600", fontSize: "30px" }}>OR</span>
                        </div>
                    </div>


                    <div className="row formLable">
                        <div className="col">
                            <div className="col-12 formContainer p-5">
                                <h4 className="text-left">
                                    <b> Download Excel </b>
                                </h4>

                                <div className="page-title-subheading text-left">
                                    <ul>Steps to follow</ul>
                                    <ul>1. Download the excel.</ul>
                                    <ul>2. Add the desired data without changing header row.</ul>
                                    <ul>2. Select the ID Proof, Country, State and city from the dropdown.</ul>
                                </div>
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase " href={dashboard} download="rfid.xlsx" >
                                    {" "}
                                    Download Excel Format Here{" "}
                                </a>
                            </div>
                        </div>
                        <div className="col ">
                            <div className="col-12 formContainer p-5">
                                <h4 className="text-left">
                                    <b> Upload Excel </b>
                                </h4>
                                <div className="page-title-subheading text-left">
                                    <ul>Steps to follow</ul>
                                    <ul>1. Save the previous excel file.</ul>
                                    <ul>2. Click on choose file and select the same file.</ul>
                                    <ul>3. Click upload.</ul></div>
                                <input type="file" className="columnsBtn p-1" name="file_upload" onChange={(e) => { const file = e.target.files[0]; readExcel(file); }} />
                            </div>
                        </div>


                    </div>

                    <br />
                    <div className="row">
                        <div className="col-md-3 col-lg-3 ">
                            <a
                                className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                href="/dashboard/customers"
                            >
                                Cancel
                            </a>
                        </div>
                        <div className="col-md-9 col-lg-9 ">
                            <button
                                className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"
                                onClick={handleRfidExcelUpload}
                            >
                                Upload New RFID
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}


const mapStateToProps = function (state) {
    return {
        loading: state.customer.isLoading,
        operatorlist: state.operator.fetchOperator,
        enterpriselist: state.operator.fetchEnterprise,
        locations: state.location.fetchLocation,
        countries: state.common.countryList,
        states: state.common.stateList,
        cities: state.common.cityList,
        rfidResponse: state.customer.saveRfid,
        saveexcelrfid: state.customer.saverfidexcel,
        pricelistdata: state.price.fetchPriceList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        npData: (nrole) => dispatch(operatorAction(nrole)),
        epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
        getLocation: () => dispatch(getLocationAction()),
        countrylist: () => dispatch(countryAction()),
        statelist: (countrycode) => dispatch(stateAction(countrycode)),
        citylist: (statecode) => dispatch(cityAction(statecode)),
        saveRfidExcel: (rfId) => dispatch(saveRfidExcelActions(rfId)),
        fetPrice: () => dispatch(getPriceByCreatorAction()),
        // saveRfid: (rfid, documents) => dispatch(saveRfidAction(rfid, documents)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewRFID);
