import React, { useEffect } from "react";
import { connect } from "react-redux";
import { customerListAction } from "../../../store/actions/customerAction";

const MonthlyViewall=(props)=>{
    useEffect(()=>{
        props.fetCustomerList();
    },[])
    return(
        <div className="row-fluid py-2">
        <div className="col">
        <div className="p-3 sideCardDesigns">
        <div className="row py-2">  
        <div className="col-xl-12 col-md-12">
        <div className="mb-3 h-100 sideCardDesigns">
        <div className="card-header">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">Monthly Customer Details
        </div>
        </div>
    
        <div className="table-responsive">
        <table className="table table-sm table-bordered">
        <thead>
        <tr>
            <th scope="col">Customer Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Email ID</th>
            <th scope="col">Date</th>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
            </tr>
            
         </thead>   
           <tbody>     
           {props.customerlist && props.customerlist.map((customer) =>
                 <tr key={customer.id}>
                <td >{customer.name}</td>
                <td >{customer.mobile}</td>
                <td >{customer.email}</td>
                <td >{(new Date(customer.createdat)).toLocaleDateString()}</td>
                <td >{customer.address}</td>
                <td >{customer.city}</td>
                <td >{customer.state}</td>
                <td >{customer.accountstatus?<span className="text-success">Active</span>:<span  className="text-danger">Inactive</span>}</td>
                <td >
                <a rel="tooltip" title="Edit " class="table-action edit">
                        <i class="fa fa-edit"></i>
                    </a>
                    {customer.accountstatus? <a href="#" rel="tooltip" title="Deactivate" id="username" class="table-action slash">
                    <i class="fas fa-user-slash"></i>
                    </a>:<a href="#" rel="tooltip" title="Activate" id="username" class="table-action active">
                    <i class="fas fa-user-check"></i>
                    </a>}
                </td>
                </tr>
                 )}
            </tbody>
            </table>  
            </div>
            </div>
            </div>
            </div>    
            </div>
            </div>
            </div>  
    )
}

const mapStateToProps = function(state){
    return{
        loading:state.customer.isLoading,
        customerlist:state.customer.fetchCustomerList
 }
}
const mapDispatchToProps=dispatch =>{
    return{
      fetCustomerList:()=>dispatch(customerListAction())
      }
 }
 
 export default connect(mapStateToProps,mapDispatchToProps)(MonthlyViewall);