import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import Moment from 'moment';
import { Button, CardBody } from "reactstrap";
import classNames from "classnames";
import { Bar } from 'react-chartjs-2';
import { insightWalletDataAction, walletCreditMonthlyDataAction,walletDebitMonthlyDataAction } from "../../../store/actions/insightAction";

const WalletDataChart = (props) => {

    useEffect(() => {
        const today = Moment();
        var monthlycurrent = Moment(today).subtract(6, 'M').format('YYYY-MM-DD');
        var monthlylastDay = Moment(today).format('YYYY-MM-DD');
        // console.log("today",monthlycurrent,monthlylastDay)  
        props.TotalWallet(monthlycurrent, monthlylastDay);
        props.TotalCreditWallet(monthlycurrent, monthlylastDay);
        props.TotalDebitWallet(monthlycurrent, monthlylastDay);
    }, []);

    const [walletChartData1, setwalletChartData1] = useState("data");
    const setWalletData1 = (name) => {
        setwalletChartData1(name);
    };

    let monthpush = []
    const monthlytime = props.totalcreditwallet && props.totalcreditwallet.map(month => month.ID);
    const monthlylabel = monthlytime && monthlytime.map(label => {
        let month = label.number

        if (month === 1) {
            monthpush.push("January")
        } else if (month === 2) {
            monthpush.push("February")
        } else if (month === 3) {
            monthpush.push("March")
        }
        else if (month === 4) {
            monthpush.push("April")
        }
        else if (month === 5) {
            monthpush.push("May")
        }
        else if (month === 6) {
            monthpush.push("June")
        }
        else if (month === 7) {
            monthpush.push("July")
        }
        else if (month === 8) {
            monthpush.push("August")
        }
        else if (month === 9) {
            monthpush.push("September")
        }
        else if (month === 10) {
            monthpush.push("October")
        }
        else if (month === 11) {
            monthpush.push("November")
        }
        else if (month === 12) {
            monthpush.push("December")
        }
    }
    );
    //Remaining wallet logic
    let walletlifetime = []
    const lifetimewallet = props.insighttotalwallet && props.insighttotalwallet.map(au => au.amount)
    for (let i = 0; i < lifetimewallet.length; i++) {
        walletlifetime.push(Math.ceil(lifetimewallet[i]))
    }
    //Wallet credit logic
    let walletCreditlifetime = []
    const lifetimewalletCredit = props.totalcreditwallet && props.totalcreditwallet.map(au => au.amount)
    for (let i = 0; i < lifetimewalletCredit.length; i++) {
        walletCreditlifetime.push(Math.ceil(lifetimewalletCredit[i]))
    }
    //Wallet debit logic
    let walletDebitlifetime = []
    const lifetimewalletDebit = props.totaldebitwallet && props.totaldebitwallet.map(au => au.amount)
    for (let i = 0; i < lifetimewalletDebit.length; i++) {
        walletDebitlifetime.push(Math.ceil(lifetimewalletDebit[i]))
    }
    let chartWalletAmt = {
        data: (canvas) => {
            let ctx = canvas.getContext("2d");
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "#D1077C");
            gradientStroke.addColorStop(0, "#D1077C");
            gradientStroke.addColorStop(0, "#D1077C");
            return {
                labels: monthpush,
                datasets: [
                    {
                        label: "Wallet Credit (In Rupee)",
                        fill: true,
                        backgroundColor: "#1E295D",
                        pointBackgroundColor: "#DCC3D5",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#DCC3D5",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: walletCreditlifetime,

                    },
                ],
            };


        },
        data2: (canvas) => {
            let ctx = canvas.getContext("2d");
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "#D1077C");
            gradientStroke.addColorStop(0.4, "#D1077C");
            gradientStroke.addColorStop(0, "#D1077C");
            return {
                labels: monthpush,
                datasets: [
                    {

                        label: "Wallet Debit (In Rupee)",
                        fill: true,
                        backgroundColor: "#1E295D",
                        pointBackgroundColor: "#013220",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#013220",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: walletDebitlifetime,
                    },
                ],

            };
        },
        data3: (canvas) => {
            let ctx = canvas.getContext("2d");
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "#D1077C");
            gradientStroke.addColorStop(0.4, "#D1077C");
            gradientStroke.addColorStop(0, "#D1077C");
            return {
                labels: monthpush,
                datasets: [
                    {

                        label: "Wallet Balance Remaining (In Rupee)",
                        fill: true,
                        backgroundColor: "#1E295D",
                        pointBackgroundColor: "#013220",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#013220",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: walletlifetime,
                    },
                ],

            };
        },

    };





    // let TotalWallet = {
    //     data: (canvas) => {

    //       return {
    //         labels: monthpush,
    //         datasets: [
    //           {
    //             label: "Wallet Balance(In Rupee)",
    //             backgroundColor: "#1E295D",
    //             data: walletlifetime,
    //           },
    //         ],
    //       };
    //     },

    //   };

    const plugin = {
        beforeInit(chart) {
            const originalFit = chart.legend.fit;
            chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
                this.height += 25;
            }
        }
    }
    return (
        <>
            <div className="sideCardDesigns m-1">
                <CardBody>
                    <span className="charttitle">Wallet</span>
                    <div className="chart-area">
                        <Bar
                            data={chartWalletAmt[walletChartData1]}
                           plugins={plugin}
                                options={{
            
            
                                    tooltips: {
                                        mode: 'index'
                                    },
                                    
                                    animation: {
                                        duration: 0,
                                    },
                                    hover: {
                                        animationDuration: 0,
                                    },
                                   
                                  legend: {
                                    display: true,
                                    labels: {
                                      fontColor: 'black',
                                      fontSize: 15,
                                      padding: 20,
                                      usePointStyle: true,
                                      //width of circles in legend
                                      boxWidth: 9
            
                                    }
                                  },
                                  
                                  scales: {
                                    xAxes: [{
                                      gridLines: {
                                        color: "rgba(0, 0, 0, 0)",
                                      }
                                    }],
                                    yAxes: [{
                                      gridLines: {
                                        color: "rgba(0, 0, 0, 0)",
                                      }
                                    }]
                                  },
                                  tooltips: {
                                    enabled: true
                                },
                                animation: {
                                    duration: 1,
                                    onComplete: function () {
                                        var chartInstance = this.chart,
                                            ctx = chartInstance.ctx;
                                        ctx.textAlign = 'center';
                                        ctx.color = "black";
                                        ctx.fillStyle = "black";
                                        ctx.textBaseline = 'bottom';
                                        this.data.datasets.forEach(function (dataset, i) {
                                            var meta = chartInstance.controller.getDatasetMeta(i);
                                            meta.data.forEach(function (bar, index) {
                                                var data = dataset.data[index];
                                                
                                                if(data > 999 && data < 1000000){
                                                    var rdata= (data/1000).toFixed() +'K';     // convert to K for number from > 1000 < 1 million 
                                                    ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                                                  }else if(data > 1000000){
                                                    var rdata= (data/1000000).toFixed() + 'M';  // convert to M for number from > 1 million 
                                                    ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                                                  }else if(data < 900){
                                                    ctx.fillText(data, bar._model.x, bar._model.y - 5); // if value < 1000, nothing to do
                                                }
                                            });
                                        });
                                    }
                                }
                            }
                            }

                        />
                    </div>
                    <br />
                    <div className="text-center">
                        <Button
                            tag="label"
                            className={classNames("btn btn-primary btn-sm m-2", {
                                active: walletChartData1 === "data",
                            })}

                            onClick={() => setWalletData1("data")}
                        >
                            <span >
                                Wallet Credit
                            </span>
                        </Button>

                       
                        <Button
                            tag="label"
                            className={classNames("btn btn-primary btn-sm m-2", {
                                active: walletChartData1 === "data2",
                            })}
                            onClick={() => { setWalletData1("data2") }}
                        >
                            <span >
                                Wallet Debit
                            </span>
                        </Button>
                      
                        <Button
                            tag="label"
                            className={classNames("btn btn-primary btn-sm m-2", {
                                active: walletChartData1 === "data3",
                            })}
                            onClick={() => { setWalletData1("data3") }}
                        >
                            <span >
                                Wallet Balance
                            </span>
                        </Button>
                    </div>
                </CardBody>
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {

        insighttotalwallet: state.insight.fetchWalletData,
        totalcreditwallet: state.insight.fetchWalletCreditData,
        totaldebitwallet: state.insight.fetchWalletDebitData


    }
}
const mapDispatchToProps = dispatch => {
    return {

        TotalWallet: (current, lastDay) => dispatch(insightWalletDataAction(current, lastDay)),
        TotalCreditWallet: (current, lastDay) => dispatch(walletCreditMonthlyDataAction(current, lastDay)),
        TotalDebitWallet: (current, lastDay) => dispatch(walletDebitMonthlyDataAction(current, lastDay)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WalletDataChart);