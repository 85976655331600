import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSingleRFDIActions } from "../../../../store/actions/customerAction";

class ViewSingleRFIDdetails extends Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getSingleRFID(params.id)

    }
    render() {
        // console.log("details", this.props.rfidsingledetail.firstname);
        return (
            <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading">
                            <b>View RFID</b>
                        </h4>
                        <div className="row rowpadding  justify-content-between mt-5">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="firstname">First Name</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="firstname" name="firstname" className="form-control control-label" placeholder="First Name" value={this.props.rfidsingledetail.firstname} disabled />
                                            <label htmlFor="firstname">First Name</label>
                                        </div>
                                        <label className="formLable" htmlFor="lastname">Last Name</label>
                                        <div className="form-label-group">
                                            <input type="text" id="lastname" name="lastname" className="form-control control-label" placeholder="Last Name" value={this.props.rfidsingledetail.lastname} disabled />
                                            <label htmlFor="lastname">Last Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="mobile">Mobile Number</label>
                                        <div className="form-label-group">
                                            <input type="number" id="mobile" name="mobile" className="form-control control-label" placeholder="Mobile Number" value={this.props.rfidsingledetail.mobile} disabled />
                                            <label htmlFor="mobile">Mobile Number</label>
                                        </div>

                                        <label className="formLable" htmlFor="emailid">Email ID</label>
                                        <div className="form-label-group">
                                            <input type="email" id="emailid" name="emailid" className="form-control control-label" placeholder="Email ID" value={this.props.rfidsingledetail.emailid} disabled />
                                            <label htmlFor="emailid">Email ID</label>
                                        </div>

                                        <label className="formLable" htmlFor="idprof">ID Proof</label>
                                        <div className="form-label-group required">
                                            <select className="form-control border-radius" id="idprof" name="idprof" >
                                                <option>{this.props.rfidsingledetail.idprof}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="idprofnumber">ID Proof Number</label>
                                        <div className="form-label-group required" >
                                            <input type="text" id="idprofnumber" name="idprofnumber" className="form-control control-label" placeholder="ID Proof No" value={this.props.rfidsingledetail.idprofnumber} disabled />
                                            <label htmlFor="idprofnumber">ID Proof Number</label>
                                        </div>

                                        <label className="formLable" htmlFor="rfidno">RFID number</label>
                                        <div className="form-label-group">
                                            <input type="text" id="rfidno" name="rfidno" className="form-control control-label" placeholder="RFID No" value={this.props.rfidsingledetail.rfidno} disabled />
                                            <label htmlFor="rfidno">RFID number</label>
                                        </div>

                                        <label className="formLable" htmlFor="rfidissuedate">RFID Issue Date</label>
                                        <div className="form-label-group">
                                            <input className="form-control" type="date" id="rfidissuedate" name="rfidissuedate" value={this.props.rfidsingledetail.rfidissuedate} disabled />
                                            <label htmlFor="rfidissuedate">RFID Issue Date</label>
                                        </div>

                                        <label className="formLable" htmlFor="taguid">TAG-UID</label>
                                        <div className="form-label-group">
                                            <input type="text" id="taguid" name="taguid" className="form-control control-label" placeholder="TAG-UID(authorization and authentication)" value={this.props.rfidsingledetail.taguid} disabled />
                                            <label htmlFor="taguid">TAG-UID</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="rfidissuedate">KEY-UID</label>
                                        <div className="form-label-group">
                                            <input type="text" id="keyuid" name="keyuid" className="form-control control-label" placeholder="KEY-UID(authorization and authentication)" value={this.props.rfidsingledetail.keyuid} disabled />
                                            <label htmlFor="keyuid">KEY-UID</label>
                                        </div>

                                        <label className="formLable" htmlFor="country">Country</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="country">
                                                <option>{this.props.rfidsingledetail.country}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="state">State</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="state">
                                                <option>{this.props.rfidsingledetail.state}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="city">City</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="city">
                                                <option>{this.props.rfidsingledetail.city}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="status">Status</label>
                                        <div className="form-label-group">
                                            <select className="form-control border-radius" name="status" >
                                                <option>{this.props.rfidsingledetail.status}</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="address">Address</label>
                                        <div className="form-label-group">
                                            <input type="text" id="address" name="address" className="form-control" placeholder="Address" value={this.props.rfidsingledetail.address} disabled />
                                            <label htmlFor="address">Address</label>
                                        </div>


                                        <label className="formLable" htmlFor="topup">Top Up Amount</label>
                                        <div className="form-label-group">
                                            <input type="text" id="topup" name="topup" className="form-control" placeholder="Top Up Amount" value={this.props.rfidsingledetail.topup} disabled />
                                            <label htmlFor="topup">Top Up Amount</label>
                                        </div>



                                        <label className="formLable" htmlFor="address">Used Amount</label>
                                        <div className="form-label-group">
                                            <input type="text" id="usedamt" name="usedamt" className="form-control" placeholder="Used Amount" value={this.props.rfidsingledetail.usedamt} disabled />
                                            <label htmlFor="usedamt">Used Amount</label>
                                        </div>

                                        <label className="formLable" htmlFor="current">Current Balance </label>
                                        <div className="form-label-group">
                                            <input type="text" id="current" name="current" className="form-control" placeholder="Used Amount" value={this.props.rfidsingledetail.current} disabled />
                                            <label htmlFor="current">Current Balance</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/customers">Back</a>
                            </div>

                            {/* <div className="col-sm-12 col-md-6 col-lg-6 float-right">
                            <button className="btn btn-primary btn-pill btn-wide text-uppercase float-right" onClick={handleRfidSubmit}>Add RFID</button>
                        </div> */}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        rfidsingledetail: state.customer.fetchSingleRFID,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleRFID: (id) => dispatch(getSingleRFDIActions(id))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleRFIDdetails));