import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { cityAction, countryAction, privilegeAction, roleAction, stateAction, roleActionByNetworkOperator } from "../../../store/actions/commonAction";
import { getUserDetailsAction, updateAction } from "../../../store/actions/userAction";
import { getSubscriptionPlanByCreatorAction } from "../../../store/actions/subscriptionAction";
import { operatorAction } from "../../../store/actions/opAction";

var arr = [];
var state;
class EditAssignedAccess extends Component {
  state = {
    user: {
      username: '',
      firstname: '',
      lastname: '',
      phone: '',
      role: '',
      privileges: '',
      planname: '',
      organization: '',
      organizationtype: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      createdat: '',
      accountstatus: '',
      ispasswordupdated: '',
      error: '',
    }
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const token = '';
    this.props.getUser(params.userId, token)
    this.props.rolelist(this.state);
    this.props.privilegelist(this.state);
    this.props.countrylist(this.state);
    this.props.statelist(this.state);
    this.props.citylist(this.state);
    this.props.npData("Network Operator");


  }

  //onload call APIs
  componentDidUpdate(previousProps, previousState) {
    if (previousProps.userdetail !== this.props.userdetail) {
      this.setState({
        user: this.props.userdetail,
        privilegeValue: this.props.userdetail.privileges
      })
    }
  }

  //onchange 

  handleCheckChange = (e) => {
    const target = e.target;
    let pvalue = target.value;
    let pid = target.id
    arr = this.state.privilegeValue
    let cd = e.target['name']
    if (cd === "sedan.svg") {
      this.props.fetSubscriptionplan(e.target.id);
    }
    if (target.checked) {
      arr.push({ key: pid, name: pvalue, code: cd })
      // console.log("push", arr)
    } else {
      const removeIndex = arr.findIndex(item => item.name === pvalue);
      // remove object
      arr.splice(removeIndex, 1);
      // console.log("pop", arr)
    }
    this.setState({
      access: arr
    })
  }
  getChecked(item) {
    let status = this.props.userdetail && this.props.userdetail.privileges.filter(pri => pri.name === item).map(st => { return true });
    return status.length != 0 ? true : false;
  }
  getRedioChecked(role) {
    return this.props.userdetail.role === role ? true : false;
  }
  getNPRedioChecked(organization) {
    return this.props.userdetail.organization === organization ? true : false;
  }

  // dropdown onchange
  handleChange = (e) => {
    e.persist();
    this.setState(prevState => ({
      user: { ...prevState.user, [e.target.name]: e.target.value }
    }))
    if (e.target.name === "networkoperator") {
      this.props.rolelist("NP", this.state);
    }
    if (e.target.name === "country") {
      this.props.statelist(e.target.value);
    }
    if (e.target.name === "cstate") {
      this.props.citylist(e.target.value);
    }
    if (e.target.name === "role") {
      this.props.privilegelist(e.target.id);
    }
    if (e.target.name === "sedan.svg") {
      this.props.fetcSubscriptionplan(e.target.id);
    }
    this.setState(state);
  }


  //Update user
  handleSubmit = (e) => {
    e.preventDefault();
    const { match: { params } } = this.props;
    this.props.updateUser(params.userId, this.state.user)

  }

  render() {
    if (this.props.modifiedUser && this.props.modifiedUser.error === '') {
      return <Redirect to='/dashboard/access' />;
    }
    return (
      <div className="container-fluid">
        <div className="sideCardDesigns card-signin my-5">
          <div className="card-body">
            <h4 className="text-center formHeading"><b>Edit User</b></h4>
            <form className="form mt-5" onSubmit={this.handleSubmit}>
              <div className="row rowpadding  justify-content-between">
                <div className="col-md-6 col-sm-12 ">
                  <div className="col-12  formContainer">
                    <div className="col-12">
                      <label className="formLable" htmlFor="username">Email address</label>
                      <div className="form-label-group">
                        <input type="email" className="form-control" name="username" value={this.state.user.username} onChange={this.handleChange} disabled />
                        <label>Email address</label>
                      </div>
                      <label className="formLable" htmlFor="password">Password</label>
                      <div className="form-label-group">
                        <input type="password" className="form-control" name="password" value={this.state.user.password} disabled />
                        <label >Password</label>
                      </div>

                      <label className="formLable" htmlFor="planname">First Name</label>
                      <div className="form-label-group">
                        <input type="text" className="form-control" name="planname" value={this.state.user.firstname} onChange={this.handleChange} />
                        <label>First Name</label>
                      </div>

                      <label className="formLable" htmlFor="lastname">Last Name</label>
                      <div className="form-label-group">
                        <input type="text" className="form-control" name="lastname" value={this.state.user.lastname} onChange={this.handleChange} />
                        <label>Last Name</label>
                      </div>

                      <label className="formLable" htmlFor="organization">Organization Name</label>
                      <div className="form-label-group">
                        <input type="text" name="organization" className="form-control" value={this.state.user.organization} onChange={this.handleChange} />
                        <label>Organization Name</label>
                      </div>

                      <label className="formLable" htmlFor="organizationtype">Organization type</label>
                      <div className="form-label-group">
                        <select className="form-control border-radius" name="organizationtype" value={this.state.user.organizationtype} onChange={this.handleChange}>
                          {this.state.user.organizationtype ? <option>{this.state.user.organizationtype}</option> : ''}
                          <option>Private Ltd Company</option>
                          <option>Public Ltd Company</option>
                          <option>Sole Proprietorship</option>
                          <option>Cooperatives</option>
                          <option>Limited Liability Partnership(LLP)</option>
                        </select>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 ">
                  <div className="col-12  formContainer ">
                    <div className="col-12 ">
                      <label className="formLable" htmlFor="address">Organization Address</label>
                      <div className="form-label-group">
                        <input type="text" name="address" className="form-control" value={this.state.user.address} onChange={this.handleChange} />
                        <label>Organization Address</label>
                      </div>

                      <label className="formLable" htmlFor="phone">Phone Number</label>
                      <div className="form-label-group">
                        <input type="text" name="phone" className="form-control" value={this.state.user.phone} onChange={this.handleChange} />
                        <label>Phone Number</label>
                      </div>

                      <label className="formLable" htmlFor="country">Country</label>
                      <div className="form-label-group">
                        <select className="form-control border-radius" name="country" onChange={this.handleChange} value={this.state.user.country}>
                          {this.state.user.country ? <option>{this.state.user.country}</option> : ''}
                          {this.props.countries && this.props.countries.map((country) => <option key={country.code} value={country.name}>{country.name}</option>)}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="state">State</label>
                      <div className="form-label-group">
                        <select className="form-control border-radius" name="state" onChange={this.handleChange} value={this.state.user.state}>
                          {this.state.user.state ? <option>{this.state.user.state}</option> : ''}
                          {this.props.states && this.props.states.map((st) => <option key={st.code} value={st.name}>{st.name}</option>)}
                        </select>
                      </div>
                      <label className="formLable" htmlFor="city">City</label>
                      <div className="form-label-group">
                        <select className="form-control border-radius" name="city" onChange={this.handleChange} value={this.state.user.city}>
                          {this.state.user.city ? <option>{this.state.user.city}</option> : ''}
                          {this.props.cities && this.props.cities.map((city) => <option key={city.id} value={city.name}>{city.name}</option>)}
                        </select>
                      </div>

                      <label className="formLable" htmlFor="zipcode">Zip Code</label>
                      <div className="form-label-group">
                        <input type="text" name="zipcode" className="form-control" placeholder="Enter the zipcode" value={this.state.user.zipcode} onChange={this.handleChange} />
                        <label >Zip Code</label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>



              <div className="row rowpadding formContainer m-1 mb-4">
                <div className="col-xl-3 col-sm-3 col-12 p-3">
                  <h5 className="font-size-lg text-capitalize font-weight-normal pl-3"> Network Operator </h5>
                  <hr />
                  {this.props.operatorlist && this.props.operatorlist.map((network) => (
                    <div className="custom-control custom-radio pl-5 " key={network.id}>
                      <input type="radio" id={network.id} name="networkoperator" className="custom-control-input" value={network.id} onChange={this.handleChange} defaultChecked={this.getNPRedioChecked(network.organization)} />
                      <label className="custom-control-label" htmlFor={network.id} >
                        {network.organization}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="col-xl-3 col-sm-3 col-12 p-3">
                  <h5>Select Access Role</h5>
                  <hr />
                  {this.props.roles && this.props.roles.slice(0, 7).map((r) =>
                    <div className="custom-control custom-radio pl-5 " key={r.id}>
                      <input type="radio" id={r.key} name="role" className="custom-control-input" value={r.name} onChange={this.handleChange} defaultChecked={this.getRedioChecked(r.name)} />
                      <label className="custom-control-label" htmlFor={r.key}>{r.name}</label>
                    </div>
                  )}
                </div>
                <div className="col-xl-3 col-sm-3 col-12 p-3">
                  <h5>Select Privileges</h5>
                  <hr />
                  {this.props.privileges && this.props.privileges.map((items, index) => {
                    return (
                      <ul key={index}>
                        {Object.values(items.privileges).map((privilege) => {
                          return (
                            <div className="custom-control custom-checkbox pl-5" key={privilege.key}>
                              <input type="checkbox" name={privilege.code} id={privilege.key} className="custom-control-input" value={privilege.name} onChange={this.handleCheckChange} defaultChecked={this.getChecked(privilege.name)} />
                              <label className="custom-control-label" htmlFor={privilege.key}>{privilege.name}</label>

                            </div>
                          )
                        })}
                      </ul>
                    )
                  })}
                </div>

                <div className="col-xl-3 col-sm-3 col-12 p-3">
                  <h5 className="font-size-lg text-capitalize font-weight-normal"> Fleet Users </h5>
                  <hr />
                  {this.props.sublistdata && this.props.sublistdata.slice(1,).map(planname => {
                    return planname !== "Pay as you go" && planname.display === true ?
                      <div className="custom-control custom-radio pl-5" key={planname.id}>
                        <input type="radio" id={planname.name} name="planname" className="custom-control-input" value={planname.name} onChange={this.handleChange} />
                        <label className="custom-control-label" htmlFor={planname.name}>
                          {planname.name}
                        </label>
                      </div> : ''
                  })}


                </div>
              </div>




              <div className="row">
                <div className="col-xl-6 col-sm-6 col-12">
                  <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/access">Back</a>
                </div>

                <div className="col-xl-6 col-sm-6 col-12 float-right">
                  <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">Update User Access</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = function (state) {
  return {
    userdetail: state.users.fetchUserDetail,
    roles: state.common.roleList,
    privileges: state.common.privilegeList,
    countries: state.common.countryList,
    states: state.common.stateList,
    cities: state.common.cityList,
    modifiedUser: state.users.updateUser,
    operatorlist: state.operator.fetchOperator,
    sublistdata: state.subscriptionplan.fetcSubscriptionplan,

  }
}
const mapDispatchToProps = dispatch => {
  return {
    getUser: (id, token) => dispatch(getUserDetailsAction(id, token)),
    rolelist: (role) => dispatch(roleAction(role)),
    privilegelist: (privilege) => dispatch(privilegeAction(privilege)),
    countrylist: () => dispatch(countryAction()),
    statelist: () => dispatch(stateAction()),
    citylist: () => dispatch(cityAction()),
    updateUser: (id, user) => dispatch(updateAction(id, user)),
    fetSubscriptionplan: () => dispatch(getSubscriptionPlanByCreatorAction("All")),
    npData: (nrole) => dispatch(operatorAction(nrole)),


  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditAssignedAccess));
