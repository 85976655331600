import React, { Component } from "react"
import { connect } from "react-redux"
import { deactivateAction, getUsersAction } from "../../../store/actions/userAction"

class UserList extends Component {

    state = {
        token: '',
        username: '',
        show: ''
    }
    componentDidMount() {
        this.props.getUserList(this.state)
    }

    activateDeactivateUser = (user, userstatus) => {
        this.props.deactivateUser(user, userstatus);
        this.props.getUserList(this.state);

    }

    render() {
        const roles = this.props.userlist && this.props.userlist.map(e => e.role)

        return (
            <>
                <div className="mb-3 sideCardDesigns">
                    <div className="">
                        <span className=" pl-2" style={{ fontSize: "18px", color: "#10204B", fontWeight: "550" }}>User Details</span>
                        {/* <div className="btn-actions-pane-right">
                            <button type="button" className="btn btn-light btn-pill btn-wide btn-sm">View All<span className="pl-2 align-middle opacity-7"></span></button>
                        </div> */}
                    </div>

                    <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">User ID</th>
                                    <th scope="col" >Name</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Organization</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody id="userlist">
                                {

                                    this.props.userlist && this.props.userlist.map((user) =>

                                        <tr key={user.id}>
                                            <td>{user.username}</td>
                                            <td>{user.firstname} {user.lastname}</td>
                                            <td>{user.role}</td>
                                            <td>{user.organization}</td>
                                            <td>{user.phone}</td>
                                            <td>{user.city}</td>
                                            <td>{(new Date(user.createdat)).toLocaleDateString()}</td>
                                            <td>{user.accountstatus ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>}</td>
                                            <td>
                                                <a rel="tooltip" title="View " className="table-action view" href={`/dashboard/users/viewuser/${user.id}`}>
                                                    <i className="fas fa-eye"></i>
                                                </a>
                                                <a rel="tooltip" title="Edit " className="table-action edit" href={`/dashboard/users/edituser/${user.id}`}>
                                                    <i className="fa fa-edit"></i>
                                                </a>
                                                {user.accountstatus ? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash" onClick={() => this.activateDeactivateUser(user, false)}>
                                                    <i className="fas fa-user-slash"></i>
                                                </a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active" onClick={() => this.activateDeactivateUser(user, true)}>
                                                    <i className="fas fa-user-check"></i>
                                                </a>}
                                            </td>
                                        </tr>
                                    )}
                            </tbody>

                        </table>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = function (state) {
    return {
        userlist: state.users.fetchList,
        response: state.users.deactivateUser
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUserList: (user) => dispatch(getUsersAction(user)),
        deactivateUser: (user, userstatus) => dispatch(deactivateAction(user, userstatus))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
