import React, { useEffect , useState } from "react";
import { connect } from "react-redux";
import { getStationByCreatorAction } from "../../../store/actions/stationAction";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import Pagination from "../../Pagination";
import 'jspdf-autotable';

const StationViewAll=(props)=>{
  const [ searchTerm, setSearchTerm] = useState("")
  useEffect(()=>{
   props.StationData("All")
  },[]);
  const [ transactiondata, setTransactionData  ] = useState([ props.transactions ])
  const [ showPerPage, setShowPerPage ] = useState(10)
  const [ pagination, setPagination ] = useState({
    start:0,
    end:showPerPage,
  });
  const onPaginationChange = (start,end) => {
    setPagination({start:start, end: end});

  }
  
  const generatePDF = (event) =>{
    var doc = new jsPDF('l', 'pt', 'a4');
    doc.text(50, 40, "All Transaction");
    var res = doc.autoTableHtmlToJson(document.getElementById("All_Station_details"));
    doc.autoTable(res.columns, res.data, {
      startY: 60,
      theme: 'grid',
    });
    doc.save("All_Station_details.pdf");
  }
      return(
        <>
        <div className="row">
        <div className="col">
        <div className="p-3 bg-light">
        <div className="row">
        <div className="col-md-12 col-lg-12">   
        <div className="mb-3 h-100 card">
        <div className="table-responsive">
        <div className="table-wrapper">	
        <div className="table-title">
        <div className="row ">
        <div className="col-auto mr-auto">
        <div className="cssearch-box ">   
        <input type="text" className="form-control" placeholder="Search&hellip;" onChange={(event) => {
        setSearchTerm(event.target.value);
         }}
    />
     </div>			
     </div>
     <div className="col-auto">
    <div  className="float-center">
    <div className="btn-group dropleft float-right">
  <button className="btn btn-primary btn-sm " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  {<i className="fa fa-download" aria-hidden="true" title="Download" ></i>}
  </button>
  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a className="dropdown-item" href="">
    <ReactHTMLTableToExcel
         id="test-table-xls-button"
        table="All_Station_details"
        filename="All_Station_details"
        className="btn btn-default"
        sheet="tablexls"
        buttonText="Download as XLS">
        </ReactHTMLTableToExcel>
    </a>
    <a className="dropdown-item" href="" onClick ={generatePDF}>
  <center>Download as PDF</center>
    </a>
    </div>
          </div>
          </div>
          </div>
          </div>
     
    <table className="table table-bordered table-hover table-sm" id="All_Station_details" > 
    <thead className="thead-light">
        <tr>
            <th scope="col">Station Id</th>
            <th scope="col">Charging Station Name</th>
            <th scope="col">Visibility</th>
            <th scope="col">Commisioned Date</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
            </tr>
         </thead >   
         <tbody>     
         {props.stations && props.stations.filter((station) =>{
                   if (searchTerm === ""){
                    return station
                  } else if (station.stationid.toLowerCase().includes(searchTerm.toLowerCase())){
                    return station
                  } else if (station.name.toLowerCase().includes(searchTerm.toLowerCase())){
                    return station
                  }
                 }).slice( pagination.start, pagination.end ).map((station) =>
                  <tr key={station.id}>
                <th scope="col">{station.stationid}</th>
                <td>{station.name}</td>
                <td>{station.visibility}</td>
                <td>{station.commisiondate}</td>
                <td>{station.status}</td>
                <td>
                <a rel="tooltip" title="Edit " className="table-action view">
                        <i className="fa fa-edit"></i>
                 </a>
                <a href={`/dashboard/station/updatestation/${station.stationid}/${station.location}`} rel="tooltip" title="Update " className="table-action edit">
                <i className="fas fa-wrench"></i>
                 </a>
                 <a href={`/dashboard/stations/addstationgroup/${station.stationid}/${station.location}`} rel="tooltip" title="Add Price Group " className="table-action Price Group">
                 <i className="far fa-object-group"></i>
                 </a>
                 <a rel="tooltip" title="View " className="table-action view">
                        <i className="fa fa-eye"></i>
                 </a>
                   
                </td>
                </tr>
                 )}
                 
                
            </tbody>
     </table>  
     <Pagination showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={transactiondata.length}/>
     </div>
        </div>   
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
  </>
)
}

const mapStateToProps = function(state){
  return{
    stations:state.station.fetchStation,
    loading:state.station.isLoading,
 }
}
const mapDispatchToProps=dispatch =>{
  return{
    StationData:(viewtype)=>dispatch(getStationByCreatorAction(viewtype)),
  
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(StationViewAll);