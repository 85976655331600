import React, { useEffect, useState, useRef } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Loader from 'react-loader-spinner';
import { Checkbox } from "../report/Checkbox";
import GlobalFilter from "../report/GlobalFilter"
import moment from "moment";

const gridStyle = {
  minHeight: 500, marginTop: 10
}
const SEPARATOR = ',';

const csvColumnsNeededToBeRemoved = ['driverinimage','vechileinimage','vechileoutimage','driveroutimage', ]

export default function ReactDataTable({ columns, data, parentName, filter, enableCSV = true, enableSearch = true, enableColumnFilter = true, onRowClick }) {
  const [isLoading, setLoading] = useState(true)
  const [tableColumns, setTableColumns] = useState(columns)
  const [tableColumnsWithoutChange, setTableColumnsWithoutChange] = useState(columns)
  const [visibleColumns, setSetVisibleColumns] = useState({ "all": true })
  const [searchText, setSearchText] = useState('');
  const [dataSource, setDataSource] = useState(data);
  const [gridRef, setGridRef] = useState(null);
  const searchTextRef = useRef(searchText);
  searchTextRef.current = searchText;

  const onSearchChange = (value) => {
    const visibleColumns = gridRef.current.visibleColumns;

    const lowerSearchText = value && value.toLowerCase();
    const newData = data.filter((p) => {
      return visibleColumns.reduce((acc, col) => {
        const v = (p[col.id] + '').toLowerCase(); // get string value
        return acc || v.indexOf(lowerSearchText) != -1; // make the search case insensitive
      }, false);
    });

    setSearchText(value);
    setDataSource(newData);
  };

  useEffect(() => {
    let tempColumns = []
    let tempVisibleColumns = {}
    columns.forEach((column) => {
      tempColumns.push({ ...column, isVisible: true, headerProps: { style: { color: '#ffffff', backgroundColor: "#10204b", fontWeight: 'bold', textAlign: 'center', width: 100 } } })
      tempVisibleColumns[column.name] = true
    })
    setTableColumns(tempColumns)
    setTableColumnsWithoutChange(tempColumns)
    setSetVisibleColumns({ ...visibleColumns, ...tempVisibleColumns })
    setLoading(false)
  }, [])

  const downloadBlob = (blob) => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', parentName);
    link.style.position = 'absolute';
    link.style.visibility = 'hidden';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const rowStyle = () => {
    return {
      backgroundColor: "#ffffff",
      color: '#10204b',
      textAlign: 'center'
    }
  }

  const changeCSVDate = (key, data, parentName) => {
    if (parentName === "hub_operation") {
      if (key === "indate") {
        var date = moment(data["createdat"])
        return `"${date.format("DD-MM-YYYY")}"`
      } else if (key === "intime") {
        var date = moment(data["createdat"])
        return `"${date.format("HH:mm:ss")}"`
      } else if (key === "outdate") {
        var date = moment(data["modifiedat"])
        return `"${date.format("HH:mm:ss")}"`
      } else if (key === "outtime") {
        var date = moment(data["modifiedat"])
        return `"${date.format("HH:mm:ss")}"`
      }
    }
    return `"${data[key]}"`
  }

  const miscellaneousData=(parentName, key, data)=>{
    if (parentName === "hub_operation") {
      if(key === "totalTime"){
        if (!data.outdate || !data.modifiedat || !data.createdat) {
          return "";
        } else {
          const inTime = moment(data.createdat);
          const outTime = moment(data.modifiedat);
          const duration = moment.duration(outTime.diff(inTime));
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();
          return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
      }
      return data.ivs_checkList.checkIn[key] !== undefined && data.ivs_checkList.checkIn[key] !== null ? ((data.ivs_checkList.checkIn[key] ? 'Yes' : 'No')+"/"+(data.ivs_checkList.checkOut[key] ? 'Yes' : 'No')) : ""
    }
    return ""
  }

  const exportCSV = () => {
    var columns = gridRef.current.visibleColumns;
    columns = columns.filter((c)=>(!csvColumnsNeededToBeRemoved.includes(c.id)))

    const header = columns.map((c) => c.header).join(SEPARATOR);
    const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id] !== undefined ? changeCSVDate(c.id, data) : miscellaneousData(parentName, c.id, data)).join(SEPARATOR));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);
  };

  const exportAllCSV = () => {
    var columns = gridRef.current.visibleColumns;
    columns = columns.filter((c) => (!csvColumnsNeededToBeRemoved.includes(c.id)))
  
    const header = columns.map((c) => `"${c.header}"`).join(SEPARATOR);
  
    const rows = dataSource.map((data) =>
      columns.map((c) =>
        data[c.id] !== undefined ? changeCSVDate(c.id, data) : miscellaneousData(parentName, c.id, data)
      ).join(SEPARATOR)
    );
  
    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
  
    downloadBlob(blob, `All_${parentName}.csv`);
  };

  const callOnRowClick=(r, e)=>{
    // console.log("12313 Asdasdasd", r, e)
    if(onRowClick !== undefined){
      onRowClick(r, e)
    }

  }

  const handleCheckChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked
    let tempVisibleColumns = visibleColumns
    if (value === "all") {
      tableColumnsWithoutChange.forEach((column) => {
        tempVisibleColumns[column.name] = isChecked
      })
    } else if (!isChecked) {
      tempVisibleColumns["all"] = isChecked
    }
    tempVisibleColumns[value] = isChecked
    let tempTableColumns = tableColumnsWithoutChange.filter(column => tempVisibleColumns[column.name] === true)
    setTableColumns(tempTableColumns)
    setSetVisibleColumns(tempVisibleColumns)
  }
  return (
    isLoading ?
      (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} > Loading < h3 className="loader" > <Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3 ></div >)
      : <div className="container-fluid ">
        <div className="row ">
          <div className="col-md-12">
            <div className="row">
              {enableColumnFilter?<div className="col-md-3 ">
                <a
                  className="btn columnsBtn p-2"
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{ color: "#10204B" }}
                >
                  Select Columns &nbsp;<i className="fas fa-caret-down"></i>
                </a>
              </div>:""}
              <div className="col-md-9 pt-3 ">
                <div className="row float-right ">

                  {enableSearch?<div className="col-md-6 ">
                    <div className="row float-right">
                      <div className="col-md-12"
                      style={{
                        marginRight:20
                      }}
                      >
                        <GlobalFilter
                          filter={searchText}
                          setFilter={onSearchChange}
                        />{' '}
                      </div>
                    </div>
                  </div>:""}

                  {enableCSV ? <div className="col-md-6">
                    <div className="row float-right">
                      <div className="col-md-12"
                       style={{
                        display:"inline-flex",
                        // marginLeft:50,
                        // backgroundColor:"red",
                        justifyContent:"space-between"
                      }}

                      
                      >
                        <div className="page-title-actions"
                        
                          style={{
                            display:"inline-flex"
                          }}
                        
                        
                        >
                          <a onClick={exportCSV} className="btn btn-primary-csv-download " style={{ backgroundColor: "#d7def2 !important", fontSize: "13px", color: "#10204B", textDecoration: "none", width: 100, display:"inline-flex",paddingRight:10 }} ><img className="pr-1" src={process.env.PUBLIC_URL + '/images/Insight_img/tableDownload.svg'} style={{ height: "15px" }} ></img>Download</a>
                        </div>
                        {parentName === "hub_operation" && (
            <a
              onClick={exportAllCSV}
              className="btn btn-primary-csv-download"
              style={{ backgroundColor: "#d7def2 !important", 
                            fontSize: "13px", 
                            color: "#10204B",
                             textDecoration: "none", 
                             width: "120px", marginLeft:10, }}
            >
              <img
                src={process.env.PUBLIC_URL + '/images/Insight_img/tableDownload.svg'}
                style={{ height: "15px" }}
              ></img>
              Download All
            </a>
          )}
                      </div>
                    </div>
                  </div> :""}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <div className="collapse" id="collapseExample" style={{ width: 'auto', height: 'auto'}}>
              <div className="float-left">
                <span className="pr-3">
                  <Checkbox value="all" onChange={handleCheckChange} checked={visibleColumns["all"]} /> Toggle all
                </span>
                {tableColumnsWithoutChange.map((column) => (
                  <span key={column.id} className="pr-3">
                    <label>
                      <input
                        type="checkbox"
                        checked={visibleColumns[column.name]}
                        value={column.name} onChange={handleCheckChange}
                      />
                      {column.header}
                    </label>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div id="tabeller" className="table-responsive">
          <ReactDataGrid
            id={parentName}
            handle={setGridRef}
            style={gridStyle}
            columns={tableColumns}
            rowStyle={rowStyle}
            pagination
            columnUserSelect={true}
            showColumnMenuTool={false}
            pageSizes={[10, 50, 100, 200, 1000, 5000, 10000, 20000]}
            dataSource={dataSource}
            defaultFilterValue={filter}
            enableColumnFilterContextMenu= {false}
            onRowClick={(r, e)=>callOnRowClick(r, e)}
          />
        </div>
      </div>
  );
}
