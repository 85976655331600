import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux";
import GoogleMapReact from 'google-map-react';
import GoogleMapStyles from "./GoogleMapStyles"
import classNames from "classnames";
import { Button, CardBody } from "reactstrap";
import Moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { ExportToExcel } from './cityDataDownload';
import { StateExportToExcel } from './stateDataDownload';
import axios from "axios";
import PureBreadcrumbs from "../Breadcrums";
import { getLocationAction } from "../../../store/actions/locationAction";
import { getChargerByCreatorAction } from "../../../store/actions/stationAction";
import { customerListAction } from "../../../store/actions/customerAction";
import { ReportListAction } from "../../../store/actions/chargesessionAction";
import { transactionDataAction } from "../../../store/actions/transactionAction";
import { insightDailyDataAction, insightWeeklyDataAction, insightMonthlyDataAction, insightTotalDataAction, insightCustomerDataAction, insightWalletDataAction, insightCityDataAction, insightStateDataAction } from "../../../store/actions/insightAction";
import { REPORT_BASE_URL, FETCH_CITY_CHART_URL, FETCH_STATE_CHART_URL } from "../../config/configs";
import ConnectorDataChart from "./connector_loctype_Chart";
import WalletDataChart from "./walletChart";
import CustomerDataChart from "./customerData";
import "./insight.css";
import "./Marker.css";


const Marker = (props) => {
  const { name,address, id } = props;
  const { username, role } = JSON.parse(localStorage.getItem('user'))
  return (
    <div>
      {username == "networkoperator@tsredco.gov.in" ? <div className="pin-tsr bounce" style={{ cursor: 'pointer' }} title={name +'\n' +address}/>  : <div className="pin bounce" style={{ cursor: 'pointer' }} title={name +'\n' +address} />}
      <div className="pulse" /> 
    </div>
  );
};
const Insight = (props) => {
  const { role } = JSON.parse(localStorage.getItem('user'))
  const [center, setCenter] = useState({ lat: 20.5937, lng: 78.9629 });
  const [zoom, setZoom] = useState(4.8);
  const [timer, setTimer] = useState(180);
  const id = useRef(null);
  const year = new Date().getFullYear();
  const tyear = [year]

  //chart city data download
  const [data, setData] = React.useState([])
  const fileName = "CityData";
  React.useEffect(() => {
    const fetchData = () => {
      const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
      var bearer = 'basic ' + token;
      axios.get(
        REPORT_BASE_URL + FETCH_CITY_CHART_URL + "?userid=" + id + "&role=" + role + "&np=" + networkoperator,
        {
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      ).then(r => setData(r.data))
    }
    fetchData()
  }, [])

  //State Data chart download
  const [Statedata, setStateData] = React.useState([])
  const StatefileName = "StateData";
  React.useEffect(() => {
    const fetchData = () => {
      const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
      var bearer = 'basic ' + token;
      //console.log(JSON.parse(localStorage.getItem('user')));
      axios.get(
        REPORT_BASE_URL + FETCH_STATE_CHART_URL + "?userid=" + id + "&role=" + role + "&np=" + networkoperator,
        {
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      ).then(r => setStateData(r.data))
    }
    fetchData()
  }, [])

  // const [Dailydata, setDailydata]= React.useState([])
  // const DailyfileName = "Dailyreport";
  // React.useEffect(() => {
  //   const fetchData = () => {
  //     const { token, id, role, networkoperator } = JSON.parse(localStorage.getItem('user'))
  //     var bearer = 'basic ' + token;
  //     //console.log(JSON.parse(localStorage.getItem('user')));
  //     axios.get(
  //       REPORT_BASE_URL + FETCH_DAILY_CHART_URL + "?userid=" + id + "&role=" + role + "&np=" + networkoperator,
  //       {
  //         headers: {
  //           'Authorization': bearer,
  //           'Content-Type': 'application/json',
  //           Accept: 'application/json',
  //         },
  //       }
  //     ).then(r => setDailydata(r.data))
  //   }
  //   fetchData()
  // }, [])


  // const [bigChartData, setbigChartData] = useState("data");
  // const setBgChartData = (name) => {
  //   setbigChartData(name);
  // }
  const [bigChartData2, setbigChartData2] = useState("data3");
  const setBgChartData2 = (name) => {
    setbigChartData2(name);
  };
  const clear = () => {
    // console.log("IN CLEAR");
    window.location.reload(false);
  }

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1)
    }, 1000)
    return () => clear();
  }, [])

  useEffect(() => {
    if (timer === 0) {
      // console.log("CALL API.....");
      props.getCharger();
      props.TotalData();
      window.location.reload(false);
      setTimer(180)
    }
  }, [timer])
  const handleAddClick = () => {
    window.location.reload(false);
    props.getCharger();
  };
  const available = "Available";
  const busy = "Busy";
  const maintenance = "Maintenance";
  const comingsoon = "Coming soon"

  useEffect(() => {
    props.getLocation();
    props.getCharger();
    props.fetCustomerList()
    props.fetchTransactionData("All", "All")

    const today = Moment();
    const current = today.add(0, 'days').format('YYYY-MM-DD')
    const lastDay = today.add(1, 'days').format('YYYY-MM-DD');
    const weeklycurrent = today.startOf('week').format('YYYY-MM-DD')
    const weeklylastDay = today.endOf('week').format('YYYY-MM-DD');
    const monthlycurrent = today.subtract(6, 'month').format('YYYY-MM-DD');
    const monthlylastDay = today.add(8, 'month').format('YYYY-MM-DD');
    // console.log("Monthlycurrent", monthlycurrent, monthlylastDay);
    props.DailyData(current, lastDay);
    props.WeeklyData(weeklycurrent, weeklylastDay);
    props.MonthlyData(monthlycurrent, monthlylastDay);
    props.TotalData();
    props.TotalCustomer(monthlycurrent, monthlylastDay);
    props.TotalWallet(monthlycurrent, monthlylastDay);
    props.CityChartData();
    props.StateChartData();
    props.fetReport();
  }, [])

  // var currentWeekNumber = require('current-week-number');
  let timepush = []
  let weekpush = []
  let monthpush = []

  // To correct month sequence on graph we are rotating the array by 2 
  // const rotate = (arr, count = 1) => {
  //   return [...arr.slice(count, arr.length), ...arr.slice(0, count)];
  // };

  const dailyenergy = props.insightdaily && props.insightdaily.map(en => en.energy);
  let dailyunit = []
  for (let i = 0; i < dailyenergy.length; i++) {
    dailyunit.push(Math.ceil(dailyenergy[i]))
  }
  const weeklyenergy = props.insightweekly && props.insightweekly.map(wenr => wenr.energy);
  let weeklyunit = []
  for (let i = 0; i < weeklyenergy.length; i++) {
    weeklyunit.push(Math.ceil(weeklyenergy[i]))
  }

  const monthlyenergy = props.insightmonthly && props.insightmonthly.map(menr => menr.energy);
  let monthlyunit = []
  for (let i = 0; i < monthlyenergy.length; i++) {
    monthlyunit.push(Math.ceil(monthlyenergy[i]))
  }

  // var monthconsumption = rotate(monthlyunit,   2)

  const lifetimetotalrevenue = parseFloat(props.insighttotal.lifetimeamount + props.insighttotal.totalgst !== 0 ? props.insighttotal.lifetimeamount + props.insighttotal.totalgst : 0).toFixed(0)

  const lifetimetotalunit = parseFloat(props.insighttotal.lifetimeusage !== 0 ? props.insighttotal.lifetimeusage : 0).toFixed(0)
  // const totalenergy = props.insighttotal !== null ? props.insighttotal.lifetimeusage : 0

  const dailyamount = props.insightdaily && props.insightdaily.map(amt => amt.amount + amt.gst);

  let dailyrevenue = []
  for (let i = 0; i < dailyamount.length; i++) {
    dailyrevenue.push(Math.ceil(dailyamount[i]))
  }
  const weeklyamount = props.insightweekly && props.insightweekly.map(wamt => wamt.amount + wamt.gst);
  let weeklyrevenue = []
  for (let i = 0; i < weeklyamount.length; i++) {
    weeklyrevenue.push(Math.ceil(weeklyamount[i]))
  }
  const monthlyamount = props.insightmonthly && props.insightmonthly.map(mamt => mamt.amount + mamt.gst);
  let monthlyrevenue = []
  for (let i = 0; i < monthlyamount.length; i++) {
    monthlyrevenue.push(Math.ceil(monthlyamount[i]))
  }


  // var monthrevenue = rotate(monthlyrevenue,   2)

  // const totalamount = props.insighttotal !== null ? props.insighttotal.lifetimeamount : 0

  const totalcustomer = props.insightcustomer && props.insightcustomer.map(cu => cu.total);

  const lifetimewallet = props.insighttotalwallet && props.insighttotalwallet.map(au => au.amount)
  let walletlifetime = []
  for (let i = 0; i < lifetimewallet.length; i++) {
    walletlifetime.push(Math.ceil(lifetimewallet[i]))
  }

  const dailytime = props.insightdaily && props.insightdaily.map(time => time.ID);
  const weeklytime = props.insightweekly && props.insightweekly.map(week => week.ID);
  const monthlytime = props.insightmonthly && props.insightmonthly.map(month => month.ID);


  // const arr = monthlytime;
  // var monthaxis = rotate(arr,   2)
  // console.log("monthlyroatet",monthaxis);


  //for pie chart
  // const availablestatus = props.chargers.TotalAvailableCharger !== 0 ? props.chargers.TotalAvailableCharger : 0
  // const busyStatus = props.chargers.TotalBusyCharger !== 0 ? props.chargers.TotalBusyCharger : 0
  // const maintenanceStatus = props.chargers.TotalMaintenanceCharger !== 0 ? props.chargers.TotalMaintenanceCharger : 0
  // const comingsoonStatus = props.chargers.TotalComingSoon !== 0 ? props.chargers.TotalComingSoon : 0

  //For city and state data
  const citytotalamount = props.insightcitydata && props.insightcitydata.map(ctamt => ctamt.amount);
  let cityrevenue = []
  for (let i = 0; i < citytotalamount.length; i++) {
    cityrevenue.push(Math.ceil(citytotalamount[i]))
  }
  const citytotalenergy = props.insightcitydata && props.insightcitydata.map(cteng => cteng.energy);
  let cityunit = []
  for (let i = 0; i < citytotalenergy.length; i++) {
    cityunit.push(Math.ceil(citytotalenergy[i]))
  }
  const statetotalamount = props.insightstatedata && props.insightstatedata.map(stamt => stamt.amount);
  let staterevenue = []
  for (let i = 0; i < statetotalamount.length; i++) {
    staterevenue.push(Math.ceil(statetotalamount[i]))
  }
  const statetotalenergy = props.insightstatedata && props.insightstatedata.map(steng => steng.energy);
  let sstateunit = []
  for (let i = 0; i < statetotalenergy.length; i++) {
    sstateunit.push(Math.ceil(statetotalenergy[i]))
  }
  const citylist = props.insightcitydata && props.insightcitydata.map(ctlist => ctlist.id);
  const statelist = props.insightstatedata && props.insightstatedata.map(stlist => stlist.id);

  // var weekno = currentWeekNumber()

  const cleanKMs = parseFloat(((props.list.totalkwh) * 1000) / 130).toFixed(0)
  const fuelreplaced = parseFloat(cleanKMs / 15).toFixed(0)
  const co2 = parseFloat(fuelreplaced * 2.7).toFixed(0)

  function gettotalSum(a) {
    var total = 0;
    for (var i in a) {
      total += a[i];
    }
    return total;
  }

  //totAL calculation for chart
  let calcdailytotalamount = [];
  let calcdailytotalenergy = [];
  let calcweeklytotalamount = [];
  let calcweeklytotalenergy = [];
  let calcmonthlytotalamount = [];
  let calcmonthlytotalenergy = [];

  for (let i = 0; i < dailyenergy.length; i++) {
    if (dailyenergy[i] >= 0) {
      calcdailytotalenergy.push(dailyenergy[i]);
    }
  }
  for (let i = 0; i < dailyamount.length; i++) {
    if (dailyamount[i] >= 0) {
      calcdailytotalamount.push(dailyamount[i]);
    }
  }
  for (let i = 0; i < weeklyamount.length; i++) {
    if (weeklyamount[i] >= 0) {
      calcweeklytotalamount.push(weeklyamount[i]);
    }
  }
  for (let i = 0; i < weeklyenergy.length; i++) {
    if (weeklyenergy[i] >= 0) {
      calcweeklytotalenergy.push(weeklyenergy[i]);
    }
  }
  for (let i = 0; i < monthlyenergy.length; i++) {
    if (monthlyenergy[i] >= 0) {
      calcmonthlytotalenergy.push(monthlyenergy[i]);
    }
  }
  for (let i = 0; i < monthlyamount.length; i++) {
    if (monthlyamount[i] >= 0) {
      calcmonthlytotalamount.push(monthlyamount[i]);
    }
  }
  let totaldailyenergy = parseFloat(gettotalSum(calcdailytotalenergy)).toFixed(0);
  let totaldailyamount = parseFloat(gettotalSum(calcdailytotalamount)).toFixed(0);
  let totalweeklyamount = parseFloat(gettotalSum(calcweeklytotalamount)).toFixed(0);
  let totalweeklyenergy = parseFloat(gettotalSum(calcweeklytotalenergy)).toFixed(0);
  let totalmonthlyenergy = parseFloat(gettotalSum(calcmonthlytotalenergy)).toFixed(0);
  let totalmonthlyamount = parseFloat(gettotalSum(calcmonthlytotalamount)).toFixed(0);

  //Top 5 City amount Calculations for chart
  const cityData = (props.insightcitydata && props.insightcitydata)
  const cityDataAmountArray = [...cityData];
  cityDataAmountArray.sort(function (x, y) {
    return y.amount - x.amount;
  });
  const top5cityAmount = cityDataAmountArray.sort((a, b) => b - a).slice(0, 5);
  const amounttop5 = top5cityAmount.map(ami => ami.amount + ami.gst)
  let amounttop5arry = []
  for (let i = 0; i < amounttop5.length; i++) {
    amounttop5arry.push(Math.ceil(amounttop5[i]))
  }
  const amounttop5id = top5cityAmount.map(ame => ame.id)

  //Top 5 City consumption Calculations for chart
  const cityDataUnitArray = [...cityData];
  cityDataUnitArray.sort(function (x, y) {
    return y.energy - x.energy;
  });
  const top5cityUnit = cityDataUnitArray.sort((a, b) => b - a).slice(0, 5);
  const cityunitttop5 = top5cityUnit.map(ami => ami.energy)
  let cityunitttop5arry = []
  for (let i = 0; i < cityunitttop5.length; i++) {
    cityunitttop5arry.push(Math.ceil(cityunitttop5[i]))
  }
  const cityunittop5id = top5cityUnit.map(ame => ame.id)

  //Top 5 State amount Calculations for chart
  const stateData = (props.insightstatedata && props.insightstatedata)
  const StateDataAmountArray = [...stateData];
  StateDataAmountArray.sort(function (x, y) {
    return y.amount - x.amount;
  });
  const top5StateAmount = StateDataAmountArray.sort((a, b) => b - a).slice(0, 5);
  const Stateamounttop5 = top5StateAmount.map(ami => ami.amount + ami.gst)
  let Stateamounttoparry = []
  for (let i = 0; i < Stateamounttop5.length; i++) {
    Stateamounttoparry.push(Math.ceil(Stateamounttop5[i]))
  }
  const Stateamounttop5id = top5StateAmount.map(ame => ame.id)

  //Top 5 State unit Calculations for chart
  const StateDataEnergyArray = [...stateData];
  StateDataEnergyArray.sort(function (x, y) {
    return y.energy - x.energy;
  });
  const top5StateEnergy = StateDataEnergyArray.sort((a, b) => b - a).slice(0, 5);
  const StateEnergytop5 = top5StateEnergy.map(ami => ami.energy)
  let StateEnergytop5arry = []
  for (let i = 0; i < StateEnergytop5.length; i++) {
    StateEnergytop5arry.push(Math.ceil(StateEnergytop5[i]))
  }
  const StateEnergyttop5id = top5StateEnergy.map(ame => ame.id)


  const timelabels = dailytime && dailytime.map(label => {
    let time = label.number + 5.30
    if (time < 12) {
      timepush.push(time + "0 AM")
    } else {
      timepush.push(time + "0 PM")
    }
  });
  const weeklylabel = weeklytime && weeklytime.map(label => {
    let week = label.number
    if (week === 1) {
      weekpush.push("Sunday")
    } else if (week === 2) {
      weekpush.push("Monday")
    } else if (week === 3) {
      weekpush.push("Tuesday")
    }
    else if (week === 4) {
      weekpush.push("Wednesday")
    }
    else if (week === 5) {
      weekpush.push("Thursday")
    }
    else if (week === 6) {
      weekpush.push("Friday")
    }
    else if (week === 7) {
      weekpush.push("Saturday")
    }
  });
  const monthlylabel = monthlytime && monthlytime.map(label => {
    let month = label.number

    if (month === 12) {
      monthpush.push("December")
    } else if (month === 11) {
      monthpush.push("November")
    } else if (month === 10) {
      monthpush.push("October")
    }
    else if (month === 9) {
      monthpush.push("September")
    }
    else if (month === 8) {
      monthpush.push("August")
    }
    else if (month === 7) {
      monthpush.push("July")
    }
    else if (month === 6) {
      monthpush.push("June")
    }
    else if (month === 5) {
      monthpush.push("May")
    }
    else if (month === 4) {
      monthpush.push("April")
    }
    else if (month === 3) {
      monthpush.push("March")
    }
    else if (month === 2) {
      monthpush.push("February")
    }
    else if (month === 1) {
      monthpush.push("January")
    }
  }
  );



  let chartDailyAmt = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");
      return {
        labels: timepush,
        datasets: [
          {

            label: "Daily Revenue - ₹ " + totaldailyamount,
            fill: true,
            backgroundColor: "#1E295D",
            pointBackgroundColor: "#013220",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#013220",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dailyrevenue,
          },
          {
            label: "Daily Units Consumed - " + totaldailyenergy + " kWh",
            fill: true,
            backgroundColor: "#D1108C",
            pointBackgroundColor: "#fee7da",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#fee7da",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dailyunit,
          },

        ],

      };

    },

    data2: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#D1077C");
      gradientStroke.addColorStop(0.4, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");
      return {
        labels: weekpush,
        datasets: [
          {
            label: "Weekly Revenue - ₹ " + totalweeklyamount,
            fill: true,
            backgroundColor: "#1E295D",
            pointBackgroundColor: "#DCC3D5",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#DCC3D5",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: weeklyrevenue,

          },
          {
            label: "Weekly Units Consumed - " + totalweeklyenergy + " kWh",
            fill: true,
            backgroundColor: "#D1108C",
            pointBackgroundColor: "#fee7da",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#fee7da",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: weeklyunit,

          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#D1077C");
      gradientStroke.addColorStop(0.4, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");

      return {
        labels: monthpush,
        datasets: [
          {
            label: "Monthly Revenue  ",
            fill: true,
            backgroundColor: "#1E295D",
            pointBackgroundColor: "#DCC3D5",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#DCC3D5",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: monthlyrevenue,
          },
          {
            label: "Monthly Units Consumed ",
            fill: true,
            backgroundColor: "#D1108C",
            pointBackgroundColor: "#fee7da",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#fee7da",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: monthlyunit,
          },
        ],
      };
    },
    data4: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#D1077C");
      gradientStroke.addColorStop(0.4, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");

      return {
        labels: citylist,
        datasets: [
          {
            label: "City Wise Revenue",
            fill: true,
            backgroundColor: "#1E295D",
            pointBackgroundColor: "#DCC3D5",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#DCC3D5",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: cityrevenue,
          },
          {
            label: "City Wise Energy Consumption (In kWh)",
            fill: true,
            backgroundColor: "#D1108C",
            pointBackgroundColor: "#fee7da",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#fee7da",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: cityunit,
          },
        ],
      };
    },
    data5: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#D1077C");
      gradientStroke.addColorStop(0.4, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");

      return {
        labels: statelist,
        datasets: [
          {
            label: "State Wise Revenue",
            fill: true,
            backgroundColor: "#1E295D",
            pointBackgroundColor: "#DCC3D5",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#DCC3D5",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: staterevenue,
          },
          {
            label: "State Wise Energy Consumption (In kWh)",
            fill: true,
            backgroundColor: "#D1108C",
            pointBackgroundColor: "#fee7da",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#fee7da",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: sstateunit,
          },
        ],
      };
    },
    data6: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#D1077C");
      gradientStroke.addColorStop(0.4, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");

      return {
        labels: amounttop5id,
        datasets: [
          {
            label: "City Revenue (TOP 5)",
            fill: true,
            backgroundColor: "#1E295D",
            pointBackgroundColor: "#DCC3D5",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#DCC3D5",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: amounttop5arry,
          }
        ],
      };
    },
    data7: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#D1077C");
      gradientStroke.addColorStop(0.4, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");

      return {
        labels: cityunittop5id,
        datasets: [
          {
            label: "City Consumption (TOP 5)",
            fill: true,
            backgroundColor: "#D1108C",
            pointBackgroundColor: "#DCC3D5",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#DCC3D5",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: cityunitttop5arry,
          }
        ],
      };
    },
    data8: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#D1077C");
      gradientStroke.addColorStop(0.4, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");

      return {
        labels: Stateamounttop5id,
        datasets: [
          {
            label: "State Revenue (TOP 5)",
            fill: true,
            backgroundColor: "#1E295D",
            pointBackgroundColor: "#DCC3D5",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#DCC3D5",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: Stateamounttoparry,
          }
        ],
      };
    },
    data9: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "#D1077C");
      gradientStroke.addColorStop(0.4, "#D1077C");
      gradientStroke.addColorStop(0, "#D1077C");

      return {
        labels: StateEnergyttop5id,
        datasets: [
          {
            label: "State Energy Consumption (TOP 5)",
            fill: true,
            backgroundColor: "#D1108C",
            pointBackgroundColor: "#DCC3D5",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#DCC3D5",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: StateEnergytop5arry,
          }
        ],
      };
    },

  };

  // let chartTotalUnits = {
  //   data: (canvas) => {
  //     return {
  //       labels: tyear,
  //       datasets: [
  //         {
  //           label: "Life Time Unit(In kWh)",
  //           backgroundColor: [
  //             'rgba(255, 159, 64)',
  //             'rgba(255, 159, 64)',
  //             'rgba(255, 159, 64)',
  //             'rgba(255, 159, 64)',
  //             'rgba(255, 159, 64)',
  //             'rgba(255, 159, 64)',

  //           ],
  //           borderColor: [
  //             'rgb(153, 102, 255)',
  //             'rgb(153, 102, 255)',
  //             'rgb(153, 102, 255)',
  //             'rgb(153, 102, 255)',
  //             'rgb(153, 102, 255)',
  //             'rgb(153, 102, 255)',
  //           ],

  //           data: [totalenergy],
  //         },
  //       ],
  //     };
  //   },
  // };

  // let chartTotalAmount = {
  //   data: (canvas) => {

  //     return {
  //       labels: tyear,
  //       datasets: [
  //         {
  //           label: "Life Time Amount (In Rupee)",
  //           backgroundColor: [
  //             'rgba(153, 102, 255)',
  //             'rgba(153, 102, 255)',
  //             'rgba(153, 102, 255)',
  //             'rgba(153, 102, 255)',
  //             'rgba(153, 102, 255)',
  //             'rgba(153, 102, 255)',

  //           ],
  //           borderColor: [
  //             'rgb(255, 159, 64)',
  //             'rgb(255, 159, 64)',
  //             'rgb(255, 159, 64)',
  //             'rgb(255, 159, 64)',
  //             'rgb(255, 159, 64)',
  //             'rgb(255, 159, 64)',
  //           ],
  //           data: [totalamount],
  //         },
  //       ],
  //     };
  //   },
  // };
  // let CustomerChart = {
  //   data: (canvas) => {
  //     return {
  //       labels: monthpush,
  //       datasets: [
  //         {
  //           label: "Total Customer",
  //           backgroundColor: "#D1108C",
  //           data: totalcustomer,
  //         },
  //       ],

  //     };
  //   },

  // };


  const plugin = {
    beforeInit(chart) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 25;
      }
    }
  }


  const xLabels = new Array(24).fill(0).map((_, i) => `${i}`)
  const yLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri']
  const dataheat = new Array(yLabels.length)
    .fill(0)
    .map(() =>
      new Array(xLabels.length).fill(0).map(() => Math.floor(Math.random() * 50 + 50))
    )



  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col col-sm-4 ">
          <PureBreadcrumbs />
        </div>
        <div className="col text-right">
          <span className="text-magenta" style={{ fontSize: "10px", color: "#BC0078" }}> Auto Refresh in {timer} sec</span>&nbsp;&nbsp;
          <a className="refresh" style={{ fontSize: "13px", color: "#10204B", fontWeight: "bold" }} onClick={handleAddClick}>Refresh &nbsp;<img style={{ height: "16px" }} src={process.env.PUBLIC_URL + '/images/Insight_img/refresh.svg'} alt="" /></a>
        </div>
      </div>


      {role !== "Enterprise" ?
        <>
          <div className="row rowpadding topCardDesigns m-1">
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-sm colpadding singleCard">
                  <a href={`/dashboard/insights/totalchargers`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-right pl-5 ">
                            <img className="float-right" src={process.env.PUBLIC_URL + '/images/Insight_img/Station.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.Total !== 0 ? props.chargers.Total : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Total Chargers</h5></span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "80px" }} />
                <div className="col-sm colpadding singleCard">
                  <a href={`/dashboard/insights/chargerstatus/${available}`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/Available.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.TotalAvailableCharger !== 0 ? props.chargers.TotalAvailableCharger : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Available</h5></span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "80px" }} />

                <div className="col-sm colpadding singleCard ">
                  <a href={`/dashboard/insights/chargerstatus/${busy}`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5 ">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/Busy.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.TotalBusyCharger !== 0 ? props.chargers.TotalBusyCharger : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Busy</h5></span>
                        </div>
                      </div>
                    </div>

                  </a>
                </div>

                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "80px" }} />

                <div className="col-sm colpadding singleCard">
                  <a href={`/dashboard/insights/chargerstatus/${maintenance}`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/Maintance.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.TotalMaintenanceCharger !== 0 ? props.chargers.TotalMaintenanceCharger : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Maintenance</h5></span>
                        </div>
                      </div>
                    </div>

                  </a>
                </div>

                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "80px" }} />

                <div className="col-sm colpadding singleCard">
                  <a href={`/dashboard/insights/chargerstatus/${comingsoon}`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/ComingSoon.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.TotalComingSoon !== 0 ? props.chargers.TotalComingSoon : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Coming Soon</h5></span>
                        </div>
                      </div>
                    </div>

                  </a>
                </div>
              </div>
            </div>
          </div>


          <div className="row rowpadding justify-content-between mt-4">

            <div className="col col-md-8 "  >
              <div className="col-md-12 sideCardDesigns ">
                <CardBody >
                  {role !== "Customer Support" ?
                    <>
                      <div className="row  pb-4">
                        <div className="col-5 center-block  h6"><span className="charttitle" >LifeTime Total Revenue - INR {lifetimetotalrevenue} </span></div>
                        <div className="col-5 center-block  h6"> <span className="charttitle"> LifeTime Units Charged - {lifetimetotalunit} kWh </span></div>
                        <div className="col-2 text-right">
                          <div className="btn-group dropleft text-right">
                            <button className="btn btn-sm" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {<i className="" title="Download" ><img style={{ height: "22px" }} src={process.env.PUBLIC_URL + '/images/Insight_img/mapDownload.svg'} alt="" /></i>
                              }
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" >

                              <a className="dropdown-item" href=" " >
                                <ExportToExcel apiData={data} fileName={fileName} />
                              </a>
                              <a className="dropdown-item" href=" ">
                                <StateExportToExcel apiData={Statedata} fileName={StatefileName} />
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>  </> :
                    <>
                      <div className="row ">
                        <div className="col-5 center-block  h6"><span className="charttitle" > Total Revenue / Total Units consumed </span></div>
                      </div>
                    </>
                  }

                  <hr />
                  <div className="chart-area2">
                    <Bar
                      data={chartDailyAmt[bigChartData2]}
                      plugins={plugin}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false
                          },

                          datalabels: {
                            display: false,
                            color: "black",
                            align: "end",
                            padding: {
                              right: 2
                            },
                            labels: {
                              padding: { top: 10 },
                              title: {
                                font: {
                                  weight: "bold"
                                }
                              },
                              value: {
                                color: "green"
                              }
                            },
                            formatter: function (value) {
                              return "\n" + value;
                            }
                          }
                        },

                        tooltips: {
                          mode: 'index',
                        },
                        responsive: true,

                        animation: {
                          duration: 0,
                        },
                        hover: {
                          animationDuration: 0,
                        },

                        legend: {
                          display: true,
                          labels: {
                            fontColor: 'black',
                            fontSize: 15,
                            padding: 20,
                            usePointStyle: true,
                            //width of circles in legend
                            boxWidth: 9

                          }
                        },

                        scales: {
                          xAxes: [{
                            gridLines: {
                              color: "rgba(0, 0, 0, 0)",
                            }
                          }],
                          yAxes: [{
                            gridLines: {
                              color: "rgba(0, 0, 0, 0)",
                            }
                          }]
                        },

                        tooltips: {
                          enabled: true
                        },
                        animation: {
                          duration: 1,
                          onComplete: function () {
                            var chartInstance = this.chart,
                              ctx = chartInstance.ctx;
                            ctx.textAlign = 'center';
                            ctx.color = "black";
                            ctx.fillStyle = "black";
                            ctx.textBaseline = 'bottom';
                            this.data.datasets.forEach(function (dataset, i) {
                              var meta = chartInstance.controller.getDatasetMeta(i);
                              meta.data.forEach(function (bar, index) {
                                var data = dataset.data[index];

                                if (data > 999 && data < 1000000) {
                                  var rdata = (data / 1000).toFixed() + 'K';     // convert to K for number from > 1000 < 1 million 
                                  ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                                } else if (data > 1000000) {
                                  var rdata = (data / 1000000).toFixed() + 'M';  // convert to M for number from > 1 million 
                                  ctx.fillText(rdata, bar._model.x, bar._model.y - 5);
                                } else if (data < 900) {
                                  ctx.fillText(data, bar._model.x, bar._model.y - 5); // if value < 1000, nothing to do
                                }

                              });
                            });
                          }
                        }
                      }
                      }

                    />
                  </div>
                  <br />
                  <div className="text-center">
                    <Button
                      tag="label"
                      className={classNames("btn btn-primary btn-sm", {
                        active: bigChartData2 === "data",
                      })}

                      onClick={() => setBgChartData2("data")}
                    >
                      <span >
                        Daily
                      </span>
                    </Button>

                    &nbsp;&nbsp;
                    <Button
                      tag="label"
                      className={classNames("btn btn-primary btn-sm", {
                        active: bigChartData2 === "data2",
                      })}
                      onClick={() => { setBgChartData2("data2") }}
                    >
                      <span >
                        Weekly
                      </span>
                    </Button>

                    &nbsp;
                    <Button
                      tag="label"
                      className={classNames("btn btn-primary btn-sm", {
                        active: bigChartData2 === "data3",
                      })}
                      onClick={() => setBgChartData2("data3")}
                    >
                      <span >
                        Monthly
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-tap-02" />
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      tag="label"
                      className={classNames("btn btn-primary btn-sm", {
                        active: bigChartData2 === "data6",
                      })}
                      onClick={() => setBgChartData2("data6")}
                    >
                      <span >
                        City revenue
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-tap-02" />
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      tag="label"
                      className={classNames("btn btn-primary btn-sm", {
                        active: bigChartData2 === "data7",
                      })}
                      onClick={() => setBgChartData2("data7")}
                    >
                      <span >
                        City energy consumption
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-tap-02" />
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      tag="label"
                      className={classNames("btn btn-primary btn-sm", {
                        active: bigChartData2 === "data6",
                      })}
                      onClick={() => setBgChartData2("data8")}
                    >
                      <span >
                        State revenue
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-tap-02" />
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      tag="label"
                      className={classNames("btn btn-primary btn-sm", {
                        active: bigChartData2 === "data7",
                      })}
                      onClick={() => setBgChartData2("data9")}
                    >
                      <span >
                        State energy consumption
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-tap-02" />
                      </span>
                    </Button>
                  </div>
                </CardBody>
              </div>
            </div>
            <div className="col-md-4   ">
              <div className="col-md-12 sideCardDesigns " >
                <div className="row rowpadding " >
                  <div className="col">
                    <div className=" text-center  singleCard  " >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="media-body text-left pt-3">
                            <span ><h4 className="statsweight" style={{ color: "#1E295D" }}>{cleanKMs}</h4></span>
                            <span className="stats">Clean <strong style={{ color: "#B50073" }}>KMs</strong> driven</span>
                          </div>
                          <div className="float-end p-3">
                            <img className="float-end" src={process.env.PUBLIC_URL + '/images/Insight_img/cleanKm.svg'} alt="" style={{ height: "50px" }} />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <hr className=" ml-5" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "40px" }} />
                <div className="row rowpadding" >
                  <div className="col">

                    <div className=" text-center  singleCard   " >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="media-body text-left pt-3" >
                            <span ><h4 className="statsweight" style={{ color: "#1E295D" }}>{fuelreplaced}</h4></span>
                            <span className="stats" >Litres of <strong style={{ color: "#B50073" }}>fuel</strong> replaced</span>
                          </div>
                          <div className="float-end p-3">
                            <img className="float-end" src={process.env.PUBLIC_URL + '/images/Insight_img/Fuel.svg'} alt="" style={{ height: "50px" }} />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <hr className=" ml-5" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "40px" }} />
                <div className="row  rowpadding" >
                  <div className="col">
                    <div className="text-center  singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="media-body text-left pt-3" >
                            <span ><h4 className="statsweight" style={{ color: "#1E295D" }}>{co2}</h4></span>
                            <span className="stats" >kg of <strong style={{ color: "#B50073" }}>Co2</strong> emissions reduced</span>
                          </div>
                          <div className="float-end p-3">
                            <img className="float-end" src={process.env.PUBLIC_URL + '/images/Insight_img/CO2insight.svg'} alt="" style={{ height: "50px" }} />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Connector chart */}
          {role !== "Customer Support" ?
            <ConnectorDataChart /> : ''
          }

          <div className="row rowpadding  mt-4  ">
            <div className="col-md-6 ">
              <CustomerDataChart />
            </div>
            <div className="col-md-6 ">
              <WalletDataChart />
            </div>
          </div>

          <div className="row rowpadding m-1 mt-4 " >
            <div className="col  sideCardDesigns " >

              <div className="" >
                <div style={{ height: '81vh', width: '100%' }}>
                  <GoogleMapReact
                    options={{ styles: GoogleMapStyles }}
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                  >
                    {props.locations && props.locations.map((location) => {
                      return (
                        <Marker
                          lat={location.latitude}
                          lng={location.longitude}
                          name={location.name}
                          address={location.address}
                          color="blue"
                        />
                      )
                    })}

                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </> :
        <>

        <div className="row rowpadding topCardDesigns m-1">
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-sm colpadding singleCard">
                  <a href={`/dashboard/insights/totalchargers`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-right pl-4 ">
                            <img className="float-right" src={process.env.PUBLIC_URL + '/images/Insight_img/Station.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-left pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.Total !== 0 ? props.chargers.Total : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Total Chargers</h5></span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "80px" }} />
                <div className="col-sm colpadding singleCard">
                  <a href={`/dashboard/insights/chargerstatus/${available}`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-3">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/Available.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-left pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.TotalAvailableCharger !== 0 ? props.chargers.TotalAvailableCharger : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Available</h5></span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "80px" }} />

                <div className="col-sm colpadding singleCard ">
                  <a href={`/dashboard/insights/chargerstatus/${busy}`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-3 ">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/Busy.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-left pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.TotalBusyCharger !== 0 ? props.chargers.TotalBusyCharger : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Busy</h5></span>
                        </div>
                      </div>
                    </div>

                  </a>
                </div>

                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "80px" }} />

                <div className="col-sm colpadding singleCard">
                  <a href={`/dashboard/insights/chargerstatus/${maintenance}`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-3">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/Maintance.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-left pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.TotalMaintenanceCharger !== 0 ? props.chargers.TotalMaintenanceCharger : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Maintenance</h5></span>
                        </div>
                      </div>
                    </div>

                  </a>
                </div>

                <div className="vl d-none d-md-block d-lg-block d-xl-block "></div>
                <hr className="d-block d-md-none" style={{ color: "#BDBFC7", border: "solid 0.5px", width: "70%", textAlign: "center", marginLeft: "80px" }} />

                <div className="col-sm colpadding singleCard">
                  <a href={`/dashboard/insights/chargerstatus/${comingsoon}`} style={{ textDecoration: "none" }}>
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-3">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/Insight_img/ComingSoon.svg'} alt="" style={{ height: "32px", width: "32px" }} />
                          </div>
                        </div>
                        <div className="media-body float-left pl-5" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "32px", textAlign: "center" }}><strong>{props.chargers.TotalComingSoon !== 0 ? props.chargers.TotalComingSoon : 0}</strong></h4></span>
                          <span className="stats"  > <h5 style={{ fontSize: "16px", textAlign: "center" }}>Coming Soon</h5></span>
                        </div>
                      </div>
                    </div>

                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row rowpadding  mt-4 m-1 ">
            <div className="col  sideCardDesigns">

              <div className=" " >
                <div style={{ height: '81vh', width: '100%' }}>
                  <GoogleMapReact
                    options={{ styles: GoogleMapStyles }}
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                  >
                    {props.locations && props.locations.map((location) => {
                      return (
                        <Marker
                          lat={location.latitude}
                          lng={location.longitude}
                          name={location.name} 
                          address={location.address}
                          color="blue"
                        />
                      )
                    })}

                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </>
      }


    </div>
  )

}


const mapStateToProps = (state) => {
  return {
    locations: state.location.fetchLocation,
    chargers: state.station.totalCharger,
    customerlist: state.customer.fetchCustomerList,
    insightdaily: state.insight.fetchDailyData,
    insightweekly: state.insight.fetchWeeklyData,
    insightmonthly: state.insight.fetchMonthlyData,
    insighttotal: state.insight.fetchTotalData,
    insightcustomer: state.insight.fetchCustomerData,
    insighttotalwallet: state.insight.fetchWalletData,
    insightcitydata: state.insight.fetchCityData,
    insightstatedata: state.insight.fetchStateData,
    list: state.report.fetchReportList,
    transactions: state.transaction.fetchTransactionData,

  }
}
const mapDispatchToProps = dispatch => {
  return {
    getLocation: () => dispatch(getLocationAction()),
    getCharger: () => dispatch(getChargerByCreatorAction()),
    fetCustomerList: () => dispatch(customerListAction()),
    DailyData: (current, lastDay) => dispatch(insightDailyDataAction(current, lastDay)),
    WeeklyData: (current, lastDay) => dispatch(insightWeeklyDataAction(current, lastDay)),
    MonthlyData: (current, lastDay) => dispatch(insightMonthlyDataAction(current, lastDay)),
    TotalData: () => dispatch(insightTotalDataAction()),
    TotalCustomer: (current, lastDay) => dispatch(insightCustomerDataAction(current, lastDay)),
    TotalWallet: (current, lastDay) => dispatch(insightWalletDataAction(current, lastDay)),
    CityChartData: () => dispatch(insightCityDataAction()),
    StateChartData: () => dispatch(insightStateDataAction()),
    fetReport: () => dispatch(ReportListAction()),
    fetchTransactionData: (viewtype, plananme) => dispatch(transactionDataAction(viewtype, plananme))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Insight);
