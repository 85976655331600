import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import "../station.css"
import { getStationByCreatorAction, editStationAction, saveMasterBooking } from "../../../../store/actions/stationAction";



const ManageBooking = (props) => {

  const { stationid, location } = useParams()

  const [regularHour, SetRegularHour] = useState([{ weekday: "", period_begin: "", period_end: "" }]);

  const [booking, setBooking] = useState();

  useEffect(() => {
    props.StationData("All")
    props.StationByName(stationid, location);
  }, []);

  const handleChange = (e) => {
    setBooking({
      ...booking,
      [e.target.name]: e.target.value.trim()
    });
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...regularHour];
    list[index][name] = value;
    SetRegularHour(list);
  };

  const handleAddClick = () => {
    SetRegularHour([...regularHour, { weekday: "", period_begin: "", period_end: "" }]);
  };

  const handleRemoveClick = (e, index) => {
    e.preventDefault()
    const list = [...regularHour];
    list.splice(index, 1);
    SetRegularHour(list);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Charger Slot Saved');
    console.log("regularHour", regularHour)
    props.saveMasterBooking(booking, regularHour);

  }

  if (props.masterbooking.error === '' && props.masterbooking.result !== "") {
    return <Redirect to={"/dashboard/stations/booking"}></Redirect>
  }

  return (


    <div className="container-fluid">
      {/* <div className="sideCardDesigns my-5">
        <div className="singleCard"> */}
      <div className="sideCardDesigns card-signin my-5">
        <div className="card-body">
          <h4 className="text-center formHeading pb-5"><b>Choose the preferred charger booking slot</b></h4>

          {/* <h4 className="text-center"><b>Choose the preferred charger booking slot</b></h4> */}
          {/* <hr className="my-4" /> */}
          <form className="form" onSubmit={handleSubmit} autoComplete="off">

            <div className="row">

              <div className="col-xl-4 col-sm-4 col-12">
                <div className="form-label-group">
                  <input type="text" id="name" name="name" className="form-control" value={props.list.name} disabled />
                  <label htmlFor="name">Location Name</label>
                </div>
              </div>
              <div className="col-xl-4 col-sm-4 col-12">
              </div>
              <div className="col-xl-4 col-sm-4 col-12">
                <div className="form-label-group">
                  <select className="form-control border-radius" name="chargeboxid" required onChange={handleChange} >
                    <option value="">Select ChargeBox ID</option>
                    {props.list && props.list.chargers.map((charger) => (
                      <option key={charger.chargeboxid} value={charger.chargeboxid}>
                        {charger.chargeboxid}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Weekday</th>
                        <th scope="col">Period Begin</th>
                        <th scope="col">Period End</th>
                        <th scope="col">Action</th>

                      </tr>
                    </thead>
                    <tbody>
                      {regularHour.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div className="text-center mr-2">
                                <select className="border-radius " name="weekday" onChange={e => handleInputChange(e, i)}>
                                  <option>Select Weekday</option>
                                  <option >1</option>
                                  <option >2</option>
                                  <option >3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                  <option>7</option>
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="text-center mr-2">
                                <select className="border-radius " name="period_begin" onChange={e => handleInputChange(e, i)}>
                                  <option>Select Period Begin</option>
                                  <option>00:00</option>
                                  <option>01:00</option>
                                  <option>02:00</option>
                                  <option>03:00</option>
                                  <option>04:00</option>
                                  <option>05:00</option>
                                  <option>06:00</option>
                                  <option>07:00</option>
                                  <option>08:00</option>
                                  <option>09:00</option>
                                  <option>10:00</option>
                                  <option>11:00</option>
                                  <option>12:00</option>
                                  <option>13:00</option>
                                  <option>14:00</option>
                                  <option>15:00</option>
                                  <option>16:00</option>
                                  <option>17:00</option>
                                  <option>18:00</option>
                                  <option>19:00</option>
                                  <option>20:00</option>
                                  <option>21:00</option>
                                  <option>22:00</option>
                                  <option>23:00</option>
                                  <option>24:00</option>

                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="text-center mr-2">
                                <select className="border-radius " name="period_end" onChange={e => handleInputChange(e, i)}>
                                  <option>Select Period End</option>
                                  <option>00:00</option>
                                  <option>01:00</option>
                                  <option>02:00</option>
                                  <option>03:00</option>
                                  <option>04:00</option>
                                  <option>05:00</option>
                                  <option>06:00</option>
                                  <option>07:00</option>
                                  <option>08:00</option>
                                  <option>09:00</option>
                                  <option>10:00</option>
                                  <option>11:00</option>
                                  <option>12:00</option>
                                  <option>13:00</option>
                                  <option>14:00</option>
                                  <option>15:00</option>
                                  <option>16:00</option>
                                  <option>17:00</option>
                                  <option>18:00</option>
                                  <option>19:00</option>
                                  <option>20:00</option>
                                  <option>21:00</option>
                                  <option>22:00</option>
                                  <option>23:00</option>
                                  <option>24:00</option>

                                </select>
                              </div>
                            </td>
                            <td>
                              {regularHour.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e, i) => handleRemoveClick(e, i)}>-</button>}
                              {regularHour.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddClick}>+</button>}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
            <div className="">
              <div className="row">
                <div className="col">
                  {props.masterbooking && props.masterbooking.error != '' ? <div className="alert alert-danger" role="alert">
                    {props.masterbooking.error}
                  </div> : ''}
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-3 col-lg-3 ">
                  <a className="btn btnchart btn-pill btn-wide text-uppercase" href="/dashboard/stations/booking">Back</a>
                </div> */}

                <div className="col-md-3 col-lg-3 ">
                  <a className="btn columnsBtn btn-pill btn-wide text-uppercase" href="/dashboard/stations/booking">Back</a>
                </div>
                <div className="col-md-9 col-lg-9 ">
                  <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"  >{!props.loading ? 'Save' : 'Please wait...'}</button>
                </div>

              </div>
            </div>
          </form>

        </div>
        <h6 className="text-center font-weight-bold"><i>Note: Timezone - Indian Standard time</i></h6>
            <br />
      </div>
      <div>
        <br />
        <br />
        
      </div>
    </div>


  )
}

const mapStateToProps = function (state) {
  return {
    stations: state.station.fetchStation,
    list: state.station.editstationbyname,
    masterbooking: state.station.savebookingmaster
  }
}
const mapDispatchToProps = dispatch => {
  return {
    StationData: (viewtype) => dispatch(getStationByCreatorAction(viewtype)),
    StationByName: (stationid, location) => dispatch(editStationAction(stationid, location)),
    saveMasterBooking: (chargeboxid, regular_hours) => dispatch(saveMasterBooking(chargeboxid, regular_hours))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBooking);
