import React, { useMemo, useEffect } from "react"
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getPriceByCreatorAction, PriceDeactivateAction } from "../../../store/actions/priceAction";
import Table from "./priceTable";
import "../report/table.css"
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2'

const PriceListView = (props) => {

  useEffect(() => {
    props.fetPrice();
  }, [])
  const user = JSON.parse(localStorage.getItem('user'));
  const activateDeactivateUser = (id,status) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#1e295d',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        props.PriceDeactivation(id,status);
        window.location.reload();
      }
    });
  }



  const data = props.pricelistdata && props.pricelistdata


  const columns = useMemo(

    (pricecomponent) => [

      {
        header: 'Price Name',
        accessor: 'name'
      },
      {
        header: 'Operator',
        accessor: 'operator'
      },
      {
        header: 'Country',
        accessor: 'country'
      },
      {
        header: 'State',
        accessor: 'state'
      },
      {
        header: 'Price',
        id: 'power',
        accessor: 'pricecomponent[0].amount'

      },
      {
        header: 'Unit',
        accessor: 'pricecomponent[0].kwh'
      },
      {
        header: 'Price type',
        accessor: 'pricecomponent[0].pricetype'
      },
      {
        header: 'Status',
        id: "status",
        accessor: d => {
          return d.status ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
        }
      },

      {
        header: 'Actions',
        id: 'action',
        accessor: (price) => {
          return (
            <div>
              <span>
                <a href={`/dashboard/pricing/viewsingleprice/${price.id}`} rel="tooltip" title="View " className="table-action view">
                  <i className="fas fa-eye"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;
              <span>
                <a href={`/dashboard/pricing/editprice/${price.id}`} rel="tooltip" title="Edit " className="table-action edit">
                  <i className="fa fa-edit"></i>
                </a></span>
              &nbsp;&nbsp;&nbsp;
              {user.role === "Admin" ? (
              <span>
                    {price.status? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash"  onClick={() => activateDeactivateUser(price.id, false) } >
                        <i className="fas fa-user-check"></i></a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active" onClick={() => activateDeactivateUser(price.id, true)}>
                    <i className="fas fa-user-slash"></i></a>}
                </span>
                ):( <span ></span>)
              }
              &nbsp;&nbsp;&nbsp;

            </div>

          );

        },

      }
    ],
    []
  );



  return (
    <div className="row py-2 mt-3  ">
      <div className="col">
        <div className="row py-2">
          <div className="col-md-12 col-lg-12">
            <div className="mb-3 h-100 sideCardDesigns ">
              <div className="card-header singleCard">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong>Price Details</strong>
                </div>

              </div>
              {!data.length ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
                (<div className="App"> <Table data={data} columns={columns}></Table></div>)}
            </div>
          </div>
        </div>
      </div>
    </div>



  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.price.isLoading,
    pricelistdata: state.price.fetchPriceList,
    priceDeactivation: state.price.priceDeactivation

  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetPrice: () => dispatch(getPriceByCreatorAction()),
    PriceDeactivation: (id,status) => dispatch(PriceDeactivateAction(id,status))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceListView);