const initState = {
    saveStationResponse: '',
    isLoading: false,
    fetchStation: '',
    totalCharger: '',
    editstationbyname: '',
    getconfiguration: '',
    changeconfiguration: '',
    unloackconnector: '',
    triggerconnector: '',
    clearcache: '',
    resetcharger: '',
    savepricegroupresponse: '',
    fetchdashchargers: '',
    fetchchargerbystatus: '',
    fetchTop10Station: '',
    updatefirmware: '',
    updateQRnumber: '',
    getChargerDowntime: '',
    changeavailability: '',
    updatestation: '',
    updatebusytoavailable: '',
    checkserverupordown: '',
    upocppserver: '',
    savebookingmaster: '',
    fetchbookingmaster: '',
    fetchinitialize: '',
    updateinitialize: '',
    deactivecharger: '',
    chargerUpTimeReport: '',
    isChargerUpTimeReportLoading: false,
    changeconfigurationchargeboxid: '',
    changeconfigurationurl: '',
    changeconfigurationnetwork: '',
    changeconfigurationwifissid: '',
    changeconfigurationwifipass: '',
    changeconfigurationoperatormode: '',
    changeconfigurationchargepointmodel: '',
    changeconfigurationconnectiontimeout: '',
    changeconfigurationloadsimulation: '',
    changeconfigurationrfid1: '',
    changeconfigurationrfid2: '',
    changeconfigurationsaveconfiguration: '',

}
const stationReducer = (state = initState, action) => {
    switch (action.type) {
        case 'STATION_LOADING':
            return { ...state, isLoading: true }
        case 'SAVE_STATION':
            return { ...state, saveStationResponse: action.payload, isLoading: false }
        case 'FETCH_STATION':
            return { ...state, fetchStation: action.payload, isLoading: false }
        case 'FETCH_STATION_TOP10':
            return { ...state, fetchTop10Station: action.payload, isLoading: false }
        case 'FETCH_TOTAL_CHARGER':
            return { ...state, totalCharger: action.payload, isLoading: false }
        case 'EDIT_STATION':
            return { ...state, editstationbyname: action.payload, isLoading: false }
        case 'GET_CONFIGURATION':
            return { ...state, getconfiguration: action.payload, isLoading: false }
        case 'CHANGE_CONFIGURATION':
            return { ...state, changeconfiguration: action.payload, isLoading: false }
        case 'UNLOACK_CONNECTOR':
            return { ...state, unloackconnector: action.payload, isLoading: false }
        case 'CLEAR_CACHE':
            return { ...state, clearcache: action.payload, isLoading: false }
        case 'RESET_CHARGER':
            return { ...state, resetcharger: action.payload, isLoading: false }
        case 'TRIGGER_CONNECTOR':
            return { ...state, triggerconnector: action.payload, isLoading: false }
        case 'SAVE_STATION_PRICE':
            return { ...state, savepricegroupresponse: action.payload, isLoading: false }
        case 'FETCH_DASH_CHARGERS':
            return { ...state, fetchdashchargers: action.payload, isLoading: false }
        case 'GET_CHARGER_LIST':
            return { ...state, getchargerlist: action.payload, isLoading: false }
        case 'GET_LOGS_BY_CHARGEBOXID':
            return { ...state, logsList: action.payload, isLoading: false }
        case 'FETCH_CHARGERS_BY_STATUS':
            return { ...state, fetchchargerbystatus: action.payload, isLoading: false }
        case 'UPDATE_FIRMWARE':
            return { ...state, updatefirmware: action.payload, isLoading: false }
        case 'UPDATE_QRNUMBER':
            return { ...state, updateQRnumber: action.payload, isLoading: false }
        case 'GET_CHARGER_DOWNTIME':
            return { ...state, getChargerDowntime: action.payload, isLoading: false }
        case 'CHANGE_AVAILABILITY':
            return { ...state, changeavailability: action.payload, isLoading: false }
        case 'UPDATE_STATION':
            return { ...state, updatestation: action.payload, isLoading: false }
        case 'UPDATE_BUSY_TO_AVAILABLE':
            return { ...state, updatebusytoavailable: action.payload, isLoading: false }
        case 'CHECK_SERVER_UP_DOWN':
            return { ...state, checkserverupordown: action.payload, isLoading: false }
        case 'UP_OCPP_SERVER':
            return { ...state, upocppserver: action.payload, isLoading: false }
        case 'SAVE_MASTER_BOOKING':
            return { ...state, savebookingmaster: action.payload, isLoading: false }
        case 'FETCH_MASTER_BOOKING':
            return { ...state, fetchbookingmaster: action.payload, isLoading: false }
        case 'DECATIVE_CHARGER':
            return { ...state, deactivecharger: action.payload, isLoading: false }
        case 'CHARGER_UPTIME_REPORT_DATA':
            return { ...state, chargerUpTimeReport: action.payload, isChargerUpTimeReportLoading: action.isChargerUpTimeReportLoading }
        //chnage confiration
        case 'CONFIGURATION_CHARGEBOXID':
            return { ...state, changeconfigurationchargeboxid: action.payload, isLoading: false }
        case 'CONFIGURATION_URL':
            return { ...state, changeconfigurationurl: action.payload, isLoading: false }
        case 'CONFIGURATION_NETWORK':
            return { ...state, changeconfigurationnetwork: action.payload, isLoading: false }
        case 'CONFIGURATION_WIFISSID':
            return { ...state, changeconfigurationwifissid: action.payload, isLoading: false }
        case 'CONFIGURATION_WIFIPASS':
            return { ...state, changeconfigurationwifipass: action.payload, isLoading: false }
        case 'CONFIGURATION_OPERATORMODE':
            return { ...state, changeconfigurationoperatormode: action.payload, isLoading: false }
        case 'CONFIGURATION_CHARGEPOINTMODEL':
            return { ...state, changeconfigurationchargepointmodel: action.payload, isLoading: false }
        case 'CONFIGURATION_CONNECTIONTIOMEOUT':
            return { ...state, changeconfigurationconnectiontimeout: action.payload, isLoading: false }
        case 'CONFIGURATION_LOADSIMULATION':
            return { ...state, changeconfigurationloadsimulation: action.payload, isLoading: false }
        case 'CONFIGURATION_RFID1':
            return { ...state, changeconfigurationrfid1: action.payload, isLoading: false }
        case 'CONFIGURATION_RFID2':
            return { ...state, changeconfigurationrfid2: action.payload, isLoading: false }
        case 'CONFIGURATION_SAVECONFIGURATION':
            return { ...state, changeconfigurationsaveconfiguration: action.payload, isLoading: false }

        default:
            return state;
    }
}
export default stationReducer;


