import React, { Component } from "react"
import './settings.css'

class Settings extends Component {
  render() {
    const { username, organization,firstname,lastname,city,state,address,phone } = JSON.parse(localStorage.getItem('user'))
    // console.log(JSON.parse(localStorage.getItem('user')))
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-sm-2 header">
              Profile
            </div>
            <div className="col-sm">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/email.svg'} alt="" />
              &nbsp;&nbsp;Email
            </div>
            <div className="col-sm-6 content-c">
              {username}
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/password.svg'} alt="" />
              &nbsp;&nbsp;Update password
            </div>
            <div className="col-sm-6 content-c">
              <a href="../auth/updatepass">Click Here</a>
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/companyname.svg'} alt="" />
              &nbsp;&nbsp;Company name
            </div>
            <div className="col-sm-6 content-c">
              {organization}
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/language.svg'} alt="" />
              &nbsp;&nbsp;Language
            </div>
            <div className="col-sm-6 content-c">
              English
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/time.svg'} alt="" />
              &nbsp;&nbsp;Time zone
            </div>
            <div className="col-sm-6 content-c">
              Indian Standard Time
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
        </div>
        <br />

        <div className="container">
          <div className="row">
            <div className="col-sm-2 header">
              Contact
            </div>
            <div className="col-sm">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/user.svg'} alt="" />
              &nbsp;&nbsp;First name
            </div>
            <div className="col-sm-6 content-c">
              {firstname}
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/user.svg'} alt="" />
              &nbsp;&nbsp;Last name
            </div>
            <div className="col-sm-6 content-c">
              {lastname}
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/mobile.svg'} alt="" />
              &nbsp;&nbsp;Mobile Number
            </div>
            <div className="col-sm-6 content-c">
              {phone}
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/city.svg'} alt="" />
              &nbsp;&nbsp;City
            </div>
            <div className="col-sm-6 content-c">
              {city}
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/state.svg'} alt="" />
              &nbsp;&nbsp;State
            </div>
            <div className="col-sm-6 content-c">
              {state}
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 inner-content">
              <img className="content-icon" src={process.env.PUBLIC_URL + '/images/settings/address.svg'} alt="" />
              &nbsp;&nbsp;Address
            </div>
            <div className="col-sm-6 content-c">
             {address}
            </div>
            <div className="col-sm-3 inner-content">
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings