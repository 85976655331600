import React, { useEffect, useMemo, useState } from "react";
// import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { connect } from "react-redux";
import PureBreadcrumbs from "../../../Breadcrums"
import Loader from 'react-loader-spinner';
import Table from "./chargerTable";
import { getSingleHubChargerPmr, getAllHubListData } from "../../../../../store/actions/hubActions";
import { FcDataConfiguration } from "react-icons/fc";
import moment from "moment";
import Modal from "reactstrap/es/Modal";

const ManageCharger = (props, args) => {

    const initialValues = { hubname: "" }
    const [singlehubname, setSinglehubname] = useState(initialValues)
    const [previewImg, setPreviewImg] = useState("")

    useEffect(() => {
        let hubb = "All"
        props.getHubListData()
        if (hubb == "All") {
            props.getChargerPmr(hubb);
        }
    }, []);

    const handleChange = (e) => {
        let hubname = e.target.value
        const { name, value } = e.target;
        setSinglehubname({ ...singlehubname, [name]: value });
        if (e.target.value !== "" && e.target.value !== undefined) {
            props.getChargerPmr(hubname);
        }
    };
    const previewImageFun = (e, previewImageStr) => {

        if (previewImageStr && previewImageStr !== "") {
            setPreviewImg(previewImageStr);
        } else {
            setPreviewImg("")
        }
    }

    const data = props.fetchChargerPmr && props.fetchChargerPmr
    // console.log("charger",data)


    const columns = useMemo(

        () => [
            {
                header: 'Hub Name',
                accessor: 'hubname'
            },
            {
                header: 'Date',
                accessor: 'date'
            },
            {
                header: 'Chargebox ID',
                accessor: 'chargeboxid'
            },
            {
                header: 'Energy Meter',
                id: 'energymeter',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.energymeter.map((data, i) =>

                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'Emergency Button',
                id: 'emergencybutton',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.emergencybutton.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'Alarm',
                id: 'alarm',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.alarm.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'Abnormal Noise',
                id: 'abnormalnoise',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.abnormalnoise.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'Damage Gun',
                id: 'damagegun',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.damagegun.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'Panel Lock',
                id: 'panellock',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.panellock.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'PCB Damage',
                id: 'pcbdamage',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.pcbdamage.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'Charger Position',
                id: 'chargerpositioning',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.chargerpositioning.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'Charger Condition',
                id: 'chargercondition',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.chargercondition.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'Charger Water Leakage',
                id: 'chargerwaterleakage',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.chargerwaterleakage.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'MOV Condition',
                id: 'movCondition',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.movCondition.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },
            {
                header: 'QR Code',
                id: 'qrcode',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>Value</td>
                                        <td className='tableHead'>Remark</td>
                                        <td className='tableHead'>Image</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.qrcode.map((data, i) =>
                                            <tr key={i}>
                                                <td>{data.value}</td>
                                                <td>{data.remark}</td>
                                                <td>
                                                    {
                                                        (data.image && data.image !== "")
                                                            ? <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#mypreviewImageModalcharger" onClick={e => previewImageFun(e, data.image)}>Preview <i color="danger" className="fas fa-eye"></i></a>
                                                            : "Not found"
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    );

                },
            },


        ],
        []);

    const hubList = props.fetchHubListData && props.fetchHubListData.map(id => id.hubname)

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col sideCardDesigns mb-2">

                    <div className="card-header singleCard justify-content-between mb-3">
                        <div className="text-left">
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{ color: "#10204B" }}>Hub Charger PMR Details</strong>
                            </div>
                        </div>
                        <div className=" text-right">
                            <select className="columnsBtn p-2" name="hubname" onChange={handleChange}>
                                <option value="All" >All</option>
                                {hubList && hubList.map((hub) => (
                                    <option key={hub.id} value={hub} >{hub}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col">
                            <div className="row ">
                                <div className="col-md-12 col-lg-12">
                                    <div className="mb-3 h-100 singleCard">
                                        {data && data.length > 0 ? (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                            :
                                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#1e295d", fontWeight: "600", fontSize: "30px", marginTop: "30px" }}>No Data Available</div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="mypreviewImageModalcharger" className="modal " abindex="-1" role="dialog" data-backdrop="false" style={{ paddingTop: '100px' }}>
                        <div className="modal-dialog modal-sm p-2">
                            <div className="modal-content ">
                                <div className="modal-body text-center">
                                {(() => {
                                        if (previewImg && ((previewImg.indexOf("http://") == 0 || previewImg.indexOf("https://") == 0))) {
                                            return (
                                                <img src={previewImg} className="previewImage w-100" />
                                            )
                                        } else {
                                            return (
                                                <img src={`data:image/jpeg;base64,${previewImg}`} className="previewImage w-100" />
                                            )
                                        }
                                    })()}
                                </div>

                                <button type="button" className="btn btn-default columnsBtn p-2 m-2" data-dismiss="modal">Close</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        fetchHubListData: state.hub.getAllHubListData,
        fetchChargerPmr: state.hub.getChargerPmr
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getHubListData: () => dispatch(getAllHubListData()),
        getChargerPmr: (hubname) => dispatch(getSingleHubChargerPmr(hubname)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCharger);

