
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fleetListAction, fleetTranListAction } from "../../../store/actions/fleetAction";
import "../assets/asset.css";
import UsersPage from './users/user'
import PureBreadcrumbs from "../Breadcrums"
import { getSubscriptionPlanByCreatorAction } from '../../../store/actions/subscriptionAction';

const Fleet = (props) => {
  useEffect(() => {
    const {planname}= JSON.parse(localStorage.getItem('user'))
    let pname = planname
    if (pname === "") {
      pname= "All"
    }

    if(pname === ""){
      props.fetSubscriptionplan("All")
    props.fleetData("All");
    props.fleetTransData("All")
    }else
    {
      props.fetSubscriptionplan(pname)
    props.fleetData(pname);
    props.fleetTransData(pname)
    }
  }, []);

  const handleChange = (e) => {
    let fleetid = e.target.value
   // console.log("e.target.value==>", e.target.value);
    if (e.target.value !== "" && e.target.value !== undefined) {
      props.fleetData(fleetid);
      props.fleetTransData(fleetid)
    }
  };
  const { role } = JSON.parse(localStorage.getItem('user'))

  var TotalFleetRevenue = parseFloat(props.fleets.amount + props.fleets.totalgst !== 0 ? props.fleets.amount + props.fleets.totalgst : 0).toFixed(0)

  return (
    <div className="container-fluid">
      <div className="row  ">
        <div className="col col-sm-4 ">
          <PureBreadcrumbs />
        </div>
      </div>


      <div className="row rowpadding topCardDesigns m-1  mb-5 ">
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-sm colpadding singleCard m-1">
                
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-right pl-5 ">
                            <img className="float-right" src={process.env.PUBLIC_URL + '/images/fleet_logo/totalConsumption.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center  pt-2" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center",color: "#bc0078" }}><strong>{props.fleets.usage !== 0 ? props.fleets.usage : 0}</strong></h4></span>
                          <span className="stats "  > <h5 className="font-weight-bold pb-2" style={{ fontSize: "20px", textAlign: "center" }}>Total Consumption (In kW)</h5></span>
                         
                        </div>
                      </div>
                    </div>
                
                </div>

                <div className="col-sm colpadding singleCard m-1">
                  
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/fleet_logo/totalRevenue.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pt-2" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center",color: "#bc0078" }}><strong>{TotalFleetRevenue}</strong></h4></span>
                          <span className="stats"  > <h5 className="font-weight-bold pb-2" style={{ fontSize: "20px", textAlign: "center" }}>Total Revenue (In ₹)</h5></span>
                         
                        </div>
                      </div>
                    </div>
                  
                </div>


                <div className="col-sm colpadding singleCard m-1">
                  
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5 ">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/fleet_logo/totalSessions.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pt-2" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center" ,color: "#bc0078" }}><strong>{props.fleets.session !== 0 ? props.fleets.session : 0}</strong></h4></span>
                          <span className="stats"  > <h5 className="font-weight-bold pb-2" style={{ fontSize: "20px", textAlign: "center" }}>Total Sessions</h5></span>
                          
                        </div>
                      </div>
                    </div>

                
                </div>


                <div className="col-sm colpadding singleCard m-1">
               
                    <div className=" text-center cardbg singleCard" >
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="float-left pl-5">
                            <img className="float-left" src={process.env.PUBLIC_URL + '/images/fleet_logo/totalFleetUser.svg'} alt="" style={{ height: "40px", width: "40px" }} />
                          </div>
                        </div>
                        <div className="media-body float-center pt-2" style={{ color: "#10204B" }} >
                          <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center",color: "#bc0078" }}><strong>{props.fleets.customers !== 0 ? props.fleets.customers : 0}</strong></h4></span>
                          <span className="stats"  > <h5 className="font-weight-bold pb-2" style={{ fontSize: "20px", textAlign: "center" }}>Total Users</h5></span>
                      
                        </div>
                      </div>
                    </div>             
                </div>

              </div>
            </div>
          </div> 
{/* 

      <div className="row">
        <div className="col">
          <div className="card shadow p-3 mb-5 rounded">
            <div className="row">
              <div className="col-md-12 col-xl-12">
                <div className="app-page-title cardbg bg-custom-1" >
                  <div className="page-title-wrapper ">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <img src={process.env.PUBLIC_URL + '/images/RFID.svg'} alt="" />
                      </div>
                      <div>
                        <span className="pink_text">Add Group RFID</span>
                        <div className="page-title-subheading">RFID details for fleet customers.</div></div>
                    </div>
                    <div className="page-title-actions">
                      <a href={`/dashboard/fleet/fleetgrouprfid`} className="btn btn-primary" >Add Group RFID</a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> */}
     

      <div className="row">
        <div className="col">
          <div className="sideCardDesigns">
          {role === "Network Operator" && "Admin" ? 
            <>
            <div className="card-header singleCard">
              <div className="col-md-9 text-left">
                Select Fleet to view desired data
              </div>
              <div className="col text-right">
                <select className="columnsBtn p-2" onChange={handleChange}>
                  <option value="All" >All</option>
                  {props.sublistdata && props.sublistdata.slice(1,).map(planname => {
                    return planname !== "Pay as you go" && planname.category === "Fleet" ?
                      <option key={planname.id} value={planname.name} >{planname.name}</option> : ''
                  })}
                </select>
              </div>
            </div>
            </>  
            : ''
            }

            
            
                    {props && <UsersPage fleetdata={{ props }}> </UsersPage>}
                  </div>
                </div>
              </div>
    </div>
  )
}



const mapStateToProps = function (state) {
  return {
    fleets: state.fleet.fetchFleetList,
    loading: state.fleet.isLoading,
    sublistdata: state.subscriptionplan.fetcSubscriptionplan,
    fleettran: state.fleet.fetchFleetTranList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fleetData: (planname) => dispatch(fleetListAction(planname)),
    fetSubscriptionplan: (planname) => dispatch(getSubscriptionPlanByCreatorAction(planname)),
    fleetTransData: (planname) => dispatch(fleetTranListAction(planname)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fleet);



