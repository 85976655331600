import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import validator from "validator";
import { cityAction, countryAction, privilegeAction, stateAction, roleActionByNetworkOperator } from "../../../store/actions/commonAction";
import { operatorAction } from "../../../store/actions/opAction";
import { saveAction } from "../../../store/actions/userAction";
import { getSubscriptionPlanByCreatorAction } from "../../../store/actions/subscriptionAction";
var arr = [];

class AssignAccess extends Component {


    accessform = {
        username: { value: '', isValid: true, message: '' },
        password: { value: '', isValid: true, message: '' },
        firstname: { value: '', isValid: true, message: '' },
        lastname: { value: '', isValid: true, message: '' },
        organization: { value: '', isValid: true, message: '' },
        organizationtype: { value: '', isValid: true, message: '' },
        addres: { value: '', isValid: true, message: '' },
        phone: { value: '', isValid: true, message: '' },
        country: { value: '', isValid: true, message: '' },
        cstate: { value: '', isValid: true, message: '' },
        city: { value: '', isValid: true, message: '' },
        zipcode: { value: '', isValid: true, message: '' },
        role: { value: "", isValid: true, message: "" },
        networkoperator: { value: "", isValid: true, message: "" },
        planname: { value: "", isValid: true, message: "" },
    };

    //state
    state = {
        ...this.accessform,
        access: { value: '', isValid: true, message: '' },
        role: JSON.parse(localStorage.getItem('user')),
        hidden: true,
    }
//show or hide privilages 
    toggleShow = () => {
        this.setState({ hidden: !this.state.hidden });
    }

    componentDidMount() {
        this.props.countrylist();
        this.props.npData("Network Operator");

    }

    //Dropdown onchange
    handleChange = (e) => {
        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: e.target.value,
            }
        };
        if (e.target.name === "networkoperator") {
            this.props.rolelist("NP", this.state);
        }
        if (e.target.name === "country") {
            this.props.statelist(e.target.value);
        }
        if (e.target.name === "cstate") {
            this.props.citylist(e.target.value);
        }
        if (e.target.name === "role") {
            this.props.privilegelist(e.target.id);
        }
        if (e.target.name === "sedan.svg") {
            this.props.fetcSubscriptionplan(e.target.id);
        }
        this.setState(state);
    }

    handleCheckChange = (e) => {
        const target = e.target;
        let pvalue = target.value;
        let pid = target.id
        let cd = e.target['name']
        if (cd === "sedan.svg") {
            this.props.fetSubscriptionplan(e.target.id);
        }
        if (target.checked) {
            arr.push({ key: pid, name: pvalue, code: cd })

        } else {

            const removeIndex = arr.findIndex(item => item.name === pvalue);
            // remove object
            arr.splice(removeIndex, 1);

        }
        this.setState({
            access: arr
        })
    }

    //save Users

    handleSubmit = (e) => {
        e.preventDefault();
        this.resetValidationStates();
        if (this.userFormValidation()) {
            this.props.saveUser({ ...this.state })
        }
    }

    //User Form validation
    userFormValidation = () => {
        const username = { ...this.state.username };
        const password = { ...this.state.password };
        const firstname = { ...this.state.firstname };
        const lastname = { ...this.state.lastname };
        const role = { ...this.state.role };
        const access = { ...this.state.access };
        const organization = { ...this.state.organization };
        const organizationtype = { ...this.state.organizationtype };
        const addres = { ...this.state.addres };
        const phone = { ...this.state.phone };
        const country = { ...this.state.country };
        const cstate = { ...this.state.cstate };
        const city = { ...this.state.city };
        const zipcode = { ...this.state.zipcode };
        const networkoperator = { ...this.state.networkoperator };
        const planname = { ...this.state.planname };

        let isGood = true;
        if (!validator.isEmail(username.value)) {
            username.isValid = false;
            username.message = "Not a valid email address";
            isGood = false;
        }
        if (validator.isEmpty(password.value)) {
            password.isValid = false;
            password.message = "Password is required";
            isGood = false;
            password.pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
         }
        if (validator.isEmpty(firstname.value)) {
            firstname.isValid = false;
            firstname.message = "First Name is required";
            isGood = false;
        }
        if (validator.isEmpty(lastname.value)) {
            lastname.isValid = false;
            lastname.message = "Last Name is required";
            isGood = false;
        }
        if (validator.isEmpty(role.value)) {
            role.isValid = false;
            role.message = "Role is required";
            isGood = false;
        }
        if (access[0] === undefined || access.value === "") {
            access.isValid = false;
            access.message = "Privileges is required";
            isGood = false;
        }
        if (validator.isEmpty(organization.value)) {
            organization.isValid = false;
            organization.message = "Organization is required";
            isGood = false;
        }
        if (validator.isEmpty(organizationtype.value)) {
            organizationtype.isValid = false;
            organizationtype.message = "Organization Type is required";
            isGood = false;
        }
        if (validator.isEmpty(addres.value)) {
            addres.isValid = false;
            addres.message = "Address is required";
            isGood = false;
        }
        if (validator.isEmpty(phone.value)) {
            phone.isValid = false;
            phone.message = "Phone is required";
            isGood = false;
        }
        if (validator.isEmpty(country.value)) {
            country.isValid = false;
            country.message = "Country is required";
            isGood = false;
        }
        if (validator.isEmpty(cstate.value)) {
            cstate.isValid = false;
            cstate.message = "State is required";
            isGood = false;
        }
        if (validator.isEmpty(city.value)) {
            city.isValid = false;
            city.message = "City is required";
            isGood = false;
        }
        if (validator.isEmpty(zipcode.value)) {
            zipcode.isValid = false;
            zipcode.message = "Zip Code is required";
            isGood = false;
        }
        if (validator.isEmpty(networkoperator.value)) {
            networkoperator.isValid = false;
            networkoperator.message = "Network Operator is required";
            isGood = false;
        }
        if (!isGood) {
            this.setState({
                username,
                password,
                firstname,
                lastname,
                role,
                access,
                organization,
                organizationtype,
                addres,
                phone,
                country,
                cstate,
                city,
                zipcode,
                networkoperator,
                planname,
            });
        }

        return isGood;
    };
    resetValidationStates = () => {
        // make a copy of everything in state
        const state = JSON.parse(JSON.stringify(this.state));
        Object.keys(state).map((key) => {
            if (state[key].hasOwnProperty("isValid")) {
                state[key].isValid = true;
                state[key].message = "";
            }
        });
        this.setState(state);
    };

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    render() {
        // console.log("dscwe", this.props.privileges.id)
        const { username, password, firstname, lastname, role, access, organization, organizationtype, addres, phone, country, cstate, city, zipcode, networkoperator, planname } = this.state;
        if (this.props.userresponse && this.props.userresponse.error === '') {
            return <Redirect to='/dashboard/access' />;
        }
        return (

            <div className="container-fluid">
                <div className="sideCardDesigns card-signin my-5">
                    <div className="card-body">
                        <h4 className="text-center formHeading"><b>Assign User Access</b></h4>
                        {this.props.userresponse.error ? <Alert variant="danger">
                            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                            <p>
                                {this.props.userresponse.error}
                            </p>
                        </Alert> : ''
                        }
                        <form className="form mt-5" onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="row rowpadding  justify-content-between">
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer">
                                        <div className="col-12">
                                            <label className="formLable" htmlFor="username">Email address</label>
                                            <div className="form-label-group required">
                                                <input type="email" id="username" name="username" className="form-control" placeholder="Email address" required onChange={this.handleChange} />

                                                <label htmlFor="username">Email address</label>
                                                <span className="help-block text-danger">{username.message}</span>
                                            </div>
                                            <label className="formLable" htmlFor="password"  data-toggle="tooltip" data-placement="right" title="password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters!">Password <i class="fa fa-info-circle" aria-hidden="true"></i></label>
                                            <div className="form-label-group required">
                                                <input type={this.state.hidden ? "password" : "text"} id="password" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" className="form-control" placeholder="Password" required onChange={this.handleChange} />
                                                <span class="p-viewer">
                                                    <i onClick={this.toggleShow} className={`fa ${this.state.hidden ? "fa-eye-slash" : "fa-eye"}`} style={{ cursor: "pointer", marginLeft: "-30px" }}></i>
                                                </span>

                                                <label htmlFor="password">Password </label>
                                                <span className="help-block text-danger">{password.message}</span>

                                            </div>

                                            <label className="formLable" htmlFor="firstname">First Name</label>
                                            <div className="form-label-group required">
                                                <input type="text" id="firstname" name="firstname" className="form-control" placeholder="First Name" onChange={this.handleChange} />
                                                <label htmlFor="firstname">First Name</label>
                                                <span className="help-block text-danger">{firstname.message}</span>
                                            </div>

                                            <label className="formLable" htmlFor="lastname">Last Name</label>
                                            <div className="form-label-group required">
                                                <input type="text" id="lastname" name="lastname" className="form-control" placeholder="Last Name" onChange={this.handleChange} />
                                                <label htmlFor="lastname">Last Name</label>
                                                <span className="help-block text-danger">{lastname.message}</span>
                                            </div>

                                            <label className="formLable" htmlFor="organization">Organization Name</label>
                                            <div className="form-label-group ">
                                                <input type="text" id="organization" className="form-control" name="organization" placeholder="Organization" onChange={this.handleChange} />
                                                <label htmlFor="organization">Organization Name</label>
                                                <span className="help-block text-danger">{organization.message}</span>
                                            </div>

                                            <label className="formLable" htmlFor="organizationtype">Organization type</label>
                                            <div className="form-label-group ">
                                                <select className="form-control border-radius" name="organizationtype" onChange={this.handleChange}>
                                                    <option>Organization type</option>
                                                    <option>Private Ltd Company</option>
                                                    <option>Public Ltd Company</option>
                                                    <option>Sole Proprietorship</option>
                                                    <option>Cooperatives</option>
                                                    <option>Limited Liability Partnership(LLP)</option>
                                                </select>
                                                <span className="help-block text-danger">{organizationtype.message}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <div className="col-12  formContainer ">
                                        <div className="col-12 ">
                                            <label className="formLable" htmlFor="addres">Organization Address</label>
                                            <div className="form-label-group required">
                                                <input type="text" id="addres" className="form-control" name="addres" placeholder="Address" onChange={this.handleChange} />
                                                <label htmlFor="addres">Organization Address</label>
                                                <span className="help-block text-danger">{addres.message}</span>
                                            </div>

                                            <label className="formLable" htmlFor="phone">Phone Number</label>
                                            <div className="form-label-group ">
                                                <input type="number" id="phone" className="form-control" name="phone" placeholder="Phone Number" maxLength="11" onInput={this.maxLengthCheck} onChange={this.handleChange} />
                                                <label htmlFor="phone">Phone Number</label>
                                                <span className="help-block text-danger">{phone.message}</span>
                                            </div>

                                            <label className="formLable" htmlFor="country">Country</label>
                                            <div className="form-label-group ">
                                                <select className="form-control border-radius" name="country" onChange={this.handleChange}>
                                                    <option>Country</option>
                                                    {this.props.countries && this.props.countries.map((country) => <option key={country.code} value={country.code}>{country.name}</option>)}
                                                </select>
                                                <span className="help-block text-danger">{country.message}</span>
                                            </div>

                                            <label className="formLable" htmlFor="cstate">State</label>
                                            <div className="form-label-group ">
                                                <select className="form-control border-radius" name="cstate" onChange={this.handleChange}>
                                                    <option>State</option>
                                                    {this.props.states && this.props.states.map((st) => <option key={st.code} value={st.name}>{st.name}</option>)}
                                                </select>
                                                <span className="help-block text-danger">{cstate.message}</span>
                                            </div>
                                            <label className="formLable" htmlFor="city">City</label>
                                            <div className="form-label-group required">
                                                <select className="form-control border-radius" name="city" onChange={this.handleChange}>
                                                    <option>City</option>
                                                    {this.props.cities && this.props.cities.map((c) => <option key={c.id} value={c.name}>{c.name}</option>)}
                                                </select>
                                                <span className="help-block text-danger">{city.message}</span>
                                            </div>

                                            <label className="formLable" htmlFor="zipcode">Zip Code</label>
                                            <div className="form-label-group required">
                                                <input type="number" id="zipcode" className="form-control" placeholder="Zip code" name="zipcode" maxLength="7" onInput={this.maxLengthCheck} onChange={this.handleChange} />
                                                <label htmlFor="zipcode">Zip Code</label>
                                                <span className="help-block text-danger">{zipcode.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row rowpadding formContainer m-1 mb-4">
                                <div className="col-xl-3 col-sm-3 col-12 p-2 ">
                                    <h5 className="font-size-lg text-capitalize font-weight-normal pl-3"> Network Operator </h5>
                                    <hr />
                                    {this.props.operatorlist && this.props.operatorlist.map((network) => (
                                        <div className="custom-control custom-radio pl-5 " key={network.id}>
                                            <input type="radio" id={network.id} name="networkoperator" className="custom-control-input" value={network.id} onChange={this.handleChange} />
                                            <label className="custom-control-label" htmlFor={network.id} >
                                                {network.organization}
                                            </label>
                                        </div>
                                    ))}
                                    <span className="help-block text-danger">
                                        {networkoperator.message}
                                    </span>
                                </div>
                                <div className="col-xl-3 col-sm-3 col-12 p-2">
                                    <h5>Select Access Role</h5>
                                    <hr />
                                    {this.props.roles && this.props.roles.map((r) =>
                                        <div className="custom-control custom-radio pl-5 " key={r.id}>
                                            <input type="radio" id={r.key} name="role" className="custom-control-input" value={r.name} onChange={this.handleChange} />
                                            <label className="custom-control-label" htmlFor={r.key}>{r.name}</label>
                                        </div>
                                    )}
                                    <span className="help-block text-danger">{role.message}</span>
                                </div>
                                <div className="col-xl-3 col-sm-3 col-12 p-2">
                                    <h5>Select Privileges</h5>
                                    <hr />
                                    {this.props.privileges && this.props.privileges.map((items, index) => {
                                        return (
                                            <ul key={index}>
                                                {Object.values(items.privileges).map((privilege) => {
                                                    return (
                                                        <div className="custom-control custom-checkbox pl-5" key={privilege.key}>
                                                            <input type="checkbox" name={privilege.code} id={privilege.key} className="custom-control-input" value={privilege.name} onChange={this.handleCheckChange} />
                                                            <label className="custom-control-label" htmlFor={privilege.key}>{privilege.name}</label>

                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        )
                                    })}

                                    <span className="help-block text-danger">{access.message}</span>
                                </div>

                                <div className="col-xl-3 col-sm-3 col-12 p-2">
                                    <h5 className="font-size-lg text-capitalize font-weight-normal"> Fleet Users </h5>
                                    <hr />
                                    {this.props.sublistdata && this.props.sublistdata.slice(1,).map(planname => {
                                        return planname !== "Pay as you go" && planname.display === true ?
                                            <div className="custom-control custom-radio pl-5" key={planname.id}>
                                                <input type="radio" id={planname.name} name="planname" className="custom-control-input" value={planname.name} onChange={this.handleChange} />
                                                <label className="custom-control-label" htmlFor={planname.name}>
                                                    {planname.name}
                                                </label>
                                            </div> : ''
                                    })}
                                    {/* {this.props.sublistdata && this.props.sublistdata.map((planname) => (
                                        <div className="custom-control custom-radio pl-5" key={planname.id}>
                                            <input type="radio" id={planname.name} name="planname" className="custom-control-input" value={planname.name} onChange={this.handleChange} />
                                            <label className="custom-control-label" htmlFor={planname.name}>
                                                {planname.name}
                                            </label>
                                        </div>
                                    ))} */}
                                    <span className="help-block text-danger">
                                        {planname.message}
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-6 col-sm-6 col-12">
                                    <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/access">Back</a>
                                </div>

                                <div className="col-xl-6 col-sm-6 col-12 float-right">
                                    <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">Assign User Access</button>

                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = function (state) {
    return {
        response: state.auth.currentUser,
        countries: state.common.countryList,
        states: state.common.stateList,
        cities: state.common.cityList,
        roles: state.common.roleListByNp,
        privileges: state.common.privilegeList,
        userresponse: state.users.saveUser,
        operatorlist: state.operator.fetchOperator,
        sublistdata: state.subscriptionplan.fetcSubscriptionplan

    }
}
const mapDispatchToProps = dispatch => {
    return {
        countrylist: () => dispatch(countryAction()),
        statelist: (countrycode) => dispatch(stateAction(countrycode)),
        citylist: (statecode) => dispatch(cityAction(statecode)),
        rolelist: (access) => dispatch(roleActionByNetworkOperator(access)),
        privilegelist: (privilege) => dispatch(privilegeAction(privilege)),
        npData: (nrole) => dispatch(operatorAction(nrole)),
        saveUser: (user) => dispatch(saveAction(user)),
        fetSubscriptionplan: () => dispatch(getSubscriptionPlanByCreatorAction("All"))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssignAccess);