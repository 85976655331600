import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { cityAction, countryAction, stateAction, } from "../../../../store/actions/commonAction";
import { getLocationOwnerAction, locationCategoryAction, locationSubCategoryAction, saveLocationAction, slocationAction, } from "../../../../store/actions/locationAction";
import { networkEnterpriseOperatorAction, operatorAction } from "../../../../store/actions/opAction";
import { amenityAction } from "../../../../store/actions/assetAction"
import dashboard from "../excel/newlocation.xlsx";
import * as XLSX from "xlsx";
import { Col } from 'reactstrap';

var amenitiesList = [];

const CreateNewLocation = (props) => {
  const { firstname } = JSON.parse(localStorage.getItem("user"));
  const [utilityList, setUtilityList] = useState();
  const [amenity, setAmenityList] = useState([]);

  // console.log("amenity", amenity)
  useEffect(() => {
    props.npData("Network Operator");
    props.locationStatusList();
    props.locationType();
    props.countrylist();
    props.locationOwn();
    props.getAmenityList();
  }, []);

  const amenities = props.amenitylist && props.amenitylist
  // console.log("amenities65667", amenities)
  // console.log("amenityList", amenity)

  const handleChange = (e) => {
    setUtilityList({
      ...utilityList,
      [e.target.name]: e.target.value.trim(),
    });

    if (e.target.name === "networkoperator") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let createdby = el.getAttribute("id");
      props.epData(createdby, "Enterprise");
    }
    if (e.target.name === "locationtype") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let loctype = el.getAttribute("id");
      props.locationSubType(loctype);
    }
    if (e.target.name === "country") {
      props.statelist(e.target.value);
    }
    if (e.target.name === "lstate") {
      props.citylist(e.target.value);
    }
  };

  const handleAmenityChange = (e) => {
    const target = e.target;
    let pvalue = target.value;
    let pid = target.id
    if (target.checked) {
      amenitiesList.push({ icon: pid, name: pvalue })
    } else {
      amenitiesList.splice(pvalue, 1);
    }
    setAmenityList(amenitiesList)
    // console.log("target",target)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("New Location Added");
    // console.log("save location", utilityList, amenity);
    props.saveLocation(utilityList, amenity);
  };
  //Read Excel file
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // console.log("wsname", ws);
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
        // console.log("exceldata", data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((d) => {
      // console.log("data2", d);

      for (let value of Object.values(d)) {
        const a = Object.keys(value);
        // console.log("array of keys", a);
        var b = [
          "networkoperator",
          "enterprise",
          "locationname",
          "locationnumber",
          "locationstatus",
          "locationtype",
          "locationsubtype",
          "ameneties",
          "address",
          "addressline1",
          "landmark",
          "country",
          "lstate",
          "city",
          "zipcode",
          "source",
          "latitude",
          "longitude",
          "description",

        ];
        if (JSON.stringify(a) == JSON.stringify(b)) {
          setUtilityList(d);
          return;
        } else {
          alert("Header format does not match");

          return (window.location.href = "/dashboard/assets/addnewlocation");
        }
      }
    });
  };

  const handleSubmitFile = () => {
    // console.log("Selectedfile", utilityList);
    for (let i = 0; i < utilityList.length; i++) {
      props.saveLocation(utilityList[i]);
    }
  };

  if (props.locationresponse && props.locationresponse.error === '') {
    return <Redirect to='/dashboard/assets' />;
  }


  return (
    <div className="container-fluid">
      <div className="sideCardDesigns card-signin">
        <div className="card-body">
          <h4 className="text-center formHeading">
            <b>Create New Location</b>
          </h4>
          <form className="form mt-4" onSubmit={handleSubmit} autoComplete="off">
            <div className="row rowpadding  justify-content-between">
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer">
                  <div className="col-12">
                    <label className="formLable" htmlFor="networkoperator">Network Operator</label>
                    <div className="form-label-group">
                      <select
                        className="form-control border-radius"
                        id="noperator"
                        name="networkoperator"
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Network Operator</option>
                        {props.operatorlist &&
                          props.operatorlist.map((network) => (
                            <option
                              key={network.id}
                              id={network.id}
                              value={network.organization}
                            >
                              {network.organization}{" "}
                            </option>
                          ))}
                      </select>
                    </div>
                    <label className="formLable" htmlFor="enterprise">Enterprise</label>
                    <div className="form-label-group">
                      <select
                        className="form-control border-radius"
                        id="enterprise"
                        name="enterprise"
                        required
                        onChange={handleChange}
                      >
                        <option value="">Enterprise</option>
                        {props.enterpriselist === null ? (
                          <option value="none">none</option>
                        ) : (
                          props.enterpriselist &&
                          props.enterpriselist.map((enterprise) => (
                            <option
                              key={enterprise.id}
                              value={enterprise.organization}
                            >
                              {enterprise.organization}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <label className="formLable" htmlFor="locationname">Location Name</label>
                    <div className="form-label-group required">
                      <input
                        type="text"
                        id="locationname"
                        name="locationname"
                        className="form-control control-label"
                        placeholder="Enter Location Name"
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="locationname">Enter Location Name</label>
                    </div>

                    <label className="formLable" htmlFor="locationnumber">Location Number</label>
                    <div className="form-label-group required">
                      <input
                        type="text"
                        id="locationnumber"
                        name="locationnumber"
                        className="form-control"
                        placeholder="Enter Location Number"
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="locationnumber">Enter Location Number</label>
                    </div>

                    <label className="formLable" htmlFor="locationstatus">Location Status</label>
                    <div className="form-label-group required">
                      <select
                        className="form-control border-radius"
                        name="locationstatus"
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Location Status</option>
                        {props.statuslist &&
                          props.statuslist.map((slist) => (
                            <option key={slist.id} value={slist.name}>
                              {slist.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <label className="formLable" htmlFor="locationtype">Location Category</label>
                    <div className="form-label-group required">
                      <select
                        className="form-control border-radius"
                        id="loctype"
                        name="locationtype"
                        onChange={handleChange}
                      >
                        <option>Select Location Category</option>
                        {props.ltype &&
                          props.ltype.map((lt) => (
                            <option key={lt.id} id={lt.code} value={lt.type}>
                              {lt.type}
                            </option>
                          ))}
                      </select>
                    </div>

                    <label className="formLable" htmlFor="locationsubtype">Location Sub-Category</label>
                    <div className="form-label-group required">
                      <select
                        className="form-control border-radius"
                        name="locationsubtype"
                        onChange={handleChange}
                      >
                        <option>Select Location Sub Category Type</option>
                        {props.lsubtype &&
                          props.lsubtype.map((lts) => (
                            <option key={lts.id} id={lts.code} value={lts.subtype}>
                              {lts.subtype}
                            </option>
                          ))}
                      </select>
                    </div>

                    <label className="formLable" htmlFor="locationowner">Location Ownership</label>
                    <div className="form-label-group required">
                      <select
                        className="form-control border-radius"
                        name="locationowner"
                        onChange={handleChange}
                        required
                      >
                        <option value="">Location Ownership</option>
                        {props.locationownerlist &&
                          props.locationownerlist.map((own) => (
                            <option key={own.id} value={own.marker}>
                              {own.owner}
                            </option>
                          ))}
                      </select>
                    </div>

                    <label className="formLable" htmlFor="address">Address Line 1</label>
                    <div className="form-label-group required">
                      <input
                        type="text"
                        id="address"
                        name="address"
                        className="form-control"
                        placeholder="Address Line 1"
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="address">Address Line 1</label>
                    </div>

                    <label className="formLable" htmlFor="latitude"> Location Latitude </label>
                    <div className="form-label-group required">
                      <input
                        type="number"
                        id="latitude"
                        name="latitude"
                        step="0.00000001"
                        pattern="^\d+(?:\.\d{1,8})?$"
                        className="form-control"
                        placeholder="Enter Location Latitude"
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="latitude">Location Latitude </label>
                    </div>


                    <label className="formLable" htmlFor="longitude">Location Longitude</label>
                    <div className="form-label-group required ">
                      <input
                        type="number"
                        id="longitude"
                        name="longitude"
                        step="0.00000001"
                        pattern="^\d+(?:\.\d{1,8})?$"
                        className="form-control"
                        placeholder="Enter Location Longitude"
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="longitude">Location Longitude</label>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer ">

                  <div className="col-12 ">

                    <label className="formLable" htmlFor="addressline1">Address Line 2</label>
                    <div className="form-label-group">
                      <input
                        type="text"
                        id="addressline1"
                        name="addressline1"
                        className="form-control"
                        placeholder="Address Line 2(Optional)"
                        onChange={handleChange}
                      />
                      <label htmlFor="addressline1">Address Line 2(Optional)</label>
                    </div>

                    <label className="formLable" htmlFor="landmark">Enter Landmark</label>
                    <div className="form-label-group">
                      <input
                        type="text"
                        id="landmark"
                        name="landmark"
                        className="form-control"
                        placeholder="Land Mark(Optional)"
                        onChange={handleChange}
                      />
                      <label htmlFor="landmark">Land Mark(Optional)</label>
                    </div>

                    <label className="formLable" htmlFor="country">country</label>
                    <div className="form-label-group required">
                      <select
                        className="form-control border-radius"
                        name="country"
                        onChange={handleChange}
                      >
                        <option>Select Country</option>
                        {props.countries &&
                          props.countries.map((country) => (
                            <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <label className="formLable" htmlFor="lstate">State</label>
                    <div className="form-label-group required">
                      <select
                        className="form-control border-radius"
                        name="lstate"
                        onChange={handleChange}
                      >
                        <option>Select State</option>
                        {props.states &&
                          props.states.map((st) => (
                            <option key={st.code} value={st.name}>
                              {st.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <label className="formLable" htmlFor="city">Select City</label>
                    <div className="form-label-group required">
                      <select
                        className="form-control border-radius"
                        name="city"
                        onChange={handleChange}
                      >
                        <option>Select City</option>
                        {props.cities &&
                          props.cities.map((c) => (
                            <option key={c.id} value={c.name}>
                              {c.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <label className="formLable" htmlFor="zipcode">Zipcode</label>
                    <div className="form-label-group required">
                      <input
                        type="number"
                        id="zipcode"
                        name="zipcode"
                        className="form-control"
                        placeholder="Enter Zipcode"
                        onChange={handleChange}
                      />
                      <label htmlFor="zipcode">Zipcode</label>
                    </div>

                    <label className="formLable" htmlFor="source">Source</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="source" name="source" required onChange={handleChange}>
                        <option value="">Select Source</option>
                        <option>Chargegrid</option>
                        <option>HpPay</option>
                        <option>Bosch</option>
                      </select>
                    </div>


                    <label className="formLable" htmlFor="networkoperator">Ameneties</label>
                    <div className=" containerAme p-2 pt-1">
                      {amenities && amenities.map((ame) =>
                        <div className="form-check form-check-inline badge badge-pill text py-1 px-2" icon={ame.icon}>
                          <input className="form-check-input" type="checkbox" name="amenities" id={ame.icon} value={ame.name} onChange={handleAmenityChange} />
                          <label className="form-check-label" htmlFor={ame.icon}>{ame.name}</label>
                        </div>
                      )}
                    </div>



                    <label className="formLable pt-4" htmlFor="enteredby">Entry By</label>
                    <div className="form-label-group ">
                      <input
                        type="text"
                        name="enteredby"
                        className="form-control control-label"
                        value={firstname}
                        disabled
                      />
                    </div>

                    <label className="formLable" htmlFor="description">Description</label>
                    <div className="form-group pb-3">
                      <textarea
                        className="form-control p-3"
                        id="description"
                        name="description"
                        placeholder="Description(Optional)"
                        rows="4"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <br/>
                    <br/>
                    <br/>

                  </div>
                </div>
              </div>
            </div>



            <div className="row">
              <div className="col-xl-6 col-sm-6 col-12">
                <a
                  className="btn  btn-pill btn-wide columnsBtn text-uppercase float-left"
                  href="/dashboard/assets"
                >
                  Back
                </a>
              </div>

              <div className="col-xl-6 col-sm-6 col-12 float-right">
                <button className="btn  btn-pill btn-wide columnsBtn text-uppercase float-right">
                  {!props.loading ? "Create New Location" : "Please wait..."}
                </button>
              </div>
            </div>
          </form>


          <div className="row">
            <div className="hr-sect">
              <span style={{ color: "#10204B", fontWeight: "600", fontSize: "30px" }}>OR</span>
            </div>
          </div>


          <div className="row formLable">
          <div className="col-6">
            <div className="col-12 formContainer p-5">
              <h4 className="text-left textColor ">
                <b> Download Excel </b>
              </h4>

              <div className="page-title-subheading text-left textColor">
                <ul>Steps to follow</ul>
                <ul>1. Download the excel.</ul>
                <ul>2. Add the desired data without changing header row.</ul>
                <ul>
                  2. Select the location name as it is mentioned in above form.
                </ul>
                <ul>
                  3. If there is already a dropdown, select the options from
                  dropdown.
                </ul>
              </div>
              <a
                className=" p-2 columnsBtn btn-pill btn-wide text-uppercase textColor "
                href={dashboard}
                download="newlocation.xlsx"
              >
                {" "}
                Download Excel Format Here{" "}
              </a>
            </div>
            
            </div>
          <div className="col-6">
            <div className="col-12 textColor formContainer p-5">
              <h4 className="text-left">
                <b> Upload Excel </b>
              </h4>
              <div className="page-title-subheading text-left">
                <ul>Steps to follow</ul>
                <ul>1. Save the previous excel file.</ul>
                <ul>2. Click on choose file and select the same file.</ul>
                <ul>3. Click upload.</ul>
              </div>
              <input
                className="p-2 columnsBtn btn-pill btn-wide text-uppercase"
                type="file"
                name="file_upload"
                onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
              />
            </div>
            </div>
          </div>

          <br />
          <div className="row">
            <div className="col-md-3 col-lg-3 ">
              <a
                className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                href="/dashboard/assets"
              >
                Cancel
              </a>
            </div>
            <div className="col-md-9 col-lg-9 ">
              <button
                className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"
                onClick={handleSubmitFile}
              >
                Upload New Location
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    operatorlist: state.operator.fetchOperator,
    enterpriselist: state.operator.fetchEnterprise,
    statuslist: state.location.fetchLocationStatusList,
    ltype: state.location.fetchLocationCategorylist,
    lsubtype: state.location.ftchLocationSubCategoryList,
    countries: state.common.countryList,
    states: state.common.stateList,
    cities: state.common.cityList,
    loading: state.location.isLoading,
    locationresponse: state.location.saveLocation,
    locationownerlist: state.location.fetchLocationOwner,
    amenitylist: state.asset.fetchAmenity
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    npData: (nrole) => dispatch(operatorAction(nrole)),
    epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
    locationStatusList: () => dispatch(slocationAction()),
    locationType: () => dispatch(locationCategoryAction()),
    locationSubType: (loctype) => dispatch(locationSubCategoryAction(loctype)),
    countrylist: () => dispatch(countryAction()),
    statelist: (countrycode) => dispatch(stateAction(countrycode)),
    citylist: (statecode) => dispatch(cityAction(statecode)),
    saveLocation: (location, amenity) => dispatch(saveLocationAction(location, amenity)),
    locationOwn: () => dispatch(getLocationOwnerAction()),
    getAmenityList: () => dispatch(amenityAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewLocation);




















