import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { getSinglePlanActions, getPlanTypeAction, updatePlanAction } from "../../../store/actions/subscriptionAction"
import moment from "moment";
import { useHistory } from 'react-router-dom';

const EditPlan = (props) => {
    const history = useHistory();
    const { firstname } = JSON.parse(localStorage.getItem("user"));
    
    const [data, setData] = useState({
        name: "",
        code: "",
        description: "",
        startdate: "",
        enddate: "",
        fee: "",
        setupfee: "",
        period: "",
        category: "",
        display: "",
        expiration: "",
        plantype: "",
    });
    const pdisplay = data.display === true ? "Yes" : "No"

    const { id } = useParams()

    useEffect(() => {
        props.getSinglePlan(id);
        props.plantypelist();
    }, []);

    // console.log(props.plansingledetail)

    useEffect(() => {
        setData(props.plansingledetail)
    }, [props.plansingledetail])

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        props.updatePlan(id, data);
        history.push("/dashboard/pricing")


    };

   

    // const { firstname } = JSON.parse(localStorage.getItem("user"));
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);

    const startddate = props.plansingledetail.startdate
    const end = props.plansingledetail.enddate
    const start = moment(startddate, 'YYYY-MM-DD').format('MM-DD-YYYY');
    const endate = moment(end, 'YYYY-MM-DD').format('MM-DD-YYYY');



    return (
        <div className="container-fluid">
            <div className="sideCardDesigns card-signin my-5">
                <div className="card-body">
                    <h4 className="text-center formHeading">
                        <b>Update Subscription Plan</b>
                    </h4>

                    <form className="form mt-5" onSubmit={handleSubmit}>
                        <div className="row rowpadding  justify-content-between">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="plantype">Plan Type</label>
                                        <div className="form-label-group ">
                                            <select
                                                className="form-control border-radius"
                                                name="plantype"
                                                onChange={handleChange}
                                                value={data.plantype}
                                            >
                                                <option>Select Operator</option>
                                                {props.plantypleres &&
                                                    props.plantypleres.map((pt) => (
                                                        <option key={pt.id}
                                                            value={pt.id}>
                                                            {pt.name}
                                                        </option>
                                                    ))}
                                            </select>

                                        </div>
                                        <label className="formLable" htmlFor="name">Subscription Name</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="name" name="name" className="form-control control-label" value={data.name} onChange={handleChange} />
                                            <label htmlFor="name">Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="code">Subscription Code</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="code" name="code" className="form-control control-label" value={data.code} onChange={handleChange} />
                                            <label htmlFor="code">Code</label>
                                        </div>



                                        <label className="formLable" htmlFor="startDate">Start Date</label>
                                        <div className="form-label-group">
                                            <DatePicker className="form-control control-label"
                                                name="startDate"
                                                // selected={data.startddate}
                                                value={start}
                                                // onChange={startdate => setData(startdate)}
                                                isClearable
                                                placeholderText="Select Subscription Start Date"
                                                disabled
                                            />
                                        </div>

                                        <label className="formLable" htmlFor="endDate">End Date</label>
                                        <div className="form-label-group">
                                            <DatePicker className="form-control control-label "
                                                name="endDate"
                                                // selected={data.enddate}
                                                value={endate}
                                                // onChange={enddate => setData(enddate)}
                                                isClearable
                                                placeholderText="Select Subscription End Date"
                                                disabled
                                            />
                                        </div>

                                        <label className="formLable" htmlFor="fee">Subscription Fee</label>
                                        <div className="form-label-group ">
                                            <input type="number" id="fee" name="fee" className="form-control control-label" value={data.fee} onChange={handleChange} />
                                            <label htmlFor="fee">Subscription Fee</label>
                                        </div>

                                        <label className="formLable" htmlFor="setupfee">Subscription Setup Fee</label>
                                        <div className="form-label-group ">
                                            <input type="number" id="setupfee" name="setupfee" className="form-control control-label" value={data.setupfee} onChange={handleChange} />
                                            <label htmlFor="setupcost">Subscription Setup Fee</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="period">Subscription Period</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="period" value={data.period} onChange={handleChange} >
                                                <option value="">Select Subscription Period</option>
                                                <option>Monthly</option>
                                                <option>Quarterly</option>
                                                <option>Yearly</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="category">Subscription Category</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="category" value={data.category} onChange={handleChange} >
                                                <option value="">Subscription Category</option>
                                                <option>Individual</option>
                                                <option>Fleet</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="display">Display to Customer</label>
                                        <div className="form-label-group required">
                                            <select className="form-control border-radius" id="display" name="display" value={pdisplay} onChange={handleChange} >
                                                <option value="">Display to Customer</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="expiration">Subscription Expiration Type</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="expiration" value={data.expiration} onChange={handleChange} >
                                                <option value="">Subscription Expiration Type</option>
                                                <option>Subscription End Date</option>
                                                <option>Incentive End Date</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="description">Description</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="description" name="description" className="form-control control-label" value={data.description} onChange={handleChange} />
                                            <label htmlFor="description">Description</label>
                                        </div>
                                        <label className="formLable" htmlFor="enterdby">Entered By</label>
                                        <div className="form-label-group pb-3">
                                            <input
                                                type="text"
                                                name="enterdby"
                                                className="form-control control-label"
                                                value={firstname}
                                                disabled
                                            />
                                            <label htmlFor="enterdby">Data Entered By</label>
                                        </div>

                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-12">
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase float-left" href="/dashboard/pricing">Back</a>
                            </div>

                            <div className="col-xl-6 col-sm-6 col-12 float-right">
                                <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" >{!props.loading ? 'Update Subscription Plan' : 'Please wait...'}</button>

                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = function (state) {
    return {
        loading: state.subscriptionplan.isLoading,
        plansingledetail: state.subscriptionplan.fetchsubscriptionbyid,
        plantypleres: state.subscriptionplan.fetchPlanType,
        updateplan: state.subscriptionplan.updateplanbyid

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSinglePlan: (id) => dispatch(getSinglePlanActions(id)),
        plantypelist: () => dispatch(getPlanTypeAction()),
        updatePlan: (id, data) => dispatch(updatePlanAction(id, data))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPlan); 