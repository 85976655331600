
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PureBreadcrumbs from "../../Breadcrums"
import { getAllHubListData } from '../../../../store/actions/hubActions';
import {  getChargerUpTimeReport } from '../../../../store/actions/stationAction';
import Table from "./chargerUpTimeReportTable";
import "../../report/table.css"
import Loader from 'react-loader-spinner';


const ChargerUpTimeReport = (props) => {
    const [hubName, setHubName] = useState("All")

    useEffect(() => {
        props.getAllHubListData();
    }, []);


    useEffect(() => {
        if (props.fetchAllHubData !== undefined && props.fetchAllHubData !== null && props.fetchAllHubData.length > 0) {
            let temp = props.fetchAllHubData[0]
            setHubName(temp.hubname)
            // props.getChargerUpTimeReport(temp.stationid, temp.hubname);
            let locationId = findLocationId(props.fetchAllHubData, temp.hubname)

            if (locationId !== undefined && locationId.length > 0) {
                props.getChargerUpTimeReport(locationId);
            }
        }
    }, [props.fetchAllHubData]);


    const secondsToHMS = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);
    
        const formattedTime = [
          hours.toString().padStart(2, '0'),
          minutes.toString().padStart(2, '0'),
          seconds.toString().padStart(2, '0')
        ].join(':');
    
        return formattedTime;
    };

    const handleChange = (e) => {
        e.preventDefault();
        let hubName = e.target.value
        // const { name, value } = e.target;
        setHubName(hubName);

        let locationId = findLocationId(props.fetchAllHubData, hubName)

        if (locationId !== undefined && locationId.length > 0) {
            props.getChargerUpTimeReport(locationId);
        }

    };

    const hubList = props.fetchAllHubData && props.fetchAllHubData.map(id => id.hubname)


    const findLocationId = (data, name) => {
        
        const foundElement = (data).find(element => element.hubname === name);

        
        if (foundElement) {
          console.log('Found element:', foundElement);
          return [foundElement.stationid]
        } else if (name === "All") {
            let arr = data.map(item => item.stationid)
            return arr
        } else {
          alert('LocationId  not found');
        }
      };

      const columns = useMemo(

        () => [
            {
                header: 'Depot',
                accessor: 'hubName'
            },
            {
                header: 'Charger Box ID',
                accessor: 'chargerBoxID'
            },
            {
                header: 'Charger Number',
                accessor: 'chargerNumber'
            },
            {
                header: 'Total Available Time',
                id: 'totalAvailableTime',
                accessor: (d) => {
                    return (
                        <>
                            {secondsToHMS(d.totalAvailableTime)}
                        </>
                    )
                }

            },
            {
                header: 'Total Busy Time',
                id: 'totalBusyTime',
                accessor: (d) => {
                    return (
                        <>
                            {secondsToHMS(d.totalBusyTime)}
                        </>
                    )
                }
            },
            {
                header: 'Total Maintenance Time',
                id: 'totalMaintenanceTime',
                accessor: (d) => {
                    return (
                        <>
                            {secondsToHMS(d.totalMaintenanceTime)}
                        </>
                    )
                }
            },
            {
                header: 'Total Up Time %',
                id: 'totalUpTime',
                accessor: (d) => {
                    return (
                        <>
                            {d.totalUpTime +"%"}
                        </>
                    )
                }
            },
        ],
        []);

    const data = props.chargerUpTimeReport && props.chargerUpTimeReport

    console.log("data",data)
    return (
        <div className="container-fluid">
            <div className="row  ">
                <div className="col col-sm-4 ">
                    <PureBreadcrumbs />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="sideCardDesigns mb-2">
                    {props.isLoading ?(<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>) :
                    <>
                        <div className="card-header  singleCard  justify-content-between">
                            <div className=" text-left" style={{ color: "#10204B" }}>
                                Select Hub to view desired data
                            </div>
                            <div className=" text-right">
                                <select className=" columnsBtn p-2" name="hubname" onChange={handleChange} value={hubName}>
                                    <option value="All" >All</option>
                                    {hubList && hubList.map((hub) => (
                                        <option key={hub.id} value={hub} >{hub}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col">
                                <div className="row ">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="mb-3 h-100 singleCard">

                                            {props.isChargerUpTimeReportLoading ? (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>) : (data && data.length >0  ?  (<div className="App"> 
                                           <Table data={data} columns={columns} hubName={hubName}></Table>
                                           </div>)
                                               :
                                               (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#1e295d", fontWeight: "600", fontSize: "30px", marginTop: "30px" }}>No Data Available</div>))}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                    </div>
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = function (state) {
    return {
        fetchAllHubData: state.hub.getAllHubListData,
        isLoading: state.hub.isLoading,
        isChargerUpTimeReportLoading: state.station.isChargerUpTimeReportLoading,
        chargerUpTimeReport: state.station.chargerUpTimeReport,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllHubListData: () => dispatch(getAllHubListData()),
        getChargerUpTimeReport: (locationId) => dispatch(getChargerUpTimeReport(locationId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargerUpTimeReport);




