const initState={
    fetchmobileglobalsetting:'',
    updatemobileglobalsetting:''
}
const utilsReducer=(state=initState,action)=>{
    switch (action.type) {
        case 'FETCH_MOBILE_SETTTING':
          return {...state, fetchmobileglobalsetting: action.payload}
        case 'UPDATE_MOBILE_SETTING':
          return {...state, updatemobileglobalsetting: action.payload}
 
        default:
          return state;
      }
}
export default utilsReducer;