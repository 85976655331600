import React, { useEffect, useMemo } from "react"
import moment from "moment";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "../../report/table";
import Loader from 'react-loader-spinner';
import { getStationByCreatorAction, getChargerByOwnerAction } from "../../../../store/actions/stationAction";

const BookingCard = (props) => {

  useEffect(() => {
    props.StationData("All")
    props.chargerData();
  }, []);

  const data = props.stations && props.stations
  const chargerData = props.chargers && props.chargers

  const columns = useMemo(

    () => [

      {
        header: 'Station Id',
        accessor: 'stationid'
      },
      {
        header: 'Charging Station Name',
        accessor: 'name'
      },

      {
        header: 'Commission Date',
        id: 'commisiondate',
        accessor: d => {
          return moment(d.commisiondate)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Actions',
        id: 'id',
        accessor: d => `${d.stationid}/${d.id}`,
        Cell: (data) => {
          return (
            <div>
              <span>
                <a href={`/dashboard/stations/booking/managebooking/${data.value}`}
                  rel="tooltip"
                  title="Book slot "
                  className="table-action view"
                >
                  <button className="btn btnchart btn-sm"><i className="fas fa-calendar-alt"></i>&nbsp;&nbsp;Manage slot</button>
                </a>
              </span>&nbsp;
            </div>

          );
        },
      }
    ],
    []
  );

  const chargerColumns = useMemo(

    () => [

      {
        header: 'Charge BoxId',
        accessor: 'chargeboxid'
      },
      {
        header: 'Charger Name',
        accessor: 'chargername'
      },
      {
        header: 'Location',
        accessor: 'location'
      },
      {
        header: 'Charger Type',
        accessor: 'chargertype'
      },
      {
        header: 'Make',
        accessor: 'make'
      },
      {
        header: 'Commission Date	',
        id: 'commisiondate',
        accessor: d => {
          return moment(d.commisiondate)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        header: 'Actions',
        id: 'id',
        accessor: d => `${d.chargeboxid}`,
        Cell: (data) => {
          // console.log("id",data.value  )
          return (
            <div>
              <span>
                <a href={`/dashboard/stations/booking/viewbooking/${data.value}`}
                  rel="tooltip"
                  title="View "
                  className="table-action view"
                >
                  <i className="fas fa-eye"></i>
                </a></span>
            </div>
          );
        },
      }
    ],
    []
  );



  return (
    <>
      <div className="container-fluid">

        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="mb-3 h-100 sideCardDesigns ">
              <div className="card-header singleCard">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <strong>Choose the preferred location to manage booking</strong>
                </div>
              </div>

              {!data.length ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
                (<div className="App"> <Table data={data} columns={columns}></Table></div>)}

            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="mb-3 h-100 sideCardDesigns ">
              <div className="card-header singleCard">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <strong>View individual charger booking</strong>
                </div>
              </div>

              {/* {
                chargerData && chargerData.length > 0 ?
                  (<div className="App"> <Table data={chargerData} columns={chargerColumns}></Table></div>) :
                  (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)

              } */}

              {!chargerData.length ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                :
                (<div className="App"> <Table data={chargerData} columns={chargerColumns}></Table></div>)}

            </div>
          </div>
        </div>

        {/* 
      <div className="card my-5">
        <div className="card-body">
          <h4 className="text-center"><b>View individual charger booking</b></h4>
          <hr className="my-4" />
        </div>
        {
          chargerData && chargerData.length > 0 ?
            (<div className="App"> <Table data={chargerData} columns={chargerColumns}></Table></div>) :
            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)

        }
      </div> */}


      </div>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    stations: state.station.fetchStation,
    chargers: state.station.fetchdashchargers,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    StationData: (viewtype) => dispatch(getStationByCreatorAction(viewtype)),
    chargerData: () => dispatch(getChargerByOwnerAction()),


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingCard);
