import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getStationByCreatorAction } from "../../../store/actions/stationAction";
import PureBreadcrumbs from "../Breadcrums"

const StationCardModel = (props) => {
  useEffect(() => {
    props.StationData("All")
  }, []);

  const commisioned = props.stations && props.stations.filter(comd => comd.status === "Available");
  const comingsoon = props.stations && props.stations.filter(coms => coms.status === "Coming soon");
  return (
    <>
      <div className="row ">
        <div className="col col-sm-4 ">
          <PureBreadcrumbs />
        </div>
      </div>



      <div className="row rowpadding justify-content-between ">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mt-2 ">
              <div className=" colpadding sideCardDesigns p-2">
                <div className=" text-center cardbg singleCard" >
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="float-right  ">
                        <img className="float-right" src={process.env.PUBLIC_URL + '/images/station_img/stationCardIcon.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                      </div>
                    </div>
                    <div className="media-body float-center mb-4" style={{ color: "#10204B" }} >
                      <span className="statsweight"><h4 style={{ fontSize: "28px", textAlign: "center", color: "#bc0078" }}><strong>{props.stations !== null ? props.stations.length : 0}</strong></h4></span>
                      <span className="stats"  > <h5 style={{ fontSize: "20px", textAlign: "center", fontWeight: "600" }}>Station</h5></span>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-md-4 mt-2 ">
              <div className="colpadding sideCardDesigns p-2">
                <div className=" text-center cardbg singleCard" >
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="float-right  ">
                        <img className="float-right" src={process.env.PUBLIC_URL + '/images/station_img/addStation.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                      </div>
                    </div>
                    <div className="media-body float-center " style={{ color: "#10204B" }} >
                      <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center", color: "#bc0078" }}><strong>Add New Station</strong></h4></span>
                      <span className="stats"  > <h5 style={{ fontSize: "12px", textAlign: "center" }}>Create new station</h5></span>
                    </div>
                    <div className="float-center">
                      <a href={`/dashboard/stations/addcharger`} className="btn btn-primary" >Add New Station</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div className="col-md-4 mt-2 ">
              <div className=" colpadding sideCardDesigns p-2">
                <div className=" text-center cardbg singleCard" >
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="float-right  ">
                        <img className="float-right" src={process.env.PUBLIC_URL + '/images/station_img/uploadimage.svg'} alt="" style={{ height: "48px", width: "48px" }} />
                      </div>
                    </div>
                    <div className="media-body float-center" style={{ color: "#10204B" }} >
                      <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center", color: "#bc0078" }}><strong>Add/Update Station Images</strong></h4></span>
                      <span className="stats"  > <h5 style={{ fontSize: "12px", textAlign: "center" }}>Upload station images.</h5></span>
                    </div>
                    <div className="float-center">
                      <a href={`/dashboard/stations/upload`} className="btn btn-primary" >Upload Station Image</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3 mt-2 ">
              <div className=" colpadding sideCardDesigns p-2">
                <div className=" text-center cardbg singleCard" >
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="float-right  ">
                        <img className="float-right" src={process.env.PUBLIC_URL + '/images/station_img/uploadimage.svg'} alt="" style={{ height: "48px", width: "48px" }} />
         
                      </div>
                    </div>
                    <div className="media-body float-center" style={{ color: "#10204B" }} >
                      <span className="statsweight"><h4 style={{ fontSize: "18px", textAlign: "center", color: "#bc0078" }}><strong>Charger Reservation</strong></h4></span>
                      <span className="stats"  > <h5 style={{ fontSize: "12px", textAlign: "center" }}>Charger Booking.</h5></span>
                    </div>
                    <div className="float-center">
                      <a href={`/dashboard/stations/booking`} className="btn btn-primary" >Charger Booking</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             */}
          </div>
        </div>
      </div>

    </>
  )
}

const mapStateToProps = function (state) {
  return {
    stations: state.station.fetchStation,
    loading: state.station.isLoading,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    StationData: (viewtype) => dispatch(getStationByCreatorAction(viewtype)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StationCardModel);