import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getSubscriptionPlanByCreatorAction } from '../../../store/actions/subscriptionAction';
import { getRFIDListAction, saveFleetRFIDAction } from '../../../store/actions/customerAction';
import "../report/table.css";
import Loader from 'react-loader-spinner';
import { useTable, useGlobalFilter, usePagination, useSortBy, useRowSelect } from "react-table";
import { Checkbox } from "../report/Checkbox";
import GlobalFilter from "../report/GlobalFilter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import StickyTable from "react-sticky-table-thead";


const FleetTable = ({ columns, data }) => {
    const {

        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        setGlobalFilter,
        allColumns,
        selectedFlatRows,
        getToggleHideAllColumnsProps,
        state: { globalFilter, pageIndex, PageSize},
    } = useTable(
        {
            columns,
            data
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
    
            ])
        }
    );


    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])
           
            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} name="rfidlist" />

                </>
            )
            
        }
    )

    const list = selectedFlatRows.map(d => d.original)
    // console.log("list",list)

 

    return (
        <>
        
            <div className="container-fluid ">
                <div className="row mt-3 mb-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <a
                                    className="btn btn-primary btn-sm"
                                    data-toggle="collapse"
                                    href="#collapseExample"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                >
                                    Select Columns &nbsp;<i className="fas fa-caret-down"></i>
                                </a>
                            </div>
                            <div className="col-md-4 text-center">
                                <GlobalFilter
                                    filter={globalFilter}
                                    setFilter={setGlobalFilter}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-3">
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="btn btn-primary btn-sm"
                                            table="location-table-to-xls"
                                            filename="Location"
                                            sheet="tablexls"
                                            buttonText="Download"
                                        />

                                    </div>
                                    <div className="col-md-6 " >
                                        <select
                                            value={PageSize}
                                            onChange={(e) => setPageSize(Number(e.target.value))}
                                        >
                                            {[10, 50, 200, 1000, 20000].map((PageSize) => (
                                                <option key={PageSize} value={PageSize}>
                                                    Show {PageSize} entries
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2" style={{height: '20px'}}>
          <div className="col">
            <div className="collapse" id="collapseExample" style={{width: 'auto'}}>
              <div className="float-left">
                <span className="pr-3">
                  <Checkbox {...getToggleHideAllColumnsProps()} /> Toggle all
                </span>
                {allColumns.map((column) => (
                  <span key={column.id} className="pr-3">
                    <label>
                      <input
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />
                      {column.header}
                    </label>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
                <div className="table-responsive">
                    <div className="customtable">
                        <StickyTable height={390}>
                            <table {...getTableProps()} id="location-table-to-xls">
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                >
                                                    {column.render("Header") }
                                                    {column.canGroupBy ? (
                                                        <span {...column.getGroupByToggleProps()}>
                                                            {column.isGrouped ? "🛑 " : "👊 "}
                                                        </span>
                                                    ) : null}
                                                    <span>
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? " 🔽"
                                                                : " 🔼"
                                                            : ""}
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render("Cell")} 
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>

                            </table>
                        </StickyTable>

                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-4 text-left">
                        {/* page number */}
                        <span>
                            Page{" "}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{" "}
                        </span>
                    </div>
                    <div className="col-md-4 text-center">
                        <span>
                            Goto Page :{""}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const pageNumber = e.target.value
                                        ? Number(e.target.value) - 1
                                        : 0;
                                    gotoPage(pageNumber);
                                }}
                                style={{ width: "50px" }}
                            />
                        </span>
                    </div>
                    <div className="col-md-4 text-right">
                        <button
                            className="btn btn-primary btn-sm mr-2"
                            onClick={() => {
                                gotoPage(0);
                            }}
                            disabled={!canPreviousPage}
                        >
                            {"<<"}
                        </button>
                        <button
                            className="btn btn-primary btn-sm mr-2"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            Previous
                        </button>
                        <button
                            className="btn btn-primary btn-sm mr-2"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            Next
                        </button>
                        <button
                            className="btn btn-primary btn-sm mr-2"
                            onClick={() => {
                                gotoPage(pageCount - 1);
                            }}
                            disabled={!canNextPage}
                        >
                            {">>"}
                        </button>
                    </div>
                </div>
                {/* {data && <FleetGroupRFID getRFIDdata={{ data }}> </FleetGroupRFID>} */}
                {/* <pre>
            <code>
              {JSON.stringify(
                {
                  selectedRowIds: selectedRowIds,
                  selectedFlatRows: selectedFlatRows.map(
                    d => d.original
                  ),
                },
                null,
                2
              )}
              
            </code>
          </pre> */}
            </div>
        </>
    );
}


const FleetGroupRFID = (props) => {

        // console.log("selectedFlatRows",props)
    // console.log("props.fleetdata.props===>",props.getaRFIDdata);
    useEffect(() => {
        const { planname } = JSON.parse(localStorage.getItem('user'))
        let pname = planname
        if (pname === "") {
            pname = "All"
        }
        props.fetSubscriptionplan(pname);

        props.getRFIDdata();
    }, []);

    const [fleetData, setFleetData] = useState();
    const [fleetList, setfleetList] = useState([]);

    const fleetdata = props.rfidlist && props.rfidlist

    // console.log("list down",list)
    const columns = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstname'
            },

            {
                Header: 'RFID Number',
                accessor: 'rfidno'
            },

            {
                Header: 'Tag UID',
                accessor: 'taguid'
            },
            {
                Header: 'Key UID',
                accessor: 'keyuid'
            },
            {
                Header: 'TopUp Amount',
                accessor: 'topup'
            },
        ],
        []
    );

    const handleChange = (e) => {
        setFleetData({
            ...fleetData,
            [e.target.name]: e.target.value.trim(),
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("Fleet RFID Added");
        // console.log("Save RFID FLEET", fleetData, fleetList);
        props.saveFleetRFID(fleetData, fleetList);
    };

    return (

        <div className="container-fluid">
            <form className="form" onSubmit={handleSubmit} autoComplete="off">
                <div className='row'>
                    <div className='col'>
                        <div className="card">
                            <div className="card-header">
                                <div className="col-md-9 text-left">
                                    Select Fleet to bind RFID
                                </div>

                                <div className="col text-right">
                                    <select className="form-control" id="name" name="name" required onChange={handleChange}>
                                        <option value="" >Select Fleet</option>
                                        {props.sublistdata && props.sublistdata.slice(1,).map(planname => {
                                            return planname !== "Pay as you go" && planname.category === "Fleet" ?
                                                <option key={planname.id} value={planname.name} >{planname.name}</option> : ''
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className="col-md-12 col-lg-12">
                        <div className="mb-3 h-100 card">
                            <div className="card-header">
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                    <strong>Location Details</strong>
                                </div>
                            </div>

                            {!fleetdata.length ?
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                                :
                                (<div className="App"> <FleetTable data={fleetdata} columns={columns} > </FleetTable></div>)}

                        </div>
                    </div>

                </div>

                <div className="row mt-3">
                    <div className="col-xl-6 col-sm-6 col-12">
                        <a
                            className="btn btn-primary btn-pill btn-wide text-uppercase float-left"
                            href="/dashboard/fleet"
                        >
                            Back
                        </a>
                    </div>

                    <div className="col-xl-6 col-sm-6 col-12 float-right">
                        <button className="btn btn-primary btn-pill btn-wide text-uppercase float-right">
                            {!props.loading ? "Save Group Fleet RFID" : "Please wait..."}
                        </button>
                    </div>
                    <hr className="my-4" />
                </div>
            </form>

        </div>
    )


}


const mapStateToProps = function (state) {
    return {
        loading: state.fleet.isLoading,
        sublistdata: state.subscriptionplan.fetcSubscriptionplan,
        rfidlist: state.customer.fetchAllRfid,
        savegrouprfid: state.customer.savefleetrfid,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetSubscriptionplan: (planname) => dispatch(getSubscriptionPlanByCreatorAction(planname)),
        getRFIDdata: () => dispatch(getRFIDListAction()),
        saveFleetRFID: (rfid, rfidlist) => dispatch(saveFleetRFIDAction(rfid, rfidlist))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(FleetGroupRFID);
