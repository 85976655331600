import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { getconnectorTypeListAction } from "../../../store/actions/ctAction";
import { getLocationAction } from "../../../store/actions/locationAction";
import { networkEnterpriseOperatorAction, operatorAction } from "../../../store/actions/opAction";
import { updateStationAction } from "../../../store/actions/stationAction";
import { getSingleAssetActions } from "../../../store/actions/assetAction";
import "./station.css";
import { withRouter } from "react-router-dom";
import { editStationAction } from "../../../store/actions/stationAction";
import Swal from 'sweetalert2'

const CreateChargeStation = (props) => {
  const { firstname } = JSON.parse(localStorage.getItem("user"));
  //const [inputList, setInputList] = useState([{ stationid: "", name: "", operator: "", enterprise: "", location: "", visibility: "", projectname: "", commisiondate: "", status: "", createdby: "", install: "", fleet: "", enterdby: "" }]);
  const [inputchargerList, setInputchargerList] = useState([{ stationid: "",chargername: "", chargeboxid: "", chargerserialno: "", make: "", model: "", compatibilty: "", powertype: "", chargertype: "", protocol: "", install: "", fleet: "", connectors: {} }]);
  const [inputConnectorList, setInputConnectorList] = useState([{ stationid: "",chargeboxid: "", connectorname: "", connectortype: "", powertype: "", power: "", connectorno: "" }]);
  const [inputImagesList, setImagesList] = useState([{ file: "" }]);
  const [stationList, setStationList] = useState({ stationid: "", name: "", operator: "", enterprise: "", location: "", visibility: "", projectname: "", commisiondate: "", status: "", install: "", fleet: "", enterdby: "" });
 const { stationid,location} = useParams()


  useEffect(() => {
    props.npData("Network Operator");
    props.getLocation();
    props.getConnectors();
    props.getSingleAsset()
    props.GetStationByName(stationid)
  }, [])

  useEffect(() => {
    const conn = props.stationlist && props.stationlist.chargers
    const zxc = conn && conn.map(en => en.connectors)
    const data = Object.values(zxc).flatMap(arr => arr)
    setInputchargerList(data)
  }, [props.stationlist.chargers]);

  // useEffect(() => {
  //   setInputList(props.stationlist)
  // }, [props.stationlist]);

  useEffect(()=>{
    setStationList(props.stationlist);
    //var tempArry = [];

    const conn = props.stationlist && props.stationlist.chargers
    setInputchargerList(conn)
  },[props.stationlist.chargers])

  useEffect(() => {
    const conn = props.stationlist && props.stationlist.chargers
    const zxc = conn && conn.map(en => en.connectors)
    const data = Object.values(zxc).flatMap(arr => arr)
    setInputConnectorList(data)
  }, [props.stationlist.chargers]);

  
  const handleChange = (e) => {
    setStationList({
      ...stationList,
      [e.target.name]: e.target.value.trim()

    });


    
    if (e.target.name === "operator") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index]
      let createdby = el.getAttribute('id');
      props.epData(createdby, "Enterprise")
    }
  }
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputchargerList];
    list[index][name] = value;
    setInputchargerList(list);
 

  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {

    //e.preventDefault()

    const list = [...inputchargerList];
    list.splice(index, 1);
    setInputchargerList(list);
  };

  const handleConnectorInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputConnectorList];
    list[index][name] = value;
    setInputConnectorList(list);

  };
  const handleConnectorRemoveClick = (index) => {
    // e.preventDefault()
    const list = [...inputConnectorList];
    list.splice(index, 1);
    setInputConnectorList(list);
  };


  // handle click event of the Add button
  const
    handleAddClick = () => {
      setInputchargerList([...inputchargerList, { chargername: "", chargeboxid: "", chargerserialno: "", make: "", model: "", compatibilty: "", powertype: "", chargertype: "", protocol: "" }]);
    };

  const handleConnectorAddClick = () => {
    setInputConnectorList([...inputConnectorList, { chargeboxid: "", connectorname: "", connectortype: "", powertype: "", power: "", connectorno: "" }]);
  };
  // const handleImageAddClick = () => {
  //   setImagesList([...inputImagesList, { file: ""}]);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('New Station Commissioned');
    props.updateStationAction(stationList, inputchargerList, inputConnectorList, inputImagesList);
    // return <Redirect to={"/dashboard/station"}></Redirect>
    // window.location("/dashboard/station");

    Swal.fire({
      text: "Station upldated successfully",
      icon: 'success',

    }).then((result) => {
      if (result.value) {
        window.location.href = '/dashboard/stations';
      }
    });
  
  
  }



  return (
    <div className="container-fluid">
      <div className="sideCardDesigns card-signin my-5">
        <div className="card-body">
          <h4 className="text-center formHeading"><b>Edit Charging Station </b></h4>

          <form className="form mt-5" onSubmit={handleSubmit} autoComplete="off">

            <div className="row rowpadding  justify-content-between">
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer">
                  <div className="col-12">
                    <label className="formLable" htmlFor="stationid">Station ID</label>
                    <div className="form-label-group">
                      <input type="text" id="stationid" name="stationid" className="form-control control-label" placeholder="Station ID" required onChange={handleChange} value={stationList.stationid} disabled/>
                      <label htmlFor="stationid">Station ID</label>
                    </div>
                    
                    <label className="formLable" htmlFor="name">Station Name</label>
                    <div className="form-label-group">
                      <input type="text" id="name" name="name" className="form-control control-label" placeholder="Station Name" required onChange={handleChange} value={stationList.name} />
                      <label htmlFor="name">Station Name</label>
                    </div>
                    <label className="formLable" htmlFor="operator">Network Operator</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="operator" name="operator" required onChange={handleChange} value={stationList.operator}>
                        <option>{props.stationlist.operator}</option>
                        {props.operatorlist && props.operatorlist.map(network =>
                          <option key={network.id} id={network.id} value={network.organization}>{network.organization}</option>
                        )}
                      </select>
                    </div>



                    <label className="formLable" htmlFor="enterprise">Enterprise</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="enterprise" name="enterprise" required onChange={handleChange} value={stationList.enterprise}>
                        <option value="">{props.stationlist.enterprise}</option>
                        {props.enterpriselist === null ? <option value="none">none</option> : props.enterpriselist && props.enterpriselist.map(enterprise =>
                          <option key={enterprise.id} value={enterprise.organization}>{enterprise.organization}</option>
                        )}
                      </select>
                    </div>
                    <label className="formLable" htmlFor="location">Location Name</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius selectpicker" id="location" name="location" data-live-search="true" required onChange={handleChange} value={stationList.location}>
                        <option value="">Select Location</option>
                        {props.locations === null ? <option value="none">none</option> : props.locations && props.locations.map(location =>
                          <option key={location.id} value={location.id}>{location.name}</option>
                        )}
                      </select>
                    </div>
                    <label className="formLable" htmlFor="visibility">Visibility</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="visibility" name="visibility" required onChange={handleChange} value={stationList.visibility}>
                        <option value="">Select Visibility</option>
                        <option>Public</option>
                        <option>Private</option>
                        <option>Captive</option>
                      </select>
                    </div>



                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="col-12  formContainer ">

                  <div className="col-12 ">

                    <label className="formLable" htmlFor="commisiondate">Commisioned Date</label>
                    <div className="form-label-group">
                      <input className="form-control" type="date" id="commisiondate" name="commisiondate" required onChange={handleChange}  value={stationList.commisiondate}/>
                      <label htmlFor="commisiondate">Commisioned Date</label>
                    </div>

                    <label className="formLable" htmlFor="status">Status</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="status" name="status" required onChange={handleChange} value={stationList.status}>
                        <option value="">Status</option>
                        <option>Available</option>
                        <option>offline</option>
                        <option>Maintenance</option>
                        <option>Coming soon</option>
                      </select>
                    </div>
                    <label className="formLable" htmlFor="install">Install</label>
                    {/* <div className="form-label-group">
                      <select className="form-control border-radius" id="install" name="install" required onChange={handleChange} value={inputchargerList.install}>
                        <option value="">Install</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <label className="formLable" htmlFor="fleet">Fleet</label>
                    <div className="form-label-group">
                      <select className="form-control border-radius" id="fleet" name="fleet" required onChange={handleChange} value={inputchargerList.fleet}>
                        <option value="">Fleet</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div> */}
                    <label className="formLable" htmlFor="projectname">Project Name</label>
                    <div className="form-label-group">
                      <input type="text" id="projectname" name="projectname" className="form-control control-label" placeholder="Project Name" required onChange={handleChange} value={stationList.projectname} />
                      <label htmlFor="projectname">Project Name</label>
                    </div>
                    <label className="formLable" htmlFor="enteredby">Entered By</label>
                    <div className="form-label-group ">
                      <input
                        type="text"
                        name="enteredby"
                        className="form-control control-label"
                        value={firstname}
                        disabled
                      />
                      <label htmlFor="enterdby">Data Entered By</label>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">Add Chargers</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">BoxId</th>
                        <th scope="col">Serial No</th>
                        <th scope="col">Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">Compatibility</th>
                        <th scope="col">Type</th>
                        <th scope="col">Charger</th>
                        <th scope="col">Protocol</th>
                        <th scope="col">Fleet</th>
                        <th scope="col">Install</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputchargerList && inputchargerList.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td><input type="text" name="chargername" placeholder="Name" value={x.chargername} onChange={e => handleInputChange(e, i)} /></td>
                            <td><input type="text" name="chargeboxid" placeholder="Charger BoxId" value={x.chargeboxid} onChange={e => handleInputChange(e, i)} /></td>
                            <td><input type="text" name="chargerserialno" placeholder="Serial No.(Machine)" value={x.chargerserialno} onChange={e => handleInputChange(e, i)} /> </td>
                            <td><input type="text" name="make" placeholder="Make" value={x.make} onChange={e => handleInputChange(e, i)} /> </td>
                            <td><input type="text" name="model" placeholder="Model" value={x.model} onChange={e => handleInputChange(e, i)} /> </td>
                            <td className="multiselect">
                              <input type="checkbox" name="Compatibility" value={x.compatibilty} onChange={e => handleInputChange(e, i)} />e-3w <br />
                              <input type="checkbox" name="Compatibility" value={x.compatibilty} onChange={e => handleInputChange(e, i)} />e-4w <br />
                              <input type="checkbox" name="Compatibility" value={x.compatibilty} onChange={e => handleInputChange(e, i)} />e-bus

                            </td>
                            <td>
                              <select className="border-radius" name="powertype" onChange={e => handleInputChange(e, i)} value={x.powertype} >
                                <option>{x.powertype}</option>
                                <option>AC</option>
                                <option>DC</option>
                              </select>
                            </td>
                            <td>
                              <select className="border-radius" name="chargertype" onChange={e => handleInputChange(e, i)} value={x.chargertype}>
                                <option>{x.chargertype}</option>
                                <option>IEC60309</option>
                                <option>Type 2</option>
                                <option>GBT</option>
                                <option>CCS2</option>
                                <option>CHAdeMO</option>
                                <option>GBT2</option>

                              </select>
                            </td>

                            <td>
                              <select className="border-radius" name="protocol" onChange={e => handleInputChange(e, i)} value={x.protocol}>
                                <option value="">{x.protocol}</option>
                                <option>OCPP1.6J</option>
                                <option>OCPP2.0.1</option>
                                <option>Non OCPP</option>
                              </select>
                            </td>
                                                        <td>
                              <select className="border-radius" name="fleet" id="install" onChange={e => handleInputChange(e, i)} value={x.fleet}>
                                <option>{x.fleet}</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </td>
                            <td>
                              <select className="border-radius"  id="install" name="install" onChange={e => handleInputChange(e, i)} value={x.install}>
                                <option>{x.install}</option>
                                <option>Yes</option>
                                <option>No</option>

                              </select>
                            </td>
                            <td>
                              {inputchargerList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e) => handleRemoveClick(i)}>-</button>}
                              {inputchargerList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleAddClick}>+</button>}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">Add Connector</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Charger No</th>
                        <th scope="col">Connector Name</th>
                        <th scope="col">Connector Type</th>
                        <th scope="col">Power Type</th>
                        <th scope="col">Rating(In kW)</th>
                        <th scope="col">Connector No(Machine)</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>

                      {inputConnectorList.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <select className="border-radius" name="chargeboxid" onChange={e => handleConnectorInputChange(e, i)} >
                                <option value="">  {x.chargeboxid}</option>
                                {inputchargerList !== -1 && inputchargerList.map((il) => {
                                  return (
                                    <option>{il.chargeboxid}</option>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              <select className="border-radius" name="connectorname" onChange={e => handleConnectorInputChange(e, i)}>
                                <option value=""> {x.connectorname}</option>
                                <option>A</option>
                                <option>B</option>
                                <option>C</option>
                                <option>D</option>
                                <option>E</option>
                                <option>F</option>
                                <option>G</option>
                                <option>H</option>
                                <option>I</option>
                                <option>J</option>
                                <option>K</option>
                                <option>L</option>
                              </select>
                            </td>
                            <td>
                              <select className="border-radius" name="connectortype" onChange={e => handleConnectorInputChange(e, i)}>
                                <option value="">{x.connectortype}</option>
                                {props.conectortypes && props.conectortypes.map((ct) => {
                                  return <option>{ct.connectortype}</option>
                                })}
                              </select>
                            </td>
                            <td>
                              <select className="border-radius" name="powertype" onChange={e => handleConnectorInputChange(e, i)}>
                                <option value="">{x.powertype}</option>
                                <option>AC</option>
                                <option>DC</option>
                              </select>
                            </td>
                            <td>
                              <input type="text" name="power" placeholder="Power" onChange={e => handleConnectorInputChange(e, i)} value={x.power} />

                            </td>
                            <td>
                              <input type="text" name="connectorno" placeholder="Connector No." onChange={e => handleConnectorInputChange(e, i)} value={x.connectorno} />
                            </td>
                            <td>
                              {inputConnectorList.length !== 1 && <button type="button" className="btn1 btn-outline-danger btn-sm py-1" onClick={(e) => handleConnectorRemoveClick(i)}>-</button>}
                              {inputConnectorList.length - 1 === i && <button type="button" className="btn1 btn-outline-dark btn-sm py-1" onClick={handleConnectorAddClick}>+</button>}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>



            <div className="row">
              <div className="col">
                {props.response && props.response.error != '' ? <div className="alert alert-danger" role="alert">
                  {props.response.error}
                </div> : ''}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-lg-3 ">
                <a className="btn columnsBtn btn-pill btn-wide text-uppercase" href="/dashboard/stations">Cancel</a>
              </div>
              <div className="col-md-9 col-lg-9 ">
                <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"  >{!props.loading ? 'Commission Charger' : 'Please wait...'}</button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}
const mapStateToProps = function (state) {
  return {
    operatorlist: state.operator.fetchOperator,
    enterpriselist: state.operator.fetchEnterprise,
    pricelistdata: state.price.fetchPriceList,
    locations: state.location.fetchLocation,
    conectortypes: state.ct.fetchConnectorTypeList,
    loading: state.station.isLoading,
    response: state.station.saveStationResponse,
    assetsingledetail: state.asset.fetchSingleAsset,
    stationlist: state.station.editstationbyname,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    npData: (nrole) => dispatch(operatorAction(nrole)),
    epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
    getLocation: () => dispatch(getLocationAction()),
    getConnectors: () => dispatch(getconnectorTypeListAction()),
    updateStationAction: (station, chargers, connectors) => dispatch(updateStationAction(station, chargers, connectors)),
    getSingleAsset: (assetid) => dispatch(getSingleAssetActions(assetid)),
    GetStationByName: (stationid, location) => dispatch(editStationAction(stationid, location)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateChargeStation);