import React, { useEffect, useState } from "react";
import { countryAction } from "../../../../store/actions/commonAction";
import { stateAction } from "../../../../store/actions/commonAction";
import { cityAction } from "../../../../store/actions/commonAction";
import { getLocationAction } from "../../../../store/actions/locationAction";
import { CUSTOMER_BASE_URL, SAVE_RFID_URL } from "../../../config/configs";
import { saveRfidExcelActions } from "../../../../store/actions/customerAction";
import { getPriceByCreatorAction } from "../../../../store/actions/priceAction"
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { Redirect, useParams,useHistory  } from "react-router-dom";
import axios from "axios";
import { networkEnterpriseOperatorAction, operatorAction } from "../../../../store/actions/opAction";
import { getSingleRFDIActions, editRfid } from "../../../../store/actions/customerAction";

const EditRfid = (props) => {
    const [data, setData] = useState({ firstname: "", lastname: "", mobile: "", emailid: "", idprof: "", idprofnumber: "", rfidno: "", rfidissuedate: "", taguid: "", keyuid: "", country: "", price: "", state: "", city: "", status: "", topup: "", address: "", usedamt: "", current: "" });
    toast.configure()
    const history = useHistory();
    const { rfid } = useParams();
    useEffect(() => {
        props.npData("Network Operator");
        props.countrylist();
        props.fetPrice()
        props.getSingleRFID(rfid);
    }, []);

    useEffect(() => {
        setData(props.rfidsingledetail)
    }, [props.rfidsingledetail]);

    console.log("data",data)
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "networkoperator") {
            let index = e.target.selectedIndex;
            let el = e.target.childNodes[index];
            let createdby = el.getAttribute("id");
            props.epData(createdby, "Enterprise");
        }
        if (e.target.name === "country") {
            props.statelist(e.target.value);
        }
        if (e.target.name === "state") {
            props.citylist(e.target.value);
        }

    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
    };


    const handleRfidSubmit = (e) => {
        e.preventDefault()
        props.editrfidByID(rfid, data);
        history.push("/dashboard/assets");
    }

    const notify = () => {
        toast("RFID Details Updated Successfully");
    };

    return (
        <div className="container-fluid">
            <div className="sideCardDesigns card-signin my-5">
                <div className="card-body">
                    <h4 className="text-center formHeading"><b>Edit RFID</b></h4>

                    <form className="form mt-5" onSubmit={handleRfidSubmit} autoComplete="off">

                        <div className="row rowpadding  justify-content-between">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">
                                        <label className="formLable" htmlFor="firstname">First Name</label>
                                        <div className="form-label-group required">
                                            <input type="text" id="firstname" name="firstname" className="form-control control-label" placeholder="First Name" onChange={handleChange} value={data.firstname} />
                                            <label htmlFor="firstname">First Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="lastname">Last Name</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="lastname" name="lastname" className="form-control control-label" placeholder="Last Name" onChange={handleChange} value={data.lastname} />
                                            <label htmlFor="lastname">Last Name</label>
                                        </div>

                                        <label className="formLable" htmlFor="mobile">Mobile Number</label>
                                        <div className="form-label-group " >
                                            <input type="number" id="mobile" name="mobile" className="form-control control-label" placeholder="Mobile Number" maxLength="10" onInput={maxLengthCheck} onChange={handleChange} value={data.mobile} />
                                            <label htmlFor="mobile">Mobile Number</label>
                                        </div>

                                        <label className="formLable" htmlFor="emailid">Email ID</label>
                                        <div className="form-label-group ">
                                            <input type="email" id="emailid" name="emailid" className="form-control control-label" placeholder="Email ID" onChange={handleChange} value={data.emailid} />
                                            <label htmlFor="emailid">Email ID</label>
                                        </div>

                                        <label className="formLable" htmlFor="idprof">ID Proof</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" id="idprof" name="idprof" onChange={handleChange} value={data.idprof}>
                                                <option>{data.idprof}</option>
                                                <option>Driving License</option>
                                                <option>Voter Id Card</option>
                                                <option>Passport No.</option>
                                                <option>Pan Card</option>
                                                <option>Aadhar Card No</option>
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="idprofnumber">ID Proof Number</label>
                                        <div className="form-label-group " >
                                            <input type="text" id="idprofnumber" name="idprofnumber" className="form-control control-label" placeholder="ID Proof No" onChange={handleChange} value={data.idprofnumber} />
                                            <label htmlFor="idprofnumber">ID Proof Number</label>
                                        </div>

                                        <label className="formLable" htmlFor="rfidno">RFID number</label>
                                        <div className="form-label-group required ">
                                            <input type="text" id="rfidno" name="rfidno" className="form-control control-label" placeholder="RFID No" onChange={handleChange} value={data.rfidno} />
                                            <label htmlFor="rfidno">RFID number</label>
                                        </div>

                                        <label className="formLable" htmlFor="rfidissuedate">RFID Issue Date</label>
                                        <div className="form-label-group required ">
                                            <input className="form-control" type="date" id="rfidissuedate" name="rfidissuedate" onChange={handleChange} value={data.rfidissuedate} />
                                            <label htmlFor="rfidissuedate">RFID Issue Date</label>
                                        </div>

                                        <label className="formLable" htmlFor="taguid">TAG-UID</label>
                                        <div className="form-label-group required ">
                                            <input type="text" id="taguid" name="taguid" className="form-control control-label" placeholder="TAG-UID(authorization and authentication)" onChange={handleChange} value={data.taguid} />
                                            <label htmlFor="taguid">TAG-UID</label>
                                        </div>

                                        <label className="formLable" htmlFor="keyuid">KEY-UID</label>
                                        <div className="form-label-group required ">
                                            <input type="text" id="keyuid" name="keyuid" className="form-control control-label" placeholder="KEY-UID(authorization and authentication)" onChange={handleChange} value={data.keyuid} />
                                            <label htmlFor="keyuid">KEY-UID</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="country">Country</label>
                                        <div className="form-label-group  ">
                                            <select className="form-control border-radius" name="country" onChange={handleChange} value={data.country}>
                                                <option>{data.country}</option>
                                                {props.countries && props.countries.map((country) => <option key={country.code} value={country.code}>{country.name}</option>)}
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="state">State</label>
                                        <div className="form-label-group  ">
                                            <select className="form-control border-radius" name="state" onChange={handleChange} value={data.state}>
                                                <option>{data.state}</option>
                                                {props.states && props.states.map((st) => <option key={st.code} value={st.name}>{st.name}</option>)}
                                            </select>
                                        </div>

                                        <label className="formLable" htmlFor="city">City</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="city" onChange={handleChange} value={data.city}>
                                                <option>{data.city}</option>
                                                {props.cities && props.cities.map((c) => <option key={c.id} value={c.name}>{c.name}</option>)}
                                            </select>
                                        </div>


                                        <label className="formLable" htmlFor="status">Status</label>
                                        <div className="form-label-group ">
                                            <select className="form-control border-radius" name="status" onChange={handleChange} value={data.status}>
                                                <option>{data.status}</option>
                                                <option>Activated</option>
                                                <option>Deactivated</option>
                                            </select>
                                        </div>



                                        <label className="formLable" htmlFor="address">Address</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="address" name="address" className="form-control" placeholder="Address" onChange={handleChange} value={data.address} />
                                            <label htmlFor="address">{data.address}</label>
                                        </div>

                                        <label className="formLable" htmlFor="price">Select Plan Price </label>
                                        <div className="form-label-group required ">
                                            <select className="form-control border-radius" name="price" onChange={handleChange} value={data.price}>
                                                <option value="">{data.price} </option>
                                                {props.pricelistdata && props.pricelistdata.map(p =>
                                                    <option id={p.id} value={p.name}>{p.name}</option>
                                                )}
                                            </select>

                                        </div>
                                        <label className="formLable" htmlFor="topup">Top Up Amount</label>
                                        <div className="form-label-group ">
                                            <input type="text" id="topup" name="topup" className="form-control" placeholder="Top Up Amount" onChange={handleChange} value={data.topup} />
                                            <label htmlFor="topup">Top Up Amount</label>
                                        </div>
                                        <label className="formLable" htmlFor="usedamt">Used Amount</label>
                                        <div className="form-label-group">
                                            <input type="text" id="usedamt" name="usedamt" className="form-control" placeholder="Used Amount" onChange={handleChange} disabled value={data.usedamt} />
                                            <label htmlFor="usedamt">Used Amount</label>
                                        </div>
                                        <label className="formLable" htmlFor="current">Current Balance</label>
                                        <div className="form-label-group">
                                            <input type="text" id="current" name="current" className="form-control" placeholder="Used Amount" onChange={handleChange} disabled value={data.current} />
                                            <label htmlFor="current">Current Balance</label>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-lg-3 ">
                                <a
                                    className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                    href="/dashboard/customers"
                                >
                                    Cancel
                                </a>
                            </div>
                            <div className="col-md-9 col-lg-9 ">
                                <button
                                    className="btn columnsBtn btn-pill btn-wide text-uppercase float-right" onClick={notify}
                                >
                                    Update RFID
                                </button>
                            </div>
                        </div>
                    </form>



                </div>
            </div>
        </div>
    )
}


const mapStateToProps = function (state) {
    return {
        loading: state.customer.isLoading,
        operatorlist: state.operator.fetchOperator,
        enterpriselist: state.operator.fetchEnterprise,
        locations: state.location.fetchLocation,
        countries: state.common.countryList,
        states: state.common.stateList,
        cities: state.common.cityList,
        rfidResponse: state.customer.saveRfid,
        pricelistdata: state.price.fetchPriceList,
        rfidsingledetail: state.customer.fetchSingleRFID,
        editrfidbyid: state.customer.edisignlerfid,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        npData: (nrole) => dispatch(operatorAction(nrole)),
        epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
        getLocation: () => dispatch(getLocationAction()),
        countrylist: () => dispatch(countryAction()),
        statelist: (countrycode) => dispatch(stateAction(countrycode)),
        citylist: (statecode) => dispatch(cityAction(statecode)),
        fetPrice: () => dispatch(getPriceByCreatorAction()),
        getSingleRFID: (id) => dispatch(getSingleRFDIActions(id)),
        editrfidByID: (rfid, data) => dispatch(editRfid(rfid, data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditRfid);
