import React from "react"
import CustomerCard from "./customercard"
import CustomerView from "./customerlist"
import RFIDTableView from "../assets/rfid/viewRfid"
import CustomerWalletlist from "./customerWalletlist"
import CustomerOTPView from "./customerotp"
import Configurerlist from "./configurerlist"

const CustomerLandingPage=()=>{
    const userrole = JSON.parse(localStorage.getItem('user'));

    return(
        <>
        <div className="container-fluid">
            <CustomerCard></CustomerCard>
            <CustomerView></CustomerView>
            <Configurerlist></Configurerlist>
            <CustomerWalletlist></CustomerWalletlist>
            {userrole.role === "Admin" ? (
            <CustomerOTPView></CustomerOTPView>
            ) : (<span ></span>)
        }
            <RFIDTableView></RFIDTableView>
        </div>
        </>
    )
}
export default CustomerLandingPage;