

import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PureBreadcrumbs from "../../Breadcrums"
import { getSingleHubPower, getAllHubListData } from '../../../../store/actions/hubActions';
import Table from "./powerstatusTable";
import "../../report/report.css"
import "../../report/table.css"
import moment from "moment";


const ManageHubPower = (props) => {
    const initialValues = { hubname: "" }
    const [singlehubname, setSinglehubname] = useState(initialValues)

    useEffect(() => {
        let hubb = "All"
        props.getHubListData()
        if(hubb == "All"){
            props.getSingleHubPower(hubb);
        }
    }, []);

    const handleChange = (e) => {
        let hubname = e.target.value
        // console.log("hubnameee", hubname)
        const { name, value } = e.target;
        setSinglehubname({ ...singlehubname, [name]: value });
        if (e.target.value !== "" && e.target.value !== undefined) {
            props.getSingleHubPower(hubname);
        }
    };

    // const TimeCalculate = (StartTime, EndTime)=>{

    //     let start_time = moment(StartTime, ' h:mm:ss A');
          
      
    //     let end_time = moment(EndTime, ' h:mm:ss A');

    //     return (Math.floor((moment.duration(end_time.diff(start_time).asHours())) + ":" + (moment.duration(end_time.diff(start_time).asHours()).minutes())))
    //     return (Math.floor((moment.duration(moment(EndTime, 'hh:mm:ss A').diff(moment(StartTime, ' hh:mm:ss A'))).asHours()) || 0) + ":" + (moment.duration(moment(EndTime, ' hh:mm:ss A').diff(moment(StartTime, 'hh:mm:ss A'))).minutes() || 0))

    //     var duration = moment.duration(end_time.diff(start_time));

    //     return (Math.floor(duration.asHours())+ ':' + Math.floor(duration.asMinutes()));
      
    //     var TotalTime = end_time
    //       .diff(start_time,'seconds');
          
    //     var hours = Math.floor(TotalTime / 3600);
    //     var minutes = Math.floor((TotalTime / 60) % 60);
    //     console.log(`${start_time.format('DD-MM-YYYY h:mm:ss A')}:${end_time.format('DD-MM-YYYY h:mm:ss A')} hours: ${hours} minutes: ${minutes}`);
    // }


    // const UptimeCalculate = (first, second)=>{
    //     const TotalTime = 24:
    //     return TotalTime
    // }

    const data = props.fetchSingleHubPower && props.fetchSingleHubPower
    console.log("dbhjds", data)

    const columns = useMemo(

        () => [
            {
                header: 'Hub Name',
                accessor: 'hubname'
            },
            {
                header: 'Power Cut Date',
                accessor: 'date'
            },
            {
                header: 'Power Cut Time',
                accessor: 'powercuttime'
            },
            {
                header: 'Power Restored Date',
                accessor: 'restoredate'
            },

            {
                header: 'Power Restored Time',
                accessor: 'powerrestoredtime'
            },
            {
                header: 'Down Time',
                id: 'downtime',
                accessor: data => {
                    return (Math.floor((moment.duration(moment(`${data.restoredate} ${data.powerrestoredtime}`, 'DD-MM-YYYY hh:mm:ss A').diff(moment(`${data.date} ${data.powercuttime}`, 'DD-MM-YYYY hh:mm:ss A'))).asHours()) || 0) + ":" + (moment.duration(moment(`${data.restoredate} ${data.powerrestoredtime}`, 'DD-MM-YYYY hh:mm:ss A').diff(moment(`${data.date} ${data.powercuttime}`, 'DD-MM-YYYY hh:mm:ss A'))).minutes() || 0))
                }
            },
            // {
            //     header: 'Down Time',
            //     id: 'downtime',
            //     accessor: data => {
            //         return (Math.floor((moment.duration(moment(` ${data.powerrestoredtime}`, 'hh:mm:ss A').diff(moment(` ${data.powercuttime}`, ' hh:mm:ss A'))).asHours()) || 0) + ":" + (moment.duration(moment(` ${data.powerrestoredtime}`, ' hh:mm:ss A').diff(moment(`${data.powercuttime}`, 'hh:mm:ss A'))).minutes() || 0))
            //     }
            // },
            // {
            //     header: 'Up Time (HH:MM)',
            //     id: 'uptime',
            //     accessor: data =>{
            //         return ( Math.floor(24-(moment.duration(moment(` ${data.powerrestoredtime}`, ' hh:mm:ss A').diff(moment(`${data.powercuttime}`, 'hh:mm:ss A'))).asHours() || 0))+":"+(moment.duration(moment('24:00  ', 'hh:mm:ss A').diff(moment((((moment.duration(moment(` ${data.powerrestoredtime}`, 'hh:mm:ss A').diff(moment(` ${data.powercuttime}`, ' hh:mm:ss A'))).asHours()) || 0) + ":" + (moment.duration(moment(` ${data.powerrestoredtime}`, ' hh:mm:ss A').diff(moment(`${data.powercuttime}`, 'hh:mm:ss A'))).minutes() || 0)), 'hh:mm:ss A')))).minutes())
            //     }
            // },
            {
                header: 'Entry By',
                accessor: 'entryby'
            },
          
        ],
        []);


        
  

    const hubList = props.fetchHubListData && props.fetchHubListData.map(id=> id.hubname)

    


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col sideCardDesigns mb-2">
                    
                        <div className="card-header singleCard justify-content-between mb-3">
                            <div className="text-left">
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{color:"#10204B"}}>Hub Power Status Details</strong>
                        </div>
                            </div>
                            <div className=" text-right">
                                <select className="columnsBtn p-2" name="hubname" onChange={handleChange}>
                                <option value="All" >All</option>
                                    {hubList && hubList.map((hub) => (
                                        <option key={hub.id} value={hub} >{hub}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col">
                                <div className="row ">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="mb-3 h-100 singleCard">
                                            {data && data.length > 0 ? (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                                :
                                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#1e295d", fontWeight: "600", fontSize: "30px", marginTop: "30px" }}>No Data Available</div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}



const mapStateToProps = function (state) {
    return {
        fetchHubListData: state.hub.getAllHubListData,
        fetchSingleHubPower: state.hub.getHubPowerDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getHubListData: () => dispatch(getAllHubListData()),
        getSingleHubPower: (hubname) => dispatch(getSingleHubPower(hubname)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageHubPower);









































