import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getLocationAction } from "../../../../store/actions/locationAction";
import { networkEnterpriseOperatorAction, operatorAction, } from "../../../../store/actions/opAction";
import { savehublistAction } from "../../../../store/actions/hubActions";
import dashboard from "../../assets/excel/hublist.xlsx";
import * as XLSX from "xlsx";

const HubNameListMaster = (props) => {
    const { firstname } = JSON.parse(localStorage.getItem("user"));
    const [utilityList, setUtilityList] = useState();
    useEffect(() => {
        props.npData("Network Operator");
        props.getLocation();
    }, []);
    const handleChange = (e) => {
        setUtilityList({
            ...utilityList,
            [e.target.name]: e.target.value.trim(),
        });
        if (e.target.name === "network_operator") {
            let index = e.target.selectedIndex;
            let el = e.target.childNodes[index];
            let createdby = el.getAttribute("id");
            props.epData(createdby, "Enterprise");
        }
    };

    // console.log("utilityList", utilityList)
    const handleSubmit = (e) => {
        e.preventDefault();
      
        if (props.response && props.response.error) {
          alert("Hub already exists on this location!");
        } else if (utilityList && utilityList.stationid) {
          if (props.response) {
            alert("New Hub Added");
          }
          props.commisionUtility(utilityList);
        } else {
          alert("Please select a location");
        }
      };
      
    return (
        <div className="container-fluid">
            <div className="sideCardDesigns card-signin">
                <div className="card-body">
                    <h4 className="text-center formHeading">
                        <b >Create New Hub</b>
                    </h4>
                    <form className="form mt-4" onSubmit={handleSubmit} autoComplete="off">
                        <div className="row rowpadding  justify-content-between">
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer">
                                    <div className="col-12">

                                        <label className="formLable" htmlFor="locationname">Location Name</label>
                                        <div className="form-label-group">
                                            <select
                                                className="form-control border-radius"
                                                id="station_name"
                                                name="stationid"
                                                required
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Location</option>
                                                {props.locations === null ? (
                                                    <option value="none">none</option>
                                                ) : (
                                                    props.locations &&
                                                    props.locations.map((location) => (
                                                        <option key={location.name} value={location.id}>
                                                            {location.name}
                                                        </option>
                                                    ))
                                                )}
                                            </select>
                                        </div>


                                        <label className="formLable" htmlFor="hub_name">Hub Name</label>
                                        <div className="form-label-group required">
                                            <input
                                                type="text"
                                                id="hub_name"
                                                name="hubname"
                                                className="form-control control-label"
                                                placeholder="Enter hub name"
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="hub_name">Hub Name</label>
                                        </div>


                                        <label className="formLable" htmlFor="isloadrestriction">load Restriction</label>
                                        <div className="form-label-group required">
                                            <select className="form-control border-radius" id="isloadrestriction" name="isloadrestriction" onChange={handleChange} required>
                                                <option>load Restriction</option>
                                                <option>Yes</option>
                                                <option >No</option>
                                            </select>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="col-12  formContainer ">

                                    <div className="col-12 ">

                                        <label className="formLable" htmlFor="hub_lat">Hub latitude</label>
                                        <div className="form-label-group required">
                                            <input
                                                type="number"
                                                id="hub_lat"
                                                name="lat"
                                                step="0.00000001"
                                                pattern="^\d+(?:\.\d{1,8})?$"
                                                className="form-control control-label"
                                                placeholder="Enter Hub lat"
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="hub_lat">
                                                Hub latitude
                                            </label>
                                        </div>

                                        <label className="formLable" htmlFor="hub_long">Hub longitude</label>
                                        <div className="form-label-group required">
                                            <input
                                                type="number"
                                                id="hub_long"
                                                name="long"
                                                step="0.00000001"
                                                pattern="^\d+(?:\.\d{1,8})?$"
                                                className="form-control control-label"
                                                placeholder="Enter lat"
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="hub_long">Hub longitude</label>
                                        </div>
                                        <label className="formLable" htmlFor="minloadrestriction">Min Load Restriction</label>
                                        <div className="form-label-group required">
                                            <input
                                                type="number"
                                                id="minloadrestriction"
                                                name="minloadrestriction"
                                                step="0.00000001"
                                                pattern="^\d+(?:\.\d{1,8})?$"
                                                className="form-control control-label"
                                                placeholder="Enter min load restriction"
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="minloadrestriction">Min Load Restriction</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="row" >
                            <div className="col-md-3 col-lg-3 ">
                                <a
                                    className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                    href="/dashboard/hub"
                                >
                                    Cancel
                                </a>
                            </div>
                            <div className="col-md-9 col-lg-9 ">
                                <button className="btn columnsBtn btn-pill btn-wide text-uppercase float-right">

                                    {!props.loading ? "Create Hub" : "Please wait..."}

                                </button>
                            </div>
                        </div>
                    </form>

                    {/* 
                    <div className="row">
                        <div className="hr-sect">
                            <span style={{ color: "#10204B", fontWeight: "600", fontSize: "30px" }}>OR</span>
                        </div>
                    </div>


                    <div className="row formLable">
                        <div className="col-6">
                            <div className="col-12 formContainer p-5">
                                <h4 className="text-left textColor ">
                                    <b> Download Excel </b>
                                </h4>

                                <div className="page-title-subheading text-left">
                                    <ul>Steps to follow</ul>
                                    <ul>1. Download the excel.</ul>
                                    <ul>2. Add the desired data without changing header row.</ul>
                                    <ul>2. Select the location name as it is mentioned in above form.</ul>
                                    <ul>3. If there is already a dropdown, select the options from dropdown.</ul></div>
                                <a className="btn columnsBtn btn-pill btn-wide text-uppercase " href={dashboard} download="utils.xlsx" >
                                    {" "}
                                    Download Excel Format Here{" "}
                                </a>
                            </div>

                        </div>
                        <div className="col-6 ">
                            <div className="col-12 textColor formContainer p-5 ">
                                <h4 className="text-left">
                                    <b> Upload Excel </b>
                                </h4>
                                <div className="page-title-subheading text-left ">
                                    <ul>Steps to follow</ul>
                                    <ul>1. Save the previous excel file.</ul>
                                    <ul>2. Click on choose file and select the same file.</ul>
                                    <ul>3. Click upload.</ul></div>
                                <input type="file" name="file_upload columnsBtn" onChange={(e) => { const file = e.target.files[0]; readExcel(file); }} />

                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-3 col-lg-3 ">
                            <a
                                className="btn columnsBtn btn-pill btn-wide text-uppercase float-left"
                                href="/dashboard/assets"
                            >
                                Cancel
                            </a>
                        </div>
                        <div className="col-md-9 col-lg-9">
                            <button
                                className="btn columnsBtn btn-pill btn-wide text-uppercase float-right"
                                onClick={handleSubmitFile}
                            >
                                Upload New Utility
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        operatorlist: state.operator.fetchOperator,
        enterpriselist: state.operator.fetchEnterprise,
        locations: state.location.fetchLocation,
        response: state.hub.savehublist,
        loading: state.asset.isLoading,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        npData: (nrole) => dispatch(operatorAction(nrole)),
        epData: (nid, nrole) => dispatch(networkEnterpriseOperatorAction(nid, nrole)),
        getLocation: () => dispatch(getLocationAction()),
        commisionUtility: (utility) => dispatch(savehublistAction(utility)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubNameListMaster);
