import { ASSET_BASE_URL, SAVE_INVENTORY_ASSET_URL, GET_ALL_INVENTORY_ASSET ,GET_INVENTORY_ASSETBYID,UPDATE_INVENTORY_ASSETS,GET_INVENTORY_PRODUCT_COUNT} from "../../components/config/configs";

export const saveInventoryAssetAction = (inventoryasset) => {
  const { token, id,firstname } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { chargerserialno, chargeboxid, ptype, assembled, firmwareversion, dispatchdate, rfidno, tagid, simcard, acpboard, sunboard, instructionboard, stand, couriername, trackingnumber, dispatchbrand, address, prnumber, projectcode, sealable, remark, statuss } = inventoryasset;
  return dispatch => {
    dispatch({ type: 'INVENTORY_LOADING', payload: '' })
    return fetch(ASSET_BASE_URL + SAVE_INVENTORY_ASSET_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        chargerserialno: chargerserialno,
        chargeboxid: chargeboxid,
        ptype: ptype,
        assembled: assembled,
        firmwareversion: firmwareversion,
        dispatchdate: dispatchdate,
        rfidno: rfidno,
        tagid: tagid,
        simcard: simcard,
        acpboard: acpboard,
        sunboard: sunboard,
        instructionboard: instructionboard,
        stand: stand,
        couriername: couriername,
        trackingnumber: trackingnumber,
        dispatchbrand: dispatchbrand,
        address: address,
        prnumber: prnumber,
        projectcode: projectcode,
        sealable: sealable,
        remark: remark,
        statuss: statuss,
        createdby: id,
        enteredby: firstname,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        //  console.log("SAVE_INVENTORY_ASSET",data)
        dispatch({ type: 'SAVE_INVENTORY_ASSET', payload: data })
      })
  }
}


export const getAllInventoryAssetAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(ASSET_BASE_URL + GET_ALL_INVENTORY_ASSET, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
          // console.log("GETALL_INVENTORY_ASSET",data)
        dispatch({ type: 'GETALL_INVENTORY_ASSET', payload: data })
      })
  }
}

export const getInventoryAssetByIDAction = (id) =>{
  const {token}= JSON.parse(localStorage.getItem('user'))
  //  console.log("edfew",ASSET_BASE_URL+GET_INVENTORY_ASSETBYID+"?invasset_id="+id)
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(ASSET_BASE_URL+GET_INVENTORY_ASSETBYID+"?invasset_id="+id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("GET_INVENTORY_ASSET_BYID",data);
          dispatch({type: 'GET_INVENTORY_ASSET_BYID', payload: data})
      })
  }
}

export const updateInventoryAssetAction = (id,invaseet) =>{
  const {token,firstname}= JSON.parse(localStorage.getItem('user'))
  // console.log("URL",ASSET_BASE_URL+UPDATE_LOCATION_URL)
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(ASSET_BASE_URL+UPDATE_INVENTORY_ASSETS, {
      method: "PUT",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id":id,
        "chargerserialno": invaseet.chargerserialno,
        "chargeboxid": invaseet.chargeboxid,
        "ptype": invaseet.ptype,
        "assembled": invaseet.assembled,
        "firmwareversion": invaseet.firmwareversion,
        "dispatchdate": invaseet.dispatchdate,
        "rfidno":invaseet.rfidno,
        "tagid": invaseet.tagid,
        "simcard": invaseet.simcard,
        "acpboard": invaseet.acpboard,
        "sunboard": invaseet.sunboard,
        "instructionboard": invaseet.instructionboard,
        "stand": invaseet.stand,
        "couriername": invaseet.couriername,
        "trackingnumber": invaseet.trackingnumber,
        "dispatchbrand": invaseet.dispatchbrand,
        "address": invaseet.address,
        "prnumber": invaseet.prnumber,
        "projectcode": invaseet.projectcode,
        "sealable": invaseet.sealable,
        "remark": invaseet.remark,
        "statuss": invaseet.statuss,
        "createdby": invaseet.createdby,
        "enteredby":firstname
   
     })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("UPDATE_LOCATION",data)
          dispatch({type: 'UPDATE_INVENTORY_ASSET', payload: data})
      })
  }
}

export const getAssetProductCountAction = () =>{
  const {token}= JSON.parse(localStorage.getItem('user'))
  //  console.log("edfew",ASSET_BASE_URL+GET_INVENTORY_ASSETBYID+"?invasset_id="+id)
  var bearer = 'basic ' + token;
  return dispatch => {
    return fetch(ASSET_BASE_URL+GET_INVENTORY_PRODUCT_COUNT, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("ASSET_PRODUCT_COUNT",data);
          dispatch({type: 'ASSET_PRODUCT_COUNT', payload: data})
      })
  }
}