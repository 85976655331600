import {BASE_URL, FETCH_ENTERPRISE_URL } from "../../components/config/configs";

export const operatorAction=(userrole)=>{
    const {token,networkoperator,role}= JSON.parse(localStorage.getItem('user'))
    userrole=userrole==null?role:userrole;
   // console.log(userrole);
    var bearer = 'basic ' + token;
      return dispatch => {
       return fetch(BASE_URL+FETCH_ENTERPRISE_URL+"?parentid="+networkoperator+"&role="+userrole, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
          //console.log(" FETCH_OPERATOR----",data);
             dispatch({type: 'FETCH_OPERATOR', payload: data})
         })
     }
   }

   export const networkEnterpriseOperatorAction=(networkid,networkrole)=>{
    const {token}= JSON.parse(localStorage.getItem('user'))
     var bearer = 'basic ' + token;
      return dispatch => {
       return fetch(BASE_URL+FETCH_ENTERPRISE_URL+"?parentid="+networkid+"&role="+networkrole, {
         method: "GET",
         headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
       })
         .then(resp => resp.json())
         .then(data => {
             dispatch({type: 'FETCH_ENTERPRISE', payload: data})
         })
     }
   }