const initState = {
    fetchAsset: '',
    fetchMaker: '',
    fetchModel: '',
    saveAsset: '',
    isLoading: false,
    fetchAssetList: '',
    fetchSingleAsset: '',
    updateAsset: '',
    saveUtility: '',
    fetchAllUtility: '',
    fetchUtilityByID: '',
    updateUtilityByID: '',
    fetchAmenity: '',
    updateAssetById: '',
    assetDeactivation:'',
    utilityDeactivation:'',

}
const assetReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ASSET_LOADING':
            return { ...state, isLoading: true }
        case 'FETCH_ASSET':
            return { ...state, fetchAsset: action.payload, isLoading: false }
        case 'FETCH_ASSET_MAKER':
            return { ...state, fetchMaker: action.payload, isLoading: false }
        case 'FETCH_ASSET_MODEL':
            return { ...state, fetchModel: action.payload, isLoading: false }
        case 'SAVE_ASSET':
            return { ...state, saveAsset: action.payload, isLoading: false }
        case 'FETCH_ASSET_LIST':
            return { ...state, fetchAssetList: action.payload, isLoading: false }
        case 'FETCH_SINGLE_ASSET_LIST':
            return { ...state, fetchSingleAsset: action.payload, isLoading: false }
        case 'SAVE_UTILITY':
            return { ...state, saveUtility: action.payload, isLoading: false }
        case 'FETCH_UTILITY':
            return { ...state, fetchAllUtility: action.payload, isLoading: false }
        case 'FETCH_UTILIT_BYID':
            return { ...state, fetchUtilityByID: action.payload, isLoading: false }
        case 'UPDATE_UTILITY':
            return { ...state, updateUtilityByID: action.payload, isLoading: false }
        case 'UPDATE_ASSET':
            return { ...state, updateAsset: action.payload, isLoading: false }
        case 'FETCH_AMENITY':
            return { ...state, fetchAmenity: action.payload, isLoading: false }
        case 'UPDATE_ASSET':
            return { ...state, updateAssetById: action.payload, isLoading: false }
        case 'ASSET_DEACTIVATION':
            return { ...state, assetDeactivation: action.payload, isLoading: false }
        case 'UTILITY_DEACTIVATION':
            return { ...state, utilityDeactivation: action.payload, isLoading: false }



        default:
            return state;
    }
}
export default assetReducer;