
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { getHubStatus } from '../../../../store/actions/hubActions';
import Table from "./hubstatusTable";
// import "../report/report.css"
import Loader from 'react-loader-spinner';
import "../../report/table.css"


const ManageHubStatus = (props) => {


    useEffect(() => {
        props.getHubStatus()
    }, []);

    const data = props.fetchHubStatus && props.fetchHubStatus
// console.log("alldata",data)

    const columns = useMemo(

        () => [
            {
                header: 'Hub Name',
                accessor: 'hubname'
            },
            {
                header: 'Date',
                accessor: 'date'
            },
            {
                header: 'Status',
                id: 'status',
                accessor: (d) => {
                    return (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='tableHead'>SL.No</td>
                                        <td className='tableHead'>Time</td>
                                        <td className='tableHead'>Total Charger</td>
                                        <td className='tableHead'>Available Charger</td>
                                        <td className='tableHead'>Faulty Charger</td>
                                        <td className='tableHead'>Total Connector</td>
                                        <td className='tableHead'>Available Connector</td>
                                        <td className='tableHead'>Maintenance Charger</td>
                                        <td className='tableHead'>Vehicle Count </td>
                                        <td className='tableHead'>Vehicle Charging</td>
                                        <td className='tableHead'>Vehicle Parking</td>
                                        <td className='tableHead'>Entery By</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        d.status.map((data, i) =>
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{data.time}</td>
                                                <td>{data.totalcharger}</td>
                                                <td>{data.availablecharger}</td>
                                                <td>{data.faultycharger}</td>
                                                <td>{data.totalconnector}</td>
                                                <td>{data.availableconnector}</td>
                                                <td>{data.maintenancecharger}</td>
                                                <td>{data.vehiclecount}</td>
                                                <td>{data.vehiclecharging}</td>
                                                <td>{data.vehicleparking}</td>
                                                <td>{data.entryby}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                    );

                },
            },

        ],
        []);
    useEffect(() => {
        props.getHubStatus();
    }, [])

      return (
        <div className="container-fluid">

            <div className="row">
                <div className="col sideCardDesigns">
                    <div className="mb-3 h-100 singleCard">
                        <div className="card-header singleCard mb-3">
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal"><strong style={{color:"#10204B"}}>Hub Status Details</strong>
                            </div>
                        </div>
                        {!data.length ?
                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                            :
                            (<div className="App "> <Table id="react-status-table" data={data} columns={columns}></Table></div>)}
                    </div>
                </div>
            </div>


        </div>
    )
}



const mapStateToProps = function (state) {
    return {
        fetchHubStatus: state.hub.getHubStatusDetails,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getHubStatus: () => dispatch(getHubStatus()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageHubStatus);



