import React from 'react';
import AssetCard from './assetcard';
import "./asset.css";

function Asset() {
  return (
   
      <AssetCard></AssetCard>   
    
  );
}
export default Asset;